import React from 'react'

import theme from 'theme'

const ProceedArrow = ({
  color = theme.colors.white,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 18l6-6-6-6"
    />
  </svg>
)

export default ProceedArrow
