import React from 'react'
import dayjs from 'dayjs'

import { getDeliveryDayIcon } from '../../utils'
import ContainerArrow from '../../assets/ContainerArrow'

import {
  OuterWrapper,
  DeliveryDayContainer,
  TextContainer,
  DateText,
  DayText,
  IconContainer,
} from './styles'

const DeliveryDay = ({ deliveryDay, isToday, isSelected, onClick }) => {
  const { date, routes } = deliveryDay

  const handleClick = () => {
    if (!isSelected) onClick()
  }

  const parsedDate = dayjs(date)
  const dateFormatted = parsedDate.format('D')
  const dayFormatted = parsedDate.format('ddd')

  const { icon, iconTestId } = getDeliveryDayIcon({ routes, isToday })

  return (
    <OuterWrapper data-test-id={`delivery-day-${dateFormatted}`}>
      <DeliveryDayContainer
        $isToday={isToday}
        $isSelected={isSelected}
        onClick={handleClick}
      >
        <TextContainer>
          <DateText>{dateFormatted}</DateText>
          <DayText>{dayFormatted}</DayText>
        </TextContainer>
        <IconContainer data-test-id={iconTestId}>{icon}</IconContainer>
      </DeliveryDayContainer>
      {isSelected && <ContainerArrow />}
    </OuterWrapper>
  )
}

export default DeliveryDay
