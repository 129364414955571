import React from 'react'

import theme from 'theme'

const CalendarV2 = ({ color = theme.colors.gray5 }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="3"
      width="12"
      height="12"
      rx="3"
      stroke={color}
      strokeWidth="1.4"
    />
    <path d="M2 7H14" stroke={color} strokeWidth="1.4" strokeLinecap="round" />
    <path d="M8 12H11" stroke={color} strokeWidth="1.4" strokeLinecap="round" />
    <path d="M5 5V2" stroke={color} strokeWidth="1.4" strokeLinecap="round" />
    <path d="M11 5V2" stroke={color} strokeWidth="1.4" strokeLinecap="round" />
  </svg>
)

export default CalendarV2
