import styled, { css } from 'styled-components'
import {
  FilteredPageTileImage,
  FilteredPageTileTitle,
  FilteredPageTileWrapper,
} from 'components/FilteredPageTile/styles'
import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'

export const PreselectedFilterPagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
  > ${FilteredPageTileWrapper} {
    aspect-ratio: 1;

    @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
      aspect-ratio: auto;
      min-height: 20vw;
    }

    > ${FilteredPageTileTitle} {
      font-size: 13px;
    }

    @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
      min-height: auto;
      padding: ${theme.spacing.sm} ${theme.spacing.base};
      > ${FilteredPageTileTitle} {
        font-size: 14px;
      }
    }

    ${p =>
      p.$tileCount === 3 &&
      css`
        :last-child {
          grid-column-start: span 2;
          aspect-ratio: auto;
          ${FilteredPageTileImage} {
            width: 75%;
            height: auto;
          }
        }
      `};

    ${p =>
      p.$tileCount <= 2 &&
      css`
        grid-column-start: span 2;
      `};
    ${p =>
      p.$tileCount === 2 &&
      css`
        aspect-ratio: auto;
      `};
    ${p =>
      p.$tileCount === 1 &&
      css`
        grid-row-start: span 2;
      `};
  }

  ${FilteredPageTileImage} {
    ${p =>
      p.$tileCount === 2 &&
      css`
        width: 75%;
        height: auto;
      `};
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    grid-template-rows: unset;
    grid-row-start: 2;
  }
`
