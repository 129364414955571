import React from 'react'
import theme from 'theme'

const UserMenu = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M15 20V19C15 16.7909 13.2091 15 11 15H7C4.79086 15 3 16.7909 3 19V20"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="9" cy="8" r="3" stroke={color} strokeWidth="2" />
    <path d="M19 8H23" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M19 12H23" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M19 16H23" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export default UserMenu
