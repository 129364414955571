import React, { useState, useRef, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { xor } from 'lodash/fp'

import theme from 'theme'
import { cn } from 'utils'
import { ArrowDownWide as ArrowIcon } from 'components/Icons'
import Multiselect from 'components/DropdownBody/Multiselect'
import { PROFILES } from '../consts'
import TailShadowSvg from './assets/tail-shadow.svg'
import TailShadowDarkSvg from './assets/tail-shadow-dark.svg'

import messages from '../messages'

const ProfileMultiselect = ({ confirmedProfiles, setConfirmedProfiles }) => {
  const { formatMessage } = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const multiselectEl = useRef(null)

  const profiles = useMemo(() => Object.values(PROFILES), [])
  const hasConfirmBtn = useMemo(
    () => !!xor(selectedProfiles, confirmedProfiles).length,
    [selectedProfiles, confirmedProfiles],
  )

  const elHeight = multiselectEl.current?.offsetHeight || 0
  const dropdownOffset = {
    // element height + gap
    top: elHeight + 8,
  }

  const handleProfileSelect = profile => {
    if (selectedProfiles.includes(profile)) {
      setSelectedProfiles(state =>
        state.filter(selectedProfile => selectedProfile !== profile),
      )
    } else {
      setSelectedProfiles(state => [...state, profile])
    }
  }

  const handleDropdownClose = () => {
    setIsOpen(false)
    setSelectedProfiles(confirmedProfiles)
  }

  const handleConfirm = () => {
    setIsOpen(false)
    setConfirmedProfiles(selectedProfiles)
  }

  return (
    <div className="relative" ref={multiselectEl}>
      <div className="flex flex-col gap-2 w-full">
        <span className="text-[12px] leading-5 px-2 text-left text-blue-900">
          {formatMessage(messages.profile)}
        </span>
        <button
          type="button"
          className={cn(
            'flex w-full rounded-lg px-4 py-3 justify-between',
            isOpen ? 'bg-grey-400' : 'bg-grey-150',
          )}
          onClick={() => setIsOpen(true)}
        >
          <div className="flex gap-2 overflow-hidden">
            <div className="flex gap-2" data-test-id="selected-profiles">
              {confirmedProfiles.map(profile => (
                <div key={profile} className="bg-green-600 h-6 px-2 rounded-lg">
                  <span className="text-white text-[11px] leading-6 text-nowrap">
                    {profile}
                  </span>
                </div>
              ))}
            </div>
            <span
              className={cn(
                'text-base text-nowrap',
                isOpen ? 'text-blue-900' : 'text-grey-600',
              )}
            >
              {formatMessage(messages.profilePlaceholder)}
            </span>
          </div>
          <div
            className={cn(
              'z-[1] w-6 h-6 justify-items-center content-center transition duration-300 ease-out',
              isOpen ? '-rotate-180' : 'rotate-0',
            )}
          >
            <ArrowIcon color={theme.colors.primary} />
          </div>
          <img
            src={isOpen ? TailShadowDarkSvg : TailShadowSvg}
            className="absolute right-0 bottom-0"
            alt=""
          />
        </button>
      </div>
      {isOpen && (
        <Multiselect
          portalNode={multiselectEl.current}
          maxWidth={multiselectEl.current?.offsetWidth}
          offset={dropdownOffset}
          onOverlayClick={handleDropdownClose}
          onOptionSelect={handleProfileSelect}
          onConfirm={handleConfirm}
          options={profiles}
          selectedOptions={selectedProfiles}
          selectedOptionsLimit={3}
          hasConfirmBtn={hasConfirmBtn}
          btnText={formatMessage(messages.profileConfirm)}
        />
      )}
    </div>
  )
}

export default ProfileMultiselect
