import React from 'react'

import theme from 'theme'

import { ROUTES } from 'consts'
import { CartV2, SearchV2 as SearchIcon } from 'components/Icons'
import { BadgeAlert as AlertIcon } from 'components/Icons/NavMenu'
import { Link } from 'components/Text'
import { trackSimpleEvent, NAV_BAR_EVENTS } from 'services/analytics'

import { UserControlsWrapper } from '../../UserControls/styles'
import NotificationCenter from '../../NotificationCenter'
import MobileBurger from './MobileBurger'
import {
  CartIconWrapper,
  BadgeContainer,
  BadgeInnerContainer,
  BadgeText,
} from './styles'

const MobileUserControls = ({
  mobileSearchEnabled,
  setMobileSearchEnabled,
  userBtnStyles,
  cartBtnStyles,
  cartCount,
  mobileMenuOffset,
}) => {
  const { isAlert, bgColor, contentColor } = cartBtnStyles
  const isBadgeShown = isAlert || !!cartCount

  return (
    <UserControlsWrapper isMobile>
      {!mobileSearchEnabled && (
        <div>
          <SearchIcon
            data-test-id="search-mobile-icon"
            onClick={() => setMobileSearchEnabled(true)}
          />
        </div>
      )}

      <NotificationCenter />

      <Link
        to={ROUTES.CART}
        onClick={() => trackSimpleEvent(NAV_BAR_EVENTS.CART_CLICKED)}
      >
        <CartIconWrapper>
          {isBadgeShown && (
            <BadgeContainer {...{ isAlert }}>
              {isAlert ? (
                <AlertIcon
                  data-test-id="badge-alert-icon"
                  {...{ bgColor, contentColor }}
                />
              ) : (
                <BadgeInnerContainer {...{ bgColor }}>
                  <BadgeText {...{ contentColor }}>{cartCount}</BadgeText>
                </BadgeInnerContainer>
              )}
            </BadgeContainer>
          )}
          <CartV2 color={theme.colors.primary} />
        </CartIconWrapper>
      </Link>

      <MobileBurger {...{ mobileMenuOffset, userBtnStyles }} />
    </UserControlsWrapper>
  )
}

export default MobileUserControls
