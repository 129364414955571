import React from 'react'
import { useSelector } from 'react-redux'
import { Flex } from '@rebass/grid'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import theme from 'theme'
import { useOfMaxWidth } from 'hooks'

import { cartPromosSelector } from 'containers/Cart/selectors'

import { APP_BREAKPOINTS } from 'consts'
import messages from '../messages'
import { SectionHeader } from '../styledComponents'
import Item from './Item'

const Wrapper = styled(Flex)`
  padding-top: 80px;
  margin-top: 80px;
  border-top: 1px solid ${theme.colors.gray2};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: column;
    padding-top: 32px;
    margin-top: 32px;
  }
`

const ListWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    overflow: auto;
    flex-direction: row;
  }
`

const PromotionsSection = () => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TABLET_START - 1)
  const promotions = useSelector(cartPromosSelector)

  if (!promotions.length) return null

  return (
    <Wrapper>
      <SectionHeader style={{ marginLeft: isMobile ? theme.spacing.sm : 0 }}>
        <FormattedMessage {...messages.promotionsSection} />
      </SectionHeader>

      <ListWrapper>
        {promotions.map(promotion => (
          <Item key={promotion.reward?.productId} {...promotion} />
        ))}
      </ListWrapper>
    </Wrapper>
  )
}

export default PromotionsSection
