import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'

export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    width: auto;
    justify-content: unset;
  }
`

// prettier-ignore
export const PaginationBtn = styled(Flex)`
  border-radius: 8px;
  padding: 8px 16px;
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  :hover {
    background: ${props =>
    props.isDisabled
      ? 'initial'
      : theme.colors.lightGrey
};
  }
`

const paginationTextStyling = css`
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  margin: 0 4px;
  padding: 8px 16px;
  width: 85px;
  height: 40px;
  border-radius: 8px;
  line-height: 24px;
  :hover {
    background: ${theme.colors.lightGrey};
  }
`

export const PaginationInput = styled.input`
  ${paginationTextStyling};
  color: ${theme.colors.primary};
  text-align: center;
  :focus-visible {
    outline: none;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
`

export const PaginaitonPlaceholder = styled(Flex)`
  ${paginationTextStyling};
  justify-content: center;
  color: ${theme.colors.lightGrey4};
  cursor: pointer;
  span:first-child {
    color: ${theme.colors.primary};
  }
  span:nth-child(2) {
    margin: 0 10px;
  }
`
