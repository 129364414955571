import React from 'react'
import { useIntl } from 'react-intl'

import {
  ProductDetailsText,
  ProductInfoContainer,
} from 'views/ProductDetails/ProductInfoSection/styles'
import DescriptionDropdown from 'views/ProductDetails/DescriptionDropdown'
import ProductParameters from 'views/ProductDetails/ProductInfoSection/ProductParameters'
import messages from 'views/ProductDetails/messages'

const ProductInfoSection = ({
  product: { description, ingredients, parameters },
}) => {
  const { formatMessage } = useIntl()

  return (
    <ProductInfoContainer>
      {description && (
        <DescriptionDropdown
          isOpenByDefault
          title={formatMessage(messages.basicInfo)}
        >
          <ProductDetailsText
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </DescriptionDropdown>
      )}
      {ingredients && (
        <DescriptionDropdown title={formatMessage(messages.ingredientsTitle)}>
          <ProductDetailsText
            dangerouslySetInnerHTML={{ __html: ingredients }}
          />
        </DescriptionDropdown>
      )}
      {!!parameters.length && (
        <DescriptionDropdown title={formatMessage(messages.parametersTitle)}>
          <ProductParameters parameters={parameters} />
        </DescriptionDropdown>
      )}
    </ProductInfoContainer>
  )
}

export default ProductInfoSection
