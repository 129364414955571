import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import ButtonLoader from 'components/Icons/ButtonLoader'

const ButtonV2 = ({ children, isLoading, disabled, ...props }) => (
  <button type="button" disabled={disabled || isLoading} {...props}>
    {isLoading ? <ButtonLoader /> : children}
  </button>
)

const BasicBtn = styled(ButtonV2)`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  gap: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius.base};
  cursor: pointer;
  width: 100%;

  font-size: ${theme.textSizes.xs};
  font-weight: ${theme.fontWeights.regular};
  line-height: 24px;
  background-color: ${p => p.$bgColor};
  &:hover {
    background-color: ${p => (p.isLoading ? p.$bgColor : p.$hoverColor)};
  }
`

export default BasicBtn
