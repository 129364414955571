import styled from 'styled-components'

export const Columns = styled.div`
  box-sizing: border-box;
  ${({ col }) => `width: calc((${col} / 12) * 100% )`};
`

export const FlexColumns = styled(Columns)`
  display: flex;
  flex-wrap: wrap;
`
