import React from 'react'

import Checkbox from 'components/Input/Checkbox'
import { CheckboxWrapperV2 } from 'components/Input/styles'

import { CheckboxContainer } from './styles'

const TemplateCheckbox = ({ isChecked, onClick }) => (
  <CheckboxContainer>
    <Checkbox
      onClick={onClick}
      Wrapper={CheckboxWrapperV2}
      checked={isChecked}
      readOnly
    />
  </CheckboxContainer>
)

export default TemplateCheckbox
