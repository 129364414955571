import React from 'react'

import theme from 'theme'

const DotsMenu = ({ color = theme.colors.primary }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="7" r="1" fill={color} />
    <circle cx="12" cy="12" r="1" fill={color} />
    <circle cx="12" cy="17" r="1" fill={color} />
  </svg>
)

export default DotsMenu
