import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { bindActionCreators } from 'utils/redux-utils'

import {
  selectTemplateDetailsFinished,
  selectTemplatesData,
  selectTemplatesFinished,
  templateProductDetailsSelector,
  templateProductDetailsIsFetched,
} from './selectors'
import {
  templatesActions,
  templateItemsActions,
  productTemplatesAction,
  getTemplateProductDetails,
} from './actions'

export * from './consts'
export * from './selectors'

export const mapStateToProps = createStructuredSelector({
  isItemsFinished: selectTemplateDetailsFinished,
  isTemplatesFinished: selectTemplatesFinished,
  templates: selectTemplatesData,
  templateProductDetails: templateProductDetailsSelector,
  templateIsProductDetailsFetched: templateProductDetailsIsFetched,
})

export const mapDispatchToProps = bindActionCreators({
  getTemplates: templatesActions.delta,
  getTemplateItems: templateItemsActions.delta,
  getProductTemplates: productTemplatesAction,
  createTemplate: templatesActions.createDelta,
  clearTemplates: templatesActions.clear,
  clearTemplateItems: templateItemsActions.clear,
  getTemplateProductDetails: getTemplateProductDetails.delta,
  clearTemplateProductDetails: getTemplateProductDetails.clear,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
