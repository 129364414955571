import React from 'react'
import { find, get, groupBy } from 'lodash/fp'

import { calculateAmountByQuantity } from 'components/Product/utils'

import OrderProduct from 'views/Cart/CartProducts/DeliveryProducts/IncomingOrdersExpandable/IncomingOrders/OrderProduct'

const Products = ({ orderItems }) =>
  Object.entries(groupBy('productId')(orderItems)).map(
    ([productId, variants]) => (
      <OrderProduct
        isSummary
        key={productId}
        product={{
          product: variants[0].product,
          units: variants.map(variant => {
            const unitData = find({
              unitOfMeasure: variant.unitOfMeasure,
            })(variants[0].product.unitsOfMeasure)
            return {
              ...variant,
              quantity: calculateAmountByQuantity(unitData, variant.quantity),
              unitOfMeasureDescription: get('unitOfMeasureDescription')(
                unitData,
              ),
            }
          }),
        }}
      />
    ),
  )

export default Products
