import React from 'react'
import PropTypes from 'prop-types'
import { ICON_SIZE, ICONS_POSITION } from './consts'
import IconWrapper from './IconWrapper'
import Icon from './Icon'

export {
  ICONS,
  ICONS_POSITION,
  ICON_SIZE,
  ICON_COLORS,
  getIconSize,
} from './consts'
export withIcon from './withIcon'
export IconWrapper from './IconWrapper'
export { Logo } from './Logo'
export Icon from './Icon'

export const SmallIcon = ({ type, iconSvgProps, ...props }) => (
  <IconWrapper
    position={ICONS_POSITION.CENTER}
    iconSize={ICON_SIZE.SMALL}
    {...props}
  >
    <Icon {...iconSvgProps} type={type} size={ICON_SIZE.SMALL} />
  </IconWrapper>
)

SmallIcon.propTypes = {
  type: PropTypes.string.isRequired,
  iconSvgProps: PropTypes.object,
}

export const NormalIcon = ({ type, iconSvgProps, ...props }) => (
  <IconWrapper
    position={ICONS_POSITION.CENTER}
    iconSize={ICON_SIZE.NORMAL}
    {...props}
  >
    <Icon {...iconSvgProps} type={type} size={ICON_SIZE.NORMAL} />
  </IconWrapper>
)

NormalIcon.propTypes = {
  type: PropTypes.string.isRequired,
  iconSvgProps: PropTypes.object,
}

export const DefaultIcon = ({ type, iconSvgProps, size, ...props }) => (
  <IconWrapper position={ICONS_POSITION.CENTER} iconSize={size} {...props}>
    <Icon {...iconSvgProps} type={type} size={size} />
  </IconWrapper>
)
