import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2Medium } from 'components/Text'

export const RouteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: ${theme.spacing.xs};
  transition: all 0.3s ease-in;

  &:hover {
    background-color: ${theme.colors.lightGrey7};
  }
`

export const RouteTextContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  overflow: hidden;
  // an extra span styling for tooltip wrapper
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const RouteStatusContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xxs};
  height: 24px;
`

export const RouteProgressWrapper = styled.div``

const RoundedWrapper = styled.div`
  display: flex;
  background: ${theme.colors.greenLight5};
  border-radius: ${theme.borderRadius.base};
`

export const RouteStatusTextWrapper = styled(RoundedWrapper)`
  padding: 0 ${theme.spacing.xs};
`

export const RouteStatusText = styled(BaseTextV2Medium)`
  white-space: nowrap;
  font-size: ${theme.textSizes.xxs};
  color: ${theme.colors.secondary};
`

export const LimitMetStatusWrapper = styled(RoundedWrapper)`
  padding: 6px ${theme.spacing.xs};
`

export const LimitNotMetStatusWrapper = styled(LimitMetStatusWrapper)`
  width: 28px;
  background: ${theme.colors.redLight};
`

export const LimitMetIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.secondary};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`
