import { takeLatest, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced, crudSwitch } from 'containers/App/sagas'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'

import * as api from './api'
import actions, { toggleNewsletterSubscription } from './actions'

export function* getAgreementsFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions,
      request: api.get,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* updateAgreementsFlow({ data: { id, value } }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions,
      request: api.update,
      params: { id, value },
      method: 'cancel', // do not change data
    })
    yield call(getAgreementsFlow)
    return true
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    return false
  }
}

export function* toggleNewsletterSubscriptionFlow({ payload, meta }) {
  const isSuccess = yield call(updateAgreementsFlow, { data: payload })
  if (!isSuccess) return

  yield call(meta.onSuccess)
}

export default [
  takeLatest(actions.DELTA, crudSwitch, {
    getSaga: getAgreementsFlow,
    updateSaga: updateAgreementsFlow,
  }),
  takeLatest(
    toggleNewsletterSubscription.toString(),
    toggleNewsletterSubscriptionFlow,
  ),
]
