import styled, { css } from 'styled-components'
import { getColors, getFontWeights } from '../../helpers/theme-selectors'

import { Label, getdisabledOpacity } from '../styles'

import checkedRadio from './checkedRadio.svg'

const RadioboxSize = css`
  width: 28px;
  height: 28px;
  min-width: 28px;
`

export const RadioLabel = styled(Label)`
  color: ${props => getColors(props).primary};
  ${props =>
    props.bold &&
    `
    font-weight: ${getFontWeights(props).bold};
  `} word-break: break-all;
  ${({ disabled }) => disabled && getdisabledOpacity()};
  margin-left: 12px;
`

export const RadioLabelWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const RadioInput = styled.input`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  :checked {
    ~ span {
      background-image: url("${checkedRadio}");
    }
  }

  :not(:checked) {
    ~ span {
      background: ${props => getColors(props).white};
      border: 1px solid ${props => getColors(props).gray2};
      border-radius: 50%;
    }
  }
`

export const RadioToggle = styled.span`
  ${RadioboxSize};
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
`
