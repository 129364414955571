import styled from 'styled-components'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

// prettier-ignore
export const FixedContainer = styled.div`
  @media screen and (max-width: ${APP_BREAKPOINTS.TMP_MAIN - 1}px) {
    margin: 0 -${theme.spacing.xs};
  }

  ${p => p.isFixed && `
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: ${APP_BREAKPOINTS.TMP_MAIN - 1}px) {
      margin: 0;
    }
  `}
`

// prettier-ignore
export const InnerFixedWrapper = styled.div`
  width: 100%;

  ${p => p.isFixed && `
    margin-left: ${theme.spacing.md};
    margin-right: ${theme.spacing.md};

    @media screen and (max-width: ${APP_BREAKPOINTS.TMP_MAIN - 1}px) {
      margin: 0;
    }
  `};
`
