import React from 'react'

import theme from 'theme'

const HeartBig = ({ color = theme.colors.secondary }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.28757 15.737L14.4441 27.0737C15.2446 28.0648 16.7554 28.0648 17.5559 27.0737L26.7124 15.737C28.6007 13.3991 28.4895 10.0303 26.4511 7.82205C24.0525 5.2236 19.9474 5.2236 17.5489 7.82205L16 9.5L14.4511 7.82205C12.0526 5.2236 7.94745 5.2236 5.54887 7.82205C3.5105 10.0303 3.39929 13.3991 5.28757 15.737Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

export default HeartBig
