import React from 'react'
import { useIntl } from 'react-intl'

import { useGetOrderDetailsQuery } from 'containers/Orders/Details/rtkApi'
import { PlusV2Big as PlusIcon, InfoV2 as InfoIcon } from 'components/Icons'
import OrdersListItem from './OrdersListItem'

import messages from './messages'
import {
  ListContainer,
  ListTitle,
  AffectedOrdersTitleContainer,
  InfoTextContainer,
  InfoText,
} from './styles'

const AffectedOrdersList = ({
  orderId,
  affectedOrders,
  handleShowAffectedOrder,
}) => {
  const { formatMessage } = useIntl()
  const { data: currentOrder } = useGetOrderDetailsQuery({ orderId })

  if (!currentOrder) return null

  return (
    <ListContainer>
      <ListTitle>{formatMessage(messages.currentOrderToCancel)}</ListTitle>
      <OrdersListItem
        orderNumber={currentOrder.orderNumber}
        orderTotal={currentOrder.totalNet}
      />
      <AffectedOrdersTitleContainer>
        <PlusIcon />
        <ListTitle>{formatMessage(messages.affectedOrdersToCancel)}</ListTitle>
      </AffectedOrdersTitleContainer>
      {affectedOrders.map(order => (
        <OrdersListItem
          key={order.number}
          orderNumber={order.number}
          orderTotal={order.totalNet}
          onViewOrderClick={() => handleShowAffectedOrder(order.id)}
          isAffectedOrder
        />
      ))}
      <InfoTextContainer>
        <div>
          <InfoIcon />
        </div>
        <InfoText>{formatMessage(messages.affectedOrdersInfo)}</InfoText>
      </InfoTextContainer>
    </ListContainer>
  )
}

export default AffectedOrdersList
