import * as React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { isNil } from 'lodash/fp'

import theme from 'theme'

import { PPrimary, TinyText } from 'components/Text'

const Number = styled(PPrimary)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6px;
  font-weight: bold;
`

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
`

const CalendarSmall = ({
  color = theme.colors.primary,
  number,
  day,
  numColor,
  width = 30,
  height = 30,
  ...rest
}) => (
  <Wrapper {...rest}>
    {!isNil(number) && <Number color={numColor}>{number}</Number>}
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ width, height }}
    >
      <path
        d="M6 2H5a4 4 0 00-4 4v18a4 4 0 004 4h20a4 4 0 004-4V6a4 4 0 00-4-4h-1M12 2h6"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M9 1v2M21 1v2"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    {!isNil(day) && <TinyText>{day}</TinyText>}
  </Wrapper>
)

export default CalendarSmall
