import React from 'react'

import { Description, Title, Count } from './styles'

const TemplateEntry = ({ icon, title, count }) => (
  <Description>
    {icon}

    <Title>{title}</Title>
    <Count>{count}</Count>
  </Description>
)

export default TemplateEntry
