import { createSelector } from 'reselect'
import { genericSelector } from 'utils/redux-utils'

import { INVOICES_REDUCER_NAME, INVOICES_SUMMARY_REDUCER_NAME } from './consts'

export const invoicesSummarySelectors = genericSelector([
  INVOICES_REDUCER_NAME,
  INVOICES_SUMMARY_REDUCER_NAME,
])

export const selectHasOverpayments = createSelector(
  invoicesSummarySelectors.data,
  data => data?.hasOverpayments,
)
