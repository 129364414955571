import styled, { css } from 'styled-components'
import theme from 'theme'

export const DropdownOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${theme.colors.gray5};
  z-index: ${theme.zIndex.header - 1};
  ${p =>
    p.$hidden
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 0.3;
          visibility: visible;
        `};
  transition: all 300ms ease-out;
`
