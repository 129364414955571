import React from 'react'
import {
  FloatingPriceBig,
  FloatingPriceXSmall,
} from 'components/Icons/FloatingPrice'
import { useIntl } from 'react-intl'
import theme from 'theme'

import { FLOATING_PRICE_VARIANT } from '../consts'

import messages from '../messages'
import {
  FloatingPricesIconContainer,
  FloatingPricesInfoContainer,
  FloatingPricesInfoText,
  FloatingPricesInfoTitle,
  FloatingPricesTextContainer,
} from './styles'

const VARIANTS = {
  [FLOATING_PRICE_VARIANT.EXTRA_SMALL]: {
    container: {
      gap: theme.spacing.xs,
    },
    title: {
      fontWeight: theme.fontWeights.regular,
    },
    icon: <FloatingPriceXSmall />,
  },
}

const FloatingPriceInfoBox = ({ className, infoText, bgColor, variant }) => {
  const { formatMessage } = useIntl()

  const config = VARIANTS[variant] || {}

  return (
    <FloatingPricesInfoContainer
      className={className}
      data-test-id="floating-prices-info"
      style={config.container}
      $bgColor={bgColor}
    >
      <FloatingPricesIconContainer>
        {config.icon || <FloatingPriceBig />}
      </FloatingPricesIconContainer>
      <FloatingPricesTextContainer>
        <FloatingPricesInfoTitle style={config.title}>
          {formatMessage(messages.infoBoxTitle)}
        </FloatingPricesInfoTitle>
        <FloatingPricesInfoText>
          {infoText || formatMessage(messages.infoBoxText)}
        </FloatingPricesInfoText>
      </FloatingPricesTextContainer>
    </FloatingPricesInfoContainer>
  )
}

export default FloatingPriceInfoBox
