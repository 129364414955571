import { path } from 'lodash/fp'
import { createSelector } from 'reselect'

import { makeImmutableStateSelector } from 'utils/redux-utils'
import { PROMOS_REDUCER_NAME, PROMO_PRODUCTS_REDUCER_NAME } from '../consts'

const selectPromotions = state =>
  makeImmutableStateSelector(state, [
    PROMOS_REDUCER_NAME,
    PROMO_PRODUCTS_REDUCER_NAME,
  ])

export const makePromotionProductSelectors = productId => ({
  isFetching: createSelector(selectPromotions, path([productId, 'isFetching'])),
  data: createSelector(selectPromotions, path([productId, 'data'])),
  error: createSelector(selectPromotions, path([productId, 'error'])),
})
