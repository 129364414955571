export const SIZE = {
  BIG: 'big',
  MEDIUM: 'medium',
  SMALL: 'small',
}

export const CIRCLE_SIZE = {
  [SIZE.BIG]: '40px',
  [SIZE.MEDIUM]: '32px',
  [SIZE.SMALL]: '24px',
}

export const POINTS_TEXT_SIZE = {
  [SIZE.BIG]: '22px',
  [SIZE.MEDIUM]: '16px',
  [SIZE.SMALL]: '12px',
}

export const AMOUNT_TEXT_SIZE = {
  [SIZE.BIG]: '16px',
  [SIZE.MEDIUM]: '14px',
  [SIZE.SMALL]: '12px',
}

export const MAX_AMOUNT_TEXT_SIZE = {
  [SIZE.BIG]: '16px',
  [SIZE.MEDIUM]: '12px',
  [SIZE.SMALL]: '8px',
}
