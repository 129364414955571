import { handleActions } from 'redux-actions'

import { createReducer, initialState } from 'utils/simpleDataReducer'

import { updateCartItem, deleteCartItem } from '../Cart/actions'
import { resetProductUnitsOfMeasureInCart } from '../Products/reducer'
import { productDetailsActions, subscribeToProductActions } from './actions'

const detailsReducer = createReducer(productDetailsActions)
const cartHandlers = handleActions(
  {
    [subscribeToProductActions.SUCCESS]: (state, { data: { email } }) => {
      const stateData = state.get('data')
      if (stateData) {
        return state.set('data', stateData.merge({ notificationEmail: email }))
      }

      return state
    },
    [updateCartItem.SUCCESS]: (state, { data: { cartItem } }) => {
      const data = state.get('data')
      if (data && cartItem && cartItem.product.id === data.get('id')) {
        return state.set('data', data.merge(cartItem.product))
      }

      return state
    },
    [deleteCartItem.SUCCESS]: (
      state,
      { data: { cartItem }, additionalData },
    ) => {
      if (
        !cartItem &&
        state.getIn(['data', 'id']) === additionalData.productId
      ) {
        return state.setIn(
          ['data', 'unitsOfMeasure'],
          resetProductUnitsOfMeasureInCart(
            state.getIn(['data', 'unitsOfMeasure']),
          ),
        )
      }

      return state
    },
  },
  initialState,
)

export default (state, action) => {
  const newState = detailsReducer(state, action)
  return cartHandlers(newState, action)
}
