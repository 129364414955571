export const ALERT_SIZE = {
  NORMAL: 'big',
  SMALL: 'small',
}

export const ALERT_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
}
