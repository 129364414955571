import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { AlternateBtn, BUTTON_SIZE } from 'components/Button'
import { POSITION_ERRORS } from 'containers/Orders/consts'
import {
  findProductUnitOfMeasure,
  formatAmount,
} from 'components/Product/utils'
import { cartProductByIdSelector } from 'containers/Cart/selectors'
import ProductLicense from 'components/Product/ProductLicense'

import messages from '../../messages'

const CtaBtn = ({
  product,
  unitOfMeasureObj,
  onShiftDelivery,
  isElastic,
  errorId,
  onDelete,
  onGetReplacements,
  quantityAdded,
  quantityMissing,
  isDisabled,
  closestDDFormatted,
}) => {
  const cartProduct = useSelector(cartProductByIdSelector(product.id))
  const hasElasticStockAdded = useMemo(
    () => {
      if (!cartProduct || !isElastic) return false

      const inCartUnitOfMeasureObj = findProductUnitOfMeasure(
        cartProduct.product,
        unitOfMeasureObj.unitOfMeasure,
      )
      return (
        inCartUnitOfMeasureObj.inCartQuantity >= quantityAdded + quantityMissing
      )
    },
    [cartProduct, unitOfMeasureObj, quantityAdded, quantityMissing, isElastic],
  )

  const amountMissingFormatted = formatAmount(
    unitOfMeasureObj,
    quantityAdded + quantityMissing,
  )

  if (product.licenseMissing) {
    return <ProductLicense {...{ product }} />
  }

  if (errorId === POSITION_ERRORS.ALCOHOL_LICENSE) {
    return (
      <AlternateBtn nowrap size={BUTTON_SIZE.SMALL} onClick={onDelete}>
        <FormattedMessage {...messages.removeFromCart} />
      </AlternateBtn>
    )
  }

  if (isElastic) {
    return (
      <AlternateBtn
        nowrap
        size={BUTTON_SIZE.SMALL}
        disabled={isDisabled || hasElasticStockAdded}
        onClick={onShiftDelivery}
      >
        {hasElasticStockAdded ? (
          <FormattedMessage
            {...messages.chosenDelivery}
            values={{ date: closestDDFormatted }}
          />
        ) : (
          <FormattedMessage
            {...messages.addWithDeliveryOnDate}
            values={{
              amount: amountMissingFormatted,
              date: closestDDFormatted,
            }}
          />
        )}
      </AlternateBtn>
    )
  }

  return (
    <AlternateBtn nowrap size={BUTTON_SIZE.SMALL} onClick={onGetReplacements}>
      <FormattedMessage {...messages.replacementButton} />
    </AlternateBtn>
  )
}

export default CtaBtn
