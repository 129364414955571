import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import ActionLink, {
  ACTION_TYPE,
} from 'containers/App/LoggedInLayout/Footer/Contacts/ActionLink'

import messages from './messages'
import { ContactBox, ContactTitle, PhoneContainer } from './styles'

const PanelBox = ({ titleValue, phone, email }) => (
  <ContactBox>
    <ContactTitle>
      <FormattedMessage
        {...messages.department}
        values={{ value: titleValue }}
      />
    </ContactTitle>
    <PhoneContainer>
      <ActionLink
        suppressSpacingTop
        value={phone}
        actionType={ACTION_TYPE.PHONE}
      />
    </PhoneContainer>
    <ActionLink
      suppressSpacingTop
      value={email}
      actionType={ACTION_TYPE.EMAIL}
    />
  </ContactBox>
)

PanelBox.propTypes = {
  titleValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  phone: PropTypes.string,
  email: PropTypes.string,
}

export default PanelBox
