import { put, select } from 'redux-saga/effects'
import { some } from 'lodash'

import { isAutomatic } from 'views/Templates/utils'
import { TEMPLATES_ERRORS, DEFAULT_SORT_BY_TYPES } from '../consts'

export const itemNotExistInTemplate = errors =>
  some(errors, {
    code: TEMPLATES_ERRORS.ITEM_NOT_EXISTS,
  })

export const makeProductUpdater = ({ itemSelector, actions }) =>
  function* updateProductsList({ productId, ...rest }) {
    const item = yield select(itemSelector(productId))
    if (item) {
      yield put(actions.update(rest, { id: productId }))
    }
  }

export const getDefaultSorting = templateType => {
  const { SYSTEM, USER } = DEFAULT_SORT_BY_TYPES

  return isAutomatic(templateType) ? SYSTEM : USER
}
