import React from 'react'

import {
  ArrowLongLeft as ArrowLeft,
  ArrowLongRight as ArrowRight,
} from 'components/Icons'

import theme from 'theme'

import CarouselTracker from './CarouselTracker'
import { ControlsButton, ControlsContainer } from './styles'

const DefaultCarouselControls = ({
  handlePrev,
  handleNext,
  numberOfPages,
  selectedPageIndex,
}) => {
  const isFirstPage = selectedPageIndex === 0
  const isLastPage = selectedPageIndex + 1 === numberOfPages

  return (
    <ControlsContainer>
      <ControlsButton
        disabled={isFirstPage}
        onClick={handlePrev}
        data-test-id="prev-page-btn"
      >
        <ArrowLeft
          color={isFirstPage ? theme.colors.lightGrey7 : theme.colors.gray5}
        />
      </ControlsButton>
      <CarouselTracker
        slideCount={numberOfPages}
        selectedSlide={selectedPageIndex}
      />
      <ControlsButton
        disabled={isLastPage}
        onClick={handleNext}
        data-test-id="next-page-btn"
      >
        <ArrowRight
          color={isLastPage ? theme.colors.lightGrey7 : theme.colors.gray5}
        />
      </ControlsButton>
    </ControlsContainer>
  )
}

export default DefaultCarouselControls
