import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Checkbox from 'components/Input/Checkbox'
import { TextAreaV2 } from 'components/Fields/styles'
import { OTHER_REASON_KEY, REASON_KEYS, INPUT_MAX_LENGTH } from './consts'

import messages from './messages'
import {
  OuterContainer,
  ReasonsListContainer,
  ReasonOptionContainer,
  ReasonOptionText,
  ReasonOptionCheckbox,
  InnerContainer,
  OtherReasonInputCounter,
} from './styles'

const CancelReasonsList = ({
  setSelectedReasons,
  selectedReasons,
  otherReason,
  setOtherReason,
}) => {
  const { formatMessage } = useIntl()
  const [isInputOpen, setInputOpen] = useState(false)

  const handleOptionToggle = reasonKey => {
    const isReasonSelected = selectedReasons.includes(reasonKey)

    if (reasonKey === OTHER_REASON_KEY) {
      setOtherReason('')
      setInputOpen(prevState => !prevState)
    }

    setSelectedReasons(
      prevState =>
        isReasonSelected
          ? prevState.filter(el => el !== reasonKey)
          : [...prevState, reasonKey],
    )
  }

  return (
    <OuterContainer>
      <ReasonsListContainer $hasBottomMargin={!isInputOpen}>
        {REASON_KEYS.map(reasonKey => (
          <ReasonOptionContainer key={reasonKey}>
            <InnerContainer onClick={() => handleOptionToggle(reasonKey)}>
              <Checkbox
                onClick={ev => {
                  ev.stopPropagation()
                }}
                Wrapper={ReasonOptionCheckbox}
                checked={selectedReasons.includes(reasonKey)}
                readOnly
              />
              <ReasonOptionText>
                {formatMessage(messages[reasonKey])}
              </ReasonOptionText>
            </InnerContainer>
            {reasonKey === OTHER_REASON_KEY &&
              isInputOpen && (
                <OtherReasonInputCounter>{`${
                  otherReason.length
                } / ${INPUT_MAX_LENGTH}`}</OtherReasonInputCounter>
              )}
          </ReasonOptionContainer>
        ))}
      </ReasonsListContainer>
      {isInputOpen && (
        <TextAreaV2
          rows={4}
          maxLength={INPUT_MAX_LENGTH}
          placeholder={formatMessage(messages.inputPlaceholder)}
          onChange={e => setOtherReason(e.target.value)}
          value={otherReason}
        />
      )}
    </OuterContainer>
  )
}

export default CancelReasonsList
