import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getSpacing } from 'components/helpers/theme-selectors'
import { SmallIcon, IconWrapper, ICONS } from 'components/Icon'
import { P } from 'components/Text'
import { ALERT_SIZE } from './consts'
import AlertBox from './AlertBox'
import { getIcon } from './utils'

const AlertWrapper = styled(AlertBox)`
  ${P} {
    flex-grow: 1;
  }
  ${IconWrapper}:first-child {
    margin-right: ${props => getSpacing(props).xs};
  }
  ${IconWrapper}:last-child {
    cursor: pointer;
    margin-inline-end: 0;
    margin: 0;
  }
`

const SmallAlert = ({ type, children, onClick }) => (
  <AlertWrapper type={type} size={ALERT_SIZE.SMALL}>
    <SmallIcon type={getIcon(type)} />
    <P>{children}</P>
    {onClick && <SmallIcon onClick={onClick} type={ICONS.X} />}
  </AlertWrapper>
)

SmallAlert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
}

export default SmallAlert
