import theme, { paymentTheme } from 'theme'

import {
  PAYMENT_COLORS,
  PAY_SCENARIO,
  BELOW_LIMIT,
} from 'containers/Orders/consts'

const { green, yellow, red } = paymentTheme

export const USER_CONTROLS_STYLES = {
  [BELOW_LIMIT]: {
    contentColor: theme.colors.secondary,
    bgColor: theme.colors.greenLight3,
    hoverBgColor: theme.colors.greenLight4,
    variantName: BELOW_LIMIT,
  },
  [PAYMENT_COLORS.GREEN]: {
    contentColor: green.text,
    bgColor: green.bg,
    hoverBgColor: green.bgHover,
    variantName: PAYMENT_COLORS.GREEN,
  },
  [PAYMENT_COLORS.YELLOW]: {
    contentColor: yellow.text,
    bgColor: yellow.bg,
    hoverBgColor: yellow.bgHover,
    variantName: PAYMENT_COLORS.YELLOW,
  },
  [PAYMENT_COLORS.RED]: {
    contentColor: red.text,
    bgColor: red.bg,
    hoverBgColor: red.bgHover,
    isAlert: true,
    variantName: PAYMENT_COLORS.RED,
  },
}

export const setPaymentStylesConfig = (
  scenario,
  paymentColorName,
  isBelowLimit,
) => {
  const belowLimitStyles = USER_CONTROLS_STYLES[BELOW_LIMIT]
  const greenStyles = USER_CONTROLS_STYLES[PAYMENT_COLORS.GREEN]
  const yellowStyles = USER_CONTROLS_STYLES[PAYMENT_COLORS.YELLOW]
  const redStyles = USER_CONTROLS_STYLES[PAYMENT_COLORS.RED]

  const isRedScenario = scenario === PAY_SCENARIO.RED

  // default config
  const config = {
    userBtnStyles: {},
    cartBtnStyles: greenStyles,
  }

  if (isBelowLimit) {
    config.cartBtnStyles = belowLimitStyles
  }

  if (paymentColorName === PAYMENT_COLORS.RED) {
    config.userBtnStyles = redStyles
    config.cartBtnStyles = redStyles
  }

  if (paymentColorName === PAYMENT_COLORS.YELLOW) {
    config.userBtnStyles = { ...yellowStyles, isAlert: !isRedScenario }
    config.cartBtnStyles = isRedScenario
      ? { ...redStyles, isAlert: false }
      : yellowStyles
  }

  if (
    paymentColorName === PAYMENT_COLORS.GREEN &&
    scenario !== PAY_SCENARIO.GREEN
  ) {
    config.cartBtnStyles = isRedScenario
      ? { ...redStyles, isAlert: false }
      : yellowStyles
  }

  return config
}
