import React from 'react'

const Honey = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="group-[.active-btn]:fill-white"
      d="M10.2313 6.14848C10.6822 5.69754 11.4133 5.69755 11.8642 6.14848L17.8518 12.1361C18.3027 12.5871 18.3027 13.3182 17.8518 13.7691C17.4009 14.22 16.6698 14.22 16.2188 13.7691L10.2313 7.78147C9.78034 7.33054 9.78034 6.59942 10.2313 6.14848Z"
      fill="#229944"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M9.14274 10.503C9.59367 10.0521 10.3248 10.0521 10.7757 10.503L13.4973 13.2247C13.9483 13.6756 13.9483 14.4067 13.4973 14.8577C13.0464 15.3086 12.3153 15.3086 11.8644 14.8577L9.14274 12.136C8.69181 11.6851 8.69181 10.954 9.14274 10.503Z"
      fill="#229944"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M12.9526 3.42675C13.4035 2.97581 14.1346 2.97581 14.5856 3.42675L20.5731 9.41438C21.024 9.86531 21.024 10.5964 20.5731 11.0474C20.1222 11.4983 19.3911 11.4983 18.9401 11.0474L12.9526 5.05974C12.5017 4.6088 12.5017 3.87769 12.9526 3.42675Z"
      fill="#229944"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M10.4075 13.4008C10.8584 13.8517 11.3228 14.3161 11.3228 14.3161L3.97141 21.6618C3.52048 22.1127 2.78937 22.1127 2.33844 21.6618C1.88751 21.2109 1.88751 20.4797 2.33844 20.0288L9.68184 12.6751C9.68184 12.6751 9.95655 12.9498 10.4075 13.4008Z"
      fill="#229944"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M17.3075 2.3382C17.7584 1.88727 18.4895 1.88727 18.9404 2.3382L21.662 5.05985C22.113 5.51079 22.113 6.24191 21.662 6.69284C21.2111 7.14378 20.48 7.14378 20.0291 6.69284L17.3075 3.97119C16.8565 3.52026 16.8565 2.78914 17.3075 2.3382Z"
      fill="#229944"
    />
    <path
      d="M22.0002 19.5507C22.0002 20.9034 20.881 22 19.5002 22C18.1195 22 17.0002 20.9034 17.0002 19.5507C17.0002 17.8536 18.2004 16.1565 18.9377 15.2741C19.243 14.9086 19.7574 14.9086 20.0628 15.2741C20.8001 16.1565 22.0002 17.8536 22.0002 19.5507Z"
      fill="#99D0AC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9651 18.0668C19.1042 18.2107 19.1035 18.4434 18.9637 18.5864C18.8558 18.6967 18.7505 19.0043 18.7967 19.3823C18.8406 19.7415 19.009 20.0515 19.3019 20.2013C19.4785 20.2915 19.5506 20.5121 19.4628 20.6938C19.375 20.8755 19.1607 20.9496 18.984 20.8593C18.4144 20.5681 18.1518 19.9964 18.088 19.4739C18.0264 18.9701 18.1368 18.3959 18.4601 18.0653C18.5999 17.9223 18.8261 17.9229 18.9651 18.0668Z"
      fill="white"
    />
  </svg>
)

export default Honey
