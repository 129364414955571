import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Box, Flex } from '@rebass/grid'
import { FormattedMessage } from 'react-intl'

import theme from 'theme'
import { userDataSelector } from 'containers/UserInfo/selectors'

import { Location as LocationIcon } from 'components/Icons'
import { SecondaryTextPrimary, TinyTextFaded } from 'components/Text'
import { formatDeliveryAddress } from 'containers/Cart/utils'

import messages from 'views/Cart/messages'

const DeliveryAddress = styled(TinyTextFaded)`
  line-height: 1.33;
  margin-top: ${theme.spacing.xxs};
`

const Address = () => {
  const {
    customerName,
    deliveryAddress,
    deliveryPostcode,
    deliveryCity,
    deliveryCountry,
  } = useSelector(userDataSelector)

  return (
    <Flex alignItems="center">
      <Box pr={24}>
        <LocationIcon />
      </Box>

      <Box>
        <SecondaryTextPrimary bold>{customerName}</SecondaryTextPrimary>
        <DeliveryAddress>
          {deliveryAddress ? (
            formatDeliveryAddress({
              deliveryAddress,
              deliveryPostcode,
              deliveryCity,
              deliveryCountry,
            })
          ) : (
            <FormattedMessage {...messages.lackOfDeliveryAddress} />
          )}
        </DeliveryAddress>
      </Box>
    </Flex>
  )
}

export default Address
