import React from 'react'
import PropTypes from 'prop-types'

import { ICONS } from 'components/Icon'

import { Container, InfoIcon, Message } from './styles'

const SuccessBox = ({ message, isWarning }) => {
  if (isWarning) {
    return (
      <Container isWarning>
        <InfoIcon isWarning type={ICONS.ALERT} />
        <Message isWarning>{message}</Message>
      </Container>
    )
  }

  return (
    <Container>
      <InfoIcon type={ICONS.SUCCESS} />
      <Message>{message}</Message>
    </Container>
  )
}

SuccessBox.propTypes = {
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isWarning: PropTypes.bool,
}

export default SuccessBox
