import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { formatPrice, formatPricePerUnit } from 'utils'
import {
  getUnitDef,
  isLoweredPrice,
  parseAmount,
} from 'components/Product/utils'
import { FloatingPriceWrapper } from 'components/FloatingPrice'
import {
  ArrowFullDown as ArrowDownIcon,
  CircleCheckMark,
} from 'components/Icons'
import messages from 'views/ProductDetails/messages'
import { SecondaryBtnV2 } from 'components/Button/styles'
import { BaseTextV2, TinyTextV2Secondary } from 'components/Text'

import {
  UnitSelectionContainer,
  CheckmarkContainer,
  NetPrice,
  NetPriceContainer,
  PriceRow,
  PricesContainer,
  ProductUnitContainer,
  ProductUnitsContainer,
  SecondaryText,
  UnitText,
  OldPrice,
  PackagingInfoContainer,
} from './styles'

const UnitSelection = ({
  product,
  selectedUnit,
  setSelectedUnit,
  isNotificationBtnShown,
  scrollToReplacements,
  hasReplacements,
  trackSeeReplacementClick,
}) => {
  const { packaging } = product
  const isSecondaryReplacementsBtnShown =
    isNotificationBtnShown && hasReplacements

  const { formatMessage } = useIntl()

  const isLoweredPriceProduct = isLoweredPrice(product.promotion)
  const {
    priceNet: rewardPriceNet,
    priceGross: rewardPriceGross,
    priceNetPerUnit: rewardPricePerUnit,
  } = product.promotion?.reward || {}

  const formatPerUnitLabel = unit => {
    const pricePerUnit = isLoweredPriceProduct
      ? rewardPricePerUnit
      : product.pricePerUnit

    if (unit.multiplier !== 1) {
      return formatMessage(messages.soldFor, {
        amount: parseAmount(unit.multiplier),
        unit: unit.unitOfMeasureDescription,
      })
    }

    return formatPricePerUnit(pricePerUnit, product.unitForPricePerUnit)
  }

  if (!product.active || !product.unitsOfMeasure.length) {
    return null
  }

  return (
    <UnitSelectionContainer>
      <ProductUnitsContainer>
        {product.unitsOfMeasure.map(unit => (
          <ProductUnitContainer
            data-test-id={`product-unit-${unit.unitOfMeasure}`}
            onClick={() => setSelectedUnit(unit.unitOfMeasure)}
            key={unit.unitOfMeasure}
          >
            <CheckmarkContainer>
              <CircleCheckMark
                bgColor={
                  unit.unitOfMeasure === selectedUnit
                    ? theme.colors.secondary
                    : theme.colors.white
                }
              />
            </CheckmarkContainer>
            <PricesContainer>
              <PriceRow>
                <NetPriceContainer>
                  <FloatingPriceWrapper
                    hasFloatingPrice={product.floatingPrice}
                    iconMargin={8}
                    hasTooltip
                  >
                    <NetPrice>
                      {formatPrice(
                        isLoweredPriceProduct ? rewardPriceNet : unit.priceNet,
                      )}
                    </NetPrice>
                  </FloatingPriceWrapper>
                  {isLoweredPriceProduct && (
                    <OldPrice>{formatPrice(unit.priceNet)}</OldPrice>
                  )}
                </NetPriceContainer>
                <UnitText>
                  {getUnitDef({
                    unitQuantity: unit.quantity,
                    unitDesc: unit.unitOfMeasureDescription,
                    baseUnitDesc: product.baseUnitOfMeasureDescription,
                  })}
                </UnitText>
              </PriceRow>
              <PriceRow>
                <SecondaryText>
                  {formatMessage(messages.priceGross, {
                    price: formatPrice(
                      isLoweredPriceProduct
                        ? rewardPriceGross
                        : unit.priceGross,
                    ),
                  })}
                </SecondaryText>
                <SecondaryText data-test-id="per-unit-label">
                  {formatPerUnitLabel(unit)}
                </SecondaryText>
              </PriceRow>
            </PricesContainer>
          </ProductUnitContainer>
        ))}
        {isSecondaryReplacementsBtnShown && (
          <SecondaryBtnV2
            onClick={() => {
              trackSeeReplacementClick()
              scrollToReplacements()
            }}
          >
            <ArrowDownIcon color={theme.colors.gray5} />
            <BaseTextV2 $fontWeight={theme.fontWeights.medium}>
              {formatMessage(messages.replacementsBtn)}
            </BaseTextV2>
          </SecondaryBtnV2>
        )}
      </ProductUnitsContainer>
      {packaging && (
        <PackagingInfoContainer>
          <TinyTextV2Secondary>
            {formatMessage(messages.packaging, {
              amount: parseAmount(packaging.quantity),
              unit: packaging.baseDescription,
            })}
          </TinyTextV2Secondary>
        </PackagingInfoContainer>
      )}
    </UnitSelectionContainer>
  )
}

export default UnitSelection
