import * as React from 'react'

import theme from 'theme'

const Blocked = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <circle cx={12} cy={12} r={8} stroke={color} strokeWidth={2} />
    <path d="M9 15l6-6" stroke={color} strokeWidth={2} strokeLinecap="round" />
  </svg>
)

export default Blocked
