import styled from 'styled-components'
import { ModalHeader, Link, BaseTextV2 } from 'components/Text'
import theme from 'theme'
import ActionWrapper from 'components/ActionWrapper'
import { APP_BREAKPOINTS } from 'consts'
import { ErrorTextContainer } from 'components/Fields/ValidationWrapper/styles'
import AlertBox from 'components/Alert/AlertBox'

export const LoginTitle = styled(ModalHeader)`
  padding: 0 ${theme.spacing.xs};
`

export const LoginLink = styled(Link)`
  color: ${theme.colors.secondary};
  margin-left: ${theme.spacing.xs};
  font-size: ${theme.textSizes.sm};
  font-weight: ${theme.fontWeights.medium};
  line-height: 24px;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.ss};
`

export const SessionToast = styled(AlertBox)`
  display: flex;
  background-color: ${theme.colors.infoBlue2};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  gap: ${theme.spacing.xs};
`

export const ToastText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  color: ${theme.colors.white};
`

export const LoginErrorContainer = styled(ErrorTextContainer)`
  margin-top: -6px;
`

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.ss};
`
export const LoginActionsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.lg} 0px;
  gap: ${theme.spacing.base};
`

export const ContactsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing.xs};
  gap: ${theme.spacing.sm};
`

export const LoginWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: start;
  padding: ${theme.spacing.sm};
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: ${theme.spacing.md};
  }
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    justify-content: start;
    align-items: stretch;
    padding: 40px;
  }
`

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 460px;
  padding: ${theme.spacing.md} ${theme.spacing.base};
  z-index: ${theme.zIndex.base + 1};
  border-radius: 16px;
  background: ${theme.colors.white};
  overflow: auto;
  margin: auto 0;
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    margin: 0;
  }
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.24);
`

export const ImgBgContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${theme.zIndex.base};
  background-color: ${theme.colors.semiTranspBg.grayLight};
`

export const ImgBg = styled.div`
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-image: url(${p => p.img});
`

export const RegisterRow = styled.div`
  display: flex;
  padding: 0 ${theme.spacing.xs};
`

export const MessageLink = styled(ActionWrapper)`
  align-self: start;
  text-decoration: none;
`

export const ContactLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
`
