import { sortBy } from 'lodash'
import {
  get,
  getOr,
  compose,
  snakeCase,
  find,
  propEq,
  filter as _filter,
  lt,
  isEmpty,
  mapValues,
  isArray,
  zipObject,
} from 'lodash/fp'

import {
  FILTERS_TYPES,
  FILTER_ITEMS,
  FILTER_TYPES_LIST,
  SELECT_TYPE,
  PRODUCT_FEATURE_ICON,
  CUISINE_ICON,
} from 'components/Filters/consts'
import { ProductFeaturePlaceholderIcon } from 'components/Icons/Labels'
import { CuisinePlaceholderIcon } from 'components/Icons/Cuisines'
import React from 'react'

export const sortedFilterItems = (selectedItems, filter) =>
  sortBy(filter, el => !([el.value] in selectedItems))

export const findFilter = (array, value) => array.find(i => i.value === value)

export const getFilterItemCount = (array, value) => {
  const filter = findFilter(array, value)

  return filter?.count
}

const getAvailableFilter = (availableFilters, filterName) =>
  compose(
    _filter(
      compose(
        lt(0),
        get('count'),
      ),
    ),
    getOr([], 'items'),
    find(propEq('filterField', `filter.${snakeCase(filterName)}`)),
  )(availableFilters)

export const getFilterWithAllOptions = (availableFilters, filterName) =>
  compose(
    getOr([], 'items'),
    find(propEq('filterField', `filter.${snakeCase(filterName)}`)),
  )(availableFilters)

const makeFiltersWithAllOption = (allFilterLabel, filters) => {
  const all = filters.find(propEq('label', allFilterLabel))
  const restFilters = filters.filter(({ label }) => label !== allFilterLabel)

  return [{ ...all, value: FILTER_ITEMS.ALL }, ...restFilters]
}

export const mapAvailableFilters = availableFilters => {
  const getFilter = filterName =>
    getAvailableFilter(availableFilters, filterName)

  const categories = getFilter(FILTERS_TYPES.CATEGORIES)

  const brands = getFilter(FILTERS_TYPES.BRANDS)
  const productFeatures = getFilterWithAllOptions(
    availableFilters,
    FILTERS_TYPES.PRODUCT_FEATURES,
  )
  const cuisineFeatures = getFilterWithAllOptions(
    availableFilters,
    FILTERS_TYPES.CUISINE_FEATURES,
  )

  const onStockFilter = getFilter(FILTERS_TYPES.ON_STOCK)
  const onStock = makeFiltersWithAllOption('filter.on_stock.all', onStockFilter)

  const promoFilter = getFilter(FILTERS_TYPES.PROMOTION)
  const promotion = makeFiltersWithAllOption(
    'filter.promotion.all',
    promoFilter,
  )

  const storageTypes = getFilter(FILTERS_TYPES.STORAGE_TYPES)
  const offerFeatures = getFilter(FILTERS_TYPES.OFFER_FEATURES)

  return {
    categories,
    brands,
    onStock,
    promotion,
    storageTypes,
    productFeatures,
    offerFeatures,
    cuisineFeatures,
  }
}

export const makeSelectedFiltersWithParams = (selectedFiltersParams = {}) =>
  mapValues(value => {
    if (isArray(value)) {
      return zipObject(value, Array(value.length).fill(true))
    }
    return { [value]: true }
  }, selectedFiltersParams)

export const makeSelectedFiltersParams = (
  availableFilters = [],
  selectedFilters = {},
) =>
  FILTER_TYPES_LIST.reduce((acc, filterField) => {
    if (
      isEmpty(selectedFilters[filterField]) ||
      selectedFilters[filterField][FILTER_ITEMS.ALL]
    ) {
      return acc
    }

    if (!availableFilters) return acc

    const filter = Object.keys(selectedFilters[filterField])
    const filterType = get(
      'type',
      find(
        { filterField: `filter.${snakeCase(filterField)}` },
        availableFilters,
      ),
    )

    return {
      ...acc,
      [filterField]: filterType === SELECT_TYPE.SINGLE ? filter[0] : filter,
    }
  }, {})

export const getAvailableFilterItems = (filter, map) =>
  filter.reduce((acc, item) => {
    if (map[item.value]) {
      return { ...acc, [item.value]: true }
    }
    return acc
  }, {})

const ICONS_CONFIG = {
  [FILTERS_TYPES.PRODUCT_FEATURES]: {
    icons: PRODUCT_FEATURE_ICON,
    placeholder: ProductFeaturePlaceholderIcon,
  },
  [FILTERS_TYPES.CUISINE_FEATURES]: {
    icons: CUISINE_ICON,
    placeholder: CuisinePlaceholderIcon,
  },
}

export const renderFilterIcon = (filterType, value, color) => {
  const { icons, placeholder } = ICONS_CONFIG[filterType]

  const Icon = icons[value] || placeholder
  return <Icon color={color} />
}
