import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import { useParams } from 'react-router-dom'

import Category from './Category'
import CategoryGroup from './CategoryGroup'
import CategorySubGroup from './CategorySubGroup'
import AllProducts from './AllProducts'

const Header = ({ productParams }) => {
  const params = useParams()
  // productParams are for pages where params are not in route (such as PDP)
  const validParams = _isEmpty(productParams) ? params : productParams

  const isPDP = !_isEmpty(productParams)

  if (validParams.subgroup) {
    return <CategorySubGroup id={validParams.subgroup} isPDP={isPDP} />
  }

  if (validParams.group) {
    return <CategoryGroup id={validParams.group} isPDP={isPDP} />
  }

  if (validParams.category) {
    return <Category id={validParams.category} isPDP={isPDP} />
  }

  return <AllProducts isPDP={isPDP} />
}

export default Header
