import React from 'react'

import { getTopHeaderOffset, scrollElementIntoView } from 'utils/dom'

import Date from '../../DateCard/Date'

import IncomingIndicator from './IncomingIndicator'
import NewIndicator from './NewIndicator'
import { Container, VerticalLine, DeliveryRoute, Indicator } from './styles'

const TimelineItem = ({
  date,
  isIncoming,
  isFirst,
  isLast,
  routes,
  stickyId,
}) => (
  <Container {...{ isIncoming }}>
    <Date
      value={date}
      onClick={() => {
        scrollElementIntoView(stickyId, -getTopHeaderOffset())
      }}
    />

    {routes.length > 1 &&
      routes.map(
        ({ routeId, routeName, isIncoming: isIncomingRoute }, index) => (
          <DeliveryRoute
            key={routeId}
            isFirst={index === 0}
            isLast={index === routes.length - 1}
            isTail={isLast && index === routes.length - 1}
          >
            {routeName}
            <Indicator>
              {isIncomingRoute ? <IncomingIndicator /> : <NewIndicator />}
            </Indicator>
          </DeliveryRoute>
        ),
      )}

    <VerticalLine shiftTop={isFirst} shiftBottom={isLast} />
  </Container>
)

export default TimelineItem
