import styled from 'styled-components'

import {
  getColors,
  getSpacing,
  getTextSizes,
} from 'components/helpers/theme-selectors'
import { Link } from 'components/Navigation'

export const NotFoundContent = styled.div`
  margin: ${props => getSpacing(props).md};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 64px 12px 55px;
  border: 1px solid rgba(221, 226, 229, 0.5);
`

export const NotFoundButton = styled(Link)`
  background: ${props => getColors(props).primary};
  color: ${props => getColors(props).white};
  font-weight: 600;
  font-size: ${props => getTextSizes(props).l};
  padding: 14px ${props => getSpacing(props).sm};
  min-width: 200px;
`

export const NotFoundWrapper = styled.div`
  width: 100%;
`
