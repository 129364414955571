import { createSelector } from 'reselect'
import { prop } from 'lodash/fp'

import { genericSelector } from 'utils/redux-utils'

import { INVOICES_REDUCER_NAME, PAY_PROCESSING_REDUCER_NAME } from '../consts'

export const payStatusSelectors = genericSelector([
  INVOICES_REDUCER_NAME,
  PAY_PROCESSING_REDUCER_NAME,
])

export const payStatusSelector = createSelector(
  payStatusSelectors.data,
  prop('status'),
)

export const payAmountSelector = createSelector(
  payStatusSelectors.data,
  prop('amount'),
)
