import styled from 'styled-components'

import { APP_BREAKPOINTS } from 'consts'

export const Svg = styled.svg`
  height: 28px;
  align-self: flex-start;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    height: 24px;
  }
`

export const SvgUnauthenticated = styled.svg`
  height: 36px;
  align-self: flex-start;
`
