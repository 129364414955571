import { useIntl } from 'react-intl'

import messages from './messages'

export const useCancelOrderModalTexts = isAffectedOrdersVariant => {
  const { formatMessage } = useIntl()

  const singleCancelationConfig = {
    headingText: 'title',
    topText: 'description',
    bottomText: null,
    leftBtnText: 'abortCancel',
    rightBtnText: 'confirmCancel',
  }

  const multipleCancelationConfig = {
    headingText: 'multipleTitle',
    topText: null,
    bottomText: 'multipleDescription',
    leftBtnText: 'abortCancel',
    rightBtnText: 'continueCancel',
  }

  const {
    headingText,
    topText,
    bottomText,
    leftBtnText,
    rightBtnText,
  } = isAffectedOrdersVariant
    ? multipleCancelationConfig
    : singleCancelationConfig

  return {
    headingText: formatMessage(messages[headingText]),
    topText: topText ? formatMessage(messages[topText]) : null,
    bottomText: bottomText ? formatMessage(messages[bottomText]) : null,
    leftBtnText: formatMessage(messages[leftBtnText]),
    rightBtnText: formatMessage(messages[rightBtnText]),
  }
}
