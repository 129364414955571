import React from 'react'

import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'consts'

import messages from '../messages'

import { EmptyCartWrapper, BlackHeader, EmptyButton } from './styledComponents'

const EmptyCart = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <EmptyCartWrapper>
      <BlackHeader data-test-id="empty_cart_header" bold>
        {formatMessage(messages.emptyCart)}
      </BlackHeader>

      <EmptyButton
        data-test-id="empty_cart_continue_shopping_main_btn"
        onClick={() => navigate(ROUTES.CATEGORIES)}
      >
        {formatMessage(messages.continueShopping)}
      </EmptyButton>
    </EmptyCartWrapper>
  )
}

export default EmptyCart
