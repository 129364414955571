import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { TinyLabel } from 'components/Text'
import theme from 'theme'

export const ProgressInfoContainer = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding: ${theme.spacing.xxs} 6px;
`

export const ThresholdFont = styled(TinyLabel)`
  color: ${theme.colors.greenDark};
  font-weight: ${theme.fontWeights.medium};
`
