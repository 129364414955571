import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

export const FooterNav = styled(Flex)`
  width: 100%;
  justify-content: right;
  padding: 17px 0;
  border-top: 1px solid ${theme.colors.lightGrey2};
`
