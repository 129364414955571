import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

export const PriceWrapper = styled(Flex)`
  justify-content: space-between;
  width: 188px;
  padding-left: ${theme.spacing.sm};
  padding-right: 56px;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: column;
    align-items: flex-end;
    justify-content: initial;
    padding-right: ${theme.spacing.sm};
    width: auto;
  }
`

export const ProductContainer = styled(Flex)`
  padding: 10px 0;
  ${p => !p.isFirst && `padding-top: 0`};
  ${p => p.isFirst && `border-top: 1px solid ${theme.colors.gray2};`};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    padding: ${theme.spacing.xs} 0;
    ${p => !p.isFirst && `padding-top: 0`};
  }
`

// prettier-ignore
export const ImgWrapper = styled(Flex)`
  padding: 0 ${theme.spacing.sm};
  align-self: flex-start;

  ${p => p.isSummary && `
    padding: 0;
    padding-right: ${theme.spacing.sm};

    @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
      padding-left: ${theme.spacing.sm};
      padding-right: ${theme.spacing.base};
    }
  `};
`
