import React from 'react'
import PropTypes from 'prop-types'

import { Container, InnerContainer, SectionTitle } from './styles'

const SectionColumn = ({ className, title, children }) => (
  <Container {...{ className }}>
    {!!title && <SectionTitle>{title}</SectionTitle>}

    <InnerContainer>{children}</InnerContainer>
  </Container>
)

SectionColumn.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default SectionColumn
