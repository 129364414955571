import styled, { css } from 'styled-components'

import theme from 'theme'

const fieldStyles = css`
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.sm};
  color: ${theme.colors.primary};
  line-height: 24px;
  background: ${theme.colors.lightGrey};
  border-radius: ${theme.borderRadius.base};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  outline: none;
  transition: 0.3s;
  :focus,
  :hover {
    background: ${theme.colors.lightGrey7};
  }
  ::placeholder {
    color: ${theme.colors.gray7};
  }
  ${props =>
    props.isError &&
    `
    color: ${theme.colors.redDarkText};
    background: ${theme.colors.redLight2};
    :focus, :hover {
      background: ${theme.colors.redLight2};
    }
    ::placeholder {
      color: ${theme.colors.redDarkText};
    }
  `};
`

export const LabelV2 = styled.label`
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.xs};
  color: ${theme.colors.primary};
  line-height: 20px;
  pointer-events: none;
`

export const InputV2 = styled.input`
  ${fieldStyles} height: 40px;
`

export const SelectV2 = styled.select`
  ${fieldStyles} height: 40px;
  border-right: ${theme.spacing.sm} solid transparent; // add padding to arrow

  option {
    background: ${theme.colors.lightGrey};
    color: ${theme.colors.primary};
  }

  // placeholder styles
  &:required:invalid {
    color: ${theme.colors.gray7};
  }
  option[value=''][disabled] {
    display: none;
  }
`

export const TextAreaV2 = styled.textarea`
  ${fieldStyles};
`
