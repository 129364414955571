import React from 'react'

const Cupcake = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="group-[.active-btn]:fill-white"
      d="M20 15.7001C19.8652 15.7095 19.7282 15.7144 19.5894 15.7144C18.8843 15.7144 18.2255 15.4541 17.6663 15.1571C17.272 14.9476 16.7704 14.9476 16.3761 15.1572C15.8172 15.4542 15.1588 15.7144 14.4538 15.7144C13.7488 15.7144 13.09 15.4541 12.5308 15.1571C12.1365 14.9476 11.6348 14.9476 11.2406 15.1572C10.6816 15.4542 10.0233 15.7144 9.3183 15.7144C8.61326 15.7144 7.95447 15.4541 7.39527 15.1571C7.00094 14.9476 6.49932 14.9476 6.10503 15.1572C5.5461 15.4542 4.88777 15.7144 4.18278 15.7144C3.76893 15.7144 3.3711 15.6712 3 15.5914L4.7552 20.276C4.90512 20.6991 5.50014 21 6.18714 21H16.8505C17.5375 21 18.1325 20.6991 18.2825 20.276L20 15.7001Z"
      fill="#229944"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2H11C7.82667 2 5.14653 4.11158 4.288 7.00631C2.45372 7.11591 1 8.63819 1 10.5C1 11.7527 1.65812 12.8517 2.64747 13.4701L3.42021 13.6361C3.65054 13.6856 3.90746 13.7145 4.18285 13.7145C4.41034 13.7145 4.72588 13.6253 5.1666 13.3911C6.14771 12.8697 7.35258 12.8698 8.33357 13.3909C8.77458 13.6251 9.09068 13.7145 9.31838 13.7145C9.54587 13.7145 9.86141 13.6253 10.3021 13.3911C11.2832 12.8697 12.4881 12.8698 13.4691 13.3909C13.9101 13.6251 14.2262 13.7145 14.4539 13.7145C14.6814 13.7145 14.9969 13.6253 15.4377 13.3911C16.4188 12.8697 17.6236 12.8698 18.6046 13.3909C19.0456 13.6251 19.3617 13.7145 19.5894 13.7145C19.6815 13.7145 19.7718 13.7113 19.8598 13.7051L19.9177 13.701C21.1444 13.1569 22 11.9284 22 10.5C22 8.63819 20.5463 7.11591 18.712 7.00632C17.8535 4.11158 15.1733 2 12 2Z"
      fill="#99D0AC"
    />
  </svg>
)

export default Cupcake
