import React from 'react'

import { FormattedMessage } from 'react-intl'

import { AlternateBtn, BUTTON_SIZE } from 'components/Button'
import ProductLicense from 'components/Product/ProductLicense'

import StarBtn from '../StarBtn'
import { ReplacementContent } from './styles'
import messages from './messages'

const OutOfStock = ({ onGetReplacements, productId, product, ...rest }) => (
  <ReplacementContent>
    <StarBtn {...rest} productId={productId} />

    {product.licenseMissing ? (
      <ProductLicense {...{ product }} />
    ) : (
      <AlternateBtn
        size={BUTTON_SIZE.SMALL}
        onClick={() => onGetReplacements({ productId })}
        data-test-id={`replacements_${productId}`}
      >
        <FormattedMessage {...messages.showReplacements} />
      </AlternateBtn>
    )}
  </ReplacementContent>
)

export default OutOfStock
