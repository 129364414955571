import React from 'react'
import { useSelector } from 'react-redux'
import { Flex } from '@rebass/grid'
import { FormattedMessage } from 'react-intl'

import theme from 'theme'

import { productDeliveryDatesByDateSelector } from 'containers/Cart/selectors'

import { RouteIcon } from 'components/Icons'
import { ICONS } from 'components/Icon'
import { TertiaryText } from 'components/Text'
import messages from 'views/Cart/messages'

import { Icon } from '../styledComponents'
import DeliveryRoutes from './DeliveryRoutes'

const RouteSection = ({
  routeChanging,
  toggleRouteChanging,
  activeProductIds,
  onlyIncoming,
  updateDeliveryDate,
  allProductIds,
  selectedDelivery: { deliveryDate, routeId, routeName } = {},
}) => {
  const routes = useSelector(
    productDeliveryDatesByDateSelector(activeProductIds, deliveryDate),
  )
  const hasRouteSelector = !onlyIncoming && routes.length > 1

  return (
    <Flex alignItems="center" width="100%">
      <Flex
        alignSelf="flex-start"
        ml={theme.spacing.xs}
        mr={theme.spacing.base}
      >
        <RouteIcon />
      </Flex>

      <Flex width="100%" flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={hasRouteSelector ? { cursor: 'pointer' } : {}}
          onClick={() => (hasRouteSelector ? toggleRouteChanging() : {})}
        >
          <TertiaryText>
            <FormattedMessage
              {...messages.selectedRouteInfo}
              values={{ value: routeName }}
            />
          </TertiaryText>

          {hasRouteSelector && (
            <Flex ml={theme.spacing.sm}>
              <Icon
                type={routeChanging ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                size={24}
              />
            </Flex>
          )}
        </Flex>

        {routeChanging && (
          <DeliveryRoutes
            {...{ routes }}
            selectedRouteId={routeId}
            routeChangeHandler={route => {
              updateDeliveryDate({
                deliveryDate,
                productIds: allProductIds,
                ...route,
              })
              toggleRouteChanging()
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default RouteSection
