import React from 'react'

import { CONTACT_TYPES } from 'containers/UserInfo/consts'
import messages from 'views/Profile/BottomSection/messages'
import { useIntl } from 'react-intl'
import { getValueByType } from './ContactRow/utils'
import ContactRow from './ContactRow'

const Contacts = ({ contacts = [] }) => {
  const { formatMessage } = useIntl()

  const supportContact = {
    title: formatMessage(messages.supportSubheader),
    contact: [
      {
        type: CONTACT_TYPES.NAME,
        value: formatMessage(messages.supportHeader),
      },
      {
        type: CONTACT_TYPES.EMAIL,
        value: formatMessage(messages.supportMail),
      },
      {
        type: CONTACT_TYPES.PHONE,
        value: formatMessage(messages.supportPhone),
      },
    ],
  }

  return [...contacts, supportContact].map(({ contact, title }, index) => {
    const key = getValueByType(contact, CONTACT_TYPES.NAME)

    return <ContactRow key={key || index} {...{ contact, title }} />
  })
}

export default Contacts
