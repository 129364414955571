import React from 'react'
import { useIntl } from 'react-intl'

import Documents from 'components/Documents'

import messages from './messages'

const PrivacyPolicy = () => {
  const { formatMessage } = useIntl()

  return (
    <Documents title={formatMessage(messages.title)} contentfulSlug="privacy" />
  )
}

export default PrivacyPolicy
