import React from 'react'

const CallCenter = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 10C4.45507 10 3 11.4551 3 13.25V13.75C3 15.5449 4.45507 17 6.25 17C7.2165 17 8 16.2165 8 15.25V11.75C8 10.7835 7.2165 10 6.25 10Z"
      stroke="#A6B3C0"
      strokeWidth="2"
    />
    <path
      d="M17.75 10C16.7835 10 16 10.7835 16 11.75V15.25C16 16.2165 16.7835 17 17.75 17C19.5449 17 21 15.5449 21 13.75V13.25C21 11.4551 19.5449 10 17.75 10Z"
      stroke="#A6B3C0"
      strokeWidth="2"
    />
    <path
      d="M6 11V10C6 6.68629 8.68629 4 12 4V4C15.3137 4 18 6.68629 18 10V11"
      stroke="#A6B3C0"
      strokeWidth="2"
    />
    <path
      d="M18 17L18 18C18 19.6569 16.6569 21 15 21L11 21"
      stroke="#A6B3C0"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default CallCenter
