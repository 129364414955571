export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ALTERNATE: 'alternate',
  ERROR: 'error',
  ERROR_ALTERNATE: 'error-alternate',
  WARNING_LIGHT: 'warning-light',
  LINK: 'link',
  BASIC: 'basic',
}

export const BUTTON_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  BIG: 'big',
  ICON: 'icon',
  BLOCK: 'block',
  SEARCH: 'search',
  BASIC: 'basic',
}

export const ICONS_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
  ONLY: 'only',
}
