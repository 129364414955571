import qs from 'qs'

export const getDeliveryDatesByProductsRequest = ({ productIds }) => {
  const queryParams = qs.stringify(
    {
      product_ids: productIds,
    },
    { arrayFormat: 'comma' },
  )

  return {
    url: `me/delivery_dates/by_products?${queryParams}`,
  }
}

export const getAllDeliveryDatesRequest = () => ({
  url: 'me/delivery_dates',
})

export const getUserDeliveriesRequest = () => ({
  url: 'me/deliveries',
})
