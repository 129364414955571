import React from 'react'

import Panel from 'components/Panel'

import { PanelTitle } from './styledComponents'

const PanelComponent = ({ title, children, ...rest }) => (
  <Panel {...rest}>
    {!!title && <PanelTitle my={16}>{title}</PanelTitle>}
    {children}
  </Panel>
)

export default PanelComponent
