import { takeLatest, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'

import { getAllPromoGroups } from './api'
import { getAllPromoGroupsActions } from './actions'

function* getAllPromoGroupsFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getAllPromoGroupsActions,
      request: getAllPromoGroups,
    })
  } catch (error) {
    console.log('Error getting/processing all promo groups', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(getAllPromoGroupsActions.DELTA, getAllPromoGroupsFlow),
]
