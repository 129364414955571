import React from 'react'
import PropTypes from 'prop-types'

import { BigAlert } from 'components/Alert'

import { NotificationWrapper } from './styles'

const Notification = ({ type, children, isMobile, ...rest }) => (
  <NotificationWrapper {...{ isMobile }} {...rest}>
    <BigAlert type={type}>{children}</BigAlert>
  </NotificationWrapper>
)

Notification.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.string,
}

export default Notification
