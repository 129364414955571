import { makeMessages, makeTranslation } from 'utils'

const messages = {
  chooseAddress: 'chooseAddress',
  changeAddress: 'changeAddress',
  askAtLogin: 'askAtLogin',
  searchPlaceholder: { id: 'loggedInLayout.searchPlaceholder' },
  chosenAddress: 'chosenAddress',
  recentlyChosenAddress: 'recentlyChosenAddress',
}

export default makeMessages(messages, makeTranslation('deliveryAddress'))
