import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { TinyTextV2 } from 'components/Text'

export const ContactContainer = styled(Flex)`
  margin-top: ${theme.spacing.sm};
  &:not(:last-child) {
    padding-bottom: ${theme.spacing.sm};
    border-bottom: 1px solid ${theme.colors.lightGrey2};
  }
`

export const DropdownWrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  border-radius: ${theme.borderRadius.base};
  padding: ${theme.spacing.xs};
  gap: ${theme.spacing.sm};
  :hover {
    background: ${theme.colors.lightGrey};
  }
`

export const ContactTitleWrapper = styled(Flex)`
  justify-content: space-between;
`

export const ContactTitleAndIcons = styled(Flex)`
  cursor: pointer;
  justify-content: space-between;
`

export const ContactTextContainer = styled(Flex)`
  flex-direction: column;
`

export const ContactLink = styled.a`
  padding: 8px;
  border-radius: 8px;
  :hover {
    background: ${theme.colors.lightGrey7};
  }
  &:not(:last-child) {
    margin-right: 8px;
  }
`

export const ContactTitle = styled(TinyTextV2)`
  color: ${theme.colors.lightGrey4};
`

export const ContactDetails = styled(Flex)`
  flex-direction: column;
  margin-left: 56px;
`

export const ContactTitleContainer = styled(Flex)`
  align-items: center;
  gap: ${theme.spacing.sm};
`

export const ContactTitleIconContainer = styled(Flex)`
  padding: ${theme.spacing.xs};
`
