import qs from 'qs'

export const COOKIE_PERMISSIONS = {
  STATISTICAL: 'C0007',
}

export const getCookiesObject = () =>
  document.cookie.split('; ').reduce((res, part) => {
    const index = part.indexOf('=')
    const key = part.substring(0, index)
    const value = part.substring(index + 1)
    return {
      ...res,
      [key]: value,
    }
  }, {})

export const getCookiePermissions = () => {
  const cookies = getCookiesObject()
  if (cookies.OptanonConsent) {
    const optanonConsent = qs.parse(cookies.OptanonConsent)
    return optanonConsent.groups.split(',').reduce((res, group) => {
      const [key, value] = group.split(':')
      return {
        ...res,
        [key]: !!Number(value),
      }
    }, {})
  }
  return {}
}

export const isCookiePermitted = group => {
  const permissions = getCookiePermissions()
  return permissions[group]
}

export const getGAClientId = () => {
  const cookies = getCookiesObject()
  if (cookies && isCookiePermitted(COOKIE_PERMISSIONS.STATISTICAL)) {
    // eslint-disable-next-line no-underscore-dangle
    return cookies._ga?.substring(6)
  }
  return null
}
