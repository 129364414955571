import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { SecondaryBtnV2 } from 'components/Button/styles'

export const Container = styled.div`
  background-color: ${theme.colors.semiTranspBg.grayLight};
  border-radius: 16px;
  padding: ${theme.spacing.sm};
  gap: ${theme.spacing.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: ${theme.spacing.base};
    gap: ${theme.spacing.base};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
`

export const DocumentBtn = styled(SecondaryBtnV2)`
  flex: 0;
  display: flex;
  gap: ${theme.spacing.xs};

  background-color: ${theme.colors.semiTranspBg.grayLight};
  &:hover {
    background-color: ${theme.colors.semiTranspBg.grayLight2};
  }
`
