import { createActionCreator } from 'utils/redux-utils'

export const NOTIFICATIONS_SIGNAL = 'NOTIFICATIONS_DISPLAY'
export const NOTIFICATIONS_OPEN = 'NOTIFICATIONS_OPEN'
export const NOTIFICATIONS_HIDE = 'NOTIFICATIONS_HIDE'

export const openNotification = createActionCreator(NOTIFICATIONS_OPEN)
export const hideNotification = createActionCreator(NOTIFICATIONS_HIDE)

export const notifyAction = createActionCreator(NOTIFICATIONS_SIGNAL)
