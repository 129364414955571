import React from 'react'

const CircleAlert = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4733 2.02549C8.88865 1.36911 7.10817 1.36911 5.52353 2.02549C3.9389 2.68186 2.67991 3.94085 2.02353 5.52549C1.36716 7.11012 1.36716 8.8906 2.02353 10.4752C2.67991 12.0599 3.9389 13.3189 5.52353 13.9752C7.10817 14.6316 8.88865 14.6316 10.4733 13.9752C12.0579 13.3189 13.3169 12.0599 13.9733 10.4752C14.6297 8.8906 14.6297 7.11012 13.9733 5.52549C13.3169 3.94085 12.0579 2.68186 10.4733 2.02549ZM6.99841 4.50036C6.99841 3.94808 7.44612 3.50036 7.99841 3.50036C8.55069 3.50036 8.99841 3.94808 8.99841 4.50036V8.50036C8.99841 9.05264 8.55069 9.50036 7.99841 9.50036C7.44612 9.50036 6.99841 9.05264 6.99841 8.50036V4.50036ZM6.99841 11.5004C6.99841 10.9481 7.44612 10.5004 7.99841 10.5004C8.55069 10.5004 8.99841 10.9481 8.99841 11.5004C8.99841 12.0526 8.55069 12.5004 7.99841 12.5004C7.44612 12.5004 6.99841 12.0526 6.99841 11.5004Z"
      fill="#E45A4F"
    />
  </svg>
)

export default CircleAlert
