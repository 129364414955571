import React from 'react'

import theme from 'theme'

const Edit = ({ color = theme.colors.primary }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 14.4142V17H7.58579C7.851 17 8.10536 16.8946 8.29289 16.7071L17.5 7.5C18.3284 6.67157 18.3284 5.32843 17.5 4.5C16.6716 3.67157 15.3284 3.67157 14.5 4.5L5.29289 13.7071C5.10536 13.8946 5 14.149 5 14.4142Z"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M13 6L16 9" stroke={color} strokeWidth="2" />
    <path d="M5 21H19" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export default Edit
