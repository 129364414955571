const MISSING_CART_ITEMS_EVENTS = {
  SHOWN: 'cartIntermediateScreen',
  PRODUCT_MISSING_ALCOHOL_LICENSE: 'cisMissingAlcLicenseAcc',
  PRODUCT_UNAVAILABLE: 'cisProductUnavailable',
  PRODUCT_REDUCED_QUANTITY: 'cisProductReducedQuantity',
  PRODUCT_RESIGN: 'cisProductResign',
  PRODUCT_RETURN_AND_PLACE: 'returnAndPlace',
}

export const trackMissingItemsScreen = ({ customerNo, storeId }) => {
  window.dataLayer.push({
    event: MISSING_CART_ITEMS_EVENTS.SHOWN,
    customer_no: customerNo,
    store_id: String(storeId),
  })
}

export const trackMissingAlcoholLicense = ({ productId }) => {
  window.dataLayer.push({
    event: MISSING_CART_ITEMS_EVENTS.PRODUCT_MISSING_ALCOHOL_LICENSE,
    product_id: productId,
  })
}

export const trackAProductUnsufficientQuantity = ({
  productId,
  availableQuantity,
  acceptedQuantity,
}) => {
  if (!availableQuantity) {
    window.dataLayer.push({
      event: MISSING_CART_ITEMS_EVENTS.PRODUCT_UNAVAILABLE,
      product_id: productId,
    })
    return
  }

  if (availableQuantity === acceptedQuantity) {
    window.dataLayer.push({
      event: MISSING_CART_ITEMS_EVENTS.PRODUCT_REDUCED_QUANTITY,
      product_id: productId,
    })
    return
  }

  window.dataLayer.push({
    event: MISSING_CART_ITEMS_EVENTS.PRODUCT_RESIGN,
    product_id: productId,
  })
}

export const trackResolveMissingItems = ({ storeId, customerNo }) => {
  window.dataLayer.push({
    event: MISSING_CART_ITEMS_EVENTS.PRODUCT_RETURN_AND_PLACE,
    store_id: String(storeId),
    cusomer_no: customerNo,
  })
}
