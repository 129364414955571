import React from 'react'
import { Box, Flex } from '@rebass/grid'
import { FormattedMessage } from 'react-intl'

import { formatPrice } from 'utils'
import { useOfMaxWidth } from 'hooks'

import { APP_BREAKPOINTS } from 'consts'
import { getProductGenericInfo, getUnitDef } from '../../utils'

import {
  NetPrice,
  Prices,
  VariantRowWrapper,
  MobileLeftBottomWrapper,
} from '../styledComponents'
import ActionButtons from '../ActionButtons'
import InfoColumn from '../InfoColumn'
import TemplateStar from '../TemplateStar'
import messages from '../messages'

const VariantRow = ({
  unit,
  product,
  productId,
  addRemoveCallback,
  maxValue,
  disableInput,
  unitsOfMeasure,
  onStarClick,
}) => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const { unitOfMeasureObj: variantUnitObj = {} } = getProductGenericInfo({
    product,
    unitOfMeasure: unit.unitOfMeasure,
  })
  const unitDesc = variantUnitObj.unitOfMeasureDescription
  const unitInTemplate = variantUnitObj.inTemplate
  const baseUnitDesc = product.baseUnitOfMeasureDescription

  const starRendered = (
    <TemplateStar
      {...{
        product,
        productId,
        unitsOfMeasure,
        onStarClick,
      }}
      active={unitInTemplate}
      unitOfMeasure={variantUnitObj.unitOfMeasure}
    />
  )
  const packageRendered = (
    <InfoColumn
      title={<FormattedMessage {...messages.packaging} />}
      value={getUnitDef({
        unitQuantity: variantUnitObj.quantity,
        unitDesc,
        baseUnitDesc,
      })}
    />
  )
  const actionButtonsRendered = (
    <ActionButtons
      product={product}
      productId={productId}
      addRemoveCallback={addRemoveCallback}
      maxValue={maxValue}
      disableInput={disableInput}
      quantity={unit.quantity}
      unitOfMeasureObj={variantUnitObj}
      inTemplate={unitInTemplate}
      isVariant
    />
  )
  const priceRendered = (
    <Prices>
      <NetPrice isVariant>{formatPrice(unit.totalPriceNet)}</NetPrice>
    </Prices>
  )

  return (
    <VariantRowWrapper>
      {isMobile ? (
        <Flex width="100%">
          <MobileLeftBottomWrapper>{starRendered}</MobileLeftBottomWrapper>

          <Flex flexDirection="column" flex={1} ml="8px">
            <Flex width="100%" mb={15} ml="-8px">
              {actionButtonsRendered}
            </Flex>

            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              {packageRendered}
              {priceRendered}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <>
          <Flex alignItems="center">
            <Box ml="-40px">{starRendered}</Box>

            {packageRendered}

            <InfoColumn
              title={<FormattedMessage {...messages.netPrice} />}
              value={formatPrice(unit.priceNet)}
            />

            <InfoColumn
              title={<FormattedMessage {...messages.articleNo} />}
              value={productId}
            />
          </Flex>

          <Flex alignItems="center" height="100%">
            {actionButtonsRendered}

            {priceRendered}
          </Flex>
        </>
      )}
    </VariantRowWrapper>
  )
}

export default VariantRow
