import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import TextTooltip from 'components/Tooltip/TextTooltip'
import {
  PRODUCT_EVENTS,
  useProductAvailabilityTracking,
} from 'services/analytics'
import {
  ArrowFullDown as ArrowDownIcon,
  Bell as BellIcon,
  MailV2 as MailIcon,
  ScenarioLocked as LockedIcon,
} from 'components/Icons'
import theme from 'theme'

import CartQuantityControls from './CartQuantityControls'
import EmailNotificationModal from './EmailNotificationModal'
import { InfoBtn, ProductUnavailableBtn, BtnText } from './styles'
import messages from './messages'

const ProductCartControls = ({
  product,
  unitData,
  isUnitDisabled,
  isNotificationBtnShown,
  hasReplacements,
  scrollToReplacements,
  trackSeeReplacementClick,
}) => {
  const { formatMessage } = useIntl()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const trackProductAvailability = useProductAvailabilityTracking({
    product,
    unitData,
  })

  const handleOpenModalClick = () => {
    setIsModalOpen(true)
    trackProductAvailability(PRODUCT_EVENTS.OPEN_NOTIFICATION_MODAL_CLICKED)
  }

  if (isNotificationBtnShown) {
    return (
      <>
        <EmailNotificationModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          product={product}
          unitData={unitData}
        />

        <InfoBtn onClick={handleOpenModalClick}>
          {product.notificationEmail ? (
            <MailIcon color={theme.colors.white} />
          ) : (
            <BellIcon color={theme.colors.white} />
          )}
          <BtnText $color={theme.colors.white}>
            {formatMessage(
              product.notificationEmail
                ? messages.openChangeEmailModalBtn
                : messages.openSetupModalBtn,
            )}
          </BtnText>
        </InfoBtn>
      </>
    )
  }

  if (product.licenseMissing) {
    return (
      <TextTooltip
        content={formatMessage(messages.missingLicenseTooltipText)}
        matchTriggerWidth
        targetProps={{ style: { display: 'flex', flex: 1 } }}
      >
        <InfoBtn>
          <LockedIcon color={theme.colors.white} />
          <BtnText $color={theme.colors.white}>
            {formatMessage(messages.missingLicense, product)}
          </BtnText>
        </InfoBtn>
      </TextTooltip>
    )
  }

  if (isUnitDisabled) {
    if (hasReplacements) {
      return (
        <InfoBtn
          onClick={() => {
            scrollToReplacements()
            trackSeeReplacementClick()
          }}
          data-test-id={`replacements_${product.id}`}
        >
          <ArrowDownIcon color={theme.colors.white} />
          <BtnText $color={theme.colors.white}>
            {formatMessage(messages.replacements)}
          </BtnText>
        </InfoBtn>
      )
    }

    return (
      <ProductUnavailableBtn>
        <BtnText $color={theme.colors.infoBlue2}>
          {formatMessage(messages.productUnavailable)}
        </BtnText>
      </ProductUnavailableBtn>
    )
  }

  return <CartQuantityControls unitData={unitData} product={product} />
}

export default ProductCartControls
