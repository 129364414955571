import { find } from 'lodash/fp'

import { CATALOG_EVENTS } from './consts'
import {
  getFormattedProductParams,
  normalizePrice,
  trackEcommerceEvent,
  formatItemListParams,
} from './utils'

export const trackViewItemList = ({
  products,
  itemListName,
  itemListId,
  itemModelId,
}) => {
  if (!products.length) return

  const itemListParams = formatItemListParams(itemListId, itemListName)

  const items = products.map((product, index) => {
    const { unitsOfMeasure, baseUnitOfMeasure, unitOfMeasure } = product
    const unitOfMeasureObj = find(
      { unitOfMeasure: unitOfMeasure || baseUnitOfMeasure },
      unitsOfMeasure,
    )
    const value = normalizePrice(unitOfMeasureObj.priceGross)
    const productParams = getFormattedProductParams(
      product,
      unitOfMeasureObj,
      itemModelId,
    )

    return {
      ...productParams,
      ...itemListParams,
      price: value,
      index: index + 1,
    }
  })

  trackEcommerceEvent(CATALOG_EVENTS.VIEW_ITEM_LIST, {
    ...itemListParams,
    items,
    total_count: items.length,
  })
}

export const trackSearchProductsEvent = ({ event, query, userData }) =>
  trackEcommerceEvent(event, {
    search_term: query,
    customer_no: userData.customerNo,
    store_id: String(userData.storeId),
  })
