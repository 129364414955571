import React from 'react'

import { BUTTON_TYPES } from './consts'
import Button, { buttonProps } from './Button'

export { default as Button } from './Button'
export { default as PrimaryBtn } from './PrimaryBtn'
export { default as AddProductsButton } from './AddProductsButton'
export { default as SecondaryBtn } from './SecondaryBtn'
export { default as AlternateBtn } from './AlternateBtn'
export { default as ErrorAlternateBtn } from './ErrorAlternateBtn'
export { default as LinkBtn, LinkBtnV2 } from './LinkBtn'
export { BasicButtonV2, BasicButtonV2Grey } from './styles'

export { BUTTON_SIZE, BUTTON_TYPES } from './consts'

export const ErrorBtn = props => <Button {...props} type={BUTTON_TYPES.ERROR} />
ErrorBtn.propTypes = buttonProps

export const WarningLightBtn = props => (
  <Button {...props} type={BUTTON_TYPES.WARNING_LIGHT} />
)
WarningLightBtn.propTypes = buttonProps
