import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { get } from 'lodash/fp'

import { getColors, getSpacing } from 'components/helpers/theme-selectors'
import { ICON_SIZE } from 'components/Icon'

import StarBtn from '../../StarBtn'

export const StarContainer = styled(Flex)`
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-right: ${p => !p.withoutMargin && getSpacing(p).sm};

  button {
    ${p => !p.active && `color: ${getColors(p).gray3}`};
    padding: 0;
    height: 24px;
    width: 24px;
  }
`

const TemplateStar = ({
  product,
  productId,
  unitsOfMeasure,
  onStarClick,
  unitOfMeasure,
  active = get('0.inTemplate')(unitsOfMeasure),
  withoutMargin,
}) => (
  <StarContainer {...{ active, withoutMargin }}>
    <StarBtn
      {...{ active, productId }}
      iconSize={ICON_SIZE.NORMAL}
      onStarClick={() => {
        onStarClick({
          product,
          unitOfMeasure,
        })
      }}
      isTransparent
      noBorder
    />
  </StarContainer>
)

export default TemplateStar
