import { createSelector } from 'reselect'
import { makeDataSelector, makeIsFetchingSelector } from 'utils/redux-utils'
import { uniqBy } from 'lodash'

import { isRewardPromoType } from 'containers/Promotions/utils'
import {
  PROMOS_REDUCER_NAME,
  PROMO_GROUP_PRODUCTS_REDUCER_NAME,
} from '../consts'

export const selectPromoGroupProductsData = makeDataSelector([
  PROMOS_REDUCER_NAME,
  PROMO_GROUP_PRODUCTS_REDUCER_NAME,
])

export const isPromoGroupProductsFetching = makeIsFetchingSelector([
  PROMOS_REDUCER_NAME,
  PROMO_GROUP_PRODUCTS_REDUCER_NAME,
])

export const selectStandardPromotions = createSelector(
  selectPromoGroupProductsData,
  data =>
    uniqBy(data?.products.map(product => product.promotion), 'id').filter(
      isRewardPromoType,
    ),
)

export const selectPromoGroupPrizesIds = createSelector(
  selectStandardPromotions,
  data => data.map(({ productId }) => productId),
)
