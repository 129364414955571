import React from 'react'

const Polish = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.66528" cy="9.33331" r="2" fill="#8E9BA8" />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="matrix(-1 0 0 1 13.9987 7.33331)"
      fill="#8E9BA8"
    />
    <ellipse
      cx="6"
      cy="3"
      rx="6"
      ry="3"
      transform="matrix(-1 0 0 1 13.332 5.33331)"
      fill="#8E9BA8"
    />
    <circle cx="2.66528" cy="6.66669" r="2" fill="#8E9BA8" />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="matrix(-1 0 0 1 13.9987 4.66669)"
      fill="#8E9BA8"
    />
    <circle cx="4.66528" cy="4.66669" r="2" fill="#8E9BA8" />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="matrix(-1 0 0 1 11.9987 2.66669)"
      fill="#8E9BA8"
    />
    <circle cx="7.33203" cy="4" r="2" fill="#8E9BA8" />
    <circle cx="4" cy="11.9999" r="2" fill="#A6B3C0" />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="matrix(-1 0 0 1 15.3334 9.99994)"
      fill="#A6B3C0"
    />
    <ellipse
      cx="6"
      cy="3"
      rx="6"
      ry="3"
      transform="matrix(-1 0 0 1 14.6666 7.99994)"
      fill="#A6B3C0"
    />
    <circle cx="4" cy="9.33325" r="2" fill="#A6B3C0" />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="matrix(-1 0 0 1 15.3334 7.33325)"
      fill="#A6B3C0"
    />
    <circle cx="6" cy="7.33325" r="2" fill="#A6B3C0" />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="matrix(-1 0 0 1 13.3334 5.33325)"
      fill="#A6B3C0"
    />
    <circle cx="8.66663" cy="6.66663" r="2" fill="#A6B3C0" />
    <path
      d="M5.33337 10.6669C5.33337 6.66663 12 6.66657 12 10.6669"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Polish
