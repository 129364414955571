import React from 'react'
import PropTypes from 'prop-types'
import { withIcon } from 'components/Icon'
import { BasicButton } from './styles'
import { BUTTON_SIZE, BUTTON_TYPES } from './consts'

const Button = props =>
  props.iconType && props.iconPosition ? (
    withIcon(props)(BasicButton)
  ) : (
    <BasicButton {...props}>{props.children}</BasicButton>
  )

export const buttonProps = {
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.values(BUTTON_SIZE)),
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  iconType: PropTypes.string,
  iconPosition: PropTypes.string,
}

export default Button
