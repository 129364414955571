import React from 'react'

const ProductTileSkeleton = () => (
  <svg
    data-test-id="product-skeleton"
    width="184"
    height="444"
    viewBox="0 0 184 444"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.857178" width="182.857" height="444" rx="8" fill="white" />
    <rect
      x="8.85718"
      y="32"
      width="166.857"
      height="130"
      rx="12"
      fill="url(#paint0_linear_21729_529431)"
    />
    <rect
      x="8.85718"
      y="182"
      width="150"
      height="20"
      rx="6"
      fill="url(#paint1_linear_21729_529431)"
    />
    <rect
      x="8.85718"
      y="214"
      width="95"
      height="20"
      rx="6"
      fill="url(#paint2_linear_21729_529431)"
    />
    <rect
      x="8.85718"
      y="396"
      width="166.857"
      height="40"
      rx="8"
      fill="url(#paint3_linear_21729_529431)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_21729_529431"
        x1="175.714"
        y1="97"
        x2="8.85718"
        y2="97"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F8FA" />
        <stop offset="1" stopColor="#E4E9EE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_21729_529431"
        x1="158.857"
        y1="192"
        x2="8.85718"
        y2="192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F8FA" />
        <stop offset="1" stopColor="#E4E9EE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_21729_529431"
        x1="103.857"
        y1="224"
        x2="8.85718"
        y2="224"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F8FA" />
        <stop offset="1" stopColor="#E4E9EE" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_21729_529431"
        x1="175.714"
        y1="416"
        x2="8.85718"
        y2="416"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F8FA" />
        <stop offset="1" stopColor="#E4E9EE" />
      </linearGradient>
    </defs>
  </svg>
)

const ProductTileMultipleSkeleton = ({ length = 10 }) =>
  new Array(length)
    .fill(undefined)
    // eslint-disable-next-line react/no-array-index-key
    .map((_, index) => <ProductTileSkeleton key={index} />)

export { ProductTileMultipleSkeleton }
