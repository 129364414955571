import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useIntl } from 'react-intl'
import parse from 'html-react-parser'

import ModalV2 from 'components/Icons/ModalV2'
import PromoGroup from 'components/Product/PromoGroup'
import { trackPromoTileClicked, ITEM_LIST_NAMES } from 'services/analytics'
import { ProductBox } from 'components/Product'
import theme from 'theme'
import { breakPointTablet } from 'components/Layout'

import messages from './messages'
import {
  PromoTileContainer,
  TextContainer,
  ImgContainer,
  BrowseButton,
  PromoTagText,
  PromoTileTitle,
  PromoTileDescription,
  BrowseText,
  ProductContainer,
  Img,
  LinkWrapper,
} from './styles'

const PromoTile = ({
  promoGroup,
  onClickEvent,
  bgColor = theme.colors.greenLight5,
  hoverBgColor = theme.colors.semiTranspBg.greenLight2,
  txtColor = theme.colors.greenDark,
  btnColor = theme.colors.secondary,
  BtnIcon = ModalV2,
  redirectRoute,
  ...props
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${breakPointTablet})` })

  const { formatMessage } = useIntl()

  const {
    title,
    shortDescription,
    id,
    picture: { urlMobile, urlWeb },
    product,
    tag,
  } = promoGroup

  const trackEvent = () => {
    if (onClickEvent) {
      onClickEvent()
    } else {
      trackPromoTileClicked(title)
    }
  }

  const promoTile = (
    <PromoTileContainer
      onClick={trackEvent}
      $isProductTile={!!product}
      $bgColor={bgColor}
      $hoverBgColor={hoverBgColor}
    >
      <TextContainer $isProductTile={!!product} $txtColor={txtColor}>
        {tag && <PromoTagText>{tag}</PromoTagText>}
        <PromoTileTitle>{title}</PromoTileTitle>
        <PromoTileDescription>
          {!!shortDescription && parse(shortDescription)}
        </PromoTileDescription>
      </TextContainer>
      {!product && (
        <ImgContainer>
          <Img src={isMobile ? urlMobile : urlWeb} />
          <BrowseButton $bgColor={btnColor}>
            <BrowseText>
              {formatMessage(
                redirectRoute ? messages.browse : messages.seePromo,
              )}
            </BrowseText>
            <BtnIcon />
          </BrowseButton>
        </ImgContainer>
      )}
      {product && (
        <ProductContainer>
          <ProductBox
            isPromoGroupModal={false}
            itemListId={id}
            itemListName={ITEM_LIST_NAMES.PROMO_TILE}
            additionalEventParams={{
              promo_group_id: id,
              promo_group_name: title,
            }}
            {...product}
            {...{ product }}
          />
        </ProductContainer>
      )}
    </PromoTileContainer>
  )

  if (redirectRoute) {
    return (
      <LinkWrapper to={redirectRoute} {...props}>
        {promoTile}
      </LinkWrapper>
    )
  }

  if (!product) {
    return (
      <PromoGroup groupId={id} showModal {...props}>
        {promoTile}
      </PromoGroup>
    )
  }

  return promoTile
}

export default PromoTile
