import styled from 'styled-components'

import { getSpacing } from 'components/helpers/theme-selectors'

import { InputWrapper } from 'components/Input/styles'

export const OptionsHeader = styled.div`
  margin: ${props => getSpacing(props).base} 0;
`

export const Option = styled.div`
  margin-bottom: ${props => getSpacing(props).base};

  &:last-of-type {
    margin-bottom: ${props => getSpacing(props).ss};
  }
`

export const Options = styled.div`
  margin-bottom: ${props => getSpacing(props).base};

  ${InputWrapper} {
    margin-top: 0;
  }
`

export const OptionsInnderContainer = styled.div``
