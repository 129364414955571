export const InputTypes = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
}

export const BORDER_TYPES = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  VISITED: 'visited',
}

export const ICON_STYLES = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
}
