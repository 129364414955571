import React from 'react'

import RecommendedBox from 'components/RecommendedBox'
import { RECOMMENDATION_PAGE_NAMES } from 'components/RecommendedBox/consts'
import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'

const Recommendations = () => (
  <RecommendedBox
    itemListId={ITEM_LIST_IDS.RECOMMENDATION_MAIN}
    itemListName={ITEM_LIST_NAMES.RECOMMENDATION_MAIN}
    pageName={RECOMMENDATION_PAGE_NAMES.DASHBOARD}
  />
)

export default Recommendations
