import _ from 'lodash/fp'

import { mapKeysDeep, mapValuesDeep } from 'utils'

/* eslint-disable no-param-reassign */
export const convertToKeysObject = (obj, objToConvertTo = {}, current) => {
  Object.keys(obj).forEach(key => {
    const currentLookupKey = current ? `${current}.${key}` : key
    if (typeof obj[key] === 'object') {
      objToConvertTo[key] = {
        [key]: currentLookupKey,
      }
      convertToKeysObject(obj[key], objToConvertTo[key], currentLookupKey)
    } else {
      objToConvertTo[key] = currentLookupKey
    }
  })

  return objToConvertTo
}

const mapKeysToUpperSnake = mapKeysDeep((v, k) =>
  _.flow(
    _.snakeCase,
    _.toUpper,
  )(k),
)
export const getNestedKeysMap = (obj, objToConvertTo) =>
  _.flow(
    mapKeysToUpperSnake,
    mapValuesDeep(
      _.flow(
        _.split('.'),
        _.map(_.camelCase),
        _.join('.'),
      ),
    ),
  )(convertToKeysObject(obj, objToConvertTo))

export const getApiPathsMap = (obj, objToConvertTo) =>
  _.flow(
    mapKeysToUpperSnake,
    mapValuesDeep(
      _.flow(
        _.split('.'),
        _.map(_.snakeCase),
        _.join('/'),
      ),
    ),
  )(convertToKeysObject(obj, objToConvertTo))

export const filterOutNotNil = obj =>
  _.reduce.convert({ cap: false })((acc, v, k) => {
    if (_.isPlainObject(v)) return { ...acc, [k]: filterOutNotNil(v) }
    if (_.isNil(v)) return { ...acc, [k]: v }

    return acc
  }, {})(obj)

export const filterOutNil = ({ src, differenceObj, currentKey }) =>
  _.reduce.convert({ cap: false })((acc, v, k) => {
    const current = currentKey ? `${currentKey}.k` : k

    if (_.hasIn(current)(differenceObj) || _.isNil(v)) {
      return acc
    }

    if (_.isPlainObject(v))
      return {
        ...acc,
        [k]: filterOutNil({
          src: v,
          differenceObj,
          currentKey: current,
        }),
      }

    return { ...acc, [k]: v }
  }, {})(src)
