import React from 'react'
import theme from 'theme'

const FloatingPriceSmall = ({
  color = theme.colors.white,
  bgColor = theme.colors.infoBlue2,
}) => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none">
    <rect width="11" height="14" rx="2" fill={bgColor} />
    <rect x="5" y="3" width="1" height="5" fill={color} />
    <rect
      x="8"
      y="5"
      width="1"
      height="5"
      transform="rotate(90 8 5)"
      fill={color}
    />
    <rect
      x="8"
      y="10"
      width="1"
      height="5"
      transform="rotate(90 8 10)"
      fill={color}
    />
  </svg>
)

export default FloatingPriceSmall
