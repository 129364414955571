import styled from 'styled-components'

import { Link } from 'components/Text'
import theme from 'theme'

export const GroupButton = styled(Link)`
  cursor: pointer;
  width: fit-content;
  background-color: rgba(115, 145, 185, 0.1);
  user-select: none;
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.sm};
  font-weight: ${theme.fontWeights.regular};
  border-radius: 8px;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  white-space: nowrap;
  :hover {
    background-color: ${theme.colors.darkGrey};
  }
`
export const GroupButtonsScrollContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  padding: 0 ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.base};
`

export const InnerWrapper = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
`
