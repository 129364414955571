const PROMO_GROUP = {
  OPENED: 'PromoGroupModalOpened',
  PRODUCT_DETAILS_CLICK: 'PromoGroupModalProductDetailsClick',
}

export const trackPromoGroupOpened = groupId => {
  window.dataLayer.push({
    event: PROMO_GROUP.OPENED,
    group_id: groupId,
  })
}
