import styled from 'styled-components'

import theme from 'theme'

export const BreadCrumbsContainer = styled.div`
  margin-bottom: ${theme.spacing.md};

  @media screen and (min-width: ${theme.breakpoint.tabletS}) {
    margin-bottom: 48px;
  }
`
