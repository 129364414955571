import { useIntl } from 'react-intl'

import messages from './messages'

export const formatMultiline = items => items.filter(item => !!item).join('\n')

export const useAddressFormatting = ({
  name,
  address,
  postcode,
  city,
  billingVatNo,
}) => {
  const { formatMessage } = useIntl()

  let items = { name, address, city }

  if (postcode) {
    items = {
      ...items,
      city: `${postcode} ${city}`,
    }
  }

  if (billingVatNo) {
    items = {
      ...items,
      billingVatNo: formatMessage(messages.nip, { value: billingVatNo }),
    }
  }

  return formatMultiline(Object.values(items))
}
