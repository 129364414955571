import * as yup from 'yup'

import validationMessages from 'utils/validators/messages'

export default yup.object().shape({
  reasonComment: yup
    .string()
    .trim()
    .required(validationMessages.required),
})
