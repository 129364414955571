import styled, { css } from 'styled-components'
import Modal from 'react-modal'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { PORTAL_MODAL_VARIANTS } from './consts'

import ModalWrapper from '../ModalWrapper'

const portalModalStyling = {
  [PORTAL_MODAL_VARIANTS.MIDDLE]: {
    modal: css`
      border-radius: ${theme.borderRadius.md};
      margin: 0 ${theme.spacing.sm};
    `,
    overlay: css`
      align-items: center;
    `,
  },
  [PORTAL_MODAL_VARIANTS.BOTTOM]: {
    modal: css`
      border-radius: ${theme.borderRadius.md} ${theme.borderRadius.md} 0 0;
      margin: 0;
      max-width: 100%;
    `,
    overlay: css`
      align-items: end;
    `,
  },
}

export const PortalModalOverlay = styled(ModalWrapper)`
  z-index: ${theme.zIndex.modalOverlay + 1};
  background: rgb(166 179 192 / 30%) !important;
  display: flex;

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    align-items: center;
  }

  ${props => portalModalStyling[props.$variant].overlay};
`

export const ModalStyled = styled(Modal)`
  inset: auto;
  border: 0;
  box-shadow: 0px 12px 32px 0px #0c345929;
  padding: ${theme.spacing.sm};
  background-color: #fff;
  max-width: 480px;

  :focus-visible {
    outline: none;
  }

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    max-width: 510px;
    width: 100%;
    margin: 0 ${theme.spacing.sm};
    border-radius: 16px;
    padding: ${theme.spacing.md};
  }

  ${props => portalModalStyling[props.$variant].modal};
`
