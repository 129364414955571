import styled from 'styled-components'

import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'
import { H1v2 } from 'components/Text'
import { CarouselOverflowContainer } from 'components/Carousel/styles'
import { BasicButtonV2Grey } from 'components/Button'

export const OverflowContainer = styled(CarouselOverflowContainer)`
  border-radius: 16px;
`

export const ScrollContainer = styled.div`
  display: flex;
  a {
    display: flex;
    flex: 0 0 100%;
  }
`

export const CampaignsTileContainer = styled.div`
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  position: relative;
  gap: ${theme.spacing.sm};
  overflow: hidden;
`

export const CampaignSliderImg = styled.img`
  object-fit: cover;
  object-position: left bottom;
  width: 100%;
  max-height: 500px;
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    height: 440px;
    object-fit: cover;
  }
`

export const CampaignPlaceholderContainer = styled.div`
  padding: ${theme.spacing.base} ${theme.spacing.base} 40px;
  position: absolute;
  border-radius: 16px;
  left: ${theme.spacing.base};
  bottom: ${theme.spacing.base};
  width: 396px;
  background-color: ${theme.colors.secondary};
`

export const CampaignPlaceholderText = styled(H1v2)`
  color: ${theme.colors.white};
`

export const CarouselButtonsWrapper = styled.div`
  position: absolute;
  bottom: ${theme.spacing.base};
  right: ${theme.spacing.base};
  display: flex;
  gap: ${theme.spacing.xs};
`

export const CarouselButtonLeft = styled(BasicButtonV2Grey)`
  padding: ${theme.spacing.xs};
  width: 40px;
  height: 40px;
`

export const CarouselButtonRight = styled(CarouselButtonLeft)`
  transform: rotate(180deg);
`
