import React from 'react'
import { FormattedMessage } from 'react-intl'
import { prop } from 'lodash/fp'

import { SecondaryTextFaded } from 'components/Text'

import { InputWrapper, TextareaBasic, TextareaLabel } from '../styles'
import WithVisitCheck from '../WithVisitCheck'

import { Counter, HintError } from './styles'

const Textarea = ({
  disabled,
  width,
  checked,
  label,
  value,
  id,
  meta = {},
  maxLength = 250,
  input,
  ...props
}) => (
  <InputWrapper width={width} disabled={disabled}>
    <TextareaBasic
      checked={checked}
      value={value}
      id={id}
      {...props}
      maxLength={maxLength}
      disabled={disabled}
      {...input || {}}
    />
    {label && (
      <TextareaLabel {...props} disabled={disabled} htmlFor={id}>
        {label}
      </TextareaLabel>
    )}

    <Counter>
      {meta.touched && meta.error ? (
        <HintError isError>
          <FormattedMessage {...meta.error} />
        </HintError>
      ) : (
        <div />
      )}
      <SecondaryTextFaded>
        {(prop('value', input) || value || '').length} / {maxLength}
      </SecondaryTextFaded>
    </Counter>
  </InputWrapper>
)

export default WithVisitCheck(Textarea)
