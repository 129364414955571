import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { values } from 'lodash/fp'

import { selectHasOverpayments } from 'containers/Invoices/selectors'
import { LinkBtnV2 } from 'components/Button'
import { selectedInvoicesTotalSelector } from 'containers/Invoices/List/selectors'
import { invoicesToPayActions } from 'containers/Invoices/List/actions'
import { formatPrice } from 'utils'
import { initPaymentActions } from 'containers/Invoices/PaySelection/actions'
import { PageContent } from 'containers/App/LoggedInLayout/styles'
import { Columns } from 'layouts'
import { trackOverpaymentsClose } from 'services/analytics/finances'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { usePaySummaryTransition } from 'views/Invoices/utils'
import { Invoice as InvoiceIcon } from 'components/Icons'
import { BaseTextV2 } from 'components/Text'
import theme from 'theme'

import messages from '../../../messages'
import SettleModal from '../../../SettleModal'

import {
  PaySummaryContainer,
  PaySummaryInnerContainer,
  TotalToPay,
  SmallText,
  InnerCountContainer,
  ActionsContainer,
  ToPayContainer,
  ButtonsContainer,
  PayBtn,
  OverpaymentsBtn,
  CountContainer,
  InvoiceIconContainer,
} from './styles'

const PaySummary = ({ selectedInvoicesMap }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const total = useSelector(selectedInvoicesTotalSelector)
  const hasOverpayments = useSelector(selectHasOverpayments)
  const [isOverpaymentsSettlingOpen, setIsOverpaymentsSettlingOpen] = useState(
    false,
  )
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)
  const userData = useSelector(userDataSelector)

  const selectedInvoicesList = useMemo(() => values(selectedInvoicesMap), [
    selectedInvoicesMap,
  ])

  const transitions = usePaySummaryTransition(!!selectedInvoicesList.length)

  const invoicesCount = formatMessage(messages.paySelectedCount, {
    count: selectedInvoicesList.length,
  })
  const invoicesPlural = formatMessage(messages.paySelectedCountPlural, {
    number: selectedInvoicesList.length,
  })

  return transitions(
    (styled, item) =>
      item && (
        <PaySummaryContainer style={styled} data-test-id="pay-summary">
          {isOverpaymentsSettlingOpen && (
            <SettleModal
              invoicesToPayMap={selectedInvoicesMap}
              invoicesToPayAmount={total}
              onClose={() => {
                setIsOverpaymentsSettlingOpen(false)
                trackOverpaymentsClose({ storeId: userData.storeId })
              }}
            />
          )}

          <PageContent>
            <Columns col={12}>
              <PaySummaryInnerContainer>
                <CountContainer>
                  <InvoiceIconContainer>
                    <InvoiceIcon />
                  </InvoiceIconContainer>
                  <InnerCountContainer>
                    <SmallText
                    >{`${invoicesCount} ${invoicesPlural}`}</SmallText>
                    <LinkBtnV2
                      onClick={() =>
                        dispatch(invoicesToPayActions.clearSelected())
                      }
                      data-test-id="pay-summary-clear-btn"
                    >
                      {formatMessage(messages.paySelectClear)}
                    </LinkBtnV2>
                  </InnerCountContainer>
                </CountContainer>
                <ActionsContainer>
                  <ToPayContainer>
                    <SmallText>{formatMessage(messages.toPayLabel)}</SmallText>
                    <TotalToPay>{formatPrice(total)}</TotalToPay>
                  </ToPayContainer>
                  <ButtonsContainer>
                    {hasOverpayments && (
                      <OverpaymentsBtn
                        onClick={() => {
                          setIsOverpaymentsSettlingOpen(true)
                        }}
                      >
                        <BaseTextV2 $fontWeight={theme.fontWeights.medium}>
                          {formatMessage(messages.payWithOverpayments)}
                        </BaseTextV2>
                      </OverpaymentsBtn>
                    )}
                    <PayBtn
                      onClick={() => {
                        setIsPaymentLoading(true)
                        dispatch(
                          initPaymentActions.delta(selectedInvoicesList, {
                            total,
                            callback: () => {
                              setIsPaymentLoading(false)
                            },
                          }),
                        )
                      }}
                      isLoading={isPaymentLoading}
                      data-test-id="pay-summary-pay-btn"
                    >
                      <BaseTextV2
                        $color={theme.colors.white}
                        $fontWeight={theme.fontWeights.medium}
                      >
                        {formatMessage(messages.pay)}
                      </BaseTextV2>
                    </PayBtn>
                  </ButtonsContainer>
                </ActionsContainer>
              </PaySummaryInnerContainer>
            </Columns>
          </PageContent>
        </PaySummaryContainer>
      ),
  )
}

export default PaySummary
