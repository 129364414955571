import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { TruckSpeed } from 'components/Icons'
import { makeDeliveryStickyId } from 'containers/Cart/utils'
import {
  useGetStorageValue,
  CONFIG_SCHEMA_KEYS,
  CONFIG_API_PATHS_MAP,
} from 'containers/Storage'
import { useUpdateStorageMutation } from 'services/api/rtkApi'
import { HintModal, HintOverlay } from 'components'

import { findRoute } from '../utils'
import { hintModalConfig } from '../hintModalConfig'

import DottedLine from './DottedLine'
import TimelineItem from './TimelineItem'
import { TimelineHeader, TimelineTitle, DottedLineContainer } from './styles'

const DeliveryDatesStack = ({
  dataGrouppedByDate,
  hideDeliveries,
  timelineTitle,
}) => {
  const [truckHintIndex, setTruckHintIndex] = useState(-1)
  const truckHintEnabled = useGetStorageValue(
    CONFIG_SCHEMA_KEYS.CART_GUIDE.TIMELINE,
  )
  const hasTimeline = dataGrouppedByDate.length > 1
  const [updateStorage] = useUpdateStorageMutation()

  return (
    <div>
      <TimelineHeader>
        {!hideDeliveries && (
          <Flex
            ml={theme.spacing.sm}
            width={38}
            minWidth={38}
            justifyContent="center"
          >
            <HintOverlay enabled={truckHintEnabled}>
              <TruckSpeed
                onClick={() => (truckHintEnabled ? setTruckHintIndex(0) : {})}
              />
            </HintOverlay>
          </Flex>
        )}

        <TimelineTitle>{timelineTitle}</TimelineTitle>

        {hasTimeline && (
          <DottedLineContainer>
            <DottedLine />
          </DottedLineContainer>
        )}
      </TimelineHeader>

      {hasTimeline && (
        <div>
          {dataGrouppedByDate.map(({ deliveryDate, routes, items }, index) => {
            const routeId = findRoute(routes)
            return (
              <TimelineItem
                key={deliveryDate}
                isFirst={index === 0}
                isLast={index === dataGrouppedByDate.length - 1}
                date={dayjs.utc(deliveryDate)}
                isIncoming={!items}
                stickyId={makeDeliveryStickyId(deliveryDate, routeId)}
                {...{ routes }}
              />
            )
          })}
        </div>
      )}

      {truckHintIndex > -1 && (
        <HintModal
          {...hintModalConfig[truckHintIndex]}
          onClose={() => {
            setTruckHintIndex(-1)
            updateStorage({
              path: CONFIG_API_PATHS_MAP.CART_GUIDE.TIMELINE,
              value: false,
            })
          }}
          nextClickHandler={() => setTruckHintIndex(truckHintIndex + 1)}
        />
      )}
    </div>
  )
}

export default DeliveryDatesStack
