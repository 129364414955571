export const makeSchemaValidator = schema => values => {
  try {
    schema.validateSync(values.toJSON(), { abortEarly: false })
    return null
  } catch (error) {
    return error.inner.reduce(
      (errors, err) => ({
        ...errors,
        [err.path]: err.message,
      }),
      {},
    )
  }
}

export const EMAIL_REGEX = new RegExp(
  '^([a-zA-Z0-9_\\-\\.\\+]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
)
