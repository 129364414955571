import React from 'react'

import theme from 'theme'

const Trash = ({ color = theme.colors.primary }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7H17V17C17 18.1046 16.1046 19 15 19H9C7.89543 19 7 18.1046 7 17V7Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M9 7H15V6C15 4.89543 14.1046 4 13 4H11C9.89543 4 9 4.89543 9 6V7Z"
      stroke={color}
      strokeWidth="2"
    />
    <path d="M5 7L19 7" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export default Trash
