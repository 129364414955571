import { createSelector } from 'reselect'
import { filter, getOr, path, sumBy, compose, values } from 'lodash/fp'
import {
  genericSelector,
  makePaginationSelector,
  makeImmutableStateSelector,
} from 'utils/redux-utils'

import {
  INVOICE_STATUS,
  INVOICES_REDUCER_NAME,
  INVOICES_LIST_REDUCER_NAME,
  INVOICES_TO_PAY_REDUCER_NAME,
  OVERPAYMENT_INVOICES_REDUCER_NAME,
} from '../consts'

export const invoicesSelectors = genericSelector([
  INVOICES_REDUCER_NAME,
  INVOICES_LIST_REDUCER_NAME,
])

export const invoicesSelector = createSelector(
  invoicesSelectors.data,
  getOr([], 'invoices'),
)

export const invoicesPaginationSelector = makePaginationSelector(
  invoicesSelectors.data,
)

export const invoicesFiltersSelector = createSelector(
  invoicesSelectors.data,
  path(['meta', 'counters']),
)

export const selectSelectedInvoices = state =>
  makeImmutableStateSelector(state, [
    INVOICES_REDUCER_NAME,
    INVOICES_TO_PAY_REDUCER_NAME,
  ])

export const selectedInvoicesTotalSelector = createSelector(
  selectSelectedInvoices,
  compose(
    sumBy('remainingAmount'),
    values,
  ),
)

export const totalInvoicesToPaySelecor = createSelector(
  invoicesSelector,
  sumBy('remainingAmount'),
)

export const invoicesPaymentDateSelector = createSelector(
  invoicesSelector,
  invoices => {
    const [minDateInvoice] = invoices
    if (!minDateInvoice) return null

    return minDateInvoice.payment.deadlineDate
  },
)

export const overdueInvoicesSelector = createSelector(
  invoicesSelector,
  filter({ statusId: INVOICE_STATUS.NOT_PAID_EXPIRED }),
)

export const overpaymentInvoicesSelectors = genericSelector([
  INVOICES_REDUCER_NAME,
  OVERPAYMENT_INVOICES_REDUCER_NAME,
])

export const overpaymentInvoicesSelector = createSelector(
  overpaymentInvoicesSelectors.data,
  getOr([], 'invoices'),
)
