import React, { useState, useMemo } from 'react'
import { Box, Flex } from '@rebass/grid'

import theme from 'theme'

import {
  useGetStorageValue,
  CONFIG_SCHEMA_KEYS,
  CONFIG_API_PATHS_MAP,
} from 'containers/Storage'
import { useUpdateStorageMutation } from 'services/api/rtkApi'

import { HintModal, HintOverlay } from 'components'
import { PPrimary } from 'components/Text'
import { ArrowUp } from 'components/Icons'

import ProductPreview from './ProductPreview'
import { ArrowContainer } from './styles'
import { hintModalConfig } from './hintModalConfig'

const ProductsPreview = ({
  incomingOrders,
  isExpanded,
  onToggleExpanded,
  isFirstIncomingDelivery,
}) => {
  const [hintIndex, setHintIndex] = useState(-1)
  const incomingProductsHintEnabled =
    useGetStorageValue(CONFIG_SCHEMA_KEYS.CART_GUIDE.INCOMING_PRODUCTS) &&
    isFirstIncomingDelivery

  const [updateStorage] = useUpdateStorageMutation()

  const products = useMemo(
    () =>
      incomingOrders.reduce(
        (acc, { orderItems }) => [...acc, ...orderItems],
        [],
      ),
    [incomingOrders],
  )

  return (
    <Flex alignItems="center" justifyContent="flex-end" flex={1}>
      {products.slice(0, 3).map(({ product }, index) => (
        // products can be duplicated per multiple orders
        // eslint-disable-next-line react/no-array-index-key
        <Box mr={theme.spacing.xxs} key={index}>
          <ProductPreview {...{ product }} />
        </Box>
      ))}

      {products.length > 3 && <PPrimary bold>+{products.length - 3}</PPrimary>}

      {!!incomingOrders.length && (
        <HintOverlay
          ml={theme.spacing.sm}
          enabled={incomingProductsHintEnabled}
        >
          <ArrowContainer
            {...{ isExpanded }}
            onClick={e => {
              if (incomingProductsHintEnabled) {
                e.stopPropagation()
                setHintIndex(0)
              }
              onToggleExpanded(!isExpanded)
            }}
          >
            <ArrowUp />
          </ArrowContainer>
        </HintOverlay>
      )}

      {hintIndex > -1 && (
        <HintModal
          {...hintModalConfig[hintIndex]}
          onClose={() => {
            setHintIndex(-1)
            updateStorage({
              path: CONFIG_API_PATHS_MAP.CART_GUIDE.INCOMING_PRODUCTS,
              value: false,
            })
          }}
        />
      )}
    </Flex>
  )
}

export default ProductsPreview
