import React from 'react'

import { Container, TitleContainer, Title, BottomBar } from './styles'

const Confirmation = ({ message, children, Wrapper = Container }) => (
  <Wrapper onClick={e => e.stopPropagation()}>
    <TitleContainer>
      <Title>{message}</Title>
    </TitleContainer>

    <BottomBar>{children}</BottomBar>
  </Wrapper>
)

export default Confirmation
