/* eslint-disable indent */
import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

export const FooterWrapper = styled.footer`
  background-color: ${theme.colors.lightGrey6};
`

export const FooterPageContent = styled.div`
  width: 100%;
  padding: ${theme.spacing.md} ${theme.spacing.sm};

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: ${theme.spacing.lg} 40px;
  }

  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.lg} 80px;
  }
`

export const FooterInnerContainer = styled.div`
  > div:not(:first-of-type) {
    border-top: 1px solid ${theme.colors.lightGrey7};
    margin-top: 32px;
    padding-top: 32px;
  }

  @media screen and (min-width: 680px) {
    display: grid;
    grid-column-gap: ${theme.colors.base};
    grid-row-gap: 64px;
    grid-column-gap: 64px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    > div:not(:first-of-type) {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }

    .FooterApps {
      grid-column-start: 1;
      grid-column-end: 3;
      display: flex;
      flex-flow: row;
      gap: 64px;

      > div {
        flex: 1;
      }

      .FooterCopyright {
        text-align: end;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  @media screen and (min-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr;

    .FooterApps {
      grid-column-start: 3;
      grid-column-end: 4;
      flex-flow: column;
    }
  }
`

export const FooterLinkText = styled(BaseTextV2).attrs({
  isSmall: { fontSize: true },
})`
  color: ${theme.colors.lightGrey4};

  &:hover {
    color: ${theme.colors.primary};
  }

  ${props =>
    props.bold &&
    `
    font-weight: 500;
  `};
`
