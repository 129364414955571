import React from 'react'

import { ProductsLoaderContainer, ProductsLoader } from './styles'

const Loader = () => (
  <ProductsLoaderContainer data-test-id="loader">
    <ProductsLoader>
      <div />
      <div />
      <div />
      <div />
    </ProductsLoader>
  </ProductsLoaderContainer>
)

export default Loader
