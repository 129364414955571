import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { Row } from 'components/Layout'

import { Column } from '../components'

// prettier-ignore
export const RowContainer = styled(Row)`
  height: 80px;
  max-height: 80px;
  margin: 48px 0;
  justify-content: normal;

  ${p => p.isLowerThanMain && `
    flex-direction: column;
    margin: 32px 0;
    height: auto;
    max-height: unset;
  `};
`

export const InfoContainer = styled(Flex)`
  width: 100%;

  ${p => p.isMobile && 'flex-direction: column'};
`

export const PaddedColumn = styled(Column).attrs({
  isEven: true,
  pl: 24,
  pr: 24,
  py: 16,
  width: 1,
})``
