import styled from 'styled-components'

import { RegularText } from 'components/Text'

import { ProductNameStyled } from '../styledComponents'

export const StockNotif = styled(RegularText)`
  margin-top: 2px;
  line-height: 20px;
`

export const ProductName = styled(ProductNameStyled)`
  line-height: 20px;
`
