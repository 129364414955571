const AUTH_KEY = 'ftx-auth'

export const getStoredAuthInfo = () => {
  try {
    return JSON.parse(localStorage.getItem(AUTH_KEY))
  } catch (err) {
    return null
  }
}

export const storeAuthInfo = data =>
  localStorage.setItem(AUTH_KEY, JSON.stringify(data))

export const getRefreshToken = () => getStoredAuthInfo()?.refreshToken

export const getAuthToken = () => getStoredAuthInfo()?.authToken

export const clearAuthInfo = () => localStorage.removeItem(AUTH_KEY)
