import styled, { css } from 'styled-components'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'
import { templateIconStyling } from 'components/Product/BookmarkIcon/styles'
import { CatalogAddProductsButton } from 'components/Product/ProductButtons/styles'
import {
  ProductLongBtn,
  ReplacementContent,
} from 'components/Product/ButtonsContainer/styles'

export const StickyContainer = styled.div`
  // top value is assigned by parent container
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.base};
`

export const ProductControlsContainer = styled.div`
  width: 100%;
  ${ReplacementContent} {
    flex: 1;
    ${ProductLongBtn} {
      background-color: ${theme.colors.infoBlue2};
      color: ${theme.colors.white};
      path {
        stroke: ${theme.colors.white};
      }

      &:hover {
        background-color: ${theme.colors.infoBlue3} !important;
      }
    }
  }
`

export const ProductNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const ProductNameTopLine = styled.div`
  display: flex;
  gap: ${theme.spacing.ss};
`

export const BrandLine = styled(BaseTextV2).attrs({
  $fontWeight: theme.fontWeights.medium,
})`
  cursor: pointer;
  &:hover {
    color: ${theme.colors.secondary};
  }
`

export const ProductTitle = styled(BaseTextV2)`
  font-weight: ${theme.fontWeights.bold};
  font-size: 24px;
  line-height: 32px;
`
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const CartControlsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.ss};
  ${CatalogAddProductsButton} {
    font-size: ${theme.textSizes.sm};
    font-weight: ${theme.fontWeights.medium};
    line-height: 24px;

    > svg {
      margin-right: ${theme.spacing.sm};
    }
  }
`

export const BookmarkIconContainer = styled.div`
  ${templateIconStyling};
  background-color: ${theme.colors.lightGrey};
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.borderRadius.base};
  &:hover {
    background-color: ${theme.colors.lightGrey7};
  }
  ${p =>
    p.$isInUserTemplate &&
    css`
      path {
        stroke: ${theme.colors.primary};
      }
    `};
`
