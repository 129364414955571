import styled, { css } from 'styled-components'
import { Box } from '@rebass/grid'

import theme from 'theme'

import { Overlay } from 'components/HintModal/styles'

export const DropdownContentMobileStyles = css`
  display: flex;
  position: fixed;
  flex-direction: column;
  max-height: calc(100% - 168px);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: auto;
  bottom: 0;
  left: 0;
  max-width: 100%;
`

export const DropdownContent = styled(Box)`
  background: ${theme.colors.white};
  position: absolute;
  z-index: ${theme.zIndex.modalOverlay + 1};
  ${props => props.top && `top: ${props.top}px;`};
  ${props => props.left && `left: ${props.left}px;`};
  ${props => props.right && `right: ${props.right}px;`};
  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
  `};
  border-radius: 16px;
  box-shadow: ${theme.shadows.primary};
  width: 100%;
  ${props => props.isMobile && DropdownContentMobileStyles};
`

export const DropdownOverlay = styled(Overlay)`
  background: none;
  ${props =>
    props.isMobile &&
    `
    background: ${theme.colors.gray5};
  `};
`
