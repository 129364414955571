import React from 'react'

import ModalWrapper from 'components/modal/ModalWrapper'
import ModalBody from 'components/modal/ModalBody'
import ModalClose from 'components/modal/ModalClose'
import { ModalGlobalStyle } from 'containers/App/styledComponents'

const Modal = ({
  children,
  handleClose,
  rootComponent,
  size,
  bodyClassName,
  hideHeader,
  heading,
}) => {
  const RootComponent = rootComponent || ModalBody

  return (
    <>
      <ModalWrapper isOpened>
        <RootComponent
          size={size}
          className={bodyClassName}
          closeModal={handleClose}
        >
          {!hideHeader && (
            <ModalClose heading={heading} onClick={handleClose} />
          )}
          {children}
        </RootComponent>
      </ModalWrapper>
      <ModalGlobalStyle />
    </>
  )
}

export default Modal
