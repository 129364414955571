import React from 'react'
import styled from 'styled-components'

import {
  getColors,
  getHeadingSize,
  getTextSizes,
  getLineHeights,
} from 'components/helpers/theme-selectors'
import { Link } from 'components/Text'

// prettier-ignore
export const ProductName = styled(({ isSmall, ...props }) => (
  <Link {...props} />
))`
  text-align: left;
  font-size: ${props => getHeadingSize(props).m};
  ${props => !props.active && `color: ${getColors(props).gray4};`};

  ${p => p.isSmall && `
    font-size: ${getTextSizes(p).l};
    line-height: ${getLineHeights(p).md};
  `}

  &:hover {
    color: ${props => getColors(props).secondary};
    ${props => !props.active && `color: ${getColors(props).gray4};`};
  }

  @media (max-width: 640px) {
    text-align: center;
    margin: 10px auto;
  }
`

// prettier-ignore
export const NameContainer = styled.div`
  display: inline-block;

  a {
    ${p => !p.isClickable && `
      pointer-events: none;
    `};
  }
`
