import React from 'react'
import { useIntl } from 'react-intl'

import { cn } from 'utils'

import messages from './messages'

const CategoryTab = ({
  onClick,
  className,
  active,
  title,
  showNewBadge = false,
}) => {
  const { formatMessage } = useIntl()

  return (
    <button
      type="button"
      className={cn('flex flex-col gap-0.5 group', className)}
      onClick={onClick}
    >
      <div className="flex gap-2 items-center">
        <span
          className={cn(
            'text-[13px] text-grey-600 group-hover:text-blue-900 font-medium leading-6 duration-300 ease-out',
            active && 'text-blue-900',
          )}
        >
          {title}
        </span>
        {showNewBadge && (
          <span className="flex items-center font-medium px-1 bg-[#42CF001F] text-green-600 text-[11px] leading-5 rounded-md">
            {formatMessage(messages.badgeNew)}
          </span>
        )}
      </div>
      <div
        className={cn(
          'h-1 bg-green-600 rounded-2xl duration-300 ease-out w-full',
          active ? 'opacity-100' : 'opacity-0',
        )}
      />
    </button>
  )
}

export default CategoryTab
