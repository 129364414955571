import { makeMessages, makeTranslation } from 'utils'

const messages = {
  title: { id: 'navbar.invoices' },
  documentType: 'documentType',
  expired: 'expired',
  expiredDay: 'expiredDay',
  expiredDays: 'expiredDays',
  emptyMsg: 'emptyMsg',
  goHomeBtn: { id: 'notFound.returnToCategories' },
  paySelectExpiredDay: 'paySelect.expiredDay',
  paySelectExpiredDays: 'paySelect.expiredDays',
  payTill: 'paySelect.payTill',
  processing: 'paySelect.processing',
  toPay: 'filters.toPay',
  paid: 'filters.paid',
  all: 'filters.all',
  overpaid: 'filters.overpaid',
  categoryEmpty: 'categoryEmpty',
  categoryEmptyBtn: 'categoryEmptyBtn',
  itemDetails: 'itemDetails',
  paySelectedCount: 'paySelectedCount',
  paySelectedCountPlural: 'paySelectedCountPlural',
  pay: { id: 'payment.payBtn' },
  toPayLabel: 'toPay',
  paySelectClear: { id: 'common.labels.clear' },
  hideInvoicesInfo: 'hideInvoicesInfo',
  remainingDiff: 'paySelect.remainingDiff',
  payWithOverpayments: 'payWithOverpayments',
  dateRangeLabel: 'dateRangeLabel',
  days: { id: 'plural.days' },
  home: {
    id: 'navbar.home',
  },
  paidOnline: 'paidOnline',
  processingFile: 'processingFile',
  downloadInvoice: 'downloadInvoice',
  downloadInvoiceError: 'downloadInvoiceError',
  paymentNumber: 'paymentNumber',
  invoiceDate: 'invoiceDate',
}

export default makeMessages(messages, makeTranslation('invoices'))
