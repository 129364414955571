import styled from 'styled-components'
import { FlexColumns } from 'layouts'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import {
  MOBILE_HEIGHT,
  DESKTOP_HEIGHT,
} from 'containers/App/LoggedInLayout/Header/styles'

export const breakPointLayoutL = '814px'
export const breakPointLayoutS = '600px'

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));
  grid-column-gap: ${theme.spacing.base};
  grid-row-gap: ${theme.spacing.base};
`

// prettier-ignore
export const Content = styled(ProductsGrid)`
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
  grid-column-gap: ${theme.spacing.sm};
  grid-row-gap: 50px;
  margin-bottom: ${theme.spacing.base};

  @media (max-width: ${breakPointLayoutL}) {
    margin-left: 0;
  }

  @media (max-width: 624px) {
    margin-left: -16px;
    width: calc(100% + 32px);
    grid-column-gap: 0;
  }
`

export const ProductsContentWrapper = styled(FlexColumns)`
  flex-direction: column;
  padding: ${theme.spacing.md};

  @media (max-width: ${breakPointLayoutS}) {
    padding: ${theme.spacing.base};
  }
`

export const InnerContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

export const Gradient = styled.div`
  position: relative;
  width: calc(100% + 48px);
  margin-left: -24px;
  :after,
  :before {
    content: '';
    position: absolute;
    z-index: ${theme.zIndex.base};
    top: 0;
    bottom: 0;
    pointer-events: none;
    width: 24px;
  }
  :after {
    right: 0;
    background-image: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  :before {
    left: 0;
    background-image: linear-gradient(
      90deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

// prettier-ignore
export const FiltersContainer = styled.div`
  max-width: 100%;
  position: sticky;
  // 1px of border
  top: ${props => props.isMobile ? MOBILE_HEIGHT - 1 : DESKTOP_HEIGHT - 1}px;
  z-index: ${theme.zIndex.base};
`

export const StyledDiv = styled.div`
  width: 100%;

  @media only screen and (max-width: ${breakPointLayoutL}) {
    margin-left: 0;
  }
`
