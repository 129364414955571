import { combineReducers } from 'redux-immutable'

import {
  APP_NOTIFICATIONS_REDUCER_NAME,
  APP_MODALS_REDUCER_NAME,
  APP_CONFIG_REDUCER_NAME,
  APP_TOOLTIP_REDUCER_NAME,
  APP_DASHBOARD_TILES_REDUCER_NAME,
} from './consts'

import notificationsReducer from './notifications'
import modalReducer from './modal'
import appConfigReducer from './appConfig'
import tooltipReducer from './tooltip'
import appDashboardTilesReducer from './dashboardTiles'

export default combineReducers({
  [APP_NOTIFICATIONS_REDUCER_NAME]: notificationsReducer,
  [APP_MODALS_REDUCER_NAME]: modalReducer,
  [APP_TOOLTIP_REDUCER_NAME]: tooltipReducer,
  [APP_CONFIG_REDUCER_NAME]: appConfigReducer,
  [APP_DASHBOARD_TILES_REDUCER_NAME]: appDashboardTilesReducer,
})
