import React, { useMemo } from 'react'
import { Flex } from '@rebass/grid'
import dayjs from 'dayjs'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { formatPrice, useRichFormatMessage } from 'utils'
import { CheckV3 as IndicatorIcon } from 'components/Icons'
import TextTooltip from 'components/Tooltip/TextTooltip'
import { BaseTextV2 } from 'components/Text'

import theme from 'theme'
import messages from '../../../messages'
import {
  RouteContainer,
  RouteTextContainer,
  RouteStatusContainer,
  RouteProgressWrapper,
  RouteStatusTextWrapper,
  RouteStatusText,
  LimitMetStatusWrapper,
  LimitMetIndicatorWrapper,
  LimitNotMetStatusWrapper,
} from './styles'

const RouteDetails = ({ route, routeInfoMsg, onSelect, showRouteLabel }) => {
  const formatMessage = useRichFormatMessage()
  const { logisticLackAmount, logisticLimit, orderDeadline, orders } = route

  const isLimitMet = logisticLackAmount === 0
  const routeTotal = logisticLimit - logisticLackAmount
  const logisticLimitProgress = Math.round((routeTotal / logisticLimit) * 100)

  const isDeliveryOpen = dayjs().isBefore(dayjs(orderDeadline))
  const statusMsg = isDeliveryOpen
    ? formatMessage(messages.openDelivery)
    : formatMessage(messages.incomingDelivery)

  const statusTooltipMsg = isDeliveryOpen
    ? formatMessage(messages.openDeliveryTooltip)
    : formatMessage(messages.incomingDeliveryTooltip)

  // prettier-ignore
  const logisticLimitProgressTooltip = isLimitMet
    ? formatMessage(messages.limitReached)
    : formatMessage(messages.lacksToLimit, {
      amount: formatPrice(logisticLackAmount),
    })

  const ordersInfo = formatMessage(messages.deliveriesNumber, {
    amount: orders.length,
  })

  const orderNumbersFormatted = useMemo(
    () => orders.map(order => order.number).join(' '),
    [orders],
  )

  return (
    <RouteContainer data-test-id={`route#${orders[0].id}`} onClick={onSelect}>
      <RouteTextContainer>
        <TextTooltip
          content={orderNumbersFormatted}
          textContainerStyle={{ maxWidth: 135 }}
        >
          <BaseTextV2>
            {showRouteLabel
              ? formatMessage(routeInfoMsg, { orders: ordersInfo })
              : formatMessage(messages.routeDefault, { orders: ordersInfo })}
          </BaseTextV2>
        </TextTooltip>
      </RouteTextContainer>
      <RouteStatusContainer>
        <TextTooltip content={statusTooltipMsg}>
          <RouteStatusTextWrapper>
            <RouteStatusText>{statusMsg}</RouteStatusText>
          </RouteStatusTextWrapper>
        </TextTooltip>
        <RouteProgressWrapper>
          <TextTooltip content={logisticLimitProgressTooltip}>
            {isLimitMet ? (
              <LimitMetStatusWrapper data-test-id="limit-met-indicator">
                <LimitMetIndicatorWrapper>
                  <Flex>
                    <IndicatorIcon
                      height={6}
                      width={8}
                      color={theme.colors.white}
                    />
                  </Flex>
                </LimitMetIndicatorWrapper>
              </LimitMetStatusWrapper>
            ) : (
              <LimitNotMetStatusWrapper data-test-id="limit-not-met-indicator">
                <CircularProgressbar
                  value={logisticLimitProgress}
                  strokeWidth={18}
                  styles={buildStyles({
                    trailColor: theme.colors.redProgressTrail,
                    pathColor: theme.colors.white,
                  })}
                />
              </LimitNotMetStatusWrapper>
            )}
          </TextTooltip>
        </RouteProgressWrapper>
      </RouteStatusContainer>
    </RouteContainer>
  )
}

export default RouteDetails
