import styled from 'styled-components'
import { getBorder } from 'components/Input/styles'
import { getSpacing } from 'components/helpers/theme-selectors'
import { BORDER_TYPES } from 'components/Input/consts'

export const SelectContainer = styled.div`
  width: 140px;
  height: 56px;
  margin-top: ${props => getSpacing(props).sm};

  .select__control {
    height: 56px;
    border: ${({ borderType }) => getBorder(borderType)};
    border-radius: initial;
    box-shadow: none;

    :not(.select__control--is-focused):hover {
      border: ${({ borderType }) => getBorder(borderType)};
    }
  }

  .select__control--is-focused {
    border: ${getBorder(BORDER_TYPES.SUCCESS)};
    :hover {
      border: ${getBorder(BORDER_TYPES.SUCCESS)};
    }
  }

  .select__indicator-separator {
    display: none;
  }

  svg {
    width: 16px;
    height: 8px;
  }
`
