import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { TinyText } from 'components/Text'

import { APP_BREAKPOINTS } from 'consts'

export const Container = styled(Flex)`
  overflow-x: auto;

  @media screen and (max-width: ${APP_BREAKPOINTS.TMP_MAIN - 1}px) {
    padding-top: ${theme.spacing.xs};
  }
`

// prettier-ignore
export const Date = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    background-color: ${p => p.isLast ? theme.colors.gray2 : theme.colors.primary};
    content: ' ';
    display: block;
    width: calc(100% - 5px);
    height: 2px;
    margin-top: -2px;
  }
`

export const DateLabels = styled(Flex)`
  flex-flow: column;
  align-items: center;
  transform: translateX(calc(50% + 0px));
  cursor: pointer;
  position: relative;
  z-index: ${theme.zIndex.base};
`

export const DateDot = styled.div`
  width: 8px;
  height: 8px;
  background: ${theme.colors.primary};
  border: 2px solid ${theme.colors.white};
  border-radius: 50%;
  padding-top: 6px;
  padding-right: 6px;
`

export const DayOfWeek = styled(TinyText)`
  line-height: 22px;
`
