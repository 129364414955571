import styled, { css } from 'styled-components'
import { MODAL_SIZES } from 'containers/App/modalTypes'
import { getColors, getSpacing } from 'components/helpers/theme-selectors'

const sizeStyles = {
  [MODAL_SIZES.SMALL]: css`
    max-width: ${MODAL_SIZES.SMALL}px;
    width: ${MODAL_SIZES.SMALL}px;
    min-height: 180px;
  `,
  [MODAL_SIZES.MEDIUM]: css`
    max-width: ${MODAL_SIZES.MEDIUM}px;
    min-height: 180px;
  `,
  [MODAL_SIZES.BIG]: css`
    max-width: ${MODAL_SIZES.BIG}px;
    min-height: 180px;
  `,
}

const getSizeStyles = size => sizeStyles[size] || sizeStyles[MODAL_SIZES.SMALL]

const ModalBody = styled.div`
  width: calc(100% - 24px);
  ${props => getSizeStyles(props.$size)};
  background: ${props => getColors(props).white};
  padding: ${props => getSpacing(props).base};

  @media screen and (min-width: 768px) {
    width: calc(100% - 48px);
  }

  @media screen and (max-width: 767px) {
    margin: auto 8px;
  }
`

export default ModalBody
