import * as yup from 'yup'

import profileMessages from '../messages'

export default yup.object().shape({
  newPassword: yup
    .string()
    .required(profileMessages.required)
    .min(8, profileMessages.newPasswordLengthError),
  oldPassword: yup.string().required(profileMessages.required),
})
