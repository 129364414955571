import React, { useState } from 'react'
import { noop } from 'lodash'

import { AmountContainer } from './styles'

const Amount = ({
  value,
  displayUnit,
  onFocus = noop,
  onBlur = noop,
  onChange,
  ...props
}) => {
  const [focused, setFocused] = useState(false)

  const handleFocus = ev => {
    setFocused(true)
    onFocus(ev)
  }
  const handleBlur = ev => {
    setFocused(false)
    onBlur(ev)
  }
  const handleKeyDown = ({ key }) => {
    if (key === 'Enter' && document.activeElement) {
      document.activeElement.blur()
    }
  }

  return (
    <AmountContainer
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      value={
        focused || !displayUnit || !Number(value)
          ? value
          : `${value} ${displayUnit}`
      }
    />
  )
}

export default Amount
