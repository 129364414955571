import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import useDeadlineOrderDates from 'hooks/useDeadlineOrderDates'
import { InfoV2 } from 'components/Icons'

import messages from '../../messages'
import { BannerContainer, BannerTextContainer, BannerText } from './styles'

const RouteDeadlineBanner = ({ orderDetails = {} }) => {
  const { formatMessage } = useIntl()
  const [deadlineDateFormatted] = useDeadlineOrderDates({
    deadlineOrderDate: orderDetails?.routeDeadline,
  })

  if (!deadlineDateFormatted) return null

  return (
    <BannerContainer data-test-id="route-deadline-banner">
      <div>
        <InfoV2 color={theme.colors.infoBlue2} />
      </div>
      <BannerTextContainer>
        <BannerText>
          {formatMessage(messages.routeDeadlineInfo, {
            deadlineDate: deadlineDateFormatted,
          })}
        </BannerText>
      </BannerTextContainer>
    </BannerContainer>
  )
}

export default RouteDeadlineBanner
