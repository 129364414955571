import { makeMessages, makeTranslation } from 'utils'

const messages = {
  promoTile: 'promotion',
  clearanceTile: 'clearance',
  noveltyTile: 'offer_features.novelty',
  bestsellersTile: 'offer_features.bestseller',
}

export default makeMessages(messages, makeTranslation('filter'))
