import { useIntl } from 'react-intl'
import NotificationWrapper from 'components/NotificationWrapper'
import { ArrowLongRight, MailSmall, PhoneSmall } from 'components/Icons'
import theme from 'theme'
import { ACTION_TYPE } from 'components/ActionWrapper'
import { BaseTextV2 } from 'components/Text'
import React from 'react'
import {
  Divider,
  FormSection,
  SubmitButton,
  ContactButton,
  ContactButtonsContainer,
  ContactsSection,
  ContactsSectionHeader,
} from './styledComponents'
import messages from './messages'

const InfoPanel = ({ title, subtitle, btnText, onBtnClick, hasContacts }) => {
  const { formatMessage } = useIntl()
  return (
    <NotificationWrapper title={title} subtitle={subtitle}>
      <FormSection>
        {btnText && (
          <>
            <Divider />
            <SubmitButton $fullWidth onClick={onBtnClick}>
              {btnText}
              <ArrowLongRight color={theme.colors.white} />
            </SubmitButton>
          </>
        )}
        {hasContacts && (
          <>
            <Divider />
            <ContactsSection>
              <ContactsSectionHeader>
                {formatMessage(messages.supportTitle)}
              </ContactsSectionHeader>
              <ContactButtonsContainer>
                <ContactButton
                  value={formatMessage(messages.supportPhone)}
                  actionType={ACTION_TYPE.PHONE}
                >
                  <PhoneSmall color={theme.colors.gray5} />
                  <BaseTextV2>
                    {formatMessage(messages.supportPhone)}
                  </BaseTextV2>
                </ContactButton>
                <ContactButton
                  actionType={ACTION_TYPE.EMAIL}
                  value={formatMessage(messages.supportMail)}
                >
                  <MailSmall color={theme.colors.gray5} />
                  <BaseTextV2>{formatMessage(messages.supportMail)}</BaseTextV2>
                </ContactButton>
              </ContactButtonsContainer>
            </ContactsSection>
          </>
        )}
      </FormSection>
    </NotificationWrapper>
  )
}

export default InfoPanel
