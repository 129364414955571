import { createSelector } from 'reselect'

import { makeDataSelector } from 'utils/redux-utils'

import {
  SELECTED_CATEGORY_GROUP_REDUCER_NAME,
  CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_SUBGROUP_REDUCER_NAME,
  SELECTED_CATEGORY_REDUCER_NAME,
  CATEGORY_LIST_REDUCER_NAME,
  ALL_CATEGORIES_LIST_REDUCER_NAME,
} from './consts'

// category
export const selectCategory = makeDataSelector([
  CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_REDUCER_NAME,
])
export const categorySelector = createSelector(
  selectCategory,
  data => data || {},
)

// categories list
export const selectCategoriesList = makeDataSelector([
  CATEGORY_REDUCER_NAME,
  CATEGORY_LIST_REDUCER_NAME,
])

export const selectAllCategoriesList = makeDataSelector([
  CATEGORY_REDUCER_NAME,
  ALL_CATEGORIES_LIST_REDUCER_NAME,
])

export const categoriesListSelector = createSelector(
  selectCategoriesList,
  data => data || [],
)

export const allCategoriesListSelector = createSelector(
  selectAllCategoriesList,
  data => data || [],
)

// category group
export const selectCategoryGroup = makeDataSelector([
  CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_GROUP_REDUCER_NAME,
])
export const categoryGroupSelector = createSelector(
  selectCategoryGroup,
  data => data || {},
)

// category subgroup
export const selectCategorySubgroup = makeDataSelector([
  CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_SUBGROUP_REDUCER_NAME,
])
export const categorySubgroupSelector = createSelector(
  selectCategorySubgroup,
  data => data || {},
)
