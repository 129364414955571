import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { PSuccess, TertiaryText } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

export const OuterWrapper = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.bgGray};
`

export const Wrapper = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  background-color: #effbe8;
  padding: 8px;
  margin: 8px;
  margin-top: 0;
`

export const PromoBox = styled(PSuccess).attrs({ as: 'div' })`
  width: 104px;
  min-width: 104px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.borderGreen};
  border-radius: 4px;
  font-weight: ${theme.fontWeights.bold};
  overflow: hidden;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: 88px;
    min-width: 88px;
  }
`

export const BoxChild = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const Counter = styled(BoxChild)`
  background-color: ${theme.colors.secondaryBgLight};
  border-left: 1px solid ${theme.colors.borderGreen};
`

export const PromoText = styled(TertiaryText)`
  color: ${theme.colors.secondary};
  ${p => p.withSmMargin && `margin-left: ${theme.spacing.sm}`};
  ${p => p.withSsMargin && `margin-left: ${theme.spacing.ss}`};
`
