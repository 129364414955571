import React from 'react'

import theme from 'theme'

const CircleAlertFilled = ({ color = theme.colors.white }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="8" fill="#E45A4F" />
    <path
      d="M8.61504 6.99558C8.55299 6.18883 9.19087 5.5 10 5.5C10.8091 5.5 11.447 6.18883 11.385 6.99558L11.0767 11.0029C11.0334 11.5656 10.5643 12 10 12C9.43572 12 8.96658 11.5656 8.9233 11.0029L8.61504 6.99558Z"
      fill={color}
    />
    <rect x="9" y="13" width="2" height="2" rx="1" fill={color} />
  </svg>
)

export default CircleAlertFilled
