import React from 'react'
import theme from 'theme'

const BellSmall = ({ color = theme.colors.infoBlue, fill = 'none' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 9C17 5.732 15.268 4 12 4C8.732 4 7 5.732 7 9C7 14 5 16 5 16H19C19 16 17 14 17 9Z"
      stroke={color}
      fill={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7295 19C13.5537 19.3031 13.3014 19.5547 12.9978 19.7295C12.6941 19.9044 12.3499 19.9965 11.9995 19.9965C11.6492 19.9965 11.3049 19.9044 11.0013 19.7295C10.6977 19.5547 10.4453 19.3031 10.2695 19"
      fill={color}
    />
    <path
      d="M13.7295 19C13.5537 19.3031 13.3014 19.5547 12.9978 19.7295C12.6941 19.9044 12.3499 19.9965 11.9995 19.9965C11.6492 19.9965 11.3049 19.9044 11.0013 19.7295C10.6977 19.5547 10.4453 19.3031 10.2695 19"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default BellSmall
