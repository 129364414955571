import { makeMessages, makeTranslation } from 'utils'

const messages = {
  creditLimit: 'creditLimit',
  overpayments: 'overpayments',
  titlePristine: 'titlePristine',
  titleUnpaid: 'titleUnpaid',
  titleUnpaidCredit: 'titleUnpaidCredit',
  titlePaid: 'titlePaid',
  titlePaidCredit: 'titlePaidCredit',
  subtitlePaid: 'subtitlePaid',
  subtitlePristine: 'subtitlePristine',
  paymentDate: 'paymentDate',
  dateTillBlockage: 'dateTillBlockage',
  unpaidInvoicesCount: 'unpaidInvoicesCount',
  unpaidCountAmount: 'unpaidCountAmount',
  accountBlocked: 'accountBlocked',
  requiresApproval: 'requiresApproval',
  paymentHistoryBtn: 'paymentHistoryBtn',
  showCartBtn: 'showCartBtn',
  titleOverdue: 'titleOverdue',
  payInvoicesBtn: { id: 'cart.payBills' },
  creditLimitExceeded: 'creditLimitExceeded',
  transactionLimitExceeded: 'transactionLimitExceeded',
  titlePristineCredit: 'titlePristineCredit',
  subtitleCredit: 'subtitleCredit',
  subtitleCartLimit: 'subtitleCartLimit',
  unpaidCount: 'unpaidCount',
  overdueCount: 'overdueCount',
  grossPrice: { id: 'prices.gross' },
  hiddenDuesMsg: 'hiddenDuesMsg',
  titleOverdueCart: 'titleOverdueCart',
}

export default makeMessages(messages, makeTranslation('dashboard.invoices'))
