import React from 'react'

import theme from 'theme'

const TimerClock = ({ color = theme.colors.lightGrey4 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ICO/24/Clock Timer">
      <circle
        id="Ellipse 244"
        cx="12"
        cy="13"
        r="8"
        stroke={color}
        strokeWidth="2"
      />
      <path
        id="Vector 1037"
        d="M10 2L14 2"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Vector 1040"
        d="M12 5L12 2"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Vector 1042"
        d="M17.7773 7.22189L19.7773 5.22192"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Vector 1041"
        d="M6.22266 7.22192L4.22266 5.22192"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Vector 1043"
        d="M12 13L15.5 16.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
)

export default TimerClock
