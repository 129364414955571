import { trim } from 'lodash'

import signUpMessages from '../SignUp/messages'
import {
  isUsernameValid,
  isEmailValid,
  validatePassword,
} from '../SignUp/utils'

export default values => {
  const errors = { ...validatePassword(values) }

  const login = trim(values.get('login') || '')
  const email = values.get('emailAddress') || ''
  const recaptcha = values.get('recaptcha') || ''
  const requiredCheckbox = values.get('agreement_1')

  if (login.length) {
    if (!isUsernameValid(login)) {
      errors.login = signUpMessages.loginInvalid
    }
  } else {
    errors.login = signUpMessages.blank
  }

  if (!recaptcha.length) {
    errors.recaptcha = signUpMessages.required
  }

  if (!isEmailValid(email)) {
    errors.emailAddress = signUpMessages.invalidEmail
  }

  if (!requiredCheckbox) {
    errors.agreement_1 = signUpMessages.required
  }

  return errors
}
