import React from 'react'

import theme from 'theme'

const Modal = ({
  color = theme.colors.primary,
  width = 16,
  height = 16,
  ...rest
}) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <rect
      x={2}
      y={3}
      width={12}
      height={10}
      rx={2}
      stroke={color}
      strokeWidth={2}
    />
    <path
      d="M11 10H5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={11} cy={6} r={1} fill={color} />
  </svg>
)

export default Modal
