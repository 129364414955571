import styled, { css } from 'styled-components'
import { FloatingArrow } from '@floating-ui/react'

import theme from 'theme'

export const Arrow = styled(FloatingArrow)`
  fill: white;
`

export const TriggerContainer = styled.div`
  overflow: hidden;
`
export const ContentPositioningContainer = styled.div`
  z-index: ${theme.zIndex.modalOverlay + 1};
`
export const DefaultPopoverContainer = css`
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.primary};
  border-radius: 20px;
`
export const CloseElementContainer = styled.div``
