import { combineReducers } from 'redux-immutable'

import { createReducer } from 'utils/simpleDataReducer'

import {
  INVOICES_LIST_REDUCER_NAME,
  PAY_INIT_REDUCER_NAME,
  PAY_PROCESSING_REDUCER_NAME,
  INVOICES_FILTERS_REDUCER_NAME,
  INVOICES_TO_PAY_REDUCER_NAME,
  INVOICES_SUMMARY_REDUCER_NAME,
  OVERPAYMENT_INVOICES_REDUCER_NAME,
  SETTLE_OVERPAYMENTS_REDUCER_NAME,
} from './consts'

import {
  getInvoicesActions,
  getOverpaymentInvoicesActions,
} from './List/actions'
import {
  initPaymentActions,
  settleInvoicesActions,
} from './PaySelection/actions'
import { getInvoicesSummaryActions } from './actions'
import { getPayStatusActions } from './PayProcessing/actions'
import invoicesFiltersReducer from './List/Filters/reducer'
import { invoicesToPayReducer } from './List/reducer'

export default combineReducers({
  [INVOICES_LIST_REDUCER_NAME]: createReducer(getInvoicesActions),
  [PAY_INIT_REDUCER_NAME]: createReducer(initPaymentActions),
  [PAY_PROCESSING_REDUCER_NAME]: createReducer(getPayStatusActions),
  [INVOICES_FILTERS_REDUCER_NAME]: invoicesFiltersReducer,
  [INVOICES_TO_PAY_REDUCER_NAME]: invoicesToPayReducer,
  [INVOICES_SUMMARY_REDUCER_NAME]: createReducer(getInvoicesSummaryActions),
  [OVERPAYMENT_INVOICES_REDUCER_NAME]: createReducer(
    getOverpaymentInvoicesActions,
  ),
  [SETTLE_OVERPAYMENTS_REDUCER_NAME]: createReducer(settleInvoicesActions),
})
