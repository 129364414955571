import React from 'react'

const Invoice = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29 15C29 13.8954 28.1046 13 27 13H23C20.2386 13 18 10.7614 18 8V4C18 2.89543 17.1046 2 16 2H10.3C8.47759 2 7 3.45573 7 5.25V12.024C7 12.6831 7.62845 13.161 8.28223 13.0773C8.68095 13.0263 9.08741 13 9.5 13C14.7467 13 19 17.2533 19 22.5C19 23.8577 18.7152 25.1489 18.202 26.3171C17.8753 27.061 18.3728 28 19.1853 28H25.7C26.9375 28 28.016 27.3293 28.5808 26.3369C28.8477 25.8672 29 25.3255 29 24.75V15Z"
      fill="#229944"
    />
    <path
      d="M17 22.5C17 26.6421 13.6421 30 9.5 30C5.35786 30 2 26.6421 2 22.5C2 18.3579 5.35786 15 9.5 15C13.6421 15 17 18.3579 17 22.5Z"
      fill="#ABCFB5"
    />
    <path
      d="M9.5 17V18.5M9.5 18.5H9C7.89543 18.5 7 19.3954 7 20.5C7 21.6046 7.89543 22.5 9 22.5H10C11.1046 22.5 12 23.3954 12 24.5C12 25.6046 11.1046 26.5 10 26.5H9.5M9.5 18.5H10.5C11.3284 18.5 12 19.1716 12 20M9.5 28V26.5M9.5 26.5H8.5C7.67157 26.5 7 25.8284 7 25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20 3.71627L20 7.57434C20 9.46628 21.5337 11 23.4257 11H27.2837C28.8097 11 29.5739 9.15503 28.4949 8.07601L22.924 2.50511C21.845 1.42609 20 2.1903 20 3.71627Z"
      fill="#ABCFB5"
    />
  </svg>
)

export default Invoice
