import React from 'react'
import { useSelector } from 'react-redux'

import { userDataSelector } from 'containers/UserInfo/selectors'
import FilteredPageTile from 'components/FilteredPageTile'
import { DROPDOWN_FILTERED_PAGE_VARIANTS } from 'components/CategoriesDropdown/FilteredPagesSection/consts'
import { FILTERED_PAGE_MAP } from 'components/FilteredPageTile/consts'

const FilteredPagesSection = () => {
  const { offerDetails } = useSelector(userDataSelector)

  return (
    <div className="grid grid-cols-2 gap-4">
      {offerDetails?.hasNovelties && (
        <FilteredPageTile
          variantConfig={
            DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.NOVELTIES]
          }
        />
      )}
      {offerDetails?.hasActiveClearances && (
        <FilteredPageTile
          variantConfig={
            DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.CLEARANCE]
          }
        />
      )}
      {offerDetails?.hasBestsellers && (
        <FilteredPageTile
          variantConfig={
            DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.BESTSELLERS]
          }
        />
      )}
      {offerDetails?.hasActivePromotions && (
        <FilteredPageTile
          variantConfig={
            DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.PROMOTIONS]
          }
        />
      )}
    </div>
  )
}

export default FilteredPagesSection
