import styled from 'styled-components'

import theme from 'theme'

const Wrapper = styled.div`
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: ${theme.zIndex.loaderOverlay};
  position: fixed;
  top: 0;

  &::after {
    content: ' ';
    width: 100%;
    height: 100%;
    background-color: #021521;
    position: absolute;
    opacity: 0.4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export default Wrapper
