import React from 'react'
import { useIntl } from 'react-intl'

import { BaseTextV2, H3v2 } from 'components/Text'
import theme from 'theme'
import {
  Document as DocumentIcon,
  History as PreviousDocumentIcon,
} from 'components/Icons'
import messages from 'components/Documents/DocumentTile/messages'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'

import { ButtonsContainer, Container, DocumentBtn } from './styles'

const DocumentTile = ({
  title,
  currentDocumentUrl,
  previousVersionFileUrl,
}) => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)
  const { formatMessage } = useIntl()

  return (
    <Container>
      <H3v2>{title}</H3v2>
      <ButtonsContainer>
        <DocumentBtn
          onClick={() => window.open(`https:${currentDocumentUrl}`, '_blank')}
        >
          <DocumentIcon color={theme.colors.primary} />
          <BaseTextV2 $fontWeight={500}>
            {formatMessage(messages.currentFileBtn)}
          </BaseTextV2>
        </DocumentBtn>
        {previousVersionFileUrl && (
          <DocumentBtn
            onClick={() =>
              window.open(`https:${previousVersionFileUrl}`, '_blank')
            }
          >
            {isTablet && <PreviousDocumentIcon color={theme.colors.primary} />}
            <BaseTextV2 $fontWeight={500}>
              {formatMessage(messages.previousFileBtn)}
            </BaseTextV2>
          </DocumentBtn>
        )}
      </ButtonsContainer>
    </Container>
  )
}

export default DocumentTile
