import { combineReducers } from 'redux-immutable'

import {
  PROMO_PRODUCTS_REDUCER_NAME,
  PROMO_CAMPAIGNS_REDUCER_NAME,
  PROMO_GROUP_REDUCER_NAME,
  PROMO_GROUP_PRODUCTS_REDUCER_NAME,
  ALL_PROMO_GROUPS_REDUCER_NAME,
} from './consts'
import promoProductsReducer from './Products/reducer'
import promoCampaignsReducer from './Campaigns/reducer'
import promoGroupReducer from './Groups/reducer'
import promoGroupProductsReducer from './GroupProducts/reducer'
import AllPromoGroupsReducer from './AllGroups/reducer'

export default combineReducers({
  [PROMO_PRODUCTS_REDUCER_NAME]: promoProductsReducer,
  [PROMO_CAMPAIGNS_REDUCER_NAME]: promoCampaignsReducer,
  [PROMO_GROUP_REDUCER_NAME]: promoGroupReducer,
  [PROMO_GROUP_PRODUCTS_REDUCER_NAME]: promoGroupProductsReducer,
  [ALL_PROMO_GROUPS_REDUCER_NAME]: AllPromoGroupsReducer,
})
