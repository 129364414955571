import styled from 'styled-components'
import { FlexColumns } from 'layouts'
import { P } from 'components/Text'

import theme from 'theme'

// prettier-ignore
export const Brand = styled(P)`
  color: ${theme.colors.secondary};
  font-size: ${theme.headingSize.sm};
  letter-spacing: 0.08em;
  line-height: 15px;
  text-transform: uppercase;

  :after {
    content: '';
    border-right: 1px solid ${theme.colors.gray4};
    margin-left: 8px;
    height: 8px;
    display: inline-block;
  }
`
export const ProductId = styled(P)`
  color: ${theme.colors.gray4};
  font-size: ${theme.headingSize.sm};
  letter-spacing: 0.08em;
  line-height: 15px;
`

export const BrandSection = styled(FlexColumns).attrs({ col: 12 })`
  align-items: flex-end;
  ${Brand} {
    margin-right: ${theme.headingSize.xs};
  }
  margin-bottom: ${theme.headingSize.xs};
`
