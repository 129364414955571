import React from 'react'
import theme from 'theme'

const GlutenFree = ({ color = theme.colors.primary }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_67_211)">
      <path
        d="M14 5H12C10.3431 5 9 6.34315 9 8V9H11C12.6569 9 14 7.65685 14 6V5Z"
        fill={color}
        className="primaryFill"
      />
      <path
        d="M8.5 0.171387L7.79289 0.878493C6.62132 2.05007 6.62132 3.94956 7.79289 5.12113L8.5 5.82824L9.20711 5.12113C10.3787 3.94956 10.3787 2.05007 9.20711 0.878493L8.5 0.171387Z"
        fill={color}
        className="primaryFill"
      />
      <path
        d="M3 5H5C6.65685 5 8 6.34315 8 8V9H6C4.34315 9 3 7.65685 3 6V5Z"
        fill={color}
        className="primaryFill"
      />
      <path
        d="M3 10H5C6.65685 10 8 11.3431 8 13V14H6C4.34315 14 3 12.6569 3 11V10Z"
        fill={color}
        className="primaryFill"
      />
      <path
        d="M14 10H12C10.3431 10 9 11.3431 9 13V14H11C12.6569 14 14 12.6569 14 11V10Z"
        fill={color}
        className="primaryFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L2.58579 4H5.41421L1.70711 0.292893ZM14.7071 13.2929L13.2929 14.7071L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L14.7071 13.2929Z"
        fill={color}
        className="primaryFill"
      />
    </g>
    <defs>
      <clipPath id="clip0_67_211">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default GlutenFree
