import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose, prop, propOr, head } from 'lodash/fp'
import dayjs from 'dayjs'

import { FormattedRichMessage } from 'utils'
import { BADGE_STATUS } from 'components/Product/Badge'
import { UNAVAILABILITY_TYPES } from './consts'

import messages from '../messages'

export const getAvailabilityStatus = ({
  outOfStock,
  closestDeliveryTime,
  isSoonAvailable,
}) => {
  if (isSoonAvailable) return BADGE_STATUS.DISABLED
  if (outOfStock) return BADGE_STATUS.ERROR
  if (closestDeliveryTime) return BADGE_STATUS.INFO

  return BADGE_STATUS.WARNING
}

export const daysDiff = date => dayjs().diff(date, 'days', true)

export const isDateTomorrow = date =>
  dayjs(date).isSame(dayjs().add(1, 'days'), 'd')

export const isDateToday = date => dayjs(date).isSame(dayjs(), 'd')

export const getDate = date => dayjs(date).format('D.MM')

export const getValidDeadline = date =>
  dayjs(date).format(
    daysDiff(date) < -1 || daysDiff(date) > 0 || isDateTomorrow(date)
      ? 'D.MM'
      : 'HH:mm',
  )

export const areStockNonStockDeliveryDatesEqual = (
  productDeliveryOptions,
  closestDeliveryTime,
) => {
  const headDeliveryDate = compose(
    date => dayjs(date),
    prop('deliveryDate'),
    head,
  )(productDeliveryOptions)
  return headDeliveryDate.isSame(closestDeliveryTime, 'd')
}

export const getUnavailableMsg = ({
  closestDeliveryTime,
  withdrawingSoon,
  deadlineOrderTime,
  receiptDate,
  nonStock,
  labelName,
  unitOfMeasureObj,
  isSoonAvailable,
  productDeliveryOptions,
  hasLongUnavailableMsg,
  hasLongNonStockMsg,
}) => {
  const { isStockLimited, inCartQuantity } = unitOfMeasureObj
  const stock = propOr(0, 'stock', unitOfMeasureObj)
  if (isSoonAvailable) {
    return {
      type: UNAVAILABILITY_TYPES.AVAILABLE_SOON,
      message: (
        <FormattedMessage
          {...messages.availableFrom}
          values={{ date: dayjs(closestDeliveryTime).format('DD.MM') }}
        />
      ),
    }
  }

  // non stock
  if (nonStock) {
    const type = UNAVAILABILITY_TYPES.NON_STOCK
    if (stock > 0) {
      if (
        inCartQuantity > stock &&
        !areStockNonStockDeliveryDatesEqual(
          productDeliveryOptions,
          closestDeliveryTime,
        )
      ) {
        const elasticMsgKey = hasLongNonStockMsg
          ? 'elasticDeliveryOnLong'
          : 'elasticDeliveryOn'
        return {
          type,
          message: (
            <FormattedRichMessage
              {...messages[elasticMsgKey]}
              values={{
                date: getDate(closestDeliveryTime),
              }}
            />
          ),
        }
      }

      return { message: null }
    }

    const nonStockMsgKey = hasLongNonStockMsg
      ? 'collectedFromToLong'
      : 'collectedFromTo'
    return {
      type,
      message: (
        <FormattedRichMessage
          {...messages[nonStockMsgKey]}
          values={{
            fromDate: getValidDeadline(deadlineOrderTime),
            toDate: getDate(closestDeliveryTime),
          }}
        />
      ),
    }
  }
  // unavailable till...
  if (stock === 0) {
    if (withdrawingSoon) {
      return {
        message: <FormattedMessage {...messages.notActiveNotification} />,
        type: UNAVAILABILITY_TYPES.WITHDRAWING_SOON,
      }
    }

    const unavailableMsgKey = hasLongUnavailableMsg
      ? 'unavailableLong'
      : 'unavailable'
    const unavailableMsg = isStockLimited
      ? 'unavailableLimited'
      : unavailableMsgKey

    const message = receiptDate ? (
      <FormattedMessage
        {...messages.unavailableTill}
        values={{
          value: dayjs(receiptDate).format('D.MM.YYYY'),
        }}
      />
    ) : (
      <FormattedMessage {...messages[unavailableMsg]} />
    )

    return {
      message,
      type: isStockLimited ? UNAVAILABILITY_TYPES.LIMITED_STOCK : undefined,
    }
  }

  // in stock, low etc.
  if (labelName) {
    return { message: <span>{labelName}</span> }
  }

  return { message: null }
}

export const formatHumanDate = ({ date, formatMessage }) => {
  if (isDateToday(date)) {
    return formatMessage(messages.today)
  }
  if (isDateTomorrow(date)) {
    return formatMessage(messages.tomorrow)
  }

  return dayjs(date).format('DD.MM')
}

export const formatRegularDate = date => dayjs(date).format('YYYY-MM-DD')
