import { makeMessages, makeTranslation } from 'utils'

const messages = {
  categories: 'categories',
  showAll: 'showAll',
  products: 'products',
  noResults: { id: 'searchResults.noResults' },
}

export default makeMessages(messages, makeTranslation('searchSuggest'))
