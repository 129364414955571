import React from 'react'
import { useIntl } from 'react-intl'

import { formatPrice } from 'utils'
import { FLOATING_PRICE_ORDER_STATUS } from 'views/Orders/consts'
import InfoBox from './InfoBox'
import TotalSumWrapper from './TotalSumWrapper'

import messages from '../../messages'
import { ColumnHeader } from '../components/Column/styles'
import {
  OrderSummaryContainer,
  RowsContainer,
  OrderSummaryRow,
  TopOrderSummaryRow,
  BottomOrderSummaryRow,
  InnerContainer,
  RowLabel,
  RowText,
  BigRowLabel,
  BigRowText,
} from './styles'

const OrderSummary = ({ orderDetails }) => {
  const { formatMessage } = useIntl()

  const {
    orderPaymentMethod,
    totalNet,
    totalGross,
    anyFloatingPrice,
    orderStatusId,
    logisticLackAmount,
    routeDeadline,
  } = orderDetails

  const hasLogisticLimitInfo = logisticLackAmount && routeDeadline
  const hasFloatingPrice =
    anyFloatingPrice && FLOATING_PRICE_ORDER_STATUS.includes(orderStatusId)

  return (
    <OrderSummaryContainer data-test-id="order-summary-column">
      <ColumnHeader>{formatMessage(messages.paymentSummary)}</ColumnHeader>
      <RowsContainer>
        <TopOrderSummaryRow>
          <RowLabel>{formatMessage(messages.payment)}</RowLabel>
          <RowText>{orderPaymentMethod}</RowText>
        </TopOrderSummaryRow>
        <InnerContainer>
          <OrderSummaryRow>
            <RowLabel>{formatMessage(messages.amountNet)}</RowLabel>
            <RowText>{formatPrice(totalNet)}</RowText>
          </OrderSummaryRow>
          <OrderSummaryRow>
            <RowLabel>{formatMessage(messages.orderVAT)}</RowLabel>
            <RowText>{formatPrice(totalGross - totalNet)}</RowText>
          </OrderSummaryRow>
          <OrderSummaryRow>
            <RowLabel>{formatMessage(messages.delivery)}</RowLabel>
            <RowText>{formatMessage(messages.free)}</RowText>
          </OrderSummaryRow>
        </InnerContainer>
        <BottomOrderSummaryRow>
          <BigRowLabel>{formatMessage(messages.amountGross)}</BigRowLabel>
          <TotalSumWrapper
            hasLogisticLimitInfo={hasLogisticLimitInfo}
            hasFloatingPrice={hasFloatingPrice}
          >
            <BigRowText>{formatPrice(totalGross)}</BigRowText>
          </TotalSumWrapper>
        </BottomOrderSummaryRow>
      </RowsContainer>
      <InfoBox
        orderDetails={orderDetails}
        hasLogisticLimitInfo={hasLogisticLimitInfo}
        hasFloatingPrice={hasFloatingPrice}
      />
    </OrderSummaryContainer>
  )
}

export default OrderSummary
