import React from 'react'
import { useDispatch } from 'react-redux'

import { getPromoGroupActions } from 'containers/Promotions/Groups/actions'

import { PromoGroupWrapper } from './styles'

const PromoGroup = ({ children, groupId, showModal }) => {
  const dispatch = useDispatch()

  if (showModal) {
    const onPromoNameClick = () =>
      dispatch(getPromoGroupActions.delta({ groupId }))

    return (
      <PromoGroupWrapper
        onClick={e => {
          e.preventDefault()
          onPromoNameClick()
        }}
        data-test-id="promo-group-wrapper"
      >
        {children}
      </PromoGroupWrapper>
    )
  }

  return children
}

export default PromoGroup
