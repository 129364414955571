import React from 'react'

const DottedLine = () => (
  <svg
    width="2"
    height="48"
    viewBox="0 0 2 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L1 48"
      stroke="#131B4D"
      strokeWidth="2"
      strokeLinecap="square"
      strokeDasharray="0.01 4"
    />
  </svg>
)

export default DottedLine
