import { rtkApi } from 'services/api/rtkApi'
import { formatFiltersParams } from 'containers/Products/utils'

const categoriesApi = rtkApi.injectEndpoints({
  endpoints: build => ({
    getCategoryTree: build.query({
      query: (params = {}) => {
        const queryParams = formatFiltersParams(params)

        return {
          url: queryParams
            ? `categories_tree?${queryParams}`
            : 'categories_tree',
        }
      },
    }),
    getCuisines: build.query({
      query: () => ({
        url: 'me/cuisines',
      }),
    }),
  }),
})

export const { useGetCategoryTreeQuery, useGetCuisinesQuery } = categoriesApi
