import React from 'react'
import { useIntl } from 'react-intl'
import { useModal } from '@ebay/nice-modal-react'

import TextTooltip from 'components/Tooltip/TextTooltip'
import { Trash as TrashIcon } from 'components/Icons'
import RemoveItemModal from './RemoveItemModal'

import messages from './messages'
import { RowIconBtn } from '../styles'

const RemoveItemBtn = ({ orderId, orderItem, isDesktop }) => {
  const removeItemModal = useModal(RemoveItemModal)
  const { formatMessage } = useIntl()
  const { actions, product, id } = orderItem
  const { isAvailable } = actions?.removing || {}

  return (
    <TextTooltip
      disabled={!isDesktop}
      content={
        isAvailable
          ? formatMessage(messages.removeTooltip)
          : formatMessage(messages.cannotRemoveTooltip)
      }
    >
      <RowIconBtn
        onClick={() =>
          removeItemModal.show({
            productName: product.name,
            itemId: id,
            orderId,
          })
        }
        disabled={!isAvailable}
        data-test-id={`remove-item-btn#${product.id}`}
      >
        <TrashIcon />
      </RowIconBtn>
    </TextTooltip>
  )
}

export default RemoveItemBtn
