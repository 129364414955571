import React from 'react'
import { FormattedMessage } from 'react-intl'

import container from 'containers/Products/Replacements'
import ModalClose from 'components/modal/ModalClose'

import messages from './messages'
import NoReplacements from './NoReplacements'
import ReplacementsList from './ReplacementsList'

const Replacements = ({
  replacementProducts,
  modal,
  addToTemplate,
  removeFromTemplate,
  getTemplateItems,
  templateDetailsPagination,
}) => {
  const handleGetTemplateItems = () =>
    getTemplateItems(
      {
        id: modal.templateId,
        current: templateDetailsPagination.currentPage,
      },
      { discardPrevItems: true },
    )

  return (
    <div>
      <ModalClose
        heading={<FormattedMessage {...messages.modalTitle} />}
        onClick={modal.closeModal}
        dataTestId="close_replacements_modal"
      />

      {replacementProducts.length ? (
        <ReplacementsList
          replacements={replacementProducts}
          onProductDetailsClick={modal.closeModal}
          hideBookmark
          templateId={modal.templateId}
          onAddToTemplate={addToTemplate}
          onRemoveFromTemplate={removeFromTemplate}
          onGetTemplateItems={handleGetTemplateItems}
        />
      ) : (
        <NoReplacements />
      )}
    </div>
  )
}

export default container(Replacements)
