import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { H3, H4Primary } from 'components/Text'

import theme from 'theme'
import {
  getSpacing,
  getFontWeights,
  getColors,
} from 'components/helpers/theme-selectors'

import { APP_BREAKPOINTS } from 'consts'

export const MainWrapper = styled(Flex)`
  width: 100%;
  max-width: 1440px;
  flex-direction: column;
  position: relative;
`

export const CartWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 2000px;
  width: 100%;
  padding: 0 ${theme.spacing.xs};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: ${theme.spacing.md};
    padding-bottom: 0;
  }
`

export const ProductsColumn = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

export const CartContent = styled(Flex)`
  width: 100%;
  flex-direction: column;
`

export const BigHeader = styled(H3)`
  padding-top: ${props => getSpacing(props).md};
  margin-top: 0;
  margin-bottom: ${props => getSpacing(props).sm};
  font-weight: ${props => getFontWeights(props).strongBold};
  color: ${props => getColors(props).black};
`
export const ProductsBigHeader = styled(BigHeader)`
  padding-top: 0;
  margin-top: 0;
`

// prettier-ignore
export const SectionHeader = styled(H4Primary)`
  flex-shrink: 0;
  min-width: 306px;
  margin-right: ${p => getSpacing(p).md};
  margin-bottom: ${p => getSpacing(p).md};

  b {
    color: ${p => getColors(p).secondary};
  }

  ${p => p.borderedTop && `
    padding-top: ${getSpacing(p).sm};
    border-top: 1px solid ${getColors(p).gray2};
  `};
`

export const GreyWrapper = styled(Flex)`
  flex-direction: column;
  margin: 0 calc(50% - 50vw + ${p => p.scrollbarWidth});
  padding: 0 calc(50vw - 50% - ${p => p.scrollbarWidth});
  background-color: #eceef0;

  ${p => p.withBorder && `border-top: 1px solid ${theme.colors.gray2}`};
`

/* eslint-disable indent */
export const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  background-color: ${p =>
    p.isRedError ? theme.colors.semiTranspBg.locked : theme.colors.orangeLight};
  box-shadow: inset 0px 0px 0px 2px rgb(0 0 0 / 10%);
  margin-top: -2px;
  padding: 0 ${theme.spacing.base};

  @media screen and (min-width: ${APP_BREAKPOINTS.TMP_MAIN}px) {
    margin: 0 ${theme.spacing.md};
  }

  > *:not(span) {
    flex: 1;
  }

  > div:last-child {
    display: flex;
    justify-content: flex-end;

    > figure {
      cursor: pointer;
      color: ${p =>
        p.isRedError ? theme.colors.redDark : theme.colors.orangeDark};
      margin: 0;
      margin-left: ${theme.spacing.sm};
    }
  }

  > span {
    color: ${p =>
      p.isRedError ? theme.colors.redDark : theme.colors.orangeDark};
    font-size: 14px;
  }
`
