/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ROUTES } from 'consts'
import { PageTitle, P } from 'components/Text'

import messages from './messages'
import {
  NotFoundContent,
  NotFoundButton,
  NotFoundWrapper,
} from './styledComponents'

/* eslint-disable react/prefer-stateless-function */
export default class NotFound extends React.PureComponent {
  render() {
    return (
      <NotFoundWrapper>
        <NotFoundContent>
          <PageTitle spacedBottom>
            <FormattedMessage {...messages.notFoundPage} />
          </PageTitle>

          <P bold style={{ marginBottom: '32px' }}>
            <FormattedMessage {...messages.checkUrl} />
          </P>

          <NotFoundButton to={ROUTES.DASHBOARD}>
            <FormattedMessage {...messages.returnToHome} />
          </NotFoundButton>
        </NotFoundContent>
      </NotFoundWrapper>
    )
  }
}
