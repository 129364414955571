import { makeMessages, makeTranslation } from 'utils'

const messages = {
  notNeeded: 'notNeeded',
  orderedElsewhere: 'orderedElsewhere',
  wantToModify: 'wantToModify',
  wrongPrice: 'wrongPrice',
  orderByMistake: 'orderByMistake',
  other: 'other',
  inputPlaceholder: { id: 'contactForm.descPlaceholder' },
}

export default makeMessages(
  messages,
  makeTranslation('cancelOrderModal.reasons'),
)
