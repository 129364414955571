import styled from 'styled-components'

import theme from 'theme'
import { BaseText, PPrimary } from 'components/Text'
import ModalBody from 'components/modal/ModalBody'

import dateMovedImg from 'assets/DateMoved.png'

// used in withModal to override parent container
export const ModalRoot = styled(ModalBody)`
  min-width: calc(100% - 24px);
  width: calc(100% - 24px);
  padding: ${theme.spacing.sm};

  @media screen and (min-width: 768px) {
    min-width: 604px;
    padding: 32px;
  }
`

export const TitleContainer = styled.header`
  display: flex;
  flex-flow: row;
`

export const Title = styled(BaseText).attrs({ as: 'h2' })`
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.secondary};
  font-weight: 600;

  @media screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`

export const Msg = styled(PPrimary)`
  margin-top: ${theme.spacing.base};
  line-height: 24px;

  @media screen and (min-width: 768px) {
    margin-top: 32px;
  }
`

export const CtaBtnContainer = styled.div`
  margin-top: ${theme.spacing.xs};

  > button {
    background-color: ${theme.colors.secondaryBgLight};
    color: ${theme.colors.secondary};
    font-size: 18px;
    line-height: 20px;
    height: 56px;

    &:hover {
      /* TODO proper hover color */
      background-color: ${theme.colors.secondaryBgLight};
    }
  }
`

export const CloseIconContainer = styled.div`
  margin-left: ${theme.spacing.sm};
  margin-top: ${theme.spacing.xs};
  cursor: pointer;
`

export const ImgContainer = styled.div`
  margin-top: ${theme.spacing.base};
  background-image: url(${dateMovedImg});
  background-position: center;
  height: 200px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-top: 32px;
  }
`
