import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { BaseTextV2 } from 'components/Text'
import { BasicButtonV2 } from 'components/Button'

export const OrderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.xs};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-bottom: ${theme.spacing.sm};
  }
`

export const OrdersDetailsBtn = styled(BasicButtonV2)`
  display: flex;
  gap: ${theme.spacing.sm};
  background: ${theme.colors.lightGrey7};
  color: ${theme.colors.primary};
  font-size: ${theme.textSizes.xs};
  font-weight: ${theme.fontWeights.regular};

  :hover {
    background: ${theme.colors.lightGreyHover};
    color: ${theme.colors.primary};
  }
`

export const OrderBtnTitle = styled.span`
  display: inline-flex;
  gap: 10px;
`

export const OrderBtnPrice = styled.span`
  color: ${theme.colors.lightGrey4};
`

export const AllOrdersBtn = styled(BaseTextV2).attrs({
  as: 'button',
  isSmall: { fontSize: true },
})`
  border: 0;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  background-color: transparent;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease-in;

  &:hover {
    background-color: ${theme.colors.lightGrey7};
  }
`
