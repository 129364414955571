import React from 'react'

const Pizza = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_34121_130855)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0168 8.80506C14.3116 7.20528 13.3131 5.70353 12.0215 4.38132L11.7684 4.12823C10.4454 2.83585 8.9427 1.837 7.3419 1.13169C6.83677 0.909134 6.26185 1.18007 6.07321 1.69881L3.9405 7.56378C3.90055 7.67363 3.96355 7.79325 4.07145 7.83821C4.27134 7.9215 4.45861 8.04445 4.62123 8.20708C5.28794 8.87379 5.28794 9.95473 4.62123 10.6214C4.15692 11.0858 3.49168 11.2267 2.90539 11.0443C2.79386 11.0096 2.66884 11.0608 2.62892 11.1706L2.01953 12.8464C1.72934 13.6445 2.50304 14.4182 3.30106 14.128L8.40052 12.2736C8.56031 12.2155 8.58379 11.9882 8.45825 11.8735C8.436 11.8532 8.4141 11.8323 8.39257 11.8108C7.62131 11.0395 7.62131 9.78906 8.39257 9.0178C9.16383 8.24654 10.4143 8.24654 11.1855 9.0178C11.6384 9.47063 11.8253 10.0887 11.7464 10.6779C11.7238 10.8464 11.8878 11.0055 12.0476 10.9474L14.4497 10.0739C14.9686 9.88526 15.2395 9.31021 15.0168 8.80506ZM7.05652 6.18188C7.6423 6.76766 8.59205 6.76766 9.17784 6.18188C9.76362 5.59609 9.76362 4.64634 9.17784 4.06055C8.59205 3.47477 7.6423 3.47477 7.05652 4.06056C6.47073 4.64634 6.47073 5.59609 7.05652 6.18188Z"
        fill="#A6B3C0"
      />
    </g>
    <defs>
      <clipPath id="clip0_34121_130855">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Pizza
