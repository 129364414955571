import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { trackSortingClick } from 'services/analytics'
import Dropdown from 'components/Filters/Dropdown'
import { BaseTextV2 } from 'components/Text/texts'
import { InteractiveWrapper } from 'components/Button/styles'
import { SORT_BY_TYPES, DEFAULT_SORT_BY_TYPES } from 'containers/Templates'

import messages from './messages'
import { TemplateSortingContainer, SortingDropdownContainer } from './styles'

const TemplateSorting = ({
  id,
  type,
  getSortedItems,
  currentSorting,
  isTemplateAutomatic,
}) => {
  const { formatMessage } = useIntl()
  const [currentSortingObj, setCurrentSortingObj] = useState({})

  useEffect(
    () => {
      const newObj = sortingConfig.find(
        option => option.type === currentSorting,
      )

      setCurrentSortingObj(newObj || {})
    },
    [currentSorting],
  )

  const { NAME, PURCHASE_DATE } = SORT_BY_TYPES
  const { SYSTEM, USER } = DEFAULT_SORT_BY_TYPES

  const handleSortingSelection = value => {
    if (value !== currentSorting) {
      getSortedItems(value)
    }
  }

  const sortByName = {
    title: 'name',
    type: NAME,
    handleClick: () => handleSortingSelection(NAME),
  }

  const systemTemplateOptions = [
    {
      title: 'default',
      type: SYSTEM,
      handleClick: () => handleSortingSelection(SYSTEM),
    },
    {
      title: 'purchaseDate',
      type: PURCHASE_DATE,
      handleClick: () => handleSortingSelection(PURCHASE_DATE),
    },
    sortByName,
  ]

  const userTemplateOptions = [
    {
      title: 'createdAt',
      type: USER,
      handleClick: () => handleSortingSelection(USER),
    },
    sortByName,
  ]

  const sortingConfig = isTemplateAutomatic
    ? systemTemplateOptions
    : userTemplateOptions

  const dropdownTitle = currentSortingObj.title
  const isSortingObj = Object.keys(currentSortingObj).length

  if (isSortingObj) {
    return (
      <TemplateSortingContainer>
        <Dropdown
          title={formatMessage(messages[dropdownTitle])}
          onDropdownShow={() => trackSortingClick(id, type)}
          maxWidth={220}
          testId="template-sorting-btn"
          isDropdownInvert
          calculatePositionFromParent={{ left: -72 }}
          portalNode={document.getElementById('template-list-toolbar')}
        >
          {closeDropdown => (
            <SortingDropdownContainer>
              {sortingConfig.map(({ title, handleClick }) => (
                <InteractiveWrapper
                  onClick={() => {
                    handleClick()
                    closeDropdown()
                  }}
                  data-test-id={`template-sortBy-${title}`}
                  key={title}
                >
                  <BaseTextV2>{formatMessage(messages[title])}</BaseTextV2>
                </InteractiveWrapper>
              ))}
            </SortingDropdownContainer>
          )}
        </Dropdown>
      </TemplateSortingContainer>
    )
  }

  return null
}

export default TemplateSorting
