import React from 'react'

import { MinusV2, PlusV2 } from 'components/Icons'

import UnitQuantityInput from './UnitQuantityInput'
import { QuantityBtnWrapper, QuantityControlsWrapper } from './styles'

const QuantityControls = ({
  product,
  unitData,
  disabled,
  quantity,
  onQuantityChange,
  minValue,
}) => {
  const handleQuantityChange = newValue => {
    if (newValue < minValue) {
      onQuantityChange(minValue)
    } else {
      onQuantityChange(newValue)
    }
  }

  return (
    <QuantityControlsWrapper>
      <QuantityBtnWrapper
        data-test-id={`minus_${product.id}`}
        disabled={disabled || quantity === minValue}
        onClick={() => handleQuantityChange(quantity - 1)}
      >
        <MinusV2 />
      </QuantityBtnWrapper>
      <UnitQuantityInput
        data-test-id={`input_${product.id}`}
        unitData={unitData}
        value={quantity}
        onBlur={handleQuantityChange}
        disabled={disabled}
      />
      <QuantityBtnWrapper
        data-test-id={`plus_${product.id}`}
        disabled={disabled}
        onClick={() => handleQuantityChange(quantity + 1)}
      >
        <PlusV2 />
      </QuantityBtnWrapper>
    </QuantityControlsWrapper>
  )
}

export default QuantityControls
