import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { reduxForm } from 'redux-form/immutable'

import { Password } from 'components/Fields'
import { PrimaryBtn } from 'components/Button'
import { changePasswordErrorSelector } from 'containers/Profile/ChangePassword/selectors'
import { changePasswordActions } from 'containers/Profile/ChangePassword/actions'
import { CHANGE_PASSWORD_FORM_NAME } from 'containers/Profile/ChangePassword/consts'
import { makeSchemaValidator } from 'utils/validators'
import { isPasswordInvalidError } from 'containers/Profile/ChangePassword/utils'
import Panel from 'components/Panel'

import { Header } from '../components/styledComponents'
import ServerError from '../components/ServerError'
import profileMessages from '../messages'

import messages from './messages'
import { InnerContainer, ConfirmPasswordContainer } from './styledComponents'
import validationSchema from './validationSchema'

const ChangePassword = ({ submitting, handleSubmit }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const changePasswordError = useSelector(changePasswordErrorSelector)
  const submitPasswordChange = () =>
    dispatch(changePasswordActions.createDelta())
  const clearPasswordChange = () => dispatch(changePasswordActions.clear())

  useEffect(() => () => clearPasswordChange(), [])

  return (
    <Panel>
      <Header>{formatMessage(profileMessages.changePassword)}</Header>

      <form onSubmit={handleSubmit(submitPasswordChange)} noValidate>
        <InnerContainer>
          {isPasswordInvalidError(changePasswordError) && (
            <ServerError
              message={formatMessage(messages.invalidPasswordError)}
            />
          )}

          <Password
            name="oldPassword"
            label={formatMessage(messages.password)}
            dataAttr="change_password_old_password"
          />
          <ConfirmPasswordContainer>
            <Password
              name="newPassword"
              label={formatMessage(messages.newPassword)}
              dataAttr="change_password_new_password"
              hint={formatMessage(messages.newPasswordHint)}
            />
          </ConfirmPasswordContainer>
          <PrimaryBtn
            disabled={submitting}
            data-test-id="change_password_submit"
          >
            {formatMessage(messages.ctaButton)}
          </PrimaryBtn>
        </InnerContainer>
      </form>
    </Panel>
  )
}

export default reduxForm({
  form: CHANGE_PASSWORD_FORM_NAME,
  validate: makeSchemaValidator(validationSchema),
})(ChangePassword)
