import React from 'react'
import { Flex } from '@rebass/grid'
import styled, { css } from 'styled-components'

import theme from 'theme'

import {
  ArrowLongDown,
  DottedVertical,
  Location,
  TruckSpeed,
} from 'components/Icons'

const lineCss = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

export const Dotted = styled(DottedVertical)`
  ${lineCss};
  top: 24px;
`

export const TruckIcon = styled(props => (
  <div {...props}>
    <TruckSpeed />
    <Dotted />
  </div>
))`
  position: relative;
  margin-right: ${theme.spacing.base};
`

export const ColoredBox = styled(Flex)`
  background-color: #f5f7f9;
  padding: 12px 16px;
`

const Arrow = styled(ArrowLongDown)`
  ${lineCss};
  top: -39px;
`
export const LocationIcon = styled(props => (
  <div {...props}>
    <Location />
    <Arrow />
  </div>
))`
  position: relative;
  margin-right: ${theme.spacing.base};
`
