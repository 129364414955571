import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import { SELECT_TYPE } from 'components/Filters/consts'
import MultiFilter from './MultiFilter'
import ButtonFilter from './ButtonFilter'

class Filter extends PureComponent {
  state = {
    items: [],
    selectedItems: {},
    selectedFilters: {},
  }

  static getDerivedStateFromProps({ items, selectedItems }, prevState) {
    if (
      items === prevState.items &&
      selectedItems === prevState.selectedItems
    ) {
      return null
    }
    return {
      items,
      selectedItems,
      selectedFilters: { ...selectedItems },
    }
  }

  handleFiltersClear = callback => {
    this.setState({ selectedFilters: {} }, callback)
  }

  handleFiltersApply = () => {
    this.props.onApplyFilters(this.props.name, this.state.selectedFilters)
  }

  handleFiltersChange = (selectedFilters, callback) => {
    this.setState({ selectedFilters }, callback)
  }

  handleFiltersReset = () => {
    this.setState(state => ({ selectedFilters: { ...state.selectedItems } }))
  }

  render() {
    const { intl, type = SELECT_TYPE.MULTIPLE } = this.props

    if (type === SELECT_TYPE.MULTIPLE) {
      return (
        <MultiFilter
          {...this.props}
          {...this.state}
          formatMessage={intl.formatMessage}
          onApply={this.handleFiltersApply}
          onClear={this.handleFiltersClear}
          onChange={this.handleFiltersChange}
          onReset={this.handleFiltersReset}
        />
      )
    }

    if (type === SELECT_TYPE.BUTTON || type === SELECT_TYPE.GROUP_BUTTON) {
      return (
        <ButtonFilter
          {...this.props}
          {...this.state}
          onApply={this.handleFiltersApply}
          onChange={this.handleFiltersChange}
        />
      )
    }

    return null
  }
}

export default injectIntl(Filter)
