import styled from 'styled-components'
import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.vertical};
  padding: ${theme.spacing.vertical} 0;
  border-top: 1px solid ${theme.colors.semiTranspBg.gray};
  border-bottom: 1px solid ${theme.colors.semiTranspBg.gray};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    gap: ${theme.spacing.base};
    padding: ${theme.spacing.md} 0 ${theme.spacing.vertical} 0;
  }
`
