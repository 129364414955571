import { rtkApi } from 'services/api/rtkApi'
import { getPhoneNumber } from 'utils'

const authApi = rtkApi.injectEndpoints({
  endpoints: build => ({
    signUp: build.mutation({
      query: data => {
        const { phoneNo, deliveryCountryCode: codeObj, ...formValues } = data
        const phoneNumber = getPhoneNumber({ codeObj, number: phoneNo })

        return {
          url: 'signup',
          method: 'POST',
          data: {
            ...formValues,
            phoneNo: phoneNumber,
            deliveryCountryCode: codeObj.value,
          },
        }
      },
    }),
    checkLogin: build.mutation({
      query: data => ({
        url: 'signup/check_login',
        method: 'POST',
        data,
      }),
    }),
    getCompany: build.query({
      query: tin => ({
        url: `companies/${tin}`,
        meta: {
          suppress404Redirect: true,
        },
      }),
      serializeQueryArgs: ({ queryArgs }) => queryArgs.tin,
    }),
  }),
})

export const {
  useSignUpMutation,
  useCheckLoginMutation,
  useLazyGetCompanyQuery,
} = authApi
