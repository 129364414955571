import { handleActions } from 'redux-actions'
import { omit, always } from 'lodash/fp'

import { invoicesToPayActions, getInvoicesActions } from './actions'

export const invoicesToPayReducer = handleActions(
  {
    [getInvoicesActions.SUCCESS]: (state, { data: { invoices } }) => {
      let newState = state

      Object.entries(state).forEach(([, invoice]) => {
        if (!invoices.some(e => e.id === invoice.id)) {
          newState = omit([invoice.id], newState)
        } else {
          const updInvoice = invoices.find(e => e.id === invoice.id)

          newState = { ...newState, [invoice.id]: updInvoice }
        }
      })

      return newState
    },
    [invoicesToPayActions.toggleSelected]: (
      state,
      { payload: { invoice } },
    ) => {
      const { id } = invoice
      if (state[id]) {
        return omit([id], state)
      }
      return {
        ...state,
        [id]: invoice,
      }
    },
    [invoicesToPayActions.clearSelected]: always({}),
    [invoicesToPayActions.selectMultiple]: (state, { payload: { invoices } }) =>
      invoices.reduce((acc, invoice) => {
        if (acc[invoice.id]) return acc

        return {
          ...acc,
          [invoice.id]: invoice,
        }
      }, state),
  },
  {},
)
