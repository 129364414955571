import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { animated } from '@react-spring/web'

import theme from 'theme'

import { INVOICES_DESKTOP_START } from 'views/Invoices/consts'
import { H4v2, TinyTextV2Secondary } from 'components/Text'
import { PrimaryBtnV2, SecondaryBtnV2 } from 'components/Button/styles'

// prettier-ignore
export const PaySummaryContainer = styled(animated.div)`
  margin: -${theme.spacing.sm};
  margin-top: 0;
  position: sticky;
  bottom: 0;
  z-index: ${theme.zIndex.base};

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    width: 100%;
    margin: 0;
  }
`

/* prettier-ignore */
export const PaySummaryInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-top: 1px solid ${theme.colors.borderFaded};
  background: ${theme.colors.white};
  justify-content: space-between;

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    width: auto;
    padding: 16px 0;
    flex-direction: row;
  }
`

export const SmallText = styled(TinyTextV2Secondary)``

export const TotalToPay = styled(H4v2)`
  font-weight: 600;
  line-height: 24px;
`

const TextContainer = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    flex-direction: column;
    justify-content: center;
  }
`

export const CountContainer = styled(Flex)`
  gap: 16px;
  align-items: center;
`

export const InvoiceIconContainer = styled(Flex)`
  height: 32px;
  width: 32px;
  display: none;
  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    display: block;
  }
`

export const InnerCountContainer = styled(TextContainer)`
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${theme.colors.borderFaded};
  flex-grow: 1;

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    align-items: flex-start;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`

export const ToPayContainer = styled(TextContainer)`
  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    align-items: flex-end;
  }
`

export const ActionsContainer = styled(Flex)`
  gap: 12px;
  flex-direction: column;

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    gap: 24px;
    flex-direction: row;
  }
`

export const PayBtn = styled(PrimaryBtnV2)`
  width: 100%;

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    width: 178px;
  }
`

export const OverpaymentsBtn = styled(SecondaryBtnV2)`
  width: 100%;

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    width: auto;
  }
`

export const ButtonsContainer = styled(Flex)`
  gap: 12px;

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    gap: 24px;
  }
`
