import { makeMessages, makeTranslation } from 'utils'

const messages = {
  basicInfo: 'basicInfo',
  ingredientsTitle: 'ingredientsTitle',
  parametersTitle: 'parametersTitle',
  priceGross: { id: 'prices.gross' },
  soldFor: { id: 'product.soldFor' },
  notActiveNotification: 'notActiveNotification',
  deadlineOrderDate: { id: 'dashboard.deadlineOrderDate' },
  floatingPriceInfo: { id: 'floatingPrice.tooltipText' },
  replacements: { id: 'common.actions.replacements' },
  replacementsBtn: { id: 'prices.replacementButton' },
  packaging: 'packaging',
  frequentlyBoughtTogether: 'frequentlyBoughtTogether',
}

export default makeMessages(messages, makeTranslation('productDetails'))
