import React from 'react'

import { ModalGlobalStyle } from 'containers/App/styledComponents'

import { PORTAL_MODAL_VARIANTS } from './consts'
import { PortalModalOverlay, ModalStyled } from './styles'

const PortalModal = ({
  isOpen,
  onClose,
  children,
  className,
  testId,
  variant = PORTAL_MODAL_VARIANTS.BOTTOM,
}) => (
  <ModalStyled
    $variant={variant}
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayElement={(props, contentElement) => (
      <>
        <ModalGlobalStyle />
        <PortalModalOverlay {...props} $variant={variant}>
          {contentElement}
        </PortalModalOverlay>
      </>
    )}
    className={className}
    data-test-id={testId}
  >
    {children}
  </ModalStyled>
)

export default PortalModal
