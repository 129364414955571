import React from 'react'
import { FormattedMessage } from 'react-intl'
import { formatPrice } from 'utils'
import { ROUTES } from 'consts'
import messages from './messages'

export const formatSavingsLabel = savings => (
  <FormattedMessage
    {...messages.savingsLabel}
    values={{
      savings: formatPrice(savings, {
        withoutCurrency: true,
      }),
    }}
  />
)

export const isCartRoute = location => location.pathname === ROUTES.CART
