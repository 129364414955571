import React from 'react'
import { useIntl } from 'react-intl'

import { PROFILE_ROUTES } from 'containers/Profile/consts'
import { Settings } from 'components/Icons/NavMenu'
import OtherRow from '../OtherRow'

import messages from '../messages'
import { OtherRowLink } from '../styles'

const ChangePassword = () => {
  const { formatMessage } = useIntl()

  return (
    <OtherRowLink
      data-test-id="profile-change-password"
      to={PROFILE_ROUTES.CHANGE_PASSWORD}
    >
      <OtherRow
        text={formatMessage(messages.changePassword)}
        icon={<Settings />}
      />
    </OtherRowLink>
  )
}

export default ChangePassword
