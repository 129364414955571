import React from 'react'

const Ramen = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.29424 9.48828L22.0016 6.00013"
      stroke="#241C17"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14 5L14 15"
      stroke="#FDD978"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8.64674 7.07353L22.9998 1.50005"
      stroke="#241C17"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M5 21V19.7735C5 19.493 4.88087 19.2271 4.68028 19.0311C3.46085 17.8393 2.32178 16.2475 2.05743 13.9983C1.99296 13.4498 2.44772 13 3 13H21C21.5523 13 22.007 13.4498 21.9426 13.9983C21.6782 16.2475 20.5392 17.8393 19.3197 19.0311C19.1191 19.2271 19 19.493 19 19.7735V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21Z"
      fill="#241C17"
    />
    <path
      d="M11 10.9999L11 5.99984C11 3.99984 12.5 3.50006 13.5 4"
      stroke="#FDD978"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default Ramen
