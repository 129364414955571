import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  didntGetMessage: 'didntGetMessage',
  toContinue: { id: 'signUpSuccess.toContinue' },
  toDoThis: 'toDoThis',
  needHelp: 'needHelp',
  email: { id: 'support.mail' },
  phone: { id: 'support.phone' },
}

export default makeMessages(messages, makeTranslation('verifyEmail'))
