import styled from 'styled-components'

import { BaseTextV2Secondary } from 'components/Text'

export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProductDetailsText = styled(BaseTextV2Secondary)`
  font-size: 14px;
`
