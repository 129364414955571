import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { dashboardTilesDisplaySelector } from 'containers/App/selectors'
import { setDashboardTiles } from 'containers/App/actions/dashboardTiles'

import { setBrowserDashboardLayout } from 'views/Dashboard/utils'
import { DashboardTileControlsWrapper } from 'views/Dashboard/TileControls/styles'
import {
  DashboardA,
  DashboardB,
  DashboardCollapse,
  DashboardExpand,
} from 'components/Icons'
import {
  EVENT_ORIGIN,
  trackDashboardLayoutChange,
  trackSwitchDashboardCollapseState,
} from 'services/analytics'
import { TertiaryDashboardBtn } from 'views/Dashboard/styles'
import TextTooltip from 'components/Tooltip/TextTooltip'

import messages from './messages'

const TileControls = ({ isTopCampaignsLayout, setTopCampaignsLayout }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleChangeLayout = state => {
    trackDashboardLayoutChange({
      origin: EVENT_ORIGIN.DASHBOARD,
      targetState: state ? 'campaigns_first' : 'campaigns_last',
    })
    setBrowserDashboardLayout(state)
    setTopCampaignsLayout(state)
  }

  const areTilesOpen = useSelector(dashboardTilesDisplaySelector)
  const toggleDashboardTilesCollapse = () => {
    trackSwitchDashboardCollapseState({
      origin: EVENT_ORIGIN.DASHBOARD,
      targetState: !areTilesOpen ? 'expanded' : 'collapsed',
    })
    dispatch(setDashboardTiles(!areTilesOpen))
  }

  return (
    <DashboardTileControlsWrapper>
      <TextTooltip content={formatMessage(messages.layoutBtn)}>
        <TertiaryDashboardBtn
          data-test-id="switch-layout-btn"
          onClick={() => handleChangeLayout(!isTopCampaignsLayout)}
        >
          {isTopCampaignsLayout ? <DashboardB /> : <DashboardA />}
        </TertiaryDashboardBtn>
      </TextTooltip>
      <TextTooltip
        content={formatMessage(
          areTilesOpen ? messages.collapseBtn : messages.expandBtn,
        )}
      >
        <TertiaryDashboardBtn
          data-test-id="collapse-toggle-btn"
          onClick={toggleDashboardTilesCollapse}
        >
          {areTilesOpen ? <DashboardCollapse /> : <DashboardExpand />}
        </TertiaryDashboardBtn>
      </TextTooltip>
    </DashboardTileControlsWrapper>
  )
}

export default TileControls
