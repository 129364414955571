import styled from 'styled-components'

import { H4, SecondaryText, P, HeaderLink } from 'components/Text'
import { getColors, getSpacing } from 'components/helpers/theme-selectors'

export const TitleLink = styled(HeaderLink)`
  display: block;
`

export const Price = styled(H4)``

export const DetailText = styled(P)``

// prettier-ignore
export const Hint = styled(SecondaryText)`
  letter-spacing: 0.26px;

  ${props => props.isError || props.isInfo && `
    font-weight: bold;
  `};

  ${props => props.isAccepting && `
    font-weight: bold;
    color: ${getColors(props).warning};
  `};
`

// prettier-ignore
export const Container = styled.div`
  padding: ${props => getSpacing(props).sm} 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(221, 226, 229, 0.5);
  }

  ${DetailText},
  ${TitleLink},
  ${Price} {
    ${props => props.isInactive && `
      color: ${getColors(props).gray3};
    `}
  }
`

export const HeaderRow = styled.div`
  margin-bottom: ${props => getSpacing(props).sm};
`

export const DetailsRow = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const DetailColumn = styled.div`
  min-height: 40px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  &:not(last-of-type) {
    margin-right: ${props => getSpacing(props).base};
  }

  &:last-of-type {
    height: 40px;
  }
`

export const PriceDetailsColumn = styled(DetailColumn)`
  align-items: flex-end;
  @media screen and (max-width: 425px) {
    align-items: flex-start;
  }
`

export const MiddleCols = styled.div`
  display: flex;
  flex: 1;
  min-height: 40px;

  > div:nth-of-type(3n) {
    flex: 1;
    align-items: flex-end;
    min-height: auto;
  }

  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
    height: auto;

    ${DetailColumn} {
      width: 100%;
      margin-top: ${props => getSpacing(props).sm};
    }

    > div:nth-of-type(3n) {
      align-items: flex-start;
      margin-top: ${props => getSpacing(props).base};
    }
  }
`

export const ButtonContainer = styled.div`
  margin-left: ${props => getSpacing(props).base};

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: ${props => getSpacing(props).base};

    > button {
      width: 100%;
    }
  }
`
