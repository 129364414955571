import {
  isPlainObject,
  isNil,
  keys,
  merge,
  flow,
  map,
  snakeCase,
  toPairs,
} from 'lodash/fp'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { deepDiffObj } from 'utils'
import { rtkApi } from 'services/api/rtkApi'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'

import { filterOutNil, filterOutNotNil } from './utils'
import { CONFIG_SCHEMA } from './consts'

export const migrateStorageIfNeeded = createAsyncThunk(
  'storage/migrate',
  async (storage, { dispatch }) => {
    try {
      const differenceWithSchema = deepDiffObj(storage, true)(CONFIG_SCHEMA)

      if (keys(differenceWithSchema).length) {
        // initialise proper key:value schema in the middleware (if absent)
        const removedSchemaDiff = filterOutNotNil(differenceWithSchema)

        const promises = flow(
          toPairs,
          map(([k]) => {
            let merged = merge(CONFIG_SCHEMA[k])(storage[k])
            if (removedSchemaDiff[k]) {
              // support removing keys removed from the schema
              merged = filterOutNil({
                src: merged,
                differenceObj: removedSchemaDiff[k],
              })
            }

            const path = snakeCase(k)
            const value = isPlainObject(CONFIG_SCHEMA[k])
              ? merged
              : CONFIG_SCHEMA[k]

            if (isNil(value)) {
              return dispatch(rtkApi.endpoints.deleteStorage.initiate({ path }))
            }

            return dispatch(
              rtkApi.endpoints.updateStorage.initiate({ path, value }),
            )
          }),
        )(differenceWithSchema)

        const res = await Promise.all(promises)

        dispatch(rtkApi.endpoints.getStorage.initiate())

        return res
      }
    } catch (error) {
      console.log('error mirgating storage', error)
      dispatch(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
    return null
  },
)
