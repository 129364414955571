import React from 'react'
import { generatePath } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Flex, Box } from '@rebass/grid'
import dayjs from 'dayjs'

import theme from 'theme'
import { useOfMaxWidth } from 'hooks'
import messages from 'views/Cart/messages'
import { Link, TinyText } from 'components/Text'
import { Open, Comment } from 'components/Icons'
import { DATE_TIME_FORMATS } from 'utils/datetime'

import { ORDERS_ROUTES } from 'views/Orders/consts'
import { APP_BREAKPOINTS } from 'consts'

import { VerticalLine } from '../../styles'
import { HeaderContainer, DateWrapper, MobileCommentWrapper } from './styles'

const OrderHeader = ({ order }) => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TABLET_START - 1)
  const { formatMessage } = useIntl()
  const showCommentBelow = isMobile && order.orderComment
  const orderPath = generatePath(ORDERS_ROUTES.DETAILS, {
    orderId: order.orderId,
  })

  return (
    <HeaderContainer>
      <Flex width="100%">
        <Flex flex={1} px={theme.spacing.sm}>
          <Box mr={theme.spacing.sm}>
            <Open />
          </Box>

          <Flex {...isMobile && { flexDirection: 'column' }}>
            <TinyText>{formatMessage(messages.incomingOrder)}</TinyText>

            {!isMobile && <>&nbsp;</>}

            <Link to={orderPath} target="_blank">
              <TinyText bold>{order.orderNumber}</TinyText>
            </Link>
          </Flex>
        </Flex>

        {!isMobile && (
          <>
            <Flex>
              <VerticalLine />
            </Flex>

            <Flex flex={1} justifyContent="flex-end" px={theme.spacing.sm}>
              <TinyText>{order.orderComment}</TinyText>
              <Box ml={theme.spacing.sm}>
                {order.orderComment && <Comment />}
              </Box>
            </Flex>

            <Flex>
              <VerticalLine />
            </Flex>
          </>
        )}

        <DateWrapper>
          <TinyText bold>
            {dayjs(order.deliveryDate).format(DATE_TIME_FORMATS.FULL_DATE)}
          </TinyText>
        </DateWrapper>
      </Flex>

      {showCommentBelow && (
        <MobileCommentWrapper>
          <Box mr={theme.spacing.sm}>
            <Comment />
          </Box>
          <TinyText>{order.orderComment}</TinyText>
        </MobileCommentWrapper>
      )}
    </HeaderContainer>
  )
}

export default OrderHeader
