import React from 'react'

import theme from 'theme'

const CartV2 = ({
  color = theme.colors.white,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <circle cx="8.5" cy="19.5" r="1.5" fill={color} />
    <circle cx="17.5" cy="19.5" r="1.5" fill={color} />
    <path
      d="M18 16H10.5414C9.07486 16 7.82329 14.9398 7.5822 13.4932L6.4178 6.5068C6.17671 5.06024 4.92514 4 3.45862 4H3"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 6H16.7251C18.0903 6 19.0542 7.33739 18.6225 8.63246L18.1838 9.94868C17.7754 11.1737 16.629 12 15.3377 12H7.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CartV2
