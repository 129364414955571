import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

export const TemplateModalContainer = styled(Flex)`
  justify-content: center;
  padding-top: 24px;
  border-top: 1px solid ${theme.colors.borderFaded};
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: column;
    padding-top: 16px;
  }
`

export const ImgContainer = styled(Flex)`
  flex-direction: column;
  width: 184px;
  align-items: center;
  img {
    width: 136px;
    height: 144px;
  }
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: row;
    gap: 16px;
    width: initial;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${theme.colors.borderFaded};
  }
`
