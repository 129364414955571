import React from 'react'

import theme from 'theme'

const CheckV3 = ({ width = 10, height = 10, color = theme.colors.primary }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 5.3125L3.75 8.75L8.75 1.875"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckV3
