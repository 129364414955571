import React from 'react'
import styled from 'styled-components'

const LoaderWrapper = styled.svg`
  animation: spin 1.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

const ButtonLoader = () => (
  <LoaderWrapper
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 1.5C11.6716 1.5 11 2.17157 11 3C11 3.82843 11.6716 4.5 12.5 4.5V1.5ZM20 12C20 16.1421 16.6421 19.5 12.5 19.5V22.5C18.299 22.5 23 17.799 23 12H20ZM12.5 4.5C16.6421 4.5 20 7.85786 20 12H23C23 6.20101 18.299 1.5 12.5 1.5V4.5Z"
      fill="url(#paint0_linear_29801_27932)"
    />
    <path
      d="M12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3"
      stroke="url(#paint1_linear_29801_27932)"
      strokeWidth="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_29801_27932"
        x1="17"
        y1="3"
        x2="17"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_29801_27932"
        x1="8"
        y1="4.5"
        x2="8"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
    </defs>
  </LoaderWrapper>
)

export default ButtonLoader
