export const TEMPLATE_TYPES = {
  BIDFOOD: 1,
  CUSTOMER: 2,
}

export const TEMPLATES_MOBILE_MAX_WIDTH = '1020px'

export const GO_TO_TOP_OFFSET = {
  DESKTOP: 149,
  MOBILE: 294,
}
