import React from 'react'

import theme from 'theme'

const ArrowLongDown = ({
  color = theme.colors.primary,
  width = 16,
  height = 33,
  ...rest
}) => (
  <svg
    viewBox="0 0 16 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M8 0v32" stroke={color} strokeWidth={2} />
    <path
      d="M2 26l6 6 6-6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLongDown
