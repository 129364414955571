import { makeMessages, makeTranslation } from 'utils'

const messages = {
  orderPlaced: 'orderPlaced',
  purchaser: 'purchaser',
  billingData: 'billingData',
  orderVAT: 'orderVAT',
  free: 'free',
  payment: 'payment',
  grossSumLong: 'grossSum',
  nip: 'nip',
  ordersHeader: 'ordersHeader',
  orderNumber: 'orderNumber',
  deliveriesCount: 'deliveriesCount',
  waitingForApprovalHeader: 'waitingForApprovalHeader',
  waitingForApprovalInfo: 'waitingForApprovalInfo',
  amountNet: { id: 'cart.amountNet' },
  grossSum: { id: 'cart.grossSum' },
  delivery: { id: 'cart.delivery' },
  paymentDate: { id: 'cart.paymentDate' },
  lacksToFreeDelivery: { id: 'cart.lacksToFreeDelivery' },
  deliveries: { id: 'cart.deliveriesCount' },
  floatingPriceInfo: { id: 'floatingPrice.orderInfoBoxText' },
}

export default makeMessages(messages, makeTranslation('orderDetails'))
