import React from 'react'

import TemplateEntry from 'views/Templates/TemplatesList/TemplateListItem/TemplateEntry'
import TemplateCheckbox from './TemplateCheckbox'

import { TemplateEntryWrapper } from './styles'

export const TemplateModalListItem = ({
  template,
  productId,
  unitOfMeasure,
  onAddToTemplate,
  onRemoveFromTmplate,
}) => {
  const { name, itemsCount, inTemplate, id } = template
  const data = { productId, unitOfMeasure, template }

  return (
    <TemplateEntryWrapper
      data-test-id={`template-list-item-${id}`}
      onClick={e => {
        e.preventDefault()
        inTemplate
          ? onRemoveFromTmplate({ ...data, inTemplate })
          : onAddToTemplate(data)
      }}
    >
      <TemplateEntry
        title={name}
        count={itemsCount}
        icon={<TemplateCheckbox isChecked={inTemplate} />}
      />
    </TemplateEntryWrapper>
  )
}

export default TemplateModalListItem
