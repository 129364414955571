import React from 'react'
import theme from 'theme'

const CartCrossedV2 = ({ color = theme.colors.primary }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18.5" cy="38.5" r="2.5" fill={color} />
    <circle cx="33.5" cy="38.5" r="2.5" fill={color} />
    <path
      d="M36 34H18.5582C17.0843 34 15.8287 32.9293 15.5958 31.474L12.4042 11.526C12.1713 10.0706 10.9157 9 9.44184 9H6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 13H36.4796C37.7678 13 38.72 14.2 38.4273 15.4545L36.0409 25.6817C35.7241 27.0395 34.5137 28 33.1194 28H15.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 23.5L28 17.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22 17.5L28 23.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default CartCrossedV2
