import theme, { paymentTheme } from 'theme'

export const VARIANT = {
  RED: 'RED',
  YELLOW: 'YELLOW',
  GREEN: 'GREEN',
  GREY: 'GREY',
}
export const TEXT_COLOR_BY_VARIANT = {
  [VARIANT.RED]: theme.colors.redDarkText,
  [VARIANT.YELLOW]: paymentTheme.yellow.text,
}
