import React, { useState } from 'react'

import { Trash } from 'components/Icons'
import { RemoveTemplateBtn } from './styles'
import RemoveTemplateConfirmation from './RemoveTemplateConfirmation'

const RemoveTemplate = ({ id, name }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  return (
    <>
      {isConfirmOpen && (
        <RemoveTemplateConfirmation
          name={name}
          id={id}
          onClose={() => setIsConfirmOpen(false)}
          trigger="bin_icon"
        />
      )}

      <RemoveTemplateBtn
        data-test-id="template_remove"
        onClick={() => setIsConfirmOpen(true)}
      >
        <Trash />
      </RemoveTemplateBtn>
    </>
  )
}

export default RemoveTemplate
