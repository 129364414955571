export const ICONS_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
}

export const ICON_SIZE = {
  SMALL: 'small',
  NORMAL: 'normal',
}

export const iconSizes = {
  [ICON_SIZE.NORMAL]: 24,
  [ICON_SIZE.SMALL]: 20,
}

export const ICON_COLORS = {
  FADED: '#787380',
}

export const getIconSize = iconSize =>
  iconSizes[iconSize] || iconSize || iconSizes[ICON_SIZE.NORMAL]

/**
 * https://feathericons.com/
 */
export const ICONS = {
  MINUS: 'minus',
  PLUS: 'plus',
  EDIT: 'edit',
  CHECK_SQUARE: 'check-square',
  CART: 'shopping-cart',
  TRASH: 'trash',
  CHEVRON_LEFT: 'chevron-left',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_UP: 'chevron-up',
  HOME: 'home',
  INFO: 'info',
  HELP: 'help-circle',
  SUCCESS: 'check-circle',
  ERROR: 'alert-triangle',
  STAR: 'star',
  PIN: 'map-pin',
  CALENDAR: 'calendar',
  IMAGE: 'image',
  BELL: 'bell',
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
  BAR_CHART: 'bar-chart-2',
  INBOX: 'inbox',
  FILTER: 'filter',
  EYE: 'eye',
  EYE_OFF: 'eye-off',
  SHARE: 'share',
  CHAT: 'message-circle',
  CREDIT_CARD: 'credit-card',
  SEARCH: 'search',
  USER: 'user',
  USERS: 'users',
  X: 'x',
  CHECK: 'check',
  PHONE: 'phone',
  MAIL: 'mail',
  MENU: 'menu',
  REPLACEMENTS: 'repeat',
  TRUCK: 'truck',
  ALERT: 'alert-circle',
}
