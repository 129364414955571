import React from 'react'

import Toggle from 'components/Input/Toggle'

import { SwitchContainer, SwitchLabel } from './styledComponents'

const Switch = ({ label, disabled, checked, onChange, testId, ...rest }) => (
  <SwitchContainer {...rest}>
    <SwitchLabel
      data-test-id={testId}
      htmlFor={testId}
      {...{ disabled, checked }}
    >
      {label}
    </SwitchLabel>
    <Toggle id={testId} {...{ disabled, checked, onChange }} ml={24} />
  </SwitchContainer>
)

export default Switch
