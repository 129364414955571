import styled from 'styled-components'

import theme from 'theme'

import OtherRow from '../OtherRow'

export const DeleteAccountRow = styled(OtherRow)`
  ${p =>
    p.isDeleteRequested &&
    `
    span {
      color: ${theme.colors.redLight};
    }
    path, circle {
      stroke: ${theme.colors.redLight};
    }
  `};
`
