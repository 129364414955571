import { handleActions } from 'redux-actions'

import { createReducer, initialState } from 'utils/simpleDataReducer'
import { updateCartItem, deleteCartItem } from 'containers/Cart/actions'
import { resetProductUnitsOfMeasureInCart } from 'containers/Products/reducer'

import { getPromosActions } from './actions'

const promosReducer = createReducer(getPromosActions)
const promosHandlers = handleActions(
  {
    [updateCartItem.SUCCESS]: (state, { data: { cartItem } }) => {
      let updState = state
      const promotions = state.getIn(['data', 'promotions']) || []
      promotions.forEach((promotion, promoIndex) => {
        promotion.get('products').forEach((product, productIndex) => {
          if (cartItem && cartItem.product.id === product.get('id')) {
            updState = state.mergeIn(
              ['data', 'promotions', promoIndex, 'products', productIndex],
              product.merge(cartItem.product),
            )
          }
        })
      })

      return updState
    },
    [deleteCartItem.SUCCESS]: (
      state,
      { data: { cartItem }, additionalData },
    ) => {
      let updState = state

      if (!cartItem) {
        const promotions = state.getIn(['data', 'promotions']) || []
        promotions.forEach((promotion, promoIndex) => {
          promotion.get('products').forEach((product, productIndex) => {
            if (additionalData.productId === product.get('id')) {
              updState = state.mergeIn(
                [
                  'data',
                  'promotions',
                  promoIndex,
                  'products',
                  productIndex,
                  'unitsOfMeasure',
                ],
                resetProductUnitsOfMeasureInCart(
                  product.get('unitsOfMeasure'),
                  additionalData.unitOfMeasure,
                ),
              )
            }
          })
        })
      }

      return updState
    },
  },
  initialState,
)

export default (state, action) => {
  const newState = promosReducer(state, action)
  return promosHandlers(newState, action)
}
