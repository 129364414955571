import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '../messages'

import { CopyrightText, BrandingContainer, BrandingItems } from './styles'

const Information = () => (
  <BrandingContainer>
    <BrandingItems>
      <CopyrightText>
        &copy;&nbsp;
        {new Date().toISOString().substr(0, 4)}
        &nbsp;
        <FormattedMessage {...messages.companyName} />
      </CopyrightText>
    </BrandingItems>
  </BrandingContainer>
)

export default Information
