import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { makeMessages, makeTranslation } from 'utils'
import { DATE_TIME_FORMATS } from 'utils/datetime'

const messages = makeMessages(
  {
    distantDate: 'distantDate',
  },
  makeTranslation('dates'),
)

// mappings for date util library day of week numbering
const orderTillMsgs = makeMessages(
  {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
    tomorrow: 'tomorrow',
    midnight: 'midnight',
    midnightLong: 'midnightLong',
  },
  makeTranslation('dates.orderTill'),
)
const toReceiveMsgs = makeMessages(
  {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
    today: 'today',
    tomorrow: { id: 'navbar.tomorrow' },
  },
  makeTranslation('dates.toReceive'),
)

const useDeadlineOrderDates = ({ deadlineOrderDate, deliveryDate }) => {
  const { formatMessage } = useIntl()

  const deadlineDateFormatted = useMemo(
    () => {
      if (!deadlineOrderDate) return ''

      const date = dayjs(deadlineOrderDate)
      const formattedTime = date.format(DATE_TIME_FORMATS.SHORT_TIME)
      const isMidnight = formattedTime === '00:00'

      // substraction needed for midnight copy to be displated correctly
      const formattedDate = isMidnight ? date.subtract(1, 'days') : date

      if (formattedDate.isToday()) {
        return isMidnight
          ? formatMessage(orderTillMsgs.midnight)
          : formattedTime
      }

      const timeLong = isMidnight
        ? formatMessage(orderTillMsgs.midnightLong)
        : formattedTime
      if (formattedDate.isTomorrow()) {
        return `${formatMessage(orderTillMsgs.tomorrow)}, ${timeLong}`
      }

      if (formattedDate.isBefore(dayjs().add(7, 'days'), 'day')) {
        return `${formatMessage(
          orderTillMsgs[formattedDate.day()],
        )}, ${timeLong}`
      }

      // use non-midnight affected date here since we don't present midnight copy in this case
      return formatMessage(messages.distantDate, {
        date: date.format(DATE_TIME_FORMATS.SHORT_DATE),
      })
    },
    [deadlineOrderDate],
  )

  const deliveryDateFormatted = useMemo(
    () => {
      if (!deliveryDate) return ''

      const date = dayjs(deliveryDate)

      if (date.isToday()) {
        return formatMessage(toReceiveMsgs.today)
      }

      if (date.isTomorrow()) {
        return formatMessage(toReceiveMsgs.tomorrow)
      }

      if (date.isBefore(dayjs().add(7, 'days'), 'day')) {
        return formatMessage(toReceiveMsgs[date.day()])
      }

      return formatMessage(messages.distantDate, {
        date: date.format(DATE_TIME_FORMATS.SHORT_DATE),
      })
    },
    [deliveryDate],
  )

  return [deadlineDateFormatted, deliveryDateFormatted]
}

export default useDeadlineOrderDates
