import styled from 'styled-components'
import AlternateBtn from 'components/Button/AlternateBtn'
import { P } from 'components/Text'
import { getSpacing, getColors } from 'components/helpers/theme-selectors'

import { PrimaryBtn } from 'components/Button'

export const StyledButton = styled(AlternateBtn)`
  margin-top: ${props => getSpacing(props).base};
  width: 100%;
`
export const Title = styled(P)`
  margin-bottom: ${props => getSpacing(props).xs};
`

export const StyledPrimaryBtn = styled(PrimaryBtn)`
  position: absolute;
  bottom: ${props => getSpacing(props).base};
  left: ${props => getSpacing(props).base};
  max-width: 442px;
  transition: all 0.3s;

  &:disabled {
    background: ${props => getColors(props).gray2};
    color: ${props => getColors(props).gray3};
    cursor: default;
  }
`
