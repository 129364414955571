import React from 'react'

import { CANCELLABLE_ORDER_STATUS } from 'views/Orders/consts'
import CancelOrder from './CancelOrder'
import ContactForm from './ContactForm'

import { OrderActionsContainer } from './styles'

const OrderActions = ({ orderId, orderStatusId, orderDetails }) => {
  const isCancellable = CANCELLABLE_ORDER_STATUS.includes(orderStatusId)

  return (
    <OrderActionsContainer>
      {isCancellable && (
        <CancelOrder orderId={orderId} orderDetails={orderDetails} />
      )}
      <ContactForm orderId={orderId} orderStatusId={orderStatusId} />
    </OrderActionsContainer>
  )
}

export default OrderActions
