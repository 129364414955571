import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

export const templateIconStyling = css`
  cursor: pointer;
  :hover {
    path {
      stroke: ${theme.colors.primary};
    }
  }
`

export const DefaultIconWrapper = styled(Flex)`
  ${templateIconStyling};
`
