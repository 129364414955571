import React, { useMemo, useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import { Flex } from '@rebass/grid'
import { chunk, some } from 'lodash/fp'

import { useOfMinWidth } from 'hooks'
import { makeDeliveryStickyId } from 'containers/Cart/utils'
import {
  useGetStorageValue,
  CONFIG_SCHEMA_KEYS,
  CONFIG_API_PATHS_MAP,
} from 'containers/Storage'
import { ArrowsLeft, ArrowsRight, TruckSpeed } from 'components/Icons'
import { HintModal, HintOverlay } from 'components'
import { useUpdateStorageMutation } from 'services/api/rtkApi'

import DateCard from '../DateCard'
import { findRoute } from '../utils'
import { hintModalConfig } from '../hintModalConfig'

import {
  List,
  ListWrapper,
  DeliveryDatesListContainer,
  ChevronIconContainer,
  Chevron,
  LineDashed,
  IconWrapper,
  Header,
} from './styles'

const DeliveryDatesList = ({
  dataGrouppedByDate,
  hideDeliveries,
  timelineTitle,
}) => {
  const [truckHintIndex, setTruckHintIndex] = useState(-1)
  const expandedMode = some(data => data.items && data.routes.length > 1)(
    dataGrouppedByDate,
  )
  const listRef = useRef(null)
  const listItemRef = useRef(null)

  // TODO create isMobile, isTablet, etc. hooks if breakpoints settings will be global for the whole app
  const isWideDesktop = useOfMinWidth(1280)

  const itemsPerPage = isWideDesktop ? 5 : 4

  const dataGrouppedByDateChunks = useMemo(
    () => chunk(itemsPerPage, dataGrouppedByDate),
    [itemsPerPage, dataGrouppedByDate],
  )

  const [currChunk, setCurrChunk] = useState(0)
  const [isTailArrowVisible, setIsTailArrowVisible] = useState(
    currChunk < dataGrouppedByDateChunks.length - 1,
  )
  const [isHeadArrowVisible, setIsHeadArrowVisible] = useState(currChunk > 0)
  const truckHintEnabled = useGetStorageValue(
    CONFIG_SCHEMA_KEYS.CART_GUIDE.TIMELINE,
  )

  const [updateStorage] = useUpdateStorageMutation()

  useEffect(
    () => {
      if (
        !!dataGrouppedByDateChunks.length &&
        currChunk > dataGrouppedByDateChunks.length - 1
      ) {
        setCurrChunk(dataGrouppedByDateChunks.length - 1)
      }
      setIsTailArrowVisible(currChunk < dataGrouppedByDateChunks.length - 1)
      setIsHeadArrowVisible(currChunk > 0)
    },
    [currChunk, dataGrouppedByDateChunks],
  )

  return (
    <Flex>
      {isWideDesktop && <Flex width={338} minWidth={338} />}

      <DeliveryDatesListContainer>
        <Header data-test-id="cart_deliveries_header">{timelineTitle}</Header>

        {!hideDeliveries && (
          <>
            <ListWrapper {...{ isHeadArrowVisible, isTailArrowVisible }}>
              <Chevron isHead isVisible={isHeadArrowVisible}>
                <ChevronIconContainer
                  onClick={() => {
                    setCurrChunk(currChunk - 1)
                    listRef.current.scroll({
                      left: -listItemRef.current.clientWidth * itemsPerPage,
                      behavior: 'smooth',
                    })
                  }}
                >
                  <ArrowsLeft />
                </ChevronIconContainer>
                <LineDashed />
              </Chevron>

              {dataGrouppedByDate.length > 0 && (
                <List
                  ref={listRef}
                  {...{ isHeadArrowVisible, isTailArrowVisible }}
                >
                  {dataGrouppedByDate.map(
                    ({ deliveryDate, routes, items }, index) => {
                      const routeId = findRoute(routes)

                      return (
                        <DateCard
                          key={deliveryDate}
                          isEvenlyDisrtibuted={
                            dataGrouppedByDate.length <= itemsPerPage
                          }
                          innerRef={index === 0 ? listItemRef : undefined}
                          isFirst={index === 0}
                          isLast={index === dataGrouppedByDate.length - 1}
                          date={dayjs.utc(deliveryDate)}
                          routeId={findRoute(routes)}
                          isIncoming={!items}
                          {...{ routes, expandedMode, itemsPerPage }}
                          stickyId={makeDeliveryStickyId(deliveryDate, routeId)}
                        />
                      )
                    },
                  )}
                </List>
              )}

              <Chevron isTail isVisible={isTailArrowVisible}>
                <ChevronIconContainer
                  onClick={() => {
                    setCurrChunk(currChunk + 1)
                    listRef.current.scroll({
                      left: listItemRef.current.clientWidth * itemsPerPage,
                      behavior: 'smooth',
                    })
                  }}
                >
                  <ArrowsRight />
                </ChevronIconContainer>
                <LineDashed />
              </Chevron>
            </ListWrapper>

            <IconWrapper>
              <HintOverlay enabled={truckHintEnabled}>
                <TruckSpeed
                  onClick={() => (truckHintEnabled ? setTruckHintIndex(0) : {})}
                />
              </HintOverlay>
            </IconWrapper>

            {truckHintIndex > -1 && (
              <HintModal
                {...hintModalConfig[truckHintIndex]}
                onClose={() => {
                  setTruckHintIndex(-1)
                  updateStorage({
                    path: CONFIG_API_PATHS_MAP.CART_GUIDE.TIMELINE,
                    value: false,
                  })
                }}
                nextClickHandler={() => setTruckHintIndex(truckHintIndex + 1)}
              />
            )}
          </>
        )}
      </DeliveryDatesListContainer>
    </Flex>
  )
}

export default DeliveryDatesList
