import React, { useMemo } from 'react'

export const ACTION_TYPE = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
}

const ActionWrapper = ({ children, actionType, value, className, href }) => {
  const actionHref = useMemo(
    () => {
      if (href) {
        return href
      }
      return actionType === ACTION_TYPE.EMAIL
        ? `mailTo:${value}`
        : `tel:${value}`
    },
    [actionType, href],
  )

  return (
    <a className={className} href={actionHref}>
      {children || value}
    </a>
  )
}

export default ActionWrapper
