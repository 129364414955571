import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { P } from 'components/Text'
import {
  getColors,
  getFontWeights,
  getSpacing,
} from 'components/helpers/theme-selectors'

export const SwitchContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding-top: ${props => getSpacing(props).sm};

  ${props =>
    props.bordered &&
    `
    padding-bottom: ${getSpacing(props).sm};
    border-bottom: 1px solid ${getColors(props).border};
  `};
  & > div {
    opacity: 1 !important;
  }
`

export const SwitchLabel = styled(P).attrs({
  as: 'label',
})`
  font-weight: ${props => getFontWeights(props).strongBold};
  margin-right: ${p => getSpacing(p).sm};
  ${props => props.disabled && `color: ${getColors(props).gray3};`};
`
