import { useMemo } from 'react'
import dayjs from 'dayjs'

import theme from 'theme'

import { PAYMENT_COLORS, BELOW_LIMIT } from 'containers/Orders/consts'
import { USER_CONTROLS_STYLES } from '../../utils'

export const useTimerColorsConfig = (
  deadlineOrderDate,
  cartBtnStyles,
  hasTimer,
) => {
  const currentTime = dayjs()
  const deadlineDate = dayjs(deadlineOrderDate)

  const config = useMemo(
    () => {
      const defaultConfig = {
        bg: theme.colors.lightGrey,
        content: theme.colors.lightGrey4,
      }

      // return default if timer not visible to skip calculations below
      if (!hasTimer) {
        return defaultConfig
      }

      if (
        currentTime.isBetween(deadlineDate.subtract(5, 'minutes'), deadlineDate)
      ) {
        return {
          bg: USER_CONTROLS_STYLES[PAYMENT_COLORS.RED].bgColor,
          content: USER_CONTROLS_STYLES[PAYMENT_COLORS.RED].contentColor,
        }
      }

      if (
        currentTime.isBetween(
          deadlineDate.subtract(30, 'minutes'),
          deadlineDate.subtract(5, 'minutes'),
        )
      ) {
        return {
          bg: USER_CONTROLS_STYLES[PAYMENT_COLORS.YELLOW].bgColor,
          content: USER_CONTROLS_STYLES[PAYMENT_COLORS.YELLOW].contentColor,
        }
      }

      if (
        [BELOW_LIMIT, PAYMENT_COLORS.GREEN].includes(cartBtnStyles.variantName)
      ) {
        return {
          bg: cartBtnStyles.bgColor,
          content: cartBtnStyles.contentColor,
        }
      }

      return defaultConfig
    },
    [hasTimer, cartBtnStyles, deadlineDate, currentTime],
  )

  return config
}
