import * as React from 'react'

import theme from 'theme'

const DottedVertical = ({
  color = theme.colors.primary,
  width = 2,
  height = 40,
  ...rest
}) => (
  <svg
    viewBox="0 0 2 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M1 0v40" stroke={color} strokeWidth={2} strokeDasharray="2 2" />
  </svg>
)

export default DottedVertical
