import React from 'react'

const Cheese = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="group-[.active-btn]:fill-white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 11.0005H11.2928C11.7335 11.0005 12.0958 11.365 12.2792 11.8102C12.7337 12.9129 13.7341 13.6788 14.895 13.6788C16.056 13.6788 17.0564 12.9129 17.5109 11.8102C17.6943 11.365 18.0566 11.0005 18.4973 11.0005H19.1994C20.1935 11.0005 20.9993 11.8959 20.9993 13.0005V19C20.9993 20.1046 20.1935 21 19.1994 21H14.2837C13.7965 21 13.4155 20.5492 13.202 20.0626C12.8682 19.3017 12.1664 18.7775 11.3547 18.7775C10.543 18.7775 9.84121 19.3017 9.50741 20.0626C9.29393 20.5492 8.91294 21 8.42571 21H4.8C3.80589 21 3 20.1046 3 19V13.0005C3 11.8959 3.80589 11.0005 4.8 11.0005ZM6.67629 17.3394C7.81107 17.3394 8.731 16.3151 8.731 15.0515C8.731 13.7879 7.81107 12.7635 6.67629 12.7635C5.54151 12.7635 4.62158 13.7879 4.62158 15.0515C4.62158 16.3151 5.54151 17.3394 6.67629 17.3394ZM18.5956 16.8815C18.5956 17.6397 18.0437 18.2543 17.3628 18.2543C16.6819 18.2543 16.13 17.6397 16.13 16.8815C16.13 16.1234 16.6819 15.5087 17.3628 15.5087C18.0437 15.5087 18.5956 16.1234 18.5956 16.8815Z"
      fill="#229944"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6266 9H4.37231C2.70495 9 2.48127 5.8728 4.12023 5.47584L4.7991 5.31142C5.29596 5.19108 5.89008 5.55561 6.36077 5.76947C6.67763 5.91343 7.08483 6 7.52901 6C8.53915 6 9.35803 5.55228 9.35803 5C9.35803 4.68987 9.39957 4.19719 9.67229 4.13114L14.0926 3.06053C14.601 2.93741 15.1258 3.00294 15.6066 3.24955L20.1316 5.57035C21.5384 6.29193 21.1398 9 19.6266 9ZM16.558 6C16.558 6.55228 15.7392 7 14.729 7C13.7189 7 12.9 6.55228 12.9 6C12.9 5.44772 13.7189 5 14.729 5C15.7392 5 16.558 5.44772 16.558 6Z"
      fill="#99D0AC"
    />
  </svg>
)

export default Cheese
