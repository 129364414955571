import { makeMessages, makeTranslation } from 'utils'

export default makeMessages(
  {
    gross: { id: 'prices.gross' },
    addedItemToCartNotification: 'addedItemToCartNotification',
    addedItemToCartNotificationError: 'error.addedItemToCartNotification',
    removeFromTemplates: 'removeFromTemplates',
    addToCart: 'addToCart',
    maxAvailableAmount: {
      id: 'nonStock.maxAvailableAmount',
    },
    addedToCartNewDelivery: { id: 'product.addedToCartNewDelivery' },
  },
  makeTranslation('templates'),
)
