import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import {
  getColors,
  getFontWeights,
  getSpacing,
} from 'components/helpers/theme-selectors'
import { BaseText, H5 } from 'components/Text'

import {
  CIRCLE_SIZE,
  POINTS_TEXT_SIZE,
  MAX_AMOUNT_TEXT_SIZE,
  AMOUNT_TEXT_SIZE,
} from './consts'

export const CircleProgressContainer = styled(Flex)`
  ${p => p.withMarginLeft && `margin-left: ${getSpacing(p).sm}`};
`

export const PointsText = styled(H5)`
  font-weight: ${p => getFontWeights(p).strongBold};
  white-space: nowrap;
`

export const AmountText = styled(BaseText).attrs({
  bold: true,
  centered: true,
})``

export const MaxAmountText = styled(AmountText)`
  color: ${p => getColors(p).white};
`

export const Wrapper = styled(Flex)`
  align-items: center;

  ${p => {
    const circleSize = CIRCLE_SIZE[p.size]
    const pointsTextSize = POINTS_TEXT_SIZE[p.size]
    const amountTextSize = AMOUNT_TEXT_SIZE[p.size]
    const maxAmountTextSize = MAX_AMOUNT_TEXT_SIZE[p.size]

    return `
      ${CircleProgressContainer} {
        min-width: ${circleSize};
        min-height: ${circleSize};
        width: ${circleSize};
        height: ${circleSize};
      }

      ${PointsText} {
        font-size: ${pointsTextSize};
      }

      ${AmountText} {
        font-size: ${amountTextSize};
      }

      ${MaxAmountText} {
        font-size: ${maxAmountTextSize};
      }
    `
  }};
`
