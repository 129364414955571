import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { BasicInput } from 'components/Input/styles'

import {
  getColors,
  getSpacing,
  getFontWeights,
  getBreakpoint,
} from 'components/helpers/theme-selectors'
import { H4 } from 'components/Text'

export const Layout = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding: ${p => getSpacing(p).md};
  min-width: 490px;

  @media (max-width: ${p => getBreakpoint(p).mobile}) {
    min-width: 300px;
  }
`

export const BoxWrapper = styled(Flex)`
  flex-direction: column;
  border: 1px solid ${p => getColors(p).border};
  padding: ${p => getSpacing(p).base};
`

export const Header = styled(H4)`
  margin-bottom: ${p => getSpacing(p).xs};
  font-weight: ${p => getFontWeights(p).strongBold};
`

export const SearchContainer = styled(Box)`
  position: relative;

  input {
    height: 40px; // TODO refactor 40px
    padding-right: 40px;
    padding-top: 0;
  }
`

export const SearchInput = styled(BasicInput)`
  ::placeholder {
    color: ${props => getColors(props).gray3};
  }
`

export const SearchIconContainer = styled(Flex)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 8px;
`
