import React from 'react'

import theme from 'theme'

const Alert = ({
  primaryColor = theme.colors.primary,
  secondaryColor = theme.colors.gray5,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.629 20.472L13.087 21.388C12.604 22.204 11.397 22.204 10.913 21.388L10.371 20.472C9.951 19.762 9.741 19.406 9.403 19.21C9.065 19.013 8.64 19.006 7.79 18.991C6.534 18.97 5.747 18.893 5.087 18.619C4.48037 18.3677 3.92917 17.9994 3.46487 17.5351C3.00057 17.0708 2.63227 16.5196 2.381 15.913C2 14.995 2 13.83 2 11.5V10.5C2 7.227 2 5.59 2.737 4.388C3.14904 3.7152 3.71445 3.14945 4.387 2.737C5.59 2 7.228 2 10.5 2H13.5C16.773 2 18.41 2 19.613 2.737C20.2854 3.14917 20.8508 3.71456 21.263 4.387C22 5.59 22 7.228 22 10.5V11.5C22 13.83 22 14.995 21.62 15.913C21.3686 16.5197 21.0002 17.071 20.5357 17.5353C20.0712 17.9996 19.5198 18.3678 18.913 18.619C18.253 18.893 17.466 18.969 16.21 18.991C15.36 19.006 14.935 19.013 14.597 19.21C14.259 19.406 14.049 19.761 13.629 20.472Z"
      fill={secondaryColor}
    />
    <path
      d="M15.375 9.5V9.852C15.375 10.2745 15.495 10.6875 15.721 11.039L16.275 11.9005C16.7805 12.6875 16.3945 13.757 15.515 14.0055C13.2168 14.6565 10.7831 14.6565 8.48497 14.0055C7.60547 13.757 7.21947 12.6875 7.72497 11.9005L8.27897 11.039C8.50576 10.6847 8.62602 10.2727 8.62547 9.852V9.5C8.62547 7.567 10.1365 6 12 6C13.8635 6 15.375 7.567 15.375 9.5Z"
      fill={primaryColor}
    />
    <path
      d="M13.5 8V10"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9868 15.7705C10.6708 15.6182 10.3936 15.3961 10.176 15.121C11.3865 15.2851 12.6136 15.2851 13.824 15.121C13.6065 15.3961 13.3292 15.6182 13.0132 15.7705C12.6972 15.9227 12.3508 16.0012 12 16C11.6493 16.0012 11.3028 15.9227 10.9868 15.7705Z"
      fill={primaryColor}
    />
  </svg>
)

export default Alert
