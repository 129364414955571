import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import { useDispatch, useSelector } from 'react-redux'
import { isNumber } from 'lodash/fp'
import { FormattedMessage } from 'react-intl'

import { useOfMaxWidth } from 'hooks'
import { orderErrorCodeSelector } from 'containers/Orders/selectors'
import { orderActions } from 'containers/Orders/actions'
import { ORDER_ERRORS } from 'containers/Orders/consts'
import {
  MOBILE_HEIGHT,
  DESKTOP_HEIGHT,
} from 'containers/App/LoggedInLayout/Header/styles'
import { NormalIcon, ICONS } from 'components/Icon'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import messages from './messages'
import { AlertWrapper } from './styledComponents'

const ErrorContainer = ({ orderError }) => {
  const dispatch = useDispatch()
  const errorCode = useSelector(orderErrorCodeSelector)
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)

  const isHasOutOfStocks = orderError === ORDER_ERRORS.HAS_OUT_OF_STOCKS
  const isInactiveProducts = errorCode === ORDER_ERRORS.INACTIVE_PRODUCTS

  return (
    <AnimateHeight
      duration={500}
      height={!orderError || isNumber(orderError) ? 0 : 64}
      style={{
        position: 'fixed',
        top: isMobile ? MOBILE_HEIGHT : DESKTOP_HEIGHT,
        zIndex: theme.zIndex.base,
        width: 'inherit',
        maxWidth: 'inherit',
      }}
      animateOpacity
    >
      <AlertWrapper isRedError={isHasOutOfStocks || isInactiveProducts}>
        <div />

        <span>
          {isHasOutOfStocks ? (
            <FormattedMessage {...messages.hasOutOfStocks} />
          ) : (
            orderError
          )}
        </span>

        <div>
          <NormalIcon
            type={ICONS.X}
            onClick={() => dispatch(orderActions.clear())}
          />
        </div>
      </AlertWrapper>
    </AnimateHeight>
  )
}

ErrorContainer.propTypes = {
  orderError: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default ErrorContainer
