import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { RESET_PASSWORD_ROUTES } from 'consts'
import { BrandedLink } from 'components/Text'
import { ICONS } from 'components/Icon'

import messages from '../messages'
import TextInput from '../TextInput'

import { PasswordContainer } from './styledComponents'

const PasswordInput = ({ input, resettable, stretch, ...props }) => {
  const [showPassword, toggleType] = useState(false)

  return (
    <PasswordContainer {...{ stretch }}>
      <TextInput
        {...input}
        {...props}
        width="100%"
        type={showPassword ? 'text' : 'password'}
        iconType={showPassword ? ICONS.EYE_OFF : ICONS.EYE}
        onIconClick={() => toggleType(!showPassword)}
        iconColor="#0E0E3E"
      />
      {resettable && (
        <BrandedLink to={RESET_PASSWORD_ROUTES.INIT}>
          <FormattedMessage {...messages.forgotPassword} />
        </BrandedLink>
      )}
    </PasswordContainer>
  )
}

export default PasswordInput
