import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { MOBILE_HEIGHT } from 'containers/App/LoggedInLayout/Header/styles'
import { DefaultIcon } from 'components/Icon'
import { SecondaryText, TertiaryText } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

// prettier-ignore
export const DeliveryContainer = styled(Flex)`
  flex-direction: column;
  min-width: 306px;
  margin-right: ${theme.spacing.md};

  @media screen and (max-width: ${APP_BREAKPOINTS.TMP_MAIN - 1}px) {
    margin: 0;
    padding: 0;
  }

  .sticky-inner-wrapper > * {
    padding-left: ${theme.spacing.sm};
    padding-top: ${theme.spacing.base};
    padding-bottom: ${theme.spacing.base};
    border-top: 1px solid ${theme.colors.gray2};
  }
`

export const NoDeliveryAddress = styled(SecondaryText)`
  color: ${theme.colors.error};
`

export const StyledText = styled(TertiaryText)`
  margin-bottom: ${theme.spacing.xxs};
`

// prettier-ignore
export const Icon = styled(DefaultIcon).attrs({ size: 18 })`
  cursor: pointer;

  ${p => p.disabled && `
    color: ${theme.colors.gray2};
    pointer-events:none;
  `};
`

export const DeliveryDateContainer = styled(Flex)`
  height: 73px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${p => p.onlyIncoming && `border-bottom: 1px solid ${theme.colors.gray2};`};
`
// prettier-ignore
export const MobileDeliveryContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  margin-bottom: 8px;
  margin-left: -8px;
  width: calc(100% + 16px);

  ${p => p.sticky && `
    position: sticky;
    top: ${MOBILE_HEIGHT}px;
    z-index: ${theme.zIndex.base};
  `};

  > * {
    background-color: #eaedef;
    padding: ${theme.spacing.sm} ${theme.spacing.base};
    border-bottom: 1px solid ${theme.colors.gray2};
  }
`

export const MobileDeliveryHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.gray2};
  background-color: ${theme.colors.bgGray};

  ${p => p.clickable && `cursor: pointer`};
`

export const MobileMoveAllWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`
