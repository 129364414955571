import React from 'react'

import {
  StyledChecked,
  RadioContainer,
  HiddenRadio,
  StyledRadio,
} from './styles'

const RadioV2 = ({ className, checked, ...props }) => (
  <RadioContainer className={className}>
    <HiddenRadio checked={checked} {...props} />
    <StyledRadio checked={checked}>
      <StyledChecked />
    </StyledRadio>
  </RadioContainer>
)

export default RadioV2
