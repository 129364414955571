import React from 'react'
import styled from 'styled-components'

import theme from 'theme'
import { getColors, getSpacing } from 'components/helpers/theme-selectors'

export const PackageContainer = styled.div`
  height: ${p => (p.expanded ? '118px' : '16px')};
  position: relative;
`

// prettier-ignore
export const Line = styled.div`
  height: 2px;
  background-color: ${p => getColors(p).primary};
  position: absolute;
  top: 8px;
  width: ${p => p.pullLeft
    ? '100%'
    : `calc(100% - ${p.multipleRoutes && p.disabled ? 48 : 32}px)`};

  ${p => p.pullLeft && `
    left: 0;
  `};

  ${p => p.pullRight && `
    right: 0;
  `};

  ${p => p.disabled && `
    ${p.pullLeft ? `
      height: 0;
      border: 1px dashed ${getColors(p).primary};
      background-color: transparent;
    ` : `
      background-color: ${getColors(p).gray2};
    `};
  `};
`

export const SingleDotContainer = styled.div`
  z-index: ${theme.zIndex.base};
  position: absolute;
  top: 4px;
  left: 30px;
`

export const MiddleDotsContainer = styled(SingleDotContainer)`
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`

export const Dot = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${p => getColors(p)[p.full ? 'primary' : 'bgGray']};
  border: 2px solid ${p => getColors(p).primary};
  border-radius: 50%;

  :not(:last-child) {
    margin-right: 8px;
  }
`

export const DeliveryContainer = styled.div`
  position: absolute;
  z-index: ${theme.zIndex.base};
  top: 5px;
  display: inline-flex;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 16px;

  &:before {
    display: block;
    height: 2px;
    width: 16px;
    background-color: ${p => getColors(p).gray2};
    content: ' ';
    top: 50%;
    position: absolute;
    left: -16px;
  }
`
export const CurvedContainer = styled.div`
  z-index: ${theme.zIndex.base};
  left: 20px;
  position: absolute;
  height: 100%;
  width: 30px;
  background-color: #eceef0;
`

// prettier-ignore
export const CurvedLine = styled(({ rotate, ...rest }) => <div {...rest} />)`
  position: absolute;
  top: 8px;
  width: 12px;
  height: 24px;
  border: solid 2px ${p => getColors(p).primary};
  border-color: ${p => getColors(p).primary} transparent transparent transparent;
  border-radius: 0 12px 0 0;

  ${p => p.rotate && `
    left: 20px;
    border-radius: 12px 0 0 0;
    transform: rotate(0deg);
  `};
`

// prettier-ignore
export const VerticalCone = styled.div`
  position: absolute;
  left: 10px;
  top: 24px;
  width: 12px;
  height: 86px;
  border: solid 2px ${p => getColors(p).primary};
  border-color: transparent ${p => getColors(p).primary} ${p => getColors(p).primary} ${p => getColors(p).primary};
  border-radius: 0 0 12px 12px;
`

export const VerticalDotsContainer = styled.div`
  z-index: ${theme.zIndex.base};
  position: absolute;
  left: 26px;
  height: calc(100% - 24px);
  margin-top: ${p => getSpacing(p).sm};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
