import { makeMessages, makeTranslation } from 'utils'

const messages = {
  modalTitle: 'modalTitle',
  showReplacementsBtn: 'showReplacementsBtn',
  noReplacements: 'noReplacements',
  addToTemplateBtn: 'addToTemplateBtn',
  removeFromTemplateBtn: 'removeFromTemplateBtn',
  addedNotification: { id: 'templates.addedNotification' },
  removedNotification: { id: 'templates.removedNotification' },
}

export default makeMessages(messages, makeTranslation('catalog.replacements'))
