import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AlternateBtn } from 'components/Button'
import { P } from 'components/Text'

import Notification from '../Notification'
import messages from './messages'

const Expired = ({ onButtonClick }) => (
  <Notification header={messages.expired}>
    <FormattedMessage {...messages.expiredDescription} />

    <AlternateBtn
      $fullWidth
      onClick={onButtonClick}
      style={{ marginTop: '24px' }}
    >
      <P bold interactive>
        <FormattedMessage {...messages.newLink} />
      </P>
    </AlternateBtn>
  </Notification>
)

export default Expired
