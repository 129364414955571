import React from 'react'

const Kebab = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12H12C12 13.8409 10.5076 15.3333 8.66667 15.3333H7.33333C5.49238 15.3333 4 13.8409 4 12Z"
      fill="#657280"
    />
    <circle cx="6" cy="3.33331" r="2" fill="#657280" />
    <circle cx="8" cy="2.66669" r="2" fill="#657280" />
    <circle cx="10" cy="3.33331" r="2" fill="#657280" />
    <path
      d="M12 12.7692C12 14.1853 10.852 15.3333 9.4359 15.3333C7.17011 15.3333 5.33333 13.4965 5.33333 11.2307L5.33333 7.99998C5.33333 5.05446 7.72115 2.66665 10.6667 2.66665L11.3333 2.66665C11.7015 2.66665 12 2.96512 12 3.33331L12 12.7692Z"
      fill="#A6B3C0"
    />
    <path
      d="M4 3.33335C4 2.96516 4.29848 2.66669 4.66667 2.66669H5.33333C8.27885 2.66669 10.6667 5.0545 10.6667 8.00002V11.2308C10.6667 13.4966 8.82989 15.3334 6.5641 15.3334C5.14799 15.3334 4 14.1854 4 12.7693V3.33335Z"
      fill="#8E9BA8"
    />
  </svg>
)

export default Kebab
