import { CURRENCY } from 'consts'
import { CART_EVENTS } from './consts'
import {
  normalizePrice,
  getFormattedProductParams,
  trackEcommerceEvent,
  formatItemListParams,
} from './utils'

import { retailTrackAddToCart, retailTrackRemoveFromCart } from './retail'

export const trackChangeCartProductQty = ({
  product,
  quantity,
  unitData,
  itemListName,
  itemListId,
  itemModelId,
  index,
  customerNo,
  additionalEventParams = {},
  attributionToken,
}) => {
  if (quantity > unitData.inCartQuantity) {
    const isChangingQuantity = unitData.inCartQuantity !== 0
    trackAddToCart({
      product,
      quantity: isChangingQuantity
        ? quantity - unitData.inCartQuantity
        : quantity,
      unitData,
      itemListName,
      itemListId,
      itemModelId,
      index,
      customerNo,
      attributionToken,
      additionalEventParams: {
        ...additionalEventParams,
        ...(isChangingQuantity && { is_changing_quantity: true }),
      },
    })
  } else {
    trackRemoveFromCart({
      product,
      quantity: unitData.inCartQuantity - quantity,
      unitData,
      itemListName,
      itemListId,
      itemModelId,
      index,
      customerNo,
      additionalEventParams: {
        ...additionalEventParams,
        is_changing_quantity: true,
      },
    })
  }
}

export const trackAddToCart = async ({
  product,
  quantity,
  unitData,
  itemListName,
  itemListId,
  itemModelId,
  index = 0,
  customerNo,
  additionalEventParams = {},
  attributionToken,
}) => {
  const value = normalizePrice(unitData.priceGross * quantity)
  const currency = CURRENCY
  const productParams = getFormattedProductParams(
    product,
    unitData,
    itemModelId,
  )

  trackEcommerceEvent(CART_EVENTS.ADD_TO_CART, {
    value,
    currency,
    customer_no: customerNo,
    items: [
      {
        ...productParams,
        ...formatItemListParams(itemListId, itemListName),
        price: value,
        quantity,
        index: index + 1,
      },
    ],
    ...additionalEventParams,
  })

  await retailTrackAddToCart({
    product,
    quantity,
    attributionToken,
  })
}

export const trackRemoveFromCart = async ({
  product,
  quantity,
  unitData,
  itemListName,
  itemListId,
  itemModelId,
  index = 0,
  customerNo,
  additionalEventParams = {},
}) => {
  const value = normalizePrice(unitData.priceGross * quantity)
  const currency = CURRENCY
  const productParams = getFormattedProductParams(
    product,
    unitData,
    itemModelId,
  )

  trackEcommerceEvent(CART_EVENTS.REMOVE_FROM_CART, {
    value,
    currency,
    customer_no: customerNo,
    items: [
      {
        ...productParams,
        ...formatItemListParams(itemListId, itemListName),
        price: value,
        quantity,
        index: index + 1,
      },
    ],
    ...additionalEventParams,
  })

  await retailTrackRemoveFromCart({
    product,
    quantity,
  })
}

const trackCartChange = ({ eventType, product }) => {
  const ecommerceDetails = {
    currencyCode: 'PLN',
    product,
  }

  trackEcommerceEvent(eventType, ecommerceDetails)
}

export const trackRemovedAllFromCart = ({ products }) => {
  if (products) {
    trackCartChange({
      product: products,
      eventType: CART_EVENTS.REMOVED_ALL_FROM_CART,
    })
  }
}
