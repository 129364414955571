import React from 'react'
import { useIntl } from 'react-intl'

import { RESTRICTION_DRIVEN_BY } from 'containers/Orders/consts'
import { hasRestrictionDrivenBy } from 'containers/Orders/utils'
import { formatPrice } from 'utils'

import Tile from '../../Tile'
import messages from '../../messages'
import CartActions from '../../ActionBar/CartActions'
import { LinesGroup, Line } from '../../Lines'

const HiddenDues = ({ tradeLimit }) => {
  const { formatMessage } = useIntl()
  const restrictionDrivenBy = hasRestrictionDrivenBy(tradeLimit)

  return (
    <Tile
      title={formatMessage(messages.creditLimit)}
      subtitle={formatMessage(messages.grossPrice, {
        price: formatPrice(tradeLimit.tradeLimit),
      })}
      footer={
        restrictionDrivenBy === RESTRICTION_DRIVEN_BY.CART_LIMIT ? (
          <CartActions scenario={tradeLimit.scenario} />
        ) : null
      }
    >
      <LinesGroup isFirst isLast>
        <Line label={formatMessage(messages.hiddenDuesMsg)} />
      </LinesGroup>
    </Tile>
  )
}

export default HiddenDues
