/* eslint-disable indent */
import styled from 'styled-components'

import { Wrapper, Container } from 'layouts/Notification'

import { H4, P, A } from 'components/Text'
import withIcon from 'components/Icon/withIcon'
import { ICON_SIZE, ICONS } from 'components/Icon'

import {
  getSpacing,
  getColors,
  getLineHeights,
  getFontWeights,
} from 'components/helpers/theme-selectors'

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => getColors(props).border};
  margin: ${props => getSpacing(props).base} auto;
`

export const BigHeader = styled(H4)`
  margin-bottom: ${props => getSpacing(props).base};
  line-height: ${props => getLineHeights(props).base};
`

export const Description = styled(P)``

export const PhoneLink = props =>
  withIcon({
    ...props,
    iconType: ICONS.PHONE,
    iconSize: ICON_SIZE.SMALL,
  })(styled(P)`
    margin-top: ${p => getSpacing(p).xs};
    display: flex;
    align-items: center;
    font-weight: ${p => getFontWeights(p).bold};
  `)

export const EmailLink = props =>
  withIcon({
    ...props,
    iconType: ICONS.MAIL,
    iconSize: ICON_SIZE.SMALL,
  })(styled(A)`
    margin-top: ${p => getSpacing(p).xs};
    display: flex;
    align-items: center;
    font-weight: ${p => getFontWeights(p).bold};
  `)

export const StyledWrapper = styled(Wrapper)`
  background: ${props => getColors(props).white};
  padding: 0;
`

// prettier-ignore
export const StyledContainer = styled(Container)`
  margin-top: auto;
  margin-bottom: auto;
  min-height: ${({ height }) => height || '320px'};
  padding: ${props => getSpacing(props).base};
  flex-direction: column;
  white-space: pre-wrap;
  position: relative;
  margin-top: 88px;
  margin-bottom: 88px;

  ${({ fluidHeight }) => fluidHeight && `
    height: auto;
    min-height: auto;
  `}

  ${BigHeader} {
    ${({ headerCenter }) => headerCenter && `
      text-align: center;
    `}
  }

  @media (min-width: 500px) {
    border: 1px solid ${props => getColors(props).border};

    ${({ spacedVertical }) => spacedVertical && `
      margin-top: 24px;
      margin-bottom: 24px;
    `};

    ${({ containerHeight }) => containerHeight && `
      min-height: ${containerHeight}px;
      height: ${containerHeight}px;
    `}

    ${({ containerWidth }) => containerWidth && `
      width: ${containerWidth}px;
    `};
  }
`
