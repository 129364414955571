import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { formatPrice, useRichFormatMessage } from 'utils'
import { PAY_SCENARIO } from 'containers/Orders'
import { cartTotalSelector } from 'containers/Cart/selectors'

import { TEXT_COLOR_BY_VARIANT, VARIANT } from 'views/Dashboard/Invoices/consts'
import messages from '../messages'
import Line from './Line'

export { LinesGroup } from './styles'
export { Line }

export const UnpaidInvoicesCount = ({ invoicesSummary }) => {
  const formatMessage = useRichFormatMessage()

  return (
    <Line
      testID="unpaid-invoices-count-line"
      label={formatMessage(messages.unpaidInvoicesCount)}
      value={formatMessage(messages.unpaidCountAmount, {
        count: invoicesSummary.toPay.invoicesCount,
        amount: formatPrice(invoicesSummary.toPay.remainingAmount),
      })}
    />
  )
}

export const Overpayments = ({ invoicesSummary }) => {
  const { formatMessage } = useIntl()

  return (
    <Line
      boldValue
      testID="overpayments-line"
      label={formatMessage(messages.overpayments)}
      value={formatPrice(invoicesSummary.overpaid.remainingAmount)}
    />
  )
}

export const CreditLimit = ({
  tradeLimit: { tradeLimit, scenario },
  hasHighlight,
}) => {
  const { formatMessage } = useIntl()
  const cartTotals = useSelector(cartTotalSelector)
  const hasCartIssue = cartTotals.totalGross > tradeLimit

  const valueColor = useMemo(
    () => {
      let highlightColor
      if (scenario === PAY_SCENARIO.RED) {
        highlightColor = TEXT_COLOR_BY_VARIANT[VARIANT.RED]
      } else if (scenario === PAY_SCENARIO.YELLOW) {
        highlightColor = TEXT_COLOR_BY_VARIANT[VARIANT.YELLOW]
      }

      return hasCartIssue || hasHighlight ? highlightColor : undefined
    },
    [scenario, hasCartIssue, hasHighlight],
  )

  return (
    <Line
      boldValue
      testID="trade-limit-line"
      label={formatMessage(messages.creditLimit)}
      value={formatPrice(tradeLimit)}
      valueColor={valueColor}
    />
  )
}
