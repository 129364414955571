import styled from 'styled-components'

import { APP_BREAKPOINTS } from 'consts'
import BasicBtn from 'components/Button/ButtonV2'
import theme from 'theme'

export const InfoBtn = styled(BasicBtn).attrs({
  $bgColor: theme.colors.infoBlue2,
  $hoverColor: theme.colors.infoBlue3,
})`
  flex: 1;
  padding: 0;
  height: 40px;

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    width: 40px;
  }
`
