import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import queryString from 'query-string'
import { useMediaQuery } from 'react-responsive'
import { doc, onSnapshot } from 'firebase/firestore'

import { firestore } from 'utils/firebase'
import { FIRESTORE_COLLECTION } from 'consts'
import qs from 'qs'
import { makeSelectedFiltersWithParams } from 'components/Filters/utils'
import cartActions from 'containers/Cart/actions'
import { makeChangeNotification } from 'components/Product/utils'

export function useQuery() {
  const { search } = useLocation()
  return queryString.parse(search, { parseNumbers: true })
}

export const useOfMaxWidth = breakpoint =>
  useMediaQuery({
    query: `(max-width: ${breakpoint}px)`,
  })

export const useOfMinWidth = breakpoint =>
  useMediaQuery({
    query: `(min-width: ${breakpoint}px)`,
  })

// docRef - document of Firestore you want to track
// defaultValue - value returned initially or in case maxDelay passed w/o a value from Firestore
// maxDelay - maximum delay before returning default value. If set to 0 defaultValue would be initially returned value
export const useFirestoreDoc = ({ docRef, defaultValue, maxDelay }) => {
  const [value, setValue] = useState(maxDelay ? null : defaultValue)
  const valueRef = useRef(value)
  const timeoutRef = useRef(null)

  useEffect(
    () => {
      valueRef.current = value
    },
    [value],
  )

  useEffect(() => {
    const unsub = onSnapshot(
      doc(firestore, FIRESTORE_COLLECTION, docRef),
      ref => {
        setValue(ref.data())
      },
      error => {
        console.warn('Could not connect to Firestore:', error)
      },
    )

    if (maxDelay) {
      timeoutRef.current = setTimeout(() => {
        if (!valueRef.current) {
          setValue(defaultValue)
        }
      }, maxDelay)
    }

    return () => {
      unsub()
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return value
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()
  useEffect(
    () => {
      savedCallback.current = callback
    },
    [callback],
  )

  useEffect(
    () => {
      const tick = () => {
        savedCallback.current()
      }
      if (delay) {
        const id = setInterval(tick, delay)
        return () => {
          clearInterval(id)
        }
      }
      return undefined
    },
    [callback, delay],
  )
}

export const useQueryFilters = () => {
  const { search } = useLocation()

  const queryParams = qs.parse(search, {
    ignoreQueryPrefix: true,
    arrayLimit: 0,
  })
  const selectedFiltersParams = queryParams.filters
  const searchQuery = queryParams.search
  const selectedFilters = makeSelectedFiltersWithParams(selectedFiltersParams)

  return {
    selectedFilters,
    selectedFiltersParams: selectedFiltersParams || {},
    searchQuery,
  }
}

export const useCustomFormikHandlers = props => {
  const formik = useFormik({
    // 'validateOnBlur' includes 'setFieldTouched', which we use for custom touched logic
    // leading to validation running on user's first input
    validateOnBlur: false,
    validateOnChange: false,
    ...props,
  })

  const {
    handleChange,
    errors,
    setErrors,
    touched,
    setFieldTouched,
    validateForm,
    setValues,
    values,
  } = formik
  const handleValueChange = (e, value) => {
    if (value !== undefined) {
      setValues(prevValues => ({
        ...prevValues,
        [e.target.name]: value,
      }))
    } else {
      handleChange(e)
    }

    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: null })
    }
    !touched[e.target.name] && setFieldTouched(e.target.name)
  }

  const handleBlur = async e => {
    const formErrors = await validateForm(values)
    setErrors({ ...errors, [e.target.name]: formErrors[e.target.name] })

    return formErrors[e.target.name]
  }

  return { ...formik, handleBlur, handleChange: handleValueChange }
}

export const useDeleteProductHandler = ({ product }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  return ({ productId, nonStock, unitOfMeasure }) => {
    dispatch(
      cartActions.deleteDelta({
        product,
        productId,
        nonStock,
        unitOfMeasure,
        successCallback: () => {
          dispatch(makeChangeNotification({ removedFromCart: true, intl }))
        },
      }),
    )
  }
}
