import styled from 'styled-components'

import theme from 'theme'
import { BaseTextV2Medium } from 'components/Text'

export const LabelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.ss};
`

export const Label = styled.div`
  display: flex;
  padding: ${theme.spacing.xxs} ${theme.spacing.ss};
  background-color: ${theme.colors.greenLight5};
  border-radius: ${theme.borderRadius.base};
`

export const LabelText = styled(BaseTextV2Medium)`
  color: ${theme.colors.secondary};
`
