import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { ContentWrapper } from 'components/Layout'
import { PageTitleDivider } from 'components/Text'

export const ProfileWrapper = styled(ContentWrapper)`
  padding: 0 32px;
  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    padding: 0 16px;
  }
`

// prettier-ignore
export const SectionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-${p => p.direction}: 48px;
  ${p => p.direction === 'bottom' && `
    border-bottom: 1px solid ${theme.colors.lightGrey2};
  `}
  > * {
    width: 100%;
    :not(:last-child) {
      padding-right: 32px;
      border-right: 1px solid ${theme.colors.lightGrey2};
    }
    :not(:first-child) {
      padding-left: 32px;
    }
  }
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 48px;
    > * {
      :last-child {
        padding-left: 0;
        padding-right: 32px;
        border-right: 1px solid ${theme.colors.lightGrey2};
      }
      :nth-child(2) {
        border-right: none;
        padding-right: 0;
      }
    }
  }
  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    display: flex;
    flex-direction: column;
    border-bottom: none;
    padding-${p => p.direction}: 4px;
    row-gap: 0;
    > * {
      border-right: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid ${theme.colors.lightGrey2};
    }
  }
`

export const Section = styled(Flex)`
  flex-direction: column;
`

export const ProfileTitleDivider = styled(PageTitleDivider)`
  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    margin-bottom: 8px;
  }
`
