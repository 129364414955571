import React from 'react'
import { Flex } from '@rebass/grid'
import styled from 'styled-components'

import { getColors, getSpacing } from 'components/helpers/theme-selectors'
import { Check } from 'components/Icons'

import { StyledText } from '../../styledComponents'

const RoutesWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  border-top: 1px solid ${p => getColors(p).gray2};
  margin-top: ${p => getSpacing(p).sm};

  > * {
    cursor: pointer;
    margin-top: ${p => getSpacing(p).xs};

    &:first-child {
      margin-top: ${p => getSpacing(p).sm};
    }
  }
`

const DeliveryRoutes = ({ selectedRouteId, routeChangeHandler, routes }) => (
  <RoutesWrapper>
    {routes.map(route => {
      const { routeId, routeName } = route

      return (
        <Flex
          onClick={() => routeChangeHandler(route)}
          key={routeId}
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledText>{routeName}</StyledText>

          {routeId === selectedRouteId && <Check />}
        </Flex>
      )
    })}
  </RoutesWrapper>
)

export default DeliveryRoutes
