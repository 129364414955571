import styled from 'styled-components'

import {
  getColors,
  getFonts,
  getTextSizes,
  getFontWeights,
  getLineHeights,
  getSpacing,
} from 'components/helpers/theme-selectors'

import IconWrapper from 'components/Icon/IconWrapper'
import ActionWrapper from 'components/ActionWrapper'

export const Container = styled(ActionWrapper)`
  display: flex;
  text-decoration: none;
  font-family: ${props => getFonts(props).base};
  font-weight: ${props => getFontWeights(props).bold};
  color: ${props => getColors(props).primary};
  font-size: ${props => getTextSizes(props).l};
  line-height: ${props => getLineHeights(props).md};
  cursor: pointer;
  width: fit-content;

  ${props =>
    !props.suppressSpacingTop &&
    `
    margin-top: ${getSpacing(props).sm};
  `} &:hover {
    color: ${props => getColors(props).primaryDark};
    text-decoration: none;
  }

  ${IconWrapper} {
    margin-right: ${props => getSpacing(props).ss};
  }
`

export const InnerContainer = styled.span`
  display: inline-flex;
  flex-flow: row;
  align-items: center;
`
