import React from 'react'

import theme from 'theme'

const ArrowDown = ({
  width = 28,
  height = 17,
  fill = theme.colors.primary,
  stroke = theme.colors.white,
  ...rest
}) => (
  <svg viewBox="0 0 28 17" fill="none" {...{ ...rest, width, height }}>
    <path
      d="M4.055 1L14 10.271 23.945 1 27 3.854 14 16 1 3.854 4.055 1z"
      fill={fill}
      fillOpacity={0.85}
      stroke={stroke}
    />
  </svg>
)

export default ArrowDown
