import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import {
  FilteredPageTileImage,
  FilteredPageTileTitle,
  FilteredPageTileWrapper,
} from './styles'

const FilteredPageTile = ({ variantConfig }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const {
    TileContainer = FilteredPageTileWrapper,
    ImageContainer = FilteredPageTileImage,
    imageSrc,
    message,
    route,
    clickEvent,
    testId,
  } = variantConfig

  return (
    <TileContainer
      onClick={() => {
        clickEvent()
        navigate(route)
      }}
      data-test-id={testId}
    >
      <FilteredPageTileTitle>{formatMessage(message)}</FilteredPageTileTitle>
      <ImageContainer src={imageSrc} />
    </TileContainer>
  )
}

export default FilteredPageTile
