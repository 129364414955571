import { useIntl } from 'react-intl'

import { PAY_SCENARIO } from 'containers/Orders'
import theme from 'theme'
import { TEXT_COLOR_BY_VARIANT, VARIANT } from 'views/Dashboard/Invoices/consts'
import messages from './messages'

export const useGetSubtitleFromTradeLimit = ({ scenario, defaultMessage }) => {
  const { formatMessage } = useIntl()

  if (scenario === PAY_SCENARIO.RED) {
    return {
      subtitleColor: TEXT_COLOR_BY_VARIANT[VARIANT.RED],
      subtitle: formatMessage(messages.subtitleCartLimit),
    }
  }
  if (scenario === PAY_SCENARIO.YELLOW) {
    return {
      subtitleColor: TEXT_COLOR_BY_VARIANT[VARIANT.YELLOW],
      subtitle: formatMessage(messages.subtitleCredit),
    }
  }
  return { subtitleColor: theme.colors.primary, subtitle: defaultMessage }
}
