export const TEMPLATES_ERRORS = {
  ITEM_NOT_EXISTS: 10209115089,
  TEMPLATE_NOT_EXISTS: 10209112081,
  NOT_ENOUGH_STOCK: 10209101093,
  MAXIMUM_TEMPLATE_SIZE: 10209112141,
}

export const SORT_BY_TYPES = {
  PURCHASE_DATE: 'purchase_date',
  NAME: 'name',
  CREATED_AT: 'created_at',
  SORTING_ORDER: 'sorting_order',
}

export const DEFAULT_SORT_BY_TYPES = {
  SYSTEM: SORT_BY_TYPES.SORTING_ORDER,
  USER: SORT_BY_TYPES.CREATED_AT,
}

export const TEMPLATE_ITEMS_ERRORS = {
  TEMPLATE_NOT_EXISTS: 10209110081,
}

export const TEMPLATES_INFO_REDUCER_NAME = 'templatesInfo'

export const CURRENT_TEMPLATE_REDUCER_NAME = 'currentTemplate'
export const TEMPLATES_REDUCER_NAME = 'templates'

export const TEMPLATE_PRODUCT_DETAILS_REDUCER_NAME = 'templateProductDetails'

export const TEMPLATE_NOT_ADDED_ITEMS_REDUCER_NAME = 'templateNotAddedItems'

export const TEMPLATE_AVAILABLE_FILTERS_REDUCER_NAME = 'availableFilters'
export const TEMPLATE_SELECTED_FILTERS_REDUCER_NAME = 'selectedFilters'
