import React, { useState, useCallback } from 'react'
import { identity } from 'lodash/fp'

import { TEMPLATE_MAX_LENGTH } from 'consts'
import { X, CheckV2 } from 'components/Icons'

import {
  CloseBtn,
  ListInputStyled,
  ListInputError,
  ListInputWrapper,
  CheckMarkBtn,
  ListInputContainer,
  InputAssistiveText,
} from './styles'

const ListInput = ({
  value,
  onSubmit,
  onSuccess,
  onFailure = identity,
  onClose = identity,
  onBlur = identity,
  maxLength = TEMPLATE_MAX_LENGTH,
  closeBtnTestId = 'template_close_creating',
  inputTestId = 'template_input_add',
  actionTestId = 'template_add_template',
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [name, setName] = useState(value || '')
  const [error, setError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const clearError = useCallback(() => {
    setError(null)
  }, [])

  const handleBlur = e => {
    setIsFocused(false)
    onBlur(e)
  }

  const onNameChange = useCallback(
    ({ target }) => {
      let newValue = target.value
      if (newValue.length > maxLength) {
        newValue = newValue.slice(0, maxLength)
      }

      setName(newValue)
      clearError()
    },
    [maxLength],
  )

  const submitCallback = useCallback(
    e => {
      e.preventDefault()

      if (!isSubmitting) {
        setIsSubmitting(true)
        clearError()

        onSubmit(
          { name: name.trim() },
          {
            onSuccess: params => {
              onSuccess(params)
            },
            onFailure: err => {
              onFailure(err)
              setIsSubmitting(false)
              setError(err)
            },
          },
        )
      }
    },
    [name, isSubmitting, onSubmit, onSuccess, onFailure],
  )

  const acceptBtnDisabled = !name || !!error || name === value

  return (
    <React.Fragment>
      <ListInputContainer
        onSubmit={submitCallback}
        isError={!!error}
        hasFocus={isFocused}
      >
        <ListInputWrapper>
          <ListInputStyled
            autoFocus
            onChange={onNameChange}
            type="text"
            onFocus={() => setIsFocused(true)}
            onBlur={e => handleBlur(e)}
            value={name}
            data-test-id={inputTestId}
          />
        </ListInputWrapper>
        <CloseBtn onClick={onClose} data-test-id={closeBtnTestId}>
          <X />
        </CloseBtn>
        <CheckMarkBtn
          onMouseDown={acceptBtnDisabled ? undefined : submitCallback}
          data-test-id={actionTestId}
          acceptBtnDisabled={acceptBtnDisabled}
        >
          <CheckV2 />
        </CheckMarkBtn>
      </ListInputContainer>

      {!!error?.length && (
        <InputAssistiveText>
          <ListInputError>{error}</ListInputError>
        </InputAssistiveText>
      )}
    </React.Fragment>
  )
}

export default ListInput
