import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import cartActions from 'containers/Cart/actions'
import { CartV2 } from 'components/Icons'
import { BaseTextV2 } from 'components/Text'
import messages from 'components/ProductCartControls/messages'
import theme from 'theme'

import ProductCartControlsTooltip from './ProductCartControlsTooltip'
import { useCartQuantityHandling } from './utils'
import { CartBtn } from './styles'
import QuantityControls from './QuantityControls'

const CartQuantityControls = ({ product, unitData }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const [affectedElasticStock, setAffectedElasticStock] = useState(null)
  const [stockLimitedAmount, setStockLimitedAmount] = useState(null)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  useEffect(
    () => {
      if (affectedElasticStock || stockLimitedAmount) {
        setIsTooltipOpen(true)
      }
    },
    [affectedElasticStock, stockLimitedAmount],
  )

  const [inputQuantity, setInputQuantity] = useState(unitData.inCartQuantity)
  const [isInputDisabled, setInputDisabled] = useState(false)

  const changeCartQuantity = useCartQuantityHandling({
    product,
    unitData,
    attributionToken: location.state?.attributionToken,
    beforeAll: () => setInputDisabled(true),
    afterAll: () => setInputDisabled(false),
    resetQuantityCallback: () => setInputQuantity(unitData.inCartQuantity),
    onAffectedElasticStock: affectedStock =>
      setAffectedElasticStock(affectedStock),
    onLimitedStock: stock => setStockLimitedAmount(stock),
    onChangeAmountToStock: stock => setInputQuantity(stock),
  })

  useEffect(
    () => {
      setInputQuantity(unitData.inCartQuantity)
    },
    [unitData],
  )

  const handleQuantityChange = (value, dispatchParams) => {
    if (value === 0 || unitData.inCartQuantity === 0) {
      setInputDisabled(true)
    }

    setInputQuantity(value)

    if (value !== unitData.inCartQuantity) {
      changeCartQuantity(value, dispatchParams)
    }
  }

  if (!inputQuantity) {
    return (
      <CartBtn
        data-test-id={`cart_${product.id}`}
        disabled={isInputDisabled}
        onClick={() => {
          handleQuantityChange(1)
        }}
      >
        <CartV2 />
        <BaseTextV2
          $color={theme.colors.white}
          $fontWeight={theme.fontWeights.medium}
        >
          {formatMessage(messages.toCart)}
        </BaseTextV2>
      </CartBtn>
    )
  }

  return (
    <ProductCartControlsTooltip
      product={product}
      unitData={unitData}
      affectedElasticStock={affectedElasticStock}
      stockLimitedAmount={stockLimitedAmount}
      matchTriggerWidth
      isOpen={isTooltipOpen}
      onConfirmDateShift={() => {
        handleQuantityChange(affectedElasticStock?.requestedQuantity, {
          suppressElasticStockCheck: true,
          onAddSuccessCallback: () => dispatch(cartActions.delta()),
        })
        setIsTooltipOpen(false)
      }}
      onCancel={() => {
        setIsTooltipOpen(false)
      }}
      onClosed={() => {
        setAffectedElasticStock(null)
        setStockLimitedAmount(null)
      }}
    >
      <QuantityControls
        product={product}
        unitData={unitData}
        disabled={isInputDisabled || isTooltipOpen}
        quantity={inputQuantity}
        onQuantityChange={handleQuantityChange}
      />
    </ProductCartControlsTooltip>
  )
}

export default CartQuantityControls
