import React from 'react'
import Button, { buttonProps } from './Button'
import { BUTTON_TYPES } from './consts'

const SecondaryBtn = props => (
  <Button {...props} type={BUTTON_TYPES.SECONDARY} />
)

SecondaryBtn.propTypes = buttonProps

export default SecondaryBtn
