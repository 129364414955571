import theme from 'theme'

export const HEADER_DROPDOWN_TRANSITIONS = {
  content: {
    initial: {
      scale: '0.9',
      opacity: 0,
    },
    common: {
      transitionTimingFunction: 'cubic-bezier(0, 0, 0, 1)',
      transformOrigin: 'top right',
    },
    duration: 450,
  },
  overlay: {
    open: {
      opacity: 0.3,
    },
    common: {
      transitionTimingFunction: 'ease-out',
      backgroundColor: theme.colors.gray5,
      zIndex: theme.zIndex.header - 1,
    },
    duration: 300,
  },
}

export const HEADER_DROPDOWN_MOBILE_TRANSITIONS = {
  content: {
    initial: {
      maxHeight: 0,
    },
    open: {
      maxHeight: 'calc(100vh - 56px)',
    },
    common: {
      overflow: 'hidden',
    },
    duration: 300,
  },
}
