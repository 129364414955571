import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import theme from 'theme'
import { TinyText } from 'components/Text'

import { APP_BREAKPOINTS } from 'consts'

export const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.bgGray};
  border-bottom: 1px solid ${theme.colors.gray2};

  ${p => !p.isExpanded && 'height: 74px'};
  ${p => p.withBorder && `border-top: 1px solid ${theme.colors.gray2};`};

  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    ${p => !p.isExpanded && 'height: auto'};
  }
`

export const Heading = styled(Flex)`
  height: 72px;
  align-items: center;
  padding: ${theme.spacing.sm};
  ${p => p.toggleable && `cursor: pointer`};

  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    height: auto;
  }
`

export const DeliveryCaption = styled(TinyText)`
  line-height: 20px;
  display: inline;

  b {
    font-weight: bold;
  }
`

export const VerticalLine = styled(Box)`
  width: 1px;
  background-color: ${theme.colors.gray2};
  height: 100%;
`

export const MobileRightSide = styled(Flex)`
  height: 100%;
  flex-direction: column;
  border-left: 1px solid ${theme.colors.gray2};
  padding-left: ${theme.spacing.sm};
  margin-left: ${theme.spacing.sm};
`
