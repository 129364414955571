import styled, { css } from 'styled-components'

import theme from 'theme'
import { SubheaderV2 } from 'components/Text'
import { SecondaryBtn, AlternateBtn } from 'components/Button'
import { DefaultPopoverContainer } from 'components/Popup/styles'

const tooltipBtnV2Styles = css`
  padding: 8px 16px;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: ${theme.textSizes.sm};
  border: none;
  white-space: break-spaces;
  text-align: initial;
  > span {
    max-width: 151px;
  }
`

const discardBtnV2Styles = css`
  ${tooltipBtnV2Styles}
  border-radius: ${p => (p.isFullyRounded ? '8px' : '0px 0px 8px 8px')};
  color: ${theme.colors.white};
  background: ${theme.colors.infoBlue2};
  margin-top: 2px;
  :hover {
    background: ${theme.colors.infoBlue3};
    color: ${theme.colors.white};
  }
`

export const Container = styled.div`
  background: ${theme.colors.greenDark};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${theme.spacing.xs};
  justify-content: space-between;
  height: 100%;
`

export const TitleContainer = styled.div`
  padding: ${theme.spacing.xs};
  display: flex;
  flex-flow: column;
`

export const Title = styled(SubheaderV2)`
  color: #fff;
`

export const BottomBar = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: ${theme.spacing.sm};
`

export const DelayButton = styled(SecondaryBtn)`
  width: 100%;
  margin-top: ${theme.spacing.xs};
`

export const DiscardButton = styled(AlternateBtn)`
  color: ${theme.colors.secondary};

  &:hover {
    border: none;
  }
`

export const DiscardButtonV2 = styled(DiscardButton)`
  ${discardBtnV2Styles};
`

export const CatalogContainer = styled(Container)`
  ${DefaultPopoverContainer};

  padding: ${theme.spacing.sm};
  ${TitleContainer} {
    padding: 4px;
    max-width: 176px;
    margin: 0 auto;
  }
  ${Title} {
    color: ${theme.colors.infoBlue2};
    font-family: ${theme.fonts.primary};
    font-weight: 500;
    font-size: ${theme.textSizes.sm};
    line-height: 20px;
    align-items: center;
  }
  ${BottomBar} {
    max-width: 184px;
    flex-direction: column-reverse;
  }
  ${DiscardButton}, ${DelayButton} {
    ${tooltipBtnV2Styles};
  }
  ${DiscardButton} {
    ${discardBtnV2Styles};
  }
  ${DelayButton} {
    border-radius: 8px 8px 0px 0px;
    color: ${theme.colors.primary};
    background: ${theme.colors.lightGrey};
    margin-top: 0;
    :hover {
      background: ${theme.colors.lightGrey3};
    }
  }
`
