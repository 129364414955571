import { makeMessages, makeTranslation } from 'utils'

const messages = {
  lacksToLogisticLimit: 'lacksToLogisticLimit',
  addProductsToDelivery: 'addProductsToDelivery',
}

export default makeMessages(
  messages,
  makeTranslation('orderDetails.logisticInfo'),
)
