import styled from 'styled-components'

import { H3v2, BaseTextV2 } from 'components/Text'

import theme from 'theme'

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.base};
`

export const ColumnHeader = styled(H3v2)`
  line-height: 36px;
`

export const ColumnRow = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
`

export const ColumnRowText = styled(BaseTextV2).attrs({
  isSmall: { fontSize: true, lineHeight: true },
})`
  text-align: left;
  white-space: break-spaces;
  word-break: break-word;
`

export const ColumnRowLabel = styled(ColumnRowText)`
  color: ${theme.colors.lightGrey4};
  width: 132px;
  flex-shrink: 0;
`
