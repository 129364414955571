import { combineReducers } from 'redux-immutable'

import { createReducer } from 'utils/simpleDataReducer'

import {
  resetPasswordActions,
  confirmResetPasswordActions,
  validateResetPasswordTokenActions,
} from './actions'
import {
  RESET_PASSWORD_INIT_REDUCER_NAME,
  RESET_PASSWORD_CONFRIRM_REDUCER_NAME,
  RESET_PASSWORD_TOKEN_VALIDATOR_REDUCER_NAME,
} from './consts'

export default combineReducers({
  [RESET_PASSWORD_INIT_REDUCER_NAME]: createReducer(resetPasswordActions),
  [RESET_PASSWORD_CONFRIRM_REDUCER_NAME]: createReducer(
    confirmResetPasswordActions,
  ),
  [RESET_PASSWORD_TOKEN_VALIDATOR_REDUCER_NAME]: createReducer(
    validateResetPasswordTokenActions,
  ),
})
