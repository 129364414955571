import styled from 'styled-components'
import { Box } from '@rebass/grid'

import { BrandedLink } from 'components/Text'
import { getSpacing } from 'components/helpers/theme-selectors'
import { InputWrapper } from 'components/Input/styles'

export const PasswordContainer = styled(Box)`
  ${BrandedLink} {
    display: block;
    margin-top: ${props => getSpacing(props).xs};
    margin-left: ${props => getSpacing(props).sm};
  }

  ${InputWrapper} {
    margin-top: 0;
  }

  ${({ stretch }) => stretch && `width: 100%;`};
`
