import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { Link } from 'components/Text'
import { CalendarChange, X } from 'components/Icons'
import { APP_BREAKPOINTS } from 'consts'

export const ImgContainer = styled(Link)`
  mix-blend-mode: multiply;
  width: 48px;
  height: 48px;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: 80px;
    height: 100%;
    align-items: center;
    display: flex;
  }
`

export const Calendar = styled(CalendarChange)`
  cursor: pointer;
`

export const XIcon = styled(X)`
  margin-right: ${theme.spacing.xs};
  cursor: pointer;
`

export const MobileIconsWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.gray2};
  margin-top: ${theme.spacing.sm};
  padding-top: ${theme.spacing.ss};
`
