import queryString from 'query-string'

import { INVOICES_PER_PAGE_SIZE } from '../consts'

export const getInvoices = ({
  perPage = INVOICES_PER_PAGE_SIZE,
  page = 1,
  type,
  startDate,
  endDate,
} = {}) => {
  // prettier-ignore
  const from = startDate ? startDate.startOf('day').format() : null
  // prettier-ignore
  const to = endDate ? endDate.endOf('day').format() : null

  const queryParams = queryString.stringify(
    { per_page: perPage, page, type, from, to },
    { skipNull: true },
  )

  return {
    url: `me/invoices?${queryParams}`,
  }
}

export const getInvoiceFile = ({ invoiceId }) => ({
  url: `me/invoices/${invoiceId}/file`,
})
