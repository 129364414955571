import React from 'react'

const Placeholder = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.90046 6.40134C8.89503 6.6131 8.80097 6.81743 8.6412 6.96452L6.87063 8.59463L8.072 9.796L9.7021 8.02543C9.8492 7.86565 10.0535 7.77159 10.2653 7.76616L11.2226 7.74162C11.423 7.73648 11.6172 7.65196 11.7625 7.50665L14.549 4.72014C14.7003 4.56884 14.7064 4.32967 14.5627 4.18594C14.419 4.0422 14.1798 4.04834 14.0285 4.19963L11.8369 6.39124C11.6856 6.54254 11.4464 6.54867 11.3027 6.40493C11.159 6.2612 11.1651 6.02203 11.3164 5.87073L13.508 3.67913C13.6593 3.52783 13.6654 3.28866 13.5217 3.14492C13.378 3.00119 13.1388 3.00732 12.9875 3.15862L10.7959 5.35023C10.6446 5.50152 10.4054 5.50766 10.2617 5.36392C10.118 5.22019 10.1241 4.98102 10.2754 4.82972L12.467 2.63812C12.6183 2.48682 12.6244 2.24765 12.4807 2.10391C12.337 1.96018 12.0978 1.96631 11.9465 2.11761L9.15998 4.90412C9.01467 5.04943 8.93015 5.24363 8.92501 5.44399L8.90046 6.40134Z"
      fill="#A6B3C0"
    />
    <path
      d="M6.73485 11.2484C6.97698 10.9854 6.96858 10.5782 6.71579 10.3254L6.34122 9.95083C6.08843 9.69805 5.68126 9.68964 5.41826 9.93178L2.92614 12.2262C2.60163 12.525 2.57812 13.0216 2.87446 13.3179L3.34872 13.7922C3.64506 14.0885 4.14166 14.065 4.44043 13.7405L6.73485 11.2484Z"
      fill="#A6B3C0"
    />
    <path
      d="M2.03298 2.13261C1.77368 1.8936 1.36877 1.99397 1.34433 2.30331C1.2976 2.89469 1.39992 3.5004 1.64344 4.07403L1.88183 4.63557C2.28285 5.58019 2.96752 6.4197 3.85028 7.04921C4.49009 7.50546 5.21631 7.83876 5.98032 8.02677L6.09736 8.05557C6.29184 8.10343 6.47136 8.21121 6.60433 8.35994L11.4056 13.6682C11.7425 14.0449 12.3217 14.112 12.6473 13.812L13.1294 13.3676C13.431 13.0896 13.3938 12.6045 13.0462 12.2841L2.03298 2.13261Z"
      fill="#8E9BA8"
    />
  </svg>
)

export default Placeholder
