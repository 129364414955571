import { makeMessages, makeTranslation } from 'utils'

const messages = {
  toCart: 'toCart',
  maxAvailableAmount: { id: 'nonStock.maxAvailableAmount' },
  missingLicense: 'missingLicense',
  missingLicenseTooltipText: 'missingLicenseTooltipText',
  replacements: { id: 'prices.replacementButton' },
  stockLimitedText: { id: 'stockLimited.text' },
  productUnavailable: 'productUnavailable',
  openSetupModalBtn: 'notification.openSetupModalBtn',
  openChangeEmailModalBtn: 'notification.openChangeEmailModalBtn',
}

export default makeMessages(messages, makeTranslation('product'))
