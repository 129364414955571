import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'
import { BUTTON_SIZE, BUTTON_TYPES } from 'components/Button/consts'
import { Button } from 'components/Button'
import { Container } from '../Contacts/ActionLink/styles'
import { InnerContainer } from '../SectionColumn/styles'
import SectionColumn from '../SectionColumn'

export const AppsSectionColumn = styled(SectionColumn)`
  ${InnerContainer} {
    flex-flow: row;
    gap: 8px;
  }
`

export const AppContainer = styled(Container)`
  width: 100%;
  height: fit-content;
`

export const AppBtn = styled(Button).attrs({
  size: BUTTON_SIZE.BASIC,
  type: BUTTON_TYPES.BASIC,
})`
  background: ${theme.colors.lightGrey7};
  border-radius: 8px;
  width: 100%;
  height: 48px;
  gap: 10px;
  transition: all 0.2s ease-in;

  :hover {
    background-color: ${theme.colors.gray6};
  }
`

export const AppBtnTextContainer = styled(Flex)`
  flex-direction: column;
  gap: 2px;
`

export const AppBtnText = styled(BaseTextV2)`
  font-size: 10px;
  line-height: 12px;
  align-self: flex-start;
  ${props =>
    props.bold &&
    `
    font-weight: 600;
  `};
`

export const AppsContainer = styled.div`
  display: flex;
  flex-flow: column;
`

export const CopyrightContainer = styled.div`
  margin-top: 32px;
`
