import { takeLatest, call, all, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'

import {
  getCategory,
  getCategoriesList,
  getCategoriesListWithParams,
  getCategoryGroups,
  getCategorySubgroups,
} from './api'
import {
  categoryActions,
  categoriesListActions,
  categoryGroupActions,
  categorySubgroupActions,
  allCategoriesListActions,
} from './actions'

export function* getCategoryFlow({ data: body }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: categoryActions,
      request: getCategory,
      params: {
        body,
      },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* getAllCategoriesFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: allCategoriesListActions,
      request: getCategoriesList,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* getCategoriesListFlow({ data: body } = {}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: categoriesListActions,
      request: getCategoriesListWithParams,
      params: {
        body,
      },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* getCategoryGroupFlow({ data: body }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: categoryGroupActions,
      request: getCategoryGroups,
      params: {
        body,
      },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* getCategorySubgroupFlow({ data: body }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: categorySubgroupActions,
      request: getCategorySubgroups,
      params: {
        body,
      },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export const categoriesWatchers = [
  takeLatest(categoryActions.DELTA, getCategoryFlow),
  takeLatest(allCategoriesListActions.DELTA, getAllCategoriesFlow),
  takeLatest(categoriesListActions.DELTA, getCategoriesListFlow),
  takeLatest(categoryGroupActions.DELTA, getCategoryGroupFlow),
  takeLatest(categorySubgroupActions.DELTA, getCategorySubgroupFlow),
]

export default function* watcher() {
  yield all(categoriesWatchers)
}
