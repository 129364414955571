import React from 'react'

import {
  StyledCheckIcon,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
} from './styles'

const CheckboxV2 = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <StyledCheckIcon />
    </StyledCheckbox>
  </CheckboxContainer>
)

export default CheckboxV2
