import { select, all, put } from 'redux-saga/effects'

import { rtkApi } from 'services/api/rtkApi'

export function* updateRtkListRequest({ endpointName, updatedProduct, propsToUpdate }) {
  const queryArgsList = yield select(state =>
    rtkApi.util.selectCachedArgsForQuery(state, endpointName),
  )
  yield all(
    (queryArgsList || []).map(queryArgs =>
      put(
        rtkApi.util.updateQueryData(endpointName, queryArgs, state => {
          // for cases with items list
          const listState = state?.products || state?.items

          return listState?.forEach(entry => {
            // for cases with nested product inside item obj
            const product = entry.product || entry

            if (product.id === updatedProduct.id) {
              propsToUpdate.forEach(prop => {
                // eslint-disable-next-line no-param-reassign
                product[prop] = updatedProduct[prop]
              })
            }
          })
        }),
      ),
    ),
  )
}