import React from 'react'

import theme from 'theme'

const BookmarkList = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M17 8L23 8" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M17 12H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M17 16H23" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path
      d="M3 6H13V19L8 15L3 19V6Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default BookmarkList
