import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import CatalogButtonsContainer from 'components/Product/ButtonsContainer/CatalogButtonsContainer'
import ProductLinkImage from 'components/Product/ProductLinkImage'
import {
  CatalogAddProductsButton,
  CatalogProductButtonsContainer,
} from 'components/Product/ProductButtons/styles'
import { DetailTextV2 } from 'components/Text'
import { PROMO_GROUP_MOBILE_BREAKPOINT } from '../const'

export const PrizeRowContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`

export const PrizeRowDescriptionContainer = styled(Flex)`
  align-items: center;
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`

export const PrizeRowImg = styled(ProductLinkImage)`
  margin-right: 21px;
  img,
  div {
    width: 64px;
    height: 64px;
  }
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    img,
    div {
      width: 72px;
      height: 72px;
    }
    margin-right: 24px;
    margin-left: 8px;
  }
`

export const PrizeRowTextContainer = styled(Flex)`
  margin-right: 24px;
  padding: 8px 0;
  flex-direction: column;
  white-space: pre-wrap;
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    margin: 0;
  }
`

export const PrizeRowTextSpan = styled.span`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.colors.lightGrey4};
`

export const PrizeRowTextTitle = styled(PrizeRowTextSpan)`
  color: ${theme.colors.secondary};
  font-weight: 600;
  margin-bottom: 8px;
`

export const PrizeRowDetails = styled(Flex)`
  margin: 12px 0;
  gap: ${theme.spacing.sm};
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    gap: 0;
    width: 100%;
    margin: ${theme.spacing.sm} 0 ${theme.spacing.xs} 0;
    > span:last-child {
      flex: 1;
    }
  }
`

export const PrizeRowPrice = styled(Flex)`
  font-family: ${theme.fonts.primary};
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.secondary};
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    background-color: ${theme.colors.greenLight2};
    margin-left: ${theme.spacing.sm};
    border-radius: ${theme.spacing.xs} 0 0 ${theme.spacing.xs};
    padding: 0 20px;
  }
`

export const PrizeRowCount = styled(Flex)`
  max-height: 40px;
  padding: 10px 0;
  padding-right: 10px;
  border-radius: 8px;
  background: ${props =>
    theme.colors[
      props.prizeQtyToClaim && !props.inUserCatalog
        ? 'secondary'
        : 'greenLight2'
    ]};
  ${props =>
    props.inUserCatalog &&
    `
    padding: 1px 4px 1px 8px;
  `};
`

export const PrizeRowCountQuantity = styled.span`
  font-family: ${theme.fonts.primary};
  font-size: 13px;
  width: 58px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: ${props =>
    theme.colors[props.prizeQtyToClaim ? 'white' : 'greenDark']};
  font-weight: 500;
`

export const CircularProgressbarContainer = styled.div`
  width: 20px;
  height: 20px;
`

// prettier-ignore
export const PrizeRowButtons = styled(CatalogButtonsContainer)`
  margin-top: 0;
  ${CatalogAddProductsButton} {
    padding: 8px 88px;
  }
  ${CatalogProductButtonsContainer} {
    width: 200px;
    background: ${theme.colors.greenLight2};
    input {
      color: ${theme.colors.greenDark};
    }
    button,
    input {
      background: ${theme.colors.greenLight2};
      :hover {
        background: ${theme.colors.greenLight2} !important;
      }
    }
    @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
      border-radius: 0 ${theme.spacing.xs} ${theme.spacing.xs} 0;
      margin-left: 2px;
      width: auto;
      flex: 1;
      > form {
        flex: 1;
      }
    }
    ${props => props.prizeQtyToClaim && (props.unitData.inCartQuantity < props.maxValue) && `
      button:nth-of-type(2) {
        background: ${theme.colors.secondary};
        opacity: 1;
        :hover {
          background: ${theme.colors.secondary} !important;
        }
        path {
          stroke: ${theme.colors.white}
        }
      }
    `};
  }
`

export const UnitSelectionContainer = styled(Flex)`
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    background-color: ${theme.colors.greenLight2};
    margin-left: ${theme.spacing.sm};
    border-radius: ${theme.spacing.xs} 0 0 ${theme.spacing.xs};
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
`

export const UnitCounterContainer = styled(Flex)`
  flex-direction: column;
  padding-bottom: 2px;
  padding-right: 6px;
`

export const UnitCounter = styled(DetailTextV2)`
  color: ${theme.colors.greenDark};
  font-weight: 500;
  padding: 2px 0;
  display: flex;
  align-items: center;
  :after {
    display: inline-block;
    content: '';
    border-radius: 8px;
    height: 2px;
    width: 2px;
    margin: 1px;
    margin-left: 4px;
    background-color: ${theme.colors.greenDark};
  }
`

export const UnitPriceContainer = styled(Flex)`
  flex-direction: column;
  padding-bottom: 2px;
  margin: 1px 0;
  > span:nth-child(2) {
    margin-top: 2px;
  }
`

export const UnitPrice = styled(UnitCounter)`
  padding: 1px 4px;
  border-radius: 4px;
  margin: 0;
  text-align: right;
  :after {
    display: none;
  }
`

export const PrizeUnitPrice = styled(UnitPrice)`
  background: ${props =>
    props.isHighlighted ? theme.colors.secondary : '#acdbb0'};
  color: ${theme.colors.white};
`

export const TotalPointsToThresholdProgress = styled.span`
  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.medium};
  margin-top: ${theme.spacing.xs};
`

export const RewardProgressTextContainer = styled.span`
  display: flex;
  margin-top: ${theme.spacing.xxs};
  gap: ${theme.spacing.base};
  flex-wrap: wrap;
`
