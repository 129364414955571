import React from 'react'

const Burger = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 18C3 19.66 4.34 21 6 21H18C19.66 21 21 19.66 21 18V17H3V18Z"
      fill="#E39600"
    />
    <path d="M3 9C3 9 3 3 12 3C21 3 21 9 21 9H3Z" fill="#E39600" />
    <path
      d="M19.2 15C20.199 15 21 14.11 21 13C21 12.4696 20.8104 11.9609 20.4728 11.5858C20.1352 11.2107 19.6774 11 19.2 11H17.4L15.15 13L12.9 11H4.8C4.32261 11 3.86477 11.2107 3.52721 11.5858C3.18964 11.9609 3 12.4696 3 13C3 13.5304 3.18964 14.0391 3.52721 14.4142C3.86477 14.7893 4.32261 15 4.8 15H19.2Z"
      fill="#38170D"
    />
  </svg>
)

export default Burger
