import React from 'react'

import theme from 'theme'

const CardC = ({ color = theme.colors.white }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V8H2V7ZM2 11H22V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V11ZM15 14C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14H15Z"
      fill={color}
    />
  </svg>
)

export default CardC
