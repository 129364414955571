import React from 'react'

import theme, { paymentTheme } from 'theme'

import { StyledButton, BUTTON_VARIANT } from './styles'

export { BUTTON_VARIANT }

export const STYLE_BY_VARIANT = {
  [BUTTON_VARIANT.GREEN]: paymentTheme.green,
  [BUTTON_VARIANT.YELLOW]: {
    bg: paymentTheme.yellow.bgHover,
    bgHover: theme.colors.yellowDark,
    text: paymentTheme.yellow.text,
  },
  [BUTTON_VARIANT.LIGHT_RED]: {
    bg: paymentTheme.red.bg,
    bgHover: paymentTheme.red.bgHover,
    text: paymentTheme.red.text,
  },
}

const Button = ({ children, IconComponent, variant, ...rest }) => {
  const variantStyle =
    STYLE_BY_VARIANT[variant] || STYLE_BY_VARIANT[BUTTON_VARIANT.GREEN]

  return (
    <StyledButton $variantStyle={variantStyle} {...rest}>
      {children}

      <i>
        <IconComponent color={variantStyle.text} />
      </i>
    </StyledButton>
  )
}

export default Button
