import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from '@rebass/grid'
import { get, isPlainObject, reduce } from 'lodash/fp'

import { ROUTES, APP_BREAKPOINTS } from 'consts'
import { mapValuesDeep } from 'utils'
import { useOfMaxWidth } from 'hooks'
import {
  useGetStorageQuery,
  useUpdateStorageMutation,
} from 'services/api/rtkApi'

import {
  CONFIG_API_PATHS_MAP,
  CONFIG_SCHEMA,
  CONFIG_SCHEMA_KEYS,
} from 'containers/Storage'

import messages from '../messages'
import {
  Arrow,
  CheckboxStyled,
  ClearCart,
  ClearIcon,
  ContinueShopping,
  Wrapper,
} from './styles'

const anyTrueDeep = reduce((acc, val) => {
  if (acc) return acc
  if (isPlainObject(val)) return anyTrueDeep(val)
  if (val) return val
  return acc
}, false)

const ActionsHeader = ({ hideActionButtons, onClearCart }) => {
  const { data: storageData } = useGetStorageQuery()
  const [updateStorage] = useUpdateStorageMutation()
  const storage = storageData?.data

  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TABLET_START - 1)
  const cartGuideStorage = get(CONFIG_SCHEMA_KEYS.CART_GUIDE.CART_GUIDE)(
    storage,
  )
  const anyHintLeft = useMemo(() => anyTrueDeep(cartGuideStorage), [
    cartGuideStorage,
  ])

  const handleCheckboxToggle = useCallback(
    () => {
      updateStorage({
        path: CONFIG_API_PATHS_MAP.CART_GUIDE.CART_GUIDE,
        value: anyHintLeft
          ? mapValuesDeep(() => false)(cartGuideStorage)
          : mapValuesDeep(() => true)(CONFIG_SCHEMA.cartGuide),
      })
    },
    [anyHintLeft, cartGuideStorage, updateStorage],
  )

  return (
    <Wrapper>
      <ContinueShopping to={ROUTES.CATEGORIES}>
        <Arrow />

        <FormattedMessage {...messages.continueShopping} />
      </ContinueShopping>

      {!hideActionButtons && (
        <Flex>
          <Flex alignItems="center">
            {!isMobile && <FormattedMessage {...messages.hints} />}

            <CheckboxStyled
              id="cart-hint-checkbox"
              checked={anyHintLeft}
              onChange={handleCheckboxToggle}
            />
          </Flex>

          <ClearCart onClick={onClearCart} data-test-id="cart_remove_all">
            {!isMobile && <FormattedMessage {...messages.clearCart} />}

            <ClearIcon />
          </ClearCart>
        </Flex>
      )}
    </Wrapper>
  )
}

export default ActionsHeader
