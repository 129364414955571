import React from 'react'
import { FormattedMessage } from 'react-intl'

import { makeMessages, makeTranslation, FormattedRichMessage } from 'utils'

import deliveryImg from 'assets/hintsImgs/delivery1.png'
import otherDeliveryImg from 'assets/hintsImgs/delivery3.png'

const messages = makeMessages(
  {
    header: 'header',
    info: 'info',
  },
  makeTranslation('hintModal.freeDeliveries'),
)

const header = <FormattedMessage {...messages.header} />

export const hintModalConfig = [
  {
    header,
    img: deliveryImg,
    info: <FormattedRichMessage {...messages.info} />,
  },
]

const otherDeliveryMessages = makeMessages(
  {
    header: 'header',
    info: 'info',
  },
  makeTranslation('hintModal.otherDelivery'),
)

const otherDeliveryHeader = (
  <FormattedMessage {...otherDeliveryMessages.header} />
)

export const otherDeliveryHintModalConfig = [
  {
    header: otherDeliveryHeader,
    img: otherDeliveryImg,
    info: <FormattedRichMessage {...otherDeliveryMessages.info} />,
  },
]
