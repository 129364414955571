import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  useAuthenticatedKnockClient,
  useNotifications,
  useNotificationStore,
} from '@knocklabs/react-core'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { KNOCK_API_KEY, KNOCK_IN_APP_CHANNEL } from 'consts'

import { FEED_MESSAGE_MAP } from './consts'

export const useNotificationFeed = () => {
  const userData = useSelector(userDataSelector)
  // some methods/requests on feedClient don't work with numbers
  const accountId = userData.accountId?.toString()
  const knockClient = useAuthenticatedKnockClient(KNOCK_API_KEY, accountId)
  const feedClient = useNotifications(knockClient, KNOCK_IN_APP_CHANNEL)
  const { items, metadata } = useNotificationStore(feedClient)

  const filteredItems = useMemo(
    () => items.filter(item => FEED_MESSAGE_MAP[item.source.key]),
    [items],
  )

  const markAsRead = useCallback(value => feedClient.markAsRead(value), [
    feedClient,
  ])

  useEffect(
    () => {
      feedClient.fetch()
    },
    [feedClient],
  )

  return { items: filteredItems, metadata, markAsRead }
}
