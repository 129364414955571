import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

export const AmountsContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid ${theme.colors.borderGreen};
  width: 202px;
  padding-left: 14px;
`
