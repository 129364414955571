import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { ProductBoxPromoText } from '../ProductBox/styles'

export const SaleTextContainer = styled(Flex)`
  justify-content: space-between;
  gap: ${theme.spacing.xxs};
`

export const SaleText = styled(ProductBoxPromoText)`
  padding: 0;
`

export const SaleContainer = styled(Flex)`
  flex-direction: column;
`
