/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form/immutable'

import appReducer from 'containers/App/reducer'
import { APP_REDUCER_NAME } from 'containers/App/reducer/consts'
import languageProviderReducer from 'containers/LanguageProvider/reducer'
import { LOGOUT_ACTION_TYPE } from 'containers/Auth/actions'
import { AUTH_REDUCER_NAME } from 'containers/Auth/consts'
import authReducer from 'containers/Auth/reducer'
import { USER_INFO_REDUCER_NAME } from 'containers/UserInfo/consts'
import userInfoReducer from 'containers/UserInfo/reducer'
import { CART_INFO_REDUCER_NAME } from 'containers/Cart/consts'
import promosReducer from 'containers/Promotions/reducer'
import { PROMOS_REDUCER_NAME } from 'containers/Promotions/consts'
import cartReducer from 'containers/Cart/reducer'
import { ORDER_INFO_REDUCER_NAME } from 'containers/Orders/consts'
import orderReducer from 'containers/Orders/reducer'
import { DELIVERY_INFO_REDUCER_NAME } from 'containers/Delivery/consts'
import deliveryReducer from 'containers/Delivery/reducer'
import { CATEGORY_REDUCER_NAME } from 'containers/Categories/consts'
import categoryReducer from 'containers/Categories/reducer'
import { CAMPAIGNS_REDUCER_NAME } from 'containers/Campaigns/consts'
import campaignsReducer from 'containers/Campaigns/reducer'
import { TEMPLATES_INFO_REDUCER_NAME } from 'containers/Templates/consts'
import templatesReducer from 'containers/Templates/reducer'
import { invoicesReducer, INVOICES_REDUCER_NAME } from 'containers/Invoices'
import productsReducer from 'containers/Products/reducer'
import { PRODUCTS_REDUCER_NAME } from 'containers/Products/consts'
import profileReducer from 'containers/Profile/reducer'
import { PROFILE_REDUCER_NAME } from 'containers/Profile/consts'
import resetPasswordReducer from 'containers/Profile/ResetPassword/reducer'
import { RESET_PASSWORD_REDUCER_NAME } from 'containers/Profile/ResetPassword/consts'
import productDetailsReducer from 'containers/ProductDetails/reducer'
import { PRODUCT_DETAILS_REDUCER_NAME } from 'containers/ProductDetails/consts'
import searchReducer from 'containers/components/Search/reducer'
import { PRODUCTS_SEARCH_REDUCER_NAME } from 'containers/components/Search/consts'
import { rtkApi } from 'services/api/rtkApi'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

const LOGOUT_WHITELIST = ['form', 'language', APP_REDUCER_NAME]

const logoutMiddleware = rootReducer => (state, action) => {
  if (action.type === LOGOUT_ACTION_TYPE) {
    return rootReducer(
      Object.fromEntries(
        Object.entries(state).filter(([key]) => LOGOUT_WHITELIST.includes(key)),
      ),
      action,
    )
  }
  return rootReducer(state, action)
}

// global reducers must be used in case we want to rehydrate its data
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    [rtkApi.reducerPath]: rtkApi.reducer,
    language: languageProviderReducer,
    form: formReducer,
    [AUTH_REDUCER_NAME]: authReducer,
    [APP_REDUCER_NAME]: appReducer,
    [USER_INFO_REDUCER_NAME]: userInfoReducer,
    [CART_INFO_REDUCER_NAME]: cartReducer,
    [PROMOS_REDUCER_NAME]: promosReducer,
    [ORDER_INFO_REDUCER_NAME]: orderReducer,
    [DELIVERY_INFO_REDUCER_NAME]: deliveryReducer,
    [CATEGORY_REDUCER_NAME]: categoryReducer,
    [CAMPAIGNS_REDUCER_NAME]: campaignsReducer,
    [TEMPLATES_INFO_REDUCER_NAME]: templatesReducer,
    [INVOICES_REDUCER_NAME]: invoicesReducer,
    [PRODUCTS_REDUCER_NAME]: productsReducer,
    [PROFILE_REDUCER_NAME]: profileReducer,
    [RESET_PASSWORD_REDUCER_NAME]: resetPasswordReducer,
    [PRODUCT_DETAILS_REDUCER_NAME]: productDetailsReducer,
    [PRODUCTS_SEARCH_REDUCER_NAME]: searchReducer,
    ...injectedReducers,
  })

  return logoutMiddleware(rootReducer)
}
