import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ICONS_POSITION, getIconSize } from './consts'
import { getSpacing } from '../helpers/theme-selectors'

const iconStyles = {
  [ICONS_POSITION.LEFT]: css`
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-start: 0;
    margin-inline-end: ${props => getSpacing(props).sm};
    margin-right: ${props => getSpacing(props).sm};
  `,
  [ICONS_POSITION.RIGHT]: css`
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-start: ${props => getSpacing(props).sm};
    margin-inline-end: 0;
    margin-left: ${props => getSpacing(props).sm};
  `,
  [ICONS_POSITION.CENTER]: css`
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin: 0 auto;
  `,
}

const getIconStyles = position =>
  iconStyles[position] || iconStyles[ICONS_POSITION.LEFT]

const IconWrapper = styled.figure`
  display: block;
  height: ${({ iconSize }) => getIconSize(iconSize)}px;
  width: ${({ iconSize }) => getIconSize(iconSize)}px;
  ${({ position }) => getIconStyles(position)};
  ${({ clickable }) => clickable && `cursor: pointer;`};
  line-height: 1;
`

IconWrapper.propTypes = {
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  position: PropTypes.string,
  clickable: PropTypes.bool,
}

export default IconWrapper
