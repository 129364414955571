import styled from 'styled-components'
import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

import { H3v2 } from 'components/Text'

export const DescriptionDropdownHeader = styled.div`
  display: flex;
  padding: ${theme.spacing.md} 0 ${theme.spacing.base} 0;
  border-top: 1px solid ${theme.colors.semiTranspBg.gray};
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
`

export const DescriptionDropdownContent = styled.div`
  padding-bottom: ${theme.spacing.md};
`

export const DropdownHeading = styled(H3v2)`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.xl};
  }
`
