import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { isAccountDeleteRequested } from 'containers/UserInfo/selectors'
import { UserDelete } from 'components/Icons'
import DeleteAccountModal from './DeleteAccountModal'

import messages from '../messages'
import { OtherRowContainer } from '../styles'
import { DeleteAccountRow } from './styles'

const DeleteAccount = () => {
  const { formatMessage } = useIntl()
  const isDeleteRequested = useSelector(isAccountDeleteRequested)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const onOpenConfirm = () => setIsConfirmOpen(true)
  const onCloseConfirm = ev => {
    ev.stopPropagation()
    setIsConfirmOpen(false)
  }

  return (
    <OtherRowContainer
      onClick={isDeleteRequested ? undefined : onOpenConfirm}
      isDisabled={isDeleteRequested}
    >
      {isConfirmOpen && <DeleteAccountModal onClose={onCloseConfirm} />}

      <DeleteAccountRow
        text={formatMessage(
          messages[
            isDeleteRequested ? 'deleteAccountPending' : 'deleteAccount'
          ],
        )}
        icon={<UserDelete />}
        isArrowHidden={isDeleteRequested}
        {...{ isDeleteRequested }}
      />
    </OtherRowContainer>
  )
}

export default DeleteAccount
