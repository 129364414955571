import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import theme, { paymentTheme } from 'theme'
import { ORDER_STATUS } from 'views/Orders/consts'
import { DATE_TIME_FORMATS } from 'utils/datetime'

import messages from '../messages'

const ORDER_MESSAGE_BY_STATUS = {
  [ORDER_STATUS.TO_BE_ACCEPTED]: {
    status: messages.orderPendingApproval,
    color: paymentTheme.yellow.text,
  },
  [ORDER_STATUS.ACCEPTED]: {
    status: messages.orderAccepted,
    color: theme.colors.primary,
  },
  [ORDER_STATUS.IN_PROGRESS]: {
    status: messages.orderInProgress,
    color: theme.colors.primary,
  },
  [ORDER_STATUS.COMPLETED]: {
    status: messages.orderCompleted,
    color: theme.colors.primary,
  },
  [ORDER_STATUS.CANCELED]: {
    status: messages.orderCanceled,
    color: theme.colors.redDarkText,
  },
}

const useOrderStatus = order => {
  const { formatMessage } = useIntl()

  return useMemo(
    () => {
      const config = ORDER_MESSAGE_BY_STATUS[(order?.orderStatusId)]
      if (!config) return {}

      const orderDatetime = dayjs(order.orderDatetime)
      let dateFormatted = orderDatetime.format(DATE_TIME_FORMATS.SHORT_DATE)

      if (orderDatetime.isToday()) {
        dateFormatted = formatMessage(messages.today)
      } else if (orderDatetime.isYesterday()) {
        dateFormatted = formatMessage(messages.yesterday)
      } else if (orderDatetime.isSame(dayjs().subtract(2, 'days'), 'date')) {
        dateFormatted = formatMessage(messages.beforeYesterday)
      }

      const status = formatMessage(config.status, { date: dateFormatted })

      return { status, color: config.color }
    },
    [order, formatMessage],
  )
}

export default useOrderStatus
