import React from 'react'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash/fp'

import { getTopHeaderOffset, scrollElementIntoView } from 'utils/dom'

import { cartDeliveriesSelector } from 'containers/Cart/selectors'

import { PPrimary } from 'components/Text'
import { makeDeliveryStickyId } from 'containers/Cart/utils'

import { Container, DateDot, Date, DateLabels, DayOfWeek } from './styles'

const DeliveryDates = () => {
  const deliveryDates = useSelector(cartDeliveriesSelector)

  return (
    <Container>
      {deliveryDates.map(
        ({ date, dateObj, routeId }) =>
          !!date && !!routeId ? (
            <Date key={`${date}-${routeId}`}>
              <DateLabels
                onClick={() => {
                  const stickyId = makeDeliveryStickyId(date, routeId)
                  scrollElementIntoView(stickyId, -getTopHeaderOffset())
                }}
              >
                <PPrimary bold>{dateObj.format('D')}</PPrimary>
                <DateDot />
                <DayOfWeek>{capitalize(dateObj.format('ddd'))}</DayOfWeek>
              </DateLabels>
            </Date>
          ) : null,
      )}
      <Date isLast />
    </Container>
  )
}

export default DeliveryDates
