import { fromJS } from 'immutable/dist/immutable'
import * as actionTypes from '../actions/modal'

export const initialState = fromJS({
  isOpened: false,
  stack: [],
})

export default (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case actionTypes.MODAL_OPEN: {
      const newStack = state
        .get('stack')
        .toJS()
        .filter(item => item.type !== payload.type)

      return state.set('isOpened', true).set(
        'stack',
        fromJS([
          ...newStack,
          {
            type: payload.type,
            data: payload.data || {},
            resolve: payload.resolve || function resolve() {},
          },
        ]),
      )
    }
    case actionTypes.MODAL_CLOSE:
      if (payload.type) {
        const newStack = state
          .get('stack')
          .toJS()
          .filter(item => item.type !== payload.type)

        return state
          .set('isOpened', !!newStack.length)
          .set('stack', fromJS(newStack))
      }
      if (state.stack.length) {
        const newStack = state
          .get('stack')
          .toJS()
          .slice(0, -1)

        return state
          .set('isOpened', !!newStack.length)
          .set('stack', fromJS(newStack))
      }
      return initialState
    case actionTypes.MODAL_CLOSE_ALL:
      return initialState
    default:
      return state
  }
}
