import dayjs from 'dayjs'
import { capitalize, ceil, range } from 'lodash/fp'

export const getRange = ({ startDate, endDate, type }) => {
  // {string} type - The range type. eg: 'days', 'hours'
  const fromDate = dayjs.utc(startDate).startOf('isoWeek')
  const toDate = dayjs(endDate).endOf('isoWeek')
  const diff = toDate.diff(fromDate, type)

  return range(0, diff + 1).map(i => {
    const dayObj = fromDate.clone().add(i, 'day')
    return {
      dayOfMonth: dayObj.date(),
      dayHumanized: capitalize(dayObj.format('ddd')),
      dayObj,
    }
  })
}

export const getPageFromIndex = ({ index, daysPerPage }) =>
  ceil((index + 1) / daysPerPage)
