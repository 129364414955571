import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { MOBILE_PAGINATION_BREAKPOINT } from 'consts'

import { breakPointLayoutS } from '../styledComponents'

export const FiltersContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: ${theme.spacing.xs} ${theme.spacing.md};
`

export const FiltersWrapper = styled.div`
  width: calc(100% + 64px);
  margin-left: -32px;
  white-space: nowrap;
  border-top: 1px solid ${theme.colors.lightGrey2};
  border-bottom: 1px solid ${theme.colors.lightGrey2};
  overflow: auto;
  background: ${theme.colors.white};
  @media (max-width: ${breakPointLayoutS}) {
    width: calc(100% + 48px);
    margin-left: -24px;
  }
`

export const FiltersPaginationWrapper = styled(Flex)`
  @media screen and (max-width: ${MOBILE_PAGINATION_BREAKPOINT}px) {
    display: none;
  }
`
