import React from 'react'

import { Svg } from './styles'

const Logo = props => (
  <Svg id="Warstwa_1" dataName="Warstwa 1" viewBox="0 0 380 60" {...props}>
    <g>
      <path
        d="M73.62,20.69a1.59,1.59,0,0,1,.13-.13,19.63,19.63,0,0,1,26.53,0A16.5,16.5,0,0,1,105.77,33a16.5,16.5,0,0,1-5.49,12.47,19.6,19.6,0,0,1-26.53,0A16.5,16.5,0,0,1,68.26,33V2.74A2.34,2.34,0,0,1,69.05,1,2.66,2.66,0,0,1,70.94.22,2.69,2.69,0,0,1,72.83,1a2.38,2.38,0,0,1,.79,1.78ZM87,45.63a13.32,13.32,0,0,0,9.48-3.69,12.09,12.09,0,0,0,0-17.82,14,14,0,0,0-18.95,0,12.09,12.09,0,0,0,0,17.82A13.31,13.31,0,0,0,87,45.63Z"
        fill="#0d124a"
      />
      <path
        d="M118.67,7.84a2.94,2.94,0,0,1-1,2.22,3.3,3.3,0,0,1-2.36.93,3.33,3.33,0,0,1-2.36-.93,3,3,0,0,1,0-4.44,3.33,3.33,0,0,1,2.36-.93,3.3,3.3,0,0,1,2.36.93A2.94,2.94,0,0,1,118.67,7.84Zm-6,10.08a2.33,2.33,0,0,1,.79-1.78,2.65,2.65,0,0,1,1.89-.74,2.62,2.62,0,0,1,1.89.74,2.33,2.33,0,0,1,.79,1.78V48.15a2.32,2.32,0,0,1-.79,1.77,2.63,2.63,0,0,1-1.89.75,2.66,2.66,0,0,1-1.89-.75,2.32,2.32,0,0,1-.79-1.77Z"
        fill="#0d124a"
      />
      <path
        d="M325.41,47.52a2.94,2.94,0,0,1-1,2.22,3.46,3.46,0,0,1-4.72,0,3,3,0,0,1,0-4.44,3.46,3.46,0,0,1,4.72,0A2.94,2.94,0,0,1,325.41,47.52Z"
        fill="#229944"
      />
      <path
        d="M157,2.74A2.37,2.37,0,0,1,157.81,1a2.8,2.8,0,0,1,3.79,0,2.38,2.38,0,0,1,.79,1.78V33a16.47,16.47,0,0,1-5.5,12.47,19.59,19.59,0,0,1-26.52,0A16.51,16.51,0,0,1,124.87,33a16.51,16.51,0,0,1,5.5-12.47,19.62,19.62,0,0,1,26.52,0l.14.13ZM157,33a11.81,11.81,0,0,0-3.92-8.91,14.05,14.05,0,0,0-19,0,12.09,12.09,0,0,0,0,17.82,14,14,0,0,0,19,0A11.79,11.79,0,0,0,157,33Z"
        fill="#0d124a"
      />
      <path
        d="M188,.28a2.69,2.69,0,0,1,1.89.74,2.38,2.38,0,0,1,.78,1.78,2.37,2.37,0,0,1-.78,1.78,2.69,2.69,0,0,1-1.89.74q-10.72,0-10.72,10.08v1.89h8.07a2.63,2.63,0,0,1,1.89.74,2.33,2.33,0,0,1,.79,1.78,2.36,2.36,0,0,1-.79,1.78,2.68,2.68,0,0,1-1.89.74H177.3V48.15a2.35,2.35,0,0,1-.79,1.77,2.76,2.76,0,0,1-3.78,0,2.32,2.32,0,0,1-.79-1.77V22.33h-2.68a2.66,2.66,0,0,1-1.89-.74,2.36,2.36,0,0,1-.79-1.78,2.33,2.33,0,0,1,.79-1.78,2.62,2.62,0,0,1,1.89-.74h2.68V15.4Q171.94.28,188,.28Z"
        fill="#229944"
      />
      <path
        d="M229.72,33a16.5,16.5,0,0,1-5.49,12.47,19.6,19.6,0,0,1-26.53,0A16.5,16.5,0,0,1,192.21,33a16.5,16.5,0,0,1,5.49-12.47,19.63,19.63,0,0,1,26.53,0A16.5,16.5,0,0,1,229.72,33ZM211,20.44a13.3,13.3,0,0,0-9.47,3.68,12.07,12.07,0,0,0,0,17.82,14,14,0,0,0,18.95,0,12.09,12.09,0,0,0,0-17.82A13.31,13.31,0,0,0,211,20.44Z"
        fill="#229944"
      />
      <path
        d="M271.42,33a16.5,16.5,0,0,1-5.49,12.47,19.59,19.59,0,0,1-26.52,0A16.47,16.47,0,0,1,233.91,33a16.47,16.47,0,0,1,5.5-12.47,19.62,19.62,0,0,1,26.52,0A16.5,16.5,0,0,1,271.42,33ZM252.67,20.44a13.31,13.31,0,0,0-9.48,3.68,12.09,12.09,0,0,0,0,17.82,14,14,0,0,0,19,0,12.09,12.09,0,0,0,0-17.82A13.35,13.35,0,0,0,252.67,20.44Z"
        fill="#229944"
      />
      <path
        d="M307.77,2.74A2.34,2.34,0,0,1,308.56,1a2.78,2.78,0,0,1,3.78,0,2.34,2.34,0,0,1,.79,1.78V33a16.5,16.5,0,0,1-5.49,12.47,19.6,19.6,0,0,1-26.53,0A16.5,16.5,0,0,1,275.62,33a16.5,16.5,0,0,1,5.49-12.47,19.63,19.63,0,0,1,26.53,0l.13.13Zm0,30.29a11.81,11.81,0,0,0-3.92-8.91,14.05,14.05,0,0,0-19,0,12.11,12.11,0,0,0,0,17.82,14,14,0,0,0,19,0A11.79,11.79,0,0,0,307.77,33Z"
        fill="#229944"
      />
      <path
        d="M338.3,63.26a2.38,2.38,0,0,1-.79,1.78,2.8,2.8,0,0,1-3.79,0,2.37,2.37,0,0,1-.78-1.78V33a16.5,16.5,0,0,1,5.49-12.47,19.62,19.62,0,0,1,26.52,0A16.47,16.47,0,0,1,370.45,33,16.47,16.47,0,0,1,365,45.5a19.59,19.59,0,0,1-26.52,0,.8.8,0,0,1-.13-.13Zm0-30.23a11.78,11.78,0,0,0,3.91,8.91,14,14,0,0,0,19,0,12.09,12.09,0,0,0,0-17.82,14.05,14.05,0,0,0-19,0A11.8,11.8,0,0,0,338.3,33Z"
        fill="#229944"
      />
      <path
        d="M380,48.15V2.8A2.36,2.36,0,0,0,379.21,1a2.78,2.78,0,0,0-3.78,0,2.36,2.36,0,0,0-.79,1.78V48.15a2.32,2.32,0,0,0,.79,1.77,2.76,2.76,0,0,0,3.78,0A2.32,2.32,0,0,0,380,48.15Z"
        fill="#229944"
      />
      <g>
        <path
          d="M59.47,16.62a2.47,2.47,0,0,0-2-1.05H49.67A14.61,14.61,0,1,0,26.05,29.42a14.44,14.44,0,0,0,9.21,3.3c.5,0,1,0,1.5-.07a14.7,14.7,0,0,0,9-4.3,2.5,2.5,0,0,0-3.57-3.51,9.61,9.61,0,0,1-12.94.71,9.54,9.54,0,0,1-3.19-5H53.93l-5,14.7H21L11.79,2.66A2.51,2.51,0,0,0,9.38.84H2.5a2.51,2.51,0,0,0,0,5h5l9.25,32.62a2.52,2.52,0,0,0,2.41,1.82H50.68a2.4,2.4,0,0,0,.46,0l.13,0,.3-.09.14-.06.3-.16.09,0,.34-.28a.2.2,0,0,1,.06-.07l.22-.27.08-.13a2,2,0,0,0,.14-.25c0-.06.05-.11.07-.17s0,0,0-.08l6.76-19.71A2.49,2.49,0,0,0,59.47,16.62ZM34.33,8.53a9.6,9.6,0,0,1,10.23,7.05H26a9.62,9.62,0,0,1,8.31-7Z"
          fill="#0d124a"
        />
        <path
          d="M44.91,44.37a3.34,3.34,0,0,0-2.37.93,3,3,0,0,0,0,4.44,3.47,3.47,0,0,0,4.73,0,3,3,0,0,0,0-4.44A3.33,3.33,0,0,0,44.91,44.37Z"
          fill="#0d124a"
        />
        <path
          d="M25.11,44.37a3.32,3.32,0,0,0-2.36.93,3,3,0,0,0,0,4.44,3.46,3.46,0,0,0,4.72,0,3,3,0,0,0,0-4.44A3.32,3.32,0,0,0,25.11,44.37Z"
          fill="#0d124a"
        />
      </g>
    </g>
  </Svg>
)

export default Logo
