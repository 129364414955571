import styled from 'styled-components'

import theme from 'theme'
import { BasicButtonV2, BasicButtonV2Grey } from 'components/Button/styles'

export const BUTTON_VARIANT = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  LIGHT_RED: 'LIGHT_RED',
}

export const StyledButton = styled(BasicButtonV2)`
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  height: 40px;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 400;

  ${p => `
      background-color: ${p.$variantStyle.bg};
      color: ${p.$variantStyle.text};

      :hover {
        background-color: ${p.$variantStyle.bgHover};
      }
    `};
`

export const HistoryButton = styled(BasicButtonV2Grey)`
  height: 40px;
  margin-left: auto;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 400;
`
