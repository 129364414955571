import { createSelector } from 'reselect'
import { prop } from 'lodash/fp'

import {
  APP_REDUCER_NAME,
  APP_NOTIFICATIONS_REDUCER_NAME,
  APP_MODALS_REDUCER_NAME,
  APP_TOOLTIP_REDUCER_NAME,
  APP_CONFIG_REDUCER_NAME,
  APP_DASHBOARD_TILES_REDUCER_NAME,
} from './reducer/consts'

const selectApp = state => state[APP_REDUCER_NAME]

export const notificationSelector = createSelector(selectApp, state =>
  state.get(APP_NOTIFICATIONS_REDUCER_NAME),
)

export const notificationMessageSelector = createSelector(
  notificationSelector,
  state => state.get('message'),
)

export const notificationDisplaySelector = createSelector(
  notificationSelector,
  state => state.get('isOpen'),
)

export const notificationIsErrorSelector = createSelector(
  notificationSelector,
  state => state.get('isError'),
)

export const notificationTypeSelector = createSelector(
  notificationSelector,
  state => state.get('type'),
)

export const modalSelector = createSelector(selectApp, state =>
  state.get(APP_MODALS_REDUCER_NAME),
)

export const tooltipSelector = createSelector(selectApp, state =>
  state.get(APP_TOOLTIP_REDUCER_NAME),
)

export const dashboardTilesSelector = createSelector(selectApp, state =>
  state.get(APP_DASHBOARD_TILES_REDUCER_NAME),
)

export const dashboardTilesDisplaySelector = createSelector(
  dashboardTilesSelector,
  state => state.get('isOpen'),
)

export const modalStackSelector = createSelector(modalSelector, state =>
  state.get('stack').toJS(),
)

export const modalIsOpenByTypeSelector = type =>
  createSelector(modalStackSelector, stack =>
    stack.some(item => item.type === type),
  )

export const modalDisplaySelector = createSelector(modalSelector, state =>
  state.get('isOpened'),
)

const appConfigSelector = createSelector(selectApp, state =>
  state.get(APP_CONFIG_REDUCER_NAME),
)

export const apiVersionSelector = createSelector(
  appConfigSelector,
  prop('apiVersion'),
)

export const isTooltipOpenSelector = productId =>
  createSelector(
    tooltipSelector,
    state => state.get('isOpen') && productId === state.get('id'),
  )
