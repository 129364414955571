import React from 'react'
import theme from 'theme'

const FloatingPriceXSmall = ({
  color = theme.colors.white,
  bgColor = theme.colors.infoBlue2,
}) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="11" height="12" rx="2" fill={bgColor} />
    <rect x="5" y="2" width="1" height="5" fill={color} />
    <rect
      x="8"
      y="4"
      width="1"
      height="5"
      transform="rotate(90 8 4)"
      fill={color}
    />
    <rect
      x="8"
      y="8"
      width="1"
      height="5"
      transform="rotate(90 8 8)"
      fill={color}
    />
  </svg>
)

export default FloatingPriceXSmall
