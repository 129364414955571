import React from 'react'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'

import theme from 'theme'
import {
  INVOICES_DESKTOP_START,
  INVOICE_FILE_STATUS,
} from 'views/Invoices/consts'
import { DATE_TIME_FORMATS } from 'utils/datetime'
import { formatPrice } from 'utils'
import {
  INVOICE_PAYMENT_STATUS,
  INVOICE_STATUS_COLOR,
  API_INVOICE_STATUS_COLOR,
} from 'containers/Invoices/consts'
import {
  Globe as PaidOnlineIcon,
  CalendarV2 as CalendarIcon,
} from 'components/Icons'
import TextTooltip from 'components/Tooltip/TextTooltip'

import Checkbox from 'components/Input/Checkbox'
import { CheckboxWrapperV2 } from 'components/Input/styles'
import InvoiceMenu from './InvoiceMenu'
import messages from '../../../messages'
import { usePayLabel } from '../../../hooks'

import {
  Container,
  DesktopHint,
  MobileHint,
  CheckboxContainer,
  AmountColumn,
  RemainingAmountColumn,
  MobileRemainingAmountRow,
  InvoiceInfoContainer,
  DesktopPriceHint,
  MobilePriceHint,
  PricesContainer,
  Heading,
  HeaderContainer,
  InvoicePriceContainer,
  InvoiceInfoOuterContainer,
  InvoiceInfoMiddleContainer,
  PaymentNumberText,
  InvoiceDateContainer,
  InlineSeparator,
  PaymentDetailsContainer,
  PaymentDetailsInnerContainer,
} from './styles'

const InvoiceListItem = ({
  invoice: {
    id,
    amount,
    number,
    postingDate,
    typeName,
    remainingAmount,
    colorId,
    payment: { labelColorId, status, number: paymentNumber },
    paidOnline,
    fileStatus,
  },
  invoice,
  isSelectable,
  isSelected,
  onToggleSelect,
  checkBoxSpacing,
}) => {
  const { formatMessage } = useIntl()
  const isDesktop = useMediaQuery({
    query: `(min-width: ${INVOICES_DESKTOP_START})`,
  })

  const isProcessing = status === INVOICE_PAYMENT_STATUS.PROCESSING
  const showRemainingAmount =
    remainingAmount !== 0 && remainingAmount !== amount
  const [payLabel, payRemainingLabel] = usePayLabel(invoice)
  const payLabelColor = showRemainingAmount
    ? INVOICE_STATUS_COLOR[5]
    : INVOICE_STATUS_COLOR[labelColorId]

  const Hint = isDesktop ? DesktopHint : MobileHint
  const PriceHint = isDesktop ? DesktopPriceHint : MobilePriceHint
  const isAlignedTop = showRemainingAmount && !isDesktop

  const isFileAvailable = fileStatus === INVOICE_FILE_STATUS.AVAILABLE
  const isFileProcessing = fileStatus === INVOICE_FILE_STATUS.PROCESSING

  const remainingAmountNode = (
    <>
      <Heading $isDisabled={isProcessing} style={{ whiteSpace: 'nowrap' }}>
        {colorId === API_INVOICE_STATUS_COLOR.RED ? '- ' : ''}
        {colorId === API_INVOICE_STATUS_COLOR.GREEN ? '+ ' : ''}
        {formatPrice(remainingAmount)}
      </Heading>

      <PriceHint $color={INVOICE_STATUS_COLOR[labelColorId]}>
        {payRemainingLabel}
      </PriceHint>
    </>
  )

  const hasAnyOnlinePaymentDetail = paidOnline || paymentNumber
  const onlinePaymentDetails = hasAnyOnlinePaymentDetail ? (
    <TextTooltip
      content={
        paymentNumber
          ? formatMessage(messages.paymentNumber)
          : formatMessage(messages.paidOnline)
      }
    >
      <PaymentDetailsInnerContainer>
        {paidOnline && <PaidOnlineIcon testId="paid-online-icon" />}
        {paymentNumber && (
          <PaymentNumberText>{paymentNumber}</PaymentNumberText>
        )}
      </PaymentDetailsInnerContainer>
    </TextTooltip>
  ) : null

  return (
    <Container
      $isSelectable={isSelectable && !isProcessing}
      $isSelected={isSelected}
      onClick={() => {
        if (isSelectable && !isProcessing) {
          onToggleSelect()
        }
      }}
      data-test-id={`invoice#${number}`}
    >
      <InvoiceInfoOuterContainer $isAlignedTop={isAlignedTop}>
        {(checkBoxSpacing || isSelectable) && (
          <CheckboxContainer>
            {isSelectable && (
              <Checkbox
                onClick={ev => {
                  ev.stopPropagation()
                }}
                Wrapper={CheckboxWrapperV2}
                checked={isSelected}
                disabled={isProcessing}
                readOnly
              />
            )}
          </CheckboxContainer>
        )}
        <InvoiceInfoContainer>
          <HeaderContainer>
            <Heading $isDisabled={isProcessing}>{number}</Heading>
          </HeaderContainer>

          <Hint $isDisabled={isProcessing}>
            {typeName}
            <InlineSeparator>•</InlineSeparator>
            <TextTooltip content={formatMessage(messages.invoiceDate)}>
              <InvoiceDateContainer>
                <CalendarIcon />
                {dayjs(postingDate).format(DATE_TIME_FORMATS.FULL_DATE)}
              </InvoiceDateContainer>
            </TextTooltip>
            {isFileProcessing && (
              <>
                <InlineSeparator>•</InlineSeparator>
                {formatMessage(messages.processingFile)}
              </>
            )}
          </Hint>
          <PaymentDetailsContainer data-test-id="payment-number-mobile-container">
            {onlinePaymentDetails}
          </PaymentDetailsContainer>
        </InvoiceInfoContainer>
      </InvoiceInfoOuterContainer>
      <InvoiceInfoMiddleContainer data-test-id="invoice-info-middle-container">
        {onlinePaymentDetails}
      </InvoiceInfoMiddleContainer>
      <InvoicePriceContainer>
        <PricesContainer>
          {showRemainingAmount && (
            <RemainingAmountColumn data-test-id="desktop-remaining-amount">
              {remainingAmountNode}
            </RemainingAmountColumn>
          )}
          <AmountColumn limitWidth={showRemainingAmount}>
            <Heading
              $isDisabled={isProcessing}
              style={{ whiteSpace: 'nowrap' }}
            >
              {colorId === API_INVOICE_STATUS_COLOR.GREEN &&
              remainingAmount === amount
                ? '+ '
                : ''}
              {formatPrice(amount)}
            </Heading>

            <PriceHint
              $color={isProcessing ? theme.colors.infoBlue2 : payLabelColor}
            >
              {payLabel}
            </PriceHint>
          </AmountColumn>
          {showRemainingAmount && (
            <MobileRemainingAmountRow>
              {remainingAmountNode}
            </MobileRemainingAmountRow>
          )}
        </PricesContainer>
        <InvoiceMenu
          isFileAvailable={isFileAvailable}
          invoiceId={id}
          isDesktop={isDesktop}
          isAlignedTop={isAlignedTop}
        />
      </InvoicePriceContainer>
    </Container>
  )
}

export default InvoiceListItem
