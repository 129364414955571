import React from 'react'
import { FormattedMessage } from 'react-intl'
import { noop } from 'lodash/fp'

import { paymentTheme } from 'theme'
import { ProceedArrow, Card, Blocked } from 'components/Icons'

import messages from 'views/Cart/messages'

export const getColors = ({
  isYellowScenario,
  isGreenScenario,
  disabled,
  submitBg,
  submitBgHover,
  txtColor,
}) => {
  if (isYellowScenario && disabled) {
    return {
      bg: paymentTheme.yellow.bgDisabled,
      textColor: paymentTheme.yellow.textDisabled,
    }
  }

  if (isGreenScenario && disabled) {
    return {
      bg: paymentTheme.green.bgDisabled,
      textColor: paymentTheme.green.textDisabled,
    }
  }

  return { bg: submitBg, bgHover: submitBgHover, textColor: txtColor }
}

export const getIcon = ({
  textColor,
  disabled,
  isGreenScenario,
  isRedScenario,
  isRedClient,
  hideDues,
}) => {
  if (isRedScenario) {
    const Icon = isRedClient && !hideDues ? Card : Blocked
    return <Icon color={textColor} />
  }

  if (isGreenScenario && disabled) {
    return <ProceedArrow color={textColor} />
  }

  return <ProceedArrow color={textColor} />
}

export const getTitle = ({
  isRedClient,
  isYellowScenario,
  isRedScenario,
  hideDues,
}) => {
  if (isRedClient) {
    if (hideDues) {
      return <FormattedMessage {...messages.hideDuesCtaBtn} />
    }

    return <FormattedMessage {...messages.payOverdueBills} />
  }

  if (isRedScenario) {
    return <FormattedMessage {...messages.contactCS} />
  }

  if (isYellowScenario) {
    return <FormattedMessage {...messages.sendForApproval} />
  }

  return <FormattedMessage {...messages.orderCta} />
}

export const getClickHandler = ({
  disabledBtn,
  isRedClient,
  onPayBills,
  onPayOrder,
}) => {
  if (isRedClient) {
    return onPayBills
  }

  if (disabledBtn) {
    return noop
  }

  return onPayOrder
}
