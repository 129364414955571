import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { withIcon, ICONS, ICONS_POSITION } from 'components/Icon'
import { AlternateBtn, BUTTON_SIZE } from 'components/Button'
import { PopoverClose } from 'components/Popup'
import { H4 } from 'components/Text'
import { getColors } from 'components/helpers/theme-selectors'

const CloseBtn = props =>
  withIcon({
    ...props,
    iconType: ICONS.X,
    iconPosition: ICONS_POSITION.CENTER,
    size: BUTTON_SIZE.ICON,
  })(styled(AlternateBtn)`
    padding: 0;
    border: none;
    :hover {
      border: none;
    }
  `)

const ModalHeading = styled(H4)`
  margin: 0;
  color: ${props => getColors(props).black};
`
// ToDo fix styled component
const ModalHeaderWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  font-family: inherit;
  font-weight: 600;
  font-size: 22px;
  color: #131b4d;
  margin-bottom: 16px;
`

const ModalClose = ({ heading, onClick, dataTestId, className, style }) => (
  <ModalHeaderWrapper {...{ className, style }}>
    <ModalHeading>{heading}</ModalHeading>
    <PopoverClose>
      <CloseBtn onClick={onClick} data-test-id={dataTestId} />
    </PopoverClose>
  </ModalHeaderWrapper>
)

ModalClose.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
}

export default ModalClose
