import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import qs from 'qs'

import { ROUTES } from 'consts'
import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/Filters'
import { INVOICES_ROUTES } from 'views/Invoices/consts'
import { PAY_STATUS } from 'containers/Invoices/PayProcessing/consts'
import { useRichFormatMessage, formatPrice } from 'utils'
import { payAmountSelector } from 'containers/Invoices/PayProcessing'
import StatusScreen from 'components/StatusScreen'
import ProcessingIcon from './assets/processing-icon.svg'
import ConfirmedIcon from './assets/confirmed-icon.svg'
import RejectedIcon from './assets/rejected-icon.svg'

import messages from '../messages'

const PayStatus = ({ variant }) => {
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()
  const navigate = useNavigate()

  const payAmount = useSelector(payAmountSelector)

  const statusConfigs = useMemo(
    () => ({
      [PAY_STATUS.PROCESSING]: {
        iconImg: ProcessingIcon,
        headerText: formatMessage(messages.processingHeader),
        descText: formatMessage(messages.processingDesc),
        secondaryBtnRoute: INVOICES_ROUTES.ROOT,
      },
      [PAY_STATUS.CONFIRMED]: {
        iconImg: ConfirmedIcon,
        headerText: formatMessage(messages.confirmedHeader),
        descText: formatRichMessage(messages.confirmedDesc, {
          price: formatPrice(payAmount),
        }),
        secondaryBtnRoute: `${INVOICES_ROUTES.ROOT}?${qs.stringify({
          type: INVOICE_TYPE_IDS.PAID,
        })}`,
      },
      [PAY_STATUS.REJECTED]: {
        iconImg: RejectedIcon,
        headerText: formatMessage(messages.rejectedHeader),
        descText: formatMessage(messages.rejectedDesc),
        secondaryBtnRoute: INVOICES_ROUTES.ROOT,
      },
    }),
    [payAmount],
  )

  const config = statusConfigs[variant]

  if (!config) return null

  return (
    <StatusScreen
      iconImg={config.iconImg}
      headerText={config.headerText}
      descText={config.descText}
      primaryBtnConfig={{
        text: formatMessage(messages.backToDashboard),
        handleClick: () => navigate(ROUTES.DASHBOARD),
      }}
      secondaryBtnConfig={{
        text: formatMessage(messages.backToPayments),
        handleClick: () => navigate(config.secondaryBtnRoute),
      }}
    />
  )
}

export default PayStatus
