import { map } from 'lodash/fp'

export const initPay = invoices => {
  const url = 'me/payments'
  const options = {
    method: 'POST',
    body: {
      invoices: map(({ id, number, remainingAmount }) => ({
        id,
        documentNumber: number,
        amount: remainingAmount,
      }))(invoices),
    },
  }
  return { url, options }
}

export const settleInvoices = ({ invoicesToPay, overpaymentInvoices }) => {
  const url = 'me/invoices_settlements'
  const options = {
    method: 'POST',
    body: {
      overpayments: map(({ id, number }) => ({
        id,
        number,
      }))(overpaymentInvoices),
      invoices_for_settlement: map(({ id, number }) => ({
        id,
        number,
      }))(invoicesToPay),
    },
  }
  return { url, options }
}
