import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { handleActions } from 'redux-actions'

import { createReducer, initialState } from 'utils/simpleDataReducer'

import { AVAILABLE_FILTERS_REDUCER_NAME } from 'components/Filters/consts'
import { subscribeToProductActions } from 'containers/ProductDetails/actions'
import { updateCartItem, deleteCartItem } from '../Cart/actions'

import { availableFiltersReducer } from './Filters/reducer'
import { REPLACEMENTS_REDUCER_NAME } from './Replacements/consts'
import { replacementsReducer } from './Replacements/reducer'
import actionsGroup from './actions'
import { PRODUCT_ITEMS_REDUCER_NAME } from './consts'

export const resetProductUnitsOfMeasureInCart = (
  unitsOfMeasure,
  unitOfMeasureStr,
) =>
  unitsOfMeasure.map(unitOfMeasure => {
    if (unitOfMeasureStr) {
      return unitOfMeasure.get('unitOfMeasure') === unitOfMeasureStr
        ? unitOfMeasure.set('inCartQuantity', 0)
        : unitOfMeasure
    }
    return unitOfMeasure.set('inCartQuantity', 0)
  })

const productsItemsReducer = createReducer(actionsGroup)
export const productsReducer = handleActions(
  {
    [subscribeToProductActions.SUCCESS]: (
      state,
      { data: { email }, additionalData: { id: productId } },
    ) => {
      const products = state.getIn(['data', 'products'])
      if (products) {
        const updatedProducts = products.map(product => {
          if (product.get('id') === productId) {
            return product.set('notificationEmail', email)
          }

          return product
        })

        return state.setIn(['data', 'products'], updatedProducts)
      }

      return state
    },
    [updateCartItem.SUCCESS]: (state, { data: { cartItem } }) => {
      const products = state.getIn(['data', 'products'])
      if (products && cartItem) {
        const updatedProducts = products.map(product => {
          if (product.get('id') === cartItem.product.id) {
            return fromJS(cartItem.product)
          }
          return product
        })

        return state.setIn(['data', 'products'], updatedProducts)
      }

      return state
    },
    [deleteCartItem.SUCCESS]: (
      state,
      { data: { cartItem }, additionalData: { productId } },
    ) => {
      const products = state.getIn(['data', 'products'])
      if (products && !cartItem) {
        return state.setIn(
          ['data', 'products'],
          products.map(product => {
            if (product.get('id') === productId) {
              return product.set(
                'unitsOfMeasure',
                resetProductUnitsOfMeasureInCart(product.get('unitsOfMeasure')),
              )
            }
            return product
          }),
        )
      }

      return state
    },
  },
  initialState,
)

export default combineReducers({
  [PRODUCT_ITEMS_REDUCER_NAME]: (state, action) => {
    const newState = productsItemsReducer(state, action)
    return productsReducer(newState, action)
  },
  [AVAILABLE_FILTERS_REDUCER_NAME]: availableFiltersReducer,
  [REPLACEMENTS_REDUCER_NAME]: (state, action) => {
    const newState = replacementsReducer(state, action)
    return productsReducer(newState, action)
  },
})
