import React from 'react'
import { useIntl } from 'react-intl'

import { TEXT_COLOR_BY_VARIANT, VARIANT } from 'views/Dashboard/Invoices/consts'
import OverdueTile from '../../Tile/OverdueTile'
import useOverdueInvoiceHandler from '../../Tile/OverdueTile/useOverdueInvoiceHandler'
import messages from '../../messages'
import InvoicesActions from '../../ActionBar/InvoicesActions'
import { LinesGroup, UnpaidInvoicesCount, Overpayments } from '../../Lines'

const Blocked = ({ invoicesSummary }) => {
  const { formatMessage } = useIntl()
  const handlePayInvoices = useOverdueInvoiceHandler()

  return (
    <OverdueTile
      invoicesSummary={invoicesSummary}
      subtitleColor={TEXT_COLOR_BY_VARIANT[VARIANT.RED]}
      footer={
        <InvoicesActions
          variant={VARIANT.RED}
          message={formatMessage(messages.accountBlocked)}
          onPayInvoices={handlePayInvoices}
        />
      }
    >
      <LinesGroup isFirst isLast>
        <UnpaidInvoicesCount invoicesSummary={invoicesSummary} />
        <Overpayments invoicesSummary={invoicesSummary} />
      </LinesGroup>
    </OverdueTile>
  )
}

export default Blocked
