export const USER_EVENTS = {
  RECEIVED_USER_ID: 'Received user ID',
  SIGN_UP_CLICKED: 'sign_up_clicked',
  SIGN_UP_DATA_SENT: 'sign_up_data_sent',
  COLLAPSE_STATE_CHANGED: 'collapse_expand_all_clicked',
  DASHBOARD_LAYOUT_CHANGED: 'change_layout_clicked',
}

export const trackUserIdReceived = ({
  accountId,
  customerNo,
  storeName,
  storeId,
}) => {
  window.dataLayer.push({
    event: USER_EVENTS.RECEIVED_USER_ID,
    userId: accountId,
    customer_no: customerNo,
    store_name: storeName,
    store_id: String(storeId),
  })
}

export const cleanupUserProperties = () => {
  window.dataLayer.push({
    userId: null,
    customer_no: null,
    store_name: null,
    store_id: null,
  })
}

export const trackSignUpClicked = () => {
  window.dataLayer.push({
    event: USER_EVENTS.SIGN_UP_CLICKED,
  })
}
export const trackSignUpDataSent = () => {
  window.dataLayer.push({
    event: USER_EVENTS.SIGN_UP_DATA_SENT,
  })
}

export const trackSwitchDashboardCollapseState = ({ origin, targetState }) => {
  window.dataLayer.push({
    event: USER_EVENTS.COLLAPSE_STATE_CHANGED,
    target_collapsibillity_state: targetState,
    origin,
  })
}

export const trackDashboardLayoutChange = ({ origin, targetState }) => {
  window.dataLayer.push({
    event: USER_EVENTS.DASHBOARD_LAYOUT_CHANGED,
    target_layout: targetState,
    origin,
  })
}
