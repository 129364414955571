import React from 'react'

import theme from 'theme'

const Location = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <circle cx="12" cy="9" r="2" fill={color} />
    <path
      d="M12 22L11.2191 22.6247C11.4089 22.8619 11.6962 23 12 23C12.3038 23 12.5911 22.8619 12.7809 22.6247L12 22ZM18 9C18 10.6595 17.2376 12.6252 15.9517 14.8002C14.678 16.9545 12.9667 19.1908 11.2191 21.3753L12.7809 22.6247C14.5333 20.4342 16.322 18.1035 17.6733 15.8181C19.0124 13.5533 20 11.2065 20 9H18ZM12 3C15.3137 3 18 5.68629 18 9H20C20 4.58172 16.4183 1 12 1V3ZM6 9C6 5.68629 8.68629 3 12 3V1C7.58172 1 4 4.58172 4 9H6ZM12.7809 21.3753C11.0333 19.1908 9.32204 16.9545 8.0483 14.8002C6.76235 12.6252 6 10.6595 6 9H4C4 11.2065 4.98765 13.5533 6.3267 15.8181C7.67796 18.1035 9.46671 20.4342 11.2191 22.6247L12.7809 21.3753ZM13 9C13 9.55228 12.5523 10 12 10V12C13.6569 12 15 10.6569 15 9H13ZM12 8C12.5523 8 13 8.44772 13 9H15C15 7.34315 13.6569 6 12 6V8ZM11 9C11 8.44772 11.4477 8 12 8V6C10.3431 6 9 7.34315 9 9H11ZM12 10C11.4477 10 11 9.55228 11 9H9C9 10.6569 10.3431 12 12 12V10Z"
      fill={color}
    />
  </svg>
)

export default Location
