import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { BoldInfo, TertiaryText } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

export const DeliveryWrapper = styled(Flex)`
  flex-direction: column;
  border-top: 2px solid ${theme.colors.gray2};
  margin-top: 48px;
  padding-top: ${theme.spacing.base};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    margin-top: 40px;
    padding-top: 0;
  }
`

export const InfoWrapper = styled(Flex)`
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: column;
    justify-content: initial;
    background-color: #eaedef;
    padding: 16px;
  }
`

export const Header = styled(BoldInfo)`
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    align-self: center;
    margin-top: 40px;
    font-size: 18px;
  }
`

export const MobileSeparator = styled.div`
  height: 1px;
  width: 100%;
  margin: ${theme.spacing.sm} 0;
  background-color: ${theme.colors.gray2};
`

export const OrderInfoWrapper = styled(Flex)`
  justify-content: flex-end;

  svg {
    margin-left: ${theme.spacing.sm};
    min-width: 24px;
  }

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: row-reverse;

    svg {
      margin-right: ${theme.spacing.base};
      margin-left: 0;
    }
  }
`

export const OrderInfoInnerWrapper = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    align-items: flex-start;
  }
`

export const CommentWrapper = styled(OrderInfoWrapper)`
  margin-top: ${theme.spacing.sm};
  margin-right: ${theme.spacing.sm};
  padding-top: ${theme.spacing.base};
  padding-left: 56px;
  border-top: 1px solid ${theme.colors.gray2};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    margin-top: 0;
    padding: 0;
    border: none;
  }
`

export const ProductsWrapper = styled(Flex)`
  flex-direction: column;
  padding-left: 56px;
  margin-top: ${theme.spacing.base};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    padding-left: 0;
    margin-top: 0;
    border-bottom: 1px solid ${theme.colors.gray2};
  }
`

export const SmallText = styled(TertiaryText)`
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    font-size: 12px;
  }
`
