import { takeLatest, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'

import invoicesListWatchers from './List/sagas'
import payInvoicesListWatchers from './PaySelection/sagas'
import payProcessWatchers from './PayProcessing/sagas'

import { getInvoicesSummaryActions } from './actions'
import { getInvoicesSummary } from './api'

function* getInvoicesSummaryFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getInvoicesSummaryActions,
      request: getInvoicesSummary,
    })
  } catch (error) {
    console.log('Error getting invoices summary', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export const invoicesWatchers = [
  takeLatest(getInvoicesSummaryActions.DELTA, getInvoicesSummaryFlow),

  ...invoicesListWatchers,
  ...payInvoicesListWatchers,
  ...payProcessWatchers,
]
