import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { formatDeliveryAddress } from 'utils'
import {
  getColors,
  getSpacing,
  getFontWeights,
} from 'components/helpers/theme-selectors'
import { H5, P, SecondaryText } from 'components/Text'
import { ICONS, ICONS_POSITION, SmallIcon } from 'components/Icon'

const CustomerNo = styled(H5)`
  font-weight: ${p => getFontWeights(p).strongBold};
  color: ${p => getColors(p).gray4};
  margin-bottom: ${p => getSpacing(p).xs};
`

const CustomerName = styled(P)`
  color: ${p => getColors(p).primary};
  font-weight: ${p => getFontWeights(p).bold};
`

const Address = styled(SecondaryText)`
  margin-top: ${p => getSpacing(p).xs};
  color: ${p => getColors(p).gray4};
`

// prettier-ignore
const Wrapper = styled(Flex)`
  flex-direction: column;
  padding-top: ${p => getSpacing(p).sm};
  cursor: pointer;

  ${p => !p.withoutBorder && `
    border-bottom: 1px solid rgba(221, 226, 229, 0.3);
    padding-bottom: ${getSpacing(p).sm};
  `}

  &:hover {
    ${CustomerName} {
      color: ${p => getColors(p).secondary};
    }

    svg {
      stroke: ${p => getColors(p).secondary};
    }
  }
`

const Item = ({
  withoutBorder,
  customerNo,
  customerName,
  deliveryAddress,
  deliveryPostcode,
  deliveryCity,
  ...rest
}) => (
  <Wrapper {...{ withoutBorder, ...rest }}>
    <CustomerNo>{customerNo}</CustomerNo>
    <Flex justifyContent="space-between">
      <CustomerName>{customerName}</CustomerName>
      <SmallIcon
        type={ICONS.CHEVRON_RIGHT}
        position={ICONS_POSITION.RIGHT}
        iconSvgProps={{ stroke: theme.colors.primary }}
      />
    </Flex>
    {deliveryAddress && (
      <Address>
        {formatDeliveryAddress(
          deliveryAddress,
          deliveryPostcode,
          deliveryCity,
          ', ',
        )}
      </Address>
    )}
  </Wrapper>
)

export default Item
