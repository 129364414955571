import React from 'react'
import { Flex } from '@rebass/grid'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import theme from 'theme'
import { useOfMinWidth } from 'hooks'

import { TinyText } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

import { VerticalDivider } from '../styledComponents'
import messages from '../messages'

const Wrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const CtaBtn = styled(Flex)`
  width: 138px;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: 80px;
  }
`

const MovedTxt = styled(TinyText)`
  flex: 1;
  text-align: center;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    padding: 0 ${theme.spacing.md};
  }
`

const MoveItemConfirm = ({
  moveItemTimeout,
  moveItemHandler,
  setMoveItemHandler,
  toggleDateChanging,
}) => {
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  return (
    <Wrapper>
      {isDesktop && <Flex width="160px" />}

      <MovedTxt>
        <FormattedMessage {...messages.moved} />
      </MovedTxt>

      <VerticalDivider />

      <CtaBtn
        onClick={() => {
          if (moveItemTimeout) clearTimeout(moveItemTimeout)
          setMoveItemHandler(false)
          toggleDateChanging(false)
        }}
      >
        <TinyText>
          <FormattedMessage {...messages.revert} />
        </TinyText>
      </CtaBtn>

      <VerticalDivider />

      <CtaBtn
        onClick={() => {
          if (moveItemTimeout) clearTimeout(moveItemTimeout)
          moveItemHandler()
          setMoveItemHandler(false)
        }}
      >
        <TinyText>
          <FormattedMessage {...messages.see} />
        </TinyText>
      </CtaBtn>

      {isDesktop && (
        <>
          <VerticalDivider />

          <Flex width="172px" />
        </>
      )}
    </Wrapper>
  )
}

export default MoveItemConfirm
