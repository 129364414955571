import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Flex } from '@rebass/grid'

import { useOfMaxWidth } from 'hooks'

import { APP_BREAKPOINTS } from 'consts'
import { HintTooltipContainer } from 'components/HintOverlay/styles'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  INTERACTION_KINDS,
} from 'components/Popup'
import { TinyText } from '../Text/texts'
import Modal from '../Icons/Modal'

const pulse = keyframes`
  0% 	{ transform: scale(1.0); opacity: 0.9; }
  50% { transform: scale(1.2); opacity: 0.3; }
  100%{ transform: scale(1.0); opacity: 0.9; }
`

// prettier-ignore
const HintOverlayWrapper = styled(Flex)`
  position: relative;

  ${p => p.enabled && `
    svg {
      cursor: pointer;
    }
  `};

  svg {
    position: relative;
  }
`
// prettier-ignore
export const Hint = styled(Flex)`
  ${p => p.enabled && css`
    position: absolute;
    top: -8px;
    left: -8px;
    padding: 8px;
    background-color: #7AE156;
    opacity: 0.9;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    animation: ${pulse} 1.2s cubic-bezier(0.5, 0, 0.3, 1) infinite;
  `};
`

const ChildrenWrapper = ({
  isMobile,
  withTooltip,
  tooltipText,
  clickHandler,
  children: innerChildren,
}) =>
  !isMobile && withTooltip ? (
    <Popover
      keepOpenOnFloatingHover
      closeDelay={100}
      interactionKind={INTERACTION_KINDS.HOVER}
    >
      <PopoverContent>
        <HintTooltipContainer>
          <TinyText>{tooltipText}</TinyText>

          <Modal
            style={{ marginLeft: '16px', minWidth: 16, cursor: 'pointer' }}
            onClick={clickHandler}
          />
        </HintTooltipContainer>
      </PopoverContent>
      <PopoverTrigger>{innerChildren}</PopoverTrigger>
    </Popover>
  ) : (
    innerChildren
  )

const HintComponent = ({
  children,
  withTooltip,
  tooltipText,
  clickHandler,
  ...rest
}) => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)

  return (
    <HintOverlayWrapper
      {...rest}
      className={rest.enabled ? 'cart-hint' : undefined}
    >
      <Hint enabled={rest.enabled} />

      <ChildrenWrapper
        isMobile={isMobile}
        withTooltip={withTooltip}
        tooltipText={tooltipText}
        clickHandler={clickHandler}
      >
        {children}
      </ChildrenWrapper>
    </HintOverlayWrapper>
  )
}

export default HintComponent
