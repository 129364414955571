/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  SliderControlsDot,
  SliderControlsThumbnailContainer,
  SliderControlsWrapper,
} from 'views/ProductDetails/ProductImageSection/styles'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import ProductImage, {
  PRODUCT_IMAGE_SIZES,
} from 'components/Product/ProductImage'

// Wrapper needed as filter effect will apply to border too
const SliderControlsThumbnail = ({ src, product, outOfStock, ...props }) => (
  <SliderControlsThumbnailContainer {...props}>
    <ProductImage
      outOfStock={outOfStock}
      product={product}
      imageSrc={src}
      size={PRODUCT_IMAGE_SIZES.S}
    />
  </SliderControlsThumbnailContainer>
)

const ImageSliderControls = ({
  handleSelectIndex,
  selectedPageIndex,
  product,
  isProductDisabled,
}) => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)
  const SliderControlsItem = isTablet
    ? SliderControlsThumbnail
    : SliderControlsDot

  if (!isTablet && product.images.length <= 1) {
    return null
  }

  return (
    <SliderControlsWrapper>
      {product.images.slice(0, 3).map((image, index) => (
        <SliderControlsItem
          key={index}
          src={image.urlSmall}
          onClick={() => isTablet && handleSelectIndex(index)}
          data-test-id="carousel-tracker-dot"
          $active={selectedPageIndex === index}
          product={product}
          outOfStock={isProductDisabled}
        />
      ))}
    </SliderControlsWrapper>
  )
}

export default ImageSliderControls
