import styled from 'styled-components'

import { H4 } from 'components/Text'
import { getSpacing } from 'components/helpers/theme-selectors'

export const Container = styled.div`
  min-height: 200px;
  padding: 48px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: 1px solid rgba(221, 226, 229, 0.5);
`

export const Message = styled(H4)`
  margin-bottom: ${props => getSpacing(props).md};
`
