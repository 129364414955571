import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import {
  getColors,
  getSpacing,
  getFontWeights,
} from 'components/helpers/theme-selectors'
import { PageTitle, P } from 'components/Text'
import { Content } from 'views/Products/styledComponents/Layout'
import { BasicButtonV2 } from 'components/Button'

const campaignMobileBreakpoint = '775px'

export const Header = styled(PageTitle)`
  margin-left: ${p => getSpacing(p).base};

  @media (max-width: ${campaignMobileBreakpoint}) {
    margin-left: 0;
    margin-top: ${p => getSpacing(p).base};
  }
`

export const ImgWrapper = styled(Flex)`
  width: 50%;
  align-items: flex-start;
  justify-content: center;
  padding: 0 44px;

  img {
    width: 100%;
    max-height: 600px;
    object-fit: contain;
  }

  @media (max-width: ${campaignMobileBreakpoint}) {
    padding: 0;
    width: 100%;
    align-items: center;
  }
`

// prettier-ignore
export const RightSection = styled.div`
 width: 560px;
  max-width: 50%;
  position: relative;
  display: table;
  padding: 0 44px 0 22px;

  ${p => p.withDivider && `
    :before {
      content: '';
      position: absolute;
      left: -22px;
      height: calc(100% + 4px);
      width: 1px;
      background: ${getColors(p).border};
    }
  `};

@media (max-width: ${campaignMobileBreakpoint}) {
  padding: 0;
  width: 100%;
  max-width: 100%;

  ${p => p.withDivider && `
    :before {
      display: none;
    }
  `};
  }
`

export const TopSection = styled(Flex)`
  width: 100%;
  margin-bottom: ${p => getSpacing(p).lg};
  justify-content: center;

  @media (max-width: ${campaignMobileBreakpoint}) {
    flex-direction: column;
  }
`

export const ProductsListWrapper = styled(Content)`
  margin-top: ${p => getSpacing(p).sm};
  margin-bottom: ${p => getSpacing(p).base};
`

export const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: ${p => getSpacing(p).md};
  width: 100%;

  @media (max-width: 767px) {
    ${TopSection} {
      flex-direction: column;

      ${ImgWrapper} {
        width: 100%;
        margin-bottom: ${p => getSpacing(p).md};
      }

      ${RightSection} {
        max-width: 100%;
        width: 100%;
        :before {
          display: none;
        }
      }
    }
    ${ProductsListWrapper} {
      justify-content: center;
    }
  }

  @media screen and (max-width: 650px) {
    padding: ${props => getSpacing(props).base};
  }
`

export const Button = styled(BasicButtonV2)`
  text-align: center;
  margin-top: ${theme.spacing.md};
  width: 100%;
  text-decoration: none;
`

export const Description = styled(P)`
  line-height: 24px;

  strong {
    font-weight: ${p => getFontWeights(p).strongBold};
  }

  p {
    margin-bottom: 24px;
  }
`

export const HeaderWrapper = styled(Flex)`
  margin-bottom: 64px;

  @media (max-width: ${campaignMobileBreakpoint}) {
    flex-direction: column;
    margin-bottom: 32px;
    align-items: flex-start;
  }
`
