import { takeLatest, call, put, take, fork } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'

import * as api from './api'
import { confirmUnsubscribeFromNewsletterActions } from './actions'

export function* confirmUnsubscribeFromNewsletterFlow({
  data,
  additionalData,
}) {
  try {
    yield fork(genericGetDataEnhanced, {
      actions: confirmUnsubscribeFromNewsletterActions,
      request: api.confirmNewsletterUnsubscription,
      params: data,
    })

    yield take(confirmUnsubscribeFromNewsletterActions.SUCCESS)
    yield call(additionalData.onSuccess)
  } catch (err) {
    console.error('Error confirming newsletter unsubscription', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(
    confirmUnsubscribeFromNewsletterActions.DELTA,
    confirmUnsubscribeFromNewsletterFlow,
  ),
]
