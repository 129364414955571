import * as React from 'react'

import theme from 'theme'

const BookmarkAdd = ({
  color = theme.colors.white,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M4.5 7L4.5 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.5 10H7.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11.5 6H21.5V19L16.5 15L11.5 19V6Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill={color} transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default BookmarkAdd
