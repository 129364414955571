import { makeMessages, makeTranslation } from 'utils'

const messages = {
  somethingWentWrong: 'somethingWentWrong',
  somethingWentWrongInfo: 'somethingWentWrongInfo',
  return: 'return',
  offlineHeader: 'offlineHeader',
  offlineInfo: 'offlineInfo',
  reloadPage: 'reloadPage',
}

export default makeMessages(messages, makeTranslation('modal'))
