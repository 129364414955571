import messages from './messages'

export const isInvalid = (meta = {}) =>
  !!meta.error && !meta.active && (meta.touched || !meta.pristine)

export const makeRequiredValidator = ({ errorMsg, intl = {} } = {}) => value =>
  !value && value !== 0
    ? errorMsg || intl.formatMessage(messages.blank)
    : undefined

export const makeMinLengthValidator = ({
  minLength,
  errorMsg,
  intl = {},
}) => value =>
  String(value).length >= minLength
    ? undefined
    : errorMsg || intl.formatMessage(messages.minLength, { minLength })

export const makeMaxLengthValidator = ({
  maxLength,
  errorMsg,
  intl = {},
}) => value =>
  String(value).length <= maxLength
    ? undefined
    : errorMsg || intl.formatMessage(messages.maxLength, { maxLength })

export const makeExactLengthValidator = ({
  exactLength,
  errorMsg,
  intl = {},
}) => value =>
  String(value).length === Number(exactLength)
    ? undefined
    : errorMsg || intl.formatMessage(messages.exactLength, { exactLength })

export const makeRegexValidator = ({
  regex,
  errorMsg,
  intl = {},
} = {}) => value =>
  (!value && value !== 0) || regex.test(value)
    ? undefined
    : errorMsg || intl.formatMessage(messages.regex, { regex })
