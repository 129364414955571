import { takeLatest, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'
import { getProductDetails } from 'containers/ProductDetails/api'

import { getTemplateProductDetails } from '../actions'

export function* getProductDetailsFlow({ data: body }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getTemplateProductDetails,
      request: getProductDetails,
      params: { body },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(getTemplateProductDetails.DELTA, getProductDetailsFlow),
]
