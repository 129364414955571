import styled from 'styled-components'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { breakPointTablet, ContentWrapper } from 'components/Layout'
import {
  getColors,
  getSpacing,
  getFontWeights,
  getTextSizes,
  getHeadingSize,
  getLineHeights,
} from 'components/helpers/theme-selectors'
import { H3, P, PageTitleDivider } from 'components/Text'

export const PaginationContainer = styled.div`
  padding-top: ${theme.spacing.base};
`

export const Container = styled(ContentWrapper)`
  margin-bottom: ${theme.spacing.ss};
  margin-top: ${theme.spacing.xxs};
  position: relative;
`

export const BlackText = styled(P)`
  margin-bottom: ${props => getSpacing(props).xs};
`

export const GreyText = styled(BlackText)`
  color: ${props => getColors(props).gray4};
  font-weight: ${props => getFontWeights(props).regular};
`
export const GreyLightText = styled(GreyText)`
  color: ${props => getColors(props).gray3};
  margin-bottom: 0;
  font-size: ${props => getTextSizes(props).l};
  line-height: ${props => getLineHeights(props).xl};
`

export const GreyLightTextWMB = styled(GreyLightText)`
  margin-bottom: 2px;
`

export const OrderDefaultHeader = styled(H3)`
  padding-top: 0;
  margin-top: 0;
  margin-right: ${props => getSpacing(props).xs};
  margin-bottom: ${props => getSpacing(props).xxs};
  font-weight: ${props => getFontWeights(props).strongBold};

  & span {
    font-size: inherit;
  }

  @media (max-width: 640px) {
    font-size: ${props => getHeadingSize(props).m};
  }
`

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`

export const TopSectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
    align-items: flex-start;
  }
`

export const OrderDetailsDivider = styled(PageTitleDivider)`
  margin: ${theme.spacing.sm} -16px;

  // TODO: rework when breakPointTablet replaced with proper breakpoint from theme
  @media (min-width: ${breakPointTablet}) {
    margin: ${theme.spacing.sm} -32px;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_WIDE_START}px) {
    margin-bottom: ${theme.spacing.md};
  }
`
