import * as yup from 'yup'

import validationMessages from 'utils/validators/messages'

export default yup.object().shape({
  password: yup
    .string()
    .required(validationMessages.required)
    .min(8, validationMessages.passShort),
})
