import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { FLOATING_PRICE_VARIANT } from 'components/FloatingPrice/consts'
import { FloatingPriceInfoBox } from 'components/FloatingPrice'
import messages from 'views/Orders/Details/messages'
import LogisticLimitNotMet from './LogisticLimitNotMet'

const InfoBox = ({ orderDetails, hasFloatingPrice, hasLogisticLimitInfo }) => {
  const { formatMessage } = useIntl()

  if (hasLogisticLimitInfo) {
    return <LogisticLimitNotMet orderDetails={orderDetails} />
  }

  if (hasFloatingPrice) {
    return (
      <FloatingPriceInfoBox
        infoText={formatMessage(messages.floatingPriceInfo)}
        bgColor={theme.colors.lightGrey6}
        variant={FLOATING_PRICE_VARIANT.EXTRA_SMALL}
      />
    )
  }

  return null
}

export default InfoBox
