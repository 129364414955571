import React from 'react'
import _toNumber from 'lodash/toNumber'

import theme from 'theme'

import { ProgressOuter, ProgressInner } from './styledComponents'

const ProgressBar = ({
  value,
  maxValue,
  innerColor = theme.colors.primary,
  outerColor = theme.colors.warning,
  height = 2,
  testId = 'progress-bar',
}) => (
  <ProgressOuter
    data-test-id={testId}
    style={{
      height,
      backgroundColor: outerColor,
    }}
  >
    <ProgressInner
      style={{
        width: `${(100 * _toNumber(value)) / _toNumber(maxValue)}%`,
        backgroundColor: innerColor,
      }}
    />
  </ProgressOuter>
)

export default ProgressBar
