import React from 'react'

import DashboardTile from 'components/DashboardTile'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import {
  EVENT_ORIGIN,
  trackFinanceTileCollapsed,
  trackFinanceTileExpanded,
} from 'services/analytics'
import { useSelector } from 'react-redux'
import { selectTradeLimit } from 'containers/Orders/selectors'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { PAYMENT_COLORS } from 'containers/Orders'
import { cn } from 'utils'

const Tile = ({ title, subtitle, children, footer, subtitleColor }) => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)
  const { customerNo, storeId } = useSelector(userDataSelector)
  const { paymentColorName } = useSelector(selectTradeLimit)

  const isTopTile = paymentColorName === PAYMENT_COLORS.RED

  return (
    <DashboardTile
      title={title}
      subtitle={
        <span
          data-test-id="invoices-header-subtitle"
          style={{ color: subtitleColor }}
        >
          {subtitle}
        </span>
      }
      headerTestId="invoices-header"
      footer={footer}
      containerClassName={cn('md:row-start-3', isTopTile && 'row-start-1')}
      containerTestId="invoices-tile-container"
      disableCollapse={isTablet && !isDesktop}
      onExpand={() =>
        trackFinanceTileExpanded({
          customerNo,
          storeId,
          origin: EVENT_ORIGIN.DASHBOARD,
        })
      }
      onCollapse={() => {
        trackFinanceTileCollapsed({
          customerNo,
          storeId,
          origin: EVENT_ORIGIN.DASHBOARD,
        })
      }}
    >
      {children}
    </DashboardTile>
  )
}

export default Tile
