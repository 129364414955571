import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { useRichFormatMessage } from 'utils'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { PORTAL_MODAL_VARIANTS } from '../PortalModal/consts'
import PortalModal from '../PortalModal'
import Infographic from './Infographic'

import messages from './messages'
import { ModalHeader } from '../styles'
import {
  LocationChangedModalContainer,
  InfoContainer,
  LocationChangedModalText,
  LocationChangedModalBtn,
  LocationChangedModalBtnText,
} from './styles'

const LocationChangedModal = ({ fromCustomerNo, toCustomerNo }) => {
  const modal = useModal()
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()

  const { customerName } = useSelector(userDataSelector)

  return (
    <PortalModal
      onClose={modal.remove}
      variant={PORTAL_MODAL_VARIANTS.MIDDLE}
      isOpen
    >
      <ModalHeader
        heading={formatMessage(messages.header)}
        onClick={modal.remove}
      />
      <LocationChangedModalContainer>
        <InfoContainer>
          <Infographic
            fromCustomerNo={fromCustomerNo}
            toCustomerNo={toCustomerNo}
          />
          <LocationChangedModalText data-test-id="location-changed-desc">
            {formatRichMessage(messages.desc, {
              customerNo: toCustomerNo,
              customerName,
            })}
          </LocationChangedModalText>
        </InfoContainer>
        <LocationChangedModalBtn onClick={modal.remove}>
          <LocationChangedModalBtnText>
            {formatMessage(messages.btnText)}
          </LocationChangedModalBtnText>
        </LocationChangedModalBtn>
      </LocationChangedModalContainer>
    </PortalModal>
  )
}

export default NiceModal.create(LocationChangedModal)
