import styled from 'styled-components'
import { rgba } from 'polished'
import { getColors } from 'components/helpers/theme-selectors'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { PROMO_GROUP_MOBILE_BREAKPOINT } from 'components/Product/PromoGroup/const'
import { DropdownContentMobileStyles } from 'components/DropdownBody/styles'

export const MODAL_BODY_CLASSNAME = {
  TEMPLATE: 'Template__modal',
  PROMO_GROUP: 'PromoGroup__modal',
}

const ModalWrapper = styled.div`
  display: ${p => (p.isOpened ? 'flex' : 'none')};
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${theme.zIndex.modalOverlay};
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${props => rgba(getColors(props).gray5, 0.6)};

  .${MODAL_BODY_CLASSNAME.PROMO_GROUP} {
    max-height: calc(100vh - 128px);
    overflow: hidden;
    border-radius: 16px;
    padding: 32px 32px 0 32px;
    box-shadow: 0px 16px 40px rgba(19, 32, 102, 0.15);
    @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
      max-height: 95vh;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 8px 8px 0 8px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  .${MODAL_BODY_CLASSNAME.TEMPLATE} {
    width: 663px;
    max-width: initial;
    border-radius: 16px;
    box-shadow: 0px 16px 40px rgba(19, 32, 102, 0.15);
    @media screen and (max-width: 767px) {
      margin: 0;
    }
    @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
      width: 100%;
      ${DropdownContentMobileStyles};
    }
  }
`

export default ModalWrapper
