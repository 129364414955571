import React from 'react'

const Cart = ({ style, color, width = 24, height = 24, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style, color }}
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M6 6h13.382a1 1 0 01.894 1.447l-3 6a1 1 0 01-.894.553H8.781a1 1 0 01-.97-.758L6 6z"
      stroke="currentcolor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={7.5} cy={20.5} r={1.5} fill="currentcolor" />
    <circle cx={18.5} cy={20.5} r={1.5} fill="currentcolor" />
    <path
      d="M19 18H8a2 2 0 01-2-2v0a2 2 0 012-2h3M6.5 8l-1-4H2"
      stroke="currentcolor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Cart
