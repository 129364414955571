import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  allTotal: 'allTotal',
  invoicesDue: 'invoicesDue',
  remaining: 'remaining',
  remainingHint: 'remainingHint',
  ctaBtn: 'ctaBtn',
  selected: 'selected',
  success: 'success',
}

export default makeMessages(
  messages,
  makeTranslation('invoices.settleOverpayments'),
)
