import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import ItemsTable, {
  makeItemsByDateGrouper,
  sortGroupedItems,
  isCurrentMonth,
} from 'components/ItemsTable'
import { MonthHeader, ItemsGroupList } from 'components/ItemsTable/styles'

import messages from '../../../messages'
import OrderListItem from '../OrderListItem'

const groupOrders = makeItemsByDateGrouper(order => order.orderDatetime)

const OrderList = ({
  orders,
  ordersPagination,
  onPageChange,
  onShowOrderDetails,
}) => {
  const groupedOrders = groupOrders(orders)
  const sortedGroups = sortGroupedItems(groupedOrders)

  return (
    <ItemsTable {...{ onPageChange }} pagination={ordersPagination}>
      {sortedGroups.map(group => (
        <ItemsGroupList key={group}>
          <MonthHeader>
            {isCurrentMonth(group) ? (
              <FormattedMessage {...messages.currentMonth} />
            ) : (
              group
            )}
          </MonthHeader>

          {groupedOrders[group].map(order => (
            <OrderListItem
              key={order.orderId}
              order={order}
              onShowDetails={onShowOrderDetails}
            />
          ))}
        </ItemsGroupList>
      ))}
    </ItemsTable>
  )
}

OrderList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({})),
  ordersPagination: PropTypes.shape({}),
  onPageChange: PropTypes.func,
  onShowOrderDetails: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default OrderList
