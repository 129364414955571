import React from 'react'
import theme from 'theme'

const Eco = ({ color = theme.colors.primary }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9305 1L6.93789 1.02227C6.93789 1.02227 7.34867 1.10013 7.64687 1.62813L9.31914 4.59063L8.19297 5.22813L12.0672 5.79531L13.4992 2.23047L12.4539 2.81992L11.698 1.48164C11.5486 1.21764 11.2983 1.0168 10.9305 1ZM5.45195 1.60234C4.93423 1.56357 4.33864 1.73541 3.88984 2.34531L3.83945 2.40156L3.35547 3.20547L6.4457 5.02891L7.34219 3.54648L6.54648 2.12148C6.40938 1.88988 5.96968 1.64112 5.45195 1.60234ZM4.79805 5.3875L1 5.96641L2.03945 6.56641L1.2707 7.89766C1.1189 8.16046 1.07389 8.47949 1.24609 8.80469L3.29453 12.2313C3.29453 12.2313 3.15325 11.8375 3.45625 11.3125L5.15664 8.36641L6.27812 9.01328L4.79805 5.3875ZM14.6758 5.7332L11.7027 7.45469L12.816 9.39883H14.268C14.8056 9.38743 16.0011 8.35776 15.3801 6.97656L15.3566 6.90508L14.6758 5.7332ZM10.7547 9.30625L8.35469 12.4L10.7547 15.4V14.2H12.2922C12.5958 14.2 12.8941 14.0802 13.0891 13.7688L15.0332 10.2812C15.0332 10.2812 14.7632 10.6 14.1566 10.6H10.7547V9.30625ZM5.26211 10.6L4.43945 12.0098C4.17965 12.4814 4.47621 14.0318 5.98281 14.1848L6.05547 14.2L7.15469 14.1883V10.6H5.26211Z"
      fill={color}
      className="primaryFill"
    />
  </svg>
)

export default Eco
