import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import NiceModal from '@ebay/nice-modal-react'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'
import { trackPromoGroupOpened } from 'services/analytics/promoGroup'
import { getPromoProduct } from 'containers/Promotions/Products/actions'
import PromoGroupModal from 'components/Product/PromoGroup/PromoGroupModal'

import { getPromoGroup } from './api'
import { getPromoGroupActions } from './actions'
import { getPromoGroupProducts } from '../GroupProducts/api'
import { getPromoGroupProductsActions } from '../GroupProducts/actions'
import { selectPromoGroupPrizesIds } from '../GroupProducts/selectors'

export function* getPromoGroupsFlow({ data: { groupId } }) {
  try {
    const [promoGroupData] = yield all([
      call(
        genericGetDataEnhanced,
        {
          actions: {
            request: () => getPromoGroupActions.request({ groupId }),
            failure: err =>
              getPromoGroupActions.failure({ groupId, error: err }),
          },
          request: getPromoGroup,
          params: { groupId },
        },
        { rethrow404: true },
      ),
      call(genericGetDataEnhanced, {
        actions: getPromoGroupProductsActions,
        request: getPromoGroupProducts,
        params: { groupId },
        dataSelector: ({ products, meta }) => ({
          pagination: meta.pagination,
          products,
        }),
      }),
    ])

    yield put(getPromoGroupActions.success({ ...promoGroupData }))

    const promoGroupPrizesIds = yield select(selectPromoGroupPrizesIds)
    yield all(
      promoGroupPrizesIds?.map(productId =>
        put(getPromoProduct.delta({ productId })),
      ),
    )

    NiceModal.show(PromoGroupModal, { groupId })

    trackPromoGroupOpened(groupId)
  } catch (error) {
    console.log('Error getting/processing promo group', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [takeLatest(getPromoGroupActions.DELTA, getPromoGroupsFlow)]
