import React from 'react'

const AppStore = () => (
  <svg
    width="25"
    height="32"
    viewBox="0 0 25 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7115_11165)">
      <path
        d="M20.316 16.7869C20.3027 14.5225 21.3287 12.8158 23.4006 11.5574C22.2418 9.89727 20.4887 8.98418 18.1777 8.8082C15.9896 8.63555 13.5957 10.0832 12.7191 10.0832C11.7928 10.0832 9.67441 8.86797 8.00762 8.86797C4.56777 8.92109 0.912109 11.6105 0.912109 17.0824C0.912109 18.6994 1.20762 20.3695 1.79863 22.0895C2.58887 24.3539 5.43769 29.9021 8.40937 29.8125C9.96328 29.776 11.0623 28.7102 13.0844 28.7102C15.0467 28.7102 16.0627 29.8125 17.7959 29.8125C20.7941 29.7693 23.3707 24.7258 24.1211 22.4547C20.1002 20.5588 20.316 16.9031 20.316 16.7869V16.7869ZM16.8264 6.65996C18.5098 4.66113 18.357 2.8416 18.3072 2.1875C16.8197 2.27383 15.0998 3.2002 14.1203 4.33906C13.0412 5.56094 12.407 7.07168 12.5432 8.775C14.1502 8.89785 15.6178 8.07109 16.8264 6.65996V6.65996Z"
        fill="#A6B3C0"
      />
    </g>
    <defs>
      <clipPath id="clip0_7115_11165">
        <rect width="24" height="32" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default AppStore
