import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import {
  BaseTextV2,
  TinyTextV2,
  TinyTextV2Secondary,
  TinyTextV2Bold,
  BaseTextV2Bold,
} from 'components/Text'
import { PromoBadgeContainer } from 'components/Product/ProductBox/styles'
import { BadgeContainer } from 'components/Product/Badge/styles'
import { SEARCH_DESKTOP_BREAKPOINT } from '../const'

export const PriceText = styled(TinyTextV2Bold)`
  color: ${p => (p.$isInactive ? theme.colors.gray4 : theme.colors.primary)};

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    font-size: ${theme.textSizes.sm};
    line-height: 20px;
  }
`

export const OldPriceText = styled(TinyTextV2Secondary)`
  text-decoration: line-through;
`

export const SuggestedProduct = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: ${theme.spacing.sm} ${theme.spacing.xs};
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: #f6f7f9;
  }

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    padding: ${theme.spacing.xs};
  }
`

export const InnerContainer = styled(Flex)`
  width: 100%;
  align-items: center;
`

export const Descr = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-left: ${theme.spacing.xs};
  padding-right: ${theme.spacing.sm};
  flex-flow: column;
  align-items: flex-start;

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    flex-flow: row;
    align-items: center;
    gap: ${theme.spacing.xs};
    padding: 0 ${theme.spacing.xs};
  }
`

export const ProductImgContainer = styled(Flex)`
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  mix-blend-mode: multiply;
  align-self: flex-start;

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    align-self: center;
  }
`

export const Naming = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  gap: ${theme.spacing.xxs};
`

export const Pricing = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${theme.spacing.xs};
  flex-direction: row-reverse;

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-end;
  }
`

export const PricingContainer = styled(Flex)`
  gap: ${theme.spacing.xs};
  align-items: center;

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    flex-direction: column;
    padding-right: ${theme.spacing.xs};
    padding-left: ${theme.spacing.sm};
    text-align: left;
    gap: ${theme.spacing.xxs};
  }
`

export const CartBtnContainer = styled.div`
  align-self: flex-start;

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    align-self: center;
  }

  ${p =>
    p.isHidden &&
    `
    visibility: hidden;
  `};
`

export const ClampedBadgeRow = styled(PromoBadgeContainer)`
  position: inherit;
  max-width: calc(100% - 8px);
  color: ${theme.colors.secondary};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // safari doesn't work well with clamp, cutting of height, min-height is added for as a band-aid
  min-height: 20px;
  ${BadgeContainer} {
    margin-right: 6px;
    max-height: 16px;
    span {
      font-size: 10px;
    }
  }
  z-index: auto;
  margin-left: 0;
`

export const BrandText = styled(BaseTextV2Bold).attrs({
  isSmall: { lineHeight: true },
})`
  margin-right: ${theme.spacing.xs};
  ${p =>
    p.$isInactive &&
    `
    color: ${theme.colors.lightGrey4};
  `} @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    vertical-align: middle;
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.textSizes.xxs};
    line-height: 16px;
    color: ${theme.colors.lightGrey4};
  }
`

export const ProductNameText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  ${p =>
    p.$isInactive &&
    `
    color: ${theme.colors.lightGrey4};
  `}
  margin-right: ${theme.spacing.xs};
`

export const IconsContainer = styled.div`
  display: inline-flex;
  gap: ${theme.spacing.xs};
  vertical-align: middle;
  align-items: center;
`

export const PromoTitle = styled(TinyTextV2)`
  color: ${theme.colors.secondary};
`

export const AmountText = styled(TinyTextV2Secondary)`
  line-height: 20px;
`

export const AvailableText = styled(TinyTextV2)`
  color: ${theme.colors.infoBlue2};
`

export const PriceTextsContainer = styled(Flex)`
  gap: ${theme.spacing.xs};

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-end;
    gap: ${theme.spacing.xxs};
  }
`
