import styled from 'styled-components'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

export const LineContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  justify-content: space-between;
  align-items: flex-start;
  padding: ${theme.spacing.xxs} 0;
`

export const LineLabel = styled(BaseTextV2)`
  line-height: 16px;
`

export const LineValue = styled(LineLabel)`
  line-height: 16px;
  white-space: nowrap;
  font-weight: ${p =>
    p.$bold ? theme.fontWeights.bold : theme.fontWeights.regular};
`
