import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { injectIntl, FormattedMessage } from 'react-intl'
import { compose } from 'lodash/fp'

import { withSpinner } from 'hocs'
import { PRODUCTS_REDUCER_NAME } from 'containers/Products'
import { REPLACEMENTS_REDUCER_NAME } from 'containers/Products/Replacements/consts'
import { selectTemplateNotAddedData } from 'containers/Templates/selectors'
import {
  ITEM_LIST_NAMES,
  trackTISNonStockDistantDD,
  trackTISShowReplacement,
} from 'services/analytics'
import SuccessBox from 'components/SuccessBox'
import ErrorContainer from 'views/Cart/ErrorContainer'
import MissingItemsList from 'components/MissingItemsList'

import MissingItemsLayout from './Layout'
import messages from './messages'

const TemplateNotAddedItems = ({
  templateDetails,
  intl: { formatMessage },
}) => {
  const { templateName, templateId, items = [], type } = templateDetails
  return items?.length ? (
    <MissingItemsLayout>
      <SuccessBox
        message={formatMessage(messages.addedAvailable, { templateName })}
      />

      <ErrorContainer errorMessage={formatMessage(messages.addedPartialy)} />

      <MissingItemsList
        items={items}
        itemListName={ITEM_LIST_NAMES.TEMPLATE}
        itemListId={templateId}
        additionalEventParams={{
          template_id: templateId,
          template_type: type,
        }}
        headerTitle={<FormattedMessage {...messages.itemsHeader} />}
        onTrackGetReplacements={trackTISShowReplacement}
        onTrackShiftDelivery={trackTISNonStockDistantDD}
      />
    </MissingItemsLayout>
  ) : null
}

export default compose(
  injectIntl,
  withSpinner([[PRODUCTS_REDUCER_NAME, REPLACEMENTS_REDUCER_NAME]]),
  connect(
    createStructuredSelector({
      templateDetails: selectTemplateNotAddedData,
    }),
  ),
)(TemplateNotAddedItems)
