import { createActionCreator } from 'utils/redux-utils'
import { setBrowserDashboardTileOpen } from 'views/Dashboard/utils'

export const DASHBOARD_TILES_SET = 'DASHBOARD_TILES_SET'
export const setTiles = createActionCreator(DASHBOARD_TILES_SET)

export const setDashboardTiles = state => {
  setBrowserDashboardTileOpen(state)
  return setTiles(state)
}
