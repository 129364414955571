import React from 'react'

import theme from 'theme'

const Document = ({ width = 24, height = 24, color = theme.colors.gray5 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V8.82843C19 8.29799 18.7893 7.78929 18.4142 7.41421L14.5858 3.58579C14.2107 3.21071 13.702 3 13.1716 3H7C5.89543 3 5 3.89543 5 5Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 9H15C13.8954 9 13 8.10457 13 7V3"
      stroke={color}
      strokeWidth="2"
    />
    <path d="M9 17H13" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M9 13H15" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export default Document
