import React from 'react'
import { isNil } from 'lodash/fp'

import { LineContainer, LineLabel, LineValue } from './styles'

const Line = ({ label, value, valueColor, testID, boldValue }) => (
  <LineContainer data-test-id={testID}>
    <LineLabel>{label}</LineLabel>
    {!isNil(value) && (
      <LineValue
        $bold={boldValue}
        data-test-id="line-value"
        style={{ color: valueColor }}
      >
        {value}
      </LineValue>
    )}
  </LineContainer>
)

export default Line
