import { makeMessages, makeTranslation } from 'utils'

const messages = {
  success: 'success',
  successDescription: 'successDescription',
  expired: 'expired',
  expiredDescription: 'expiredDescription',
  newLink: 'newLink',
  alreadyVerified: 'alreadyVerified',
  alreadyVerifiedDescription: 'alreadyVerifiedDescription',
  toLogin: { id: 'setPassword.toLogin' },
  invalid: 'invalid',
  invalidDescription: 'invalidDescription',
  resend: 'resend',
  toContinue: { id: 'verifyEmail.toContinue' },
  toDoThis: { id: 'verifyEmail.toDoThis' },
}

export default makeMessages(messages, makeTranslation('emailConfirm'))
