import React from 'react'
import { get } from 'lodash/fp'

import { CartProduct } from 'components/Product'

const CartProductList = ({
  items,
  borderFirstProduct,
  toggleDateProductIdChanging,
  selectedDeliveryDate,
  selectedRouteId,
  isFirstCartDelivery,
}) => (
  <>
    {items.map(({ product, units, unitsMap }, index) => (
      <CartProduct
        {...{
          product,
          units,
          unitsMap,
          toggleDateProductIdChanging,
          selectedRouteId,
        }}
        selectedDeliveryDate={selectedDeliveryDate}
        id={product.id}
        key={product.id}
        productId={product.id}
        borderTop={index !== 0 || borderFirstProduct}
        borderBottom={index !== items.length - 1}
        showDateHint={isFirstCartDelivery && index === 0}
        unitOfMeasure={get([[0], 'unitOfMeasure'], units)}
        index={index}
      />
    ))}
  </>
)

export default CartProductList
