import React, { useEffect, useState } from 'react'

import { handleNumberChange } from 'utils'
import { MAX_INPUT_VALUE } from 'consts'

import {
  calculateAmountByQuantity,
  calculateQuantityByAmount,
} from 'components/Product/utils'

import { UnitQuantityInputWrapper } from './styles'

const UnitQuantityInput = ({ value, unitData, onBlur, disabled, ...rest }) => {
  const [focused, setFocused] = useState(false)
  const [inputValue, setInputValue] = useState(() =>
    calculateAmountByQuantity(unitData, value),
  )

  useEffect(
    () => {
      setInputValue(calculateAmountByQuantity(unitData, value))
    },
    [value, unitData],
  )

  const onInputChange = ({ target }) => {
    const newValue = handleNumberChange(target.value)
    if (newValue > MAX_INPUT_VALUE) {
      return
    }

    setInputValue(newValue)
  }

  const handleBlur = ({ target }) => {
    setFocused(false)
    const newQuantity = calculateQuantityByAmount(unitData, target.value)
    setInputValue(calculateAmountByQuantity(unitData, newQuantity))
    onBlur(newQuantity)
  }
  const handleKeyDown = ({ key }) => {
    if (key === 'Enter' && document.activeElement) {
      document.activeElement.blur()
    }
  }

  return (
    <UnitQuantityInputWrapper
      onFocus={() => setFocused(true)}
      onBlur={handleBlur}
      onChange={onInputChange}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      value={
        focused || !Number(inputValue)
          ? inputValue
          : `${inputValue} ${unitData.unitOfMeasureDescription}`
      }
      {...rest}
    />
  )
}

export default UnitQuantityInput
