import queryString from 'query-string'

import { PROMO_GROUP_PER_PAGE_SIZE } from './consts'

export const getPromoGroupProducts = ({
  groupId,
  perPage = PROMO_GROUP_PER_PAGE_SIZE,
  page = 1,
}) => {
  const queryParams = queryString.stringify({
    per_page: perPage,
    page,
  })

  return {
    url: `/me/promotion_groups/${groupId}/products?${queryParams}`,
  }
}
