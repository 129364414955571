import React from 'react'
import { useIntl } from 'react-intl'

import {
  useAddressFormatting,
  formatMultiline,
} from 'views/Orders/Details/utils'
import Column from '../components/Column'

import messages from '../../messages'

const BillingDetails = ({ orderDetails, userData }) => {
  const { formatMessage } = useIntl()

  const { clientName, clientEmail, clientPhoneNumber } = orderDetails
  const {
    billingName,
    billingAddress,
    billingPostcode,
    billingCity,
    billingVatNo,
  } = userData

  const contactDetails = formatMultiline([
    clientName,
    clientEmail,
    clientPhoneNumber,
  ])
  const invoiceDetails = useAddressFormatting({
    name: billingName,
    address: billingAddress,
    postcode: billingPostcode,
    city: billingCity,
    billingVatNo,
  })

  const config = {
    containerTestId: 'billing-details-column',
    header: formatMessage(messages.purchaser),
    rows: [
      {
        label: formatMessage(messages.contactDetails),
        value: contactDetails,
      },
      {
        label: formatMessage(messages.billingData),
        value: invoiceDetails,
      },
    ],
  }

  return <Column config={config} />
}

export default BillingDetails
