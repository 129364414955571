import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'

import theme from 'theme'
import { TinyTextV2 } from 'components/Text'
import { INVOICES_DESKTOP_START } from '../consts'

export const Header = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  @media (min-width: ${INVOICES_DESKTOP_START}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`

/* prettier-ignore */
export const FiltersContainer = styled(Box)`
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  margin-top: 17px;
  margin-left: -${theme.spacing.base};
  margin-right: -${theme.spacing.base};
  ${p => p.bordered && `
    border-bottom: 1px solid rgba(87, 106, 118, 0.15);
  `};

  @media (min-width: ${INVOICES_DESKTOP_START}) {
    display: flex;
    justify-content: flex-end;
    margin-left: 0;
    margin-right: 0
  }
`

export const DateRangePickerLabel = styled(TinyTextV2)`
  line-height: 24px;
`

/* prettier-ignore */
export const DateRangePickerContainer = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing.xs};

  .DateInput {
    width: auto;
  }

  .DateRangePickerInput {
    display: flex;
    gap: ${theme.spacing.xxs};
  }

  .DateInput_input {
    width: ${theme.spacing.md};
    border-bottom: none;
    font-family: ${theme.fonts.primary};
    padding: 1px ${theme.spacing.xs};
    font-weight: ${theme.fontWeights.regular};
    text-align: center;
    font-size: ${theme.textSizes.xxs};
    line-height: 16px;
    color: ${theme.colors.primary};
    border-radius: 8px;

    :not(:placeholder-shown) {
      width: 80px;
      color: ${theme.colors.white};
      background: ${theme.colors.secondary};
    }
  }

  .DayPicker__withBorder {
    box-shadow: 0 12px 32px 0 #0C34593D;
    border-radius: 16px;
  }

  .DayPicker_transitionContainer {
    border-radius: 16px;
  }

  .DateInput_fang {
    display: none;
  }

  .CalendarDay {
    vertical-align: middle;
    border: none;
    font-family: ${theme.fonts.primary};
    font-size: ${theme.textSizes.sm};
    line-height: 24px;
    color: ${theme.colors.primary};
    border-radius: 8px;
    :hover {
      background: ${theme.colors.lightGrey};
    }
  }

  .CalendarDay__selected {
    color: ${theme.colors.white};
    background: ${theme.colors.secondary};
  }

  .CalendarDay__hovered_span, .CalendarDay__selected_span {
    color: ${theme.colors.greenDark};
    background: ${theme.colors.greenLight5};
  }

  .CalendarDay__blocked_out_of_range {
    color: ${theme.colors.gray2};
    :hover {
      background: ${theme.colors.white};
    }
  }

  .DayPicker_weekHeader {
    top: 53px;
  }

  .DayPicker_weekHeader_li {
    font-family: ${theme.fonts.primary};
    font-size: ${theme.textSizes.sm};
    line-height: 24px;
    color: ${theme.colors.lightGrey4};
  }

  .CalendarMonth_caption {
    font-family: ${theme.fonts.primary};
    font-size: ${theme.textSizes.l};
    color: ${theme.colors.primary};
    text-transform: capitalize;
    line-height: 24px;
  }

  .DayPickerNavigation_button {
    border: none;
  }

  .DateRangePicker_picker {
    top: 25px !important;
  }
`

/* prettier-ignore */
export const ListWrapper = styled.div`
  min-height: 425px;

  ${p => p.noInvoices && `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `};

  @media (min-width: ${INVOICES_DESKTOP_START}) {
    min-height: 325px;
  }
`

export const InvoiceListWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  margin: 0 -${theme.spacing.sm};
  flex-direction: column;
  @media (min-width: ${INVOICES_DESKTOP_START}) {
    margin: 0;
  }
`
