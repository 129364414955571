import React from 'react'

import theme from 'theme'

const User = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M19 20v-1a4 4 0 00-4-4H9a4 4 0 00-4 4v1"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <circle cx={12} cy={8} r={3} stroke={color} strokeWidth={2} />
  </svg>
)

export default User
