import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'

import {
  getColors,
  getSize,
  getSpacing,
} from 'components/helpers/theme-selectors'

/* prettier-ignore */
export const Panel = styled(Box)`
  width: 100%;
  padding: ${props => getSpacing(props).base};
  align-self: flex-start;
  min-height: 216px;
  display: flex;
  flex-flow: column;

  margin-left: auto;
  margin-right: auto;
  border: none;
  max-width: 100%;

  ${props => !props.withoutMobilMargin && `
    margin-top: 60px;
    margin-bottom: 60px;
  `};

  @media screen and (min-width: 640px) {
    border: 1px solid ${props => getColors(props).border};
    max-width: ${props => getSize(props).panel};

    ${props => props.withoutTabletMargin && `
      margin-top: 0;
      margin-bottom: 0;
    `}
    
    ${props => !props.withoutTabletMargin && `
      margin-top: 90px;
      margin-bottom: 90px;
    `};
  }
`

export const PanelCenteredContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
`
