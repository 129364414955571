import React from 'react'
import { useIntl } from 'react-intl'
import { Portal } from 'react-portal'

import { ROUTES } from 'consts'
import { ModalHeader } from 'components/modal/styles'
import LoaderFullHeight from 'components/LoaderFullHeight'
import { LegalText, LegalTextLink } from 'components/Text'
import Form from './Form'

import {
  ContactFormContainer,
  DescriptionText,
  LegalTextContainer,
} from './styles'
import messages from './messages'

const ContactForm = ({
  headingText,
  descriptionText,
  onClose,
  isTopicField,
  hasFileSelector,
  onSubmit,
  submitDisabled,
  submitError,
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      {submitDisabled && (
        <Portal key="portal">
          <LoaderFullHeight />
        </Portal>
      )}

      <ContactFormContainer data-test-id="contact-form">
        <ModalHeader
          style={{ margin: 0 }}
          heading={headingText}
          onClick={onClose}
        />
        <DescriptionText>{descriptionText}</DescriptionText>
        <Form
          isTopicField={isTopicField}
          hasFileSelector={hasFileSelector}
          onSubmit={onSubmit}
          submitDisabled={submitDisabled}
          submitError={submitError}
        />
        <LegalTextContainer>
          <LegalText>
            {formatMessage(messages.legalText, {
              link: (
                <LegalTextLink to={ROUTES.PRIVACY} target="_blank">
                  {formatMessage(messages.legalTextTermsLink)}
                </LegalTextLink>
              ),
            })}
          </LegalText>
        </LegalTextContainer>
      </ContactFormContainer>
    </>
  )
}

export default ContactForm
