import { FEATURE_TYPES } from 'components/Product/Badge/consts'
import { OFFER_FEATURES_VALUES } from '../consts'

const BADGE_PRODUCT_FEATURES = [
  FEATURE_TYPES.OFFER,
  FEATURE_TYPES.RECOMMENDATION,
]
export const isProductWithBadgeFeatures = product =>
  !!product.features?.filter(({ type }) =>
    BADGE_PRODUCT_FEATURES.includes(type),
  ).length

export const getBadgeProductLabels = features => {
  const filteredFeatures = features.filter(({ type }) =>
    BADGE_PRODUCT_FEATURES.includes(type),
  )

  return filteredFeatures.map(feature => feature.label).filter(Boolean)
}

export const excludeBidmarketFeature = features =>
  features.filter(({ value }) => value !== OFFER_FEATURES_VALUES.BIDMARKET)
