import React from 'react'

import theme from 'theme'

const DeliveryTruck = ({ color = theme.colors.primary }) => (
  <svg
    className="delivery-truck-icon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 14.166H12.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3 14H2C1.44772 14 1 13.5523 1 13L1 5C1 4.44772 1.44772 4 2 4H12C12.5523 4 13 4.44772 13 5V13C13 13.5523 12.5523 14 12 14H7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13 12.6667L13 7C13 6.44772 13.4477 6 14 6H15.9177C16.2385 6 16.5399 6.15398 16.728 6.41398L18.8103 9.29325C18.9336 9.46377 19 9.66884 19 9.87927V13C19 13.5523 18.5523 14 18 14H16.4286"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <circle cx="5" cy="15" r="2" stroke={color} strokeWidth="2" />
    <circle cx="15" cy="15" r="2" stroke={color} strokeWidth="2" />
  </svg>
)

export default DeliveryTruck
