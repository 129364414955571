import styled from 'styled-components'
import { Box } from '@rebass/grid'

import {
  getColors,
  getFontWeights,
  getSpacing,
} from 'components/helpers/theme-selectors'
import { P, H4, H5 } from 'components/Text'
import Back from 'components/Back'

export const UserEmail = styled(P)`
  color: ${props => getColors(props).gray3};
  margin-top: ${props => getSpacing(props).xxs};
`

export const PanelTitle = styled(H5)`
  color: ${props => getColors(props).gray4};
  font-weight: ${props => getFontWeights(props).strongBold};
  margin: ${props => getSpacing(props).md} 0 ${props => getSpacing(props).sm};
`

export const ConsentPanelTitle = styled(PanelTitle)`
  margin-top: 48px;
`

export const Header = styled(H4)`
  margin: 0;
  font-weight: ${props => getFontWeights(props).strongBold};
  margin-bottom: ${props => getSpacing(props).base};
`

export const SubHeader = styled(H4)`
  margin: 0;
  font-weight: ${props => getFontWeights(props).bold};
  margin-top: ${props => getSpacing(props).md};
  margin-bottom: ${props => getSpacing(props).sm};
`

// prettier-ignore
export const PageContainer = styled(Box)`
  ${props => props.padded && `
    padding: 32px 0 48px;
  `};
  ${props => !props.padded && `
    align-items: center;
  `};

  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
`

export const BackBtn = styled(Back)`
  position: absolute;
  left: ${props => getSpacing(props).md};

  @media screen and (max-width: 667px) {
    left: ${props => getSpacing(props).base};
  }
`

export const SecondaryTitle = styled(P)`
  margin-bottom: ${props => getSpacing(props).base};

  b {
    font-weight: ${props => getFontWeights(props).bold};
  }
`

export const Fixed = styled.span`
  white-space: nowrap;
`
