import { takeLatest, call, put } from 'redux-saga/effects'
import history from 'utils/history'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'

import { PROFILE_ROUTES } from '../consts'
import * as api from './api'
import { sendNewsletterFeedbackActions } from './actions'

export function* sendNewsletterFeedbackFlow({ data }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: sendNewsletterFeedbackActions,
      request: api.sendNewsletterFeedback,
      params: data,
    })

    history.replace(PROFILE_ROUTES.NEWSLETTER_UNSUBSCRIBE_SUCCESS)
  } catch (err) {
    console.error('Error sending newsletter feedback', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(sendNewsletterFeedbackActions.DELTA, sendNewsletterFeedbackFlow),
]
