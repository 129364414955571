import React from 'react'

const ContainerArrow = () => (
  <svg
    width="24"
    height="9"
    viewBox="0 0 24 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5858 7.58579L4.46447 1.46447C3.52678 0.526784 2.25501 0 0.928932 0H23.0711C21.745 0 20.4732 0.526783 19.5355 1.46446L13.4142 7.58579C12.6332 8.36683 11.3668 8.36684 10.5858 7.58579Z"
      fill="#229944"
    />
  </svg>
)

export default ContainerArrow
