import React from 'react'

import {
  PackageContainer,
  Line,
  Dot,
  MiddleDotsContainer,
  SingleDotContainer,
  CurvedContainer,
  CurvedLine,
  VerticalCone,
  VerticalDotsContainer,
} from './styles'
import DotWithName from './DotWithName'

const Package = ({ isFirst, isLast, isIncoming, routes, expandedMode }) => {
  const multipleRoutes = routes.length > 1

  return (
    <PackageContainer expanded={expandedMode && multipleRoutes}>
      <Line pullLeft disabled={isFirst} />

      {isIncoming && multipleRoutes ? (
        <MiddleDotsContainer>
          {routes.map(({ routeId }) => (
            <Dot key={routeId} />
          ))}
        </MiddleDotsContainer>
      ) : (
        !isIncoming &&
        multipleRoutes && (
          <>
            <CurvedContainer>
              <CurvedLine />
              <VerticalCone />
              <CurvedLine rotate />
            </CurvedContainer>

            <VerticalDotsContainer>
              {routes.map(route => (
                <DotWithName key={route.routeId} {...route} />
              ))}
            </VerticalDotsContainer>
          </>
        )
      )}

      {routes.length === 1 && (
        <SingleDotContainer>
          <Dot full={!isIncoming} />
        </SingleDotContainer>
      )}

      <Line pullRight disabled={isLast} {...{ multipleRoutes }} />
    </PackageContainer>
  )
}

export default Package
