import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '../messages'
import { ReplacementsButton } from './styles'

const ReplacementsBtn = ({ onClick, errorMessage, dataTestId, className }) => (
  <ReplacementsButton {...{ onClick, errorMessage, dataTestId, className }}>
    <FormattedMessage {...messages.replacements} />
  </ReplacementsButton>
)

export default ReplacementsBtn
