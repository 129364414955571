import React from 'react'
import { useIntl } from 'react-intl'
import { capitalize } from 'lodash/fp'

import { formatPrice } from 'utils'
import { formatSavingsLabel } from 'views/Cart/utils'
import { findPrizeUnitIndex } from 'containers/Cart/utils'
import messages from 'components/Product/messages'
import PromoGroup from 'components/Product/PromoGroup'
import { PromoInfoContainer } from 'components/Product/CartProduct/Promotions/PromoBar/styles'
import { isSalePromoType } from 'containers/Promotions/utils'
import { isLoweredPrice } from 'components/Product/utils'
import Amounts from '../Amounts'

import {
  OuterWrapper,
  Wrapper,
  PromoBox,
  BoxChild,
  Counter,
  PromoText,
} from '../styles'
import { SmallText, XIcon } from './styles'

const SelfPromoBar = ({ units, promotion, product, isCatalogReward }) => {
  const { formatMessage } = useIntl()
  const prizeUnitIndex = findPrizeUnitIndex(units)
  const defaultPromoUnit = {
    unitOfMeasure: units[0].unitOfMeasure,
    quantity: 0,
    prize: prizeUnitIndex === -1,
  }
  const unitsToDisplay = units[1] ? units : [...units, defaultPromoUnit]
  const isSalePromo = isSalePromoType(promotion)
  const isLoweredPricePromo = isLoweredPrice(promotion)
  const discount = promotion?.reward?.discountPercentage
  const savingsPromoText =
    promotion[isSalePromo ? 'title' : 'description'] ||
    formatSavingsLabel(units[prizeUnitIndex]?.totalPriceNetDiscount)

  const showPromoUnitsInBox =
    units[1] && !(isCatalogReward || isLoweredPricePromo)

  const promoText = (
    <PromoText>
      {isLoweredPricePromo ? savingsPromoText : promotion.title}
    </PromoText>
  )

  return (
    <OuterWrapper>
      <Wrapper>
        <PromoInfoContainer>
          <PromoBox>
            {showPromoUnitsInBox ? (
              <>
                <BoxChild>
                  {formatPrice(units[1].priceNet, {
                    withoutCurrency: true,
                    minimumFractionDigits: 0,
                  })}{' '}
                  <SmallText withMargins bold>
                    zł
                  </SmallText>
                </BoxChild>

                <Counter>
                  <XIcon />
                  {units[1].quantity}
                </Counter>
              </>
            ) : (
              <PromoText bold>
                {isLoweredPricePromo
                  ? `-${discount}%`
                  : capitalize(formatMessage(messages.promotion))}
              </PromoText>
            )}
          </PromoBox>

          {isCatalogReward || isSalePromo ? (
            <PromoGroup
              groupId={promotion?.groupId}
              showModal={promotion?.showModal}
            >
              {promoText}
            </PromoGroup>
          ) : (
            promoText
          )}
        </PromoInfoContainer>

        <Amounts
          units={unitsToDisplay}
          unitsOfMeasure={product.unitsOfMeasure}
        />
      </Wrapper>
    </OuterWrapper>
  )
}

export default SelfPromoBar
