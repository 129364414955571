import dayjs from 'dayjs'
import {
  pickBy,
  identity,
  flow,
  groupBy,
  prop,
  compose,
  filter,
  join,
  complement,
  isNil,
  find,
  keyBy,
  map,
  mapValues,
  merge,
  sortBy,
  values,
  reduce,
  uniqBy,
} from 'lodash/fp'

import {
  CART_ERRORS,
  CART_ITEM_ERROR,
  CART_UPDATE_ERROR_CODES,
} from 'containers/Cart/consts'
import { DATE_TIME_FORMATS } from 'utils/datetime'
import { extractFirstError } from 'utils'

export const getItemError = ({
  messages,
  errorId,
  errorMessage,
  stock,
  formatMessage,
}) => {
  const message = messages[errorId]
  const lowStockError = [
    String(CART_ITEM_ERROR.UNSUFFICIENT_AMOUNT),
    String(CART_ERRORS.LOW_STOCK),
  ].includes(String(errorId))

  if (!errorMessage) {
    return (
      message && formatMessage(message, lowStockError ? { value: stock } : {})
    )
  }
  return errorMessage
}

export const findPrizeUnitIndex = units => units?.findIndex(unit => unit.prize)

export const transformCartItems = items =>
  items.map(({ product, units }) => ({
    product,
    units,
    unitsMap: groupBy(prop('unitOfMeasure'), units),
  }))

export const calculateDeliveryTotal = deliveryItems =>
  deliveryItems.reduce(
    (acc, { units }) =>
      acc +
      units.reduce(
        (unitsAcc, { totalPriceNet }) => unitsAcc + totalPriceNet,
        0,
      ),
    0,
  )

export const makeDeliveryStickyId = (deliveryDate, routeId) =>
  `stickyDelivery-${dayjs(deliveryDate).format('DDMMYYYY')}-${routeId}`

export const mapDeliveriesToProductIds = deliveries =>
  deliveries.reduce((acc, { productIds }) => [...acc, ...productIds], [])

export const getDeliverySettings = (
  { deliveryDate, routeId, productIds },
  deliveryDatesSettings,
) => {
  const settingsList = deliveryDatesSettings.get(productIds.join('_'))

  return find(
    {
      deliveryDate,
      routeId,
    },
    settingsList,
  )
}

const byDateAndRoute = o =>
  `${dayjs.utc(o.deliveryDate).format()}::${o.routeId}`

const byDate = o => dayjs.utc(o.deliveryDate).format(DATE_TIME_FORMATS.API_DATE)

const orderCartFn = sortBy(['deliveryDate', 'routeId'])

export const getMergedDeliveries = ({ incomingOrders, cartData }) => {
  let firstCartDeliveryMet
  let secondCartDeliveryMet
  let firstIncomingDeliveryMet

  const tmpIncoming = flow(
    groupBy(byDate),
    mapValues.convert({ cap: false })((val, key = '') => ({
      incOrders: val,
      routeId: val[0].routeId,
      deliveryDate: key.split('::')[0],
    })),
  )(incomingOrders)

  const tmpCart = flow(
    map(item => ({
      ...item,
      deliveryDate: dayjs.utc(item.date).format(),
    })),
    keyBy(byDate),
  )(cartData)

  const mergedData = merge(tmpIncoming, tmpCart)

  return flow(
    values,
    orderCartFn,
    map.convert({ cap: false })((item, index) => {
      const isFirstCartDelivery = !firstCartDeliveryMet && !!item.items
      const isSecondCartDelivery =
        firstCartDeliveryMet && !secondCartDeliveryMet && !!item.items
      const isFirstIncomingDelivery =
        !firstIncomingDeliveryMet && !!item.incOrders

      if (isFirstCartDelivery) firstCartDeliveryMet = true
      if (isSecondCartDelivery) secondCartDeliveryMet = true
      if (isFirstIncomingDelivery) firstIncomingDeliveryMet = true

      return {
        ...item,
        // to not bloat object, filter falses
        ...pickBy(identity)({
          isFirstDelivery: index === 0,
          isFirstCartDelivery,
          isSecondCartDelivery,
          isFirstIncomingDelivery,
        }),
      }
    }),
  )(mergedData)
}

export const getDeliveriesByDate = ({ incomingOrders, cartData }) => {
  const parsedIncomingOrders = uniqBy(byDateAndRoute)(incomingOrders)

  return flow(
    reduce((acc, current) => {
      const deliveryDate = dayjs
        .utc(current.deliveryDate || current.date)
        .format()
      const currentRoute = {
        routeId: current.routeId,
        routeName: current.routeName,
        routeSortingOrder: current.routeSortingOrder,
        isIncoming: !current.items,
      }
      const match = acc[deliveryDate]
      const updatedMatch = match && {
        ...match,
        ...current,
        deliveryDate,
        routes: flow(
          uniqBy('routeId'),
          sortBy('routeSortingOrder'),
        )([...match.routes, currentRoute]),
      }

      return {
        ...acc,
        [deliveryDate]: match
          ? updatedMatch
          : { ...current, deliveryDate, routes: [currentRoute] },
      }
    }, {}),
    values,
    orderCartFn,
  )([...cartData, ...parsedIncomingOrders])
}

export const hasOutOfStockProduct = positionErrors =>
  positionErrors.some(
    ({ errorId, quantityAdded }) =>
      errorId === CART_ITEM_ERROR.UNSUFFICIENT_AMOUNT && quantityAdded === 0,
  )

export const formatDateForApi = date =>
  dayjs(date).format(DATE_TIME_FORMATS.API_DATE)
export const formatDeliveryAddress = ({
  deliveryAddress,
  deliveryCity,
  deliveryPostcode,
  deliveryCountry,
}) => {
  const primaryLine = compose(
    join(' '),
    filter(complement(isNil)),
  )([deliveryAddress, deliveryPostcode, deliveryCity])

  return deliveryCountry ? `${primaryLine} (${deliveryCountry})` : primaryLine
}

export const isCartUpdateError = error => {
  const firstError = extractFirstError(error)
  const errorCode = firstError?.code

  return Object.values(CART_UPDATE_ERROR_CODES).includes(errorCode)
}
