import React from 'react'
import { FormattedMessage } from 'react-intl'

import { makeMessages, makeTranslation, FormattedRichMessage } from 'utils'

import calendarImg from 'assets/hintsImgs/calendar2.png'

const messages = makeMessages(
  {
    header: 'header2',
    info: 'info2',
  },
  makeTranslation('hintModal.dates'),
)

const header = <FormattedMessage {...messages.header} />

export const hintModalConfig = [
  {
    header,
    img: calendarImg,
    info: <FormattedRichMessage {...messages.info} />,
  },
]
