import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'consts'
import { formatPrice } from 'utils'
import { PAY_SCENARIO } from 'containers/Orders/consts'
import { INVOICE_STATUS } from 'containers/Invoices/consts'

import { useRemainingDaysFormatted } from 'views/Invoices/utils'
import { useGetSubtitleFromTradeLimit } from 'views/Dashboard/Invoices/utils'
import { VARIANT } from 'views/Dashboard/Invoices/consts'
import Tile from '../../Tile'
import messages from '../../messages'
import InvoicesActions from '../../ActionBar/InvoicesActions'
import CartActions from '../../ActionBar/CartActions'
import { Line, LinesGroup, Overpayments, CreditLimit } from '../../Lines'

const Unpaid = ({ tradeLimit, invoicesSummary, footer }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const {
    invoicesCount,
    closestInvoiceDeadlineDate,
    remainingAmount,
  } = invoicesSummary.toPay
  const remainingDays = useRemainingDaysFormatted(closestInvoiceDeadlineDate)

  const { scenario } = tradeLimit
  const hasCartIssue = scenario !== PAY_SCENARIO.GREEN

  const title = formatMessage(
    hasCartIssue ? messages.titleUnpaidCredit : messages.titleUnpaid,
    {
      invoicesCount,
      invoicesLabel: formatMessage(messages.unpaidCount, {
        number: invoicesCount,
      }),
    },
  )
  const { subtitleColor, subtitle } = useGetSubtitleFromTradeLimit({
    scenario,
    defaultMessage: formatMessage(messages.grossPrice, {
      price: formatPrice(remainingAmount),
    }),
  })

  const footerElement = useMemo(
    () => {
      if (footer) return footer

      if (hasCartIssue) {
        return <CartActions scenario={scenario} />
      }

      return (
        <InvoicesActions
          variant={VARIANT.GREEN}
          onPayInvoices={() => {
            navigate(
              `${ROUTES.INVOICES.ROOT}?${queryString.stringify({
                preselectStatus: [
                  INVOICE_STATUS.NOT_PAID,
                  INVOICE_STATUS.NOT_PAID_EXPIRED,
                ],
              })}`,
            )
          }}
        />
      )
    },
    [hasCartIssue, scenario, footer],
  )

  return (
    <Tile
      title={title}
      subtitle={subtitle}
      subtitleColor={subtitleColor}
      footer={footerElement}
    >
      <LinesGroup isFirst isLast>
        <Line
          boldValue
          testID="payment-in-days-line"
          label={formatMessage(messages.paymentDate)}
          value={remainingDays}
        />
        <CreditLimit tradeLimit={tradeLimit} />
        <Overpayments invoicesSummary={invoicesSummary} />
      </LinesGroup>
    </Tile>
  )
}

export default Unpaid
