import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ROUTES } from 'consts'
import { BUTTON_SIZE, AlternateBtn } from 'components/Button'
import { Link } from 'components/Navigation'
import { Title } from './styledComponents'

import Notification from '../Notification'
import messages from './messages'

const Succeed = () => (
  <Notification header={messages.succeeded} bigHeader hideFooter height="214px">
    <Title>
      <FormattedMessage {...messages.youCanLogin} style={{ width: 330 }} />
    </Title>

    <Link to={ROUTES.LOGIN} style={{ width: '100%', marginTop: '16px' }}>
      <AlternateBtn size={BUTTON_SIZE.BLOCK}>
        <FormattedMessage {...messages.toLogin} />
      </AlternateBtn>
    </Link>
  </Notification>
)

export default Succeed
