import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { TinyTextV2 } from 'components/Text'

import theme from 'theme'

export const FiltersInnerContainer = styled(Flex)`
  gap: ${theme.spacing.xs};
  overflow-x: auto;
`

export const InvoiceCount = styled(TinyTextV2)`
  color: ${theme.colors.gray5};
  line-height: 24px;
  ${p =>
    p.$isActive &&
    `
    color: ${theme.colors.white};
  `};
`
