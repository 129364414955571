/* eslint-disable react/no-array-index-key */
// index usage is needed for animations to work correctly, should not lead to any problems
import React, { useMemo } from 'react'

import { CarouselTrackerDot, CarouselTrackerContainer } from './styles'

const CarouselTracker = ({ selectedSlide, slideCount }) => {
  const initialIndexArray = useMemo(
    () => Array.from({ length: slideCount }, (_, index) => index),
    [slideCount],
  )

  if (!slideCount || slideCount === 1) {
    return null
  }

  return (
    <CarouselTrackerContainer>
      {initialIndexArray.map((initialIndex, index) => (
        <CarouselTrackerDot
          key={index}
          data-test-id="carousel-tracker-dot"
          $active={selectedSlide === initialIndex}
        />
      ))}
    </CarouselTrackerContainer>
  )
}

export default CarouselTracker
