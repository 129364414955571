import React, { useEffect, useLayoutEffect, useCallback } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { ROUTES } from 'consts'
import { ContentWrapper } from 'components/Layout'
import LoaderFullHeight from 'components/LoaderFullHeight'
import { withPageView, SCREENS } from 'services/analytics'
import { PageTitle } from 'components/Text'
import { parseLocationQuery } from 'components/Pagination/utils'
import { useLazyGetOrdersQuery } from 'containers/Orders/List/rtkApi'

import { ORDERS_ROUTES } from '../consts'
import messages from '../messages'

import OrderList from './components/OrderList'
import NoOrders from './components/NoOrders'

const OrdersList = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { current } = parseLocationQuery(location)

  const [
    fetchOrders,
    { data: { orders, meta } = {}, isFetching: isOrdersFetching },
  ] = useLazyGetOrdersQuery()

  const ordersPagination = meta?.pagination || {}
  const { currentPage, totalPages } = ordersPagination

  useEffect(
    () => {
      window.scrollTo({ top: 0 })
    },
    [currentPage],
  )

  // used to eliminate flickering for cached results on re-renders
  useLayoutEffect(
    () => {
      const isInvalidPage = Number.isNaN(Number(current)) || current < 1
      const isOutOfRange = totalPages < current && totalPages !== 0

      if (isInvalidPage) {
        navigate({ search: `current=${1}` }, { replace: true })
      } else if (isOutOfRange) {
        navigate({ search: `current=${totalPages}` }, { replace: true })
      } else {
        fetchOrders({ page: current }, true)
      }
    },
    [totalPages, current],
  )

  const handleShowOrderDetails = orderId =>
    navigate(generatePath(ORDERS_ROUTES.DETAILS, { orderId }))
  const handleGoToCatalog = () => navigate(ROUTES.DASHBOARD)

  const handlePageChange = useCallback(
    page => {
      navigate({ search: `current=${page}` }, { replace: true })
    },
    [navigate],
  )

  if (isOrdersFetching) {
    return <LoaderFullHeight />
  }

  return (
    <ContentWrapper col="12">
      <PageTitle>
        <FormattedMessage {...messages.ordersTitle} />
      </PageTitle>

      {orders?.length ? (
        <OrderList
          onShowOrderDetails={handleShowOrderDetails}
          onPageChange={handlePageChange}
          ordersPagination={ordersPagination}
          isFetching={isOrdersFetching}
          orders={orders}
        />
      ) : (
        <NoOrders onGoToCatalog={handleGoToCatalog} />
      )}
    </ContentWrapper>
  )
}

export default withPageView(SCREENS.ORDERS)(OrdersList)
