import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { APP_BREAKPOINTS } from 'consts'

export const TitleAndBrand = styled(Flex)`
  flex-direction: column;
  gap: 4px;
  align-self: flex-start;
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    align-self: center;
  }
`
