import React from 'react'

import theme from 'theme'
import { Store } from 'components/Icons/NavMenu'
import { H2v2 } from 'components/Text'

import {
  Container,
  LabelContainer,
  AddressLabel,
  IconContainer,
} from './styles'

const StoreInfo = ({ customerName, deliveryAddress }) => (
  <Container data-test-id="store-info">
    <IconContainer>
      <Store />
    </IconContainer>

    <div>
      <LabelContainer data-test-id="store-info-name">
        <H2v2>{customerName}</H2v2>
      </LabelContainer>
      <LabelContainer data-test-id="store-info-address">
        <AddressLabel color={theme.colors.lightGrey4}>
          {deliveryAddress}
        </AddressLabel>
      </LabelContainer>
    </div>
  </Container>
)

export default StoreInfo
