import React from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import TextTooltip from 'components/Tooltip/TextTooltip'
import { useDaysCountFormatted } from 'utils/datetime'

import messages from '../messages'

const DeliveryTimerTooltip = ({ children, deliveryDate }) => {
  const { formatMessage } = useIntl()
  const deliveryDateFormatted = useDaysCountFormatted({
    date: deliveryDate,
    defaultMsg: dayjs(deliveryDate).format('D.MM'),
  })

  return (
    <TextTooltip
      content={formatMessage(messages.tooltipText, {
        date: deliveryDateFormatted,
      })}
    >
      {children}
    </TextTooltip>
  )
}

export default DeliveryTimerTooltip
