import styled from 'styled-components'

import { BasicButtonV2 } from 'components/Button'
import { H2v2, H3v2 } from 'components/Text'
import {
  FieldContainer,
  IconContainer,
  InputWithSpacing,
} from 'components/Fields/FormInputField/styles'
import { Separator } from 'containers/App/LoggedInLayout/styles'
import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'
import ActionWrapper from 'components/ActionWrapper'
import { HintContainer } from 'components/Fields/ValidationWrapper/styles'

export const FormSection = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  ${HintContainer} {
    padding: 0 ${theme.spacing.xs};
  }
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    gap: 48px;
  }
  padding-bottom: 160px;
`

export const InputLabel = styled(H2v2)`
  margin-left: ${theme.spacing.xs};
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin: 0;
    width: calc(25% + ${theme.spacing.xs});
  }
`

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};

  ${InputWithSpacing} {
    height: 48px;
    padding-top: ${theme.spacing.sm};
    padding-bottom: ${theme.spacing.sm};
    font-size: ${theme.textSizes.l};
    line-height: 24px;
  }
  ${IconContainer} {
    top: ${theme.spacing.ss};
  }
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    flex-direction: row;
    gap: 0;
    ${FieldContainer} {
      width: calc(50% - ${theme.spacing.sm});
    }
  }
`

export const Divider = styled(Separator)`
  margin: 0 ${theme.spacing.xs};
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin: 0;
  }
`

export const SubmitButton = styled(BasicButtonV2)`
  font-size: ${theme.textSizes.xs};
  font-weight: ${theme.fontWeights.regular};
  gap: ${theme.spacing.sm};
  > svg {
    > path {
      stroke: ${theme.colors.white};
    }
  }
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin: 0 auto;
    max-width: 624px;
    width: ${p => (p.$fullWidth ? '100%' : `calc(50% - ${theme.spacing.sm})`)};
  }
`

export const ContactsSection = styled.div`
  align-self: stretch;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.base};
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    align-self: center;
    max-width: 624px;
    width: 100%;
  }
`

export const ContactsSectionHeader = styled(H3v2)`
  padding: 0 ${theme.spacing.xs};
`

export const ContactButtonsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  flex-wrap: wrap;
`

export const ContactButton = styled(ActionWrapper)`
  display: flex;
  text-decoration: none;
  white-space: nowrap;
  align-items: center;
  padding: 10px ${theme.spacing.sm};
  background-color: ${theme.colors.lightGrey};
  transition: all 0.2s ease-in;
  :hover {
    background-color: ${theme.colors.lightGrey7};
  }
  border-radius: 8px;
  gap: ${theme.spacing.sm};
  flex: 1 0 0;
`
