import React from 'react'
import { useIntl } from 'react-intl'

import { formatPrice } from 'utils'
import { PAYMENT_COLORS } from 'containers/Orders/consts'

import { VARIANT } from 'views/Dashboard/Invoices/consts'
import AllPaid from '../AllPaid'
import Unpaid from '../Unpaid'
import Overdue from '../Overdue'
import InvoicesActions from '../../ActionBar/InvoicesActions'
import messages from '../../messages'
import Footer from '../../ActionBar/Footer'
import useOverdueInvoiceHandler from '../../Tile/OverdueTile/useOverdueInvoiceHandler'

const ZeroCartLimit = ({ tradeLimit, invoicesSummary }) => {
  const { formatMessage } = useIntl()
  const { overdue, toPay } = invoicesSummary
  const isGreenClient = tradeLimit.paymentColorName === PAYMENT_COLORS.GREEN
  const isRedClient = tradeLimit.paymentColorName === PAYMENT_COLORS.RED
  const handlePayInvoices = useOverdueInvoiceHandler()

  if (overdue.invoicesCount) {
    if (isGreenClient) {
      return (
        <Overdue tradeLimit={tradeLimit} invoicesSummary={invoicesSummary} />
      )
    }

    return (
      <Overdue
        tradeLimit={tradeLimit}
        invoicesSummary={invoicesSummary}
        hideCreditLimit={isRedClient}
        footer={
          <InvoicesActions
            variant={VARIANT.YELLOW}
            message={formatMessage(messages.requiresApproval)}
            onPayInvoices={handlePayInvoices}
          />
        }
        title={formatMessage(messages.titleOverdue, {
          invoicesCount: overdue.invoicesCount,
          invoicesLabel: formatMessage(messages.overdueCount, {
            number: overdue.invoicesCount,
          }),
        })}
        subtitle={formatMessage(messages.grossPrice, {
          price: formatPrice(invoicesSummary.overdue.remainingAmount),
        })}
      />
    )
  }

  if (toPay.invoicesCount) {
    return (
      <Unpaid
        tradeLimit={tradeLimit}
        invoicesSummary={invoicesSummary}
        footer={
          <Footer
            variant={VARIANT.YELLOW}
            message={formatMessage(messages.requiresApproval)}
          />
        }
      />
    )
  }

  return (
    <AllPaid
      tradeLimit={tradeLimit}
      invoicesSummary={invoicesSummary}
      footer={
        <Footer
          variant={VARIANT.YELLOW}
          message={formatMessage(messages.requiresApproval)}
        />
      }
    />
  )
}

export default ZeroCartLimit
