import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { BaseText, H4, TertiaryText } from 'components/Text'
import ProductImage from 'components/Product/ProductImage'

import { APP_BREAKPOINTS } from 'consts'

const { spacing, colors } = theme

export const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: ${spacing.sm};
  border-bottom: 1px solid ${colors.gray2};

  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    flex-direction: column;
    min-width: 220px;
    margin-right: ${spacing.xs};
  }
`

export const FirstPartWrapper = styled(Flex)`
  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    flex-direction: column;
  }

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    align-items: center;
  }
`

export const ImgPricesWrapper = styled(Flex)`
  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const PrizeImage = styled(ProductImage)`
  width: 48px;
  height: 48px;
  mix-blend-mode: multiply;
`

export const Prices = styled.div`
  min-width: 88px;
  text-align: left;
  padding-left: ${spacing.xs};
`

export const PriceText = styled(H4)`
  font-weight: ${theme.fontWeights.strongBold};
  color: ${colors.secondary};
`

export const FadedText = styled(BaseText)`
  font-size: 11px;
  color: ${colors.gray4};
`

// prettier-ignore
export const PromoName = styled(TertiaryText)`
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: ${spacing.sm};

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    border-left: 1px solid ${colors.gray2};
    margin-left: ${spacing.sm};
    margin-top: 0;
    padding-left: ${spacing.sm};
  }
`

export const PromoInfo = styled(Flex)`
  flex-direction: column;
  white-space: nowrap;

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-left: ${spacing.sm};
  }
`

export const InCartBox = styled(TertiaryText)`
  width: 174px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${spacing.base};
  font-weight: ${theme.fontWeights.bold};
  background-color: ${colors.secondaryBgLight};
  color: ${colors.secondary};
  ${p => p.withPointer && `cursor: pointer;`};

  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    margin: 0;
    width: 100%;
    margin-top: ${spacing.sm};
  }
`

export const InfoAndActionsWrapper = styled(Flex)`
  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    flex-direction: column;
    border-top: 1px solid ${colors.gray2};
    padding-top: ${spacing.sm};
    margin-top: ${spacing.sm};
  }

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    align-items: center;
  }
`

export const InfoWrapper = styled(Flex)`
  align-items: center;
  white-space: nowrap;

  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    justify-content: space-between;

    ${p => !p.isQuantitivePromo && 'flex-direction: row-reverse'};
  }
`
