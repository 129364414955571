import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { H2mobile, TinyTextV2Secondary } from 'components/Text'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const HeaderTitle = styled(H2mobile)`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.xxl};
    line-height: 40px;
    font-weight: ${theme.fontWeights.bold};
  }
`

export const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  padding: 2px 0;

  .bp5-popover-target {
    width: auto;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.xxs} 0;
  }
`

export const OrderDatetimeContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  cursor: pointer;
`

export const OrderDatetimeText = styled(TinyTextV2Secondary)`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.sm};
  }
`
