import React from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import { capitalize } from 'lodash/fp'

import { ORDER_SOURCE_LABELS } from 'views/Orders/consts'
import { useAddressFormatting } from 'views/Orders/Details/utils'
import Column from '../components/Column'

import messages from '../../messages'

const OrderDetails = ({ orderDetails, userData }) => {
  const { formatMessage } = useIntl()

  const { deliveryDate, orderSource, orderComment, routeName } = orderDetails
  const {
    deliveryAddress,
    deliveryName,
    deliveryPostcode,
    deliveryCity,
  } = userData

  const dateFormatted = capitalize(
    dayjs(deliveryDate).format('dddd, D MMMM YYYY'),
  )

  const orderSourceFormatted = ORDER_SOURCE_LABELS[orderSource]
    ? formatMessage({ id: ORDER_SOURCE_LABELS[orderSource] })
    : orderSource

  const addressFormatted = useAddressFormatting({
    name: deliveryName,
    address: deliveryAddress,
    postcode: deliveryPostcode,
    city: deliveryCity,
  })

  const config = {
    containerTestId: 'order-details-column',
    header: formatMessage(messages.orderDetails),
    rows: [
      {
        label: formatMessage(messages.deliveryTime),
        value: dateFormatted,
      },
      {
        label: formatMessage(messages.deliveryAddress),
        value: deliveryAddress
          ? addressFormatted
          : formatMessage(messages.lackOfDeliveryAddress),
      },
      {
        label: formatMessage(messages.orderSource),
        value: orderSourceFormatted,
      },
      {
        label: formatMessage(messages.route),
        value: routeName,
      },
      {
        label: formatMessage(messages.yourComment),
        value: orderComment,
      },
    ],
  }

  return <Column config={config} />
}

export default OrderDetails
