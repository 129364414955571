import React, { useState, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { InteractiveWrapper } from 'components/Button/styles'
import { BookmarkList } from 'components/Icons'
import { withPageView, SCREENS } from 'services/analytics'
import DropdownBody from 'components/DropdownBody'
import Content from './TemplatesListContent'
import TemplatesTopSegment from './TemplatesTopSegment'
import { TEMPLATES_MOBILE_MAX_WIDTH } from '../consts'
import { getBidfoodTemplate } from '../utils'

import {
  TemplateHeading,
  TemplatesListWrapper,
  TemplatesListDropdownScroll,
} from './styles'
import messages from '../messages'

const TemplatesList = ({ createTemplate, templates }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [isInputOpen, setInputOpen] = useState(false)
  const mediaQuery = `(max-width: ${TEMPLATES_MOBILE_MAX_WIDTH})`
  const isMobile = useMediaQuery({ query: mediaQuery })

  const bidfoodTemplate = useMemo(() => getBidfoodTemplate(templates), [
    templates,
  ])

  const openInput = () => setInputOpen(true)
  const closeInput = () => setInputOpen(false)

  const closeDropdown = () => setDropdownOpen(false)
  const handleDropdown = () => {
    if (isDropdownOpen) {
      return closeDropdown()
    }

    return setDropdownOpen(true)
  }

  const TemplatesListContent = (
    <Content
      {...{
        templates,
        bidfoodTemplate,
        createTemplate,
        isInputOpen,
        openInput,
        closeInput,
        closeDropdown,
        isMobile,
      }}
    />
  )

  return (
    <TemplatesListWrapper>
      <TemplateHeading>
        <FormattedMessage {...messages.header} />
      </TemplateHeading>
      {isMobile && (
        <InteractiveWrapper
          onClick={handleDropdown}
          data-test-id="templates-dropdown-toggle"
        >
          <BookmarkList />
        </InteractiveWrapper>
      )}
      {!isMobile && TemplatesListContent}
      {isDropdownOpen && (
        <DropdownBody onOverlayClick={closeDropdown} isMobile={isMobile}>
          <TemplatesTopSegment
            {...{ bidfoodTemplate, openInput, closeDropdown, isMobile }}
          />
          <TemplatesListDropdownScroll>
            {TemplatesListContent}
          </TemplatesListDropdownScroll>
        </DropdownBody>
      )}
    </TemplatesListWrapper>
  )
}

TemplatesList.propTypes = {
  templates: PropTypes.array,
  createTemplate: PropTypes.func,
}

export default withPageView(SCREENS.TEMPLATES)(TemplatesList)
