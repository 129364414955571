import React from 'react'

const Alkohol = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0001 20V15.6461L2.75623 5.63571C2.21898 4.98333 2.68304 4 3.52816 4C3.82698 4 4.11013 4.13363 4.30009 4.36429L12.0001 13.7143L19.7001 4.36429C19.89 4.13363 20.1732 4 20.472 4C21.3171 4 21.7812 4.98334 21.2439 5.63571L13.0001 15.6461V20H17.3334C17.8857 20 18.3334 20.4477 18.3334 21C18.3334 21.5523 17.8857 22 17.3334 22H6.66675C6.11447 22 5.66675 21.5523 5.66675 21C5.66675 20.4477 6.11447 20 6.66675 20H11.0001Z"
      fill="#99D0AC"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M16.7062 8H7.29443L12.0003 13.7143L16.7062 8Z"
      fill="#229944"
    />
  </svg>
)

export default Alkohol
