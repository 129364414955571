import React, { useState } from 'react'
import { LabelV2 } from 'components/Fields/styles'
import ValidationWrapper from 'components/Fields/ValidationWrapper'
import { EyeClosed, EyeOpen } from 'components/Icons'
import theme from 'theme'
import { FieldContainer, IconContainer, InputWithSpacing } from './styles'

const FormInputField = ({
  htmlFor,
  label,
  errorText,
  touched,
  id,
  name,
  placeholder,
  handleChange,
  handleBlur,
  value,
  isError,
  type = 'text',
  testId,
  containerTestId,
  hint,
}) => {
  const [isPassVisible, setPassVisible] = useState(false)

  return (
    <FieldContainer data-test-id={containerTestId}>
      {label && <LabelV2 htmlFor={htmlFor || id}>{label}</LabelV2>}
      <ValidationWrapper hint={hint} errorText={errorText} isTouched={touched}>
        <InputWithSpacing
          id={id}
          data-test-id={testId}
          name={name || id}
          type={isPassVisible ? 'text' : type}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          isError={isError}
        />
        {type === 'password' && (
          <IconContainer
            $isError={isError}
            $isPassVisible={isPassVisible}
            onClick={() => setPassVisible(!isPassVisible)}
          >
            {isPassVisible ? (
              <EyeClosed
                color={isError ? theme.colors.redLight : theme.colors.primary}
              />
            ) : (
              <EyeOpen
                color={isError ? theme.colors.redLight : theme.colors.gray5}
              />
            )}
          </IconContainer>
        )}
      </ValidationWrapper>
    </FieldContainer>
  )
}

export default FormInputField
