import React, { useMemo } from 'react'
import { getBadgeProductLabels } from 'components/Product/Badge/utils'
import LabelsRow from 'views/ProductDetails/CartControlsSection/ProductFeatures/LabelsRow'
import PromoBox from 'views/ProductDetails/CartControlsSection/ProductFeatures/PromoBox'
import { FeaturesContainer } from 'views/ProductDetails/CartControlsSection/ProductFeatures/styles'

const ProductFeatures = ({ product }) => {
  const { promotion, features } = product

  const labels = useMemo(
    () => {
      let result = getBadgeProductLabels(features)

      const discount = promotion?.reward?.discountPercentage
      if (discount) {
        result = [`-${discount}%`, ...result]
      }

      return result
    },
    [promotion, features],
  )

  if (!labels.length && !promotion) {
    return null
  }

  return (
    <FeaturesContainer>
      <LabelsRow labels={labels} />
      <PromoBox product={product} />
    </FeaturesContainer>
  )
}

export default ProductFeatures
