import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { PAYMENT_COLORS, PAY_SCENARIO } from 'containers/Orders/consts'

import { getInvoiceExpiryDays } from 'views/Invoices/utils'
import { useGetSubtitleFromTradeLimit } from 'views/Dashboard/Invoices/utils'
import { TEXT_COLOR_BY_VARIANT, VARIANT } from 'views/Dashboard/Invoices/consts'
import OverdueTile from '../../Tile/OverdueTile'
import useOverdueInvoiceHandler from '../../Tile/OverdueTile/useOverdueInvoiceHandler'
import messages from '../../messages'
import InvoicesActions from '../../ActionBar/InvoicesActions'
import {
  Line,
  LinesGroup,
  UnpaidInvoicesCount,
  Overpayments,
  CreditLimit,
} from '../../Lines'
import CartActions from '../../ActionBar/CartActions'

const Overdue = ({
  tradeLimit,
  invoicesSummary,
  footer,
  title,
  subtitle,
  subtitleColor,
  hideCreditLimit,
}) => {
  const { formatMessage } = useIntl()
  const handlePayInvoices = useOverdueInvoiceHandler()
  const { scenario, paymentColorName } = tradeLimit
  const isGreenClient = paymentColorName === PAYMENT_COLORS.GREEN
  const hasCartIssue = scenario !== PAY_SCENARIO.GREEN
  const isRedScenario = scenario === PAY_SCENARIO.RED
  const { overdue, unblockableOrdering } = invoicesSummary
  const { invoicesCount, closestInvoiceDeadlineDate } = overdue
  const remainingDays = Math.abs(
    getInvoiceExpiryDays(closestInvoiceDeadlineDate),
  )

  const footerElement = useMemo(
    () => {
      if (footer) return footer

      if (isGreenClient) {
        if (!hasCartIssue) {
          return (
            <InvoicesActions
              variant={VARIANT.GREEN}
              onPayInvoices={handlePayInvoices}
            />
          )
        }

        return <CartActions scenario={scenario} />
      }

      if (isRedScenario) {
        return <CartActions scenario={scenario} />
      }

      return (
        <InvoicesActions
          variant={VARIANT.YELLOW}
          message={formatMessage(messages.requiresApproval)}
          onPayInvoices={handlePayInvoices}
        />
      )
    },
    [
      isGreenClient,
      isRedScenario,
      handlePayInvoices,
      footer,
      hasCartIssue,
      scenario,
    ],
  )

  const titleOverdueCart = useMemo(
    () =>
      formatMessage(messages.titleOverdueCart, {
        invoicesCount,
        invoicesLabel: formatMessage(messages.overdueCount, {
          number: invoicesCount,
        }),
      }),
    [invoicesCount],
  )

  const titleElement = useMemo(
    () => {
      if (title) return title

      if (isGreenClient) {
        if (hasCartIssue) {
          return titleOverdueCart
        }
      }

      if (isRedScenario) {
        return titleOverdueCart
      }

      return undefined
    },
    [title, isRedScenario, isGreenClient, hasCartIssue, titleOverdueCart],
  )

  const {
    subtitle: tradeLimitSubtitle,
    subtitleColor: tradeLimitSubtitleColor,
  } = useGetSubtitleFromTradeLimit({ scenario })

  const [subtitleElement, subtitleTextColor] = useMemo(
    () => {
      if (subtitle) return [subtitle, subtitleColor]

      if (isGreenClient) {
        return [tradeLimitSubtitle, tradeLimitSubtitleColor]
      }

      if (isRedScenario) {
        return [
          formatMessage(messages.subtitleCartLimit),
          TEXT_COLOR_BY_VARIANT[VARIANT.RED],
        ]
      }

      return [undefined, TEXT_COLOR_BY_VARIANT[VARIANT.YELLOW]]
    },
    [
      subtitle,
      isGreenClient,
      tradeLimitSubtitle,
      tradeLimitSubtitleColor,
      isRedScenario,
      subtitleColor,
    ],
  )

  return (
    <OverdueTile
      invoicesSummary={invoicesSummary}
      subtitleColor={subtitleTextColor}
      footer={footerElement}
      title={titleElement}
      subtitle={subtitleElement}
    >
      <LinesGroup isFirst isLast>
        {!hideCreditLimit && (
          <>
            {!unblockableOrdering && (
              <Line
                boldValue
                testID="days-till-blockage-line"
                label={formatMessage(messages.dateTillBlockage)}
                value={remainingDays}
              />
            )}
            <CreditLimit
              tradeLimit={tradeLimit}
              hasHighlight={tradeLimit.tradeLimit === 0}
            />
          </>
        )}
        <UnpaidInvoicesCount invoicesSummary={invoicesSummary} />
        <Overpayments invoicesSummary={invoicesSummary} />
      </LinesGroup>
    </OverdueTile>
  )
}

export default Overdue
