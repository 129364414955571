import styled from 'styled-components'

export const ProgressOuter = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
`

export const ProgressInner = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  top: 0;
  border-radius: 8px;
`
