import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import jwtDecode from 'jwt-decode'

import { API_URL } from 'consts'
import {
  LOGIN_ERRORS,
  loginActions,
  logout,
  LOGOUT_REASONS,
} from 'containers/Auth'
import { cameliseDeep } from 'utils'
import {
  getRefreshToken,
  storeAuthInfo,
  getAuthToken,
} from 'containers/Auth/tokenStorage'
import { isJwtTokenExpired } from './utils'
import pkg from '../../../package.json'

const axiosConfig = {
  baseURL: API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'App-Version': pkg.version,
    Platform: 'web',
  },
  timeout: 10000 * 6, // 60 seconds
}

const refreshApi = axios.create(axiosConfig)
const baseApi = axios.create(axiosConfig)
baseApi.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${getAuthToken()}`
  return request
})

export const tryRefreshToken = async (failedRequest, store, rtkApi) => {
  console.log('[tryRefreshToken] Unauthorized....')
  console.log('[tryRefreshToken] Token is refreshing, waiting...')

  const refreshToken = getRefreshToken()
  console.log(
    '[tryRefreshToken] Refresh token has been retrieved',
    refreshToken,
  )

  if (!refreshToken) {
    console.log('[tryRefreshToken] Refresh token is invalid, logging out...')

    return store.dispatch(
      logout(null, { reason: LOGOUT_REASONS.SESSION_EXPIRED }),
    )
  }

  if (isJwtTokenExpired(refreshToken)) {
    console.log('[tryRefreshToken] Refresh token has expired, logging out...')

    return store.dispatch(
      logout(null, { reason: LOGOUT_REASONS.SESSION_EXPIRED }),
    )
  }

  try {
    const { customer_no: oldCustomerNo } = jwtDecode(refreshToken)
    console.log(
      '[tryRefreshToken] Acquiring a new Auth Token based on the Refresh Token',
    )

    const refreshTokenResponse = await refreshApi.post('auth/refresh_token', {
      refresh_token: refreshToken,
    })

    const refreshTokenData = cameliseDeep(refreshTokenResponse.data)

    console.log(
      '[tryRefreshToken] Auth Token has been received',
      refreshTokenData,
    )

    storeAuthInfo(refreshTokenData)
    store.dispatch(loginActions.success(refreshTokenData))

    const { customer_no: newCustomerNo } = jwtDecode(refreshTokenData.authToken)
    if (oldCustomerNo !== newCustomerNo) {
      console.log(
        '[tryRefreshToken] Reseting data after external location change',
      )

      store.dispatch(rtkApi.util.resetApiState())
    }

    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `Bearer ${
      refreshTokenData.authToken
    }`

    return null
  } catch (error) {
    // 401 is handled by handleRefreshFailed, skip here
    if (error?.response?.status === 401) {
      return null
    }

    console.log('[tryRefreshToken] Error fetching Auth Token:', error)
    throw new Error(error)
  }
}

const handleRefreshFailed = async store => {
  try {
    console.log(
      '[tryRefreshToken] Failed refreshing auth token, logging out...',
    )

    store.dispatch(logout(null, { reason: LOGOUT_REASONS.SESSION_EXPIRED }))
  } catch (error) {
    console.log('[tryRefreshToken] Error refreshing Auth Token:', error)
    throw new Error(error)
  }
}

const shouldRefresh = (error, statusCodes = [401]) => {
  const errorCode = error.response?.data?.errors?.[0]?.code
  const includesErrorStatus = statusCodes.includes(error.response?.status)

  return errorCode !== LOGIN_ERRORS.INVALID_CREDS && includesErrorStatus
}

const makeApiWithReauth = (store, rtkApi) => {
  createAuthRefreshInterceptor(
    baseApi,
    error => tryRefreshToken(error, store, rtkApi),
    { shouldRefresh: error => shouldRefresh(error) },
  )
  createAuthRefreshInterceptor(refreshApi, () => handleRefreshFailed(store), {
    shouldRefresh: error => shouldRefresh(error),
  })

  return baseApi
}

export default makeApiWithReauth
