import { get } from 'lodash/fp'

import { useOfMaxWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import {
  DESKTOP_HEIGHT,
  MOBILE_HEIGHT,
} from 'containers/App/LoggedInLayout/Header/styles'
import { HEADER_ID } from 'containers/App/LoggedInLayout/Header/consts'

export const scrollElementIntoView = (id, yOffset) => {
  const element = document.getElementById(id)
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

  window.scrollTo({ top: y, behavior: 'smooth' })
}

export const getTopHeaderOffset = () =>
  get('offsetHeight')(document.getElementById(HEADER_ID))

export const useScrollElementIntoView = id => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const headerHeight = isMobile ? MOBILE_HEIGHT : DESKTOP_HEIGHT

  return () => scrollElementIntoView(id, -headerHeight)
}
