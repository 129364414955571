import React from 'react'
import theme from 'theme'

const SugarFree = ({ color = theme.colors.primary }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9993 10.965C13.9998 10.9519 14.0001 10.9388 14.0001 10.9256L14.0001 5.07438C14.0001 4.71871 13.8112 4.38981 13.504 4.2106L8.50396 1.29394C8.1926 1.11231 7.80758 1.11231 7.49622 1.29394L3.0185 3.90594L1.40566 2.86912C1.05723 2.64513 0.593197 2.746 0.369207 3.09443C0.145218 3.44286 0.246096 3.90689 0.594523 4.13088L14.5945 13.1309C14.943 13.3549 15.407 13.254 15.631 12.9056C15.855 12.5571 15.7541 12.0931 15.4057 11.8691L13.9993 10.965ZM8.71749 7.56958L12.0017 5.59907C12.1321 5.52083 12.1309 5.33144 11.9995 5.25481L8.10087 2.98058C8.03859 2.94426 7.96159 2.94426 7.89932 2.98058L4.58578 4.91348L8.71749 7.56958ZM3.66009 7.64245C3.66009 7.5744 3.62549 7.51102 3.56825 7.47422L2.30825 6.66418C2.17514 6.57861 2.00009 6.67418 2.00009 6.83241L2.00009 10.9256C2.00009 11.2813 2.189 11.6102 2.49622 11.7894L7.49622 14.7061C7.80758 14.8877 8.1926 14.8877 8.50396 14.7061L11.532 12.9397C11.661 12.8645 11.665 12.6795 11.5394 12.5988L8.30825 10.5215C8.17514 10.4359 8.00009 10.5315 8.00009 10.6897V12.73C8.00009 12.8844 7.83265 12.9805 7.69932 12.9028L3.75932 10.6044C3.69787 10.5686 3.66009 10.5028 3.66009 10.4317L3.66009 7.64245Z"
      fill={color}
      className="primaryFill"
    />
  </svg>
)

export default SugarFree
