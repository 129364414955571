import { get } from 'lodash/fp'
import { useDispatch, useSelector } from 'react-redux'

import { rtkApi, useGetStorageQuery } from 'services/api/rtkApi'
import { useEffect } from 'react'
import { migrateStorageIfNeeded } from './thunks'

export * from './consts'

export const useFetchStorage = () => {
  const dispatch = useDispatch()
  const { data: storageData } = useGetStorageQuery()
  const storage = storageData?.data

  useEffect(
    () => {
      if (storage) {
        dispatch(migrateStorageIfNeeded(storage))
      }
    },
    [storage, dispatch],
  )
}

export const useGetStorageValue = path => {
  const { data: storage } = useSelector(rtkApi.endpoints.getStorage.select())
  return get(path)(storage?.data)
}
