import { handleActions } from 'redux-actions'

import { createReducer, initialState } from 'utils/simpleDataReducer'
import { updateCartItem, deleteCartItem } from 'containers/Cart/actions'
import { templateStatusChange } from 'containers/Templates/actions'
import { resetProductUnitsOfMeasureInCart } from 'containers/Products/reducer'

import { getAllPromoGroupsActions } from './actions'

const allPromoGroupsReducer = createReducer(getAllPromoGroupsActions)
const allPromoGroupsHandlers = handleActions(
  {
    [updateCartItem.SUCCESS]: (state, { data: { cartItem } }) => {
      let updState = state
      const groups = state.get('data') || []
      const isPromoItem = !!cartItem?.product.promotion

      if (isPromoItem) {
        const productId = cartItem.product.id
        const groupIndex = groups.findIndex(
          g => g.getIn(['product', 'id']) === productId,
        )

        if (groupIndex !== -1) {
          const groupProduct = groups.getIn([groupIndex, 'product'])
          updState = state.mergeIn(
            ['data', groupIndex, 'product'],
            groupProduct.merge(cartItem.product),
          )
        }
      }

      return updState
    },
    [deleteCartItem.SUCCESS]: (
      state,
      { data: { cartItem }, additionalData },
    ) => {
      let updState = state

      if (!cartItem) {
        const groups = state.get('data') || []
        const groupIndex = groups.findIndex(
          g => g.getIn(['product', 'id']) === additionalData.productId,
        )

        if (groupIndex !== -1) {
          const groupProduct = groups.getIn([groupIndex, 'product'])
          updState = state.mergeIn(
            ['data', groupIndex, 'product', 'unitsOfMeasure'],
            resetProductUnitsOfMeasureInCart(
              groupProduct.get('unitsOfMeasure'),
              additionalData.unitOfMeasure,
            ),
          )
        }
      }

      return updState
    },
    [templateStatusChange]: (state, { payload }) => {
      let updState = state
      const groups = state.get('data') || []
      const groupIndex = groups.findIndex(
        g => g.getIn(['product', 'id']) === payload.id,
      )

      if (groupIndex !== -1) {
        updState = state.mergeIn(['data', groupIndex, 'product'], payload)
      }

      return updState
    },
  },
  initialState,
)

export default (state, action) => {
  const newState = allPromoGroupsReducer(state, action)
  return allPromoGroupsHandlers(newState, action)
}
