import React from 'react'
import { FormattedMessage } from 'react-intl'
import { contains } from 'lodash/fp'

import { ALERT_TYPE } from 'components/Alert'
import { BookmarkAdd } from 'components/Icons'
import { ProductLongBtn } from 'components/Product/ButtonsContainer/styles'

import messages from '../messages'

import { AddToTemplateBtn, InTemplateContainer } from './styles'

const TemplateButtons = ({
  templateId,
  product,
  unitOfMeasure,
  handleAddToTemplate,
  handleRemoveFromTemplate,
  showNotification,
  onGetTemplateItems,
}) => {
  const isInTemplate = contains(templateId, unitOfMeasure.templateIds)
  const isVariants = product?.unitsOfMeasure.length > 1

  if (isInTemplate) {
    return (
      <InTemplateContainer
        data-test-id="remove_from_template_btn"
        onClick={() =>
          handleRemoveFromTemplate({
            product,
            unitOfMeasure,
            onSuccess: () => {
              showNotification({
                notificationMessageProp: (
                  <FormattedMessage {...messages.removedNotification} />
                ),
                notificationTypeProp: ALERT_TYPE.SUCCESS,
              })
              onGetTemplateItems()
            },
          })
        }
        {...{ isVariants }}
      >
        <ProductLongBtn textColor="secondary">
          <FormattedMessage {...messages.removeFromTemplateBtn} />
        </ProductLongBtn>
      </InTemplateContainer>
    )
  }

  return (
    <AddToTemplateBtn
      data-test-id="add_to_template_btn"
      onClick={() =>
        handleAddToTemplate({
          product,
          unitOfMeasure,
          onSuccess: () => {
            showNotification({
              notificationMessageProp: (
                <FormattedMessage {...messages.addedNotification} />
              ),
              notificationTypeProp: ALERT_TYPE.SUCCESS,
            })
            onGetTemplateItems()
          },
        })
      }
      {...{ isVariants }}
    >
      <FormattedMessage {...messages.addToTemplateBtn} />
      <BookmarkAdd />
    </AddToTemplateBtn>
  )
}

export default TemplateButtons
