export const get = () => ({ url: 'me/agreements' })

export const update = ({ id, value }) => {
  const options = {
    method: 'PUT',
    body: { value },
  }

  return {
    url: `me/agreements/${id}`,
    options,
  }
}
