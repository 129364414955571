import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  description: 'description',
  passwordShould: 'passwordShould',
  submit: 'submit',
  password: { id: 'login.passwordLabel' },
  succeeded: 'succeeded',
  youCanLogin: 'youCanLogin',
  toLogin: 'toLogin',
  emptyField: { id: 'login.emptyField' },
  invalid: 'invalid',
  invalidDescription: 'invalidDescription',
  expired: 'expired',
  expiredDescription: 'expiredDescription',
  sendNewLink: 'sendNewLink',
  resend: 'resend',
  resendDescription: 'resendDescription',
  alreadyUsed: 'alreadyUsed',
  alreadyUsedDescription: 'alreadyUsedDescription',
}

export default makeMessages(messages, makeTranslation('setPassword'))
