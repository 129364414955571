import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { PAY_SCENARIO } from 'containers/Orders/consts'

import { useGetSubtitleFromTradeLimit } from 'views/Dashboard/Invoices/utils'
import { VARIANT } from 'views/Dashboard/Invoices/consts'
import Tile from '../../Tile'
import messages from '../../messages'
import Footer from '../../ActionBar/Footer'
import CartActions from '../../ActionBar/CartActions'
import { LinesGroup, Overpayments, CreditLimit } from '../../Lines'

const AllPaid = ({ tradeLimit, invoicesSummary, footer }) => {
  const { formatMessage } = useIntl()
  const { scenario } = tradeLimit
  const hasCartIssue = scenario !== PAY_SCENARIO.GREEN

  const title = hasCartIssue
    ? formatMessage(messages.titlePaidCredit)
    : formatMessage(messages.titlePaid)

  const { subtitleColor, subtitle } = useGetSubtitleFromTradeLimit({
    scenario,
    defaultMessage: formatMessage(messages.subtitlePaid),
  })

  const footerElement = useMemo(
    () => {
      if (footer) return footer

      if (hasCartIssue) {
        return <CartActions scenario={scenario} />
      }

      return <Footer variant={VARIANT.GREY} />
    },
    [hasCartIssue, scenario, footer],
  )

  return (
    <Tile
      title={title}
      subtitle={subtitle}
      footer={footerElement}
      subtitleColor={subtitleColor}
    >
      <LinesGroup isFirst isLast>
        <CreditLimit tradeLimit={tradeLimit} />
        <Overpayments invoicesSummary={invoicesSummary} />
      </LinesGroup>
    </Tile>
  )
}

export default AllPaid
