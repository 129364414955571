import styled from 'styled-components'

import theme from 'theme'
import { InputV2 } from 'components/Fields/styles'

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.xs};
`

export const InputWithSpacing = styled(InputV2)`
  ${p => p.type === 'password' && 'padding-right: 40px'};
  filter: none;
  :autofill {
    background: ${p =>
      p.isError ? theme.colors.redLight2 : theme.colors.greenLight3};
  }
  :-webkit-autofill {
    box-shadow: ${p =>
      p.isError
        ? `0 0 0 50px ${theme.colors.redLight2} inset !important`
        : `0 0 0 50px ${theme.colors.greenLight3} inset !important`}};
  }
`

export const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 12px;
`
