import React from 'react'
import { FormattedMessage } from 'react-intl'

import { makeMessages, makeTranslation, FormattedRichMessage } from 'utils'

import deliveryImg from 'assets/hintsImgs/delivery2.png'

const messages = makeMessages(
  {
    header: 'header',
    info: 'info',
  },
  makeTranslation('hintModal.incomingProducts'),
)

const header = <FormattedMessage {...messages.header} />

export const hintModalConfig = [
  {
    header,
    img: deliveryImg,
    info: <FormattedRichMessage {...messages.info} />,
  },
]
