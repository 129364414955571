import styled from 'styled-components'

import { PageTitleDivider } from 'components/Text'
import { ContentWrapper } from 'components/Layout'
import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'

export const Container = styled(ContentWrapper)`
  width: 100%;
`

export const ItemsGrid = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  gap: ${theme.spacing.sm};

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${theme.spacing.base};
  }
`
export const Divider = styled(PageTitleDivider)`
  margin: ${theme.spacing.sm} -${theme.spacing.sm} ${theme.spacing.base};

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin: ${theme.spacing.sm} -${theme.spacing.md} ${theme.spacing.base};
  }
`
