import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  description: 'description',
  contactData: 'contactData',
  deliveryAddress: { id: 'orderDetails.deliveryAddress' },
  customerNo: 'customerNo',
  customerName: 'customerName',
  customerFirstName: 'customerFirstName',
  customerLastName: 'customerLastName',
  phoneNo: 'phoneNo',
  consentsTitle: { id: 'profile.consents.title' },
  terms: 'terms',
  termsContent: 'termsContent',
  termsRequired: 'termsRequired',
  regulationsContent: { id: 'profile_consent.regulations_content' },
  newsletter: 'newsletter',
  customerVatId: 'customerVatId',
  emailAddress: 'emailAddress',
  deliveryPostCode: 'deliveryPostCode',
  deliveryCountryCode: 'deliveryCountryCode',
  deliveryCity: 'deliveryCity',
  submit: 'submit',
  agreement_1: 'agreement1',
  agreement_2: 'agreement2',
  agreement_3: 'agreement3',
  optional: 'optional',
  newsletterContent: 'newsletterContent',
  acceptMarketing: 'acceptMarketing',
  marketing: { id: 'profile_consent.marketing' },
  marketingContent: 'marketingContent',
  emptyField: { id: 'login.emptyField' },
  invalidEmail: 'error.invalidEmail',
  invalidVatId: 'error.invalidVatId',
  required: 'required',
  emailUsed: 'emailUsed',
  emailUsedForReg: 'emailUsedForReg',
  emailNotVerified: 'emailNotVerified',
  emailUsedForRegMsg: 'emailUsedForRegMsg',
  accountExists: 'accountExists',
  backToForm: 'backToForm',
  toLogin: { id: 'setPassword.toLogin' },
  phoneInvalid: 'phoneInvalid',
  somethingWentWrong: { id: 'modal.somethingWentWrong' },
  tryAgain: { id: 'error.tryAgain' },
  termsLink: 'termsLink',
  privacyLink: 'privacyLink',
  login: 'login',
  loginPlaceholder: 'loginPlaceholder',
  businessDetails: 'businessDetails',
  customerVatIdPlaceholder: 'customerVatIdPlaceholder',
  customerNamePlaceholder: 'customerNamePlaceholder',
  customerNoPlaceholder: 'customerNoPlaceholder',
  customerFirstNamePlaceholder: 'customerFirstNamePlaceholder',
  customerLastNamePlaceholder: 'customerLastNamePlaceholder',
  emailAddressPlaceholder: 'emailAddressPlaceholder',
  phoneNoPlaceholder: 'phoneNoPlaceholder',
  streetAndNumber: 'streetAndNumber',
  streetAndNumberPlaceholder: 'streetAndNumberPlaceholder',
  deliveryPostCodePlaceholder: 'deliveryPostCodePlaceholder',
  deliveryCityPlaceholder: 'deliveryCityPlaceholder',
  loginTxt: 'loginTxt',
  loginInvalid: 'error.invalidLogin',
  blank: { id: 'validators.blank' },
  loginAlreadyTaken: 'error.loginTaken',
  tooShort: { id: 'password.tooShort' },
  markAllAgreements: 'markAllAgreements',
  acceptRequired: 'acceptRequired',
  profile: 'profile',
  profilePlaceholder: 'profilePlaceholder',
  profileConfirm: 'profileConfirm',
  otherProfile: 'otherProfile',
  otherProfilePlaceholder: 'otherProfilePlaceholder',
  otherProfileHint: 'otherProfileHint',
  customerVatIdHint: 'customerVatIdHint',
}

export default makeMessages(messages, makeTranslation('signUp'))
