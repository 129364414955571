import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { getColors } from 'components/helpers/theme-selectors'

import { AlternateBtn } from 'components/Button'

export const DeleteAllBtn = styled(AlternateBtn)`
  color: ${props => getColors(props).error};
  :hover {
    color: ${props => getColors(props).error};
    border: 1px solid ${props => getColors(props).error};
  }
  figure {
    color: ${props => getColors(props).error};
    margin-right: 16px;
    :hover {
      color: ${props => getColors(props).error};
    }
  }
`

export const FooterButtons = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
`
