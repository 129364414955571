import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getColors } from 'components/helpers/theme-selectors'
import { APP_BREAKPOINTS } from 'consts'

// prettier-ignore
export const Column = styled(Flex)`
  flex-flow: column;
  justify-content: center;

  ${p => p.pullTop && `
    justify-content: flex-start;
  `}

  ${p => p.borderedLeft && `
    border-left: 1px solid ${getColors(p).gray2};
  `}
`

export const HeadingColumn = styled(Column)`
  width: 338px;
  flex-shrink: 0;
`

export const TailColumn = styled(Column)`
  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    width: 338px;
  }
  flex-shrink: 0;
`
