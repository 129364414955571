import React from 'react'
import { Field } from 'redux-form/immutable'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormattedMessage } from 'react-intl'

import { isInvalid } from 'utils/redux-form-utils'

import { HintBox } from '../../Input/styles'

const Captcha = ({ meta, input: { onChange }, siteKey }) => (
  <div style={{ marginTop: 24 }}>
    <ReCAPTCHA
      sitekey={siteKey}
      onChange={onChange}
      onExpired={() => onChange('')}
      hl="pl"
    />

    {isInvalid(meta) && (
      <HintBox isError>
        <FormattedMessage {...meta.error} />
      </HintBox>
    )}
  </div>
)

const Recaptcha = ({ component = Captcha, ...rest }) => (
  <Field component={component} {...rest} />
)

export default Recaptcha
