import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from './messages'
import { Optional } from './styledComponents'

const CheckBoxText = ({ name, required }) => (
  <React.Fragment>
    <FormattedMessage {...messages[name]} />
    {!required && (
      <React.Fragment>
        &nbsp;
        <Optional>
          <FormattedMessage {...messages.optional} />
        </Optional>
      </React.Fragment>
    )}
  </React.Fragment>
)

export default CheckBoxText
