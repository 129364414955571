import { combineReducers } from 'redux-immutable'
import { handleActions } from 'redux-actions'

import { reducer, initialState, createReducer } from 'utils/simpleDataReducer'

import {
  loginActions,
  signUpActions,
  signUpMigrateActions,
  confirmEmailActions,
  resendConfirmEmailActions,
  validateTokenActions,
  resendSetPasswordActions,
  logoutReason,
  checkLoginActions,
} from './actions'
import {
  LOGIN_REDUCER_NAME,
  CONFIRM_EMAIL_REDUCER_NAME,
  SIGN_UP_REDUCER_NAME,
  SIGN_UP_MIGRATE_REDUCER_NAME,
  RESEND_CONFIRM_REDUCER_NAME,
  VALIDATE_TOKEN_REDUCER_NAME,
  RESEND_SET_PASSWORD_REDUCER_NAME,
  LOGOUT_REASON_REDUCER_NAME,
  CHECK_LOGIN_REDUCER_NAME,
} from './consts'

// eslint-disable-next-line default-param-last
export const loginReducer = (state = initialState, action) =>
  reducer(state, action, loginActions)

const logoutReasonReducer = handleActions(
  {
    [logoutReason]: (state, { payload }) => payload,
    [loginActions.REQUEST]: () => null,
  },
  null,
)

export default combineReducers({
  [SIGN_UP_REDUCER_NAME]: createReducer(signUpActions),
  [SIGN_UP_MIGRATE_REDUCER_NAME]: createReducer(signUpMigrateActions),
  [CONFIRM_EMAIL_REDUCER_NAME]: createReducer(confirmEmailActions),
  [LOGIN_REDUCER_NAME]: loginReducer,
  [RESEND_CONFIRM_REDUCER_NAME]: createReducer(resendConfirmEmailActions),
  [VALIDATE_TOKEN_REDUCER_NAME]: createReducer(validateTokenActions),
  [RESEND_SET_PASSWORD_REDUCER_NAME]: createReducer(resendSetPasswordActions),
  [LOGOUT_REASON_REDUCER_NAME]: logoutReasonReducer,
  [CHECK_LOGIN_REDUCER_NAME]: createReducer(checkLoginActions),
})
