import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { FormattedMessage } from 'react-intl'

import theme from 'theme'

import { TertiaryText } from 'components/Text'
import { Bill, User } from 'components/Icons'

import { BoldHeader, HalfBox, Separator } from '../styles'
import messages from '../messages'

const SecondaryData = styled(TertiaryText)`
  color: ${theme.colors.gray4};
  line-height: 24px;
  margin-top: ${theme.spacing.xs};
`
const InfoWrapper = styled(Flex)`
  flex-direction: column;
  margin-left: ${theme.spacing.sm};
`

const BillingData = ({
  firstName,
  lastName,
  customerEmail,
  phoneNo,
  billingName,
  billingAddress,
  billingPostcode,
  billingCity,
  billingVatNo,
  isMobile,
}) => (
  <Flex
    flexDirection={isMobile ? 'column' : 'row'}
    py={isMobile ? theme.spacing.xs : 0}
  >
    <HalfBox>
      <BoldHeader>
        <FormattedMessage {...messages.purchaser} />
      </BoldHeader>

      <Flex mt={theme.spacing.base}>
        <User />

        <InfoWrapper>
          <TertiaryText bold>
            {firstName} {lastName}
          </TertiaryText>

          <SecondaryData>
            {customerEmail} <br /> {phoneNo}
          </SecondaryData>
        </InfoWrapper>
      </Flex>
    </HalfBox>

    {isMobile && <Separator />}

    <HalfBox withPaddingLeft={!isMobile}>
      <BoldHeader>
        <FormattedMessage {...messages.billingData} />
      </BoldHeader>

      <Flex mt={theme.spacing.base}>
        <Bill />

        <InfoWrapper>
          <TertiaryText bold>{billingName}</TertiaryText>

          <SecondaryData>
            {billingAddress}
            <br />
            {billingPostcode} {billingCity}
            <br />
            <FormattedMessage
              {...messages.nip}
              values={{ value: billingVatNo }}
            />
          </SecondaryData>
        </InfoWrapper>
      </Flex>
    </HalfBox>
  </Flex>
)

export default BillingData
