import styled from 'styled-components'
import { Box } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

export const IncomingContainer = styled(Box)`
  padding-left: 120px;
  border-bottom: 1px solid ${theme.colors.gray2};

  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    padding: 0;
  }
`
