import styled from 'styled-components'

import theme from 'theme'

import { TEMPLATES_MOBILE_MAX_WIDTH } from '../../consts'
import { DotsMenuBtn } from './TemplateMenu/styles'

export const Container = styled.li`
  cursor: pointer;
  list-style: none;
  position: relative;
  flex-grow: 1;

  ${p =>
    p.isMenuOpen &&
    `
    a {
      background-color: ${theme.colors.lightGrey};
    }

    ${DotsMenuBtn} {
      visibility: visible;
    }
  `} &:hover {
    ${DotsMenuBtn} {
      visibility: visible;
    }
  }

  @media screen and (max-width: ${TEMPLATES_MOBILE_MAX_WIDTH}) {
    a {
      width: 100%;
    }

    ${DotsMenuBtn} {
      visibility: visible;
      position: relative;
      right: -4px;
      top: 0;

      &:after {
        display: none;
      }
    }
  }
`

export const Content = styled.div``

export const Inner = styled.div`
  display: flex;
  flex: 1;
`

export const EditInputContainer = styled.div`
  margin-bottom: ${theme.spacing.xs};
`
