import React from 'react'
import PropTypes from 'prop-types'

import { NormalIcon, ICONS } from 'components/Icon'
import theme from 'theme'

import { Error, ErrorMessage } from './styledComponents'

const ServerError = ({ message }) => (
  <Error>
    <div>
      <NormalIcon
        iconSvgProps={{ stroke: theme.colors.white }}
        type={ICONS.ERROR}
      />
    </div>
    <ErrorMessage>{message}</ErrorMessage>
  </Error>
)

ServerError.propTypes = {
  message: PropTypes.node,
}

export default ServerError
