import { makeMessages, makeTranslation } from 'utils'

const messages = {
  promotions: 'promotions',
  viewFull: 'viewFull',
  title: 'title',
  cuisines: {
    id: 'filter.cuisines',
  },
  mainPageLink: {
    id: 'navbar.home',
  },
  allProducts: {
    id: 'products.header.allProducts',
  },
}

export default makeMessages(messages, makeTranslation('categories'))
