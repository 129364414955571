import React from 'react'

import { Brand, ProductId, BrandSection } from './style'

const BrandAndReference = ({ brand, id }) => {
  const hasBrand = brand && brand !== '...'

  return (
    <BrandSection>
      {hasBrand && <Brand strongBold>{brand}</Brand>}
      <ProductId bold>{id}</ProductId>
    </BrandSection>
  )
}

export default BrandAndReference
