import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ROUTES } from 'consts'
import { AlternateBtn } from 'components/Button'
import { P } from 'components/Text'
import { Link } from 'components/Navigation'

import Notification from '../Notification'
import messages from './messages'

const Verified = () => (
  <Notification header={messages.alreadyVerified}>
    <FormattedMessage {...messages.alreadyVerifiedDescription} />

    <Link to={ROUTES.LOGIN} style={{ width: '100%', marginTop: '24px' }}>
      <AlternateBtn $fullWidth>
        <P bold interactive>
          <FormattedMessage {...messages.toLogin} />
        </P>
      </AlternateBtn>
    </Link>
  </Notification>
)

export default Verified
