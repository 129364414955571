import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'
import {
  flow,
  get,
  map,
  some,
  sum,
  groupBy,
  values,
  omit,
  toPairs,
} from 'lodash/fp'

import { useOfMaxWidth } from 'hooks'

import { getPlainCartProducts } from 'containers/Cart/selectors'
import { Truck2, Truck3, TruckThis } from 'components/Icons'
import { APP_BREAKPOINTS } from 'consts'

import ProductList from '../List'

import DeliveryTimeSlots from './DeliveryTimeSlots'
import IncomingOrdersExpandable from './IncomingOrdersExpandable'
import { Container } from './styles'

const DeliveryProducts = ({
  delivery: selectedDelivery,
  delivery: {
    deliveryDate,
    routeId,
    items = [],
    incOrders = [],
    isFirstIncomingDelivery,
    isFirstDelivery,
  },
  dateChanging,
  toggleDateChanging,
  toggleDateProductIdChanging,
  routeChanging,
  toggleRouteChanging,
}) => {
  const { formatMessage } = useIntl()
  // TODO refactor, simplify detecting firstOther etc
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TABLET_START - 1)
  const plainProducts = useMemo(() => getPlainCartProducts(items), [items])
  const onlyIncoming = !plainProducts.length && !!incOrders.length
  const itemsNumber = sum([plainProducts.length, incOrders.length])
  const activeProductIds = useMemo(() => map('product.id', items), [items])

  const { sameAsActiveIncDeliveries, otherIncDeliveriesByRoute } = useMemo(
    () => {
      const incominOrdersByRoute = groupBy(get('routeId'), incOrders)

      let sameAsActiveList = []
      let othersGroupped = incominOrdersByRoute

      if (!onlyIncoming) {
        sameAsActiveList = incominOrdersByRoute[routeId] || []
        othersGroupped = omit([routeId], incominOrdersByRoute)
      }

      return {
        sameAsActiveIncDeliveries: sameAsActiveList,
        otherIncDeliveriesByRoute: othersGroupped,
      }
    },
    [incOrders, deliveryDate, onlyIncoming],
  )
  const getIsOtherRoute = useCallback(
    ({ index, no }) =>
      (index === no - 1 && !!plainProducts.length) || index === no,
    [plainProducts],
  )

  const selectedDeliveryDate = useMemo(() => dayjs(deliveryDate), [
    deliveryDate,
  ])

  const multipleRoutesCase = flow(
    toPairs,
    some.convert({ cap: false })((_, index) =>
      getIsOtherRoute({ index, no: 1 }),
    ),
  )(otherIncDeliveriesByRoute)

  return (
    <Container>
      <DeliveryTimeSlots
        {...{
          selectedDelivery,
          onlyIncoming,
          formatMessage,
          itemsNumber,
          dateChanging,
          toggleDateChanging,
          routeChanging,
          toggleRouteChanging,
        }}
        activeProductIds={activeProductIds}
      />

      <Flex width="100%" flexDirection="column">
        <Flex flexDirection="column">
          {!!plainProducts.length && (
            <ProductList
              toggleDateProductIdChanging={toggleDateProductIdChanging}
              selectedDeliveryDate={selectedDeliveryDate}
              selectedRouteId={selectedDelivery.routeId}
              isFirstCartDelivery={selectedDelivery.isFirstCartDelivery}
              items={plainProducts}
            />
          )}

          {!!items.length && (
            <IncomingOrdersExpandable
              withBorder
              incomingOrders={sameAsActiveIncDeliveries}
              openDelivery={{
                items,
                productIds: activeProductIds,
                deliveryDate,
                routeId,
              }}
              CustomTruckIcon={multipleRoutesCase && TruckThis}
              isFirstIncomingDelivery={isFirstIncomingDelivery}
              isFirstDelivery={isFirstDelivery}
            />
          )}

          {values(otherIncDeliveriesByRoute).map(
            (incomingOrdersList, index) => {
              const incomingDeliveriesRouteId = incomingOrdersList[0].routeId

              return (
                <IncomingOrdersExpandable
                  showRouteName
                  key={incomingDeliveriesRouteId}
                  CustomTruckIcon={
                    getIsOtherRoute({ index, no: 2 })
                      ? Truck3
                      : getIsOtherRoute({ index, no: 1 }) && Truck2
                  }
                  withBorder={
                    index === 0 &&
                    !sameAsActiveIncDeliveries.length &&
                    (!isMobile || !onlyIncoming)
                  }
                  isFirstOtherIncoming={getIsOtherRoute({ index, no: 1 })}
                  incomingOrders={incomingOrdersList}
                  isFirstIncomingDelivery={
                    !items.length && isFirstIncomingDelivery
                  }
                />
              )
            },
          )}
        </Flex>
      </Flex>
    </Container>
  )
}

export default DeliveryProducts
