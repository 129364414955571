import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'

import theme from 'theme'

import { useGetStorageValue, CONFIG_SCHEMA_KEYS } from 'containers/Storage'
import { updateDeliveryComment } from 'containers/Cart/actions'

import {
  Comment as ComentIcon,
  CommentEmpty as CommentEmptyIcon,
  Plus as PlusIcon,
  Tick as TickIcon,
} from 'components/Icons'
import { HintOverlay } from 'components'
import messages from 'views/Cart/messages'

import {
  CommentInput,
  CompleteIconContainer,
  CommmentContainer,
  DeleteStyled,
} from './styles'

const { spacing } = theme

const Comment = ({
  setCommentHintIndex,
  selectedDelivery: {
    deliveryDate,
    routeId,
    routeName,
    comment,
    isSecondCartDelivery,
  } = {},
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const commentHintEnabled = useGetStorageValue(
    CONFIG_SCHEMA_KEYS.CART_GUIDE.COMMENTS,
  )
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef(null)

  const [value, setValue] = useState((comment || '').slice(0, 2000))

  useEffect(
    () => {
      setValue((comment || '').slice(0, 2000))
    },
    [comment],
  )
  const onChange = newComment => setValue(newComment.slice(0, 2000))
  const actionArgs = { date: deliveryDate, routeId, routeName }
  const handleSave = () =>
    dispatch(updateDeliveryComment.delta({ ...actionArgs, comment: value }))
  const handleClear = () => {
    setValue('')
    dispatch(updateDeliveryComment.delta({ ...actionArgs, comment: '' }))
  }

  const CommentIconComponent = value ? ComentIcon : CommentEmptyIcon

  const showPlusIcon = !value && !isFocused
  const showCompleteIcon = isFocused
  const showDeleteIcon = !isFocused && !!value

  return (
    <CommmentContainer className="delivery-comment">
      <Flex width={1}>
        <Flex
          justifyContent="center"
          mr={spacing.base}
          ml={spacing.xs}
          mt={`-${spacing.xxs}`}
        >
          <CommentIconComponent />
        </Flex>

        <CommentInput
          data-test-id="comment-input"
          {...{ value, isFocused }}
          ref={inputRef}
          placeholder={
            isFocused ? undefined : formatMessage(messages.addComment)
          }
          onChange={ev => onChange(ev.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false)
            handleSave()
          }}
        />
      </Flex>

      <HintOverlay
        enabled={isSecondCartDelivery && commentHintEnabled}
        ml={theme.spacing.sm}
        onClick={() => {
          if (isSecondCartDelivery && commentHintEnabled) setCommentHintIndex(0)
        }}
      >
        {showPlusIcon && (
          <CompleteIconContainer onClick={() => inputRef.current.focus()}>
            <PlusIcon />
          </CompleteIconContainer>
        )}
        {showCompleteIcon && (
          <CompleteIconContainer
            data-test-id="coment-enter-btn"
            onClick={handleSave}
          >
            <TickIcon />
          </CompleteIconContainer>
        )}
        {showDeleteIcon && (
          <CompleteIconContainer onClick={handleClear}>
            <DeleteStyled />
          </CompleteIconContainer>
        )}
      </HintOverlay>
    </CommmentContainer>
  )
}

export default Comment
