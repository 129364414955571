import theme from 'theme'

export const INVOICES_REDUCER_NAME = 'invoices'
export const INVOICES_LIST_REDUCER_NAME = 'list'
export const PAY_INIT_REDUCER_NAME = 'payInit'
export const PAY_PROCESSING_REDUCER_NAME = 'payProcess'
export const INVOICES_FILTERS_REDUCER_NAME = 'filters'
export const INVOICES_TO_PAY_REDUCER_NAME = 'invoicesToPay'
export const INVOICES_SUMMARY_REDUCER_NAME = 'invoicesSummary'
export const OVERPAYMENT_INVOICES_REDUCER_NAME = 'settlingOverpayments'
export const SETTLE_OVERPAYMENTS_REDUCER_NAME = 'submitOverpaymentsSettlement'

export const INVOICES_PER_PAGE_SIZE = 24

export const INVOICE_STATUS = {
  NOT_PAID: 0,
  NOT_PAID_EXPIRED: 1,
  PAID: 2,
  PAYMENT: 3,
  OVERPAID: 4,
}

export const INVOICE_PAYMENT_STATUS = {
  PROCESSING: 'processing',
}

export const API_INVOICE_STATUS_COLOR = {
  GREEN: 0,
  YELLOW: 1,
  ORANGE: 2,
  RED: 3,
  NAVY: 4,
  GREY: 5,
}

export const INVOICE_STATUS_COLOR = {
  [API_INVOICE_STATUS_COLOR.GREEN]: theme.colors.secondary,
  [API_INVOICE_STATUS_COLOR.YELLOW]: theme.colors.warning,
  [API_INVOICE_STATUS_COLOR.ORANGE]: theme.colors.warningHighlight,
  [API_INVOICE_STATUS_COLOR.RED]: theme.colors.red,
  [API_INVOICE_STATUS_COLOR.NAVY]: theme.colors.primary,
  [API_INVOICE_STATUS_COLOR.GREY]: theme.colors.gray4,
}
