import React from 'react'

import theme from 'theme'

const Globe = ({
  width = 16,
  height = 16,
  color = theme.colors.gray5,
  testId,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-test-id={testId}
  >
    <g clipPath="url(#clip0_26177_2362)">
      <path
        d="M8.0013 14.3332C11.4991 14.3332 14.3346 11.4976 14.3346 7.99984C14.3346 4.50203 11.4991 1.6665 8.0013 1.6665C4.5035 1.6665 1.66797 4.50203 1.66797 7.99984C1.66797 11.4976 4.5035 14.3332 8.0013 14.3332Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66797 8H14.3346"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99818 1.6665C9.58232 3.40079 10.4826 5.65146 10.5315 7.99984C10.4826 10.3482 9.58232 12.5989 7.99818 14.3332C6.41403 12.5989 5.51377 10.3482 5.46484 7.99984C5.51377 5.65146 6.41403 3.40079 7.99818 1.6665Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_26177_2362">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Globe
