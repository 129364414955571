import styled, { keyframes } from 'styled-components'
import Select from 'react-select'

import { getColors } from 'components/helpers/theme-selectors'
import theme from 'theme'

import DotSingle from './DotSingle.svg'
import DotMulti from './DotMulti.svg'

const getBorderSecondary = p => getColors(p).borderSecondary
const getSecondaryColor = p => getColors(p).secondary

/* eslint-disable no-nested-ternary */
// prettier-ignore
export const SelectStyled = styled(Select)`
  width: ${({ width }) => width || '100%'};
  height: ${({ big, small }) => big ? 68 : small ? 40 : 56}px;
  margin: ${({ margin }) => margin};

  .variantSelect__control {
    height: ${({ big, small }) => big ? 68 : small ? 40 : 56}px;
    border: 1px solid ${getBorderSecondary};
    border-radius: initial;
    box-shadow: none;

    :hover {
      cursor: pointer;
      border: 1px solid ${getSecondaryColor};

      * {
        color: ${getSecondaryColor};
      }

      .feather-chevron-down {
        stroke: ${getSecondaryColor};
      }
    }

    :focus {
      border: 1px solid ${getBorderSecondary};
    }
  }

  .variantSelect__menu {
    top: 0;
    margin: 0;
    border: 1px solid ${getBorderSecondary};
    box-shadow: 0px 4px 12px ${getBorderSecondary};

    .variantSelect__menu-list {
      padding: 0;
    }
  }

  .variantSelect__single-value {
    position: relative;
    padding: ${({ big }) => big ? '8px 16px' : '8px 12px'};
    transform: none;
    top: 0;
    margin: 0;
    max-width: 100%;
    overflow: visible;
    margin-right: 1px;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      left: 16px;
      bottom: -2px;
      height: 1px;
      width: calc(100% - 32px);
      background: rgba(221, 226, 229, 0.5);
    }

    :hover {
      cursor: pointer;
      * {
        color: ${getSecondaryColor};
      }
    }
  }

  .variantSelect__value-container {
    height: ${({ big, small }) => big ? 68 : small ? 40 : 56}px;
    padding: ${({ big }) => big ? '8px 16px' : '8px 12px'};
    flex-wrap: unset;
    ${({ big }) => big && 'margin-top: -1px;'};

    .variantSelect__option {
      padding: 0;
    }

    .variantSelect__single-value {
      position: initial;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .variantSelect__indicator-separator {
    display: none;
  }

  .variantSelect__dropdown-indicator-centered {
    position: absolute;
    left: 50%;
    bottom: 15px;
    padding: 0;
    transform: translateX(-50%);
  }
`

const fadeIn = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: 98px;
  }
`

const fadeOut = keyframes`
  0% {
    height: 98px;
  }
  100% {
    height: 0;
  }
`

// prettier-ignore
export const CatalogSelectStyled = styled(Select)`
  width: ${({ width }) => width || '100%'};

  .variantSelect__control {
    border-radius: initial;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    background: ${theme.colors.lightGrey};
    transition: none;

    :hover {
      background: ${theme.colors.lightGrey6} !important;
      cursor: pointer;
      border: none;
    }

    :focus {
      border: none;
    }
  }

  .variantSelect__menu {
    bottom: -4px;
    left: -4px;
    margin: 0;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    width: calc(100% + 8px);
    background: ${theme.colors.lightGrey};

    .variantSelect__menu-list {
      padding: 4px;
      overflow: hidden;
      ${props => `
        .${props.parsedProductId}-multi:after {
          content: url("${DotMulti}");
          text-align: center;
          margin-top: 6px;
          margin-right: 6px;
        }
        .${props.parsedProductId}-single:after {
          content: url("${DotSingle}");
          text-align: center;
          margin-top: 6px;
          margin-right: 6px;
        }
      `}
      .variantSelect__single-value {
        display: flex;
        :nth-child(2) {
          animation: ${fadeIn} 0.5s ease-in-out;
        }
        :hover {
          background: ${theme.colors.lightGrey6} !important;
        }
      }
    }
  }

  .variantSelect__menu--close {
    .variantSelect__single-value {
      overflow: hidden;
      :first-child {
        animation: ${fadeOut} 0.5s ease-in-out;
      }
      :nth-child(2) {
        animation: none !important;
      }
    }
  }

  .variantSelect__menu--close-other {
    .variantSelect__single-value {
      overflow: hidden;
      :first-child {
        animation: none !important;
      }
      :nth-child(2) {
        animation: ${fadeOut} 0.5s ease-in-out !important;
      }
    }
  }

  .variantSelect__single-value {
    position: relative;
    padding: 0;
    transform: none;
    top: 0;
    margin: 0;
    max-width: 100%;
    overflow: visible;
    border-radius: 5px;
    > :first-child {
      padding: 7px 8px;
      padding-bottom: 11px;
    }

    :hover {
      cursor: pointer;
    }
  }

  .variantSelect__value-container {
    padding: 0;
    flex-wrap: unset;

    .variantSelect__option {
      padding: 0;
    }

    .variantSelect__single-value {
      position: initial;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .variantSelect__indicator-separator {
    display: none;
  }

  .variantSelect__indicators {
    align-items: flex-start;
  }

  .variantSelect__indicator {
    text-align: center;
    margin-top: 6px;
    margin-right: 6px;
    padding: 0;
  }

  .variantSelect__dropdown-indicator-centered {
    position: absolute;
    left: 50%;
    bottom: 15px;
    padding: 0;
    transform: translateX(-50%);
  }
`
