import React from 'react'

import { cn } from 'utils'

const TertiaryBtn = ({ children, className, ...props }) => (
  <button
    type="button"
    className={cn(
      'py-2 px-4 hover:bg-grey-200 rounded-lg duration-300 ease-out',
      className,
    )}
    {...props}
  >
    {children}
  </button>
)

export default TertiaryBtn
