import React, { useState } from 'react'
import { Flex, Box } from '@rebass/grid'
import { useIntl } from 'react-intl'
import { sumBy } from 'lodash/fp'
import dayjs from 'dayjs'

import theme from 'theme'
import { useRichFormatMessage } from 'utils'
import { useOfMaxWidth } from 'hooks'
import { Box as BoxIcon } from 'components/Icons'
import { DATE_TIME_FORMATS } from 'utils/datetime'
import { TinyText } from 'components/Text'

import messages from 'views/Cart/messages'
import { APP_BREAKPOINTS } from 'consts'

import Delivery from './Delivery'
import ProductsPreview from './ProductsPreview'
import DeliveryProgress from './DeliveryProgress'
import IncomingOrders from './IncomingOrders'
import {
  Container,
  DeliveryCaption,
  VerticalLine,
  Heading,
  MobileRightSide,
} from './styles'
import { useDeliveryStats } from './utils'

const { spacing } = theme

const IncomingOrdersExpandable = ({
  withBorder,
  openDelivery,
  incomingOrders,
  showRouteName,
  isFirstDelivery,
  isFirstIncomingDelivery,
  isFirstOtherIncoming,
  CustomTruckIcon,
}) => {
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TABLET_START - 1)
  const [isExpanded, setIsExpanded] = useState(false)
  const deliveryStats = useDeliveryStats({
    openDelivery,
    incomingOrders,
  })
  const incomingProductsCount = sumBy('orderItems.length')(incomingOrders)

  const progressRendered = (
    <DeliveryProgress
      {...{
        deliveryStats,
        isFirstOtherIncoming,
        isFirstDelivery,
        CustomTruckIcon,
      }}
    />
  )
  const routeInfoRendered = (
    <Flex px={isMobile ? 0 : spacing.sm}>
      <TinyText>
        {showRouteName && (
          <div>
            <b>{incomingOrders.length ? incomingOrders[0].routeName : null}</b>
          </div>
        )}

        <Delivery
          missingFreeDeliveryAmount={deliveryStats.missingAmount}
          isBold={!showRouteName}
        />

        <DeliveryCaption>
          &nbsp;
          {formatMessage(messages.addProductsTill, {
            dateTime: dayjs(deliveryStats.settings.deadlineOrderDate).format(
              DATE_TIME_FORMATS.FULL_DATE_TIME,
            ),
          })}
        </DeliveryCaption>
      </TinyText>
    </Flex>
  )
  const incomingInfoRendered = (
    <DeliveryCaption {...!isMobile && { style: { textAlign: 'right' } }}>
      {formatRichMessage(messages.includesOtherOrders, {
        productsCount: `${incomingProductsCount} ${formatMessage(
          messages.numberOfProducts,
          {
            number: incomingProductsCount,
          },
        )}`,
      })}
    </DeliveryCaption>
  )
  const previewRendered = (
    <Flex {...!isMobile && { width: 172, minWidth: 172 }}>
      <ProductsPreview
        {...{
          incomingOrders,
          isExpanded,
          isFirstIncomingDelivery,
        }}
        onToggleExpanded={setIsExpanded}
      />
    </Flex>
  )

  return (
    <Container
      {...{ withBorder, isExpanded }}
      data-test-id={`incoming_orders-${incomingOrders[0]?.orderId}`}
    >
      <Heading
        {...incomingProductsCount > 0 && { className: 'incoming-details' }}
        toggleable={incomingProductsCount > 0}
        onClick={() =>
          incomingProductsCount > 0 ? setIsExpanded(!isExpanded) : {}
        }
      >
        {isMobile ? (
          <>
            <Flex
              height="100%"
              flexDirection="column"
              alignItems="flex-start"
              alignSelf="flex-start"
            >
              <Box mb={spacing.sm}>{progressRendered}</Box>
              {routeInfoRendered}
            </Flex>

            {incomingProductsCount > 0 && (
              <MobileRightSide>
                <BoxIcon />

                <Box my={spacing.sm}>{incomingInfoRendered}</Box>

                {previewRendered}
              </MobileRightSide>
            )}
          </>
        ) : (
          <>
            {progressRendered}

            <VerticalLine ml={spacing.sm} />

            <Flex flex={1}>{routeInfoRendered}</Flex>

            {incomingProductsCount > 0 && (
              <>
                <VerticalLine />

                <Flex flex={1}>
                  <Flex px={spacing.sm} alignItems="center">
                    {incomingInfoRendered}

                    <Box ml={spacing.sm} alignItems="center">
                      <BoxIcon />
                    </Box>
                  </Flex>
                </Flex>

                <VerticalLine />

                {previewRendered}
              </>
            )}
          </>
        )}
      </Heading>

      {isExpanded && <IncomingOrders items={incomingOrders} />}
    </Container>
  )
}

export default IncomingOrdersExpandable
