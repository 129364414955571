import React from 'react'
import { Flex } from '@rebass/grid'
import { FormattedMessage, useIntl } from 'react-intl'

import theme from 'theme'
import { formatDeliveryAddress } from 'utils'

import { TertiaryText, TinyText } from 'components/Text'

import { HalfBox } from '../styles'
import DeliveryInfo from '../DeliveryInfo'
import messages from '../messages'

import { LocationIcon, ColoredBox, TruckIcon } from './styles'

const { colors, spacing } = theme

const Deliveries = ({
  orders,
  deliveryName,
  deliveryAddress,
  deliveryPostcode,
  deliveryCity,
  mainColor,
  isMobile,
}) => {
  const { formatMessage } = useIntl()
  return (
    <HalfBox withBorder={!isMobile} justifyContent="space-between">
      <ColoredBox alignItems="center" height={48}>
        <TruckIcon />

        <TertiaryText bold color={mainColor}>
          <FormattedMessage
            {...messages.deliveriesCount}
            values={{
              count: orders.length,
              delivery: formatMessage(messages.deliveries, {
                number: orders.length,
              }),
            }}
          />
        </TertiaryText>
      </ColoredBox>

      <Flex flexDirection="column" py={26} px={9}>
        {orders.map(({ deliveryDate, routeId, routeName }, index) => (
          <DeliveryInfo
            key={`${deliveryDate}-${routeId}`}
            mb={index === orders.length - 1 ? undefined : 22}
            ordersLength={orders.length}
            {...{ deliveryDate, routeName, index }}
          />
        ))}
      </Flex>

      <ColoredBox height={88}>
        <LocationIcon />

        <Flex flexDirection="column" alignSelf="center">
          <TertiaryText bold style={{ marginBottom: spacing.xxs }}>
            {deliveryName}
          </TertiaryText>

          <TinyText color={colors.gray4}>
            {formatDeliveryAddress(
              deliveryAddress,
              deliveryPostcode,
              deliveryCity,
            )}
          </TinyText>
        </Flex>
      </ColoredBox>
    </HalfBox>
  )
}

export default Deliveries
