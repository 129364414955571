import styled from 'styled-components'

import theme from 'theme'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing.xs};
  gap: ${theme.spacing.sm};
`

export const DaysContainer = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 58px);
  grid-auto-flow: column;
  justify-content: space-between;
  overflow-x: auto;
`
