import React from 'react'

import theme from 'theme'

const Clock = ({
  mainColor = theme.colors.primary,
  secondaryColor = theme.colors.secondary,
  width = 136,
  height = 136,
  style = { marginBottom: '-12px' },
  ...rest
}) => (
  <svg
    viewBox="0 0 136 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    {...rest}
  >
    <g filter="url(#prefix__filter0_d)">
      <circle cx={68} cy={62} r={48} fill="#fff" />
    </g>
    <path
      d="M85 80L64 59"
      stroke={secondaryColor}
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68 24v44"
      stroke={mainColor}
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={24} y={61} width={8} height={2} rx={1} fill="#C4C4C4" />
    <rect x={67} y={98} width={2} height={8} rx={1} fill="#C4C4C4" />
    <rect x={104} y={61} width={8} height={2} rx={1} fill="#C4C4C4" />
    <defs>
      <filter
        id="prefix__filter0_d"
        x={0}
        y={0}
        width={136}
        height={136}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology radius={4} in="SourceAlpha" result="effect1_dropShadow" />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={12} />
        <feColorMatrix values="0 0 0 0 0.27375 0 0 0 0 0.447733 0 0 0 0 0.608333 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default Clock
