import { createSelector } from 'reselect'
import { groupBy, compose, defaultTo } from 'lodash/fp'

import { makeDataSelector } from 'utils/redux-utils'

import {
  DELIVERY_INFO_REDUCER_NAME,
  DELIVERY_DATES_BY_PRODUCTS_REDUCER_NAME,
  ALL_DELIVERY_DATES_REDUCER_NAME,
  USER_DELIVERIES_REDUCER_NAME,
} from './consts'

export const deliveryDatesByProductsDataSelector = makeDataSelector([
  DELIVERY_INFO_REDUCER_NAME,
  DELIVERY_DATES_BY_PRODUCTS_REDUCER_NAME,
])

export const deliveryDatesByProductsSelector = createSelector(
  deliveryDatesByProductsDataSelector,
  compose(
    groupBy('productId'),
    defaultTo([]),
  ),
)

const timerDeliveryDatesDataSelector = makeDataSelector([
  DELIVERY_INFO_REDUCER_NAME,
  ALL_DELIVERY_DATES_REDUCER_NAME,
])

export const allDeliveryDatesSelector = createSelector(
  timerDeliveryDatesDataSelector,
  defaultTo([]),
)

export const closestTimerDeliveryDateSelector = createSelector(
  allDeliveryDatesSelector,
  deliveries => deliveries[0] || {},
)

const userDeliveriesDataSelector = makeDataSelector([
  DELIVERY_INFO_REDUCER_NAME,
  USER_DELIVERIES_REDUCER_NAME,
])

export const userDeliveriesSelector = createSelector(
  userDeliveriesDataSelector,
  data => data?.deliveries || [],
)

export const closestOrderDateSelector = createSelector(
  userDeliveriesDataSelector,
  data => data?.meta?.closestOrderDate || null,
)
