import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { TinyText } from 'components/Text'

import { APP_BREAKPOINTS } from 'consts'

// prettier-ignore
export const ColoredWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 -25px;
  padding: 0 24px;
  flex: 1;

  @media screen and (max-width: ${APP_BREAKPOINTS.TMP_MAIN - 1}px) {
    ${p => !p.isExpanded && `width: 100vw;`};
    margin: -${p => p.isExpanded ? 17 : 8}px -24px;
    padding: ${p => p.isExpanded ? 17 : 8}px 24px;
  }
`

export const ScenarioDescr = styled(TinyText)`
  color: ${p => p.color || theme.colors.battleshipGrey};
  line-height: 16px;

  b {
    color: ${p => p.color || theme.colors.primary};
    font-weight: 700;
  }
`
