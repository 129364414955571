import React from 'react'
import PropTypes from 'prop-types'

import { TagWrapper, TagTextWrapper } from './styledComponents'

const Tag = ({ message, color, textMode, testId }) => {
  if (textMode) {
    return (
      <TagTextWrapper data-test-id={testId} strongBold {...{ color }}>
        {message}
      </TagTextWrapper>
    )
  }

  return (
    <TagWrapper data-test-id={testId} {...{ color }}>
      {message}
    </TagWrapper>
  )
}

Tag.propTypes = {
  color: PropTypes.string,
}

export default Tag
