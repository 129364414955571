/* eslint-disable react/prop-types */
import React from 'react'

import { formatPrice } from 'utils'
import { SecondaryTextBlack } from 'components/Text'

import { PkgColumn, DetailsContainer } from './styles'
import { getCartUnitDef } from '../utils'

const UnitPrice = ({
  className,
  active,
  unitOfMeasureObj,
  baseUnitOfMeasureDescription,
  formatMessage,
  priceShown,
  isBold,
  children,
}) => {
  const priceActive = active && priceShown
  return (
    <PkgColumn {...{ className }}>
      <DetailsContainer col="12">
        <SecondaryTextBlack bold={isBold}>
          {getCartUnitDef({
            formatMessage,
            baseUnitDesc: baseUnitOfMeasureDescription,
            unitQuantity: unitOfMeasureObj.quantity,
            unitDesc: unitOfMeasureObj.unitOfMeasureDescription,
            unitOfMeasure: unitOfMeasureObj.unitOfMeasure,
            multiplier: unitOfMeasureObj.multiplier,
          })}

          {children}
        </SecondaryTextBlack>
      </DetailsContainer>

      {priceActive && (
        <SecondaryTextBlack bold={isBold}>
          {unitOfMeasureObj.priceNet && formatPrice(unitOfMeasureObj.priceNet)}
        </SecondaryTextBlack>
      )}
    </PkgColumn>
  )
}

export default UnitPrice
