export const MODAL_SIZES = {
  SMALL: 500,
  MEDIUM: 942,
  BIG: 1074,
}

export const MODAL_REMOVE_PRODUCTS = 'MODAL_REMOVE_PRODUCTS'
export const MODAL_OFFLINE = 'MODAL_OFFLINE'
export const SIGN_IN_MODAL = 'SIGN_IN_MODAL'
export const MODAL_SOMETHING_WENT_WRONG = 'MODAL_SOMETHING_WENT_WRONG'
export const MODAL_OUT_OF_STOCK = 'MODAL_OUT_OF_STOCK'
export const AFFECT_DELIVERY_DATE_MODAL = 'affectDelivery'
export const DELIVERY_EXPIRED_MODAL = 'deliveryExpired'
