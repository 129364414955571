import { createGlobalStyle } from 'styled-components'

import reset from './reset'
import fonts from './fonts'

// prettier-ignore
const GlobalStyle = createGlobalStyle`
  ${reset};
  ${fonts};

  html,
  body {
    height: 100%;
    width: 100%;
  }

  #app {
    background-color: #fff;
    height: 100%;
    width: 100%;
  }

  svg {
    fill: transparent;
    user-select: none;
  }

  input,
  textarea,
  button,
  select,
  li,
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  body {
    font-family: Inter, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    ${({ openModal }) => openModal && `overflow: hidden;`}
  }
`

export default GlobalStyle
