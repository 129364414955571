import styled from 'styled-components'

import theme from 'theme'
import { TinyTextV2 } from 'components/Text'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${theme.spacing.xs};
  flex-grow: 1;
`

export const ErrorTextContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
`

export const ErrorText = styled(TinyTextV2)`
  color: ${theme.colors.redDarkText};
`

export const HintContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
`

export const HintText = styled(TinyTextV2)`
  color: ${theme.colors.lightGrey4};
`
