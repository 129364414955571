import React from 'react'
import { FormattedMessage } from 'react-intl'

import ConfirmPanel from '../components/ConfirmPanel'
import { PageContainer } from '../components/styledComponents'

import messages from './messages'

const ChangePasswordConfirm = () => (
  <PageContainer>
    <div>
      <ConfirmPanel
        title={<FormattedMessage {...messages.title} />}
        secondaryTitle={<FormattedMessage {...messages.secondaryTitle} />}
        ctaBtnTitle={<FormattedMessage {...messages.ctaBtn} />}
      />
    </div>
  </PageContainer>
)

export default ChangePasswordConfirm
