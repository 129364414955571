import styled from 'styled-components'

const SkeletonBlock = styled.div`
  width: ${p => p.$width || '300px'};
  height: ${p => p.$height || '40px'};
  border-radius: ${p => p.$borderRadius || '8px'};
  background: linear-gradient(270deg, #f6f8fa 0%, #e4e9ee 100%);
`

export default SkeletonBlock
