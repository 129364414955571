import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ProductLongBtn } from '../ButtonsContainer/styles'
import ProductLicenseTooltip from './ProductLicenseTooltip'

import messages from '../messages'

const CatalogProductLicense = ({ product: { licenseType } = {} }) => (
  <ProductLicenseTooltip>
    <ProductLongBtn isDisabled>
      <FormattedMessage {...messages.missingLicense} values={{ licenseType }} />
    </ProductLongBtn>
  </ProductLicenseTooltip>
)

export default CatalogProductLicense
