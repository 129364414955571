import { all } from 'redux-saga/effects'

import productsWatchers from './Products/sagas'
import campaignWatchers from './Campaigns/sagas'
import groupWatchers from './Groups/sagas'
import groupProductsWatchers from './GroupProducts/sagas'
import AllGroupsWatchers from './AllGroups/sagas'

export const promotionsWatchers = [
  ...productsWatchers,
  ...campaignWatchers,
  ...groupWatchers,
  ...groupProductsWatchers,
  ...AllGroupsWatchers,
]

export default function* watcher() {
  yield all(promotionsWatchers)
}
