import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { StyledScroll } from 'components/Scroll/styles'
import { Title } from 'views/Templates/TemplatesList/TemplateListItem/TemplateEntry/styles'
import { CheckboxLabel } from 'components/Input/styles'
import { InteractiveWrapper } from 'components/Button/styles'

export const ListContainer = styled(Flex)`
  flex: 1;
  min-height: 160px;
  max-height: calc(100vh - 385px);
  flex-direction: column;
  padding-left: 32px;
  margin-left: 32px;
  border-left: 1px solid ${theme.colors.borderFaded};
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
    max-height: calc(100vh - 453px);
  }
`

export const ScrollableListContainer = styled(StyledScroll)`
  max-height: calc(100vh - 385px);
  min-height: 160px;
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    max-height: calc(100vh - 385px);
  }
  margin-right: -10px;
  padding-right: 10px;
  .simplebar-content {
    margin-top: 8px;
  }
`

export const CheckboxContainer = styled(Flex)`
  padding: 3px;
  margin-right: 16px;
`

export const TemplateEntryWrapper = styled(InteractiveWrapper)`
  ${Title} {
    color: ${theme.colors.primary};
  }
  :hover {
    ${CheckboxLabel} {
      background: ${theme.colors.lightGrey7};
    }
  }
`

export const CreateTemplateContainer = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.borderFaded};
  padding-bottom: 8px;
  button {
    padding-left: 16px;
  }
`

export const InputContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 8px;
`
