import styled, { keyframes } from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import theme from 'theme'

import { Button } from 'components/Button'
import { BUTTON_SIZE, BUTTON_TYPES } from 'components/Button/consts'

export const SearchForm = styled.form`
  font-family: ${theme.fonts.primary};
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input {
    width: 100%;
  }

  @media (min-width: 1025px) {
    max-width: 442px;
  }
`

// prettier-ignore
export const SearchInput = styled.input`
  font-size: 13px;
  line-height: 24px;
  color: ${theme.colors.primary};
  height: 40px;
  background: ${props => !props.defaultBg && theme.colors.lightGrey};
  border-radius: 8px;
  outline: none;
  padding-left: 52px;
  ::placeholder {
    color: #9AA4AD;
  }
  :focus {
    background: ${props => !props.defaultBg && theme.colors.lightGrey7};
  }

  ${props => props.isLoading && `padding-right: 35px;`}
`

export const SearchBtn = styled(Button).attrs({
  size: BUTTON_SIZE.SEARCH,
  type: BUTTON_TYPES.BASIC,
})`
  position: absolute;
  top: 4px;
  left: 4px;
`

export const SearchInputContainer = styled(Flex)`
  position: relative;
  width: 100%;

  & input {
    font-family: inherit;
  }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const SpinnerContainer = styled(Box)`
  position: absolute;
  right: 8px;
  top: 8px;
  animation-name: ${spin};
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

export const XContainer = styled(Box)`
  position: absolute;
  text-align: center;
  cursor: pointer;
  width: 32px;
  right: 8px;
  top: 8px;
  :hover {
    path {
      stroke: ${theme.colors.primary};
    }
  }
`
