import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

export const ProgressWrapper = styled(Flex)``

export const TooltipContent = styled(Flex)`
  flex-direction: column;
  width: 180px;
  padding: ${theme.spacing.sm};
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.primary};
`

export const Circle = styled(Flex)`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: ${theme.spacing.sm};
`
