import React from 'react'
import { isNil } from 'lodash/fp'
import { FormattedMessage, useIntl } from 'react-intl'

import theme from 'theme'
import { InfoSmall } from 'components/Icons'
import TextTooltip from 'components/Tooltip/TextTooltip'
import { INTERACTION_KINDS } from 'components/Popup'
import { formatPrice } from 'utils'
import { BasicButtonV2 } from 'components/Button'

import messages from '../messages'

import {
  Container,
  LeftCol,
  RightCol,
  PriceBlock,
  TotalBlock,
  CaptionLabel,
  OverpaymentsHints,
  PriceLabel,
  RamainingInfo,
  InfoBtn,
} from './styles'

const Footer = ({
  selectedOverpaymentsTotal,
  toPayAmount,
  remainingAmountToFill,
  settleBtnDisabled,
  onSubmitSettlement,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Container>
      <LeftCol>
        <PriceBlock>
          <CaptionLabel>
            <FormattedMessage {...messages.invoicesDue} />
          </CaptionLabel>
          <PriceLabel data-test-id="invoices-to-pay-total">
            {toPayAmount}
          </PriceLabel>
        </PriceBlock>

        <PriceBlock>
          <CaptionLabel>
            <FormattedMessage {...messages.remaining} />
          </CaptionLabel>

          {!isNil(remainingAmountToFill) && (
            <OverpaymentsHints>
              <PriceLabel data-test-id="invoices-to-pay-fill-total">
                {/* negative remainingAmountToFill means that there are more overpayments selected
                than needed to fill in invoicesToPay */}
                {formatPrice(
                  remainingAmountToFill > 0 ? remainingAmountToFill : 0,
                )}
              </PriceLabel>

              {remainingAmountToFill < 0 && (
                <RamainingInfo>
                  <PriceLabel
                    color={theme.colors.secondary}
                    data-test-id="invoices-to-pay-overflow-total"
                  >
                    {formatPrice(Math.abs(remainingAmountToFill))}
                  </PriceLabel>

                  <TextTooltip
                    interactionKind={INTERACTION_KINDS.CLICK}
                    content={formatMessage(messages.remainingHint)}
                  >
                    <InfoBtn>
                      <InfoSmall />
                    </InfoBtn>
                  </TextTooltip>
                </RamainingInfo>
              )}
            </OverpaymentsHints>
          )}
        </PriceBlock>
      </LeftCol>

      <RightCol>
        <TotalBlock>
          <CaptionLabel>
            <FormattedMessage {...messages.selected} />
          </CaptionLabel>
          <PriceLabel data-test-id="invoices-to-pay-filled-total">
            {formatPrice(selectedOverpaymentsTotal)}
          </PriceLabel>
        </TotalBlock>

        <BasicButtonV2
          disabled={settleBtnDisabled}
          data-test-id="settle-invoices-btn"
          onClick={onSubmitSettlement}
        >
          <FormattedMessage {...messages.ctaBtn} />
        </BasicButtonV2>
      </RightCol>
    </Container>
  )
}

export default Footer
