import { createAction } from 'redux-actions'

import {
  createDeltaAction,
  createActionCreator,
  createBaseActionCreator,
  createActionsGroup,
} from 'utils/redux-utils'

export const LOGOUT = createDeltaAction('LOGOUT')
export const logout = createActionCreator(LOGOUT)

export const logoutReason = createAction('LOGOUT_REASON')

export const LOGOUT_ACTION_TYPE = 'LOGOUT'
export const logoutBaseAction = createBaseActionCreator(LOGOUT_ACTION_TYPE)

export const loginActions = createActionsGroup('LOGIN')

export const signUpActions = createActionsGroup('SIGN_UP')
export const signUpMigrateActions = createActionsGroup('SIGN_UP_MIGRATE')

export const passwordActions = createActionsGroup('PASSWORD')

export const confirmEmailActions = createActionsGroup('CONFIRM_EMAIL')

export const resendConfirmEmailActions = createActionsGroup(
  'RESEND_CONFIRM_EMAIL',
)

export const validateTokenActions = createActionsGroup('VALIDATE_TOKEN')

export const resendSetPasswordActions = createActionsGroup(
  'RESEND_SET_PASSWORD',
)
export const resendPasswordResetActions = createActionsGroup(
  'RESEND_PASSWORD_RESET',
)

export const checkLoginActions = createActionsGroup('CHECK_LOGIN')
