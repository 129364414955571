import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { setIsTooltipOpen } from 'containers/App/actions'
import { StockExceeded } from 'components/Product/StockConfirmation'
import { StockLimitedTooltipText } from 'components/Product/ButtonsContainer/styles'
import { formatUnitAmount } from 'components/Product/utils'
import { DiscardButtonV2 } from 'components/Product/StockConfirmation/Confirmation/styles'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'

import messages from 'components/ProductCartControls/messages'
import {
  ElasticTooltipWrapper,
  StockLimitedContainer,
} from 'components/ProductCartControls/styles'

const ProductCartControlsTooltip = ({
  product,
  unitData,
  affectedElasticStock,
  stockLimitedAmount,
  isOpen,
  onConfirmDateShift,
  matchTriggerWidth,
  onCancel,
  children,
  placement,
  targetStyles = {},
  onClosed,
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const tooltipContent = affectedElasticStock ? (
    <StockExceeded
      productId={product.id}
      Wrapper={ElasticTooltipWrapper}
      unitOfMeasureObj={affectedElasticStock?.unitOfMeasureObj}
      closestDeliveryDate={product.closestDeliveryTime}
      selectedDeliveryDate={affectedElasticStock?.deliveryDate}
      onClose={onCancel}
      onConfirmDateShift={onConfirmDateShift}
    />
  ) : (
    <StockLimitedContainer>
      <StockLimitedTooltipText>
        {formatMessage(messages.stockLimitedText, {
          amount: formatUnitAmount(unitData, stockLimitedAmount),
        })}
      </StockLimitedTooltipText>
      <DiscardButtonV2 onClick={onCancel} isFullyRounded>
        OK
      </DiscardButtonV2>
    </StockLimitedContainer>
  )

  return (
    <Popover
      onOpen={() => {
        dispatch(setIsTooltipOpen(true, { productId: product.id }))
      }}
      onClose={() => {
        dispatch(setIsTooltipOpen(false))
      }}
      onClosed={onClosed}
      placement={placement}
      fixedPlacement
      open={isOpen}
    >
      <PopoverContent matchTriggerWidth={matchTriggerWidth}>
        {tooltipContent}
      </PopoverContent>
      <PopoverTrigger style={{ ...targetStyles, flex: 1 }}>
        {children}
      </PopoverTrigger>
    </Popover>
  )
}

export default ProductCartControlsTooltip
