import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ROUTES } from 'consts'
import { BUTTON_SIZE, AlternateBtn } from 'components/Button'

import Notification from '../Notification'

import { SuccessLink } from './styles'
import messages from './messages'

const SuccessMigrate = () => (
  <Notification
    header={messages.successTitle}
    width="490px"
    height="214px"
    hideFooter
    spacedVertical
  >
    <FormattedMessage {...messages.youCanLogin} />

    <SuccessLink to={ROUTES.LOGIN}>
      <AlternateBtn size={BUTTON_SIZE.BLOCK}>
        <FormattedMessage {...messages.toLogin} />
      </AlternateBtn>
    </SuccessLink>
  </Notification>
)

export default SuccessMigrate
