import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { contains } from 'lodash/fp'

import { AlternateBtn, BUTTON_SIZE } from 'components/Button'
import { DATE_TIME_FORMATS } from 'utils/datetime'
import { formatPrice } from 'utils'

import { FloatingPriceWrapper } from 'components/FloatingPrice'
import {
  ORDER_STATUS,
  ORDERS_ROUTES,
  ORDER_SOURCE_LABELS,
} from '../../../consts'
import messages from '../../../messages'

import {
  Container,
  TitleLink,
  Hint,
  DetailsRow,
  DetailColumn,
  DetailText,
  MiddleCols,
  ButtonContainer,
  HeaderRow,
  Price,
  PriceDetailsColumn,
} from './styles'

const OrderListItem = ({ order, onShowDetails }) => {
  const { formatMessage } = useIntl()
  const isInactive = order.orderStatusId === ORDER_STATUS.CANCELED
  const isInProgress = contains(order.orderStatusId, [
    ORDER_STATUS.ACCEPTED,
    ORDER_STATUS.IN_PROGRESS,
  ])
  const isAccepting = order.orderStatusId === ORDER_STATUS.TO_BE_ACCEPTED

  return (
    <Container isInactive={isInactive} data-test-id={`order#${order.orderId}`}>
      <HeaderRow>
        <TitleLink
          data-test-id={`order_${order.orderId}`}
          to={generatePath(ORDERS_ROUTES.DETAILS, { orderId: order.orderId })}
        >
          {order.orderNumber}
        </TitleLink>
        <Hint
          isError={isInactive}
          isInfo={isInProgress}
          isAccepting={isAccepting}
        >
          {formatMessage(messages[`status${order.orderStatusId}`])}
        </Hint>
      </HeaderRow>
      <DetailsRow>
        <MiddleCols>
          <DetailColumn>
            <Hint>{formatMessage(messages.orderDate)}</Hint>
            <DetailText>
              {dayjs(order.orderDatetime).format(DATE_TIME_FORMATS.FULL_DATE)}
            </DetailText>
          </DetailColumn>

          <DetailColumn>
            {!isInactive && (
              <>
                <Hint>
                  {order.orderStatusId === ORDER_STATUS.COMPLETED
                    ? formatMessage(messages.deliveryDate)
                    : formatMessage(messages.scheduledDeliveryDate)}
                </Hint>
                <DetailText>
                  {dayjs(order.deliveryDate).format(
                    DATE_TIME_FORMATS.FULL_DATE,
                  )}
                  {order.routeName && `, ${order.routeName}`}
                </DetailText>
              </>
            )}
          </DetailColumn>

          <DetailColumn>
            <Hint>{formatMessage(messages.source)}</Hint>
            <DetailText>
              {ORDER_SOURCE_LABELS[order.orderSource]
                ? formatMessage({ id: ORDER_SOURCE_LABELS[order.orderSource] })
                : order.orderSource}
            </DetailText>
          </DetailColumn>

          <PriceDetailsColumn>
            <FloatingPriceWrapper
              hasFloatingPrice={order.anyFloatingPrice}
              hasTooltip
            >
              <Price strongBold>{formatPrice(order.totalNet)}</Price>
            </FloatingPriceWrapper>
            <Hint faded={!isInactive}>
              {formatMessage(messages.gross, {
                price: formatPrice(order.totalGross),
              })}
            </Hint>
          </PriceDetailsColumn>
        </MiddleCols>

        <ButtonContainer>
          <AlternateBtn
            data-test-id={`see_details_${order.orderId}`}
            size={BUTTON_SIZE.SMALL}
            onClick={() => onShowDetails(order.orderId)}
          >
            {formatMessage(messages.seeDetails)}
          </AlternateBtn>
        </ButtonContainer>
      </DetailsRow>
    </Container>
  )
}

OrderListItem.propTypes = {
  order: PropTypes.shape({}),
}

export default OrderListItem
