import React, { useEffect, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import { find } from 'lodash'
import { isEqual } from 'lodash/fp'

import { cartProductItemsSelector } from 'containers/Cart/selectors'
import { ArrowDownV2 } from 'components/Icons'

import { orderOptions } from './utils'
import { CatalogSelectStyled } from './styledComponents'

const CatalogVariantsSelect = props => {
  const {
    unitsOfMeasure,
    ParsedPrices,
    big,
    productId,
    defaultFillPredicate,
    value,
    handleChange,
    unitOfMeasure: unitOfMeasureObj,
  } = props
  const variantsInCart = useSelector(cartProductItemsSelector(productId))
  const parsedProductId = productId.replace(':', '')
  const unitOfMeasureRef = useRef(null)
  const selectRef = useRef(null)
  const options = useMemo(
    () => {
      const unitsOrdered = orderOptions({
        variantsInCart,
        unitsOfMeasure,
        chosenUnit: value && value.value,
      })

      return unitsOrdered.map(
        ({
          unitOfMeasureDescription,
          unitOfMeasure,
          quantity,
          priceNet,
          ...unit
        }) => ({
          ...unit,
          label: unitOfMeasureDescription,
          value: unitOfMeasure,
          parsedPrices: (
            // eslint-disable-next-line react/jsx-pascal-case
            <ParsedPrices.type
              isVariant
              unitForPricePerUnit={unitOfMeasure}
              pricePerUnit={priceNet}
              {...{ quantity, unitOfMeasureDescription, unitOfMeasure, big }}
              {...ParsedPrices.props}
            />
          ),
        }),
      )
    },
    [variantsInCart, unitsOfMeasure, value],
  )

  useEffect(
    () => {
      if (!isEqual(unitOfMeasureObj, unitOfMeasureRef.current)) {
        handleChange(find(options, defaultFillPredicate), true)
      }

      unitOfMeasureRef.current = unitOfMeasureObj
    },
    [unitOfMeasureObj, handleChange, options, defaultFillPredicate],
  )

  // eslint-disable-next-line
  const CustomOption = ({ data, ...props }) => {
    const Wrapper = data ? components.SingleValue : components.Option
    const isSingleUnit = data.value === unitsOfMeasure[0].unitOfMeasure
    const className = `${parsedProductId}-${isSingleUnit ? 'single' : 'multi'}`

    return (
      <Wrapper {...props} {...{ className }}>
        {data && data.parsedPrices}
      </Wrapper>
    )
  }

  // eslint-disable-next-line
  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <ArrowDownV2 />
    </components.DropdownIndicator>
  )

  const animateCloseMenu = className => {
    const menuEl = selectRef.current.menuListRef?.parentElement
    const containerEl = menuEl?.parentElement
    const clonedMenuEl = menuEl?.cloneNode(true)

    if (!clonedMenuEl) return

    clonedMenuEl.classList.add(`variantSelect__menu--${className}`)
    clonedMenuEl.addEventListener('animationend', () =>
      containerEl?.removeChild(clonedMenuEl),
    )

    // eslint-disable-next-line
    containerEl?.appendChild(clonedMenuEl)
  }

  return (
    <CatalogSelectStyled
      ref={selectRef}
      menuPlacement="top"
      classNamePrefix="variantSelect"
      isSearchable={false}
      components={{
        Option: CustomOption,
        SingleValue: CustomOption,
        DropdownIndicator,
      }}
      onChange={option => {
        handleChange(option)
      }}
      onMenuClose={() => {
        const { focusedOption } = selectRef.current.state
        animateCloseMenu(
          focusedOption?.value !== value.value ? 'close' : 'close-other',
        )
      }}
      {...props}
      {...{ options, parsedProductId }}
    />
  )
}

export default CatalogVariantsSelect
