import { takeLatest, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'

import { STATIC_FILTERS } from '../../../components/Filters/consts'
import { getFilters } from './api'
import { getFiltersActions } from './actions'

export function* getFiltersFlow({ data } = {}) {
  try {
    const filters = yield call(genericGetDataEnhanced, {
      request: getFilters,
      params: data,
    })

    if (!filters) {
      return
    }

    yield put({
      type: getFiltersActions.SUCCESS,
      data: [...filters, ...STATIC_FILTERS],
    })
  } catch (error) {
    console.log('Error fetching filters', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [takeLatest(getFiltersActions.DELTA, getFiltersFlow)]
