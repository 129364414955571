import { handleActions } from 'redux-actions'

import { createReducer, initialState } from 'utils/simpleDataReducer'
import { updateCartItem, deleteCartItem } from 'containers/Cart/actions'
import { resetProductUnitsOfMeasureInCart } from 'containers/Products/reducer'

import { getPromoProduct } from 'containers/Promotions/Products/actions'
import { getPromoGroupProductsActions } from './actions'

const promoGroupProductsReducer = createReducer(getPromoGroupProductsActions)
const promoGroupProductsHandlers = handleActions(
  {
    [getPromoProduct.SUCCESS]: (state, { data: { response } }) => {
      let updState = state
      const products = state.getIn(['data', 'products']) || []
      products.forEach((product, productIndex) => {
        if (
          response &&
          product.getIn(['promotion', 'reward', 'productId']) ===
            response.promotion?.reward?.productId
        ) {
          updState = updState.mergeIn(
            ['data', 'products', productIndex, 'promotion'],
            response.promotion,
          )
        }
      })
      return updState
    },
    [updateCartItem.SUCCESS]: (state, { data: { cartItem } }) => {
      let updState = state
      const products = state.getIn(['data', 'products']) || []
      products.forEach((product, productIndex) => {
        if (
          cartItem &&
          cartItem.product.promotion?.reward?.productId ===
            product.getIn(['promotion', 'reward', 'productId'])
        ) {
          updState = updState.mergeIn(
            ['data', 'products', productIndex, 'promotion'],
            cartItem.product.promotion,
          )
        }
        if (cartItem && cartItem.product.id === product.get('id')) {
          updState = updState.mergeIn(
            ['data', 'products', productIndex],
            product.merge(cartItem.product),
          )
        }
      })

      return updState
    },
    [deleteCartItem.SUCCESS]: (
      state,
      { data: { cartItem }, additionalData },
    ) => {
      let updState = state
      if (!cartItem) {
        const products = state.getIn(['data', 'products']) || []
        products.forEach((product, productIndex) => {
          if (additionalData.productId === product.get('id')) {
            updState = state.mergeIn(
              ['data', 'products', productIndex, 'unitsOfMeasure'],
              resetProductUnitsOfMeasureInCart(
                product.get('unitsOfMeasure'),
                additionalData.unitOfMeasure,
              ),
            )
          }
        })
      }

      return updState
    },
  },
  initialState,
)

export default (state, action) => {
  const newState = promoGroupProductsReducer(state, action)
  return promoGroupProductsHandlers(newState, action)
}
