import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  closeAllModals,
  closeModal,
  openModal,
} from 'containers/App/actions/modal'

const modalsMap = {}
const modalsProps = {}

const withModal = (ModalComponent, modalType, config) => Component => {
  // TODO refactor the way modals are stored/configured
  modalsMap[modalType] = ModalComponent
  modalsProps[modalType] = config

  class WithModal extends React.Component {
    constructor() {
      super()

      this.openModal = this.openModal.bind(this)
      this.closeThisModal = this.closeThisModal.bind(this)

      this.resolveModal = null
    }

    openModal(data, type = modalType) {
      return new Promise(resolve => {
        this.resolveModal = resolve
        this.props.openModal(type, data, resolve)
      })
    }

    closeThisModal() {
      this.props.closeModal(modalType)

      if (this.resolveModal) {
        this.resolveModal()
        this.resolveModal = null
      }
    }

    get propsToInject() {
      return {
        ...this.props,
        openModal: this.openModal,
        closeThisModal: this.closeThisModal,
        modalType,
      }
    }

    render() {
      return <Component {...this.propsToInject} />
    }
  }

  WithModal.propTypes = {
    openModal: PropTypes.func,
    closeAllModals: PropTypes.func,
    closeModal: PropTypes.func,
  }

  return connect(
    null,
    {
      openModal,
      closeModal,
      closeAllModals,
    },
  )(WithModal)
}

export { withModal as default, modalsMap, modalsProps }
