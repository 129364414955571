import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useDeleteProductHandler, useOfMaxWidth } from 'hooks'

import { ICONS, ICONS_POSITION } from 'components/Icon'
import { BUTTON_SIZE } from 'components/Button'
import { APP_BREAKPOINTS } from 'consts'
import { isRewardPromoType } from 'containers/Promotions/utils'
import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'

import { VerticalDivider } from '../styledComponents'
import messages from '../messages'

import {
  ButtonsContainerStyled,
  TrashBtn,
  MultiplePackagingsTxt,
  TextWrapper,
  MultiplePackagingsWrapper,
} from './styles'

const ActionButtons = ({
  hasMultipleVariants,
  productId,
  product,
  inTemplate,
  addRemoveCallback,
  maxValue,
  disableInput,
  unitOfMeasureObj,
  toggleDetails,
  isVariant,
  children,
  confirmStockRef,
  dateChangeCallback,
}) => {
  const handleDeleteProduct = useDeleteProductHandler({ product })
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)

  const promo = product?.promotion

  const refetchOnCartClick = isRewardPromoType(promo)

  const trashRendered = (
    <TrashBtn
      size={BUTTON_SIZE.ICON}
      iconPosition={ICONS_POSITION.CENTER}
      iconType={ICONS.TRASH}
      data-test-id={`trash_${productId}`}
      onClick={() =>
        handleDeleteProduct({
          productId,
          itemListId: ITEM_LIST_IDS.CART,
          itemListName: ITEM_LIST_NAMES.CART,
          unitOfMeasure: isVariant ? unitOfMeasureObj.unitOfMeasure : undefined,
        })
      }
    />
  )

  if (hasMultipleVariants) {
    const height = isMobile ? '24px' : '48px'

    return (
      <TextWrapper>
        {children}
        {trashRendered}

        <MultiplePackagingsWrapper onClick={toggleDetails}>
          <VerticalDivider {...{ height }} />

          <MultiplePackagingsTxt>
            <FormattedMessage {...messages.multiplePackagings} />
          </MultiplePackagingsTxt>

          <VerticalDivider {...{ height }} />
        </MultiplePackagingsWrapper>
      </TextWrapper>
    )
  }

  return (
    <TextWrapper>
      {children}
      {trashRendered}
      <ButtonsContainerStyled
        {...{
          product,
          inTemplate,
          productId,
          addRemoveCallback,
          maxValue,
          disableInput,
          confirmStockRef,
          dateChangeCallback,
          refetchOnCartClick,
        }}
        unitData={unitOfMeasureObj}
        justifyContent={['center', 'space-between']}
        trashInsteadOfMinus={false}
        noSpaceBetween
        hideStar
      />
    </TextWrapper>
  )
}

export default ActionButtons
