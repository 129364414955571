import React from 'react'
import { useIntl } from 'react-intl'

import messages from '../../messages'

import FilePreview from './FilePreview'
import { AddFileLabel, AddFileInput, FileSelectorContainer } from './styles'

const FileSelector = ({ files, onChange, onBlur, onRemove }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <FileSelectorContainer>
        <AddFileLabel htmlFor="files">
          {formatMessage(messages.addFiles)}
        </AddFileLabel>

        <ul>
          {files.map(file => (
            <FilePreview
              key={file.name}
              name={file.name}
              size={file.size}
              onRemove={onRemove}
            />
          ))}
        </ul>
      </FileSelectorContainer>

      <AddFileInput
        multiple
        type="file"
        id="files"
        name="files"
        accept="image/*,.pdf,audio/*,video/*"
        onChange={event => {
          const filteredFiles = [...event.currentTarget.files]
          onChange(filteredFiles)
        }}
        onBlur={onBlur}
      />
    </>
  )
}

export default FileSelector
