import React from 'react'
import ReactDOM from 'react-dom'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import { get, identity, prop } from 'lodash/fp'
import { debounce } from 'lodash'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { bindActionCreators } from 'utils/redux-utils'
import dayjs from 'dayjs'
import NiceModal from '@ebay/nice-modal-react'

import { handleNumberChange } from 'utils'
import { MAX_INPUT_VALUE } from 'consts'
import container from 'containers/components/Product/ButtonsContainer'
import { userDataSelector } from 'containers/UserInfo/selectors'
import {
  isAvailableAfter,
  calculateAmountByQuantity,
  roundEnteredAmount,
  calculateQuantityByAmount,
  formatUnitAmount,
  getInCartAmount,
} from 'components/Product/utils'
import cartActions from 'containers/Cart/actions'
import { ELASTIC_AFFECT_TYPE } from 'containers/Cart/elasticStockUtils'
import TemplateModal from 'components/modal/TemplateModal'

import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'
import messages from '../messages'
import ProductButtons from '../ProductButtons'
import { StockExceeded, StockDecreased } from '../StockConfirmation'

import OutOfStock from './OutOfStock'
import OutOfStockPDP from './OutOfStockPDP'

class ButtonsContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    const { unitData } = props

    this.state = {
      unitData,
      amount: calculateAmountByQuantity(unitData, unitData.inCartQuantity),
      isDeleteFetching: false,

      affectedElasticStock: null,
    }

    this.onCartClick = debounce(this.onCartClick.bind(this), 300)
    this.onMinusClick = this.onMinusClick.bind(this)
    this.onPlusClick = this.onPlusClick.bind(this)
    this.onCartClick = this.onCartClick.bind(this)
    this.onStarClick = this.onStarClick.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onTrashClick = this.onTrashClick.bind(this)
  }

  static getDerivedStateFromProps({ unitData }, prevState) {
    if (
      unitData.inCartQuantity !== prevState.unitData.inCartQuantity ||
      unitData.unitOfMeasure !== prevState.unitData.unitOfMeasure
    ) {
      return {
        ...prevState,
        unitData,
        amount: calculateAmountByQuantity(unitData, unitData.inCartQuantity),
      }
    }

    return null
  }

  onInputChange = ({ target: { value } }) => {
    const { amount } = this.state
    let nextAmount = handleNumberChange(value)
    if (+nextAmount > MAX_INPUT_VALUE) {
      nextAmount = amount
    }
    this.setState({ amount: nextAmount })
  }

  onInputBlur() {
    const { amount } = this.state
    const { unitData } = this.props
    const { inCartQuantity } = unitData
    const inCartAmount = calculateAmountByQuantity(unitData, inCartQuantity)

    if (amount === '0') {
      this.setState({ amount })
      this.onTrashClick({
        successCallback: () => this.setState({ amount: unitData.multiplier }),
      })
      return
    }

    if (amount === '') {
      this.setState({ amount: inCartAmount })
      return
    }

    const newRoundedAmount = roundEnteredAmount(unitData, amount)
    const newAmount =
      newRoundedAmount === 0 ? unitData.multiplier : newRoundedAmount

    if (newAmount === inCartAmount) {
      this.setState({ amount: newAmount })
      return
    }

    this.setState({ amount: newAmount }, () => {
      if (inCartQuantity) {
        this.onCartClick()
      }
    })
  }

  onCartClick() {
    const { refetchOnCartClick } = this.props
    if (this.state.isDeleteFetching) return
    this.onAddAmount(
      this.state.amount,
      refetchOnCartClick && {
        onAddSuccessCallback: this.props.refetchCart,
      },
    )
  }

  onAddAmount(amount, options = {}) {
    const {
      suppressElasticStockCheck,
      onAddSuccessCallback = identity,
    } = options
    const {
      addToCart,
      productId,
      unitData,
      intl: { formatMessage },
      refetchDataCallback,
      addRemoveCallback = identity,
      onNotifyStockUnavailable,
      product,
      dateChangeCallback,
    } = this.props

    const unit = get('unitOfMeasure', unitData)
    const resetAmount = getInCartAmount(unitData)
    const quantity = calculateQuantityByAmount(unitData, amount)
    const justAddedToCart = !unitData.inCartQuantity

    addToCart({
      product,
      productId,
      unit,
      quantity,
      justAddedToCart,
      suppressStockNotification: !!onNotifyStockUnavailable,
      suppressElasticStockCheck,
      shiftOnDecreaseToClosestDate: !dateChangeCallback,
      callback: ({ affectedElasticStock }) => {
        if (affectedElasticStock) {
          const { unitOfMeasureObj } = affectedElasticStock
          this.setState({
            affectedElasticStock,
            amount: calculateAmountByQuantity(
              unitOfMeasureObj,
              unitOfMeasureObj.inCartQuantity,
            ),
          })
        } else {
          onAddSuccessCallback()
        }

        addRemoveCallback({
          justAddedToCart,
          quantity,
          unitOfMeasure: unit,
        })
      },
      refetchDataCallback,
      getMessage: stockAmount =>
        formatMessage(messages.maxAvailableAmount, {
          amount: formatUnitAmount(unitData, stockAmount),
        }),
      changeAmountToStockCallback: (stock, unitOfMeasure) => {
        if (onNotifyStockUnavailable) {
          onNotifyStockUnavailable(stock, unitOfMeasure)
        }
        this.setState({ amount: stock })
      },
      resetAmountCallback: () => {
        this.setState({
          amount: roundEnteredAmount(unitData, resetAmount),
        })
      },
    })
  }

  onTrashClick({ successCallback } = {}) {
    const {
      deleteCartItem,
      productId,
      addRemoveCallback,
      unitData: { unitOfMeasure },
      unitData,
      product,
    } = this.props

    const resetAmount = getInCartAmount(unitData)

    if (this.state.isDeleteFetching) return

    this.setState({ isDeleteFetching: true })

    deleteCartItem({
      product,
      productId,
      unitOfMeasure,
      successCallback: () => {
        if (successCallback) successCallback()

        addRemoveCallback({
          removedFromCart: true,
          unitOfMeasure,
        })

        this.setState({ isDeleteFetching: false })
      },
      errorCallback: () =>
        this.setState({
          isDeleteFetching: false,
          amount: roundEnteredAmount(unitData, resetAmount),
        }),
    })
  }

  onMinusClick(decreasedAmount) {
    const { unitData } = this.props

    this.setState(
      {
        amount: decreasedAmount,
      },
      () => {
        if (unitData.inCartQuantity) {
          this.onCartClick()
        }
      },
    )
  }

  onPlusClick(increasedAmount) {
    const { unitData } = this.props

    this.setState(
      {
        amount: increasedAmount,
      },
      () => {
        if (
          this.state.amount > unitData.multiplier &&
          unitData.inCartQuantity
        ) {
          this.onCartClick()
        }
      },
    )
  }

  onStarClick() {
    const {
      product,
      unitData: { unitOfMeasure },
    } = this.props

    return NiceModal.show(TemplateModal, {
      product,
      unitOfMeasure,
      itemListName: ITEM_LIST_NAMES.PRODUCT_DETAILS,
      itemListId: ITEM_LIST_IDS.PRODUCT_DETAILS,
    })
  }

  render() {
    const {
      hideCart,
      cartProduct,
      hideStar,
      outOfStock,
      hasTextStarBtn,
      inTemplate,
      productId,
      getReplacements,
      userData,
      confirmStockRef,
      refetchCart,
      dateChangeCallback,
      product,
      ...rest
    } = this.props
    const isSoonAvailable = isAvailableAfter(product, userData)
    const { amount, affectedElasticStock } = this.state
    const starProps = {
      onStarClick: this.onStarClick,
      active: inTemplate,
      productId,
    }

    if ((isSoonAvailable || outOfStock) && hasTextStarBtn) {
      return <OutOfStockPDP {...starProps} />
    }

    if ((isSoonAvailable || outOfStock) && !hideStar) {
      return (
        <OutOfStock
          {...starProps}
          {...{ product }}
          onGetReplacements={params =>
            getReplacements({ ...params, showInModal: true })
          }
        />
      )
    }

    const hasElasticStockIncreaseConfirmation =
      confirmStockRef &&
      prop('stockType', affectedElasticStock) === ELASTIC_AFFECT_TYPE.EXCEEDED
    const hasElasticStockDecreaseConfirmation =
      confirmStockRef &&
      prop('stockType', affectedElasticStock) === ELASTIC_AFFECT_TYPE.DECREASED

    return (
      <>
        {hasElasticStockDecreaseConfirmation &&
          ReactDOM.createPortal(
            <StockDecreased
              {...{ product }}
              closestDeliveryDate={dayjs(affectedElasticStock.deliveryDate)}
              onDiscard={() => this.setState({ affectedElasticStock: null })}
              onConfirm={() =>
                dateChangeCallback(
                  dayjs(affectedElasticStock.deliveryDate).format(),
                )
              }
            />,
            confirmStockRef,
          )}

        {hasElasticStockIncreaseConfirmation &&
          ReactDOM.createPortal(
            <StockExceeded
              {...{ productId }}
              unitOfMeasureObj={affectedElasticStock.unitOfMeasureObj}
              closestDeliveryDate={product.closestDeliveryTime}
              selectedDeliveryDate={affectedElasticStock.deliveryDate}
              onClose={() => this.setState({ affectedElasticStock: null })}
              onConfirmDateShift={() => {
                this.setState({
                  amount: calculateAmountByQuantity(
                    affectedElasticStock.unitOfMeasureObj,
                    affectedElasticStock.requestedQuantity,
                  ),
                  affectedElasticStock: null,
                })
                this.onAddAmount(
                  calculateAmountByQuantity(
                    affectedElasticStock.unitOfMeasureObj,
                    affectedElasticStock.requestedQuantity,
                  ),
                  {
                    suppressElasticStockCheck: true,
                    onAddSuccessCallback: () => refetchCart(),
                  },
                )
              }}
            />,
            confirmStockRef,
          )}

        <ProductButtons
          mr={0}
          my={[10, 0]}
          onPlusClick={this.onPlusClick}
          onMinusClick={this.onMinusClick}
          onCartClick={this.onCartClick}
          onTrashClick={this.onTrashClick}
          onInputChange={this.onInputChange}
          onInputBlur={this.onInputBlur}
          isInCart={!!rest.unitData.inCartQuantity}
          hideStar={hideStar}
          hideCart={hideCart}
          amount={amount}
          hasTextStarBtn={hasTextStarBtn}
          product={product}
          {...starProps}
          {...rest}
        />
      </>
    )
  }
}

export const mapStateToProps = createStructuredSelector({
  userData: userDataSelector,
})

export const mapDispatchToProps = bindActionCreators({
  refetchCart: cartActions.delta,
})

export default compose(
  container,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ButtonsContainer)
