import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { categorySubgroupActions } from 'containers/Categories/actions'
import { categorySubgroupSelector } from 'containers/Categories/selectors'
import { BreadCrumb } from 'components/BreadCrumbs'
import { useQueryFilters } from 'hooks'
import { ROUTES } from 'consts'
import { retailTrackCategoryView } from 'services/analytics/retail'

import ProductsBreadcrumbs from './ProductsBreadcrumbs'
import Title from './Title'
import {
  generatePathWithSearch,
  useCatalogTitle,
  useFetchOnQueryChange,
} from './utils'

const CategorySubGroup = ({ id, isPDP }) => {
  useFetchOnQueryChange({ actions: categorySubgroupActions, id })
  const { searchQuery } = useQueryFilters()

  const {
    name,
    parentCategoryId,
    parentCategoryName,
    parentGroupId,
    parentGroupName,
  } = useSelector(categorySubgroupSelector)

  const title = useCatalogTitle(name)

  useEffect(
    () => {
      if (parentCategoryName && parentGroupName && name && !isPDP) {
        retailTrackCategoryView({
          categoryName: parentCategoryName,
          groupName: parentGroupName,
          subgroupName: name,
        })
      }
    },
    [parentCategoryName, parentGroupName, name, isPDP],
  )

  return (
    <>
      <ProductsBreadcrumbs>
        {!!parentGroupName && (
          <>
            <BreadCrumb
              to={generatePathWithSearch(ROUTES.PRODUCTS, {
                category: parentCategoryId,
                search: searchQuery,
              })}
            >
              {parentCategoryName}
            </BreadCrumb>
            <BreadCrumb
              to={generatePathWithSearch(ROUTES.PRODUCTS, {
                category: parentCategoryId,
                group: parentGroupId,
                search: searchQuery,
              })}
            >
              {parentGroupName}
            </BreadCrumb>
            {isPDP && (
              <BreadCrumb
                to={generatePath(ROUTES.PRODUCTS, {
                  category: parentCategoryId,
                  group: parentGroupId,
                  subgroup: id,
                })}
              >
                {name}
              </BreadCrumb>
            )}
          </>
        )}
      </ProductsBreadcrumbs>

      {!isPDP && <Title title={title} />}
    </>
  )
}

export default CategorySubGroup
