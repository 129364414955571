import React from 'react'
import { useSelector } from 'react-redux'

import GlobalStylesComponent from 'globalStyles'
import { modalDisplaySelector } from './selectors'

const GlobalStyle = () => {
  const openModal = useSelector(modalDisplaySelector)

  return <GlobalStylesComponent {...{ openModal }} />
}

export default GlobalStyle
