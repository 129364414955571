import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { pick } from 'lodash/fp'

import { FILTERS_TYPES } from 'components/Filters/consts'

import filterMessages from 'components/Filters/messages'
import { stringifyQueryParams } from 'views/Products/utils'
import { useQueryFilters } from 'hooks'
import { generatePath } from 'react-router-dom'
import { selectAvailableFiltersData } from 'containers/Products/Filters/selectors'
import { HIGHLIGHT_MODE } from './consts'
import messages from './messages'

const mapFilterValues = filters => {
  const filtersPicked = pick(
    [
      FILTERS_TYPES.PROMOTION,
      FILTERS_TYPES.CLEARANCE,
      FILTERS_TYPES.OFFER_FEATURES,
    ],
    filters,
  )

  // return only if one selected
  const brandName =
    filters?.[FILTERS_TYPES.BRANDS]?.length === 1
      ? filters[FILTERS_TYPES.BRANDS][0]
      : null

  const filterValues = Object.entries(filtersPicked).reduce(
    (acc, [key, value]) => {
      if (Array.isArray(value)) return [...acc, ...value]

      return [...acc, key]
    },
    [],
  )

  return [filterValues, brandName]
}

export const useCatalogTitle = categoryName => {
  const { formatMessage } = useIntl()
  const { selectedFiltersParams, searchQuery } = useQueryFilters()

  const [filterValues, brandName] = useMemo(
    () => mapFilterValues(selectedFiltersParams),
    [selectedFiltersParams],
  )

  if (!filterValues.length) {
    if (searchQuery) {
      const firstLine = formatMessage(messages.search, { value: searchQuery })

      if (categoryName) {
        const inCategory = formatMessage(messages.inCategory, {
          category: categoryName,
        })

        return {
          firstLine,
          secondLine: brandName ? `${inCategory} ${brandName}` : inCategory,
        }
      }

      return {
        firstLine,
        secondLine: brandName ? ` ${brandName}` : null,
      }
    }

    return categoryName
      ? {
          firstLine: categoryName,
          secondLine: brandName ? ` ${brandName}` : null,
          mode: HIGHLIGHT_MODE.FILTER_LAST,
        }
      : {
          firstLine: formatMessage(messages.allProducts),
          secondLine: brandName,
          mode: HIGHLIGHT_MODE.FILTER_LAST,
        }
  }

  const filter1 = formatMessage(filterMessages[filterValues[0]]).toLowerCase()
  const filter2 = filterValues[1]
    ? formatMessage(filterMessages[filterValues[1]]).toLowerCase()
    : null

  let filters = filter1
  if (filterValues.length === 2) {
    filters = formatMessage(messages.twoFilters, { filter1, filter2 })
  } else if (filterValues.length > 2) {
    filters = formatMessage(messages.twoFiltersAndMore, { filter1, filter2 })
  }

  if (searchQuery) {
    if (!brandName) {
      const firstLine = categoryName
        ? filters
        : formatMessage(messages.filtersIn, { filters })

      const categorySuffix = formatMessage(messages.inCategory, {
        category: categoryName,
      })
      const secondLine = categoryName
        ? ` “${searchQuery}”${categorySuffix}`
        : `“${searchQuery}”`

      return {
        firstLine,
        secondLine,
        mode: HIGHLIGHT_MODE.FILTER_FIRST,
      }
    }

    return {
      firstLine: formatMessage(messages.filtersIn, {
        filters: formatMessage(messages.brandAppended, {
          line: filters,
          brandName,
        }),
      }),
      secondLine: `“${searchQuery}”`,
      mode: HIGHLIGHT_MODE.FILTER_FIRST,
    }
  }

  return categoryName
    ? {
        firstLine: formatMessage(messages.filtersIn, {
          filters: formatMessage(messages.brandAppended, {
            line: filters,
            brandName,
          }),
        }),
        secondLine: categoryName,
        mode: HIGHLIGHT_MODE.FILTER_FIRST,
      }
    : {
        firstLine: formatMessage(messages.all),
        secondLine: formatMessage(messages.brandAppended, {
          line: filters,
          brandName,
        }),
        mode: HIGHLIGHT_MODE.FILTER_LAST,
      }
}

export const useFetchOnQueryChange = ({ actions, id }) => {
  const { selectedFiltersParams, searchQuery } = useQueryFilters()

  const dispatch = useDispatch()
  const prevParams = useRef(null)

  useEffect(() => () => dispatch(actions.clear()), [])

  useEffect(
    () => {
      const params = { id, ...selectedFiltersParams, search: searchQuery }
      const paramsStr = stringifyQueryParams(params)
      if (paramsStr !== prevParams.current) {
        prevParams.current = paramsStr
        if (id || paramsStr) {
          dispatch(actions.delta(params))
        }
      }
    },
    [id, selectedFiltersParams],
  )
}

export const generatePathWithSearch = (route, { search, ...params }) => {
  let path = generatePath(route, params)
  if (search) {
    path += `?search=${search}`
  }
  return path
}

export const useCuisineFeaturesTitle = () => {
  const { formatMessage } = useIntl()
  const { selectedFiltersParams } = useQueryFilters()
  const availableFilters = useSelector(selectAvailableFiltersData)

  const cuisineFilter = useMemo(() =>
    availableFilters?.find(el => el.filterField === 'filter.cuisine_features'),
  )
  const selectedCuisinesLabels = useMemo(
    () => {
      if (!cuisineFilter) return []

      const options = cuisineFilter.items
      const selectedOptions =
        selectedFiltersParams[FILTERS_TYPES.CUISINE_FEATURES]

      return options.reduce((acc, { label, value }) => {
        if (selectedOptions?.includes(value)) {
          return [...acc, label]
        }

        return acc
      }, [])
    },
    [selectedFiltersParams, cuisineFilter],
  )

  const cuisineFeaturesTitle = useMemo(
    () => {
      if (selectedCuisinesLabels.length === 1) {
        return selectedCuisinesLabels[0]
      }

      if (selectedCuisinesLabels.length === 2) {
        return formatMessage(messages.twoFilters, {
          filter1: selectedCuisinesLabels[0],
          filter2: selectedCuisinesLabels[1],
        })
      }

      if (selectedCuisinesLabels.length > 2) {
        return formatMessage(messages.twoFiltersAndOther, {
          filter1: selectedCuisinesLabels[0],
          filter2: selectedCuisinesLabels[1],
        })
      }

      return null
    },
    [selectedCuisinesLabels],
  )

  if (cuisineFeaturesTitle) return `${cuisineFeaturesTitle}:`

  return null
}
