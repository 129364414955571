import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'lodash/fp'

import { bindActionCreators } from 'utils/redux-utils'
import { categorySelector } from 'containers/Categories/selectors'

import { getFiltersActions } from './Filters/actions'

import {
  makeProductsSelector,
  makePaginationSelector,
  selectFinished,
} from './selectors'
import actions from './actions'

export * from './consts'

export const mapStateToProps = createStructuredSelector({
  isFinished: selectFinished,
  products: makeProductsSelector,
  productsPagination: makePaginationSelector,
  category: categorySelector,
})

export const mapDispatchToProps = bindActionCreators({
  getProducts: actions.delta,
  clearProducts: actions.clear,
  getFilters: getFiltersActions.delta,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
