import React from 'react'

import theme from 'theme'

const InfoCircleSmall = ({ color = theme.colors.gray5 }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7.99935"
      cy="8.00033"
      r="5.33333"
      stroke={color}
      strokeWidth="1.4"
    />
    <path
      d="M8 8L8 9.83333C8 10.2936 8.3731 10.6667 8.83333 10.6667H9"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="7.99922" cy="6.0002" r="0.8" fill={color} />
  </svg>
)

export default InfoCircleSmall
