import { makeMessages, makeTranslation } from 'utils'

const messages = {
  keepAdding: 'keepAdding',
  toClaim: 'toClaim',
  claimed: 'claimed',
  canClaim: 'canClaim',
  canClaimAnother: 'canClaimAnother',
  maxClaimed: 'maxClaimed',
  regularInCart: 'regularInCart',
  productClaimed: 'productClaimed',
  uniqueProducts: 'uniqueProducts',
}

export default makeMessages(messages, makeTranslation('promoGroup.prizeRow'))
