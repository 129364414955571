const createFormData = ({ data }) => {
  const formData = new FormData()

  const noFilesKeys = Object.keys(data).filter(key => key !== 'files')
  noFilesKeys.forEach(key => formData.append(`details[${key}]`, data[key]))

  if (data.files.length) {
    data.files.forEach(file => formData.append('files[]', file, file.name))
  }

  return formData
}

export default createFormData
