import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { H4v2 } from 'components/Text'

export const SectionTitleContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.xs}
    ${theme.spacing.sm};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.xs}
      ${theme.spacing.base};
  }
`

export const SectionTitle = styled(H4v2)`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.l};
  }
`

export const SectionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    gap: 2px;
  }
`
