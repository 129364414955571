import styled from 'styled-components'

import theme from 'theme'

export const BtnPlaceholder = styled.button`
  width: 56px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 8px;
`

// prettier-ignore
export const AddToCartButton = styled(BtnPlaceholder)`
  background-color: ${p => p.$isInCart
    ? theme.colors.secondaryBgLight
    : theme.colors.secondary
};

  :hover {
    background-color: ${p => p.$isInCart
    ? theme.colors.secondaryBgLight
    : theme.colors.greenLight
};
  }
`

export const IconContainer = styled.div`
  height: 24px;
  width: 24px;
`
