import React from 'react'

import OrderHeader from './OrderHeader'
import OrderProduct from './OrderProduct'
import { IncomingContainer } from './styles'

const IncomingOrders = ({ items }) => (
  <IncomingContainer>
    {items.map(item => (
      <React.Fragment key={item.orderId}>
        <OrderHeader order={item} />

        {item.orderItems.map(product => (
          <OrderProduct key={product.productId} product={product} />
        ))}
      </React.Fragment>
    ))}
  </IncomingContainer>
)

export default IncomingOrders
