import { takeLatest, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import userInfoActions from 'containers/UserInfo/actions'

import { deleteAccount } from './api'
import actions from './actions'

export function* deleteAccountFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions,
      request: deleteAccount,
    })
    yield put(userInfoActions.delta())
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [takeLatest(actions.DELETE, deleteAccountFlow)]
