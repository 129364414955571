import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import Checkbox from 'components/Input/Checkbox'
import { ArrowLongLeft, CartCrossed } from 'components/Icons'
import { Link } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

import checkedSvg from './checked.svg'

export const Wrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.gray2};
  width: calc(100% - ${theme.spacing.lg});
  height: 56px;
  margin: 0 ${theme.spacing.md};
  font-size: 12px;
  font-weight: ${theme.fontWeights.regular};

  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    margin: 0 ${theme.spacing.base};
    width: calc(100% - 48px);
  }
`

export const Arrow = styled(ArrowLongLeft)`
  margin-right: ${theme.spacing.sm};
`

export const ContinueShopping = styled(Link)`
  font-size: 12px;
  font-weight: ${theme.fontWeights.regular};
  display: flex;
  align-items: center;
`

export const ClearCart = styled(Flex)`
  align-items: center;
  cursor: pointer;
  margin-left: ${theme.spacing.sm};
  padding-left: ${theme.spacing.sm};
  border-left: 1px solid ${theme.colors.gray2};
`

export const ClearIcon = styled(CartCrossed)`
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-left: ${theme.spacing.sm};
  }
`

const size = css`
  width: 20px;
  height: 20px;
`
export const CheckboxStyled = styled(Checkbox)`
  margin-left: ${theme.spacing.sm};

  > div {
    ${size};
    border: 1px solid ${theme.colors.borderGreen};
    border-radius: 6px;
    background: transparent;
    margin-left: 0;

    :hover {
      background: rgba(205, 244, 199, 0.4);
    }
  }

  input:checked + div {
    background: transparent;
    border: none;
  }

  input:checked + div::before {
    ${size};
    background-color: ${theme.colors.bgGreen};
    mix-blend-mode: multiply;
    border-radius: 6px;
    content: url("${checkedSvg}");
    border: none;
    margin: 0;
    padding: 5px;

  }

  input:hover + div::before {
    background-color: ${theme.colors.borderGreen};
  }
`
