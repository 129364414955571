import styled from 'styled-components'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

import { Container } from '../SectionColumn/styles'

export const CopyrightText = styled(BaseTextV2).attrs({
  isSmall: { fontSize: true },
})`
  display: block;
  color: ${theme.colors.lightGrey4};
`

export const BrandingContainer = styled(Container)`
  @media (min-width: 692px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

export const BrandingItems = styled.div`
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-content: space-between;
`
