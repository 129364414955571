const CAMPAIGN = {
  CLICKED_BANNER: 'clicked_banner',
}

export const trackCampaignBannerClicked = ({ title, customerNo, id }) => {
  window.dataLayer.push({
    event: CAMPAIGN.CLICKED_BANNER,
    customer_no: customerNo,
    campaign_id: id,
    campaign_name: title,
  })
}
