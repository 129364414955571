import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Flex } from '@rebass/grid'
import { first, findIndex, last, map, some } from 'lodash/fp'

import theme from 'theme'
import { deliveryDatesByProductIdsSelector } from 'containers/Cart/selectors'
import { ICONS } from 'components/Icon'
import { CalendarSmall } from 'components/Icons'

import { Icon } from '../styledComponents'
import { getRange, getPageFromIndex } from './utils'

const daysPerPage = 7

const DeliveryCalendar = ({
  startDate: startDateTmp,
  endDate: endDateTmp,
  selectedDeliveryDate,
  dateChangeHandler,
  productIds,
  className,
  isGroupChange,
  ...rest
}) => {
  const deliveryDates = useSelector(
    deliveryDatesByProductIdsSelector(productIds, true, true),
  )
  const availableDeliveryDates = useMemo(
    () => map('deliveryDate', deliveryDates),
    [deliveryDates],
  )
  const startDate = startDateTmp || first(availableDeliveryDates)
  const endDate = endDateTmp || last(availableDeliveryDates)
  const datesRange = getRange({ startDate, endDate, type: 'days' })
  const selectedDateIndexTmp = findIndex(({ dayObj }) =>
    selectedDeliveryDate.isSame(dayObj, 'day'),
  )(datesRange)
  const selectedDateIndex =
    selectedDateIndexTmp === -1 ? 0 : selectedDateIndexTmp

  const [page, setPage] = useState(
    selectedDeliveryDate
      ? getPageFromIndex({ index: selectedDateIndex, daysPerPage })
      : 1,
  )

  useEffect(
    () => {
      if (selectedDeliveryDate) {
        setPage(getPageFromIndex({ index: selectedDateIndex, daysPerPage }))
      }
    },
    [selectedDateIndex],
  )

  return (
    <Flex alignItems="center" {...rest}>
      {datesRange
        .slice(page > 1 ? (page - 1) * daysPerPage : 0, page * daysPerPage)
        .map(({ dayOfMonth, dayHumanized, dayObj }) => {
          const disabled = !some(d => dayjs(d).isSame(dayObj, 'day'))(
            availableDeliveryDates,
          )
          const active =
            selectedDeliveryDate && selectedDeliveryDate.isSame(dayObj, 'day')

          return (
            <CalendarSmall
              className={
                isGroupChange ? 'change-date-group' : 'change-date-product'
              }
              key={dayObj.utc().format()}
              color={active ? theme.colors.primary : theme.colors.gray2}
              numColor={disabled ? theme.colors.gray2 : theme.colors.primary}
              number={dayOfMonth}
              day={dayHumanized}
              onClick={() =>
                disabled ? {} : dateChangeHandler(dayObj.utc().format())
              }
              mr={theme.spacing.xs}
              style={
                disabled
                  ? { userSelect: 'none' }
                  : { userSelect: 'none', cursor: 'pointer' }
              }
            />
          )
        })}

      <Flex
        flexDirection="column"
        justifyContent="space-between"
        ml={theme.spacing.xs}
      >
        <Icon
          type={ICONS.CHEVRON_UP}
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
          style={{ marginBottom: '8px' }}
        />
        <Icon
          type={ICONS.CHEVRON_DOWN}
          onClick={() => setPage(page + 1)}
          disabled={page * daysPerPage >= datesRange.length}
        />
      </Flex>
    </Flex>
  )
}

export default DeliveryCalendar
