import React, { useCallback, useState, memo } from 'react'
import { Flex } from '@rebass/grid'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash/fp'

import theme from 'theme'
import { useOfMaxWidth } from 'hooks'

import { inactiveCartProductsSelector } from 'containers/Cart/selectors'
import cartActionsGroup, {
  setChangingDateProductId,
} from 'containers/Cart/actions'
import { BaseText } from 'components/Text'
import { InactiveProduct } from 'components/Product/CartProduct'
import { APP_BREAKPOINTS } from 'consts'

import { SectionHeader } from '../styledComponents'
import messages from '../messages'

import DeliveryProducts from './DeliveryProducts'
import { getApiProductsToUpdate } from './utils'
import {
  ProductSection,
  ProductsWrapper,
  InactiveListContainer,
  RemoveAllUnavailable,
  RemoveAllUnavailableBtn,
} from './styledComponents'

const CartProducts = ({ aggregatedCartData }) => {
  const dispatch = useDispatch()
  const inactiveProducts = useSelector(inactiveCartProductsSelector)

  const isLowerThanMain = useOfMaxWidth(APP_BREAKPOINTS.TMP_MAIN - 1)
  const [changingDateIndex, setChangingDateIndex] = useState(-1)
  const [changingRouteIndex, setChangingRouteIndex] = useState(-1)

  return (
    <ProductSection col="12">
      <ProductsWrapper col="12">
        {!!inactiveProducts.length && (
          <Flex
            mt={isLowerThanMain ? 32 : 80}
            flexDirection={isLowerThanMain ? 'column' : 'row'}
          >
            <SectionHeader
              borderedTop={!isLowerThanMain}
              style={isLowerThanMain ? { marginLeft: theme.spacing.sm } : {}}
            >
              <FormattedMessage {...messages.unavailableProducts} />
            </SectionHeader>

            <InactiveListContainer>
              {inactiveProducts.map(({ product, units }, index) => (
                <InactiveProduct
                  {...{ product, units }}
                  productId={product.id}
                  unitOfMeasure={get('0.unitOfMeasure')(units)}
                  key={product.id}
                  index={index}
                />
              ))}

              <RemoveAllUnavailable>
                <BaseText
                  bold
                  color={theme.colors.primary}
                  style={{ marginLeft: theme.spacing.sm }}
                >
                  <FormattedMessage {...messages.removeUnavailableInfo} />
                </BaseText>

                <RemoveAllUnavailableBtn
                  onClick={() =>
                    dispatch(
                      cartActionsGroup.updateDelta(
                        getApiProductsToUpdate(inactiveProducts),
                      ),
                    )
                  }
                >
                  <FormattedMessage {...messages.removeAllUnavailable} />
                </RemoveAllUnavailableBtn>
              </RemoveAllUnavailable>
            </InactiveListContainer>
          </Flex>
        )}

        {aggregatedCartData.map((delivery, index) => (
          <DeliveryProductsSection
            {...{
              delivery,
              index,
              setChangingRouteIndex,
              setChangingDateIndex,
            }}
            key={delivery.deliveryDate}
            dateChanging={changingDateIndex === index}
            routeChanging={changingRouteIndex === index}
          />
        ))}
      </ProductsWrapper>
    </ProductSection>
  )
}

const DeliveryProductsSection = ({
  setChangingRouteIndex,
  setChangingDateIndex,
  dateChanging,
  routeChanging,
  index,
  ...rest
}) => {
  const dispatch = useDispatch()
  const toggleDateChanging = useCallback(
    () => {
      dispatch(setChangingDateProductId(false))
      setChangingRouteIndex(-1)
      setChangingDateIndex(dateChanging ? -1 : index)
    },
    [dateChanging, index],
  )

  const toggleDateProductIdChanging = useCallback(productId => {
    setChangingDateIndex(-1)
    setChangingRouteIndex(-1)
    dispatch(setChangingDateProductId(productId))
  }, [])

  const toggleRouteChanging = useCallback(
    () => {
      dispatch(setChangingDateProductId(false))
      setChangingDateIndex(-1)
      setChangingRouteIndex(routeChanging ? -1 : index)
    },
    [routeChanging, index],
  )

  return (
    <DeliveryProducts
      {...{
        toggleDateChanging,
        toggleDateProductIdChanging,
        toggleRouteChanging,
        dateChanging,
        routeChanging,
      }}
      {...rest}
    />
  )
}

export default memo(CartProducts)
