import React from 'react'

import theme from 'theme'

const HeartSmall = ({ iconSize = 16, color = theme.colors.gray5 }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.11342 8.3776L7.25259 14.1592C7.65038 14.6067 8.34962 14.6067 8.74741 14.1592L13.8866 8.3776C15.0733 7.04249 15.0137 5.01373 13.7506 3.75061C12.2989 2.29886 9.89788 2.46949 8.66603 4.11196L8 5L7.33397 4.11196C6.10212 2.46949 3.70114 2.29886 2.24939 3.75061C0.986272 5.01373 0.926656 7.04249 2.11342 8.3776Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

export default HeartSmall
