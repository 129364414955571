export const INPUT_MAX_LENGTH = 500

export const OTHER_REASON_KEY = 'other'
export const REASON_KEYS = [
  'notNeeded',
  'orderedElsewhere',
  'wantToModify',
  'wrongPrice',
  'orderByMistake',
  OTHER_REASON_KEY,
]
