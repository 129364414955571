import React from 'react'

import theme from 'theme'

const Delivery = ({
  primaryColor = theme.colors.primary,
  secondaryColor = theme.colors.gray5,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.20907 7.74359L2.52268 3.35898C2.27076 2.70398 2.75425 2 3.45603 2H14.0055C14.4193 2 14.7903 2.25483 14.9389 2.64102L17 8H4.58241C4.4169 8 4.26849 7.89807 4.20907 7.74359Z"
      fill={secondaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.4 10C4.17909 10 4 10.1791 4 10.4V18C4 19.1046 4.89543 20 6 20H17H19C20.1046 20 21 19.1046 21 18V10.4C21 10.1791 20.8209 10 20.6 10H17H4.4Z"
      fill={primaryColor}
    />
    <rect x="9" y="14" width="7" height="2" rx="1" fill="white" />
    <path
      d="M21.8059 3.1644L21.0557 7.66576C21.0236 7.85863 20.8567 8 20.6611 8H17L17.8607 2.8356C17.9411 2.35341 18.3583 2 18.8471 2H20.8195C21.4375 2 21.9075 2.55487 21.8059 3.1644Z"
      fill={secondaryColor}
    />
  </svg>
)

export default Delivery
