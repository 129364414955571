import React from 'react'

const FloatingPriceBig = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="2" fill="#4764FC" />
    <rect x="9" y="4" width="2" height="8" rx="1" fill="white" />
    <rect
      x="14"
      y="7"
      width="2"
      height="8"
      rx="1"
      transform="rotate(90 14 7)"
      fill="white"
    />
    <rect
      x="14"
      y="14"
      width="2"
      height="8"
      rx="1"
      transform="rotate(90 14 14)"
      fill="white"
    />
  </svg>
)

export default FloatingPriceBig
