import React from 'react'

const Wheat = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2669 2.00142C21.6887 1.9756 22.0224 2.30584 21.9988 2.72584C21.9378 3.81563 21.6329 5.87501 20.2638 7.24572C18.8946 8.61643 16.8275 8.9316 15.7331 8.99858C15.3113 9.0244 14.9776 8.69416 15.0012 8.27416C15.0622 7.18437 15.3671 5.12499 16.7362 3.75428C18.1054 2.38357 20.1725 2.0684 21.2669 2.00142Z"
      fill="#99D0AC"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M5.17693 9.99185C4.865 9.64372 4.35165 9.63875 4.05322 9.98099C3.27714 10.871 1.95937 12.7011 2.00096 14.8291C2.04133 16.8942 3.3514 18.7033 4.17016 19.6359C4.19844 19.6736 4.23061 19.7097 4.26668 19.7435C4.29519 19.7729 4.32521 19.7996 4.35644 19.8236C5.2869 20.6419 7.10015 21.9586 9.17063 21.999C11.2987 22.0406 13.1288 20.7229 14.0188 19.9468C14.3611 19.6484 14.3561 19.1351 14.008 18.8232C13.1026 18.012 11.2463 16.6222 9.11826 16.5806C8.38807 16.5664 7.69297 16.7121 7.05888 16.9411C7.28797 16.307 7.4338 15.6117 7.41952 14.8814C7.37793 12.7535 5.98811 10.8972 5.17693 9.99185Z"
      fill="#229944"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M10.9046 4.26436C10.5927 3.91622 10.0793 3.91126 9.78088 4.25349C9.00479 5.14351 7.68702 6.9736 7.72862 9.10158C7.76898 11.1667 9.07905 12.9758 9.89781 13.9084C9.92609 13.9462 9.95827 13.9822 9.99434 14.016C10.0228 14.0454 10.0529 14.0721 10.0841 14.0961C11.0146 14.9144 12.8278 16.2311 14.8983 16.2715C17.0263 16.3131 18.8565 14.9954 19.7465 14.2193C20.0887 13.9209 20.0838 13.4076 19.7356 13.0957C18.8303 12.2845 16.974 10.8947 14.8459 10.8531C14.1157 10.8389 13.4206 10.9846 12.7865 11.2136C13.0156 10.5795 13.1615 9.88425 13.1472 9.15395C13.1056 7.02597 11.7158 5.16972 10.9046 4.26436Z"
      fill="#229944"
    />
  </svg>
)

export default Wheat
