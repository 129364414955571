import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

export const PrimaryDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing.base};
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    flex-wrap: nowrap;
    gap: 0;
  }
`

const Wrapper = styled.div`
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    flex: 1 0 33%;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    flex: 1 0;
  }
`

export const OrderSummaryWrapper = styled(Wrapper)`
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    order: 2;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    order: 3;
  }
`

export const OrderDetailsWrapper = styled(Wrapper)`
  margin: ${theme.spacing.base} 0;
  padding-bottom: ${theme.spacing.base};
  border-bottom: 1px solid ${theme.colors.semiTranspBg.grayLight};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin: 0;
    padding: 0;
    border-bottom: none;
    padding-top: ${theme.spacing.base};
    order: 1;
  }
`

export const BillingDetailsWrapper = styled(Wrapper)`
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    order: 3;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding-top: ${theme.spacing.base};
    padding-left: ${theme.spacing.base};
    margin-right: ${theme.spacing.base};
    margin-left: ${theme.spacing.base};
    border-left: 1px solid ${theme.colors.semiTranspBg.grayLight};
    order: 2;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_WIDE_START}px) {
    padding-left: ${theme.spacing.mlg};
    margin-right: ${theme.spacing.mlg};
    margin-left: ${theme.spacing.mlg};
  }
`
