import { makeMessages, makeTranslation } from 'utils'

import { FILTERS_VALUES } from './consts'

const { OFFER_FEATURES } = FILTERS_VALUES

const messages = {
  title: { id: 'catalog.filters.title' },
  clearAll: { id: 'catalog.filters.clearAll' },
  searchPlaceholder: { id: 'catalog.filters.searchPlaceholder' },
  applyBtn: { id: 'catalog.filters.applyBtn' },
  showAll: { id: 'catalog.filters.showAll' },
  close: { id: 'catalog.filters.close' },
  brands: 'brands',
  productFeatures: 'product_features',
  offerFeatures: 'offer_features',
  categories: 'categories',
  roomTemp: 'storage_type.room_temp',
  frozen: 'storage_type.frozen',
  chilled: 'storage_type.chilled',
  roomTempDesc: 'storage_type.room_temp_desc',
  frozenDesc: 'storage_type.frozen_desc',
  chilledDesc: 'storage_type.chilled_desc',
  'filter.storage_type.room_temp': {
    id: 'filter.storage_type.room_temp',
  },
  'filter.storage_type.frozen': {
    id: 'filter.storage_type.frozen',
  },
  'filter.storage_type.chilled': {
    id: 'filter.storage_type.chilled',
  },
  onStock: 'on_stock',
  'filter.on_stock.available': 'on_stock.available',
  'filter.on_stock.all': 'on_stock.all',
  promotion: 'promotion',
  clearance: 'clearance',
  'filter.offer_features.novelty': 'offer_features.novelty',
  'filter.offer_features.bestseller': 'offer_features.bestseller',
  'filter.offer_features.bidmarket': 'offer_features.bidmarket',
  'filter.offer_features.recommendation': 'offer_features.recommendation',
  cuisines: 'cuisines',

  // translation keys used for products listing titles generation
  [OFFER_FEATURES.NOVELTY]: 'offer_features.novelty',
  [OFFER_FEATURES.BESTSELLER]: 'offer_features.bestseller',
  [OFFER_FEATURES.BIDMARKET]: 'offer_features.bidmarket',
  [OFFER_FEATURES.RECOMMENDATION]: 'offer_features.recommendation',
}

export default makeMessages(messages, makeTranslation('filter'))
