import React, { useCallback, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { toString } from 'lodash/fp'
import { useDispatch } from 'react-redux'

import { ROUTES } from 'consts'
import { NavLink } from 'components/Text'
import { trackChangedName } from 'services/analytics'
import ListInput from 'components/Input/ListInput'
import { templatesActions } from 'containers/Templates/actions'
import RemoveTemplateConfirmation from 'views/Templates/TemplateDetails/RemoveTemplateConfirmation'
import TemplateEntry from './TemplateEntry'

import TemplateMenu from './TemplateMenu'
import { Container, Content, Inner, EditInputContainer } from './styles'

const TemplateListItem = ({
  id,
  name,
  itemsCount,
  onClose,
  icon,
  ContentContainer,
  hasMenu,
  onEdit,
  onEditComplete,
  isEditing,
  isMenuOpen,
  onOpenMenu,
  onCloseMenu,
}) => {
  const dispatch = useDispatch()
  const ChildContainer = ContentContainer || Content
  const [isRemoveConfirmOpen, setIsRemoveConfirmOpen] = useState(false)

  const renameTemplateCallback = useCallback(
    ({ name: nextName }, meta) => {
      dispatch(templatesActions.updateDelta({ id, name: nextName }, meta))
    },
    [id],
  )

  if (isEditing) {
    return (
      <EditInputContainer>
        <ListInput
          value={name}
          onClose={onEditComplete}
          onSubmit={renameTemplateCallback}
          onSuccess={() => {
            onEditComplete()
            onCloseMenu()
            trackChangedName(id)
          }}
        />
      </EditInputContainer>
    )
  }

  return (
    <Container
      data-test-id={`template_list_item_${(name || toString(id)).replace(
        /\s/g,
        '_',
      )}`}
      onClick={onClose}
      isMenuOpen={isMenuOpen}
    >
      {isRemoveConfirmOpen && (
        <RemoveTemplateConfirmation
          name={name}
          id={id}
          onClose={() => setIsRemoveConfirmOpen(false)}
          trigger="dropdown_menu"
        />
      )}

      <ChildContainer>
        <NavLink isTemplate to={generatePath(ROUTES.TEMPLATE_DETAILS, { id })}>
          <Inner>
            <TemplateEntry title={name} count={itemsCount} {...{ icon }} />

            {hasMenu && (
              <TemplateMenu
                isOpen={isMenuOpen}
                onToggleOpen={() => onOpenMenu(id)}
                onClose={onCloseMenu}
                onEdit={() => onEdit(id)}
                onRemove={() => {
                  onCloseMenu()
                  setIsRemoveConfirmOpen(true)
                }}
              />
            )}
          </Inner>
        </NavLink>
      </ChildContainer>
    </Container>
  )
}

export default TemplateListItem
