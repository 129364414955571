import { createSelector } from 'reselect'
import _getOr from 'lodash/fp/getOr'
import _find from 'lodash/fp/find'

import {
  makeDataSelector,
  makeIsFinishedSelector,
  makePaginationSelector as _makePaginationSelector,
} from 'utils/redux-utils'

import { PRODUCTS_REDUCER_NAME, PRODUCT_ITEMS_REDUCER_NAME } from './consts'

export const selectData = makeDataSelector([
  PRODUCTS_REDUCER_NAME,
  PRODUCT_ITEMS_REDUCER_NAME,
])
export const selectFinished = makeIsFinishedSelector([
  PRODUCTS_REDUCER_NAME,
  PRODUCT_ITEMS_REDUCER_NAME,
])

export const makeProductsSelector = createSelector(
  selectData,
  _getOr([], 'products'),
)

export const makePaginationSelector = _makePaginationSelector(selectData)

export const selectProductById = id =>
  createSelector(makeProductsSelector, _find({ id }))
