import React from 'react'
import { FormattedMessage } from 'react-intl'

import TextTooltip from 'components/Tooltip/TextTooltip'

import messages from 'components/Product/messages'

const ProductLicenseTooltip = ({ children }) => (
  <TextTooltip
    content={<FormattedMessage {...messages.missingLicenseTooltipText} />}
  >
    {children}
  </TextTooltip>
)

export default ProductLicenseTooltip
