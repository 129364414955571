import React from 'react'

import { LabelsContainer, Label, LabelText } from './styles'

const LabelsRow = ({ labels }) => {
  if (!labels.length) return null

  return (
    <LabelsContainer>
      {labels.map(label => (
        <Label key={label}>
          <LabelText>{label}</LabelText>
        </Label>
      ))}
    </LabelsContainer>
  )
}

export default LabelsRow
