import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SearchV2, X } from 'components/Icons'
import { trackSearchIconClicked } from 'services/analytics/template'
import theme from 'theme'
import {
  SearchInput,
  SearchBtn,
  SearchContainer,
  XContainer,
} from 'views/Templates/TemplateDetails/styles'
import { debounce } from 'lodash'

const SEARCH_MIN_LENGTH = 3

const Search = ({ searchItems, lastSearchedValue, id, type }) => {
  const [value, setValue] = useState('')
  const [isActive, setIsActive] = useState(false)

  const inputRef = useRef(null)
  const containerRef = useRef(null)

  const searchButtonHandler = () => {
    if (value) return
    setIsActive(!isActive)
  }

  const handleInputBlur = e => {
    // don't blur if clicked element is inside container
    const clickedEl = e.relatedTarget
    if (containerRef.current.contains(clickedEl)) {
      return
    }

    if (!value) setIsActive(false)
  }

  useEffect(
    () => {
      if (isActive) {
        inputRef.current.focus()
        trackSearchIconClicked(id, type)
      } else {
        inputRef.current.blur()
      }
    },
    [isActive],
  )

  const resetInput = () => {
    setValue('')
    setIsActive(false)
  }

  useEffect(resetInput, [id])

  useEffect(
    () => {
      if (lastSearchedValue === '') resetInput()
    },
    [lastSearchedValue],
  )

  const validateSearch = useCallback(
    currentValue => {
      if (
        currentValue.length < SEARCH_MIN_LENGTH &&
        lastSearchedValue.length < SEARCH_MIN_LENGTH
      ) {
        return
      }
      if (currentValue.length >= SEARCH_MIN_LENGTH) searchItems(currentValue)
      else searchItems('')
    },
    [searchItems, lastSearchedValue],
  )

  const debouncedSearch = useMemo(() => debounce(validateSearch, 500), [
    validateSearch,
  ])

  const clearButtonHandler = () => {
    setValue('')
    debouncedSearch('')
    setIsActive(!isActive)
  }

  const handleInputChange = val => {
    setValue(val)
    debouncedSearch(val)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      debouncedSearch.cancel()
      validateSearch(e.target.value)
    }
  }

  return (
    <SearchContainer
      ref={containerRef}
      isActive={isActive}
      data-test-id="template-search-container"
    >
      <SearchBtn
        onClick={() => searchButtonHandler()}
        data-test-id="template-search-button"
      >
        <SearchV2 />
      </SearchBtn>

      <SearchInput
        data-test-id={`template-search-input-${
          isActive ? 'active' : 'inactive'
        }`}
        disabled={!isActive}
        ref={inputRef}
        value={value}
        onKeyDown={e => handleKeyDown(e)}
        onChange={e => handleInputChange(e.target.value)}
        onBlur={e => handleInputBlur(e)}
      />

      {value && (
        <XContainer
          data-test-id="template-search-clear"
          onClick={() => clearButtonHandler()}
        >
          <X color={theme.colors.gray5} />
        </XContainer>
      )}
    </SearchContainer>
  )
}

export default Search
