import React from 'react'

import theme from 'theme'

const CalendarV3 = ({ color = theme.colors.primary }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-test-id="calendarV3-icon"
  >
    <path
      d="M4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V11.3057C20 12.2834 19.2932 13.1178 18.3288 13.2785L15.4091 13.7651C14.5662 13.9056 13.9056 14.5662 13.7651 15.4091L13.4178 17.4932C13.1767 18.9398 11.9251 20 10.4586 20H6.16228C4.1146 20 2.69349 17.9991 3.29425 16.0415C4 13.7418 4 13.12 4 11V7Z"
      stroke={color}
      strokeWidth="2"
    />
    <path d="M4 9H20" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M8 5V3" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M16 5V3" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path
      d="M9 20H12C17 20 20 13 20 12V10"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CalendarV3
