import React from 'react'

const Burger = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12C2 13.1066 2.89333 14 4 14H12C13.1067 14 14 13.1066 14 12V11.3333H2V12Z"
      fill="#A6B3C0"
    />
    <path d="M2 6C2 6 2 2 8 2C14 2 14 6 14 6H2Z" fill="#A6B3C0" />
    <path
      d="M12.8 9.99998C13.466 9.99998 14 9.40665 14 8.66665C14 8.31302 13.8736 7.97389 13.6485 7.72384C13.4235 7.47379 13.1183 7.33331 12.8 7.33331H11.6L10.1 8.66665L8.6 7.33331H3.2C2.88174 7.33331 2.57652 7.47379 2.35147 7.72384C2.12643 7.97389 2 8.31302 2 8.66665C2 9.02027 2.12643 9.35941 2.35147 9.60946C2.57652 9.8595 2.88174 9.99998 3.2 9.99998H12.8Z"
      fill="#A6B3C0"
    />
  </svg>
)

export default Burger
