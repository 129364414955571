import React from 'react'
import { useIntl } from 'react-intl'
import { Flex } from '@rebass/grid'

import theme, { paymentTheme } from 'theme'
import { formatPrice } from 'utils'

import { TertiaryText } from 'components/Text'
import { FloatingPriceWrapper } from 'components/FloatingPrice'
import Row from './Row'

import { BoldHeader, HalfBox, SummaryFloatingPriceInfo } from '../styles'
import messages from '../messages'

const { colors, spacing } = theme
const { yellow } = paymentTheme

const AmountsSummary = ({
  totalGross,
  totalNet,
  paymentMethodCode,
  paymentTermCode,
  mainColor,
  isSuccess,
  isMobile,
  anyFloatingPrice,
}) => {
  const { formatMessage } = useIntl()
  const hasFloatingPriceInfoBox = isMobile && anyFloatingPrice

  return (
    <HalfBox withPaddingLeft={!isMobile} justifyContent="space-between">
      <Flex flexDirection="column" mt={isMobile ? undefined : 14}>
        <Row label="amountNet" value={formatPrice(totalNet)} />

        <Row label="orderVAT" value={formatPrice(totalGross - totalNet)} />

        <Row label="delivery" value={formatMessage(messages.free)} />

        <Flex height="1px" bg={colors.fadedGray} mt="12px" mb="20px" />

        <Row label="payment" value={paymentMethodCode} />

        <Row label="paymentDate" value={paymentTermCode} />
      </Flex>

      <Flex
        height={88}
        mt={spacing.ss}
        p={spacing.base}
        bg={isSuccess ? colors.greenLight3 : yellow.bg}
        justifyContent="space-between"
        alignItems="center"
      >
        <TertiaryText bold color={mainColor}>
          {formatMessage(messages.grossSumLong)}
        </TertiaryText>
        <FloatingPriceWrapper
          hasFloatingPrice={anyFloatingPrice}
          iconBgColor={theme.colors.transparent}
          iconColor={mainColor}
          iconBorderColor={mainColor}
        >
          <BoldHeader strongBold color={mainColor}>
            {formatPrice(totalGross)}
          </BoldHeader>
        </FloatingPriceWrapper>
      </Flex>
      {hasFloatingPriceInfoBox && (
        <SummaryFloatingPriceInfo
          infoText={formatMessage(messages.floatingPriceInfo)}
        />
      )}
    </HalfBox>
  )
}

export default AmountsSummary
