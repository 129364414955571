import React from 'react'

import { paymentTheme } from 'theme'

const BadgeAlert = ({
  bgColor = paymentTheme.yellow.bg,
  contentColor = paymentTheme.yellow.text,
  width = 18,
  height = 18,
  ...rest
}) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <rect x="1" y="1" width="16" height="16" rx="8" fill={bgColor} />
    <path
      d="M7.83239 5.78125C7.81462 5.35515 8.15526 5 8.58174 5H9.4181C9.84456 5 10.1852 5.35513 10.1674 5.78122L10.02 9.32082C10.0088 9.58863 9.78845 9.8 9.5204 9.8H8.47958C8.21154 9.8 7.99118 9.58864 7.98002 9.32084L7.83239 5.78125Z"
      fill={contentColor}
    />
    <circle cx="8.9998" cy="12.2" r="1.2" fill={contentColor} />
    <rect
      x="1"
      y="1"
      width="16"
      height="16"
      rx="8"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)

export default BadgeAlert
