import { makeMessages, makeTranslation } from 'utils'

const messages = {
  modalHeader: 'modalHeader',
  addedNotification: 'addedNotification',
  addedNotificationError: { id: 'templates.error.addedNotification' },
  removedNotification: 'removedNotification',
  removedNotificationError: { id: 'templates.error.removedNotification' },
  createNotification: 'createNotification',
}

export default makeMessages(messages, makeTranslation('templates'))
