export const login = ({ body }) => {
  const url = 'auth/login'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const signUp = ({ body }) => {
  const url = '/signup'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const signUpMigrate = ({ body }) => {
  const url = '/signup/user_migration'
  const options = {
    method: 'POST',
    body: {
      agreement_2: false,
      agreement_3: false,
      agreement_4: false,
      ...body,
    },
  }
  return { url, options }
}

export const confirmEmail = ({ body }) => {
  const url = '/signup/confirm_email'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const resendConfirmEmail = ({ body }) => {
  const url = '/signup/resend_confirm_email'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const setPassword = ({ body }) => {
  const url = '/me/password'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const changePassword = ({ body }) => {
  const url = '/me/password'
  const options = {
    method: 'PUT',
    body,
  }
  return { url, options }
}

export const validateToken = ({ body }) => {
  const url = '/me/password/validate_token'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const resendSetPassword = ({ body }) => {
  const url = '/signup/resend_set_password'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const resendPasswordReset = ({ body }) => {
  const url = '/me/password/resend_reset'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const resetPassword = ({ body }) => {
  const url = '/me/password/reset'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const confirmResetPassword = ({ body }) => {
  const url = '/me/password'
  const options = {
    method: 'POST',
    body,
  }
  return { url, options }
}

export const checkLogin = ({ body }) => {
  const url = '/signup/check_login'

  const options = {
    method: 'POST',
    body,
  }

  return { url, options }
}
