import React from 'react'

const Spices = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.46352 16.0755L6.99051 13.7673C5.94645 12.7928 5.69661 11.34 6.39015 10.2762L10.1195 4.40027C11.2562 2.6567 13.9507 2.51817 15.6619 4.11534L19.805 7.98237C21.5162 9.57954 21.3678 12.0945 19.4998 13.1554L13.2039 16.6358C12.0641 17.2832 10.5076 17.05 9.46352 16.0755ZM11.0479 14.5967C11.2635 14.7979 11.5849 14.8461 11.8203 14.7124L18.1162 11.232C18.7556 10.8688 18.8064 10.0079 18.2207 9.46116L14.0775 5.59413C13.4917 5.04738 12.5694 5.09481 12.1802 5.69167L8.45091 11.5676C8.30768 11.7872 8.35928 12.0873 8.57489 12.2885L11.0479 14.5967Z"
      fill="#99D0AC"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M11.8201 14.7125C11.5847 14.8462 11.2633 14.798 11.0477 14.5968L8.57467 12.2886C8.35906 12.0873 8.30747 11.7873 8.45069 11.5676L9.58881 9.77441L16.769 11.9767L11.8201 14.7125Z"
      fill="#229944"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M8.58989 20.0409C7.31112 21.3197 5.23784 21.3197 3.95907 20.0409L-nan -nanL3.95907 20.0409C2.68031 18.7622 2.68031 16.6889 3.95907 15.4101L4.11343 15.2558C4.45444 14.9147 5.00731 14.9147 5.34832 15.2558L8.74425 18.6517C9.08525 18.9927 9.08525 19.5456 8.74425 19.8866L8.58989 20.0409Z"
      fill="#229944"
    />
  </svg>
)

export default Spices
