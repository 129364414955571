import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash/fp'

import { productDetailsActions } from 'containers/ProductDetails/actions'
import {
  productDetailsSelector,
  productDetailsFetchingSelector,
} from 'containers/ProductDetails/selectors'
import { promoGroupFetchingSelector } from 'containers/Promotions/Groups/selectors'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { isProductOutOfStock, isOutOfStock } from 'utils'
import LoaderFullHeight from 'components/LoaderFullHeight'
import { isAvailableAfter } from 'components/Product/utils'
import { retailTrackDetailView } from 'services/analytics/retail'
import {
  findUnitData,
  SCREENS,
  useViewItemTracking,
  withPageView,
} from 'services/analytics'
import ProductsCarousel from 'components/ProductsCarousel'
import useChangedLocationModal from 'hooks/useChangedLocationModal'
import usePDPCarouselData from './utils/usePDPCarouselData'

import { ProductDetailsTopSection, ProductDetailsContainer } from './styles'
import ProductImageSection from './ProductImageSection'
import ProductInfoSection from './ProductInfoSection'
import CartControlsSection from './CartControlsSection'

const ProductDetails = () => {
  const [selectedUnit, setSelectedUnit] = useState('')
  const { id } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()

  const userData = useSelector(userDataSelector)
  const product = useSelector(productDetailsSelector)
  const isPromoGroupFetching = useSelector(promoGroupFetchingSelector)
  const isProductDetailsFetching = useSelector(productDetailsFetchingSelector)
  const isPDPLoading = isPromoGroupFetching || isProductDetailsFetching
  const hasProduct = product.id && selectedUnit

  const unitData = findUnitData(product.unitsOfMeasure, selectedUnit) || {}
  const isProductInactive = !isEmpty(product) && !product.active
  const isSoonAvailable = isAvailableAfter(product, userData)
  const isProductDisabled = isProductInactive || isProductOutOfStock(product)
  const isUnitOutOfStock = isOutOfStock({
    stock: unitData.stock,
    nonStock: product.nonStock,
  })
  const isUnitDisabled =
    isProductInactive || isSoonAvailable || isUnitOutOfStock

  useChangedLocationModal({ isReady: !isPDPLoading && hasProduct })

  const {
    carouselRef,
    carouselTitle,
    carouselProducts,
    isCarouselLoading,
    itemListName,
    itemListId,
    itemModelId,
    attributionToken,
  } = usePDPCarouselData({ isUnitDisabled, productId: product.id })

  useEffect(
    () => {
      // need for same route navigation (e.g. clicking on carousel item in PDP)
      window.scrollTo(0, 0)
      dispatch(productDetailsActions.delta({ id }))

      return () => {
        dispatch(productDetailsActions.clear())
      }
    },
    [id],
  )

  const trackViewItem = useViewItemTracking({
    product,
    itemListId: location.state?.itemListId,
    itemListName: location.state?.itemListName,
    itemModelId: location.state?.itemModelId,
    index: location.state?.index,
    unitOfMeasure: product.baseUnitOfMeasure,
  })

  useEffect(
    () => {
      if (product.id) {
        retailTrackDetailView({
          product,
          attributionToken: location.state?.attributionToken,
        })
      }

      trackViewItem()
      setSelectedUnit(product.baseUnitOfMeasure)
    },
    [product.id],
  )

  const hasReplacements = isUnitDisabled && !!carouselProducts.length

  return (
    <>
      {isPDPLoading && <LoaderFullHeight />}
      {hasProduct ? (
        <ProductDetailsContainer>
          <ProductDetailsTopSection>
            <ProductImageSection
              product={product}
              isProductDisabled={isProductDisabled}
            />
            <CartControlsSection
              product={product}
              unitData={unitData}
              selectedUnit={selectedUnit}
              setSelectedUnit={setSelectedUnit}
              isSoonAvailable={isSoonAvailable}
              isUnitDisabled={isUnitDisabled}
              isUnitOutOfStock={isUnitOutOfStock}
              hasReplacements={hasReplacements}
            />
            <ProductInfoSection product={product} />
          </ProductDetailsTopSection>

          <ProductsCarousel
            ref={carouselRef}
            itemModelId={itemModelId}
            attributionToken={attributionToken}
            isLoading={isCarouselLoading}
            products={carouselProducts}
            itemListName={itemListName}
            itemListId={itemListId}
            title={carouselTitle}
          />
        </ProductDetailsContainer>
      ) : null}
    </>
  )
}

export default withPageView(SCREENS.PDP)(ProductDetails)
