import React from 'react'

import theme from 'theme'

const Filtering = ({ color = theme.colors.primary }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 15L21 27H27L36.5 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 27H27V34C26 36 23.5 37.5 21 38V27Z"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <ellipse
      cx="24"
      cy="14"
      rx="13"
      ry="3"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect
      x="19"
      y="3"
      width="8"
      height="10"
      rx="2"
      fill="white"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <ellipse cx="24" cy="44.5" rx="5" ry="1.5" fill="#131B4D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 8C23.8954 8 23 8.89543 23 10V16.9915C23.33 16.9973 23.6635 17.0002 24 17.0002C26.5772 17.0002 28.9794 16.8272 31 16.5286V10C31 8.89543 30.1046 8 29 8H25Z"
      fill="white"
    />
    <path
      d="M23 16.9915H22C22 17.5369 22.4371 17.9818 22.9825 17.9913L23 16.9915ZM31 16.5286L31.1462 17.5179C31.6367 17.4454 32 17.0244 32 16.5286H31ZM24 10C24 9.44772 24.4477 9 25 9V7C23.3431 7 22 8.34315 22 10H24ZM24 16.9915V10H22V16.9915H24ZM24 16.0002C23.6694 16.0002 23.3417 15.9973 23.0175 15.9917L22.9825 17.9913C23.3183 17.9972 23.6577 18.0002 24 18.0002V16.0002ZM30.8538 15.5394C28.8878 15.8298 26.5351 16.0002 24 16.0002V18.0002C26.6194 18.0002 29.0709 17.8245 31.1462 17.5179L30.8538 15.5394ZM30 10V16.5286H32V10H30ZM29 9C29.5523 9 30 9.44772 30 10H32C32 8.34315 30.6569 7 29 7V9ZM25 9H29V7H25V9Z"
      fill={color}
    />
  </svg>
)

export default Filtering
