import React from 'react'
import {
  ParameterCollection,
  ParameterCollectionList,
  ParameterLine,
  ParameterName,
  ParameterValue,
  ProductParametersContainer,
} from 'views/ProductDetails/ProductInfoSection/ProductParameters/styles'
import { H4v2 } from 'components/Text'

const ProductParameters = ({ parameters }) => (
  <ProductParametersContainer>
    {parameters.map(collection => (
      <ParameterCollection key={collection.name}>
        <H4v2>{collection.name}</H4v2>
        <ParameterCollectionList>
          {collection.parameters.map(param => (
            <ParameterLine key={param.name}>
              <ParameterName>{param.name}:</ParameterName>
              <ParameterValue>{param.value}</ParameterValue>
            </ParameterLine>
          ))}
        </ParameterCollectionList>
      </ParameterCollection>
    ))}
  </ProductParametersContainer>
)

export default ProductParameters
