import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import RecommendedBox from 'components/RecommendedBox'
import { RECOMMENDATION_PAGE_NAMES } from 'components/RecommendedBox/consts'
import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'

import {
  CatalogRecommendedContainer,
  NoResultsRecommendedContainer,
} from './styles'

const Recommendations = ({ isEmptyListing }) => {
  const {
    category: categoryId,
    group: groupId,
    subgroup: subgroupId,
  } = useParams()

  const Container = useMemo(
    () =>
      isEmptyListing
        ? NoResultsRecommendedContainer
        : CatalogRecommendedContainer,
    [isEmptyListing],
  )

  const { itemListId, itemListName } = useMemo(
    () => {
      if (categoryId) {
        const param = `${ITEM_LIST_IDS.RECOMMENDATION_CATEGORY}${categoryId}`
        return {
          itemListId: param,
          itemListName: param,
        }
      }

      return {
        itemListId: ITEM_LIST_IDS.RECOMMENDATION_ALL_PRODUCTS,
        itemListName: ITEM_LIST_NAMES.RECOMMENDATION_ALL_PRODUCTS,
      }
    },
    [categoryId],
  )

  return (
    <RecommendedBox
      categoryId={categoryId}
      groupId={groupId}
      subgroupId={subgroupId}
      pageName={RECOMMENDATION_PAGE_NAMES.CATEGORY_PAGE}
      Container={Container}
      itemListId={itemListId}
      itemListName={itemListName}
    />
  )
}

export default Recommendations
