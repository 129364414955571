import { ICONS } from 'components/Icon'
import { ALERT_TYPE } from './consts'

export const getIcon = type => {
  switch (type) {
    case ALERT_TYPE.ERROR:
      return ICONS.ERROR
    case ALERT_TYPE.INFO:
      return ICONS.INFO
    default:
      return ICONS.SUCCESS
  }
}
