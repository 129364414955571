import { takeLatest, call, select, put } from 'redux-saga/effects'
import { startSubmit, stopSubmit } from 'redux-form'
import history from 'utils/history'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'

import { changePassword } from 'containers/Auth/api'
import { genericGetDataEnhanced } from 'containers/App/sagas'
import { formValuesSelector } from 'utils/redux-form-utils'
import { logout } from 'containers/Auth/actions'
import { ROUTES } from 'consts'

import { changePasswordActions } from './actions'
import { CHANGE_PASSWORD_FORM_NAME } from './consts'
import { isPasswordInvalidError } from './utils'

export function* changePasswordFlow() {
  try {
    yield put(startSubmit(CHANGE_PASSWORD_FORM_NAME))

    yield put(changePasswordActions.clear())
    const formValues = yield select(
      formValuesSelector(CHANGE_PASSWORD_FORM_NAME),
    )

    yield call(genericGetDataEnhanced, {
      actions: changePasswordActions,
      request: changePassword,
      params: {
        body: formValues,
      },
    })

    yield put(
      logout({
        callback() {
          history.push(ROUTES.CHANGE_PASSWORD_CONFIRM)
        },
      }),
    )
  } catch (error) {
    if (!isPasswordInvalidError(error)) {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }

  yield put(stopSubmit(CHANGE_PASSWORD_FORM_NAME))
}

export default [takeLatest(changePasswordActions.DELTA, changePasswordFlow)]
