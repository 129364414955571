import React from 'react'
import prettyBytes from 'pretty-bytes'

import theme from 'theme'
import { XSmall } from 'components/Icons'

import { Container, RemoveBtn, FileName, FileSize } from './styles'

const FilePreview = ({ name, size, onRemove }) => (
  <Container>
    <FileName>{name}</FileName>
    <FileSize>{prettyBytes(size)}</FileSize>
    <RemoveBtn
      type="button"
      onClick={() => onRemove(name)}
      data-test-id={`${name}-remove-btn`}
    >
      <XSmall color={theme.colors.gray5} />
    </RemoveBtn>
  </Container>
)

export default FilePreview
