import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

export const HeaderContainer = styled(Flex)`
  background-color: #eaedef;
  padding: 10px 0;
  border-bottom: 1px solid ${theme.colors.gray2};

  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    flex-direction: column;
  }
`

export const DateWrapper = styled(Flex)`
  align-self: flex-end;
  padding-right: ${theme.spacing.sm};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    width: 188px;
    justify-content: flex-end;
    padding-left: ${theme.spacing.sm};
    padding-right: 56px;
  }
`

export const MobileCommentWrapper = styled(Flex)`
  border-top: 1px solid ${theme.colors.gray2};
  margin: 0 ${theme.spacing.sm};
  margin-top: ${theme.spacing.sm};
  padding-top: ${theme.spacing.sm};
`
