import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { StyledScroll } from 'components/Scroll/styles'
import { ModalHeader } from 'components/modal/styles'
import { PROMO_GROUP_MOBILE_BREAKPOINT } from '../const'

export const ModalHeaderStyled = styled(ModalHeader)`
  padding-bottom: 32px;
  border-bottom: 1px solid ${theme.colors.lightGrey2};
  margin-bottom: 0;
  > h4 {
    line-height: 32px;
    font-size: 22px;
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.secondary};
  }

  button {
    width: auto;
    height: 32px;
  }
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    padding: 16px 8px;
    margin: 0 8px;
    > h4 {
      font-size: 16px;
      line-height: 24px;
    }
    > button {
      height: 24px;
    }
  }
`

export const ProductsContainer = styled(StyledScroll)`
  max-height: calc(100vh - ${props => (props.$isPrizeRow ? 159 : 224)}px);
  margin-right: -20px;
  overflow: auto;
  .simplebar-scrollbar {
    :before {
      bottom: 14px;
      left: 6px;
    }
  }
  .simplebar-content {
    margin-right: 20px;
  }
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    max-height: calc(100vh - ${props => (props.$isPrizeRow ? 90 : 179)}px);
  }
`

export const ToTopButton = styled.button`
  opacity: ${props => (props.isBtnVisible ? '1' : '0')};
  transition: opacity 0.3s;
  position: sticky;
  bottom: 40px;
  margin-right: 8px;
  float: right;
  background: ${theme.colors.secondary};
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
`

export const PromoProductsList = styled.div`
  margin-top: ${theme.spacing.base};
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
  grid-row-gap: ${theme.spacing.base};
  padding-bottom: 44px;
  grid-column-gap: 16px;
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    grid-column-gap: 0;
  }
`

export const TopContainer = styled(Flex)`
  align-items: center;
  padding: 32px 0;
  border-bottom: 1px solid ${theme.colors.lightGrey2};
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    padding: 24px 0px;
    margin: 0px 8px;
  }
`

export const Description = styled.span`
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.lightGrey4};
  font-size: 13px;
  line-height: 20px;
  ${props =>
    props.isMarginRight &&
    `
    margin-right: 32px;
  `};
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    padding: 0 8px;
  }
`

export const Url = styled.a`
  text-decoration: none;
  text-align: center;
  width: 200px;
  border-radius: 8px;
  background: ${theme.colors.greenLight5};
  color: ${theme.colors.greenDark};
  font-family: ${theme.fonts.primary};
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 24px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-right: 0;
  }
`

export const UrlsContainer = styled(Flex)`
  flex-direction: column;
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-top: 10px;
  }
`

export const PrizeRowsContainer = styled(Flex)`
  position: sticky;
  top: 0;
  background: white;
  padding: 8px 0;
  border-bottom: 1px solid ${theme.colors.lightGrey2};
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    margin: 0 8px;
  }
`
