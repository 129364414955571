import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'

export const TagContainer = styled.span`
  white-space: pre-wrap;

  * {
    display: inline-flex;
  }
`

export const AvailabilityWrapperV2 = styled.div`
  * {
    color: ${theme.colors.infoBlue} !important;
  }
`

export const AvailabilityRowText = styled(BaseTextV2).attrs({ isSmall: { lineHeight: true, fontSize: true } })``
