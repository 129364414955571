import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { BaseTextV2 } from 'components/Text'
import theme from 'theme'
import { PROMO_GROUP_MOBILE_BREAKPOINT } from 'components/Product/PromoGroup/const'

export const MaxIconContainer = styled(Flex)`
  align-self: center;
  margin: auto 0;
  height: 20px;
  width: 20px;
  background-color: ${theme.colors.greenDark};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`

export const ProgressContainer = styled(Flex)`
  width: 88px;
  height: 40px;
  background-color: ${theme.colors.greenLight3};
  border-radius: ${theme.borderRadius.base};
  padding: ${theme.spacing.xs};
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${PROMO_GROUP_MOBILE_BREAKPOINT}px) {
    order: -1;
  }
`

export const TopContainer = styled(Flex)``

export const UniqueProgress = styled(Flex)`
  gap: 2px;
`

export const ProgressTick = styled.div`
  height: 6px;
  width: 3px;
  border-radius: 2px;
  background-color: ${p => (p.filled ? '#229944' : '#addcb1')};
`

export const ThresholdText = styled(BaseTextV2)`
  font-size: 10px;
  line-height: 8px;
  font-weight: 500;
  color: ${theme.colors.greenDark};
  margin-left: auto;
`
