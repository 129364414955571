import React from 'react'

const Default = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.3401 3H6.46236C6.77709 3 7.01087 3.2633 6.9426 3.54086L5.49881 9.41053C5.27037 10.3392 4.3586 11 3.30555 11H3.10079C1.66798 11 0.656238 9.73184 1.10933 8.50382C1.15121 8.3903 1.20196 8.27962 1.26119 8.1726L3.58005 3.98273C3.91338 3.38045 4.59476 3 5.3401 3ZM15.0973 3H13.471C12.9276 3 12.4871 3.39797 12.4871 3.88889V8.96169C12.4871 10.0874 13.4972 11 14.7433 11C16.1374 11 17.1979 9.86901 16.9687 8.6266L16.0678 3.74276C15.9887 3.31414 15.5782 3 15.0973 3Z"
      fill="#99D0AC"
    />
    <path
      d="M10.529 3H8.90275C8.42178 3 8.0113 3.31414 7.93223 3.74276L7.03126 8.6266C6.80205 9.86901 7.86255 11 9.25673 11C10.5028 11 11.5129 10.0874 11.5129 8.96169V3.88889C11.5129 3.39797 11.0724 3 10.529 3Z"
      fill="#99D0AC"
    />
    <path
      d="M18.6599 3H17.5376C17.2229 3 16.9891 3.2633 17.0574 3.54086L18.5012 9.41053C18.7296 10.3392 19.6414 11 20.6945 11H20.8992C22.332 11 23.3438 9.73184 22.8907 8.50382C22.8488 8.3903 22.798 8.27962 22.7388 8.1726L20.42 3.98273C20.0866 3.38045 19.4052 3 18.6599 3Z"
      fill="#99D0AC"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M20.5011 12.9722C20.7755 13.0025 21 13.2239 21 13.5V19.0002C21 20.1048 20.1046 21.0002 19 21.0002H15.5C14.9477 21.0002 14.5 20.5525 14.5 20.0002V17C14.5 16.4478 14.0523 16 13.5 16H10.5C9.94772 16 9.5 16.4478 9.5 17V20.0002C9.5 20.5525 9.05228 21.0002 8.5 21.0002H5C3.89543 21.0002 3 20.1048 3 19.0002V13.5C3 13.2239 3.22447 13.0025 3.49895 12.9722C4.33265 12.8801 5.16703 12.5636 5.80993 12.0805C6.02943 11.9156 6.34092 11.9157 6.5567 12.0855C7.2817 12.6559 8.19835 13 9.20673 13C10.1193 13 10.9654 12.7153 11.661 12.2298C11.8633 12.0886 12.1363 12.0886 12.3386 12.2298C13.0342 12.7153 13.8803 13 14.7929 13C15.8013 13 16.718 12.6559 17.443 12.0855C17.6587 11.9157 17.9702 11.9156 18.1897 12.0805C18.8327 12.5636 19.6673 12.8801 20.5011 12.9722Z"
      fill="#229944"
    />
  </svg>
)

export default Default
