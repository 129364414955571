import axios from 'axios'
import qs from 'qs'

import { getGAClientId } from 'utils/cookieUtils'
import { GOOGLE_RETAIL_GCP_PROJECT_ID, GOOGLE_RETAIL_KEY } from 'consts'

export const formatCategories = ({ categoryName, groupName, subgroupName }) =>
  [categoryName, groupName, subgroupName].filter(item => !!item).join(' > ')

export const mapProductToRetailDetails = ({ product, quantity }) => ({
  product: {
    id: product.id,
  },
  quantity,
})

export const retailTrackUserEvent = async (eventType, params = {}) => {
  try {
    const visitorId = getGAClientId()

    if (visitorId) {
      const queryFormatted = qs.stringify({
        key: GOOGLE_RETAIL_KEY,
        ets: new Date().valueOf(),
        userEvent: JSON.stringify({
          eventType,
          visitorId,
          ...params,
        }),
      })

      axios.get(
        `https://retail.googleapis.com/v2/projects/${GOOGLE_RETAIL_GCP_PROJECT_ID}/locations/global/catalogs/default_catalog/userEvents:collect?${queryFormatted}`,
      )
    }
  } catch (err) {
    console.log('Error tracking retail user event', err)
  }
}
