import { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useModal } from "@ebay/nice-modal-react"

import LocationChangedModal from 'components/modal/LocationChangedModal'

const useChangedLocationModal = ({ isReady }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationChangedModal = useModal(LocationChangedModal)

  const notifProps = location.state?.notifProps
  const { customerNo: notifCustomerNo, fromCustomerNo, hasLocationChanged } = notifProps || {}

  useEffect(() => {
    if (hasLocationChanged && isReady) {
      locationChangedModal.show({ toCustomerNo: notifCustomerNo, fromCustomerNo })

      // clear the flag after
      navigate(location.pathname,
        { state:
          {
            notifProps: {
              ...notifProps,
              hasLocationChanged: false
            }
          }
        }
      )
    }
  }, [hasLocationChanged, isReady])
}

export default useChangedLocationModal