import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from '@rebass/grid'

import { PageTitle } from 'components/Text'

import messages from './messages'
import PanelBox from './PanelBox'
import { PageContainer, ContactsContainer } from './styles'

const departments = [
  {
    titleValue: 'Bydgoszcz',
    phone: '+48 52 360 75 00',
    email: 'kupuj.bydgoszcz@farutex.pl',
  },
  {
    titleValue: 'Gdańsk',
    phone: '+48 58 782 76 76',
    email: 'kupuj.gdansk@farutex.pl',
  },
  {
    titleValue: 'Katowice',
    phone: '+48 12 289 13 21',
    email: 'kupuj.slask@farutex.pl',
  },
  {
    titleValue: 'Kraków',
    phone: '+48 12 378 80 46',
    email: 'kupuj.krakow@farutex.pl',
  },
  {
    titleValue: 'Lublin',
    phone: '+48 81 463 70 97',
    email: 'kupuj.lublin@farutex.pl',
  },
  {
    titleValue: 'Łódź',
    phone: '+48 42 648 97 00',
    email: 'kupuj.lodz@farutex.pl',
  },
  {
    titleValue: 'Olsztyn',
    phone: '+48 89 526 65 99',
    email: 'kupuj.olsztyn@farutex.pl',
  },
  {
    titleValue: 'Poznań',
    phone: '+48 61 843 94 00',
    email: 'kupuj.poznan@farutex.pl',
  },
  {
    titleValue: 'Szczecin',
    phone: '+48 91 460 20 81',
    email: 'kupuj.szczecin@farutex.pl',
  },
  {
    titleValue: 'Warszawa',
    phone: '+48 22 510 70 00',
    email: 'kupuj.wawa@farutex.pl',
  },
  {
    titleValue: 'Wrocław',
    phone: '+48 71 368 39 00',
    email: 'kupuj.wroclaw@farutex.pl',
  },
  {
    titleValue: 'Zielona Góra',
    phone: '+48 68 413 08 47',
    email: 'kupuj.zgora@farutex.pl',
  },
]

/* eslint-disable react/prefer-stateless-function */
class Contact extends React.PureComponent {
  render() {
    return (
      <PageContainer>
        <Flex alignItems="center">
          <PageTitle>
            <FormattedMessage {...messages.contactUs} />
          </PageTitle>
        </Flex>

        <ContactsContainer>
          {departments.map(department => (
            <PanelBox key={department.titleValue} {...department} />
          ))}
        </ContactsContainer>
      </PageContainer>
    )
  }
}

export default Contact
