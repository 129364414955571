import { makeMessages, makeTranslation } from 'utils'

const messages = {
  newPasswordLengthError: {
    id: 'validators.new_password_length_error',
  },
  required: {
    id: 'validators.blank',
  },
  settings: { id: 'navbar.menu.settings' },
  changePassword: 'other.changePassword',
}

export default makeMessages(messages, makeTranslation('profile'))
