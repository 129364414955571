import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { find } from 'lodash'
import { generatePath } from 'react-router-dom'

import { ROUTES } from 'consts'
import {
  isOutOfStock,
  parseProductTitle,
  getProductTemplateInclusions,
} from 'utils'

import {
  trackRemovedFromTemplate,
  trackOutOfOfferRemoved,
  trackOutOfOfferReplaced,
  ITEM_LIST_NAMES,
  useSelectItemTracking,
} from 'services/analytics'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { templateItemsActions } from 'containers/Templates/actions'
import { getReplacementsActions } from 'containers/Products/Replacements/actions'

import {
  isAvailableAfter,
  makeChangeNotification,
  isProductUnavailable,
  getBidmarketLabel,
} from 'components/Product/utils'
import CatalogProductLicense from 'components/Product/ProductLicense/CatalogProductLicense'
import { Trash } from 'components/Icons'
import { Brand, Title } from 'components/Product/styles'
import PromotionBadge from 'components/Product/PromotionBadge'
import { ClampedBadgeRow } from 'containers/App/LoggedInLayout/Header/Search/SuggestItem/styledComponents'
import { isProductWithBadgeFeatures } from 'components/Product/Badge/utils'
import QuantityButtons from 'components/Product/TemplateProductRow/QuantityButtons'
import PromoGroup from '../PromoGroup'
import CatalogProductPrices from '../ProductPrices/CatalogProductPrices'
import CatalogReplacements from '../ButtonsContainer/CatalogReplacements'
import CatalogButtonsContainer from '../ButtonsContainer/CatalogButtonsContainer'
import BidmarketLabel from '../BidmarketLabel'

import Availability, { AVAILABILITY_MODE } from '../Availability'
import { AvailabilityWrapperV2 } from '../Availability/styles'
import ProductLinkImage from '../ProductLinkImage'
import BookmarkIcon from '../BookmarkIcon'

import {
  TemplateProductContainer,
  TemplateProductContent,
  Container,
  ProductInfoContainer,
  ProductActionsContainer,
  TemplateProductActions,
  InnerContainer,
  AddToCartContainer,
  ProductRowPromoText,
  TemplateRowPricesContainer,
  TemplateRowPricesMobileContainer,
  TemplateProductLink,
  ProductImgContainer,
  TemplateRowTrashBtnContainer,
  ProductRowButtonContainer,
  InactiveProductActions,
} from './styles'

// TODO: sipmplify render logic? remove calculations where possible? get rid of nested ternary
/* eslint-disable no-nested-ternary */

const TemplateProductRow = props => {
  const {
    product: {
      name,
      brand,
      active,
      licenseMissing,
      promotion,
      baseUnitOfMeasureDescription: baseUnitDesc,
      baseUnitOfMeasure,
      pricePerUnit,
      unitForPricePerUnit,
      unitsOfMeasure,
      nonStock,
      floatingPrice,
      features,
    },
    product,
    quantity,
    unitOfMeasure,
    templateId,
    productId,
    templateType,
    isTemplateAutomatic,
    totalPriceGross,
    totalPriceNet,
    units,
    index,
  } = props
  const intl = useIntl()
  const dispatch = useDispatch()

  const [unitData, setUnitData] = useState(
    () => find(unitsOfMeasure, { unitOfMeasure }) || {},
  )

  const hasOfferFeatures = isProductWithBadgeFeatures(product)
  const userData = useSelector(userDataSelector)
  const getReplacements = data => dispatch(getReplacementsActions.delta(data))
  const removeFromTemplate = data =>
    dispatch(templateItemsActions.deleteDelta(data))
  const notifyProductChange = data =>
    dispatch(makeChangeNotification({ ...data, intl }))

  const { stock } = unitData

  const prizeUnit = useMemo(() => units?.find(unit => unit.prize), [units])

  const selectedUnitInCart = useMemo(
    () => units?.find(unit => unit.unitOfMeasure === unitData.unitOfMeasure),
    [units, unitData],
  )

  useEffect(
    () => {
      setUnitData(find(unitsOfMeasure, { unitOfMeasure }) || {})
    },
    [unitOfMeasure, product],
  )

  const handleRemoveFromTemplate = () => {
    removeFromTemplate({
      productId,
      templateId,
      unitOfMeasure,
      successCallback: () =>
        active
          ? trackRemovedFromTemplate(productId, templateId)
          : trackOutOfOfferRemoved(productId, templateId),
    })
  }

  const trackSelectItem = useSelectItemTracking({
    product,
    unitOfMeasure,
    itemListId: templateId,
    itemListName: ITEM_LIST_NAMES.TEMPLATE,
    additionalEventParams: {
      template_type: templateType,
      template_id: templateId,
    },
    index,
  })

  const handleShowReplacements = () => {
    getReplacements({
      productId,
      templateId,
      showInModal: true,
    })

    trackOutOfOfferReplaced(productId, templateId)
  }

  const productPath = generatePath(ROUTES.PRODUCT_DETAILS, { id: productId })
  const outOfStock = useMemo(() => isOutOfStock({ nonStock, stock }), [
    nonStock,
    stock,
  ])
  const isSoonAvailable = useMemo(() => isAvailableAfter(product, userData), [
    product,
    userData,
  ])
  const isNonActive = isProductUnavailable({ product, unitOfMeasure })
  const [isInUserTemplate] = getProductTemplateInclusions(unitData)
  const bidmarketLabel = getBidmarketLabel(features)

  const addToCartBtn = (
    <AddToCartContainer>
      <CatalogButtonsContainer
        {...{
          nonStock,
          stock,
          unitData,
          product,
          outOfStock,
          productId,
        }}
        addRemoveCallback={({ justAddedToCart, removedFromCart }) => {
          notifyProductChange({ justAddedToCart, removedFromCart })
        }}
        additionalEventParams={{
          template_type: templateType,
          template_id: templateId,
        }}
        justAddedToCartQty={quantity}
        itemListId={templateType}
        itemListName={ITEM_LIST_NAMES.TEMPLATE}
      />
    </AddToCartContainer>
  )

  const templateRowPrices = (
    <CatalogProductPrices
      hideQuantity
      hideWeightedInfo
      hideOldPrice
      totalPriceNet={totalPriceNet}
      totalPriceGross={totalPriceGross}
      unitInCart={selectedUnitInCart}
      prizeUnit={prizeUnit}
      hasFloatingPrice={floatingPrice}
      promotion={promotion}
      baseUnitDesc={baseUnitDesc}
      baseUnitOfMeasure={baseUnitOfMeasure}
      pricePerUnit={pricePerUnit}
      unitForPricePerUnit={unitForPricePerUnit}
      unitsOfMeasure={unitsOfMeasure}
    />
  )

  const trashBtn = !isTemplateAutomatic && (
    <ProductRowButtonContainer
      onClick={handleRemoveFromTemplate}
      data-test-id="template-row-trash"
    >
      <Trash />
    </ProductRowButtonContainer>
  )

  const bookmarkBtn = active && (
    <BookmarkIcon
      Wrapper={ProductRowButtonContainer}
      clearTemplatesOnClose={false}
      itemListId={templateId}
      itemListName={ITEM_LIST_NAMES.TEMPLATE}
      listIndex={index}
      {...{ product, unitOfMeasure, isInUserTemplate }}
    />
  )

  const replacementBtn = !isTemplateAutomatic && (
    <TemplateRowTrashBtnContainer>
      {trashBtn}
      <CatalogReplacements
        product={product}
        unitData={unitData}
        onReplacementsClick={handleShowReplacements}
        dataTestId={`show_replacements_for_${productId}`}
      />
    </TemplateRowTrashBtnContainer>
  )

  const inactiveProductActions = (
    <InactiveProductActions {...{ isTemplateAutomatic }}>
      {trashBtn}
      {bookmarkBtn}
    </InactiveProductActions>
  )

  return (
    <TemplateProductContainer>
      <ProductImgContainer>
        <ProductLinkImage
          isTransparent
          onSelect={trackSelectItem}
          eventParams={{
            itemListId: templateId,
            itemListName: ITEM_LIST_NAMES.TEMPLATE,
          }}
          {...{ product, productPath, outOfStock }}
        />
        {isNonActive ? inactiveProductActions : addToCartBtn}
      </ProductImgContainer>
      <TemplateProductContent>
        <ProductInfoContainer>
          <Brand>{brand}</Brand>
          <div>
            <TemplateProductLink
              to={productPath}
              state={{
                itemListId: templateId,
                itemListName: ITEM_LIST_NAMES.TEMPLATE,
              }}
            >
              <Title onClick={trackSelectItem}>{parseProductTitle(name)}</Title>
            </TemplateProductLink>
            {!!bidmarketLabel && (
              <BidmarketLabel label={bidmarketLabel} separatorSize={4} />
            )}
          </div>
          <Availability
            {...{ product, outOfStock }}
            unitOfMeasureObj={unitData}
            WrapComponent={AvailabilityWrapperV2}
            mode={AVAILABILITY_MODE.ROW}
          />
          {(promotion || hasOfferFeatures) && (
            <PromoGroup
              groupId={promotion?.groupId}
              showModal={promotion?.showModal}
            >
              <ClampedBadgeRow>
                <PromotionBadge product={product} productFeaturesLimit={1} />
                <ProductRowPromoText>{promotion?.title}</ProductRowPromoText>
              </ClampedBadgeRow>
            </PromoGroup>
          )}
          {!isNonActive && (
            <TemplateRowPricesMobileContainer>
              {templateRowPrices}
            </TemplateRowPricesMobileContainer>
          )}
        </ProductInfoContainer>

        <TemplateProductActions
          data-test-id="template-product-actions"
          {...{ isNonActive }}
        >
          {bookmarkBtn}
          {licenseMissing ? (
            <TemplateRowTrashBtnContainer data-test-id="template-row-license-missing">
              {trashBtn}
              <CatalogProductLicense {...{ product }} />
            </TemplateRowTrashBtnContainer>
          ) : !isSoonAvailable && active ? (
            <ProductActionsContainer
              data-test-id={`${productId}-template-row-actions`}
            >
              <Container>
                {outOfStock ? (
                  replacementBtn
                ) : (
                  <>
                    <InnerContainer>
                      <QuantityButtons
                        product={product}
                        selectedUnit={unitOfMeasure}
                        selectedQuantity={quantity}
                        isTemplateAutomatic={isTemplateAutomatic}
                        onRemove={handleRemoveFromTemplate}
                      />

                      <TemplateRowPricesContainer data-test-id="template-row-prices">
                        {templateRowPrices}
                      </TemplateRowPricesContainer>
                    </InnerContainer>
                    {addToCartBtn}
                  </>
                )}
              </Container>
            </ProductActionsContainer>
          ) : (
            replacementBtn
          )}
        </TemplateProductActions>
      </TemplateProductContent>
    </TemplateProductContainer>
  )
}

export default TemplateProductRow

TemplateProductRow.propTypes = {
  name: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  brand: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quantity: PropTypes.number,
  isTemplateAutomatic: PropTypes.bool,
}
