import React from 'react'

import { find, map, uniqBy } from 'lodash'
import { isOutOfStock } from 'utils'

import ProductPrices from 'components/Product/ProductPrices'

export const orderOptions = ({
  variantsInCart,
  unitsOfMeasure,
  chosenUnit,
}) => {
  const cartVariantsUnits = map(variantsInCart, 'unitOfMeasure')
  const unitsInCart = unitsOfMeasure.filter(unit =>
    cartVariantsUnits.includes(unit.unitOfMeasure),
  )

  const chosenValueUnitObj = find(unitsOfMeasure, {
    unitOfMeasure: chosenUnit,
  })

  return uniqBy(
    [
      ...(chosenValueUnitObj ? [chosenValueUnitObj] : []),
      ...unitsInCart,
      ...unitsOfMeasure,
    ],
    'unitOfMeasure',
  )
}

// prettier-ignore
export const getProductVariants = ({
  unitsOfMeasure,
  variantsInCart,
  chosenUnit,
  big = false,
  shouldOrder = true,
  filterOutOfStocks = false,
  nonStock = false,
  baseUnitOfMeasure,
  baseUnitDesc,
  promotion,
  pricePerUnit,
  unitForPricePerUnit,
  hasFloatingPrice,
}) => {
  const units = filterOutOfStocks ? unitsOfMeasure.filter(({ stock }) => !isOutOfStock({ stock, nonStock })) : unitsOfMeasure
  const unitsToMap = shouldOrder
    ? orderOptions({
      variantsInCart,
      unitsOfMeasure: units,
      chosenUnit,
    })
    : units

  return unitsToMap.map(
    ({
      unitOfMeasureDescription,
      unitOfMeasure,
      quantity,
      ...unit
    }) => ({
      ...unit,
      label: unitOfMeasureDescription,
      value: unitOfMeasure,
      parsedPrices:
        <ProductPrices
          big={big}
          quantity={quantity}
          unitDesc={unitOfMeasureDescription}
          baseUnitDesc={baseUnitDesc}
          unitOfMeasure={unitOfMeasure || baseUnitOfMeasure}
          pricePerUnit={pricePerUnit}
          unitForPricePerUnit={unitForPricePerUnit}
          unitsOfMeasure={unitsOfMeasure}
          promotion={promotion}
          hasFloatingPrice={hasFloatingPrice}
          isVariant
        />,
    }),
  )
}
