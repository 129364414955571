/* eslint-disable prefer-promise-reject-errors */
import { useIntl } from 'react-intl'
import PhoneNumber from 'awesome-phonenumber'
import { omit, intersection } from 'lodash'

import { checkLoginActions } from 'containers/Auth/actions'
import { EMAIL_REGEX } from 'utils/validators'
import validationMessages from 'utils/validators/messages'

import messages from './messages'

export const isEmailValid = email =>
  EMAIL_REGEX.test(String(email).toLowerCase())
const USERNAME_REGEX = /^[a-zA-Z0-9_.\-@]{4,100}$/
export const isUsernameValid = login => USERNAME_REGEX.test(login)

export const useSignUpValidation = () => {
  const { formatMessage } = useIntl()

  return values => {
    const errors = {}

    if (!values.login) {
      errors.login = formatMessage(messages.emptyField)
    } else if (values.login.length < 4) {
      errors.login = formatMessage(validationMessages.minLength, {
        minLength: 4,
      })
    } else if (values.login.length > 100) {
      errors.login = formatMessage(validationMessages.maxLength, {
        maxLength: 100,
      })
    } else if (!isUsernameValid(values.login)) {
      errors.login = formatMessage(messages.loginInvalid)
    }

    if (!values.customerVatId) {
      errors.customerVatId = formatMessage(messages.emptyField)
    } else if (values.customerVatId.length > 20) {
      errors.customerVatId = formatMessage(validationMessages.maxLength, {
        maxLength: 20,
      })
    }

    if (!values.customerName) {
      errors.customerName = formatMessage(messages.emptyField)
    } else if (values.customerName.length > 250) {
      errors.customerName = formatMessage(validationMessages.maxLength, {
        maxLength: 250,
      })
    }

    if (values.customerNo.length > 20) {
      errors.customerNo = formatMessage(validationMessages.maxLength, {
        maxLength: 20,
      })
    }

    if (values.otherProfile.length > 50) {
      errors.otherProfile = formatMessage(validationMessages.maxLength, {
        maxLength: 50,
      })
    }

    if (!values.customerFirstName) {
      errors.customerFirstName = formatMessage(messages.emptyField)
    } else if (values.customerFirstName.length > 50) {
      errors.customerFirstName = formatMessage(validationMessages.maxLength, {
        maxLength: 50,
      })
    }

    if (!values.customerLastName) {
      errors.customerLastName = formatMessage(messages.emptyField)
    } else if (values.customerLastName.length > 50) {
      errors.customerLastName = formatMessage(validationMessages.maxLength, {
        maxLength: 50,
      })
    }

    if (!values.emailAddress) {
      errors.emailAddress = formatMessage(messages.emptyField)
    } else if (values.emailAddress.length > 80) {
      errors.emailAddress = formatMessage(validationMessages.maxLength, {
        maxLength: 80,
      })
    } else if (!isEmailValid(values.emailAddress)) {
      errors.emailAddress = formatMessage(validationMessages.emailFormat)
    }

    const pn = new PhoneNumber(values.phoneNo, values.deliveryCountryCode.value)

    if (!values.phoneNo) {
      errors.phoneNo = formatMessage(messages.emptyField)
    } else if (!pn.isValid()) {
      errors.phoneNo = formatMessage(messages.phoneInvalid)
    }

    if (!values.deliveryAddress) {
      errors.deliveryAddress = formatMessage(messages.emptyField)
    } else if (values.deliveryAddress.length > 50) {
      errors.deliveryAddress = formatMessage(validationMessages.maxLength, {
        maxLength: 50,
      })
    }

    if (!values.deliveryPostCode) {
      errors.deliveryPostCode = formatMessage(messages.emptyField)
    } else if (values.deliveryPostCode.length > 8) {
      errors.deliveryPostCode = formatMessage(validationMessages.maxLength, {
        maxLength: 8,
      })
    }

    if (!values.deliveryCity) {
      errors.deliveryCity = formatMessage(messages.emptyField)
    } else if (values.deliveryCity.length > 30) {
      errors.deliveryCity = formatMessage(validationMessages.maxLength, {
        maxLength: 30,
      })
    }

    if (!values.captcha.length) {
      errors.captcha = messages.emptyField
    }

    if (!values.agreement_1) {
      errors.agreement_1 = formatMessage(messages.acceptRequired)
    }

    return errors
  }
}

export const scrollToFirstError = errors => {
  const nodesNames = Array.from(document.querySelectorAll('[name]')).map(
    ({ name }) => name,
  )
  const errorsNames = Object.keys(omit(errors, ['recaptcha']))
  const [firstError] = intersection(nodesNames, errorsNames)
  const el = document.querySelector(`[name="${firstError}"]`)

  if (!el) return

  const scrollTop =
    window.scrollY ||
    window.pageYOffset ||
    document.body.scrollTop +
      ((document.documentElement && document.documentElement.scrollTop) || 0)
  const position = el.getBoundingClientRect().top + scrollTop
  const offset = 100

  window.scrollTo({ top: position - offset, behavior: 'smooth' })
}

export const asyncValidate = (values, dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(
      checkLoginActions.delta(
        {
          login: values.get('login'),
        },
        {
          onSuccess: () => resolve(),
          onError: () => reject({ login: messages.loginAlreadyTaken }),
        },
      ),
    )
  })

export const validatePassword = values => {
  const errors = {}
  const password = values.get('password') || ''

  if (!password) {
    errors.password = messages.emptyField
  } else if (password.length < 8) {
    errors.password = messages.tooShort
  }

  return errors
}
