import React from 'react'

const Bread = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="group-[.active-btn]:fill-white"
      d="M4.26189 5.65488C2.95666 5.87636 2 7.26062 2 8.60787C2 9.52501 2.63383 10.3375 3.38828 10.8385C3.72265 11.0606 3.96974 11.4137 3.96974 11.8151V17.3031C3.96974 18.2403 4.71593 19 5.6364 19L12.3028 19C13.2232 19 13.9694 18.2403 13.9694 17.3031V11.8151C13.9694 11.4137 14.2162 11.0602 14.5518 10.84C15.3149 10.3391 15.9694 9.52593 15.9694 8.60787C15.9694 7.26062 15.0128 5.87636 13.7075 5.65488C10.9688 5.00024 6.96875 5.00013 4.26189 5.65488Z"
      fill="#229944"
    />
    <path
      d="M16 19H18.5676C19.4293 19 20.1278 18.2403 20.1278 17.3031V11.8153C20.1278 11.4139 20.3588 11.0604 20.6729 10.8402C21.3873 10.3393 22 9.5261 22 8.60806C22 7.26083 21.1045 5.8766 19.8826 5.65511C19.8826 5.65511 18.2553 5 16.8511 5C17.7872 5.99998 18.2556 7.36798 18.2556 8.60806C18.2556 10.2921 17.2882 11.5514 16.3834 12.2857V17.3031C16.3834 17.9106 16.2456 18.4888 16 19Z"
      fill="#99D0AC"
    />
  </svg>
)

export default Bread
