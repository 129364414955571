import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import deleteAccountActions from 'containers/Profile/DeleteAccount/actions'
import Confirmation from 'components/modal/Confirmation'

import { DescriptionText } from 'components/modal/Confirmation/styles'
import messages from './messages'

const DeleteAccountModal = ({ onClose }) => {
  const { formatMessage } = useIntl()

  const dispatch = useDispatch()

  const handleDeleteAccount = ev => {
    dispatch(deleteAccountActions.delete())
    onClose(ev)
  }

  return (
    <Confirmation
      isOpen
      onClose={onClose}
      onConfirm={handleDeleteAccount}
      headingText={formatMessage(messages.title)}
      leftBtnText={formatMessage(messages.return)}
      rightBtnText={formatMessage(messages.deleteBtn)}
      rightBtnTestId="delete-account-modal-action"
    >
      <DescriptionText>{formatMessage(messages.description)}</DescriptionText>
    </Confirmation>
  )
}

export default DeleteAccountModal
