import styled from 'styled-components'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { BaseTextV2Medium, TinyTextV2Secondary } from 'components/Text'
import { CheckboxWrapperV2, CheckboxLabel } from 'components/Input/styles'

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    gap: ${theme.spacing.ss};
  }
`

export const ReasonsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};

  // to fill space when input is not there
  ${p =>
    p.$hasBottomMargin &&
    `
    margin-bottom: 120px;
  `};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    gap: 6px;

    ${p =>
      p.$hasBottomMargin &&
      `
      margin-bottom: 124px;
    `};
  }
`

export const InnerContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.ss};
  cursor: pointer;
  width: fit-content;

  :hover {
    ${CheckboxLabel} {
      background: ${theme.colors.lightGrey7};
    }

    // target checked checkbox on option hover
    input:checked + ${CheckboxLabel}::before {
      background-color: ${theme.colors.redMainHover};
    }
  }
`

export const ReasonOptionText = styled(BaseTextV2Medium).attrs({
  isSmall: { fontSize: true },
})``

export const ReasonOptionCheckbox = styled(CheckboxWrapperV2)`
  input:checked + ${CheckboxLabel}::before {
    background-color: ${theme.colors.redLight};
  }
`

export const ReasonOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const OtherReasonInputCounter = styled(TinyTextV2Secondary)`
  color: ${theme.colors.gray7};
`
