import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'

export const DATE_TIME_FORMATS = {
  FULL_DATE: 'DD.MM.YYYY', // 03.09.2019
  FULL_DATE_TIME: 'DD.MM.YYYY HH:mm', // 24.11.2021 18:00
  MONTH_GROUP: 'MMMM YYYY', // March 2019
  API_DATE: 'YYYY-MM-DD', // 2019-09-03
  SHORT_DATE: 'DD.MM', // 22.05
  SHORT_TIME: 'HH:mm',
}

export const isDayAfterTomorrow = date =>
  dayjs()
    .add(2, 'days')
    .isSame(dayjs(date), 'day')

export const useDaysCountFormatted = ({ date, defaultMsg }) => {
  const { formatMessage } = useIntl()

  const msg = useMemo(
    () => {
      const day = dayjs(date)

      if (day.isToday()) {
        return formatMessage({ id: 'navbar.today' })
      }
      if (day.isTomorrow()) {
        return formatMessage({ id: 'navbar.tomorrow' })
      }
      if (isDayAfterTomorrow(date)) {
        return formatMessage({ id: 'dayAfterTomorrow' })
      }

      return defaultMsg
    },
    [date, defaultMsg],
  )

  return msg
}

export const formatTime = seconds => {
  let secondsRest = seconds
  const days = Math.floor(secondsRest / (3600 * 24))
  secondsRest -= days * 3600 * 24
  let hours = Math.floor(secondsRest / 3600)
  secondsRest -= hours * 3600
  let minutes = Math.floor(secondsRest / 60)
  secondsRest -= minutes * 60

  secondsRest = String(secondsRest).padStart(2, '0')
  hours = String(hours).padStart(2, '0')
  minutes = String(minutes).padStart(2, '0')

  return { days, hours, minutes, seconds: secondsRest }
}

export const secondsDiff = date => date && dayjs(date).diff(dayjs(), 'seconds')
