import React from 'react'

import theme from 'theme'

const InfoV2 = ({ color = theme.colors.gray5 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="8" stroke={color} strokeWidth="2" />
    <path
      d="M12 12L12 14.75C12 15.4404 12.5596 16 13.25 16H13.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="11.9998" cy="9.00005" r="1.2" fill={color} />
  </svg>
)

export default InfoV2
