import React from 'react'
import { useIntl } from 'react-intl'
import { capitalize } from 'lodash/fp'
import dayjs from 'dayjs'

import { isDayAfterTomorrow } from 'utils/datetime'
import {
  DeliveryTruck as TruckIcon,
  CircleAlertFilled as AlertIcon,
  RouteIcon,
} from 'components/Icons'

import theme from 'theme'
import messages from './messages'

export const useSubtitleMsg = closestOrderDate => {
  const { formatMessage } = useIntl()
  const orderDay = dayjs(closestOrderDate)
  const date = orderDay.format('D MMMM')

  if (!closestOrderDate) return formatMessage(messages.noPlannedDeliveries)

  if (orderDay.isToday()) return formatMessage(messages.today, { date })

  if (orderDay.isTomorrow()) return formatMessage(messages.tomorrow, { date })

  if (isDayAfterTomorrow(closestOrderDate)) {
    return formatMessage(messages.dayAfterTomorrow, { date })
  }

  return capitalize(orderDay.format('dddd, D MMMM'))
}

export const getDeliveryDayIcon = ({ routes, isToday }) => {
  if (routes.some(r => r.logisticLackAmount > 0)) {
    return { icon: <AlertIcon />, iconTestId: 'delivery-icon-alert' }
  }

  if (routes.some(r => r.orders.length > 0)) {
    return {
      icon: (
        <TruckIcon
          color={isToday ? theme.colors.secondary : theme.colors.primary}
        />
      ),
      iconTestId: 'delivery-icon-truck',
    }
  }

  if (routes.length) {
    return {
      icon: <RouteIcon color={theme.colors.gray5} />,
      iconTestId: 'delivery-icon-route',
    }
  }

  return { icon: null, iconTestId: 'delivery-no-icon' }
}
