import React from 'react'
import { FormattedMessage } from 'react-intl'

import { makeMessages, makeTranslation, FormattedRichMessage } from 'utils'

import cartIntro1Img from 'assets/hintsImgs/cart_intro1.png'
import cartIntro2Img from 'assets/hintsImgs/cart_intro2.png'

const messages = makeMessages(
  {
    header: 'header',
    info1: 'info1',
    info2: 'info2',
    oneMore: { id: 'hintModal.button.oneMore' },
  },
  makeTranslation('hintModal.newCart'),
)

const header = <FormattedMessage {...messages.header} />

export const hintModalConfig = [
  {
    header,
    img: cartIntro1Img,
    info: <FormattedRichMessage {...messages.info1} />,
    nextTxt: <FormattedMessage {...messages.oneMore} />,
  },
  {
    header,
    img: cartIntro2Img,
    info: <FormattedRichMessage {...messages.info2} />,
  },
]
