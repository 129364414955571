import styled from 'styled-components'
import theme from 'theme'

export const HintTooltipContainer = styled.div`
  display: flex;
  background-color: ${theme.colors.white};
  padding: ${theme.spacing.ss} ${theme.spacing.sm};
  width: 192px;
  box-shadow: 0px 24px 112px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`
