import React, { useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { useRichFormatMessage } from 'utils'
import { trackStockExceededAction } from 'containers/Cart/actions'
import { formatAmount } from 'components/Product/utils'
import { trackInStockApplied, trackNonStockApplied } from 'services/analytics'
import {
  formatHumanDate,
  formatRegularDate,
} from 'components/Product/Availability/utils'

import Confirmation from './Confirmation'
import { DelayButton, DiscardButton } from './Confirmation/styles'
import messages from './messages'

const StockExceeded = ({
  onClose,
  unitOfMeasureObj,
  closestDeliveryDate,
  selectedDeliveryDate,
  onConfirmDateShift,
  onTrackStockExceeded,
  onTrackDateShift,
  productId,
  Wrapper,
}) => {
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(trackStockExceededAction({ onTrackStockExceeded }))
  }, [])

  const deliveryDate = formatRegularDate(selectedDeliveryDate)
  const selectedDeliveryDateFormatted = useMemo(
    () => formatHumanDate({ date: selectedDeliveryDate, formatMessage }),
    [selectedDeliveryDate],
  )

  const closestDeliveryDateFormatted = useMemo(
    () => formatHumanDate({ date: closestDeliveryDate, formatMessage }),
    [closestDeliveryDate],
  )

  const handleTrackDateShift = () =>
    onTrackDateShift
      ? onTrackDateShift(productId, deliveryDate)
      : trackNonStockApplied(productId, deliveryDate)

  return (
    <Confirmation
      message={formatMessage(messages.title, {
        quantity: formatAmount(unitOfMeasureObj, unitOfMeasureObj.stock),
        humanDate: selectedDeliveryDateFormatted,
      })}
      {...{ Wrapper }}
    >
      <DiscardButton
        onClick={() => {
          onClose()
          trackInStockApplied({
            productId,
            deliveryDate: selectedDeliveryDate.format(),
          })
        }}
      >
        <span>
          {formatRichMessage(messages.okBtn, {
            quantity: formatAmount(unitOfMeasureObj, unitOfMeasureObj.stock),
            humanDate: selectedDeliveryDateFormatted,
          })}
        </span>
      </DiscardButton>
      <DelayButton
        onClick={() => {
          onConfirmDateShift()
          handleTrackDateShift()
        }}
      >
        <span>
          {formatRichMessage(messages.shiftDateBtn, {
            date: closestDeliveryDateFormatted,
          })}
        </span>
      </DelayButton>
    </Confirmation>
  )
}

export default StockExceeded
