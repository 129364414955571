import { makeMessages, makeTranslation } from 'utils'

const messages = {
  contactPhone: 'contactPhoneNumber',
  showReplacements: { id: 'catalog.replacements.showReplacementsBtn' },
  replacements: { id: 'common.actions.replacements' },
  setNotification: 'setNotification',
  toCart: 'toCart',
  missingLicense: 'missingLicense',
  stockLimitedText: { id: 'stockLimited.text' },
  maxAvailableAmount: { id: 'nonStock.maxAvailableAmount' },
}

export default makeMessages(messages, makeTranslation('product'))
