import styled from 'styled-components'

import { AddProductsButton, AlternateBtn } from 'components/Button'
import { Flex } from '@rebass/grid'
import { FlexColumns } from 'layouts'
import theme from 'theme'
import Amount from 'components/Product/Amount'

export const ButtonsWrapper = styled(FlexColumns)`
  user-select: none;
  margin-bottom: 0;
  justify-content: space-between;
  ${props => props.noSpaceBetween && 'justify-content: flex-start'};
`

export const CatalogButtonsWrapper = styled(ButtonsWrapper)`
  margin-top: 4px;
  outline: none;
`

export const CatalogAddProductsButton = styled(AddProductsButton)`
  border-radius: 8px;
  width: 100%;
  :hover {
    background: ${theme.colors.greenLight};
  }
  :disabled {
    background: ${theme.colors.greenLight2};
    path,
    circle {
      stroke: #acdcb0;
    }
    circle {
      fill: #acdcb0;
    }
  }
`

// prettier-ignore
export const CatalogProductButtonsContainer = styled(Flex)`
  border-radius: 8px;
  flex: 1;
  padding: 4px;
  background: ${props =>
    props.isTooltipOpen ? 'white' : theme.colors.lightGrey};
  box-shadow: ${props =>
    props.isTooltipOpen ? '0px 2px 4px 0px #00000014' : 'none'};
  ${props => props.isTooltipOpen && `
    button,
    input {
      background: white;
      opacity: 0.4;
    }
  `}
  ${props => props.isVariants && `
    border-top: 1px solid white;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `};
`

export const CatalogAlternateBtn = styled(AlternateBtn)`
  padding: 0;
  border: none;
  border-radius: 5px;
  background: ${theme.colors.lightGrey};
  color: ${theme.colors.secondary};
  min-width: 32px;
  width: 32px;
  height: 32px;
  :hover {
    border: none;
    background: ${theme.colors.lightGrey5} !important;
  }
  &:disabled {
    cursor: default;
    color: ${theme.colors.secondary};
    opacity: 0.4;
    border: none;
    :hover {
      background: initial !important;
    }
  }
`

export const AmountForm = styled.form`
  margin: 0 4px;
  flex: 1;
`

export const CatalogAmount = styled(Amount)`
  border: none;
  border-radius: 5px;
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.sm};
  background: ${theme.colors.lightGrey};
  width: 100%;
  height: 32px;
  font-weight: 500;
  color: ${theme.colors.secondary};
  :hover {
    background: ${theme.colors.lightGrey5} !important;
  }
  &:disabled {
    :hover {
      background: ${theme.colors.white} !important;
    }
  }
`
