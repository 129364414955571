import React from 'react'
import { FormattedMessage } from 'react-intl'

import { PrimaryBtn } from 'components/Button'

import messages from '../../../messages'

import { Container, Message } from './styles'

const NoOrders = ({ onGoToCatalog }) => (
  <Container>
    <Message>
      <FormattedMessage {...messages.noOrdersMessage} />
    </Message>

    <PrimaryBtn onClick={onGoToCatalog}>
      <FormattedMessage {...messages.noOrdersCtaBtn} />
    </PrimaryBtn>
  </Container>
)

export default NoOrders
