import { Flex } from '@rebass/grid'
import styled from 'styled-components'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

import { ReplacementBtn } from '../../Actions'
import { ProductNameStyled, BrandTxt } from '../styledComponents'

export const ReplacementsButton = styled(ReplacementBtn)`
  width: 144px !important;
  background-color: transparent;

  :hover {
    background-color: transparent;
  }

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    border: none;
  }
`

export const BrandNameContainer = styled(Flex)`
  flex-direction: column;

  ${BrandTxt} {
    ${p => !p.isSoonAvailable && `color: ${theme.colors.gray3};`};
  }

  ${ProductNameStyled} {
    ${p => !p.isSoonAvailable && `color: ${theme.colors.gray4};`};
    text-decoration: line-through;
  }

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: 100%;
    border-bottom: 1px solid ${theme.colors.gray2};
    margin-bottom: ${theme.spacing.xs};
    padding: 0 ${theme.spacing.xs};
    padding-bottom: ${theme.spacing.xs};
  }
`

export const CtaWrapper = styled(Flex)`
  height: 100%;
  align-items: center;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    height: auto;
    width: 100%;
    justify-content: space-between;
  }
`
