import React from 'react'

import { BaseTextV2 } from 'components/Text'
import { ArrowRightV2 } from 'components/Icons'

import { OtherTextContainer } from '../styles'

const OtherRow = ({ className, icon, text, isArrowHidden }) => (
  <>
    <OtherTextContainer {...{ className }}>
      {icon}
      <BaseTextV2 isSmall={{ fontSize: true }}>{text}</BaseTextV2>
    </OtherTextContainer>
    {!isArrowHidden && <ArrowRightV2 />}
  </>
)

export default OtherRow
