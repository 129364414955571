import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { BaseTextV2 } from 'components/Text'

export const ContactFormContainer = styled(Flex)`
  flex-direction: column;
  gap: 20px;
`

export const DescriptionText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})``

export const LegalTextContainer = styled(Flex)`
  text-align: left;
`
