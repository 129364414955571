import styled from 'styled-components'

import theme from 'theme'

import {
  CarouselOverflowContainer,
  CarouselScrollContainer,
} from 'components/Carousel/styles'
import { FilteredPageTileWrapper } from 'components/FilteredPageTile/styles'

export const ScrollableContainer = styled.div`
  // 72px is sum of static elements heights that should not scroll
  height: ${p => `calc(100vh - ${p.$mobileMenuOffset}px - 72px)`};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const TilesContainer = styled(CarouselScrollContainer)`
  display: flex;
  gap: ${theme.spacing.sm};
  > ${FilteredPageTileWrapper} {
    flex: 1 0 0;
    min-width: 147.5px;
  }

  @media screen and (min-width: 300px) {
    > ${FilteredPageTileWrapper} {
      flex-basis: 57%;
    }
  }

  @media screen and (min-width: 380px) {
    > ${FilteredPageTileWrapper} {
      flex-basis: 37%;
    }
  }

  @media screen and (min-width: 540px) {
    > ${FilteredPageTileWrapper} {
      flex-basis: 0;
    }
  }
`

export const TilesOverflowContainer = styled(CarouselOverflowContainer)`
  padding: ${theme.spacing.sm};
`
