import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

import { H2v2 } from 'components/Text'

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`

export const CarouselTitle = styled(H2v2)`
  font-weight: ${theme.fontWeights.bold};
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: 28px;
    line-height: 40px;
  }
`
