export const getProductDetails = ({ body: { id } }) => ({
  url: `me/products/${id}`,
})

export const subscribeToProduct = ({ id, email }) => {
  const url = `me/products/${id}/notification`
  const options = {
    method: 'PUT',
    body: {
      email,
    },
  }

  return {
    url,
    options,
  }
}
