import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import theme from 'theme'

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${({ color = theme.colors.primary }) => color};
  &:hover {
    text-decoration: none;
  }
`

export default Link
