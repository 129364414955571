import React from 'react'

import { CircleAlert } from 'components/Icons'

import {
  Container,
  ErrorTextContainer,
  ErrorText,
  HintContainer,
  HintText,
} from './styles'

const ValidationWrapper = ({
  children,
  errorText,
  isTouched,
  testId,
  hint,
}) => {
  const isError = errorText && isTouched

  return (
    <Container data-test-id={testId}>
      {children}
      {(hint || isError) && (
        <HintContainer>
          {isError && (
            <ErrorTextContainer>
              <CircleAlert />
              <ErrorText>{errorText}</ErrorText>
            </ErrorTextContainer>
          )}
          {hint && <HintText>{hint}</HintText>}
        </HintContainer>
      )}
    </Container>
  )
}

export default ValidationWrapper
