import styled from 'styled-components'

import theme from 'theme'

const AMOUNT_INPUT_SIZE = {
  WIDTH: 72,
  HEIGHT: 40,
}

export const AmountContainer = styled.input`
  border: 1px solid #f3f3f3;
  font-size: 14px;
  background: rgba(221, 226, 229, 0.5);
  border: 1px solid ${theme.colors.gray2};
  font-weight: bold;
  width: ${AMOUNT_INPUT_SIZE.WIDTH}px;
  height: ${AMOUNT_INPUT_SIZE.HEIGHT}px;
  text-align: center;
  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.secondary};
  }
`
