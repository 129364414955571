import { takeLatest, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'

import { getProductDetails, subscribeToProduct } from './api'
import { productDetailsActions, subscribeToProductActions } from './actions'

export function* getProductDetailsFlow({ data: body }) {
  try {
    const details = yield call(genericGetDataEnhanced, {
      actions: productDetailsActions,
      request: getProductDetails,
      params: { body },
    })

    if (!details) {
      console.log('Product not found', body)
    }
  } catch (error) {
    console.log('Error getting product details', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* subscribeToProductFlow({
  data,
  additionalData: { callback, onSuccess, onError },
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: subscribeToProductActions,
      request: subscribeToProduct,
      params: data,
    })

    yield call(onSuccess)
  } catch (error) {
    console.log('Error subscribing to product', error)
    yield call(onError)
  } finally {
    yield call(callback)
  }
}

export const productDetailsWatchers = [
  takeLatest(productDetailsActions.DELTA, getProductDetailsFlow),
  takeLatest(subscribeToProductActions.DELTA, subscribeToProductFlow),
]
