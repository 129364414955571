import React from 'react'

import theme from 'theme'

const EyeOpenV2 = ({ color = theme.colors.secondary }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.11618 5.2909C10.9155 4.09119 14.0842 4.09119 16.8835 5.2909C19.0337 6.21239 20.8388 7.78804 22.0423 9.79398L22.6487 10.8046C23.2749 11.8482 23.2749 13.152 22.6487 14.1957L22.0423 15.2063C20.8388 17.2122 19.0337 18.7879 16.8835 19.7094C14.0842 20.9091 10.9155 20.9091 8.11618 19.7094C5.96601 18.7879 4.16092 17.2123 2.95736 15.2063L2.35099 14.1957C1.7248 13.152 1.7248 11.8482 2.35098 10.8046L2.95736 9.79398C4.16092 7.78804 5.96601 6.21239 8.11618 5.2909ZM16.0957 7.12919C13.7995 6.14509 11.2002 6.14509 8.90401 7.12919C7.14028 7.88507 5.6596 9.17754 4.67235 10.823L4.06597 11.8336C3.81981 12.2439 3.81981 12.7564 4.06597 13.1667L4.67235 14.1773C5.6596 15.8228 7.14028 17.1152 8.90401 17.8711C11.2002 18.8552 13.7995 18.8552 16.0957 17.8711C17.8594 17.1152 19.3401 15.8227 20.3274 14.1773L20.9337 13.1667C21.1799 12.7564 21.1799 12.2439 20.9337 11.8336L20.3274 10.823C19.3401 9.17754 17.8594 7.88507 16.0957 7.12919Z"
      fill={color}
    />
    <mask id="path-2-inside-1_32274_33395" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 16.5C14.7091 16.5 16.5 14.7091 16.5 12.5C16.5 12.0837 15.8996 11.9879 15.5448 12.2057C15.2408 12.3924 14.8829 12.5 14.5 12.5C13.3954 12.5 12.5 11.6046 12.5 10.5C12.5 10.1171 12.6076 9.75924 12.7943 9.45517C13.0121 9.1004 12.9163 8.5 12.5 8.5C10.2909 8.5 8.5 10.2909 8.5 12.5C8.5 14.7091 10.2909 16.5 12.5 16.5Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 16.5C14.7091 16.5 16.5 14.7091 16.5 12.5C16.5 12.0837 15.8996 11.9879 15.5448 12.2057C15.2408 12.3924 14.8829 12.5 14.5 12.5C13.3954 12.5 12.5 11.6046 12.5 10.5C12.5 10.1171 12.6076 9.75924 12.7943 9.45517C13.0121 9.1004 12.9163 8.5 12.5 8.5C10.2909 8.5 8.5 10.2909 8.5 12.5C8.5 14.7091 10.2909 16.5 12.5 16.5Z"
      fill={color}
    />
    <path
      d="M12.7943 9.45517L11.942 8.93201L12.7943 9.45517ZM15.5448 12.2057L15.0217 11.3535L15.5448 12.2057ZM15.5 12.5C15.5 14.1569 14.1569 15.5 12.5 15.5V17.5C15.2614 17.5 17.5 15.2614 17.5 12.5H15.5ZM15.0217 11.3535C14.8705 11.4463 14.693 11.5 14.5 11.5V13.5C15.0728 13.5 15.611 13.3385 16.068 13.058L15.0217 11.3535ZM14.5 11.5C13.9477 11.5 13.5 11.0523 13.5 10.5H11.5C11.5 12.1569 12.8431 13.5 14.5 13.5V11.5ZM13.5 10.5C13.5 10.307 13.5537 10.1295 13.6465 9.97832L11.942 8.93201C11.6615 9.38898 11.5 9.92715 11.5 10.5H13.5ZM9.5 12.5C9.5 10.8431 10.8431 9.5 12.5 9.5V7.5C9.73858 7.5 7.5 9.73858 7.5 12.5H9.5ZM12.5 15.5C10.8431 15.5 9.5 14.1569 9.5 12.5H7.5C7.5 15.2614 9.73858 17.5 12.5 17.5V15.5ZM13.6465 9.97832C13.9042 9.55858 13.9652 9.04133 13.8549 8.60132C13.7405 8.145 13.3316 7.5 12.5 7.5V9.5C12.3409 9.5 12.1773 9.43412 12.0581 9.31917C11.9553 9.22009 11.9238 9.12328 11.9149 9.08778C11.9051 9.04865 11.9072 9.02571 11.9087 9.01509C11.9103 9.00438 11.9158 8.97484 11.942 8.93201L13.6465 9.97832ZM17.5 12.5C17.5 11.6684 16.855 11.2595 16.3987 11.1451C15.9587 11.0348 15.4414 11.0958 15.0217 11.3535L16.068 13.058C16.0252 13.0842 15.9956 13.0897 15.9849 13.0913C15.9743 13.0928 15.9514 13.0949 15.9122 13.0851C15.8767 13.0762 15.7799 13.0447 15.6808 12.9419C15.5659 12.8227 15.5 12.6591 15.5 12.5H17.5Z"
      fill={color}
      mask="url(#path-2-inside-1_32274_33395)"
    />
  </svg>
)

export default EyeOpenV2
