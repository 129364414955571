import React from 'react'

import { APPS_ROUTES } from 'consts'
import { AppStore, GooglePlay } from 'components/Icons/Footer'

import messages from '../messages'
import Branding from '../Branding'

import {
  AppsSectionColumn,
  AppContainer,
  AppBtn,
  AppBtnTextContainer,
  AppBtnText,
  AppsContainer,
  CopyrightContainer,
} from './styles'

const APPS_CONFIG = [
  {
    msg: 'appStore',
    href: APPS_ROUTES.APP_STORE,
    icon: <AppStore />,
  },
  {
    msg: 'googlePlay',
    href: APPS_ROUTES.GOOGLE_PLAY,
    icon: <GooglePlay />,
  },
]

const Apps = ({ formatMessage, className }) => (
  <AppsContainer className={className}>
    <AppsSectionColumn title={formatMessage(messages.appsTitle)}>
      {APPS_CONFIG.map(({ msg, href, icon }) => (
        <AppContainer key={msg} href={href} suppressSpacingTop>
          <AppBtn>
            {icon}
            <AppBtnTextContainer>
              <AppBtnText>{formatMessage(messages.downloadFrom)}</AppBtnText>
              <AppBtnText bold>{formatMessage(messages[msg])}</AppBtnText>
            </AppBtnTextContainer>
          </AppBtn>
        </AppContainer>
      ))}
    </AppsSectionColumn>

    <CopyrightContainer className="FooterCopyright">
      <Branding />
    </CopyrightContainer>
  </AppsContainer>
)

export default Apps
