import React from 'react'

import OrderSummary from './OrderSummary'
import OrderDetails from './OrderDetails'
import BillingDetails from './BillingDetails'

import {
  PrimaryDetailsContainer,
  OrderSummaryWrapper,
  OrderDetailsWrapper,
  BillingDetailsWrapper,
} from './styles'

const PrimaryDetails = ({ orderDetails, userData }) => (
  <PrimaryDetailsContainer>
    <OrderSummaryWrapper>
      <OrderSummary orderDetails={orderDetails} />
    </OrderSummaryWrapper>
    <OrderDetailsWrapper>
      <OrderDetails orderDetails={orderDetails} userData={userData} />
    </OrderDetailsWrapper>
    <BillingDetailsWrapper>
      <BillingDetails orderDetails={orderDetails} userData={userData} />
    </BillingDetailsWrapper>
  </PrimaryDetailsContainer>
)

export default PrimaryDetails
