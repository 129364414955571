import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

import {
  BaseTextV2Secondary,
  BaseTextV2Medium,
  H1v2,
  H3v2,
} from 'components/Text'

export const OrderSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.sm};
  padding-bottom: ${theme.spacing.md};
  gap: ${theme.spacing.sm};
  background-color: ${theme.colors.lightGrey6};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  // zig-zag bottom effect
  --mask: radial-gradient(
        8.16px at 50% calc(100% - 11.2px),
        #000 99%,
        #0000 101%
      )
      calc(50% - 14px) 0/28px 100%,
    radial-gradient(8.16px at 50% calc(100% + 4.2px), #0000 99%, #000 101%) 50%
      calc(100% - 7px) / 28px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: ${theme.spacing.base};
    padding-bottom: 40px;
    gap: ${theme.spacing.base};
  }
`

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
`

// prettier-ignore
export const OrderSummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TopOrderSummaryRow = styled(OrderSummaryRow)`
  padding-bottom: ${theme.spacing.xs};
  border-bottom: 1px solid ${theme.colors.semiTranspBg.grayLight};
`

export const BottomOrderSummaryRow = styled(OrderSummaryRow)`
  padding-top: ${theme.spacing.xs};
  border-top: 1px solid ${theme.colors.semiTranspBg.grayLight};
  align-items: baseline;
`

export const RowLabel = styled(BaseTextV2Secondary).attrs({
  isSmall: { fontSize: true },
})``

export const RowText = styled(BaseTextV2Medium).attrs({
  isSmall: { fontSize: true },
})``

export const BigRowLabel = styled(H3v2)`
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: ${theme.textSizes.m};
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_WIDE_START}px) {
    font-size: ${theme.textSizes.l};
  }
`

export const BigRowText = styled(H1v2)`
  font-size: 18px;
  line-height: 24px;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: ${theme.textSizes.xl};
    font-weight: ${theme.fontWeights.medium};
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_WIDE_START}px) {
    font-size: ${theme.textSizes.xxl};
    font-weight: ${theme.fontWeights.bold};
    line-height: 40px;
  }
`
