export const placeOrder = () => {
  const url = 'me/orders/bulk'
  const options = {
    method: 'POST',
    body: {},
  }

  return { url, options }
}

export const getTradeLimits = () => ({ url: 'me/trade_limit' })
