import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import qs from 'qs'

import { selectAvailableFiltersData } from 'containers/Products/Filters/selectors'
import { makeSelectedFiltersParams } from 'components/Filters/utils'
import {
  trackBrandFilteringOpen,
  trackCatalogFilters,
  trackProductFeatureFilteringOpen,
  trackCuisineFeatureFilteringOpen,
  trackOfferFeaturesOpen,
} from 'services/analytics'

import Filters from 'components/Filters'
import FiltersPagination from './components/FiltersPagination'
import {
  FiltersContainer,
  FiltersWrapper,
  FiltersPaginationWrapper,
} from './styles'

const CatalogFilters = ({
  onPageChange,
  handleFiltersClear,
  current,
  totalPages,
  totalCount,
  selectedFilters,
  sectionName,
  queryParams,
}) => {
  const navigate = useNavigate()
  const [portalNode, setPortalNode] = useState(null)
  const containerRef = useRef(null)

  const availableFilters = useSelector(selectAvailableFiltersData)

  useEffect(() => {
    setPortalNode(containerRef?.current)
  }, [])

  const handleFiltersApply = useCallback(
    (name, filters, isMultipleFilters, eventValues) => {
      trackCatalogFilters({
        filterName: name,
        filterValues: filters,
        eventValues,
      })
      const newSelected = makeSelectedFiltersParams(availableFilters, {
        ...selectedFilters,
        [name]: filters,
      })
      const newQueries = qs.stringify(
        {
          ...queryParams,
          filters: {
            ...newSelected,
          },
        },
        { encode: false, arrayFormat: 'brackets' },
      )
      navigate({ search: newQueries }, { replace: true })
    },
    [sectionName, availableFilters, navigate],
  )

  return (
    <FiltersWrapper>
      <FiltersContainer ref={containerRef}>
        <Filters
          calculatePositionFromParent
          portalNode={portalNode}
          handleBrandsShow={() => trackBrandFilteringOpen()}
          handleProductFeaturesShow={() => trackProductFeatureFilteringOpen()}
          handleCuisineFeaturesShow={() => trackCuisineFeatureFilteringOpen()}
          handleOfferFeaturesShow={() => trackOfferFeaturesOpen()}
          handleFiltersClear={handleFiltersClear}
          {...{
            availableFilters,
            selectedFilters,
            handleFiltersApply,
          }}
        />
        {!!totalCount && (
          <FiltersPaginationWrapper>
            <FiltersPagination
              isNav
              {...{ totalCount, totalPages, current, onPageChange }}
            />
          </FiltersPaginationWrapper>
        )}
      </FiltersContainer>
    </FiltersWrapper>
  )
}

export default CatalogFilters
