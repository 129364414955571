import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  addProductToTemplate,
  deleteProductFromTemplate,
  templateItemsActions,
} from 'containers/Templates/actions'
import { selectTemplateDetailsPagination } from 'containers/Templates/selectors'

import { replacementProductsSelector } from './selectors'
import { getReplacementsActions } from './actions'

export default connect(
  createStructuredSelector({
    replacementProducts: replacementProductsSelector,
    templateDetailsPagination: selectTemplateDetailsPagination,
  }),
  {
    getReplacements: getReplacementsActions.delta,
    clearReplacements: getReplacementsActions.clear,
    addToTemplate: addProductToTemplate,
    removeFromTemplate: deleteProductFromTemplate,
    getTemplateItems: templateItemsActions.delta,
  },
)
