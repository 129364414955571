import React, { useEffect } from 'react'

import { trackPageView } from './utils'

export const withPageView = pageTitle => WrappedComponent => props => {
  useEffect(
    () => {
      trackPageView(pageTitle)
    },
    [pageTitle],
  )

  return <WrappedComponent {...props} />
}
