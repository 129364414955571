import { createSelector } from 'reselect'
import { getFormValues } from 'redux-form/immutable'
import { getOr, flattenDeep } from 'lodash/fp'

/*
 * SELECTORS
 */

export const makeImmutableStateSelector = (state, selector) => {
  const isArray = Array.isArray(selector)
  const immutableState = isArray ? state[selector[0]] : state[selector]

  // skip first key in array that was used to get immutable state
  return isArray
    ? immutableState?.getIn([...selector.slice(1)])
    : immutableState
}

export const makeStateValueSelector = (...searchKeyPaths) =>
  createSelector(
    state => makeImmutableStateSelector(state, flattenDeep(searchKeyPaths)),
    data => (data && data.toJS ? data.toJS() : data),
  )

export const stateValueSelectorFactory = property => path =>
  makeStateValueSelector(path, property)

export const makeDataSelector = stateValueSelectorFactory('data')
export const makeIsFetchingSelector = stateValueSelectorFactory('isFetching')
export const makeIsFinishedSelector = stateValueSelectorFactory('finished')
export const makeErrorSelector = stateValueSelectorFactory('error')

export const genericSelector = reducerPath => ({
  data: makeDataSelector(reducerPath),
  isFetching: makeIsFetchingSelector(reducerPath),
  isFinished: makeIsFinishedSelector(reducerPath),
  fetchError: makeErrorSelector(reducerPath),
})

export const formValuesSelector = (formName, fallback = {}) =>
  createSelector(
    getFormValues(formName),
    formValues => (formValues ? formValues.toJS() : fallback),
  )

export const makePaginationSelector = dataSelector =>
  createSelector(dataSelector, getOr({}, 'meta.pagination'))

export const makeFiltersSelector = dataSelector =>
  createSelector(dataSelector, getOr({}, 'meta.filters'))
