import React from 'react'
import dayjs from 'dayjs'
import { Flex } from '@rebass/grid'
import { capitalize } from 'lodash/fp'

import theme from 'theme'

import { TertiaryText, TinyText } from 'components/Text'

import { CalendarIcon } from './styles'

const DeliveryInfo = ({
  ordersLength,
  deliveryDate,
  routeName,
  index,
  withoutLines,
  ...rest
}) => {
  const dateObj = dayjs(deliveryDate)
  const isLast = index === ordersLength - 1

  return (
    <Flex alignItems="center" {...rest}>
      <CalendarIcon
        number={dateObj.format('D')}
        {...{ isLast, withoutLines }}
      />

      <Flex flexDirection="column">
        <TertiaryText style={{ marginBottom: theme.spacing.xxs }}>
          {capitalize(dateObj.format('D MMMM').split(' ')[1])},
          <b> {capitalize(dateObj.format('dddd'))}</b>
        </TertiaryText>

        <TinyText color={theme.colors.gray4}>{routeName}</TinyText>
      </Flex>
    </Flex>
  )
}

export default DeliveryInfo
