import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { MOBILE_PAGINATION_BREAKPOINT } from 'consts'
import Pagination from 'components/Pagination'

export const ProductCount = styled(Flex)`
  height: 24px;
  font-family: ${theme.fonts.primary};
  font-size: 11px;
  color: ${theme.colors.lightGrey4};
  margin-right: 8px;
  line-height: 24px;
  ${props =>
    props.isNav &&
    `
    border-left: solid 1px ${theme.colors.lightGrey2};
    margin-left: ${theme.spacing.sm};
    padding-left: ${theme.spacing.sm};
  `};
`

export const FiltersPageControl = styled(Pagination)`
  > div:nth-child(odd) {
    padding: 4px 8px;
  }
  > *:nth-child(2) {
    padding: 0 8px;
    margin: 0 2px;
    font-size: 11px;
    line-height: 24px;
    height: 24px;
    width: 64px;
    color: ${theme.colors.primary};
    > span:nth-child(2) {
      margin: 0 5px;
    }
  }
  @media screen and (max-width: ${MOBILE_PAGINATION_BREAKPOINT}px) {
    width: auto;
  }
`
