import React from 'react'
import { useIntl } from 'react-intl'
import { identity } from 'lodash'

import { formatPrice } from 'utils'
import { ArrowRightXS as ArrowRightIcon } from 'components/Icons'

import theme from 'theme'
import messages from '../messages'
import {
  OrderContainer,
  OrderTextContainer,
  OrderText,
  OrderTextSecondary,
  OrderTotalContainer,
  OrderLinkContainer,
} from '../styles'

const OrdersListItem = ({
  orderNumber,
  orderTotal,
  onViewOrderClick = identity,
  isAffectedOrder,
}) => {
  const { formatMessage } = useIntl()

  return (
    <OrderContainer data-test-id={`order-list-item-${orderNumber}`}>
      <OrderTextContainer>
        <OrderText>
          {`${formatMessage(messages.orderNumber)} ${orderNumber}`}
        </OrderText>
        {isAffectedOrder && (
          <OrderTextSecondary>
            {formatMessage(messages.noLogisticLimit)}
          </OrderTextSecondary>
        )}
      </OrderTextContainer>
      <OrderTotalContainer>
        <OrderText>{formatPrice(orderTotal)}</OrderText>
        {isAffectedOrder && (
          <OrderLinkContainer onClick={onViewOrderClick}>
            <OrderTextSecondary>
              {formatMessage(messages.viewOrder)}
            </OrderTextSecondary>
            <ArrowRightIcon color={theme.colors.gray5} />
          </OrderLinkContainer>
        )}
      </OrderTotalContainer>
    </OrderContainer>
  )
}

export default OrdersListItem
