const theme = {
  size: {
    inner: '1440px',
    panel: '490px',
  },

  colors: {
    transparent: 'transparent',
    primary: '#131B4D',
    primaryDark: '#0E0E3E',
    secondary: '#229944',
    secondaryBgLight: '#E2F5DA',
    error: '#F03D3E',
    errorDark: '#DE291F',
    warningHighlight: '#F37440',
    warning: '#F5AB41',
    info: '#5694F4',
    infoBlue: '#2A53E2',
    infoBlue2: '#4764FC',
    infoBlue3: '#2241E8',
    infoBlue4: '#EDF7FC',
    black: '#212429',
    placeholder: '#899097',
    gray8: '#EBEEF2',
    gray7: '#8E9BA8',
    gray6: '#D2DCE7',
    gray5: '#A6B3C0',
    gray4: '#737A80',
    gray3: '#ACB5BD',
    gray2: '#DDE2E5',
    gray1: '#F8F9FA',
    bgGray: '#f2f4f6',
    white: '#FFFFFF',
    red: '#F03D3E',
    redDark: '#DD0000',
    redDark2: '#FFD7C6',
    redLight: '#E45A4F',
    redLight2: '#FFECE4',
    redLight3: '#FFE5E3',
    border: 'rgba(221, 226, 229, 0.5)',
    borderSecondary: 'rgba(19, 27, 77, 0.1)',
    borderFaded: 'rgba(87, 106, 118, 0.15)',
    borderGreen: '#b8dfb2',
    bgGreen: '#D3FBCD',
    bgLightGreen: '#EEFBE8',
    bgLightYellow: '#FFF4E3',
    lightGreen: '#E7F9E9',
    orange: '#EA8208',
    orange2: '#FCE3C5',
    orange3: '#FFE8C4',
    orangeLight: '#FFECD6',
    orangeLight2: '#E0948E',
    orangeLight3: '#fdeedc',
    orangeLight4: '#FDF6DC',
    orangeLight5: '#FCF0C5',
    orangeDark: '#B45600',
    orangeDark2: '#874C16',
    orangeDark3: '#CF3E1E',
    purple: '#E4E2FE',
    lightPurple: '#EEEDFF',
    lightCyan: '#E4F4FF',
    cyan: '#DAEFFF',
    redDarkText: '#C82B1F',
    battleshipGrey: '#737a80',
    lightGrey: '#F1F4F8',
    darkGrey: '#E9EDF2',
    lightGrey2: '#E9E9E9',
    lightGrey3: '#E8EDF2',
    lightGrey4: '#657280',
    lightGrey5: '#F7F9FB',
    lightGrey6: '#F6F8FA',
    lightGrey7: '#E4E9EE',
    lightGreyHover: '#D8DFE7',
    paleGray: '#F2F4F6',
    fadedGray: '#E8ECEF',
    greenDark: '#007722',
    green: '#219845',
    greenLight: '#33BB55',
    greenLight2: '#eaf9e5',
    greenLight3: '#E8F9E0',
    greenLight4: '#D0F3BF',
    greenLight5: '#42CF001F',
    greenLight6: '#ADDCB1',
    greenLight7: '#ADDCB2',
    greenLightHover: '#22D20029',
    greenLightHover2: '#42CF0033',
    redMainHover: '#FF5F5B',
    redProgressTrail: '#E97B72',
    yellowDark: '#F2C98C',

    semiTranspBg: {
      success: '#e4f3e1',
      warning: '#fdefd9',
      error: '#fcd9d9',
      info: '#deeafd',

      green: '#f6fbf4',
      greenLight: '#DAF4D1D6',
      greenLight2: 'rgba(66, 207, 0, 0.18)',
      yellow: '#FFEFD8',
      locked: '#FFDDDD',
      gray: '#576A7626',
      grayLight: '#768AA51A',
      grayLight2: '#768AA529',
    },
    shadow: {
      modal: '#0C34593D',
    },
    status: {
      success: '#56ad4b',
      warning: '#dd9a3b',
      error: '#d83738',
      info: '#4d85dc',
    },
  },

  fonts: {
    primary: 'Inter, sans-serif',
  },

  textSizes: {
    xxs: '11px',
    xs: '12px',
    sm: '13px',
    m: '14px',
    l: '16px',
    xl: '20px',
    xxl: '28px',
  },

  headingSize: {
    sm: '12px',
    m: '22px',
    l: '28px',
    xl: '38px',
    xxl: '50px',
  },

  lineHeights: {
    base: '1.1',
    md: '1.2',
    lg: '1.5',
    xl: '1.9',
  },

  fontWeights: {
    regular: '400',
    medium: '500',
    bold: '600',
    strongBold: '700',
  },

  spacing: {
    xxs: '4px',
    xs: '8px',
    ss: '12px',
    sm: '16px',
    base: '24px',
    vertical: '20px',
    md: '32px',
    mlg: '40px',
    lg: '64px',
  },

  breakpoint: {
    tabletXs: '556px',
    tabletS: '808px',
    tabletM: '1072px',
    desktopS: '1264px',
    desktopM: '1537px',
    desktopL: '1680px',
  },

  borderRadius: {
    base: '8px',
    md: '16px',
  },

  zIndex: {
    base: 1,
    header: 10,
    modalOverlay: 20,
    loaderOverlay: 30,
  },

  shadows: {
    primary: '0px 12px 32px rgba(12, 52, 89, 0.16)',
    textTooltip: '0px 4px 36px rgba(12, 52, 89, 0.12)',
  },
}

export const paymentTheme = {
  green: {
    bg: theme.colors.secondary,
    bgHover: theme.colors.greenLight,
    text: theme.colors.white,
    bgDisabled: theme.colors.bgLightGreen,
    textDisabled: theme.colors.borderGreen,
  },
  yellow: {
    bg: theme.colors.orange3,
    bgHover: '#F8D6A3',
    text: theme.colors.orangeDark2,
    bgDisabled: '#FFF4E3',
    textDisabled: '#E0C4AA',
    shadow: '#9E4C0047',
  },
  red: {
    bg: theme.colors.redLight,
    bgHover: theme.colors.redMainHover,
    text: theme.colors.white,
    shadow: '#BD100247',
  },
}

export default theme
