import { fromJS } from 'immutable/dist/immutable'

import { DASHBOARD_TILES_SET } from 'containers/App/actions/dashboardTiles'
import { getBrowserDashboardTileOpen } from 'views/Dashboard/utils'

const initialState = fromJS({
  isOpen: getBrowserDashboardTileOpen(),
})

const appDashboardTilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_TILES_SET: {
      return state.set('isOpen', action.data)
    }
    default:
      return state
  }
}

export default appDashboardTilesReducer
