import React from 'react'
import theme from 'theme'

const EyeClosed = ({ color = theme.colors.primary }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3835 4.79074C13.5842 3.59103 10.4155 3.59103 7.61618 4.79074C7.3154 4.91965 7.29014 5.325 7.56241 5.50652L8.72542 6.28186C8.85684 6.36947 9.02169 6.38897 9.17187 6.34003C11.2683 5.65684 13.552 5.75317 15.5957 6.62903C17.3594 7.38492 18.8401 8.67739 19.8274 10.3228L20.4337 11.3334C20.6799 11.7437 20.6799 12.2563 20.4337 12.6665L19.8274 13.6772C19.8259 13.6796 19.8266 13.6827 19.8289 13.6842L21.407 14.7363C21.4525 14.7666 21.5142 14.7531 21.5423 14.7062L22.1487 13.6955C22.7749 12.6519 22.7749 11.3481 22.1487 10.3045L21.5423 9.29383C20.3388 7.28789 18.5337 5.71224 16.3835 4.79074ZM19.9 16.8182C20.1143 16.6023 20.0688 16.2478 19.8156 16.079L18.9602 15.5087C18.758 15.3739 18.4893 15.4051 18.3138 15.5732C17.5276 16.3261 16.6083 16.937 15.5957 17.371C13.2995 18.3551 10.7002 18.3551 8.40401 17.371C6.64028 16.6151 5.1596 15.3226 4.17235 13.6772L3.56597 12.6665C3.31981 12.2563 3.31981 11.7437 3.56597 11.3334L4.17235 10.3228C4.7314 9.39106 5.44868 8.57248 6.28598 7.90043C6.56309 7.678 6.56113 7.2427 6.26548 7.04559L5.41527 6.47879C5.23525 6.35878 4.99772 6.3673 4.83222 6.50666C3.89778 7.29345 3.09354 8.23353 2.45736 9.29383L1.85098 10.3045C1.2248 11.3481 1.2248 12.6519 1.85099 13.6955L2.45736 14.7062C3.66092 16.7121 5.46601 18.2877 7.61618 19.2092C10.4155 20.409 13.5842 20.409 16.3835 19.2092C17.7062 18.6424 18.8982 17.828 19.9 16.8182Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.93693 9.4274C8.35221 10.1229 8 11.0203 8 12C8 14.2091 9.79086 16 12 16C13.5494 16 14.8931 15.119 15.5574 13.8307C15.51 13.8059 15.4635 13.7784 15.4179 13.7481L8.93693 9.4274Z"
      fill={color}
    />
    <path d="M3 6L21 18" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export default EyeClosed
