import { TIDIO_STORE_IDS } from 'consts'
import { CONTACT_TITLE_IDS, CONTACT_TYPES } from 'containers/UserInfo/consts'

const CHAT_ELIGIBLE_STORES = TIDIO_STORE_IDS?.split(',') || []

const getBOKContactName = ({ contacts = [] }) => {
  const bokContact = contacts.find(({ type }) => type === CONTACT_TITLE_IDS.BOK)
  const contactName = bokContact?.contact?.find(
    ({ type }) => type === CONTACT_TYPES.NAME,
  )

  return contactName?.value
}

export const onTidioChatApiReady = userInfo => {
  const { billToCustomerNo, login, stockName, deliveryName } = userInfo
  const storeId = userInfo.storeId?.toString()

  if (CHAT_ELIGIBLE_STORES.includes(storeId)) {
    window.tidioChatApi.show()
  } else {
    window.tidioChatApi.hide()
  }

  window.tidioChatApi.setVisitorData({
    name: `${billToCustomerNo} ${deliveryName}`,
  })

  window.tidioChatApi.setContactProperties({
    kartoteka: billToCustomerNo,
    login,
    magazyn: stockName,
    nazwa_szukana: deliveryName,
    bok_contact: getBOKContactName(userInfo),
  })
}

export const initTidioChat = userInfo => {
  if (window.tidioChatApi) {
    window.tidioChatApi.on('ready', () => onTidioChatApiReady(userInfo))
  } else {
    document.addEventListener('tidioChat-ready', () =>
      onTidioChatApiReady(userInfo),
    )
  }
}

export const cleanupTidioChat = () => {
  if (window.tidioChatApi) {
    window.tidioChatApi.hide()

    window.tidioChatApi.setContactProperties({
      kartoteka: undefined,
      login: undefined,
      magazyn: undefined,
      nazwa_szukana: undefined,
    })
  }
}
