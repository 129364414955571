import React from 'react'

const Fish = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="group-[.active-btn]:fill-white"
      d="M9.03852 17.8942C8.36657 17.8312 8.0549 17.0757 8.31397 16.4525C8.75597 15.3893 8.99997 14.2232 8.99997 13V11C8.99997 9.8613 8.7885 8.77199 8.40269 7.7692C8.1626 7.14518 8.48589 6.40514 9.15236 6.35163C9.37292 6.33393 9.59539 6.32471 9.81955 6.32471C13.9742 6.32471 16.8447 7.90843 18.5801 9.90583C18.7828 9.51424 19.0382 9.1383 19.3597 8.80608C20.4585 7.67108 22.0637 7.32171 23.0757 7.21719C23.6138 7.16162 24.0437 7.5971 23.9947 8.14795C23.9026 9.18408 23.5769 10.8294 22.4782 11.9644L22.4514 11.9918L22.4788 12.0198C23.5775 13.1548 23.9032 14.8001 23.9954 15.8363C24.0444 16.3871 23.6144 16.8226 23.0764 16.767C22.0644 16.6625 20.4591 16.3131 19.3604 15.1781C19.0845 14.8931 18.8574 14.576 18.6704 14.2444C16.945 16.2912 14.047 17.931 9.81955 17.931C9.55676 17.931 9.2963 17.9183 9.03852 17.8942Z"
      fill="#229944"
    />
    <path
      d="M4.90401 16.7844C5.36942 17.0459 5.95135 16.8483 6.15464 16.3546C6.69675 15.0382 7.00073 13.5609 7.00073 12C7.00073 10.4117 6.68598 8.90983 6.12586 7.57612C5.91968 7.08518 5.33859 6.89108 4.87507 7.15314C2.92834 8.25379 1.35823 9.80526 0.431756 10.8451C-0.142942 11.4901 -0.142942 12.4311 0.431756 13.0761C1.36282 14.1211 2.9439 15.6828 4.90401 16.7844Z"
      fill="#99D0AC"
    />
    <ellipse cx="3.72655" cy="11.8485" rx="1.02464" ry="1.19855" fill="white" />
  </svg>
)

export default Fish
