import useOnClickOutside from 'use-onclickoutside'

export const CLICK_OUTSIDE_IGNORED_CLASSNAME = 'ignore-onClickOutside'

const hasClass = (element, className) =>
  element &&
  (element.correspondingElement
    ? element.correspondingElement
    : element
  ).classList.contains(className)

// Returns true if the given element, or any parentElement has the ignoredClass class
const isInIgnoredElement = (element, ignoredClass) => {
  if (!element) {
    return false
  }

  if (hasClass(element, ignoredClass)) {
    return true
  }

  return isInIgnoredElement(element.parentElement, ignoredClass)
}

export default (ref, handler, ignoredClass = CLICK_OUTSIDE_IGNORED_CLASSNAME) =>
  useOnClickOutside(ref, event => {
    if (isInIgnoredElement(event.target, ignoredClass)) {
      return
    }

    handler(event)
  })
