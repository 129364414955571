import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useTransition, config } from '@react-spring/web'
import { INVOICE_STATUS } from 'containers/Invoices/consts'
import { useDaysCountFormatted } from 'utils/datetime'
import messages from './messages'

export const isInvoicePayable = ({ statusId }) =>
  statusId !== INVOICE_STATUS.PAID &&
  statusId !== INVOICE_STATUS.OVERPAID &&
  statusId !== INVOICE_STATUS.PAYMENT

export const usePaySummaryTransition = isSelected => {
  const enterConfig = {
    mass: 1,
    tension: 600,
    friction: 15,
  }
  const leaveConfig = { ...config.default, duration: 100 }

  return useTransition(isSelected, {
    from: { transform: 'translateY(75px)', opacity: 0 },
    enter: { transform: 'translateY(0px)', opacity: 1 },
    leave: { transform: 'translateY(75px)', opacity: 0 },
    config: isSelected ? enterConfig : leaveConfig,
  })
}

export const getInvoiceExpiryDays = date => {
  const now = dayjs().endOf('day')
  const deadlineDate = dayjs(date).endOf('day')
  const expiredDays = now.diff(deadlineDate, 'days')

  return expiredDays
}

export const useRemainingDaysFormatted = paymentDeadlineDate => {
  const { formatMessage } = useIntl()
  const daysTillInvoice = Math.abs(getInvoiceExpiryDays(paymentDeadlineDate))

  return useDaysCountFormatted({
    date: paymentDeadlineDate,
    defaultMsg: formatMessage(messages.days, { number: daysTillInvoice }),
  })
}
