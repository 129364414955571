import { makeMessages, makeTranslation } from 'utils'

const messages = {
  consents: 'consents.title',
  contacts: 'contacts.title',
  supportHeader: 'contacts.support.header',
  supportSubheader: 'contacts.support.subHeader',
  other: 'other.title',
  supportMail: {
    id: 'support.mail',
  },
  supportPhone: {
    id: 'support.phone',
  },
}

export default makeMessages(messages, makeTranslation('profile'))
