import React from 'react'

import ChangePassword from './ChangePassword'
import DeleteAccount from './DeleteAccount'
import ContactForm from './ContactForm'

import { OtherRowsWrapper } from './styles'

const Other = () => (
  <>
    <OtherRowsWrapper>
      <ChangePassword />
      <ContactForm />
    </OtherRowsWrapper>

    <DeleteAccount />
  </>
)

export default Other
