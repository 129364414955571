import React from 'react'
import PropTypes from 'prop-types'

import { Search as SearchIcon } from 'components/Icons'

import { SearchContainer, SearchInput, SearchIconContainer } from './styles'

const Search = ({ onSearchChange, placeholder, isEmpty, testId }) => (
  <SearchContainer padded={!isEmpty}>
    <SearchIconContainer>
      <SearchIcon />
    </SearchIconContainer>
    <SearchInput
      data-test-id={testId}
      placeholder={placeholder}
      onChange={ev => onSearchChange(ev.target.value)}
    />
  </SearchContainer>
)

Search.propTypes = {
  isEmpty: PropTypes.bool,
  onSearchChange: PropTypes.func,
  placeholder: PropTypes.node,
  testId: PropTypes.string,
}

export default Search
