import { makeMessages, makeTranslation } from 'utils'

const messages = {
  title: 'title',
  description: 'description',
  deleteBtn: 'deleteBtn',
  return: { id: 'return' },
}

export default makeMessages(
  messages,
  makeTranslation('profile.other.deleteAccount.modal'),
)
