import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { TinyTextV2 } from 'components/Text'

export const FOOTER_OFFSET = {
  INNER: 'INNER',
  MOBILE_SIDE_BOTTOM: 'MOBILE_SIDE_BOTTOM',
}

export const FooterContainer = styled.div`
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.sm};

  ${p => p.$shadowColor && `box-shadow: 0px 1px 2px 0px ${p.$shadowColor}`};

  ${p =>
    p.$offset === FOOTER_OFFSET.INNER &&
    `
    padding: ${theme.spacing.sm};
  `};

  ${p =>
    p.$offset === FOOTER_OFFSET.MOBILE_SIDE_BOTTOM &&
    `
    padding: 0 ${theme.spacing.xs};

    @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
      padding: 0;
    }
  `};
`

export const FooterText = styled(TinyTextV2)`
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: 13px;
    line-height: 20px;
  }
`
