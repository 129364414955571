import React from 'react'

import theme from 'theme'

const NoInvoices = ({ color = theme.colors.primary }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 10C11 8.34315 12.3431 7 14 7L26.7574 7C27.553 7 28.3161 7.31607 28.8787 7.87868L36.1213 15.1213C36.6839 15.6839 37 16.447 37 17.2426V39.1315C37 39.9302 36.1099 40.4066 35.4453 39.9635L33.6774 38.7849C32.9718 38.3146 32.0463 38.3402 31.368 38.849L29.632 40.151C28.9537 40.6598 28.0282 40.6854 27.3226 40.2151L25.1094 38.7396C24.4376 38.2917 23.5624 38.2917 22.8906 38.7396L20.6774 40.2151C19.9718 40.6854 19.0463 40.6598 18.368 40.151L16.632 38.849C15.9537 38.3402 15.0282 38.3146 14.3226 38.7849L12.5547 39.9635C11.8901 40.4066 11 39.9302 11 39.1315L11 10Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 30L28 22"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M20 22L28 30"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M37 16H31C29.3431 16 28 14.6569 28 13V7"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
)

export default NoInvoices
