import styled from 'styled-components'

import theme from 'theme'

export const TimelineTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${theme.colors.primary};
  margin: 0 ${theme.spacing.sm};

  b {
    color: ${theme.colors.secondary};
    font-weight: 700;
  }
`

export const TimelineHeader = styled.div`
  display: flex;
  margin: ${theme.spacing.md} 0;
  position: relative;
`

export const DottedLineContainer = styled.div`
  position: absolute;
  z-index: ${theme.zIndex.base};
  left: 34px;
  top: 24px;
`
