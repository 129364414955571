import { makeMessages, makeTranslation } from 'utils'

const messages = {
  allProducts: 'allProducts',
  all: 'all',
  brandAppended: 'brandAppended',
  twoFilters: 'twoFilters',
  twoFiltersAndMore: 'twoFiltersAndMore',
  twoFiltersAndOther: 'twoFiltersAndOther',
  filteredTitle: 'filteredTitle',
  filtersIn: 'filtersIn',
  search: 'search',
  inCategory: 'inCategory',
}

export default makeMessages(messages, makeTranslation('products.header'))
