import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { BoldInfo } from 'components/Text'
import { Logo } from 'components/Icon'
import { Clock } from 'components/Icons'
import {
  LeadRow,
  Link,
  MessageContainer,
  IconContainer,
  Container,
} from 'containers/App/Maintenance/styles'
import { useIntl } from 'react-intl'
import messages from './messages'

const LogoStyled = styled(Logo)`
  position: absolute;
  top: 32px;
  left: 48px;
  height: 30px;
`

const Maintenance = ({ config }) => {
  const { formatMessage } = useIntl()

  return (
    <Flex flexDirection="column" height="100%" bg={theme.colors.paleGray}>
      <LogoStyled />

      <Container>
        <IconContainer>
          <Clock />
        </IconContainer>

        <MessageContainer flexDirection="column">
          <BoldInfo>{config.headerLine}</BoldInfo>

          <LeadRow>
            <BoldInfo color={theme.colors.secondary}>
              {formatMessage(messages.leadLine)}
            </BoldInfo>
            <BoldInfo color={theme.colors.secondary}>
              {`${formatMessage(messages.linkLine)} `}
              <Link href="https://www.bidfood.pl/oddzialy-i-filie">
                {formatMessage(messages.link)}
              </Link>
              .
            </BoldInfo>
          </LeadRow>
        </MessageContainer>
      </Container>
    </Flex>
  )
}

export default Maintenance
