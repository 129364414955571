import React from 'react'

const Wege = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9715 15.8767C16.5815 12.9016 15.7317 11.4526 13.9719 10.5662C12.3818 9.76515 10.6431 9.69874 8.99918 9.63596C7.25817 9.56946 5.62353 9.50703 4.38442 8.58034C3.94343 8.25054 3.13869 8.38396 3.07857 8.89212C2.40032 14.6253 6.09364 19.3275 14.9176 18.9869C15.2416 19.4485 15.525 19.9596 15.7863 20.5341C15.9597 20.9154 16.4478 21.0996 16.8765 20.9457C17.3052 20.7917 17.5121 20.3578 17.3387 19.9766C16.6227 18.4026 15.706 17.1476 14.2647 16.1046C12.9471 15.1513 11.2362 14.4081 8.9484 13.7314C9.07106 13.4955 9.4098 13.3425 9.91269 13.4288C12.5044 13.8736 14.6363 15.0307 15.9873 16.2283C16.3124 16.5164 17.0245 16.2812 16.9715 15.8767Z"
      fill="#229944"
      className="group-[.active-btn]:fill-white"
    />
    <path
      d="M11.5187 7.98916C11.305 7.96753 11.1613 7.75558 11.2417 7.55645C11.5618 6.76405 12.0282 6.20257 12.6785 5.75802C13.8417 4.96279 15.157 4.81742 16.4005 4.67999C17.877 4.5168 19.2523 4.36479 20.152 3.14943C20.3251 2.91559 20.7083 2.96096 20.7666 3.25215C21.4656 6.74802 20.6138 9.96695 17.9618 11.7887C17.7935 11.9043 17.5653 11.83 17.4768 11.6459C17.4696 11.631 17.4624 11.6161 17.4551 11.6012C16.9985 10.6681 16.3996 9.96177 15.6457 9.41145C15.4589 9.27509 15.4432 8.99484 15.6292 8.85738C16.5003 8.21346 17.4338 7.66471 18.313 7.26605C18.4965 7.1828 18.4513 6.89836 18.2521 6.90987C16.6877 7.00022 15.2656 7.60835 14.0763 8.4109C13.9862 8.47173 13.8726 8.48642 13.7697 8.45142C13.0106 8.19333 12.248 8.06299 11.5187 7.98916Z"
      fill="#99D0AC"
    />
  </svg>
)

export default Wege
