import React from 'react'

const Ramen = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.19618 6.3255L14.6678 4.00007"
      stroke="#657280"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M9.33331 3.33331L9.33331 9.99998"
      stroke="#A6B3C0"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M5.76449 4.71568L15.3332 1.00003"
      stroke="#657280"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M3.33331 14V13.1824C3.33331 12.9954 3.25389 12.8181 3.12017 12.6874C2.30721 11.8929 1.54783 10.8317 1.3716 9.33225C1.32862 8.96657 1.63179 8.66669 1.99998 8.66669H14C14.3682 8.66669 14.6713 8.96657 14.6284 9.33225C14.4521 10.8317 13.6927 11.8929 12.8798 12.6874C12.7461 12.8181 12.6666 12.9954 12.6666 13.1824V14C12.6666 14.3682 12.3682 14.6667 12 14.6667H3.99998C3.63179 14.6667 3.33331 14.3682 3.33331 14Z"
      fill="#A6B3C0"
    />
    <path
      d="M7.33331 7.33323L7.33331 3.99986C7.33331 2.66652 8.33331 2.33333 8.99998 2.66663"
      stroke="#A6B3C0"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
  </svg>
)

export default Ramen
