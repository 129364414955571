import { createSelector } from 'reselect'
import { getFormValues, SubmissionError } from 'redux-form/immutable'
import { translationMessages } from 'i18n'
import _reduce from 'lodash/reduce'
import _memoize from 'lodash/memoize'
import _camelCase from 'lodash/camelCase'
import _get from 'lodash/get'

export * from './validators'
export * from './normalizers'

export const formValuesSelector = (formName, fallback = {}) =>
  createSelector(
    getFormValues(formName),
    formValues => (formValues ? formValues.toJS() : fallback),
  )

export const trimValues = values =>
  _reduce(
    values,
    (acc, curr, key) => ({
      ...acc,
      [key]: curr && curr.trim ? curr.trim() : curr,
    }),
    {},
  )

export const wrapSagaWithPromise = _memoize(saga => (data, additionalData) =>
  new Promise((resolve, reject) => {
    saga(data, {
      ...additionalData,
      resolve,
      reject,
    })
  }),
)

export const wrapSagaWithSubmissionErrorsThrow = saga => async (
  data,
  additionalData,
) => {
  try {
    return await wrapSagaWithPromise(saga)(data, additionalData)
  } catch ({ formErrors, statusText }) {
    if (!formErrors) throw new SubmissionError({ _error: statusText })
    throw new SubmissionError(formErrors)
  }
}

export const getResponseErrors = (response, locale = 'en') => {
  const bodyErrors = _get(response, 'body.errors')

  if (bodyErrors) {
    return bodyErrors.reduce((errors, { message, code, field_name }) => { // eslint-disable-line
      const errorMessage = _get(
        translationMessages,
        [locale, `error.${code}`],
        message || code,
      )

      if (!field_name) { // eslint-disable-line
        return {
          ...errors,
          _error: errorMessage,
        }
      }

      return {
        ...errors,
        [_camelCase(field_name)]: errorMessage,
      }
    }, {})
  }

  return response.statusText || 'Connection error'
}
