import { takeLatest, all, call, put } from 'redux-saga/effects'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { genericGetDataEnhanced } from 'containers/App/sagas'

import { trackUserIdReceived } from 'services/analytics/user'
import { initTidioChat } from 'utils/tidioChat'
import { getUserInfo } from './api'
import actions from './actions'

export function* getUserInfoFlow() {
  try {
    const userInfo = yield call(genericGetDataEnhanced, {
      actions,
      request: getUserInfo,
    })

    if (userInfo) {
      trackUserIdReceived(userInfo)
      initTidioChat(userInfo)
    }
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default function* watcher() {
  yield all([takeLatest(actions.DELTA, getUserInfoFlow)])
}
