import styled from 'styled-components'

import theme from 'theme'
import { Button, BUTTON_SIZE } from 'components/Button'

import { APP_BREAKPOINTS } from 'consts'

export * from './FooterButtons'
export * from './Containers'

export const RemoveAllUnavailableBtn = styled(Button).attrs({
  size: BUTTON_SIZE.SMALL,
})`
  background-color: ${theme.colors.redDark};

  :hover {
    background-color: ${theme.colors.red};
  }

  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    width: 100%;
    margin-top: ${theme.spacing.base};
  }
`
