import { takeLatest, call, put, race, take } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import {
  MODAL_SOMETHING_WENT_WRONG,
  MODAL_SIZES,
} from 'containers/App/modalTypes'
import { openModal, MODAL_CLOSE } from 'containers/App/actions/modal'
import getCart, { updateCartItem } from 'containers/Cart/actions'

import { getReplacements } from './api'
import { getReplacementsActions } from './actions'
import { REPLACEMENTS_MODAL } from './consts'

export function* getReplacementsFlow({
  data: { productId, showInModal, templateId, refetchCart },
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getReplacementsActions,
      request: getReplacements,
      params: { productId },
    })

    if (showInModal) {
      yield put(
        openModal(REPLACEMENTS_MODAL, {
          size: MODAL_SIZES.BIG,
          hideHeader: true,
          bodyClassName: 'Replacements__modal',
          templateId,
        }),
      )
    }

    if (refetchCart) {
      while (true) {
        const { replacementsClosed } = yield race({
          itemAddedToCart: take(updateCartItem.SUCCESS),
          replacementsClosed: take(MODAL_CLOSE),
        })

        if (replacementsClosed) break

        yield put(getCart.delta())
      }
    }
  } catch (error) {
    console.log('Error getting/processing replacements', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [takeLatest(getReplacementsActions.DELTA, getReplacementsFlow)]
