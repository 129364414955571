import React from 'react'
import { FormattedMessage } from 'react-intl'

import Notification from '../Notification'
import messages from './messages'

const Invalid = () => (
  <Notification header={messages.invalid}>
    <FormattedMessage {...messages.invalidDescription} />
  </Notification>
)

export default Invalid
