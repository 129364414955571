import queryString from 'query-string'

export const getCart = ({ perPage = 100, page = 1 } = {}) => {
  const queryParams = queryString.stringify({
    per_page: perPage,
    page,
  })

  return {
    url: `me/cart?${queryParams}`,
  }
}

export const emptyCart = () => {
  const url = 'me/cart'
  const options = {
    method: 'DELETE',
  }

  return { url, options }
}

export const updateCart = body => {
  const url = `me/cart_items`
  const options = {
    method: 'PATCH',
    body: {
      cart_items: body,
    },
  }

  return { url, options }
}

export const addToCart = ({ productId, unit, ...body }) => {
  const url = `me/cart_items/${productId}/unit/${unit}`
  const options = {
    method: 'PUT',
    body,
  }

  return { url, options }
}

export const deleteCartItem = ({ productId, unitOfMeasure }) => {
  const url = `me/cart_items/${productId}${
    unitOfMeasure ? `/unit/${unitOfMeasure}` : ''
  }`
  const options = {
    method: 'DELETE',
  }

  return { url, options }
}

export const updateDelivery = body => {
  const url = '/me/cart/delivery'
  const options = {
    method: 'PATCH',
    body,
  }

  return { url, options }
}
