import React from 'react'

const Pierogi = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="14" r="3" fill="#E39600" />
    <circle
      cx="3"
      cy="3"
      r="3"
      transform="matrix(-1 0 0 1 21 11)"
      fill="#E39600"
    />
    <ellipse
      cx="9"
      cy="4.5"
      rx="9"
      ry="4.5"
      transform="matrix(-1 0 0 1 20 8)"
      fill="#E39600"
    />
    <circle cx="4" cy="10" r="3" fill="#E39600" />
    <circle
      cx="3"
      cy="3"
      r="3"
      transform="matrix(-1 0 0 1 21 7)"
      fill="#E39600"
    />
    <circle cx="7" cy="7" r="3" fill="#E39600" />
    <circle
      cx="3"
      cy="3"
      r="3"
      transform="matrix(-1 0 0 1 18 4)"
      fill="#E39600"
    />
    <circle cx="11" cy="6" r="3" fill="#E39600" />
    <circle cx="6" cy="18" r="3" fill="#F7C26E" />
    <circle
      cx="3"
      cy="3"
      r="3"
      transform="matrix(-1 0 0 1 23 15)"
      fill="#F7C26E"
    />
    <ellipse
      cx="9"
      cy="4.5"
      rx="9"
      ry="4.5"
      transform="matrix(-1 0 0 1 22 12)"
      fill="#F7C26E"
    />
    <circle cx="6" cy="14" r="3" fill="#F7C26E" />
    <circle
      cx="3"
      cy="3"
      r="3"
      transform="matrix(-1 0 0 1 23 11)"
      fill="#F7C26E"
    />
    <circle cx="9" cy="11" r="3" fill="#F7C26E" />
    <circle
      cx="3"
      cy="3"
      r="3"
      transform="matrix(-1 0 0 1 20 8)"
      fill="#F7C26E"
    />
    <circle cx="13" cy="10" r="3" fill="#F7C26E" />
    <path
      d="M8 16.0004C8 10 18 9.99992 18 16.0004"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Pierogi
