import { createStructuredSelector } from 'reselect'
import _isArray from 'lodash/isArray'
import _includes from 'lodash/includes'

export const selectIsFetching = selector => state => {
  const isArray = _isArray(selector)
  const immutableState = isArray ? state[selector[0]] : state[selector]

  return isArray
    ? immutableState?.getIn([...selector.slice(1), 'isFetching'])
    : immutableState?.get('isFetching')
}

export const selectIsFetchingMultiple = reducerNames => state =>
  _includes(reducerNames.map(name => selectIsFetching(name)(state)), true)

export const isFetchingSelector = reducerName =>
  createStructuredSelector({
    fetching: _isArray(reducerName)
      ? selectIsFetchingMultiple(reducerName)
      : selectIsFetching(reducerName),
  })
