import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { userDataSelector } from 'containers/UserInfo/selectors'
import {
  trackPopupNonStockDistantDD,
  trackPopupShowReplacement,
} from 'services/analytics'
import { ProductRowBoxMissing } from 'components/Product'
import { getColors, getSpacing } from 'components/helpers/theme-selectors'
import ErrorContainer from 'views/Cart/ErrorContainer'

import messages from '../messages'

// TODO check the view

const RowBox = styled(ProductRowBoxMissing)`
  border: 1px solid ${p => getColors(p).border};
  padding: ${p => getSpacing(p).base};
`

const OutOfStockModal = ({ modal: { product, closeModal } }) => {
  const { formatMessage } = useIntl()
  const user = useSelector(userDataSelector)
  const onTrackGetReplacements = productId =>
    trackPopupShowReplacement(productId, user)
  const onTrackShiftDelivery = (...params) =>
    trackPopupNonStockDistantDD(user, ...params)

  return (
    <React.Fragment>
      <ErrorContainer
        errorMessage={formatMessage(messages.singleItemAddedPartialy)}
      />
      <RowBox
        variants={[product]}
        formatMessage={formatMessage}
        {...product}
        onProductDetailsClick={closeModal}
        onTrackGetReplacements={onTrackGetReplacements}
        onTrackShiftDelivery={onTrackShiftDelivery}
        hideStar
        hideTrash
      />
    </React.Fragment>
  )
}

export default OutOfStockModal
