import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { Button, SecondaryBtn } from 'components/Button'
import { X } from 'components/Icons'
import { ICONS, ICONS_POSITION } from 'components/Icon'
import { BaseText } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

export const Overlay = styled.div`
  background: ${theme.colors.gray2};
  opacity: 0.3;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${theme.zIndex.modalOverlay};
`

export const Wrapper = styled(Flex)`
  position: fixed;
  z-index: ${theme.zIndex.modalOverlay + 1};
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 620px;
  flex-direction: column;
  padding: ${theme.spacing.md};
  background: ${theme.colors.white};
  box-shadow: 0px 24px 112px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: calc(100vw - ${theme.spacing.sm});
    padding: ${theme.spacing.sm};
  }
`

export const HeaderWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const Header = styled(BaseText)`
  font-size: 28px;
  line-height: 36px;
  font-weight: ${theme.fontWeights.strongBold};
  color: ${theme.colors.secondary};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    font-size: 22px;
    line-height: 28px;
  }
`

export const Info = styled(BaseText)`
  margin: ${theme.spacing.md} 0;
  font-size: 20px;
  line-height: 32px;
  color: ${theme.colors.primary};
  white-space: pre-wrap;
  padding: 0 ${theme.spacing.xs};

  b {
    font-weight: ${theme.fontWeights.bold};
  }

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    font-size: 14px;
    line-height: 22px;
    margin: ${theme.spacing.sm} 0;
    padding: 0;
  }
`

export const Img = styled.img`
  width: 100%;
  margin-top: 40px;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    margin-top: ${theme.spacing.base};
  }
`

export const XIcon = styled(X)`
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin-right: -${theme.spacing.xs};
  cursor: pointer;

  path {
    stroke-width: 1.5px;
  }
`

const buttonCss = css`
  height: 56px;
  width: 100%;
  font-size: 18px;
  font-weight: ${theme.fontWeights.bold};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    font-size: 14px;
    line-height: 20px;
    min-width: 120px;
    padding: 0 ${theme.spacing.xs};

    figure {
      right: ${theme.spacing.xxs};

      svg {
        width: 18px;
      }
    }
  }
`

export const SubmitButton = styled(Button)`
  background-color: ${theme.colors.secondaryBgLight};
  color: ${theme.colors.secondary};

  :hover {
    background-color: ${theme.colors.secondaryBgLight};
  }

  ${buttonCss};
`

export const NextButton = styled(SecondaryBtn).attrs({
  iconType: ICONS.CHEVRON_RIGHT,
  iconPosition: ICONS_POSITION.RIGHT,
})`
  position: relative;

  figure {
    position: absolute;
    right: ${theme.spacing.sm};
  }

  ${buttonCss};
`
