import styled from 'styled-components'

import { BaseTextV2, BaseTextV2Bold } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${theme.spacing.sm};
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: 0 40px;
  }
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: 0 80px;
  }
`

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  align-self: center;
  padding: 48px ${theme.spacing.xs};
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    max-width: 624px;
    padding: 88px 0;
  }
`

export const InfoSectionTitle = styled(BaseTextV2Bold)`
  font-size: 24px;
  line-height: 32px;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: 36px;
    line-height: 48px;
  }
`

export const InfoSectionText = styled(BaseTextV2)`
  font-size: ${theme.textSizes.l};
  line-height: 28px;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: 18px;
  }
`
