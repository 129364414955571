import { compose } from 'lodash/fp'
import { connect } from 'react-redux'

import { withSpinner } from 'hocs'
import { PRODUCTS_REDUCER_NAME } from 'containers/Products'
import { REPLACEMENTS_REDUCER_NAME } from 'containers/Products/Replacements/consts'

import { orderErrorItemsSelector } from '../Orders/selectors'

export default compose(
  withSpinner([[PRODUCTS_REDUCER_NAME, REPLACEMENTS_REDUCER_NAME]]),
  connect(state => ({
    orderErrorItems: orderErrorItemsSelector(state),
  })),
)
