import * as React from 'react'

import theme from 'theme'

const Heart = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M5.22284 11.9678L11.2357 19.0942C11.6352 19.5677 12.3648 19.5677 12.7643 19.0942L18.7772 11.9678C20.04 10.4711 19.9667 8.26177 18.6073 6.85206C17.1049 5.29397 14.6094 5.29397 13.1069 6.85206L12 8L10.8931 6.85206C9.39061 5.29397 6.8951 5.29397 5.39265 6.85206C4.03329 8.26177 3.95996 10.4711 5.22284 11.9678Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

export default Heart
