import { allCountries } from 'country-telephone-data'
import { compose, find, get, equals, toLower } from 'lodash/fp'

export const mapIsoCountryCodeToName = countryCode =>
  compose(
    get('name'),
    find(
      compose(
        equals(toLower(countryCode)),
        get('iso2'),
      ),
    ),
  )(allCountries)
