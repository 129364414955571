import React from 'react'

const GooglePlay = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2261 19.6674C20.0156 18.1063 22.1303 16.9167 22.3125 16.8242C22.8956 16.5082 23.4978 15.6717 22.3125 15.0219C21.9299 14.8176 19.8695 13.665 17.2261 12.1787L13.5615 15.9508L17.2261 19.6673V19.6674Z"
      stroke="#A6B3C0"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.5615 15.9512L1.89319 27.9374C2.16707 27.9744 2.47627 27.9004 2.84082 27.6961C3.60618 27.2691 11.7197 22.753 17.2261 19.6686L13.5615 15.9512Z"
      stroke="#A6B3C0"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.5616 15.9513L17.2261 12.1977C17.2261 12.1977 3.66158 4.65254 2.84087 4.20714C2.53167 4.02048 2.18517 3.96504 1.87511 4.02048L13.5616 15.9513V15.9513Z"
      stroke="#A6B3C0"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.5616 15.9513L1.8751 4.02051C1.40081 4.13227 1 4.55937 1 5.43283V26.5252C1 27.3247 1.31015 27.9005 1.89323 27.956L13.5616 15.9513Z"
      stroke="#A6B3C0"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

export default GooglePlay
