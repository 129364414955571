import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'

export const Container = styled.div`
  display: flex;
  padding: ${theme.spacing.xs};
  justify-content: space-between;
  gap: ${theme.spacing.sm};
  cursor: pointer;
`

export const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
  flex: 1;
`

export const OrderRowText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})``
