import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  addAll: 'addAll',
  addAllModalHeader: 'addAllModalHeader',
  addAllModalConfirm: 'addAllModalConfirm',
  change: 'change',
  addedToCartNotification: 'addedToCartNotification',
  addedToCartNewDelivery: { id: 'product.addedToCartNewDelivery' },
  addedToCartNotificationError: {
    id: 'templates.error.addedToCartNotification',
  },
  removedTemplateNotification: 'removedTemplateNotification',
  removeTemplate: 'removeTemplate',
  removeAccept: 'removeAccept',
  removeConfirmationDialog: 'removeConfirmationDialog',
  emptyView: 'emptyView',
  goToCategories: 'goToCategories',
  singleItemAddedPartialy: { id: 'outOfStock.singleItemAddedPartialy' },
  noLongerActive: 'noLongerActive',
  noLongerActiveRemaining: 'noLongerActiveRemaining',
  toTop: { id: 'toTop' },
  newTemplate: 'newTemplate',
  cancel: 'cancel',
  createNotification: 'createNotification',
}

export default makeMessages(messages, makeTranslation('templates'))
