import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { get, identity, prop } from 'lodash/fp'
import { debounce, noop } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import usePrevious from 'use-previous'

import { handleNumberChange } from 'utils'
import { MAX_INPUT_VALUE } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import {
  isAvailableAfter,
  calculateAmountByQuantity,
  roundEnteredAmount,
  calculateQuantityByAmount,
  formatUnitAmount,
  getInCartAmount,
} from 'components/Product/utils'
import cartActions from 'containers/Cart/actions'
import { setIsTooltipOpen } from 'containers/App/actions'
import { ELASTIC_AFFECT_TYPE } from 'containers/Cart/elasticStockUtils'
import { getReplacementsActions } from 'containers/Products/Replacements/actions'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'

import messages from './messages'
import CatalogProductButtons from '../ProductButtons/CatalogProductButtons'
import { StockExceeded, StockDecreased } from '../StockConfirmation'
import {
  CatalogContainer,
  DiscardButtonV2,
} from '../StockConfirmation/Confirmation/styles'

import CatalogOutOfStock from './CatalogOutOfStock'

import { StockLimitedTooltipContainer, StockLimitedTooltipText } from './styles'

const CatalogButtonsContainer = ({
  hideCart,
  cartProduct,
  outOfStock,
  inTemplate,
  productId,
  dateChangeCallback,
  product,
  isVariants,
  unitData = {},
  refetchOnCartClick,
  suppressGetDeliveryDates,
  refetchDataCallback,
  addRemoveCallback = noop,
  onNotifyStockUnavailable,
  justAddedToCartQty,
  additionalEventParams,
  itemListName,
  itemListId,
  index,
  itemModelId,
  attributionToken,
  ...rest
}) => {
  const dispatch = useDispatch()
  const userData = useSelector(userDataSelector)
  const { formatMessage } = useIntl()

  const [affectedElasticStock, setAffectedElasticStock] = useState(null)
  const [isDeleteFetching, setIsDeleteFetching] = useState(false)
  const [stockLimitedAmount, setStockLimitedAmount] = useState(null)
  const [isStockLimitedTooltipOpen, setStockLimitedTooltipOpen] = useState(
    false,
  )
  const [amount, setAmount] = useState(
    calculateAmountByQuantity(unitData, unitData?.inCartQuantity),
  )

  const prevUnitData = usePrevious(unitData)

  const location = useLocation()

  useEffect(
    () => {
      if (
        unitData.inCartQuantity !== prevUnitData?.inCartQuantity ||
        unitData.unitOfMeasure !== prevUnitData?.unitOfMeasure
      ) {
        setAmount(calculateAmountByQuantity(unitData, unitData.inCartQuantity))
      }
    },
    [unitData],
  )

  useEffect(
    () => {
      dispatch(setIsTooltipOpen(false))
    },
    [location],
  )

  const openStockLimitedTooltip = useCallback(stock => {
    setStockLimitedAmount(stock)
    setStockLimitedTooltipOpen(true)
  }, [])

  const closeStockLimitedTooltip = () => {
    setStockLimitedAmount(null)
    setStockLimitedTooltipOpen(false)
  }

  const onAddAmount = useCallback(
    (newAmount, options = {}) => {
      const {
        suppressElasticStockCheck,
        onAddSuccessCallback = identity,
      } = options

      const unit = get('unitOfMeasure', unitData)
      const resetAmount = getInCartAmount(unitData)
      const justAddedToCart = !unitData.inCartQuantity
      const quantity =
        justAddedToCart && justAddedToCartQty
          ? justAddedToCartQty
          : calculateQuantityByAmount(unitData, newAmount)

      dispatch(
        cartActions.createDelta({
          product,
          productId,
          unit,
          quantity,
          itemListName,
          itemListId,
          itemModelId,
          attributionToken,
          justAddedToCart,
          index,
          additionalEventParams,
          suppressStockNotification: !!onNotifyStockUnavailable,
          suppressElasticStockCheck,
          shiftOnDecreaseToClosestDate: !dateChangeCallback,
          callback: ({ affectedElasticStock: affectedStock }) => {
            if (affectedStock) {
              const { unitOfMeasureObj } = affectedStock
              setAmount(
                calculateAmountByQuantity(
                  unitOfMeasureObj,
                  unitOfMeasureObj.inCartQuantity,
                ),
              )
              setAffectedElasticStock(affectedStock)
            } else {
              onAddSuccessCallback()
            }

            addRemoveCallback({
              justAddedToCart,
              quantity,
              unitOfMeasure: unit,
            })
          },
          refetchDataCallback,
          getMessage: stockAmount =>
            formatMessage(messages.maxAvailableAmount, {
              amount: formatUnitAmount(unitData, stockAmount),
            }),
          changeAmountToStockCallback: (stock, unitOfMeasure) => {
            if (onNotifyStockUnavailable) {
              onNotifyStockUnavailable(stock, unitOfMeasure)
            }
            setAmount(stock)
          },
          resetAmountCallback: () => {
            setAmount(roundEnteredAmount(unitData, resetAmount))
          },
          openStockLimitedTooltip: stock => openStockLimitedTooltip(stock),
        }),
      )
    },
    [
      productId,
      dateChangeCallback,
      product,
      formatMessage,
      unitData,
      // refetchDataCallback,
      // addRemoveCallback,
      // onNotifyStockUnavailable,
      // excluded otherwise we will update this function on every render, making debounce useless
      // those should update on unitData change
      justAddedToCartQty,
      additionalEventParams,
      itemListName,
      itemListId,
      itemModelId,
      attributionToken,
      index,
      openStockLimitedTooltip,
    ],
  )

  const onCartClickDebounced = useCallback(
    debounce(({ newAmount }) => {
      onAddAmount(
        newAmount,
        refetchOnCartClick && {
          onAddSuccessCallback: () =>
            dispatch(cartActions.delta({}, { suppressGetDeliveryDates })),
        },
      )
    }, 300),
    [refetchOnCartClick, suppressGetDeliveryDates, onAddAmount],
  )

  const onTrashClick = ({ successCallback } = {}) => {
    onCartClickDebounced.cancel()

    const { unitOfMeasure } = unitData
    const resetAmount = getInCartAmount(unitData)

    if (isDeleteFetching) return

    setIsDeleteFetching(true)

    dispatch(
      cartActions.deleteDelta({
        product,
        productId,
        unitOfMeasure,
        additionalEventParams,
        itemListName,
        itemListId,
        itemModelId,
        index,
        successCallback: () => {
          if (successCallback) successCallback()

          addRemoveCallback({
            removedFromCart: true,
            unitOfMeasure,
          })

          setIsDeleteFetching(false)
        },
        errorCallback: () => {
          setIsDeleteFetching(false)
          setAmount(roundEnteredAmount(unitData, resetAmount))
        },
      }),
    )
  }

  const onInputChange = ({ target: { value } }) => {
    let nextAmount = handleNumberChange(value)
    if (+nextAmount > MAX_INPUT_VALUE) {
      nextAmount = amount
    }
    setAmount(nextAmount)
  }

  const onInputBlur = () => {
    const { inCartQuantity } = unitData
    const inCartAmount = calculateAmountByQuantity(unitData, inCartQuantity)

    if (amount === '0') {
      onTrashClick({
        successCallback: () => setAmount(unitData.multiplier),
      })
      return
    }

    if (amount === '') {
      setAmount(inCartAmount)
      return
    }

    const newRoundedAmount = roundEnteredAmount(unitData, amount)
    const newAmount =
      newRoundedAmount === 0 ? unitData.multiplier : newRoundedAmount

    if (newAmount === inCartAmount) {
      setAmount(newAmount)
      return
    }

    setAmount(newAmount)
    if (inCartQuantity) {
      onCartClickDebounced({ newAmount })
    }
  }

  const onMinusClick = newAmount => {
    setAmount(newAmount)
    if (newAmount) {
      onCartClickDebounced({ newAmount })
    }
  }

  const onPlusClick = newAmount => {
    setAmount(newAmount)
    if (newAmount > unitData.multiplier && unitData.inCartQuantity) {
      onCartClickDebounced({ newAmount })
    }
  }

  const isSoonAvailable = isAvailableAfter(product, userData)
  const { isStockLimited, inCartQuantity } = unitData

  if (isSoonAvailable || outOfStock) {
    return (
      <CatalogOutOfStock
        {...{ product, productId, unitData, isSoonAvailable }}
        onGetReplacements={params =>
          dispatch(
            getReplacementsActions.delta({ ...params, showInModal: true }),
          )
        }
      />
    )
  }

  const hasElasticStockIncreaseConfirmation =
    prop('stockType', affectedElasticStock) === ELASTIC_AFFECT_TYPE.EXCEEDED
  const hasElasticStockDecreaseConfirmation =
    prop('stockType', affectedElasticStock) === ELASTIC_AFFECT_TYPE.DECREASED

  const stockDecreased = (
    <StockDecreased
      {...{ product }}
      Wrapper={CatalogContainer}
      closestDeliveryDate={dayjs(affectedElasticStock?.deliveryDate)}
      onDiscard={() => setAffectedElasticStock(null)}
      onConfirm={() =>
        dateChangeCallback(dayjs(affectedElasticStock?.deliveryDate).format())
      }
    />
  )

  const stockExceeded = (
    <StockExceeded
      {...{ productId }}
      Wrapper={CatalogContainer}
      unitOfMeasureObj={affectedElasticStock?.unitOfMeasureObj}
      closestDeliveryDate={product.closestDeliveryTime}
      selectedDeliveryDate={affectedElasticStock?.deliveryDate}
      onClose={() => setAffectedElasticStock(null)}
      onConfirmDateShift={() => {
        setAmount(
          calculateAmountByQuantity(
            affectedElasticStock?.unitOfMeasureObj,
            affectedElasticStock?.requestedQuantity,
          ),
        )
        setAffectedElasticStock(null)
        onAddAmount(
          calculateAmountByQuantity(
            affectedElasticStock?.unitOfMeasureObj,
            affectedElasticStock?.requestedQuantity,
          ),
          {
            suppressElasticStockCheck: true,
            onAddSuccessCallback: () => dispatch(cartActions.delta()),
          },
        )
      }}
    />
  )

  const elasticStockTooltipContent = hasElasticStockIncreaseConfirmation
    ? stockExceeded
    : stockDecreased

  const stockLimited = (
    <StockLimitedTooltipContainer>
      <StockLimitedTooltipText>
        {formatMessage(messages.stockLimitedText, {
          amount: formatUnitAmount(unitData, stockLimitedAmount),
        })}
      </StockLimitedTooltipText>
      <DiscardButtonV2 onClick={closeStockLimitedTooltip} isFullyRounded>
        OK
      </DiscardButtonV2>
    </StockLimitedTooltipContainer>
  )

  const popoverContent = isStockLimited
    ? stockLimited
    : elasticStockTooltipContent

  const isTooltipOpen =
    hasElasticStockIncreaseConfirmation ||
    hasElasticStockDecreaseConfirmation ||
    isStockLimitedTooltipOpen

  return (
    <Popover
      fixedPlacement
      onOpen={() => {
        dispatch(setIsTooltipOpen(true, productId))
      }}
      onClose={() => {
        dispatch(setIsTooltipOpen(false))
      }}
      open={isTooltipOpen}
    >
      <PopoverContent>{popoverContent}</PopoverContent>
      <PopoverTrigger>
        <CatalogProductButtons
          mr={0}
          my={[10, 0]}
          onPlusClick={onPlusClick}
          onMinusClick={onMinusClick}
          onCartClick={() => onCartClickDebounced({ newAmount: amount })}
          onTrashClick={onTrashClick}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
          isInCart={!!inCartQuantity}
          {...{ hideCart, amount, product, isVariants, unitData, productId }}
          {...rest}
        />
      </PopoverTrigger>
    </Popover>
  )
}

export default CatalogButtonsContainer
