import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const DeliveryText = styled(BaseTextV2)`
  color: ${theme.colors.greenDark};
`
