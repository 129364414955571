import { rtkApi } from 'services/api/rtkApi'

import { storeAuthInfo } from 'containers/Auth/tokenStorage'
import { loginActions } from 'containers/Auth'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { trackLocationChange } from './utils'

const locationsApi = rtkApi.injectEndpoints({
  endpoints: build => ({
    getLocations: build.query({
      query: () => ({
        url: 'me/locations',
      }),
    }),
    changeLocation: build.mutation({
      query: ({ customerNo }) => ({
        url: '/auth/change_location',
        method: 'POST',
        data: { customerNo },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          if (!data) return

          dispatch(rtkApi.util.resetApiState())

          const userData = getState().userInfo.toJS().data
          trackLocationChange(userData)

          storeAuthInfo(data)
          dispatch(loginActions.success(data))
        } catch (err) {
          console.log('Error changing the location', err)
          dispatch(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
        }
      },
    }),
  }),
})

export const {
  useLazyGetLocationsQuery,
  useChangeLocationMutation,
} = locationsApi

export default locationsApi
