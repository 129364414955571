import React from 'react'
import { ProgressBarContainer, ProgressBarSplit, Progress } from './styles'

const SplittableProgressBar = ({
  totalProgress,
  threshold,
  progress,
  maxReached,
  height = 6,
  toCollect,
}) => {
  let splitCount = Math.floor(totalProgress / threshold)
  if (!maxReached) {
    splitCount += 1
  }
  const currentSplitProgress = maxReached ? 100 : (progress / threshold) * 100
  return (
    <ProgressBarContainer height={height}>
      {Array(maxReached ? toCollect : splitCount)
        .fill(null)
        .map((split, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ProgressBarSplit key={index} data-test-id="reward-progress-split">
            <Progress
              height={height}
              progress={index + 1 === splitCount ? currentSplitProgress : 100}
            />
          </ProgressBarSplit>
        ))}
    </ProgressBarContainer>
  )
}

export default SplittableProgressBar
