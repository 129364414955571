import React from 'react'
import Button, { buttonProps } from './Button'
import { BUTTON_TYPES } from './consts'

const AlternateBtn = props => (
  <Button {...props} type={BUTTON_TYPES.ALTERNATE} />
)

AlternateBtn.propTypes = buttonProps

export default AlternateBtn
