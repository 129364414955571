const PROMO_TILE = {
  CLICKED: 'promoTileClicked',
}

export const trackPromoTileClicked = title => {
  window.dataLayer.push({
    event: PROMO_TILE.CLICKED,
    title,
  })
}
