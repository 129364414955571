import React from 'react'
import styled from 'styled-components'
import { Logo } from 'components/Icon'
import { Flex } from '@rebass/grid'
import { BaseTextV2, TertiaryText } from 'components/Text'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { BasicButtonStyle, BasicButtonV2Grey } from 'components/Button/styles'
import Search from './Search'
import { SearchBtn, XContainer, SpinnerContainer } from './Search/styles'
import { SuggestContainer } from './Search/SearchSuggest/styles'

export const MOBILE_HEIGHT = 56
export const DESKTOP_HEIGHT = 73

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${theme.colors.white};
  z-index: ${theme.zIndex.header};
  display: flex;
  flex-flow: row wrap;
`

export const HeaderWrapper = styled.header`
  width: 100%;
  background-color: ${theme.colors.white};
  height: ${p => (p.isMobile ? MOBILE_HEIGHT : DESKTOP_HEIGHT)}px;
  border-bottom: 1px solid ${theme.colors.borderFaded};
`

export const Header = styled.div`
  height: ${MOBILE_HEIGHT}px;
  max-width: ${theme.size.inner};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // to match dashboard horizontal padding on tablet views
  @media (min-width: 600px) {
    padding: ${theme.spacing.xs} ${theme.spacing.md};
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    height: ${DESKTOP_HEIGHT}px;
  }
`

export const NavButton = styled.li`
  ${BasicButtonStyle};
  user-select: none;
  cursor: pointer;
  display: list-item;
  list-style-position: inside;
  list-style-type: disc;
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.textSizes.l};
  width: 100%;
  & svg {
    float: right;
  }
  padding: ${theme.spacing.sm};
  background-color: ${p =>
    p.isActive ? theme.colors.lightGrey : theme.colors.transparent};
  color: ${theme.colors.primary};
  &:hover {
    background-color: ${theme.colors.transparent};
  }
  &::marker {
    font-size: 21px;
    color: ${p =>
      p.isActive ? theme.colors.secondary : theme.colors.transparent};
  }
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xs};
    ${BasicButtonStyle};
    background-color: ${theme.colors.transparent};
    & svg {
      transform: ${p =>
        p.isDropdownActive ? 'rotate(-180deg)' : 'rotate(0deg)'};
      transition: transform 300ms ease-out;
      > path {
        stroke: ${p =>
          p.isActive ? theme.colors.secondary : theme.colors.primary};
      }
    }
    &:hover {
      color: ${theme.colors.secondary};
      background-color: ${theme.colors.lightGrey};
      & svg > path {
        stroke: ${theme.colors.secondary};
      }
    }
    color: ${p => (p.isActive ? theme.colors.secondary : theme.colors.primary)};
  }
`

export const NavigationWrapper = styled.ul`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 0;
  margin: 0;
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    flex-direction: row;
    align-items: center;
    width: auto;
    gap: ${theme.spacing.xxs};
  }
`

export const StyledSimpleLogo = styled(({ isLoggedIn, ...rest }) => (
  <Logo {...rest} />
))`
  cursor: pointer;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-right: 40px;
  }

  @media (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: 104px;
  }
`

export const MobileNavWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
`

export const MobileBurgerWrapper = styled(Flex)`
  ${props =>
    props.$isNavOpen &&
    `
    border-radius: 8px;
    background: ${theme.colors.lightGrey};
  `};
`

export const DropdownWrapper = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: ${props => props.mobileMenuOffset}px;
  left: 0;
  width: 100vw;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 12px rgba(19, 27, 77, 0.1);

  // classes for categories list slide animation on mobiles
  .right-to-left-enter {
    transform: translateX(100%);
  }
  .right-to-left-enter-active {
    transform: translateX(0);
    transition: all 0.4s ease-out;
  }

  .right-to-left-exit {
    transform: translateX(0);
  }
  .right-to-left-exit-active {
    transform: translateX(-100%);
    transition: all 0.4s ease-out;
  }

  .left-to-right-enter {
    transform: translateX(-100%);
  }
  .left-to-right-enter-active {
    transform: translateX(0);
    transition: all 0.4s ease-out;
  }

  .left-to-right-exit {
    transform: translateX(0);
  }
  .left-to-right-exit-active {
    transform: translateX(100%);
    transition: all 0.4s ease-out;
  }
`
export const Section = styled(Flex)`
  flex-direction: column;
  align-items: start;
  padding: 16px 16px 0 16px;
  :not(:first-child) {
    border-top: 1px solid ${theme.colors.gray2};
  }
`

export const BlockText = styled(TertiaryText)`
  display: block;
`

export const Text = styled(BlockText)`
  margin-top: ${theme.spacing.sm};
`

export const SectionsWrapper = styled(Flex)`
  height: calc(100vh - ${props => props.mobileMenuOffset}px);
  overflow: auto;
  flex-direction: column;
`

export const MobileSearch = styled(Search)`
  input {
    padding-left: 17px;
    margin-right: 16px;
  }

  ${SearchBtn} {
    display: none;
  }

  ${SuggestContainer} {
    left: -16px;
    width: 100vw;
  }

  ${XContainer} {
    width: 24px;
    height: 24px;
    right: 32px;
    path {
      stroke: ${theme.colors.primary};
    }
  }

  ${SpinnerContainer} {
    right: 32px;
  }
`

export const UserMenuContainer = styled.div`
  padding: 0 ${theme.spacing.sm};
`

export const LoggedOutWrapper = styled(HeaderWrapper)`
  height: ${MOBILE_HEIGHT}px;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    height: ${DESKTOP_HEIGHT}px;
  }
`

export const LoggedOutHeader = styled(Header)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  position: relative;
  height: 100%;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  ${StyledSimpleLogo} {
    margin: 0;
  }
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: ${theme.spacing.sm} 40px;
  }
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.sm} 80px;
  }
`

export const BtnText = styled(BaseTextV2)`
  font-size: ${theme.textSizes.xs};
`

export const BackBtn = styled(BasicButtonV2Grey)`
  position: absolute;
  display: flex;
  gap: ${theme.spacing.sm};
  left: ${theme.spacing.sm};
  ${BtnText} {
    display: none;
  }
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    left: 40px;
    ${BtnText} {
      display: block;
    }
  }
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    left: 80px;
  }
`
