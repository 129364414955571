import React from 'react'

import FilteringNoResults from 'components/NoResults/FilteringNoResults'
import SearchNoResults from 'components/NoResults/SearchNoResults'

import { Container, Separator } from './styles'

const NoResults = ({ isFilteringApplied, handleFiltersClear }) => {
  if (isFilteringApplied) {
    return (
      <Container>
        <FilteringNoResults onClearFilters={handleFiltersClear} />
      </Container>
    )
  }

  return (
    <>
      <Separator />
      <Container>
        <SearchNoResults />
      </Container>
    </>
  )
}

export default NoResults
