import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { MOBILE_PAGINATION_BREAKPOINT } from 'consts'
import {
  breakPointLayoutS,
  Content,
} from 'views/Products/styledComponents/Layout'

export const MobileProductPaginationWrapper = styled(Flex)`
  margin-top: 16px;
  margin-bottom: 56px;
  padding: 4px 8px;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: ${MOBILE_PAGINATION_BREAKPOINT}px) {
    display: none;
  }
  @media screen and (min-width: ${breakPointLayoutS}) {
    padding: 4px 0;
  }
`

export const ProductsContent = styled(Content)`
  margin-top: ${theme.spacing.base};
`

export * from './Breadcrumb'
export * from './Layout'
