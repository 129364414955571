import styled from 'styled-components'
import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { BasicButtonV2 } from 'components/Button'
import { breakPointTablet } from 'components/Layout'
import { ProductBoxContainer } from 'components/Product/ProductBox/styles'

export const DefaultCarouselWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`

export const ControlsContainer = styled.div`
  display: none;
  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    display: flex;
    justify-content: center;
    gap: ${theme.spacing.xs};
  }
`

export const ControlsButton = styled(BasicButtonV2)`
  padding: ${theme.spacing.xs};
  background-color: ${theme.colors.transparent};
  width: 40px;
  height: 40px;
  :hover {
    background-color: ${theme.colors.lightGrey7};
  }

  :disabled {
    background-color: ${theme.colors.transparent};

    :hover {
      background-color: ${theme.colors.transparent};
    }
  }
`

export const CarouselOverflowContainer = styled.div`
  overflow: hidden;
  position: relative;
`

export const CarouselScrollContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  @media screen and (max-width: ${breakPointTablet}) {
    gap: ${theme.spacing.xs};
  }
  > * {
    flex: 1 0 60%;
    min-width: 0;
    max-width: 221px;
    > ${ProductBoxContainer} {
      max-width: unset;
    }
  }

  @media screen and (min-width: 360px) {
    > * {
      flex-basis: 57%;
    }
  }

  @media screen and (min-width: 420px) {
    > * {
      flex-basis: 45%;
    }
  }

  @media screen and (min-width: 500px) {
    > * {
      flex-basis: 37%;
    }
  }

  @media screen and (min-width: 660px) {
    > * {
      flex-basis: 30%;
    }
  }

  @media screen and (min-width: 800px) {
    > * {
      flex-basis: 27%;
    }
  }

  @media screen and (min-width: 840px) {
    > * {
      flex-basis: 23%;
    }
  }

  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    > * {
      flex-basis: calc((100% - 64px) / 5);
    }
  }

  @media screen and (min-width: 1200px) {
    > * {
      flex-basis: calc((100% - 80px) / 6);
    }
  }

  @media screen and (min-width: 1392px) {
    > * {
      flex-basis: calc((100% - 96px) / 7);
    }
  }
`

export const CarouselTrackerContainer = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.xxs};
`

export const CarouselTrackerDot = styled.div`
  height: 2px;
  width: 12px;
  border-radius: 1px;
  background-color: ${p =>
    p.$active ? theme.colors.primary : theme.colors.gray6};
  transition: all 0.2s ease-out;
`
