import React from 'react'

import { isSalePromoType } from 'containers/Promotions/utils'
import SaleProgressBar from 'components/Product/SaleProgressBar'
import { SALE_PROGRESS_VARIANTS } from 'components/Product/SaleProgressBar/consts'
import PromoGroup from 'components/Product/PromoGroup'

import {
  PromoBoxContainer,
  PromoBoxTextContainer,
  PromoBoxTitle,
  PromoBoxDesc,
} from './styles'

const PromoBox = ({ product: { promotion, baseUnitOfMeasureDescription } }) => {
  if (!promotion) return null

  const { title, description, groupId, showModal } = promotion
  const isSale = isSalePromoType(promotion)

  return (
    <PromoGroup groupId={groupId} showModal={showModal}>
      <PromoBoxContainer data-test-id="promo-box" $hasHover={showModal}>
        <PromoBoxTextContainer>
          <PromoBoxTitle $isLightGreen={!description}>{title}</PromoBoxTitle>
          {description && <PromoBoxDesc>{description}</PromoBoxDesc>}
        </PromoBoxTextContainer>
        {isSale && (
          <SaleProgressBar
            promotion={promotion}
            baseUnitDesc={baseUnitOfMeasureDescription}
            variant={SALE_PROGRESS_VARIANTS.BIG}
            isLongText
          />
        )}
      </PromoBoxContainer>
    </PromoGroup>
  )
}

export default PromoBox
