import React, { useEffect, useRef } from 'react'

import { KEY_CODES } from 'consts'
import useOnClickOutside from 'hooks/useOnClickOutside'

import ModalBody from './ModalBody'

const ModalBodyContainer = ({ closeModal, size, ...rest }) => {
  const ref = useRef(null)
  useOnClickOutside(ref, closeModal)

  const keyPressHandler = ({ code }) => {
    if (code === KEY_CODES.ESC) {
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyPressHandler)

    return () => document.removeEventListener('keydown', keyPressHandler)
  }, [])

  return <ModalBody ref={ref} $size={size} {...rest} />
}

export default ModalBodyContainer
