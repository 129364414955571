import { getNestedKeysMap, getApiPathsMap } from './utils'

export const CONFIG_SCHEMA = {
  cartGuide: {
    intro: false,
    timeline: false,
    changeDateAll: false,
    changeDateSingle: false,
    freeDelivery: false,
    incomingProducts: false,
    comments: false,
    otherDelivery: false,
  },
}

/*
  {
    "CART_GUIDE": {
      "CART_GUIDE": "cartGuide",
      "INTRO": "cartGuide.intro",
      ...
    }
  }
*/
export const CONFIG_SCHEMA_KEYS = getNestedKeysMap(CONFIG_SCHEMA)

/*
  {
    "CART_GUIDE": {
      "CART_GUIDE": "cart_guide",
      "INTRO": "cart_guide/intro",
      ...
    }
  }
*/
export const CONFIG_API_PATHS_MAP = getApiPathsMap(CONFIG_SCHEMA)
