import styled from 'styled-components'

import theme from 'theme'

import {
  CatalogAddProductsButton,
  CatalogProductButtonsContainer,
} from 'components/Product/ProductButtons/styles'

export const AddToTemplateBtn = styled(CatalogAddProductsButton)`
  > svg:first-child {
    display: none;
  }
  > :last-child {
    margin-left: ${theme.spacing.xs};
    margin-right: 0;
  }
  font-size: ${theme.textSizes.xs};
  font-weight: 500;
  font-family: ${theme.fonts.primary};
  line-height: 16px;
  ${props => props.isVariants && `margin-top: 4px`};
`

export const InTemplateContainer = styled(CatalogProductButtonsContainer)`
  padding: 0;
  ${props => props.isVariants && `margin-top: 4px`};
`
