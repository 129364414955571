import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

export const NotificationCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  top: 3px;
  margin-left: 13px;
  padding: 1px 2px;
  min-width: 20px;
  border-radius: 5px;
  border: 2px ${theme.colors.white} solid;
  background-color: ${theme.colors.secondary};
  font-size: 9px;
  font-weight: 600;
  line-height: 10px;
  color: ${theme.colors.white};

  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: 11px;
    line-height: 11px;
    padding: 1px 4px;
  }
`

export const NotificationsBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 40px;
  cursor: pointer;
  position: relative;
  border-radius: ${theme.borderRadius.base};

  &[data-state='open'],
  :hover {
    background-color: ${theme.colors.lightGrey};
    ${NotificationCounter} {
      border-color: ${theme.colors.lightGrey};
    }
  }

  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    width: 40px;
  }
`
