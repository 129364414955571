import { takeLatest, call, put, select } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'

import { getPromoGroupProducts } from './api'
import { getPromoGroupProductsActions } from './actions'
import { selectPromoGroupProductsData } from './selectors'

export function* getPromoGroupProductsFlow({
  data: { groupId, perPage, page },
}) {
  try {
    const productsData = yield select(selectPromoGroupProductsData)

    yield call(genericGetDataEnhanced, {
      actions: getPromoGroupProductsActions,
      request: getPromoGroupProducts,
      params: { groupId, perPage, page },
      dataSelector: ({ products, meta }) => ({
        pagination: meta.pagination,
        products: [...productsData.products, ...products],
      }),
    })
  } catch (error) {
    console.log('Error getting/processing promo group products', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(getPromoGroupProductsActions.DELTA, getPromoGroupProductsFlow),
]
