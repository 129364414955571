import styled from 'styled-components'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

export const Container = styled.li`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`

export const RemoveBtn = styled.button`
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  margin: 0;
  border: none;
  outline: none;
  position: relative;
  top: -1px;
`

export const FileName = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true, fontSize: true },
})`
  color: ${theme.colors.lightGrey4};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const FileSize = styled(FileName)`
  color: ${theme.colors.gray5};
  overflow: initial;
`
