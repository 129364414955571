import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import dayjs from 'dayjs'

import theme from 'theme'
import { getInvoiceExpiryDays } from 'views/Invoices/utils'
import { ClockV2 } from 'components/Icons'
import {
  INVOICE_STATUS,
  INVOICE_PAYMENT_STATUS,
} from 'containers/Invoices/consts'
import { DATE_TIME_FORMATS } from 'utils/datetime'
import { formatPrice } from 'utils'

import messages from './messages'

export const usePayLabel = invoice => {
  const { formatMessage } = useIntl()
  const isNotPaid = invoice.statusId === INVOICE_STATUS.NOT_PAID
  const isExpired = invoice.statusId === INVOICE_STATUS.NOT_PAID_EXPIRED
  const { payment } = invoice

  const processingMessage =
    payment.status === INVOICE_PAYMENT_STATUS.PROCESSING ? (
      <>
        <ClockV2 width={16} height={16} color={theme.colors.infoBlue2} />
        {formatMessage(messages.processing)}
      </>
    ) : null

  const [payLabel, payRemainingLabel] = useMemo(
    () => {
      const expiredDays = getInvoiceExpiryDays(payment.deadlineDate)

      if (
        invoice.remainingAmount > 0 &&
        invoice.amount !== invoice.remainingAmount
      ) {
        if (isExpired) {
          return [
            <FormattedMessage
              {...messages.remainingDiff}
              values={{
                amount: formatPrice(invoice.amount - invoice.remainingAmount),
              }}
            />,
            <>
              {`${invoice.remainingAmountLabel} `}
              {formatMessage(
                expiredDays === 1
                  ? messages.paySelectExpiredDay
                  : messages.paySelectExpiredDays,
                { days: expiredDays },
              )}
            </>,
          ]
        }

        if (isNotPaid) {
          return [
            <FormattedMessage
              {...messages.remainingDiff}
              values={{
                amount: formatPrice(invoice.amount - invoice.remainingAmount),
              }}
            />,
            <FormattedMessage
              {...messages.payTill}
              values={{
                paymentDeadlineDateLabel: `${invoice.remainingAmountLabel} ${
                  payment.deadlineDateLabel
                }`,
                date: dayjs(payment.deadlineDate).format(
                  DATE_TIME_FORMATS.FULL_DATE,
                ),
              }}
            />,
          ]
        }

        return [
          <FormattedMessage
            {...messages.remainingDiff}
            values={{
              amount: formatPrice(invoice.amount - invoice.remainingAmount),
            }}
          />,
          invoice.remainingAmountLabel,
        ]
      }

      if (isExpired) {
        return [
          <>
            {`${invoice.amountLabel} `}
            {formatMessage(
              expiredDays === 1
                ? messages.paySelectExpiredDay
                : messages.paySelectExpiredDays,
              { days: expiredDays },
            )}
          </>,
          invoice.remainingAmountLabel,
        ]
      }

      if (isNotPaid) {
        return [
          <FormattedMessage
            {...messages.payTill}
            values={{
              paymentDeadlineDateLabel: `${invoice.amountLabel} ${
                payment.deadlineDateLabel
              }`,
              date: dayjs(payment.deadlineDate).format(
                DATE_TIME_FORMATS.FULL_DATE,
              ),
            }}
          />,
          invoice.remainingAmountLabel,
        ]
      }

      return [invoice.amountLabel, invoice.remainingAmountLabel]
    },
    [isExpired],
  )
  return [processingMessage || payLabel, payRemainingLabel]
}
