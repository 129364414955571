import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { PPrimary, PSuccessDark, TinyTextFaded } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

import ProductName from '../ProductName'

// prettier-ignore
export const ProductWrapper = styled(Flex)`
  width: 100%;
  min-height: 72px;
  padding: ${theme.spacing.ss} ${theme.spacing.sm};
  flex-direction: column;

  ${p => p.borderTop && `
    border-top: 1px solid ${theme.colors.bgGray};
  `};

  ${p => p.borderBottom && `
    border-bottom: 1px solid ${theme.colors.bgGray};
  `};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    min-height: 156px;
  }
`

// prettier-ignore
export const DetailsWrapper = styled(Flex)`
  flex: 1;
  margin-top: ${theme.spacing.sm};
  padding-top: ${theme.spacing.sm};
  border-top: 1px solid ${theme.colors.gray2};

  ${p => p.inColumn && `
    flex-direction: column;
    padding-left: ${theme.spacing.xs};
  `};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    margin-top: ${theme.spacing.xs};
    padding-top: ${theme.spacing.xs};
  }
`

export const VariantRowWrapper = styled(DetailsWrapper)`
  align-items: center;
  justify-content: space-between;
`

export const RightSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
`

export const ContentWrapper = styled(Flex)`
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    ${p => !p.isInactive && `min-height: 133px;`};
  }
`

export const VerticalDivider = styled(Flex)`
  width: 1px;
  background-color: ${theme.colors.gray2};
  height: ${p => p.height || '100%'};
`

export const Prices = styled.div`
  width: 124px;
  margin-left: ${theme.spacing.xs};
  text-align: right;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: auto;
  }
`

export const ProductNameStyled = styled(ProductName)`
  font-size: ${theme.textSizes.m};
  font-weight: ${theme.fontWeights.regular};
`

export const BrandTxt = styled(PSuccessDark)`
  font-size: 11px;
  font-weight: ${theme.fontWeights.strongBold};
`

export const InfoIconContainer = styled(Flex)`
  width: 24px;
  height: 24px;
  margin-left: ${theme.spacing.sm};
  cursor: pointer;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    margin-left: ${theme.spacing.xs};
  }
`

export const NetPrice = styled(PPrimary)`
  font-weight: ${p => theme.fontWeights[p.isVariant ? 'bold' : 'strongBold']};
  ${p => p.isVariant && `font-size: 13px`};
  ${p => p.$isLoweredPrice && `color: ${theme.colors.secondary}`};
`

export const InnerRightWrapper = styled(Flex)`
  height: 100%;
  align-items: center;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: column;
    border-top: 1px solid ${theme.colors.gray2};
    margin-top: ${theme.spacing.xs};
    padding-top: ${theme.spacing.ss};
    width: 100%;
    height: auto;
  }
`

export const PricesAndInfoWrapper = styled(Flex)`
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid ${theme.colors.gray2};
    margin-top: ${theme.spacing.ss};
    padding-top: ${theme.spacing.xxs};

    ${Prices} {
      margin-bottom: -${theme.spacing.xxs};
    }

    ${NetPrice} {
      line-height: 20px;
    }

    ${TinyTextFaded} {
      font-size: 11px;
    }
  }
`

export const MobileLeftBottomWrapper = styled(Flex)`
  width: 89px;
  align-items: flex-start;
  justify-content: flex-end;
  border-right: 1px solid ${theme.colors.gray2};
  margin-right: ${theme.spacing.xs};
`

export const StockConfirmContainer = styled.div`
  position: absolute;
  z-index: ${theme.zIndex.base};
  right: -24px;
  left: -24px;
  bottom: -36px;
`
