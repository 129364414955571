import { makeMessages, makeTranslation } from 'utils'

const messages = {
  currentOrderToCancel: 'currentOrderToCancel',
  affectedOrdersToCancel: 'affectedOrdersToCancel',
  noLogisticLimit: 'noLogisticLimit',
  viewOrder: 'viewOrder',
  affectedOrdersInfo: 'affectedOrdersInfo',
  orderNumber: { id: 'orderDetails.orderNumber' },
}

export default makeMessages(
  messages,
  makeTranslation('cancelOrderModal.affectedOrders'),
)
