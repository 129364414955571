import { makeMessages, makeTranslation } from 'utils'

const messages = {
  setupEmailInfo: 'setupEmailInfo',
  changeEmailInfo: 'changeEmailInfo',
  setupEmailBtn: 'setupEmailBtn',
  changeEmailBtn: 'changeEmailBtn',
  modalTitle: 'modalTitle',
  modalLegalText: 'modalLegalText',
  modalTermsLink: 'modalTermsLink',
  successMessage: 'successMessage',
  failMessage: 'failMessage',
  emailLabel: { id: 'common.labels.email' },
}

export default makeMessages(messages, makeTranslation('product.notification'))
