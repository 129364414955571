import { makeMessages, makeTranslation } from 'utils'

const messages = {
  password: 'password',
  newPassword: 'new_password',
  newPasswordHint: 'new_password_hint',
  ctaButton: 'cta_button',
  invalidPasswordError: {
    id: 'Error.10212131113',
  },
}

export default makeMessages(
  messages,
  makeTranslation('profile_change_password'),
)
