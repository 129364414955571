import React from 'react'

import theme from 'theme'

const Cookie = ({ color = theme.colors.primary }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="9.5" r="1.5" fill={color} />
    <circle cx="8.5" cy="16.5" r="1.5" fill={color} />
    <circle cx="14" cy="15" r="1" fill={color} />
    <circle cx="16" cy="5" r="1" fill={color} />
    <circle cx="18" cy="9" r="1" fill={color} />
    <circle cx="19" cy="3" r="1" fill={color} />
    <path
      d="M19.4713 12.6153L20.3136 12.0763C20.0276 11.6294 19.4423 11.4836 18.9801 11.7442L19.4713 12.6153ZM21.8012 13.994L22.7814 14.1923C22.8386 13.9095 22.7708 13.6157 22.5955 13.3865C22.4202 13.1573 22.1545 13.015 21.8665 12.9961L21.8012 13.994ZM11.1586 2.03515L12.1055 2.35668C12.2139 2.03745 12.1542 1.6851 11.9466 1.41945C11.739 1.1538 11.4116 1.01062 11.0756 1.0386L11.1586 2.03515ZM12.385 5.5289L13.2561 6.02007C13.5167 5.5579 13.3709 4.97266 12.9241 4.68664L12.385 5.5289ZM15 10.0003H16C16 9.44797 15.5523 9.00025 15 9.00025V10.0003ZM18.9801 11.7442C18.6914 11.907 18.3581 12.0003 18 12.0003V14.0003C18.7111 14.0003 19.3817 13.8138 19.9624 13.4864L18.9801 11.7442ZM21.8665 12.9961C21.2161 12.9536 20.6482 12.5992 20.3136 12.0763L18.629 13.1543C19.2934 14.1927 20.4277 14.9063 21.736 14.9919L21.8665 12.9961ZM12 23.0003C17.3253 23.0003 21.7648 19.2171 22.7814 14.1923L20.8211 13.7957C19.9895 17.9061 16.355 21.0003 12 21.0003V23.0003ZM1 12.0003C1 18.0754 5.92487 23.0003 12 23.0003V21.0003C7.02944 21.0003 3 16.9708 3 12.0003H1ZM11.0756 1.0386C5.43254 1.5087 1 6.23632 1 12.0003H3C3 7.28523 6.62648 3.41617 11.2416 3.0317L11.0756 1.0386ZM12 3.00027C12 2.77342 12.0374 2.55736 12.1055 2.35668L10.2117 1.71362C10.0742 2.11869 10 2.55185 10 3.00027H12ZM12.9241 4.68664C12.3662 4.32961 12 3.70738 12 3.00027H10C10 4.41767 10.738 5.66206 11.8459 6.37117L12.9241 4.68664ZM13 7.00025C13 6.64213 13.0932 6.30881 13.2561 6.02007L11.5139 5.03774C11.1865 5.61845 11 6.28908 11 7.00025H13ZM15 9.00025C13.8954 9.00025 13 8.10482 13 7.00025H11C11 9.20939 12.7909 11.0003 15 11.0003V9.00025ZM18 12.0003C16.8954 12.0003 16 11.1048 16 10.0003H14C14 12.2094 15.7909 14.0003 18 14.0003V12.0003Z"
      fill={color}
    />
  </svg>
)

export default Cookie
