import React from 'react'

import { LabelContainer, LabelText, LabelSeparator } from './styles'

const BidmarketLabel = ({ className, label, separatorSize = 8 }) => (
  <>
    {!!separatorSize && <LabelSeparator separatorSize={separatorSize} />}
    <LabelContainer className={className}>
      <LabelText>{label}</LabelText>
    </LabelContainer>
  </>
)

export default BidmarketLabel
