import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { H3 } from 'components/Text'
import { ProductCount } from 'views/Products/Header/styledComponents'
import { PrimaryBtn, BUTTON_SIZE } from 'components/Button'
import { ICONS, ICONS_POSITION } from 'components/Icon'
import { APP_BREAKPOINTS } from 'consts'

export const Count = styled(ProductCount)`
  font-size: ${theme.textSizes.l};
  color: ${theme.colors.gray3};
  margin-left: 0;
`

export const Header = styled(H3)`
  font-weight: ${theme.fontWeights.strongBold};
  color: ${theme.colors.black};
  display: inline;
`

export const HeaderContainer = styled.div`
  margin-bottom: ${theme.spacing.md};
`

// prettier-ignore
export const ItemsWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  border: 1px solid ${theme.colors.border};
  padding: ${theme.spacing.base};

  @media screen and (max-width: 505px) {
    border: 0;
    padding: 0;

    ${HeaderContainer} {
      flex-flow: column;
    }
  }

  ${p => p.isCartFlow && `
    padding: 40px;
    margin-bottom: 40px;
    background: ${theme.colors.white};
    box-shadow: 0px 2px 0px ${theme.colors.gray2};
    border: none;

    @media screen and (max-width: 505px) {
      width: calc(100% + 32px);
      margin-left: -${theme.spacing.sm};
      margin-bottom: ${theme.spacing.base};
      padding: ${theme.spacing.sm};
    }
  `};
`

export const SubmitButton = styled(PrimaryBtn).attrs({
  size: BUTTON_SIZE.MEDIUM,
  iconType: ICONS.CHEVRON_LEFT,
  iconPosition: ICONS_POSITION.LEFT,
})`
  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    width: 100%;
    font-size: 14px;
  }
`
