import { createSelector } from 'reselect'
import _getOr from 'lodash/fp/getOr'

import { makeDataSelector, makeIsFetchingSelector } from 'utils/redux-utils'

import { USER_INFO_REDUCER_NAME } from './consts'

export const selectData = makeDataSelector(USER_INFO_REDUCER_NAME)

export const userDataSelector = createSelector(selectData, data => data || {})

export const multipleLocationsSelector = createSelector(
  userDataSelector,
  _getOr(false, 'multipleLocations'),
)

export const hideInvoicesSelector = createSelector(
  userDataSelector,
  _getOr(false, 'hideInvoices'),
)

export const isAccountDeleteRequested = createSelector(
  userDataSelector,
  _getOr(false, 'accountDeleteRequested'),
)

export const isUserDataFetching = makeIsFetchingSelector(USER_INFO_REDUCER_NAME)
