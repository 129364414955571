import styled from "styled-components"
import { Flex } from "@rebass/grid"

import theme from "theme"

import { BaseTextV2, TinyTextV2 } from "components/Text/texts"

export const Description = styled(Flex)`
  align-items: center;
  flex: 1;
`

export const Title = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  max-width: 27ch;
  color: ${theme.colors.lightGrey4};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Count = styled(TinyTextV2)`
  flex-shrink: 0;
  margin-left: 8px;
  color: ${theme.colors.lightGrey4};
`