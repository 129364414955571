import { all } from 'redux-saga/effects'

import authWatchers from 'containers/Auth/sagas'
import cartWatchers from 'containers/Cart/sagas'
import ordersWatchers from 'containers/Orders/sagas'
import deliveryWatchers from 'containers/Delivery/sagas'
import { promotionsWatchers } from 'containers/Promotions/sagas'
import { invoicesWatchers } from 'containers/Invoices/sagas'
import { productsWatchers } from 'containers/Products/sagas'
import { templatesInfoWatchers } from 'containers/Templates/sagas'
import { profileWatchers } from 'containers/Profile/saga'
import { productDetailsWatchers } from 'containers/ProductDetails/sagas'
import { categoriesWatchers } from 'containers/Categories/sagas'
import searchWatchers from 'containers/components/Search/sagas'

import notifications from './notifications'
import appConfigWatchers from './appConfig'

export default function* watcher() {
  yield all([
    ...notifications,
    ...authWatchers,
    ...promotionsWatchers,
    ...appConfigWatchers,
    ...invoicesWatchers,
    ...cartWatchers,
    ...productsWatchers,
    ...templatesInfoWatchers,
    ...ordersWatchers,
    ...deliveryWatchers,
    ...profileWatchers,
    ...productDetailsWatchers,
    ...categoriesWatchers,
    ...searchWatchers,
  ])
}
