import { createActionCreator } from 'utils/redux-utils'

export const TOOLTIP_OPEN = 'TOOLTIP_OPEN'
export const TOOLTIP_CLOSE = 'TOOLTIP_CLOSE'

export const openTooltip = createActionCreator(TOOLTIP_OPEN)
export const closeTooltip = createActionCreator(TOOLTIP_CLOSE)

export const setIsTooltipOpen = (state, id) =>
  state ? openTooltip({ id }) : closeTooltip()
