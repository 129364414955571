import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2Medium, TinyTextV2Medium } from 'components/Text'

export const PromoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.ss} ${theme.spacing.sm};
  gap: ${theme.spacing.sm};
  background-color: ${theme.colors.greenLight5};
  border-radius: ${theme.borderRadius.base};

  ${p =>
    p.$hasHover &&
    `
    :hover {
      background-color: ${theme.colors.greenLightHover2};
    }
  `};
`

export const PromoBoxTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const PromoBoxTitle = styled(BaseTextV2Medium)`
  color: ${p =>
    p.$isLightGreen ? theme.colors.secondary : theme.colors.greenDark};
`

export const PromoBoxDesc = styled(TinyTextV2Medium)`
  color: ${theme.colors.secondary};
`
