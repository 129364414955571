import React, { useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { useSelector } from 'react-redux'

import {
  cartDeliveriesSelector,
  firstDeliveryLogisticLackAmountSelector,
} from 'containers/Cart/selectors'
import { selectTradeLimit } from 'containers/Orders/selectors'
import { PAY_SCENARIO, PAYMENT_COLORS } from 'containers/Orders/consts'
import useOnClickOutside from 'hooks/useOnClickOutside'

import { ArrowDown } from 'components/Icons'
import { FixedBottom } from 'components/Layout'

import { Address, PayBtn, PayScenario, DeliveryDates } from '../components'
import DefaultHeader from './DefaultHeader'
import {
  RowContainer,
  InnerRow,
  MobileSeparator,
  HeaderRow,
  SeparatorContainer,
} from './styles'

const ScenarioRightSideIcon = ({ color }) => (
  <ArrowDown width={18} style={{ minWidth: 18, marginLeft: 16 }} fill={color} />
)

const WrapComponent = (children, isExpanded) => (
  <SeparatorContainer>
    {isExpanded && <MobileSeparator />}
    {children}
  </SeparatorContainer>
)

const Mobile = ({ stickyPaymentId, isFixed }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const firstDeliveryLogisticLackAmount = useSelector(
    firstDeliveryLogisticLackAmountSelector,
  )
  const deliveries = useSelector(cartDeliveriesSelector)
  const { scenario, paymentColorName } = useSelector(selectTradeLimit)
  const isGreenScenario = scenario === PAY_SCENARIO.GREEN
  const isRedScenario = scenario === PAY_SCENARIO.RED
  const isRedClient = paymentColorName === PAYMENT_COLORS.RED
  const isLogisticLackAmount =
    !isRedClient && !isRedScenario && firstDeliveryLogisticLackAmount > 0

  const defaultHeaderRendered = (
    <DefaultHeader
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      isLogisticLackAmount={isLogisticLackAmount}
      firstDeliveryLogisticLackAmount={firstDeliveryLogisticLackAmount}
      deliveriesCount={deliveries.length}
    />
  )

  const ref = useRef(null)
  useOnClickOutside(ref, () => setIsExpanded(false))

  return (
    <FixedBottom ref={ref} {...{ isFixed }}>
      <RowContainer {...{ isFixed }} id={stickyPaymentId}>
        <InnerRow>
          <AnimateHeight duration={150} height={isExpanded ? 'auto' : 0}>
            <HeaderRow>{defaultHeaderRendered}</HeaderRow>

            <DeliveryDates />

            <MobileSeparator />

            <Address />
          </AnimateHeight>

          <HeaderRow
            isLastItem={isExpanded}
            onClick={() => setIsExpanded(true)}
          >
            {!isExpanded && isGreenScenario ? (
              defaultHeaderRendered
            ) : (
              <PayScenario
                {...{ isExpanded }}
                RightSideIcon={isExpanded ? undefined : ScenarioRightSideIcon}
                WrapComponent={({ children }) =>
                  WrapComponent(children, isExpanded)
                }
              />
            )}
          </HeaderRow>
        </InnerRow>

        <PayBtn {...{ isFixed }} />
      </RowContainer>
    </FixedBottom>
  )
}

export default Mobile
