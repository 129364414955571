import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { BreadCrumbList } from 'components/BreadCrumbs/styledComponents'
import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { TEMPLATES_MOBILE_MAX_WIDTH } from './consts'

export const STICKY_ELEMENTS_OFFSET = 187

export const TemplateWrapper = styled(Flex)`
  width: 100%;
  @media screen and (max-width: ${TEMPLATES_MOBILE_MAX_WIDTH}) {
    flex-direction: column;
  }
`

export const TemplatesPageWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  ${BreadCrumbList} {
    margin: 16px 0 56px 32px;
    @media screen and (max-width: ${TEMPLATES_MOBILE_MAX_WIDTH}) {
      margin-left: 16px;
    }
  }
`

export const GoToTopButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${theme.colors.white};
  box-shadow: 0 8px 32px ${theme.colors.shadow.modal};
  border-radius: 8px;
  cursor: pointer;
  position: sticky;
  z-index: -1;
  opacity: 0;
  bottom: 16px;
  transition: opacity 0.2s ease;
  margin-left: calc(100% - 40px);
  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    margin-bottom: -16px;
    margin-left: calc(
      100% - 20px ${({ isWideScreen }) => isWideScreen && ' + 32px'}
    );
  }
  margin-top: auto;
`
