import { makeMessages, makeTranslation } from 'utils'

const messages = {
  title: 'title',
  description: 'description',
  confirmCancel: 'confirmCancel',
  abortCancel: 'abortCancel',
  multipleTitle: 'multiple.title',
  multipleDescription: 'multiple.description',
  continueCancel: 'multiple.continueCancel',
  success: 'success',
  error: 'error',
}

export default makeMessages(messages, makeTranslation('cancelOrderModal'))
