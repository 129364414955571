import queryString from 'query-string'

import { DEFAULT_PRODUCT_PAGE_SIZE } from 'consts'

const scope = 'me/campaigns'

export const getCampaigns = () => ({ url: scope })

export const getCampaignDetails = id => ({ url: `${scope}/${id}` })

export const getCampaignProducts = ({ id, page, pageLimit }) => {
  const queryParams = queryString.stringify({
    page,
    per_page: pageLimit || DEFAULT_PRODUCT_PAGE_SIZE,
  })

  return {
    url: `${scope}/${id}/products?${queryParams}`,
  }
}
