import { FILTERS_TYPES, FILTERS_VALUES } from 'components/Filters/consts'
import {
  getFormattedProductParams,
  getProductDiscount,
  normalizePrice,
  trackEcommerceEvent,
  formatItemListParams,
} from 'services/analytics/utils'
import { CURRENCY } from 'consts'

const TEMPLATE_EVENTS = {
  TIS: {
    NOT_ALL_ADDED: 'tisNotAllAdded',
    NON_STOCK_DISTANT_DD: 'tisNonStockApplied',
    SHOW_REPLACEMENT: 'tisShowReplacementApplied',
  },
  POPUP: {
    NON_STOCK_DISTANT_DD: 'popupNonStockApplied',
    SHOW_REPLACEMENT: 'popupShowReplacementApplied',
  },
  PRODUCT: {
    ADDED_ALL_PRODUCTS: 'Added all products',
    ADDED_TO_TEMPLATE: 'add_to_wishlist',
    REMOVED_FROM_TEMPLATE: 'Removed product from the template',
  },
  TEMPLATE_VIEW: {
    OPENED: 'Opened template',
    EDITED_VARIANT: 'Changed product variant',
    EDITED_QTY: 'Edit quantity in template',
    CHANGED_NAME: 'Changed template name',
    ADDED_TEMPLATE: 'Added template',
    REMOVED_TEMPLATE: 'Removed template',
    OOF_PRODUCTS: 'Out-of-offer products',
    REPLACED_OOF_PRODUCT: 'Replaced out-of-offer product',
    REMOVED_OOF_PRODUCT: 'Removed out-of-offer product',
  },
  SEARCH: {
    ICON_CLICKED: 'Clicked search template',
    SEARCHED_TEMPLATE: 'Searched template',
  },
  FILTERS: {
    CATEGORY_CLICKED: 'Clicked template category filtering',
    CATEGORY_SELECTED: 'Selected template category filtering',
    PROMO_SELECTED: 'Selected template promos only',
    ON_STOCK_SELECTED: 'Selected template available only',
    BRAND_CLICKED: 'Clicked template brand filtering',
    BRAND_SELECTED: 'Selected template brand filtering',
    STORAGE_SELECTED: 'Selected template storage filtering',
    PRODUCT_FEATURES_CLICKED: 'Clicked template product features filtering',
    PRODUCT_FEATURES_SELECTED: 'Selected template product features filtering',
    BESTSELLERS_SELECTED: 'Selected template bestsellers',
    NOVELTIES_SELECTED: 'Selected template novelties',
    BIDMARKET_SELECTED: 'Selected template bidmarket',
    RECOMMENDED_SELECTED: 'selected_template_recommended',
    CLEARANCES_SELECTED: 'selected_template_clearances',
    CUISINE_FEATURES_CLICKED: 'clicked_template_cuisine_filtering',
    CUISINE_FEATURES_SELECTED: 'selected_template_cuisine_filtering',
  },
  SORTING: {
    SORTING_CLICKED: 'Opened template sorting list',
    SORTING_SELECTED: 'Clicked template sorting value',
  },
}

export const trackNotAllAdded = ({ user }) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TIS.NOT_ALL_ADDED,
    customer_no: user.customerNo,
    store_id: String(user.storeId),
  })
}

export const trackTISNonStockDistantDD = (
  productId,
  distantDD,
  inStockQty,
  closeDD,
  orderedQty,
) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TIS.NON_STOCK_DISTANT_DD,
    product_id: productId,
    distant_dd: distantDD,
    in_stock_qty: inStockQty,
    close_dd: closeDD,
    ordered_qty: orderedQty,
  })
}

export const trackTISShowReplacement = productId => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TIS.SHOW_REPLACEMENT,
    product_id: productId,
  })
}

export const trackPopupNonStockDistantDD = (
  user,
  productId,
  distantDD,
  inStockQty,
  closeDD,
  orderedQty,
) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.POPUP.NON_STOCK_DISTANT_DD,
    product_id: productId,
    distant_dd: distantDD,
    in_stock_qty: inStockQty,
    close_dd: closeDD,
    ordered_qty: orderedQty,
    customer_no: user.customerNo,
    store_id: String(user.storeId),
  })
}

export const trackPopupShowReplacement = (productId, user) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.POPUP.SHOW_REPLACEMENT,
    product_id: productId,
    customer_no: user.customerNo,
    store_id: String(user.storeId),
  })
}

export const trackAddedAllFromTemplate = (templateType, templateId) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.PRODUCT.ADDED_ALL_PRODUCTS,
    templateType,
    template: templateId,
  })
}

export const trackOpenedTemplate = (templateType, templateId) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.OPENED,
    templateType,
    template: templateId,
  })
}

export const trackEditedQty = (productId, templateId, qty) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.EDITED_QTY,
    product: productId,
    template: templateId,
    qty,
  })
}

export const trackEditedVariant = ({ productId, templateId, variant }) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.EDITED_VARIANT,
    product: productId,
    template: templateId,
    variant,
  })
}

export const trackChangedName = templateId => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.CHANGED_NAME,
    template: templateId,
  })
}

export const trackAddedTemplate = templateId => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.ADDED_TEMPLATE,
    template: templateId,
  })
}

export const trackRemovedTemplate = (templateId, trigger) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.REMOVED_TEMPLATE,
    template: templateId,
    trigger,
  })
}

export const trackOutOfOfferProducts = (productIds, templateId) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.OOF_PRODUCTS,
    products: productIds,
    template: templateId,
  })
}

export const trackOutOfOfferReplaced = (productId, templateId) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.REPLACED_OOF_PRODUCT,
    product: productId,
    template: templateId,
  })
}

export const trackOutOfOfferRemoved = (productId, templateId) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.TEMPLATE_VIEW.REMOVED_OOF_PRODUCT,
    product: productId,
    template: templateId,
  })
}

export const trackAddedToTemplate = ({
  product,
  unitData,
  itemListName,
  itemListId,
  itemModelId,
  listIndex = 0,
  customerNo,
  additionalEventParams = {},
}) => {
  const value = normalizePrice(unitData.priceGross)
  const currency = CURRENCY
  const productParams = getFormattedProductParams(
    product,
    unitData,
    itemModelId,
  )
  const discount = normalizePrice(getProductDiscount(product, unitData))

  trackEcommerceEvent(TEMPLATE_EVENTS.PRODUCT.ADDED_TO_TEMPLATE, {
    value,
    currency,
    discount,
    customer_no: customerNo,
    items: [
      {
        ...productParams,
        ...formatItemListParams(itemListId, itemListName),
        price: value,
        quantity: 1,
        index: listIndex + 1,
      },
    ],
    ...additionalEventParams,
  })
}

export const trackRemovedFromTemplate = (productId, templateId) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.PRODUCT.REMOVED_FROM_TEMPLATE,
    product: productId,
    template: templateId,
  })
}

export const trackSearchIconClicked = (templateId, templateType) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.SEARCH.ICON_CLICKED,
    template: templateId,
    templateType,
  })
}

export const trackSearchedTemplate = ({
  templateId,
  templateType,
  searchTerm,
  totalCount,
}) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.SEARCH.SEARCHED_TEMPLATE,
    template: templateId,
    templateType,
    searchTerm,
    totalCount,
  })
}

export const trackCategoriesFilterClick = templateId => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.CATEGORY_CLICKED,
    template: templateId,
  })
}

export const trackBrandsFilterClick = templateId => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.BRAND_CLICKED,
    template: templateId,
  })
}

export const trackProductFeaturesClick = templateId => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.PRODUCT_FEATURES_CLICKED,
    template: templateId,
  })
}

export const trackCuisineFeaturesClick = templateId => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.CUISINE_FEATURES_CLICKED,
    template: templateId,
  })
}

const trackCategoriesFilterApplied = (templateId, filterValues) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.CATEGORY_SELECTED,
    template: templateId,
    categories: Object.keys(filterValues),
  })
}

const trackPromoFilterApplied = (templateId, filterValues) => {
  if (FILTERS_VALUES.PROMOTION.AVAILABLE in filterValues) {
    window.dataLayer.push({
      event: TEMPLATE_EVENTS.FILTERS.PROMO_SELECTED,
      template: templateId,
    })
  }
}

const trackClearancesFilterApplied = (templateId, filterValues) => {
  if (FILTERS_VALUES.CLEARANCE.AVAILABLE in filterValues) {
    window.dataLayer.push({
      event: TEMPLATE_EVENTS.FILTERS.CLEARANCES_SELECTED,
      template: templateId,
    })
  }
}

const trackOnStockFilterApplied = (templateId, filterValues) => {
  if (FILTERS_VALUES.ON_STOCK.AVAILABLE in filterValues) {
    window.dataLayer.push({
      event: TEMPLATE_EVENTS.FILTERS.ON_STOCK_SELECTED,
      template: templateId,
    })
  }
}

const trackBrandsFilterApplied = (templateId, filterValues) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.BRAND_SELECTED,
    template: templateId,
    brands: Object.keys(filterValues),
  })
}

const trackProductFeaturesFilterApplied = (templateId, filterValues) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.PRODUCT_FEATURES_SELECTED,
    template: templateId,
    productFeatures: Object.keys(filterValues),
  })
}

const trackCuisineFeaturesFilterApplied = (templateId, filterValues) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.CUISINE_FEATURES_SELECTED,
    template: templateId,
    cuisine: Object.keys(filterValues).join(', '),
  })
}

const trackStorageTypeFilterApplied = (templateId, filterValues) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.FILTERS.STORAGE_SELECTED,
    template: templateId,
    appliedFilters: Object.keys(filterValues),
  })
}

export const trackOfferFeaturesFilterApplied = (
  templateId,
  filterValues,
  eventValues,
) => {
  const {
    BESTSELLER,
    NOVELTY,
    BIDMARKET,
    RECOMMENDATION,
  } = FILTERS_VALUES.OFFER_FEATURES

  const { templateType } = eventValues
  const dataLayer = {
    templateId,
    templateType,
  }

  if (filterValues[FILTERS_VALUES.OFFER_FEATURES.BIDMARKET]) {
    window.dataLayer.push({
      ...dataLayer,
      event: TEMPLATE_EVENTS.FILTERS.BIDMARKET_SELECTED,
      totalCount: eventValues[BIDMARKET],
    })
  }

  if (filterValues[FILTERS_VALUES.OFFER_FEATURES.BESTSELLER]) {
    window.dataLayer.push({
      ...dataLayer,
      event: TEMPLATE_EVENTS.FILTERS.BESTSELLERS_SELECTED,
      totalCount: eventValues[BESTSELLER],
    })
  }

  if (filterValues[FILTERS_VALUES.OFFER_FEATURES.NOVELTY]) {
    window.dataLayer.push({
      ...dataLayer,
      event: TEMPLATE_EVENTS.FILTERS.NOVELTIES_SELECTED,
      totalCount: eventValues[NOVELTY],
    })
  }

  if (filterValues[FILTERS_VALUES.OFFER_FEATURES.RECOMMENDATION]) {
    window.dataLayer.push({
      ...dataLayer,
      event: TEMPLATE_EVENTS.FILTERS.RECOMMENDED_SELECTED,
      totalCount: eventValues[RECOMMENDATION],
    })
  }
}

export const trackFilters = ({
  templateId,
  filterName,
  filterValues = {},
  eventValues,
}) => {
  if (!Object.keys(filterValues).length || filterValues.all) return

  const {
    ON_STOCK,
    PROMOTION,
    CATEGORIES,
    BRANDS,
    STORAGE_TYPES,
    PRODUCT_FEATURES,
    CUISINE_FEATURES,
    OFFER_FEATURES,
    CLEARANCE,
  } = FILTERS_TYPES

  switch (filterName) {
    case ON_STOCK:
      trackOnStockFilterApplied(templateId, filterValues)
      break
    case PROMOTION:
      trackPromoFilterApplied(templateId, filterValues)
      break
    case CLEARANCE:
      trackClearancesFilterApplied(templateId, filterValues)
      break
    case CATEGORIES:
      trackCategoriesFilterApplied(templateId, filterValues)
      break
    case BRANDS:
      trackBrandsFilterApplied(templateId, filterValues)
      break
    case PRODUCT_FEATURES:
      trackProductFeaturesFilterApplied(templateId, filterValues)
      break
    case CUISINE_FEATURES:
      trackCuisineFeaturesFilterApplied(templateId, filterValues)
      break
    case STORAGE_TYPES:
      trackStorageTypeFilterApplied(templateId, filterValues)
      break
    case OFFER_FEATURES:
      trackOfferFeaturesFilterApplied(templateId, filterValues, eventValues)
      break
    default:
  }
}

export const trackSortingClick = (templateId, templateType) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.SORTING.SORTING_CLICKED,
    template: templateId,
    templateType,
  })
}

export const trackSortingSelect = ({
  templateId,
  templateType,
  sortingOption,
}) => {
  window.dataLayer.push({
    event: TEMPLATE_EVENTS.SORTING.SORTING_SELECTED,
    template: templateId,
    templateType,
    sortingOption,
  })
}
