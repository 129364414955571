import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getSpacing, getFontWeights } from 'components/helpers/theme-selectors'
import { PrimaryBtn } from 'components/Button'
import { H4 } from 'components/Text'

export const EmptyButton = styled(PrimaryBtn)`
  width: 160px;
  height: 48px;
  padding: 0;
`

export const EmptyCartWrapper = styled(Flex)`
  height: 400px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
`

export const BlackHeader = styled(H4)`
  margin: 0;
  padding-bottom: ${props => getSpacing(props).md};
  font-weight: ${props => getFontWeights(props).bold};
`
