import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { useRichFormatMessage } from 'utils'
import { trackRemovedTemplate } from 'services/analytics'
import { templatesActions } from 'containers/Templates/actions'
import Confirmation from 'components/modal/Confirmation'

import { DescriptionText } from 'components/modal/Confirmation/styles'
import templateMessages from '../messages'

const RemoveTemplateConfirmation = ({ name, id, trigger, onClose }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()

  const onConfirm = () => {
    dispatch(
      templatesActions.deleteDelta(
        { id },
        {
          successMessage: formatMessage(
            templateMessages.removedTemplateNotification,
            { value: name },
          ),
          successCallback: () => trackRemovedTemplate(id, trigger),
        },
      ),
    )
    onClose()
  }

  return (
    <Confirmation
      isOpen
      onClose={onClose}
      onConfirm={onConfirm}
      headingText={formatMessage(templateMessages.removeTemplate)}
      leftBtnText={formatMessage(templateMessages.cancel)}
      rightBtnText={formatMessage(templateMessages.removeAccept)}
      rightBtnTestId="template_remove_confirm"
    >
      <DescriptionText>
        {formatRichMessage(templateMessages.removeConfirmationDialog, { name })}
      </DescriptionText>
    </Confirmation>
  )
}

export default RemoveTemplateConfirmation
