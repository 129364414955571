import styled from 'styled-components'

import { withIcon, ICONS_POSITION, ICON_SIZE } from 'components/Icon'
import { BUTTON_SIZE, AlternateBtn } from 'components/Button'

export const ReplacementsButton = props =>
  withIcon({
    ...props,
    iconSize: ICON_SIZE.SMALL,
    iconType: 'repeat',
    iconPosition: ICONS_POSITION.LEFT,
    size: BUTTON_SIZE.SMALL,
  })(
    styled(AlternateBtn)`
      width: auto;
      min-width: auto;
    `,
  )
