import React from 'react'

import theme from 'theme'
import { ControlLabel } from 'components/Text'

import {
  Container,
  DescContainer,
  SupplementLabel,
  IconContainer,
} from './styles'

const MenuItem = ({
  icon,
  testId,
  title,
  supplementLabel,
  supplementLabelTestId,
  onSelect,
  bgColor,
  txtColor,
  className,
}) => (
  <Container
    onClick={onSelect}
    data-test-id={testId}
    bgColor={bgColor}
    className={className}
  >
    <IconContainer>
      {icon && React.cloneElement(icon, { color: txtColor })}
    </IconContainer>
    <DescContainer>
      <ControlLabel color={txtColor}>{title}</ControlLabel>
      {!!supplementLabel && (
        <SupplementLabel>
          <ControlLabel
            data-test-id={supplementLabelTestId}
            color={txtColor || theme.colors.lightGrey4}
          >
            {supplementLabel}
          </ControlLabel>
        </SupplementLabel>
      )}
    </DescContainer>
  </Container>
)

export default MenuItem
