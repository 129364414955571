import React from 'react'
import { Portal } from 'react-portal'
import { DropdownOverlay } from 'components/ModalBackgroundOverlay/styles'

const ModalBackgroundOverlay = ({ enabled, ...props }) => (
  <Portal key="modal-overlay-portal">
    <DropdownOverlay
      {...props}
      data-test-id="modal-overlay"
      $hidden={!enabled}
    />
  </Portal>
)

export default ModalBackgroundOverlay
