import { makeMessages, makeTranslation } from 'utils'

const messages = {
  lackOfProducts: 'lackOfProducts',
  clearFiltersBtn: {
    id: 'catalog.noProductsClearFiltersBtn',
  },
}

export default makeMessages(messages, makeTranslation('notFound'))
