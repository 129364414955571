import React from 'react'

const Kebab = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 18H18C18 20.7614 15.7614 23 13 23H11C8.23858 23 6 20.7614 6 18Z"
      fill="#38170D"
    />
    <circle cx="9" cy="5" r="3" fill="#38170D" />
    <circle cx="12" cy="4" r="3" fill="#38170D" />
    <circle cx="15" cy="5" r="3" fill="#38170D" />
    <path
      d="M18 19.1538C18 21.278 16.278 23 14.1538 23C10.7552 23 8 20.2448 8 16.8462L8 12C8 7.58172 11.5817 4 16 4L17 4C17.5523 4 18 4.44772 18 5L18 19.1538Z"
      fill="#E19747"
    />
    <path
      d="M6 5C6 4.44771 6.44772 4 7 4H8C12.4183 4 16 7.58172 16 12V16.8462C16 20.2448 13.2448 23 9.84615 23C7.72198 23 6 21.278 6 19.1538V5Z"
      fill="#FEEFC2"
    />
  </svg>
)

export default Kebab
