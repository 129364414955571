import React from 'react'
import theme from 'theme'

const LactoseFree = ({ color = theme.colors.primary }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.77267 5.03362L5.56126 2.89887C5.2289 2.73634 5 2.3949 5 2C5 1.44772 5.44772 1 6 1L6.77787 1L9.22231 1L10 1C10.5523 1 11 1.44772 11 2C11 2.39484 10.7712 2.73623 10.4389 2.89879L11.9381 6.95728C11.9791 7.06821 12.0001 7.18554 12.0001 7.3038V9.67982L15.4057 11.8691C15.7541 12.0931 15.855 12.5571 15.631 12.9056C15.407 13.254 14.943 13.3549 14.5945 13.1309L0.594523 4.13088C0.246096 3.90689 0.145218 3.44286 0.369208 3.09443C0.593197 2.746 1.05723 2.64513 1.40566 2.86912L4.77267 5.03362ZM5.66009 8.92823C5.66009 8.86018 5.62549 8.7968 5.56825 8.76L4.30825 7.94996C4.17514 7.86439 4.00009 7.95996 4.00009 8.11819V14C4.00009 14.5523 4.44781 15 5.00009 15L11.0001 15C11.5524 15 12.0001 14.5523 12.0001 14V13.0041C12.0001 12.9361 11.9655 12.8727 11.9082 12.8359L10.6482 12.0259C10.5151 11.9403 10.3401 12.0359 10.3401 12.1941V13.14C10.3401 13.2505 10.2505 13.34 10.1401 13.34H5.86009C5.74963 13.34 5.66009 13.2505 5.66009 13.14L5.66009 8.92823Z"
      fill={color}
      className="primaryFill"
    />
  </svg>
)

export default LactoseFree
