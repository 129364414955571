import styled from 'styled-components'

import theme from 'theme'

import { breakPointTablet, ContentWrapper } from 'components/Layout'

const TILES_GRID_MIN = 416

export const PromotionsWrapper = styled(ContentWrapper)`
  @media screen and (max-width: ${breakPointTablet}) {
    padding: 32px 16px;
  }
`

export const Divider = styled.div`
  width: 100vw;
  max-width: 1440px;
  margin: 16px -32px 40px -32px;
  border-top: 1px solid ${theme.colors.lightGrey2};
  @media screen and (max-width: ${breakPointTablet}) {
    margin-bottom: 16px;
  }
`

export const TilesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${TILES_GRID_MIN}px, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  @media screen and (max-width: ${breakPointTablet}) {
    margin: 0 auto;
  }
  @media screen and (max-width: ${TILES_GRID_MIN}px) {
    max-width: calc(100vw - 32px);
    > * {
      max-width: calc(100vw - 32px);
    }
  }
`
