import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

import { BaseTextV2Secondary, H3v2 } from 'components/Text'
import { ContentWrapper } from 'components/Layout'

export const IconContainer = styled.img`
  height: 96px;
  width: 96px;
`

export const Container = styled(ContentWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px ${theme.spacing.md};
`

export const InnerContainer = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.base};
  max-width: 410px;
  align-items: center;
  text-align: center;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    align-items: flex-start;
    text-align: left;
  }
`

export const Header = styled(H3v2)`
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: ${theme.textSizes.xl};
  }
`

export const Description = styled(BaseTextV2Secondary)`
  font-size: ${theme.textSizes.l};
`

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`
