import styled from 'styled-components'
import theme from 'theme'
import { BaseTextV2, BaseTextV2Secondary } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

export const ProductParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing.md};
  margin-top: ${theme.spacing.sm};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    flex-direction: row;
    margin-top: ${theme.spacing.base};
  }
`

export const ParameterCollection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
  flex: 1;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    max-width: 55%;
  }
`

export const ParameterCollectionList = styled.div`
  display: flex;
  flex-direction: column;
`

export const ParameterLine = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  justify-content: space-between;
  padding: 6px 0;
`

export const ParameterName = styled(BaseTextV2Secondary)`
  font-size: 14px;
  display: flex;
  flex: 1;
`

export const ParameterValue = styled(BaseTextV2)`
  font-size: 14px;
  display: flex;
  flex: 1;
`
