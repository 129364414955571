import React from 'react'
import { connect } from 'react-redux'
import { Portal } from 'react-portal'

import { CLICK_OUTSIDE_IGNORED_CLASSNAME } from 'hooks/useOnClickOutside'
import LoaderFullHeight from 'components/LoaderFullHeight'

import { isFetchingSelector } from './selectors'

const withSpinner = reducerName => WrappedComponent => {
  const Component = props => [
    <WrappedComponent key="component" {...props} />,
    props.fetching && (
      <Portal key="portal">
        <LoaderFullHeight className={CLICK_OUTSIDE_IGNORED_CLASSNAME} />
      </Portal>
    ),
  ]

  return connect(isFetchingSelector(reducerName))(Component)
}

export default withSpinner
