import { handleActions } from 'redux-actions'

import { appConfigActions } from '../actions'

const INIT_STATE = {
  apiVersion: null,
}

export default handleActions(
  {
    [appConfigActions.updateApiVersion]: (state, { payload }) => ({
      ...state,
      apiVersion: payload,
    }),
  },
  INIT_STATE,
)
