import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getSpacing } from 'components/helpers/theme-selectors'
import { NormalIcon, IconWrapper, ICONS } from 'components/Icon'
import { ALERT_SIZE } from './consts'
import AlertBox from './AlertBox'
import { getIcon } from './utils'

import { AlertText } from './styles'

// prettier-ignore
const AlertWrapper = styled(AlertBox)`
  ${IconWrapper}:first-child {
    margin-right: ${props => getSpacing(props).sm};
  }
  ${IconWrapper}:last-child {
    cursor: pointer;
    margin-inline-end: 0;
    margin: 0;
  }
  margin-top:${({marginTop,...props}) => marginTop ? getSpacing(props)[marginTop] : '0'};
  margin-bottom:${({marginBottom,...props}) => marginBottom ? getSpacing(props)[marginBottom] : '0'};
`

const BigAlert = ({ type, children, onClick, marginTop, marginBottom }) => (
  <AlertWrapper
    {...{ marginTop, marginBottom }}
    type={type}
    size={ALERT_SIZE.NORMAL}
  >
    <NormalIcon type={getIcon(type)} />
    <AlertText>{children}</AlertText>
    {onClick && <NormalIcon type={ICONS.X} onClick={onClick} />}
  </AlertWrapper>
)

BigAlert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default BigAlert
