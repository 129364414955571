import { connect } from 'react-redux'

import { bindActionCreators } from 'utils/redux-utils'

import cartActions from 'containers/Cart/actions'
import { getReplacementsActions } from 'containers/Products/Replacements/actions'

export const mapDispatchToProps = bindActionCreators({
  addToCart: cartActions.createDelta,
  getCart: cartActions.delta,
  deleteCartItem: cartActions.deleteDelta,
  getReplacements: getReplacementsActions.delta,
})

export default connect(
  null,
  mapDispatchToProps,
)
