import styled from 'styled-components'

import { APP_BREAKPOINTS } from 'consts'
import { SecondaryBtnV2 } from 'components/Button/styles'
import ProductImage from 'components/Product/ProductImage'
import theme from 'theme'

export const RowIconBtn = styled(SecondaryBtnV2)`
  padding: ${theme.spacing.xs};
  height: 40px;
  width: 100%;

  :disabled {
    background-color: ${theme.colors.semiTranspBg.grayLight};
    path {
      stroke: ${theme.colors.lightGreyHover};
    }
    circle {
      fill: ${theme.colors.lightGreyHover};
    }
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    width: 40px;
  }
`

export const OrderItemActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    align-items: center;
  }
`

export const OrderItemActionsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    flex-direction: row-reverse;
    gap: ${theme.spacing.ss};
  }
`

export const OrderItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    width: 100%;
    flex-direction: row;
  }
`

export const OrderItemAmountWrapper = styled.div`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    margin-left: auto;
    margin-right: 80px;
  }
`

export const OrderItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.sm};
  cursor: pointer;
  border-radius: ${theme.borderRadius.base};
  padding: ${theme.spacing.vertical} 0;

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    :hover {
      background-color: ${theme.colors.semiTranspBg.grayLight};

      ${RowIconBtn}:enabled {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        background-color: ${theme.colors.white};
        :hover {
          background-color: ${theme.colors.semiTranspBg.grayLight};
          padding: 0 ${theme.spacing.xxs};
          border: 4px solid ${theme.colors.white};
        }
      }
    }

    padding: ${theme.spacing.sm};
  }
`

export const OrderItemImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
  min-width: 112px;
  justify-content: space-between;

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    min-width: auto;
    padding: 6px 0;
    justify-content: normal;
  }
`

export const OrderItemImage = styled(ProductImage)`
  mix-blend-mode: multiply;
  align-self: center;
  height: 72px;
  width: 72px;
  min-width: 72px;

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    height: 48px;
    width: 48px;
    min-width: 48px;
  }
`

export const OrderItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    align-self: center;
  }
`
