import * as React from 'react'

import theme from 'theme'

const InfoCircle = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-test-id="info-icon"
    width={width}
    height={height}
    {...rest}
  >
    <circle cx={12} cy={12} r={8} stroke={color} strokeWidth={2} />
    <path
      d="M12 12v2.75c0 .69.56 1.25 1.25 1.25h.25"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={12} cy={9} r={1.2} fill={color} />
  </svg>
)

export default InfoCircle
