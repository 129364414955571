import styled from 'styled-components'

import theme from 'theme'

import { H2v2 } from 'components/Text'

export const SectionHeader = styled(H2v2)`
  padding-bottom: 24px;
  border-bottom: 1px solid ${theme.colors.lightGrey2};
`
