import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { find, head } from 'lodash/fp'

import { allDeliveryDatesSelector } from 'containers/Delivery/selectors'
import { deliveryDatesSettingsByDeliveriesSelector } from 'containers/Cart/selectors'
import { calculateDeliveryTotal, formatDateForApi } from 'containers/Cart/utils'

export const useDeliveryStats = ({ openDelivery, incomingOrders }) => {
  const deliveryDates = useSelector(allDeliveryDatesSelector)
  const deliveryDatesSettingsByDeliveries = useSelector(
    deliveryDatesSettingsByDeliveriesSelector(),
  )
  const { deliveryDate, routeId } = openDelivery || head(incomingOrders) || {}

  return useMemo(
    () => {
      let total = 0
      let missingAmount
      const dateData = {
        deliveryDate: formatDateForApi(deliveryDate),
        routeId,
      }
      const settings = find(dateData)(deliveryDates) || {}
      const incTotal = settings.baseLogisticLimit - settings.logisticLimit

      if (openDelivery) {
        const cartDeliveryTotal = calculateDeliveryTotal(openDelivery.items)
        total = incTotal + cartDeliveryTotal
        missingAmount = settings.logisticLimit - cartDeliveryTotal
      } else {
        total = incTotal
        missingAmount = settings.logisticLimit
      }

      return { total, settings, missingAmount }
    },
    [
      openDelivery,
      incomingOrders,
      deliveryDates,
      deliveryDatesSettingsByDeliveries,
    ],
  )
}
