import * as React from 'react'

import theme from 'theme'

const CartCrossed = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M9 6H6l1.81 7.242a1 1 0 00.97.758h7.602a1 1 0 00.894-.553l3-6A1 1 0 0019.382 6H18"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={7.5} cy={20.5} r={1.5} fill={color} />
    <circle cx={18.5} cy={20.5} r={1.5} fill={color} />
    <path
      d="M19 18H8a2 2 0 01-2-2v0a2 2 0 012-2h3M6.5 8l-1-4H2"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 4l5 5M11 9l5-5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
)

export default CartCrossed
