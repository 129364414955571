import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Portal } from 'react-portal'

import { LegalText, LegalTextLink } from 'components/Text'
import { ModalHeader } from 'components/modal/styles'
import LoaderFullHeight from 'components/LoaderFullHeight'
import FormInputField from 'components/Fields/FormInputField'
import { subscribeToProductActions } from 'containers/ProductDetails/actions'
import { notifyFailure, notifySuccess } from 'components/Product/utils'
import { userDataSelector } from 'containers/UserInfo/selectors'
import {
  PRODUCT_EVENTS,
  useProductAvailabilityTracking,
} from 'services/analytics'
import { useCustomFormikHandlers } from 'hooks'
import { useRichFormatMessage } from 'utils'
import { ROUTES } from 'consts'
import theme from 'theme'
import { BtnText, InfoBtn } from 'components/ProductCartControls/styles'

import { DescriptionText, EmailNotificationModalWrapper } from './styles'
import validationSchema from './validationSchema'
import messages from './messages'

const EmailNotificationModal = ({ product, unitData, isOpen, setIsOpen }) => {
  const formatMessage = useRichFormatMessage()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const trackProductAvailability = useProductAvailabilityTracking({
    product,
    unitData,
  })

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
  } = useCustomFormikHandlers({
    initialValues: {
      email: '',
    },
    onSubmit: fieldValues => {
      setIsLoading(true)

      dispatch(
        subscribeToProductActions.delta(
          { ...fieldValues, id: product.id },
          {
            callback: () => {
              setIsOpen(false)
              setIsLoading(false)
            },
            onSuccess: () => {
              trackProductAvailability(PRODUCT_EVENTS.SETUP_NOTIFICATION)
              dispatch(notifySuccess(formatMessage(messages.successMessage)))
            },
            onError: () => {
              dispatch(notifyFailure(formatMessage(messages.failMessage)))
            },
          },
        ),
      )
    },
    validationSchema,
  })

  const { customerEmail } = useSelector(userDataSelector)
  useEffect(
    () => {
      setValues(
        {
          email: product.notificationEmail || customerEmail,
        },
        true,
      )
    },
    [product, customerEmail, isOpen],
  )

  return (
    <>
      {isLoading && (
        <Portal key="portal">
          <LoaderFullHeight />
        </Portal>
      )}

      <EmailNotificationModalWrapper
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ModalHeader
          style={{
            margin: 0,
          }}
          heading={formatMessage(messages.modalTitle)}
          onClick={() => setIsOpen(false)}
        />
        <DescriptionText>
          {formatMessage(
            product.notificationEmail
              ? messages.changeEmailInfo
              : messages.setupEmailInfo,
          )}
        </DescriptionText>
        <FormInputField
          name="email"
          testId="email_input"
          containerTestId="email_input_container"
          label={formatMessage(messages.emailLabel)}
          value={values.email}
          touched={touched.email}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errorText={formatMessage(errors.email)}
          isError={touched.email && errors.email}
        />
        <InfoBtn onClick={handleSubmit} data-test-id="submit-email">
          <BtnText $color={theme.colors.white}>
            {formatMessage(
              product.notificationEmail
                ? messages.changeEmailBtn
                : messages.setupEmailBtn,
            )}
          </BtnText>
        </InfoBtn>
        <LegalText $color={theme.colors.lightGrey4}>
          {formatMessage(messages.modalLegalText, {
            link: (
              <LegalTextLink to={ROUTES.TERMS}>
                {formatMessage(messages.modalTermsLink)}
              </LegalTextLink>
            ),
          })}
        </LegalText>
      </EmailNotificationModalWrapper>
    </>
  )
}

export default EmailNotificationModal
