import React from 'react'

import theme from 'theme'

const ArrowDownFilter = ({
  color = theme.colors.primary,
  width = 16,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M5 7L8 10L11 7"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowDownFilter
