import { createApi } from '@reduxjs/toolkit/query/react'

import history from 'utils/history'
import { ROUTES } from 'consts'
import { cameliseDeep, snakeCaseDeep } from 'utils'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_OFFLINE } from 'containers/App/modalTypes'
import makeApiWithReauth from './api'

const axiosBaseQuery = async (
  { url, method = 'get', data = {}, params = {}, headers, meta = {} },
  store,
) => {
  try {
    const baseApi = makeApiWithReauth(store, rtkApi)

    const result = await baseApi({
      url,
      method,
      data: snakeCaseDeep(data),
      params: snakeCaseDeep(params),
      headers,
    })

    return { data: cameliseDeep(result.data) }
  } catch (error) {
    console.log(`[RTK Query] Error sending request to ${url}`, error)
    const errorStatus = error.response?.status

    if (errorStatus === 404 && !meta.suppress404Redirect) {
      history.push(ROUTES.NOT_FOUND)
    } else if (!navigator.onLine) {
      store.dispatch(openModal(MODAL_OFFLINE, { hideHeader: true }))
    }

    return {
      error: {
        status: errorStatus,
        data: error.response?.data || error.message,
      },
    }
  }
}

const STORAGE_TAG = 'storage'

export const rtkApi = createApi({
  reducerPath: 'rtkApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [STORAGE_TAG],
  endpoints: build => ({
    getStorage: build.query({
      query: () => ({
        url: 'me/storage',
      }),
      providesTags: [STORAGE_TAG],
    }),
    updateStorage: build.mutation({
      query: ({ path, value }) => ({
        url: `me/storage/${path}`,
        method: 'PUT',
        data: { value },
      }),
      invalidatesTags: [STORAGE_TAG],
    }),
    deleteStorage: build.mutation({
      query: ({ path }) => ({
        url: `me/storage/${path}`,
        method: 'DELETE',
      }),
      invalidatesTags: [STORAGE_TAG],
    }),
  }),
})

export const {
  useGetStorageQuery,
  useUpdateStorageMutation,
  useDeleteStorageMutation,
} = rtkApi
