import React, { useEffect, useRef, useState } from 'react'

import {
  useCategoriesDropdownCategorySelect,
  useCategoriesListSelect,
} from './utils'
import CategoriesDropdownList from './CategoriesDropdownList'
import FilteredPagesSection from './FilteredPagesSection'
import SubcategoriesSection from './SubcategoriesSection'
import CategoriesDropdownHeader from './CategoriesDropdownHeader'

const Categories = () => {
  const [listHeight, setListHeight] = useState(0)

  const listRef = useRef(null)

  const {
    isCuisinesSelected,
    setCuisinesSelected,
    isLoading,
    categories,
    cuisines,
  } = useCategoriesListSelect()

  useEffect(
    () => {
      setListHeight(listRef.current?.clientHeight)
    },
    [categories],
  )

  const {
    selectedCategory,
    setSelectedCategory,
  } = useCategoriesDropdownCategorySelect({
    categories,
  })

  return (
    <div className="flex bg-white shadow-modal rounded-2xl">
      <div
        className="p-6 flex flex-col gap-y-4"
        style={{
          minHeight: isCuisinesSelected && listHeight,
        }}
        data-test-id="main-list"
        ref={listRef}
      >
        <CategoriesDropdownHeader
          isCuisinesSelected={isCuisinesSelected}
          setCuisinesSelected={setCuisinesSelected}
          setSelectedCategory={setSelectedCategory}
        />
        <CategoriesDropdownList
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          isCuisinesSelected={isCuisinesSelected}
          categories={categories}
          cuisines={cuisines}
          isLoading={isLoading}
        />
      </div>

      <div
        className="flex flex-col border-solid border-l border-grey-300 justify-between p-6 min-h-[440px] w-[360px]"
        style={{ maxHeight: listHeight }}
      >
        {selectedCategory ? (
          <SubcategoriesSection
            clearSelection={() => setSelectedCategory(null)}
            category={selectedCategory}
          />
        ) : (
          <FilteredPagesSection />
        )}
      </div>
    </div>
  )
}

export default Categories
