import { makeMessages, makeTranslation } from 'utils'

const messages = {
  title: 'title',
  loginDesc: 'loginDesc',
  loginInput: { id: 'login.usernameLabel' },
  passDesc: 'passDesc',
  pass: { id: 'login.passwordLabel' },
  passHint: { id: 'profile_change_password.new_password_hint' },
  agreements: 'agreements',
  ctaBtn: 'ctaBtn',
  errorMsg: 'errorMsg',
  successTitle: 'successTitle',
  toLogin: { id: 'setPassword.toLogin' },
  youCanLogin: { id: 'setPassword.youCanLogin' },
  expiredTitle: 'expiredTitle',
  expiredMsg: 'expiredMsg',
}

export default makeMessages(messages, makeTranslation('signUpMigrate'))
