import React from 'react'

import { mapIsoCountryCodeToName } from 'utils/maps'

import messages from './messages'
import { SectionsWrapper, Section } from '../styles'
import {
  SubSection,
  SectionHeader,
  TextContainer,
  DataText,
  LabelText,
} from './styles'

const Data = ({ formatMessage, userData }) => {
  const {
    customerNo,
    firstName,
    lastName,
    login,
    customerEmail,
    phoneNo,
    billingName,
    billingPostcode,
    billingCity,
    billingVatNo,
    billingAddress,
    deliveryName,
    deliveryAddress,
    deliveryPostcode,
    deliveryCity,
  } = userData

  const billingCountry = mapIsoCountryCodeToName(userData.billingCountry)
  const invoiceSectionAddress = `${billingAddress}\n${billingPostcode}, ${billingCity}\n${billingCountry}`

  const deliveryCountry = mapIsoCountryCodeToName(userData.deliveryCountry)
  const deliverySectionAddress = `${deliveryName}\n${deliveryAddress}\n${deliveryPostcode}, ${deliveryCity}\n${deliveryCountry}`

  const config = [
    {
      header: 'contact',
      content: [
        {
          label: 'customerNo',
          value: customerNo,
        },
        {
          label: 'name',
          value: `${firstName} ${lastName}`,
        },
        {
          label: 'login',
          value: login,
        },
        {
          label: 'email',
          value: customerEmail,
        },
        {
          label: 'tel',
          value: phoneNo,
        },
      ],
    },
    {
      header: 'invoice',
      content: [
        {
          label: 'company',
          value: billingName,
        },
        {
          label: 'taxNo',
          value: billingVatNo,
        },
        {
          label: 'address',
          value: invoiceSectionAddress,
          style: {
            whiteSpace: 'pre',
          },
        },
      ],
    },
    {
      header: 'delivery',
      content: [
        {
          label: 'address',
          value: deliverySectionAddress,
          style: {
            whiteSpace: 'pre',
          },
        },
      ],
    },
  ]

  return (
    <SectionsWrapper count={config.length} direction="bottom">
      {config.map(({ header, content }) => (
        <Section key={header}>
          <SectionHeader>{formatMessage(messages[header])}</SectionHeader>
          <SubSection>
            {content.map(({ label, value, style }) => (
              <TextContainer key={label} {...{ style }}>
                <LabelText>{formatMessage(messages[label])}</LabelText>
                <DataText>{value}</DataText>
              </TextContainer>
            ))}
          </SubSection>
        </Section>
      ))}
    </SectionsWrapper>
  )
}

export default Data
