import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'
import { DefaultPopoverContainer } from 'components/Popup/styles'

export const ReplacementContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%:
  width: 100%;
`

export const ProductLongBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  background: ${theme.colors.lightGrey};
  cursor: pointer;
  color: ${props => theme.colors[props.textColor || 'infoBlue']};
  font-weight: 500;
  font-family: ${theme.fonts.primary};
  border-radius: 8px;
  height: 40px;
  font-size: 13px;
  line-height: 24px;
  padding: 8px 16px;
  :hover {
    background: ${theme.colors.lightGrey6} !important;
  }
  :focus,
  :focus-visible {
    outline: none;
  }
  ${props =>
    props.isDisabled &&
    `
    cursor: default;
    :hover {
      background: ${theme.colors.white} !important;
    }
  `};
`

export const StockLimitedTooltipContainer = styled(Flex)`
  ${DefaultPopoverContainer};
  flex-direction: column;
  width: 216px;
  height: 176px;
  padding: 20px;
  button {
    min-width: 176px;
    min-height: 40px;
  }
`

export const ReplacementsBtn = styled(ProductLongBtn)`
  width: unset;
  border-radius: 0;
  flex-grow: 1;
  gap: 8px;
`

export const IconBtn = styled(ReplacementsBtn)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 100%;
  padding: 0;
`

export const BtnSeparator = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${theme.colors.semiTranspBg.gray};
  align-self: center;
`

export const ReplacementsBtnContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2px;
  border-radius: 0;
  color: ${theme.colors.infoBlue};
  height: 40px;

  ${ReplacementsBtn}:first-child {
    border-top-left-radius: ${theme.borderRadius.base};
    border-bottom-left-radius: ${theme.borderRadius.base};
  }

  ${ReplacementsBtn}:last-child {
    border-top-right-radius: ${theme.borderRadius.base};
    border-bottom-right-radius: ${theme.borderRadius.base};
  }
`

export const StockLimitedTooltipText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  font-weight: 500;
  color: ${theme.colors.infoBlue2};
  margin-bottom: 16px;
`
