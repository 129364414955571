import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ROUTES } from 'consts'
import { Link } from 'components/Text'

import Notification from '../Notification'
import { StyledButton } from './styledComponents'
import messages from './messages'

const AlreadyUsed = () => (
  <Notification header={messages.alreadyUsed}>
    <FormattedMessage {...messages.alreadyUsedDescription} />
    <Link to={ROUTES.LOGIN}>
      <StyledButton>
        <FormattedMessage {...messages.toLogin} />
      </StyledButton>
    </Link>
  </Notification>
)

export default AlreadyUsed
