import { makeMessages, makeTranslation } from 'utils'

const messages = {
  closestDelivery: 'closestDelivery',
  noPlannedDeliveries: 'noPlannedDeliveries',
  noDeliveries: 'noDeliveries',
  orderUntil: 'orderUntil',
  deliveriesNumber: 'deliveriesAmount',
  incomingDelivery: 'incomingDelivery',
  incomingDeliveryTooltip: 'incomingDeliveryTooltip',
  openDelivery: 'openDelivery',
  openDeliveryTooltip: 'openDeliveryTooltip',
  today: 'today',
  tomorrow: 'tomorrow',
  dayAfterTomorrow: 'dayAfterTomorrow',
  lacksToLimit: 'lacksToLimit',
  limitReached: 'limitReached',
  routeDefault: 'routeDefault',
  routeMorning: 'routeMorning',
  routeAfternoon: 'routeAfternoon',
  routeEvening: 'routeEvening',
}

export default makeMessages(messages, makeTranslation('dashboard.deliveries'))
