import React from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { useRichFormatMessage } from 'utils'
import Confirmation from 'components/modal/Confirmation'
import { DescriptionText } from 'components/modal/Confirmation/styles'
import { useRemoveOrderItemMutation } from 'containers/Orders/Details/rtkApi'
import { notifySuccess, notifyFailure } from 'components/Product/utils'

import messages from '../messages'

const RemoveItemModal = ({ productName, itemId, orderId }) => {
  const dispatch = useDispatch()
  const modal = useModal()
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()

  const [removeOrderItem] = useRemoveOrderItemMutation()
  const handleConfirm = async () => {
    const { error } = await removeOrderItem({ orderId, itemId })

    if (error) {
      console.log('Error removing an item from the order', error)
      dispatch(notifyFailure(formatMessage(messages.error)))
    } else {
      dispatch(notifySuccess(formatMessage(messages.success)))
    }

    modal.remove()
  }

  return (
    <Confirmation
      isOpen
      onClose={modal.remove}
      onConfirm={handleConfirm}
      headingText={formatMessage(messages.confirmationTitle)}
      leftBtnText={formatMessage(messages.reject)}
      rightBtnText={formatMessage(messages.confirm)}
    >
      <DescriptionText>
        {formatRichMessage(messages.confirmationDesc, { productName })}
      </DescriptionText>
    </Confirmation>
  )
}

export default NiceModal.create(RemoveItemModal)
