export const sendNewsletterFeedback = ({ accountId, reason }) => {
  const options = {
    method: 'POST',
    body: {
      account_id: accountId,
      unsubscribe_reason: reason,
    },
  }

  return {
    url: '/newsletter/unsubscribe_reason',
    options,
  }
}
