import React, { useMemo } from 'react'

import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'
import { Edit as EditIcon } from 'components/Icons'
import { formatPricePerUnit, isOutOfStock } from 'utils'
import {
  calculateAmountByQuantity,
  formatUnitAmount,
  isLoweredPrice,
} from 'components/Product/utils'
import { findUnitData } from 'services/analytics'

import TooltipContent from './TooltipContent'
import {
  EditBtn,
  PricePerUnitText,
  QuantityBox,
  QuantityBoxContainer,
  QuantityDetails,
  UnitAmountText,
} from './styles'

const QuantityButtons = ({
  product,
  selectedUnit,
  selectedQuantity,
  isTemplateAutomatic,
  onRemove,
}) => {
  const { promotion, unitsOfMeasure } = product

  const unitData = findUnitData(unitsOfMeasure, selectedUnit)
  const { priceNet, unitOfMeasureDescription } = unitData

  const formattedUnitAmount = useMemo(
    () => {
      const amount = calculateAmountByQuantity(unitData, selectedQuantity)
      return formatUnitAmount(unitData, amount)
    },
    [unitData, selectedQuantity],
  )

  const formattedPricePerUnit = useMemo(
    () => {
      const price = isLoweredPrice(promotion)
        ? promotion.reward.priceNet
        : priceNet
      return formatPricePerUnit(price, unitOfMeasureDescription)
    },
    [unitOfMeasureDescription, promotion, priceNet],
  )

  const filteredUnitsOfMeasure = unitsOfMeasure.filter(
    unit => !isOutOfStock({ stock: unit.stock, nonStock: product.nonStock }),
  )

  return (
    <Popover disabled={isTemplateAutomatic}>
      <PopoverTrigger>
        <QuantityBoxContainer>
          <QuantityBox
            $isTemplateAutomatic={isTemplateAutomatic}
            data-test-id="template-tooltip-btn"
          >
            <QuantityDetails>
              <UnitAmountText>{formattedUnitAmount}</UnitAmountText>
              <PricePerUnitText>{formattedPricePerUnit}</PricePerUnitText>
            </QuantityDetails>
            {!isTemplateAutomatic && (
              <EditBtn>
                <EditIcon />
              </EditBtn>
            )}
          </QuantityBox>
        </QuantityBoxContainer>
      </PopoverTrigger>
      <PopoverContent>
        <TooltipContent
          product={product}
          unitsOfMeasure={filteredUnitsOfMeasure}
          selectedQuantity={selectedQuantity}
          selectedUnit={unitData}
          onRemove={onRemove}
        />
      </PopoverContent>
    </Popover>
  )
}

export default QuantityButtons
