import { takeEvery, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { getProductDetails } from 'containers/ProductDetails/api'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'

import { getPromoProduct } from './actions'

export function* getPromoProductFlow({ data: { productId } }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: {
        request: () => getPromoProduct.request({ id: productId }),
        success: response =>
          getPromoProduct.success({ id: productId, response }),
        failure: err => getPromoProduct.failure({ id: productId, error: err }),
      },
      request: getProductDetails,
      params: { body: { id: productId } },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [takeEvery(getPromoProduct.DELTA, getPromoProductFlow)]
