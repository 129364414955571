import React, { useEffect, useState } from 'react'

import { EVENT_ORIGIN, trackDeliveryDateClick } from 'services/analytics'
import { useSelector } from 'react-redux'
import { userDataSelector } from 'containers/UserInfo/selectors'
import DeliveryDay from './DeliveryDay'
import DeliveryDayDetails from './DeliveryDayDetails'

import { ContentContainer, DaysContainer } from './styles'

const TileContent = ({ deliveries, isContentOpened, expandTile }) => {
  const { customerNo } = useSelector(userDataSelector)

  const [selectedDate, setSelectedDate] = useState(-1)
  const selectedDateRoutes = deliveries[selectedDate]?.routes

  useEffect(
    () => {
      if (isContentOpened && selectedDate <= -1) {
        setSelectedDate(0)
      } else if (!isContentOpened) {
        setSelectedDate(-1)
      }
    },
    [isContentOpened, selectedDate],
  )

  const handleDaySelect = (i, day) => {
    setSelectedDate(i)
    trackDeliveryDateClick({ origin: EVENT_ORIGIN.DASHBOARD, customerNo, day })
    expandTile()
  }

  return (
    <ContentContainer>
      <DaysContainer>
        {deliveries.map((d, i) => (
          <DeliveryDay
            key={d.date}
            deliveryDay={d}
            isToday={i === 0}
            isSelected={i === selectedDate}
            onClick={() => handleDaySelect(i, d)}
          />
        ))}
      </DaysContainer>
      {selectedDate > -1 && <DeliveryDayDetails routes={selectedDateRoutes} />}
    </ContentContainer>
  )
}

export default TileContent
