import { rtkApi } from 'services/api/rtkApi'

import createFormData from 'utils/createFormData'

const RTK_SETTINGS_TAG = 'Settings'

export const settingsApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_SETTINGS_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getSettings: build.query({
        query: () => ({
          url: 'me/settings',
        }),
        providesTags: [RTK_SETTINGS_TAG],
      }),
      updateSettings: build.mutation({
        query: data => ({
          url: 'me/settings',
          method: 'PUT',
          data,
        }),
        invalidatesTags: [RTK_SETTINGS_TAG],
      }),
      sendUserFeedbackForm: build.mutation({
        query: ({ formValues }) => ({
          url: 'me/feedbacks',
          method: 'POST',
          data: createFormData({ data: formValues }),
        }),
      }),
    }),
  })

export const {
  useLazyGetSettingsQuery,
  useUpdateSettingsMutation,
  useSendUserFeedbackFormMutation,
} = settingsApi

export default settingsApi
