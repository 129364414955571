import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getSpacing, getColors } from 'components/helpers/theme-selectors'
import { H5Strong } from 'components/Text'

export const PageContainer = styled.div`
  padding: ${props => getSpacing(props).md};
  width: 100%;
`

export const ContactsContainer = styled.div`
  display: grid;
  padding-top: ${props => getSpacing(props).base};
  grid-column-gap: ${props => getSpacing(props).base};
  grid-row-gap: ${props => getSpacing(props).base};
  grid-template-columns: repeat(auto-fit, minmax(326px, 1fr));
`

export const ContactBox = styled(Flex)`
  flex-direction: column;
  border: solid 1px rgba(221, 226, 229, 0.5);
  background-color: ${props => getColors(props).white};
  padding: ${props => getSpacing(props).base};
  height: 132px;
`

export const ContactTitle = styled(H5Strong)`
  margin-bottom: ${props => getSpacing(props).sm};
`

export const PhoneContainer = styled.div`
  margin-bottom: ${props => getSpacing(props).ss};
`
