import styled from 'styled-components'

import theme from 'theme'

import { TinyTextV2, BaseTextV2 } from 'components/Text/texts'

export const Brand = styled(TinyTextV2)`
  color: ${theme.colors.lightGrey4};
`

export const Title = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})``
