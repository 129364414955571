import { Flex } from '@rebass/grid'
import styled from 'styled-components'

import theme from 'theme'
import { FlexColumns, Columns } from 'layouts'

import { APP_BREAKPOINTS } from 'consts'

export const ProductSection = styled(FlexColumns)`
  flex-direction: column;
`

export const ProductsWrapper = styled(Columns)``

export const ProductsGroup = styled.div`
  width: 100%;

  @media (min-width: 640px) {
    border: solid 1px rgba(221, 226, 229, 0.5);
  }
`

export const InactiveListContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  border-top: 1px solid ${theme.colors.gray2};
`

export const RemoveAllUnavailable = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.gray2};
  border-bottom: 1px solid ${theme.colors.gray2};
  padding: ${theme.spacing.base} 0;

  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    flex-direction: column;
    border-bottom: none;
  }
`
