import styled from 'styled-components'

import { BaseTextV2 } from 'components/Text'

import theme from 'theme'

export const AlertText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  flex-grow: 1;
  color: ${theme.colors.white};
`
