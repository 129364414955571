import React from 'react'

import PortalModal from 'components/modal/PortalModal'
import ContactForm from '../index'

const ContactFormModal = ({
  isOpen,
  onClose,
  headingText,
  descriptionText,
  isTopicField,
  hasFileSelector,
  onSubmit,
  submitDisabled,
  submitError,
}) => (
  <PortalModal isOpen={isOpen}>
    <ContactForm
      onClose={onClose}
      headingText={headingText}
      descriptionText={descriptionText}
      isTopicField={isTopicField}
      hasFileSelector={hasFileSelector}
      onSubmit={onSubmit}
      submitDisabled={submitDisabled}
      submitError={submitError}
    />
  </PortalModal>
)

export default ContactFormModal
