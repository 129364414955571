import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '../../messages'
import { CreateTemplateWrapper, CreateTemplateToggle } from './styles'

const CreateTemplate = ({ onClick, testId = 'create-template-toggle' }) => (
  <CreateTemplateWrapper>
    <CreateTemplateToggle onClick={onClick} data-test-id={testId}>
      <FormattedMessage {...messages.newTemplate} />
    </CreateTemplateToggle>
  </CreateTemplateWrapper>
)

export default CreateTemplate
