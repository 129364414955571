import { makeMessages, makeTranslation } from 'utils'

const messages = {
  contact: 'header.contact',
  invoice: 'header.invoice',
  delivery: 'header.delivery',
  customerNo: 'customerNo',
  name: 'name',
  login: 'login',
  email: 'email',
  tel: 'tel',
  company: 'company',
  taxNo: 'taxNo',
  address: 'address',
}

export default makeMessages(messages, makeTranslation('profile.data'))
