import React from 'react'

import theme from 'theme'

const Store = ({ color = theme.colors.gray5 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15V20H8V15Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 8V8.5C7 9.88071 5.88071 11 4.5 11V11C3.11929 11 2 9.88071 2 8.5V7L5 4H19L22 7V8.5C22 9.88071 20.8807 11 19.5 11V11C18.1193 11 17 9.88071 17 8.5V8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 8V8.5C7 9.88071 8.11929 11 9.5 11V11C10.8807 11 12 9.88071 12 8.5V8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4V8.5C12 9.88071 13.1193 11 14.5 11V11C15.8807 11 17 9.88071 17 8.5V8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17 8L15 4" stroke={color} strokeWidth="2" />
    <path d="M7 8L9 4" stroke={color} strokeWidth="2" />
    <path
      d="M4 11V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V11"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Store
