import React from 'react'
import { Box, Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { prop } from 'lodash/fp'

import theme from 'theme'
import { formatPrice } from 'utils'
import { useOfMaxWidth } from 'hooks'

import { selectTradeLimit } from 'containers/Orders/selectors'
import { ORDER_PAY_BUTTON_COLOR } from 'containers/Orders/consts'
import {
  invoicesPaginationSelector,
  invoicesPaymentDateSelector,
  totalInvoicesToPaySelecor,
  overdueInvoicesSelector,
} from 'containers/Invoices/List/selectors'
import { H4Primary } from 'components/Text'
import { invoicesSummarySelectors } from 'containers/Invoices/selectors'

import { APP_BREAKPOINTS } from 'consts'
import messages from 'views/Cart/messages'
import { usePaymentDate } from './utils'
import { HeadingColumn, TailColumn, InfoRow } from '../components'

import PayInvoicesButton from './PayInvoicesButton'
import { InfoContainer, PaddedColumn, RowContainer } from './styles'

const { spacing } = theme

const FinanceRow = () => {
  const { formatMessage } = useIntl()
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const isLowerThanMain = useOfMaxWidth(APP_BREAKPOINTS.TMP_MAIN - 1)
  const tradeLimitData = useSelector(selectTradeLimit)
  const invoicesPagination = useSelector(invoicesPaginationSelector)
  const unpaidInvoicesCount = prop('totalCount', invoicesPagination)
  const overdueInvoices = useSelector(overdueInvoicesSelector)
  const invoicesPaymentDate = useSelector(invoicesPaymentDateSelector)
  const invoicesTotal = useSelector(totalInvoicesToPaySelecor)
  const isGreenClient =
    tradeLimitData.paymentColorId === ORDER_PAY_BUTTON_COLOR.GREEN
  const invoicesSummary = useSelector(invoicesSummarySelectors.data)
  const overdueAmount = invoicesSummary?.overdueAmount
  const overdueState = overdueAmount > 0 && !isGreenClient
  const paymentDate = usePaymentDate({
    overdueAmount,
    overdueState,
    invoicesPaymentDate,
  })

  const payBtnRendered = tradeLimitData.payButtonVisibleInCart && (
    <PayInvoicesButton {...{ invoicesTotal, tradeLimitData, isGreenClient }} />
  )
  const creditLimitRendered = (
    <InfoRow
      label="yourCreditLimit"
      value={formatPrice(tradeLimitData.tradeLimit)}
    />
  )
  const unpaidInvoicesRendered = (
    <Flex flexDirection="column">
      <InfoRow
        label="invoicesAmount"
        labelOpts={{
          invoicesCount: unpaidInvoicesCount,
          invoices: formatMessage(messages.invoices, {
            number: unpaidInvoicesCount,
          }),
        }}
        value={formatPrice(invoicesTotal)}
      />

      {overdueState && (
        <InfoRow
          label="overdueInvoices"
          labelOpts={{
            count: overdueInvoices.length,
            overduePlural: formatMessage(messages.overduePlural, {
              number: overdueInvoices.length,
            }),
          }}
          value={formatPrice(overdueAmount)}
        />
      )}

      <InfoRow
        label={overdueState ? 'afterDeadline' : 'paymentDate'}
        value={paymentDate}
      />
    </Flex>
  )

  return (
    <RowContainer {...{ isLowerThanMain }}>
      <HeadingColumn
        pullTop
        mb={isLowerThanMain ? spacing.md : 0}
        px={isMobile ? spacing.sm : 0}
      >
        <H4Primary>{formatMessage(messages.finances)}</H4Primary>
      </HeadingColumn>

      <InfoContainer {...{ isMobile }}>
        {isMobile ? (
          <>
            <Box p={spacing.sm}>
              {creditLimitRendered}
              {unpaidInvoicesCount > 0 && unpaidInvoicesRendered}
            </Box>

            <Box mt={spacing.xs}>{payBtnRendered}</Box>
          </>
        ) : (
          <>
            <PaddedColumn
              borderedLeft={!isLowerThanMain}
              pl={isLowerThanMain ? 0 : spacing.base}
            >
              {creditLimitRendered}
            </PaddedColumn>

            <PaddedColumn borderedLeft>
              {unpaidInvoicesCount > 0 && unpaidInvoicesRendered}
            </PaddedColumn>

            <TailColumn>{payBtnRendered}</TailColumn>
          </>
        )}
      </InfoContainer>
    </RowContainer>
  )
}

export default FinanceRow
