import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

export const Arrow = styled.span`
  margin: auto 4px;
`

export const SpacingArrow = () => (
  <Flex ml="2px" mr="5px">
    <Arrow>&gt;</Arrow>
  </Flex>
)
