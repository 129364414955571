import React from 'react'
import Switch from 'react-switch'

import theme from 'theme'

const Toggle = ({ testId, ...rest }) => (
  <Switch
    checkedIcon={null}
    uncheckedIcon={null}
    onColor={theme.colors.primary}
    activeBoxShadow={`0 0 1px 1px ${theme.colors.primary}`}
    width={52}
    {...rest}
    data-test-id={testId}
  />
)

export default Toggle
