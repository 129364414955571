import { makeMessages, makeTranslation } from 'utils'

const messages = {
  missingItemsError: 'missingItemsError',
  itemsHeader: 'itemsHeader',
  orderFailed: 'orderFailed',
  amountForProductsChanged: 'amountForProductsChanged',
  checkProducts: 'checkProducts',
  '200': 'missingItemError.200',
  '210': 'missingItemError.210',
  '215': 'missingItemError.215',
  '225': 'missingItemError.225',
  '230': 'missingItemError.230',
  default: 'missingItemError.default',
}

export default makeMessages(messages, makeTranslation('cart'))
