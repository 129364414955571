import React from 'react'

import theme from 'theme'

const SignOut = ({ color = theme.colors.gray5 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.5L14 7C14 5.34315 12.6569 4 11 4L5 4C3.34315 4 2 5.34315 2 7L2 17C2 18.6569 3.34315 20 5 20L11 20C12.6569 20 14 18.6569 14 17L14 16.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M19 16L23 12L19 8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 12L14 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default SignOut
