import { makeMessages, makeTranslation } from 'utils'

const messages = {
  cartUpdated: 'cartUpdated',
  addedToCart: 'addedToCart',
  removedFromCart: 'removedFromCart',
  addedToCartNewDelivery: 'addedToCartNewDelivery',
  maxAvailableAmount: { id: 'nonStock.maxAvailableAmount' },
}

export default makeMessages(messages, makeTranslation('product'))
