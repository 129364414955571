import { fromJS } from 'immutable'
import _get from 'lodash/get'
import _findIndex from 'lodash/findIndex'
import _concat from 'lodash/concat'

export const initialStateSchema = {
  isFetching: false,
  error: null,
  data: null,
  finished: false,
}

export const initialState = fromJS(initialStateSchema)

export const createReducer = signalAction => (state, action) =>
  reducer(state, action, signalAction)

const findUpdatePath = state => {
  const dataPath = ['data']
  const itemsPath = ['data', 'items']
  const productsPath = ['data', 'products']

  if (state.hasIn(itemsPath)) {
    return itemsPath
  }

  if (state.hasIn(productsPath)) {
    return productsPath
  }

  return dataPath
}

/* eslint-disable indent */
export function reducer(state = initialState, action, signalAction) {
  // TODO remove logic from reducer
  const idKey = _get(action, 'additionalData.idKey', 'id')
  const itemId = _get(action, `additionalData.${idKey}`)
  const updatePath = findUpdatePath(state)
  const itemIndex = itemId
    ? _findIndex(
        state.getIn(updatePath) ? state.getIn(updatePath).toJS() : {},
        [idKey, itemId],
      )
    : -1
  switch (action.type) {
    case signalAction.REQUEST:
      return state
        .set('isFetching', true)
        .set('finished', false)
        .set('error', null)
    case signalAction.FAILURE:
      return state
        .set('isFetching', false)
        .set('finished', true)
        .set('error', action.data)
    case signalAction.SUCCESS: {
      const newState = state.set('isFetching', false).set('finished', true)

      return newState.set('data', fromJS(action.data))
    }
    case signalAction.CREATE: {
      const newState = state.set('isFetching', false).set('finished', true)

      return newState.update('data', data =>
        fromJS(_concat(data ? data.toJS() : [], action.data)),
      )
    }
    case signalAction.UPDATE: {
      const newState = state.set('isFetching', false).set('finished', true)

      // don't update if item not found
      if (itemIndex === -1) return newState

      return newState.setIn([...updatePath, itemIndex], fromJS(action.data))
    }
    case signalAction.DELETE: {
      const newState = state.set('isFetching', false).set('finished', true)

      // don't delete if item not found
      if (itemIndex === -1) return newState

      return newState.deleteIn([...updatePath, itemIndex])
    }
    case signalAction.CANCEL:
      return state.set('isFetching', false).set('finished', false)
    case signalAction.CLEAR:
      return initialState
    default:
      return state
  }
}

export default { initialState, createReducer, reducer }
