import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getSpacing } from 'components/helpers/theme-selectors'
import { H4 } from 'components/Text'

export const Amount = styled(H4).attrs({ bold: true })`
  white-space: nowrap;
`

export const PriceBlock = styled(Flex)`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-left: ${props => getSpacing(props).md};
`

export const PriceBlockTiny = styled(PriceBlock)`
  text-align: right;

  ${Amount} {
    font-size: 16px;
    line-height: 19px;
  }
`
