import * as React from 'react'

import theme from 'theme'

const Bookmark = ({
  color = theme.colors.gray5,
  fill = 'none',
  width = 16,
  height = 16,
  ...rest
}) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M3 2L13 2L13 15L8 11L3 15L3 2Z"
      stroke={color}
      fill={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Bookmark
