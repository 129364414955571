import { rtkApi } from 'services/api/rtkApi'

import { ORDERS_PER_PAGE_SIZE } from 'containers/Orders'

export const RTK_ORDERS_LIST_TAG = 'OrdersList'

const ordersListApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_ORDERS_LIST_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getOrders: build.query({
        query: ({ perPage = ORDERS_PER_PAGE_SIZE, page = 1 } = {}) => ({
          url: 'me/orders',
          params: { perPage, page },
        }),
        providesTags: [RTK_ORDERS_LIST_TAG],
      }),
    }),
  })

export const { useGetOrdersQuery, useLazyGetOrdersQuery } = ordersListApi
