import React from 'react'

import CatalogProductLicense from 'components/Product/ProductLicense/CatalogProductLicense'
import CatalogReplacements from './CatalogReplacements'

import { ReplacementContent } from './styles'

const CatalogOutOfStock = ({
  onGetReplacements,
  product,
  unitData,
  isSoonAvailable,
}) => (
  <ReplacementContent>
    {product.licenseMissing ? (
      <CatalogProductLicense {...{ product }} />
    ) : (
      <CatalogReplacements
        onReplacementsClick={() => onGetReplacements({ productId: product.id })}
        dataTestId={`replacements_${product.id}`}
        product={product}
        unitData={unitData}
        notificationOptionEnabled
        isSoonAvailable={isSoonAvailable}
      />
    )}
  </ReplacementContent>
)

export default CatalogOutOfStock
