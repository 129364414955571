import React from 'react'
import { useSelector } from 'react-redux'
import { Flex } from '@rebass/grid'
import { useMediaQuery } from 'react-responsive'
import { useIntl } from 'react-intl'

import theme, { paymentTheme } from 'theme'

import { useRichFormatMessage } from 'utils'
import { isFetchingSelector } from 'hocs/selectors'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { orderInfoSelector } from 'containers/Orders/selectors'
import { ORDER_INFO_REDUCER_NAME } from 'containers/Orders'
import LoaderFullHeight from 'components/LoaderFullHeight'

import { APP_BREAKPOINTS } from 'consts'

import {
  OuterWrapper,
  Section,
  Separator,
  Wrapper,
  SecondaryHeader,
  BoldHeader,
  SummaryFloatingPriceInfo,
} from './styles'
import Deliveries from './Deliveries'
import AmountsSummary from './AmountsSummary'
import BillingData from './BillingData'
import ProductsSection from './ProductsSection'
import messages from './messages'

const { colors, spacing } = theme
const { yellow } = paymentTheme

const OrderSummary = () => {
  const userData = useSelector(userDataSelector)
  const {
    status,
    totalGross,
    totalNet,
    anyFloatingPrice,
    orders = [],
  } = useSelector(orderInfoSelector)
  const { formatMessage } = useIntl()
  const formatHTMLMessage = useRichFormatMessage()
  const { fetching } = useSelector(isFetchingSelector(ORDER_INFO_REDUCER_NAME))

  const isSuccess = status === 'success'
  const mainColor = isSuccess ? colors.secondary : yellow.text
  const isMobile = useMediaQuery({
    query: `(max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px)`,
  })
  const deliveriesRendered = (
    <Deliveries {...{ orders, mainColor, isMobile }} {...userData} />
  )
  const amountsSummaryRendered = (
    <AmountsSummary
      {...userData}
      mainColor={mainColor}
      isSuccess={isSuccess}
      totalGross={totalGross}
      totalNet={totalNet}
      isMobile={isMobile}
      anyFloatingPrice={anyFloatingPrice}
    />
  )
  const billingDataRendered = <BillingData {...userData} {...{ isMobile }} />

  return (
    <OuterWrapper>
      <Wrapper>
        <Section bg={colors.white} data-test-id="summary-main-section">
          <Flex flexDirection="column" my={isMobile ? spacing.sm : 0}>
            <BoldHeader color={mainColor}>
              {isSuccess
                ? formatMessage(messages.orderPlaced)
                : formatMessage(messages.waitingForApprovalHeader)}
            </BoldHeader>
            {!isSuccess && (
              <SecondaryHeader>
                {formatHTMLMessage(messages.waitingForApprovalInfo)}
              </SecondaryHeader>
            )}
          </Flex>

          {isMobile ? (
            <Flex m="-8px" mt="24px">
              {deliveriesRendered}
            </Flex>
          ) : (
            <>
              <Flex mt={48}>
                {deliveriesRendered}
                {amountsSummaryRendered}
              </Flex>
              {anyFloatingPrice && (
                <SummaryFloatingPriceInfo
                  $isDesktop
                  infoText={formatMessage(messages.floatingPriceInfo)}
                />
              )}

              <Separator />

              {billingDataRendered}
            </>
          )}
        </Section>

        {isMobile && (
          <>
            <Section>{amountsSummaryRendered}</Section>

            <Section>{billingDataRendered}</Section>
          </>
        )}

        <ProductsSection {...{ orders, isMobile }} />
      </Wrapper>
      {fetching && <LoaderFullHeight />}
    </OuterWrapper>
  )
}

export default OrderSummary
