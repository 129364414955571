import { createSelector } from 'reselect'
import { flow, find, get, getOr } from 'lodash/fp'

import { genericSelector, makePaginationSelector } from 'utils/redux-utils'

import {
  CAMPAIGNS_REDUCER_NAME,
  CAMPAIGNS_ITEMS_REDUCER_NAME,
  CAMPAIGN_DETAILS_REDUCER_NAME,
  CAMPAIGN_PRODUCTS_REDUCER_NAME,
} from './consts'

export const { data: selectCampaignsData } = genericSelector([
  CAMPAIGNS_REDUCER_NAME,
  CAMPAIGNS_ITEMS_REDUCER_NAME,
])
export const campaignsSelector = createSelector(
  selectCampaignsData,
  data => data || [],
)

export const { data: selectCampaignDetailsData } = genericSelector([
  CAMPAIGNS_REDUCER_NAME,
  CAMPAIGN_DETAILS_REDUCER_NAME,
])
export const campaignDetailsSelector = createSelector(
  selectCampaignDetailsData,
  data => data || [],
)

export const { data: selectCampaignProductsData } = genericSelector([
  CAMPAIGNS_REDUCER_NAME,
  CAMPAIGN_PRODUCTS_REDUCER_NAME,
])
export const campaignProductsSelector = createSelector(
  selectCampaignProductsData,
  data => data || [],
)

export const campaignProductsPaginationSelector = makePaginationSelector(
  selectCampaignProductsData,
)

export const campaignProductsItemsSelector = createSelector(
  selectCampaignProductsData,
  getOr([], 'products'),
)

export const makeCampaignProductByIdSelector = id =>
  createSelector(
    campaignProductsSelector,
    flow(
      get('products'),
      find({ id }),
    ),
  )
