import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getSpacing, getColors } from 'components/helpers/theme-selectors'

import { P } from 'components/Text'

export const Error = styled(Flex)`
  background-color: ${props => getColors(props).error};
  height: 48px;
  align-items: center;
  padding: 0 ${props => getSpacing(props).sm};
  margin-bottom: ${props => getSpacing(props).base};
`

export const ErrorMessage = styled(P)`
  color: ${props => getColors(props).white};
  padding-left: ${props => getSpacing(props).sm};
`
