export const confirmNewsletterUnsubscription = ({ token }) => {
  const options = {
    method: 'DELETE',
    body: { token },
  }

  return {
    url: `/newsletter/subscriptions`,
    options,
  }
}
