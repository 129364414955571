import React from 'react'

import theme from 'theme'
import { Heart } from 'components/Icons'

import TemplateListItem from './TemplateListItem'
import { BidfoodTemplateListItem, BidfoodTemplateIconContainer } from './styles'

const BidfoodTemplate = ({ template, closeDropdown }) => {
  if (template) {
    const { name, id, itemsCount } = template

    return (
      <TemplateListItem
        name={name}
        id={id}
        itemsCount={itemsCount}
        onClose={closeDropdown}
        icon={
          <BidfoodTemplateIconContainer>
            <Heart color={theme.colors.secondary} />
          </BidfoodTemplateIconContainer>
        }
        ContentContainer={BidfoodTemplateListItem}
      />
    )
  }

  return null
}

export default BidfoodTemplate
