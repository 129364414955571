import React from 'react'

import theme from 'theme'

const History = ({ color = theme.colors.gray5 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 18.9282C9.52513 19.8087 11.2982 20.1614 13.0442 19.9316C14.7902 19.7017 16.4116 18.9021 17.6569 17.6569C18.9021 16.4116 19.7017 14.7902 19.9316 13.0442C20.1614 11.2982 19.8087 9.52514 18.9282 8C18.0477 6.47487 16.6885 5.2829 15.0615 4.60896C13.4344 3.93503 11.6305 3.81679 9.92945 4.27259C8.22838 4.72839 6.72525 5.73275 5.65317 7.12991C4.5811 8.52706 4 10.2389 4 12L4 14"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12 8L12 12L14 14"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12L4 15L1 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default History
