import { makeMessages, makeTranslation } from 'utils'

const messages = {
  changePassword: 'changePassword',
  deleteAccount: 'deleteAccount',
  deleteAccountPending: 'deleteAccountPending',
  contactForm: { id: 'contactForm.title' },
}

export default makeMessages(messages, makeTranslation('profile.other'))
