import React from 'react'

import theme from 'theme'

const Logistic = ({
  primaryColor = theme.colors.primary,
  secondaryColor = theme.colors.gray5,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.277 5.24703C2.30335 5.15205 2.34816 5.0632 2.40887 4.98555C2.46958 4.90791 2.54499 4.84299 2.63081 4.79451C2.71662 4.74603 2.81115 4.71494 2.90899 4.70302C3.00683 4.69109 3.10606 4.69857 3.201 4.72503L4.904 5.19703C5.35534 5.3196 5.76723 5.55703 6.09951 5.88615C6.43179 6.21528 6.67313 6.62488 6.8 7.07503L8.951 14.861L9.109 15.408C9.74676 15.6431 10.2842 16.0905 10.631 16.675L10.941 16.579L19.811 14.274C19.9063 14.2492 20.0056 14.2434 20.1032 14.257C20.2008 14.2705 20.2948 14.3032 20.3797 14.353C20.4647 14.4029 20.539 14.469 20.5984 14.5476C20.6578 14.6262 20.7012 14.7157 20.726 14.811C20.7508 14.9064 20.7566 15.0057 20.7431 15.1032C20.7295 15.2008 20.6969 15.2948 20.647 15.3797C20.5971 15.4647 20.531 15.539 20.4524 15.5984C20.3739 15.6579 20.2843 15.7012 20.189 15.726L11.352 18.022L11.022 18.124C11.016 19.394 10.139 20.556 8.81201 20.9C7.222 21.314 5.587 20.398 5.161 18.856C4.735 17.313 5.679 15.727 7.269 15.314C7.34735 15.2938 7.4264 15.2765 7.506 15.262L5.354 7.47403C5.29607 7.27394 5.18735 7.09225 5.0384 6.94663C4.88945 6.80101 4.70535 6.69642 4.504 6.64303L2.8 6.17003C2.70505 6.14378 2.61619 6.09908 2.53852 6.03849C2.46084 5.97789 2.39586 5.90259 2.34729 5.81687C2.29872 5.73116 2.26752 5.63672 2.25546 5.53894C2.2434 5.44117 2.25072 5.34197 2.277 5.24703Z"
      fill={primaryColor}
    />
    <path
      d="M9.56397 8.73L10.079 10.593C10.564 12.348 10.806 13.226 11.519 13.625C12.232 14.025 13.137 13.789 14.947 13.319L16.867 12.819C18.677 12.349 19.582 12.114 19.994 11.423C20.406 10.731 20.164 9.853 19.678 8.098L19.164 6.236C18.679 4.48 18.436 3.602 17.724 3.203C17.01 2.803 16.105 3.039 14.295 3.51L12.375 4.008C10.565 4.478 9.65997 4.714 9.24897 5.406C8.83697 6.097 9.07897 6.975 9.56397 8.73Z"
      fill={secondaryColor}
    />
  </svg>
)

export default Logistic
