import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { getSpacing } from 'components/helpers/theme-selectors'
import { APP_BREAKPOINTS } from 'consts'

export const Content = styled(Flex)`
  flex-flow: column;
  flex: 1;
  margin-left: ${props => getSpacing(props).base};
`

export const ImageContainer = styled(Flex)`
  min-width: 104px;
  max-width: 104px;
  min-height: 104px;
  max-height: 104px;
`

export const Container = styled(Flex)`
  border-top: 1px solid ${theme.colors.fadedGray};
  padding-top: ${theme.spacing.base};
  margin-bottom: ${theme.spacing.base};

  @media screen and (max-width: 505px) {
    flex-flow: column;

    ${Content} {
      margin-left: 0;
    }

    ${ImageContainer} {
      align-self: center;
      margin-bottom: ${props => getSpacing(props).base};
    }
  }
`

export const PrimarySection = styled.div`
  max-width: 370px;
  width: 100%;
  margin-bottom: 10px;
`

export const ReplacementsBtnContainer = styled.div`
  margin-left: ${theme.spacing.md};
  margin-top: auto;

  @media screen and (max-width: 505px) {
    margin-left: auto;
  }
`

export const PricingSection = styled(Flex)`
  margin-top: ${props => getSpacing(props).xs};

  @media screen and (max-width: 667px) {
    flex-flow: column;

    ${ReplacementsBtnContainer} {
      margin-top: ${props => getSpacing(props).base};
      width: 100%;

      > button {
        width: 100%;
      }
    }
  }
`

export const UnitPriceContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`

export const AvailabilityContainer = styled.div`
  margin-bottom: ${props => getSpacing(props).ss};
`

export const FieldWrapper = styled.label`
  height: 48px;
  width: calc(50% - 12px);
  display: flex;
  align-items: center;
  align-self: flex-end;
  background-color: #ebeef1;
  padding: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.base};
  user-select: none;

  > :first-child {
    margin-right: ${theme.spacing.sm};
  }

  :hover {
    background-color: #e3e6ea;
  }

  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    margin-bottom: ${theme.spacing.sm};
    width: 100%;
  }
`

export const RadioGroupWrapper = styled(Flex)`
  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    flex-direction: column;

    > :first-child {
      margin-bottom: ${theme.spacing.xs};
    }
  }
`
