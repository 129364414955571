import React from 'react'

const Breakfast = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6351 14.6667C12.4388 14.6667 13.2096 14.3331 13.7778 13.7392C14.3461 13.1454 14.6654 12.3399 14.6654 11.5001L14.6654 5.16673C14.6654 4.5021 14.4653 3.85432 14.0934 3.31514C13.7215 2.77597 13.1967 2.37274 12.5934 2.16256C11.99 1.95239 11.3386 1.94593 10.7316 2.1441C10.1245 2.34227 9.59246 2.73502 9.21086 3.26673L2.54419 3.26673C2.22272 3.26673 1.91441 3.40018 1.68709 3.63772C1.45978 3.87527 1.33207 4.19745 1.33207 4.53339L1.33207 12.1334C1.33207 12.4693 1.45978 12.7915 1.68709 13.0291C1.91441 13.2666 2.22272 13.4001 2.54419 13.4001L9.21086 13.4001C9.49247 13.7941 9.85834 14.1138 10.2793 14.3338C10.7002 14.5537 11.1645 14.6677 11.6351 14.6667Z"
      fill="#8E9BA8"
    />
    <path
      d="M11.6351 12.6667C12.4388 12.6667 13.2096 12.3682 13.7778 11.8368C14.3461 11.3055 14.6654 10.5848 14.6654 9.83337L14.6654 4.16671C14.6654 3.57204 14.4653 2.99245 14.0934 2.51003C13.7215 2.02761 13.1967 1.66682 12.5934 1.47877C11.99 1.29072 11.3386 1.28494 10.7316 1.46225C10.1245 1.63956 9.59246 1.99097 9.21086 2.46671L2.54419 2.46671C2.22272 2.46671 1.91441 2.58611 1.68709 2.79865C1.45978 3.01119 1.33207 3.29946 1.33207 3.60004L1.33207 10.4C1.33207 10.7006 1.45978 10.9889 1.68709 11.2014C1.91441 11.414 2.22272 11.5334 2.54419 11.5334L9.21086 11.5334C9.49247 11.8859 9.85834 12.172 10.2793 12.3688C10.7002 12.5656 11.1645 12.6676 11.6351 12.6667Z"
      fill="#D8DFE7"
    />
    <rect
      x="3"
      y="4"
      width="4"
      height="4"
      rx="2"
      fill="#768AA5"
      fillOpacity="0.8"
    />
    <rect
      x="9"
      y="6"
      width="4"
      height="4"
      rx="2"
      fill="#768AA5"
      fillOpacity="0.8"
    />
  </svg>
)

export default Breakfast
