import React, { useState } from 'react'

import { trackSimpleEvent } from 'services/analytics'
import { H4v2 } from 'components/Text'
import DropdownArrow from '../../DropdownArrow'
import {
  DropdownContainer,
  DropdownTitleContainer,
  DropdownWrapper,
  DropdownDescription,
  ArrowContainer,
  DropdownSwitch,
  SwitchSlider,
} from './styles'

const Dropdown = ({
  testId,
  title,
  description,
  onSwitch,
  data: { readonly, value = false } = {},
  dropdownEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleDropdown = () => {
    setIsOpen(state => !state)
    trackSimpleEvent(dropdownEvent)
  }

  const handleSwitch = () => {
    !readonly && onSwitch(!value)
  }

  return (
    <DropdownWrapper data-test-id={testId}>
      <DropdownContainer onClick={handleDropdown}>
        <DropdownTitleContainer>
          <ArrowContainer>
            <DropdownArrow isOpen={isOpen} />
          </ArrowContainer>
          <H4v2>{title}</H4v2>
        </DropdownTitleContainer>
        <DropdownSwitch>
          <input type="checkbox" checked={value} onChange={handleSwitch} />
          <SwitchSlider
            data-test-id="profile-dropdown-switch"
            isChecked={value}
            {...{ readonly }}
          />
        </DropdownSwitch>
      </DropdownContainer>
      {isOpen && <DropdownDescription>{description}</DropdownDescription>}
    </DropdownWrapper>
  )
}

export default Dropdown
