import { takeLatest, call, put } from 'redux-saga/effects'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import {
  resetPassword,
  confirmResetPassword,
  validateToken,
} from 'containers/Auth/api'
import { genericGetDataEnhanced } from 'containers/App/sagas'

import {
  resetPasswordActions,
  confirmResetPasswordActions,
  validateResetPasswordTokenActions,
} from './actions'

export function* resetPasswordFlow({
  data: { login },
  additionalData: { successCallback },
}) {
  try {
    yield call(
      genericGetDataEnhanced,
      {
        actions: resetPasswordActions,
        request: resetPassword,
        params: {
          body: { login },
        },
      },
      { rethrow404: true },
    )
    yield call(successCallback)
  } catch (err) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    console.error('Error applying for password reset', err)
  }
}

export function* confirmResetPasswordFlow({
  data: { password, token },
  additionalData: { successCallback },
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: confirmResetPasswordActions,
      request: confirmResetPassword,
      params: {
        body: { password, token },
      },
    })
    yield call(successCallback)
  } catch (err) {
    console.error(err)
  }
}

export function* validateResetPasswordTokenFlow({ data: { token } }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: validateResetPasswordTokenActions,
      request: validateToken,
      params: {
        body: { token },
      },
    })
  } catch (err) {
    // handled by using custom components per error
  }
}

export default [
  takeLatest(resetPasswordActions.DELTA, resetPasswordFlow),
  takeLatest(confirmResetPasswordActions.DELTA, confirmResetPasswordFlow),
  takeLatest(
    validateResetPasswordTokenActions.DELTA,
    validateResetPasswordTokenFlow,
  ),
]
