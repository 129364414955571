import { takeLatest, call, put, select } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { openModal } from 'containers/App/actions/modal'
import {
  ITEM_LIST_IDS,
  ITEM_LIST_NAMES,
  CATALOG_EVENTS,
  trackViewItemList,
  trackSearchProductsEvent,
} from 'services/analytics'

import filtersWatchers from './Filters/sagas'
import replacementsWatchers from './Replacements/sagas'
import { getProducts } from './api'
import actions from './actions'

export function* getProductsFlow({ data } = {}) {
  try {
    const userData = yield select(userDataSelector)
    const response = yield call(genericGetDataEnhanced, {
      actions,
      request: getProducts,
      params: data,
    })

    if (!response) return

    if (data.search) {
      if (response.meta.pagination.totalCount === 0) {
        trackSearchProductsEvent({
          event: CATALOG_EVENTS.EMPTY_SEARCH_RESULTS,
          query: data.search,
          userData,
        })
      }

      trackSearchProductsEvent({
        event: CATALOG_EVENTS.VIEW_SEARCH_RESULTS,
        query: data.search,
        userData,
      })
    }

    trackViewItemList({
      products: response.products,
      itemListName: data.search
        ? ITEM_LIST_NAMES.SEARCH_RESULTS
        : ITEM_LIST_IDS.CATALOG,
      itemListId: data.search
        ? ITEM_LIST_IDS.SEARCH_RESULTS
        : ITEM_LIST_IDS.CATALOG,
    })
  } catch (error) {
    console.log('Error getting products', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export const productsWatchers = [
  takeLatest(actions.DELTA, getProductsFlow),
  ...filtersWatchers,
  ...replacementsWatchers,
]
