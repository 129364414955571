import React, { useCallback, useEffect, useMemo } from 'react'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { reject } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import LoaderFullHeight from 'components/LoaderFullHeight'
import { ROUTES } from 'consts'
import {
  isUserDataFetching,
  userDataSelector,
} from 'containers/UserInfo/selectors'
import { FixedBack } from 'components/Back'

import { useLazyGetLocationsQuery } from 'containers/Locations/rtkApi'
import { useLazyGetSettingsQuery } from 'containers/Settings/rtkApi'
import cartActionsGroup from 'containers/Cart/actions'
import { Layout, BoxWrapper, Header } from './components/styledComponents'
import ChooseLocation from './components/ChooseLocation'
import Item from './components/Item'
import messages from './messages'

const ChangeLocation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { formatMessage } = useIntl()

  const userData = useSelector(userDataSelector)
  const { customerNo } = userData

  const [
    fetchLocations,
    { data: fullLocationList, isLoading: isLocationsLoading },
  ] = useLazyGetLocationsQuery()

  const [
    fetchSettings,
    { data: settings, isLoading: isSettingsLoading },
  ] = useLazyGetSettingsQuery()

  const setMode = pathname === ROUTES.SET_LOCATION

  const locations = useMemo(() => reject(fullLocationList, { customerNo }), [
    fullLocationList,
    customerNo,
  ])

  useEffect(() => {
    fetchLocations(undefined, true)
    fetchSettings(undefined, true)
  }, [])

  useEffect(
    () => {
      // clear outdated cart from store for users switching address
      dispatch(cartActionsGroup.clear())
    },
    [setMode],
  )

  const handleChosenLocationClick = useCallback(() => {
    navigate(ROUTES.CATEGORIES)
  }, [])

  const isUserDataLoading = useSelector(isUserDataFetching)
  const isLoading = isUserDataLoading || isSettingsLoading || isLocationsLoading

  // wait until fetch or locations retrieved from cache on mount
  if (isLoading || !locations.length) return <LoaderFullHeight />

  return (
    <Layout>
      {!setMode && <FixedBack />}
      <Flex flexDirection="column">
        <BoxWrapper mb="16px">
          <Header>
            {formatMessage(
              messages[setMode ? 'recentlyChosenAddress' : 'chosenAddress'],
            )}
          </Header>
          <Item
            data-test-id={customerNo}
            withoutBorder
            {...userData}
            onClick={handleChosenLocationClick}
          />
        </BoxWrapper>
        <ChooseLocation
          header={formatMessage(
            messages[setMode ? 'chooseAddress' : 'changeAddress'],
          )}
          locations={locations}
          settings={settings}
        />
      </Flex>
    </Layout>
  )
}

export default ChangeLocation
