import React from 'react'

const Pending = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="40"
    viewBox="0 0 32 40"
    fill="none"
  >
    <g clipPath="url(#clip0_28077_139245)">
      <path
        d="M1 4C1 2.34315 2.34315 1 4 1H28C29.6569 1 31 2.34315 31 4V31.3431C31 32.1388 30.6839 32.9019 30.1213 33.4645L25.4645 38.1213C24.9019 38.6839 24.1388 39 23.3431 39H4C2.34315 39 1 37.6569 1 36V4Z"
        stroke="#874C16"
        strokeOpacity="0.9"
        strokeWidth="2"
      />
      <path
        d="M23 39V33C23 31.8954 23.8954 31 25 31H31"
        stroke="#874C16"
        strokeOpacity="0.9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 23H22"
        stroke="#874C16"
        strokeOpacity="0.9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 27H22"
        stroke="#874C16"
        strokeOpacity="0.9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 13V8"
        stroke="#874C16"
        strokeOpacity="0.9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 31H11"
        stroke="#874C16"
        strokeOpacity="0.9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="6" cy="16" r="1" fill="#874C16" fillOpacity="0.9" />
    </g>
    <defs>
      <clipPath id="clip0_28077_139245">
        <rect width="32" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Pending
