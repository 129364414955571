import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2, BaseTextV2Medium } from 'components/Text'
import { PrimaryBtnV2 } from 'components/Button/styles'

export const LocationChangedModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.base};
`

export const LocationChangedModalText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})``

export const LocationChangedModalBtn = styled(PrimaryBtnV2)`
  padding: ${theme.spacing.sm};
`

export const LocationChangedModalBtnText = styled(BaseTextV2Medium)`
  color: ${theme.colors.white};
`
