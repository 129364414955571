import styled, { css } from 'styled-components'
import { BADGE_HEIGTH } from 'consts'
import { SecondaryText } from 'components/Text'

import { TAG_COLORS } from './consts'

import { getColors, getFonts, getTextSizes } from '../helpers/theme-selectors'

const getBackground = ({ color }) => {
  switch (color) {
    case TAG_COLORS.RED:
      return css`
        background: linear-gradient(
            0deg,
            ${props => getColors(props).error},
            ${props => getColors(props).error}
          ),
          ${props => getColors(props).white};
      `
    case TAG_COLORS.BLUE:
      return css`
        background: linear-gradient(
            0deg,
            ${props => getColors(props).info},
            ${props => getColors(props).info}
          ),
          ${props => getColors(props).white};
      `
    case TAG_COLORS.ORANGE:
    default:
      return css`
        background: linear-gradient(
            0deg,
            ${props => getColors(props).warning},
            ${props => getColors(props).warning}
          ),
          ${props => getColors(props).white};
      `
  }
}

const getFontColor = ({ color }) => {
  switch (color) {
    case TAG_COLORS.ORANGE:
      return css`
        color: ${props => getColors(props).black};
      `
    case TAG_COLORS.RED:
    case TAG_COLORS.BLUE:
    default:
      return css`
        color: ${props => getColors(props).white};
      `
  }
}

export const TagWrapper = styled.span`
  ${props => getBackground(props)};
  ${props => getFontColor(props)};
  font-family: ${props => getFonts(props).base};
  font-size: ${props => getTextSizes(props).sm};
  display: inline-block;
  border-radius: 0px 12px 12px 0px;
  padding: 4px 16px 4px 23px;
  height: ${BADGE_HEIGTH}px;
  line-height: 16px;
`

const getTextFontColor = ({ color }) => {
  switch (color) {
    case TAG_COLORS.ORANGE:
      return css`
        color: ${props => getColors(props).warning};
      `
    case TAG_COLORS.RED:
      return css`
        color: ${props => getColors(props).error};
      `
    case TAG_COLORS.BLUE:
    default:
      return css`
        color: ${props => getColors(props).info};
      `
  }
}

export const TagTextWrapper = styled(SecondaryText)`
  ${props => getTextFontColor(props)};
`
