import * as React from 'react'

import theme from 'theme'

const RouteIcon = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="route-icon"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M5 8v8.5A3.5 3.5 0 008.5 20v0a3.5 3.5 0 003.5-3.5v-7A3.5 3.5 0 0115.5 6v0A3.5 3.5 0 0119 9.5V18"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={5} cy={6} r={2} stroke={color} strokeWidth={2} />
    <path
      d="M16 17l3 3 3-3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RouteIcon
