export const getBreadcrumbsParams = ({ subgroupId, groupId, categoryId }) => {
  if (subgroupId) {
    return {
      subgroup: subgroupId,
    }
  }
  if (groupId) {
    return {
      group: groupId,
    }
  }
  if (categoryId) {
    return {
      category: categoryId,
    }
  }
  return null
}
