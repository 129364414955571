import React from 'react'
import { FormattedMessage } from 'react-intl'

import { NoReplacementsContainer, NoReplacementsTitle } from './style'
import messages from './messages'

const NoReplacements = () => (
  <NoReplacementsContainer>
    <NoReplacementsTitle>
      <FormattedMessage {...messages.noReplacements} />
    </NoReplacementsTitle>
  </NoReplacementsContainer>
)

export default NoReplacements
