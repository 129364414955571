import React, { useMemo, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from '@rebass/grid'
import { FormattedMessage, useIntl } from 'react-intl'
import { SwitchTransition, Transition } from 'react-transition-group'
import { get, sumBy, values, compose } from 'lodash/fp'
import isDeepEqual from 'react-fast-compare'
import { useModal } from '@ebay/nice-modal-react'

import theme from 'theme'
import { formatPrice, isElasticStock, useRichFormatMessage } from 'utils'
import { useOfMaxWidth } from 'hooks'
import {
  ITEM_LIST_IDS,
  ITEM_LIST_NAMES,
  useSelectItemTracking,
} from 'services/analytics'
import { makeDateChangingProductIdSelector } from 'containers/Cart/selectors'
import {
  getTotalPoints,
  isCatalogRewardProduct,
  isDiscountPromoType,
  isNonQuantitivePromoProduct,
  isQuantitivePromoType,
  isSalePromoType,
  isValueBasedPromoType,
} from 'containers/Promotions/utils'
import productContainer from 'containers/components/Product/ButtonsContainer'
import { updateDeliveryDate as updateDeliveryDateAction } from 'containers/Cart/actions'
import TemplateModal from 'components/modal/TemplateModal'
import TextTooltip from 'components/Tooltip/TextTooltip'

import { TertiaryText, TinyTextFaded } from 'components/Text'
import { InfoCircle, ArrowDownNew, ArrowUp } from 'components/Icons'
import DeliveryCalendar from 'views/Cart/CartProducts/DeliveryProducts/DeliveryTimeSlots/DeliveryCalendar'
import { APP_BREAKPOINTS } from 'consts'

import { FloatingPriceWrapper } from 'components/FloatingPrice'
import { getProductGenericInfo } from '../utils'

import {
  ContentWrapper,
  Prices,
  ProductWrapper,
  RightSideWrapper,
  InfoIconContainer,
  NetPrice,
  InnerRightWrapper,
  PricesAndInfoWrapper,
  StockConfirmContainer,
} from './styledComponents'
import ImageAndCtas from './ImageAndCtas'
import ActionButtons from './ActionButtons'
import VariantRow from './VariantRow'
import DetailsRow from './DetailsRow'
import MoveItemConfirm from './MoveItemConfirm'
import BrandAndName from './BrandAndName'
import SelfPromoBar from './Promotions/SelfPromoBar'
import PromoBar from './Promotions/PromoBar'
import { StarContainer } from './TemplateStar'
import { defaultStyle, transitionStyles } from './fadeInConsts'
import messages from './messages'

const moveTimeout = 1500

const InfoIconWrapper = ({ isMobile, children }) => {
  const { formatMessage } = useIntl()

  return isMobile ? (
    children
  ) : (
    <TextTooltip
      placement="left"
      content={formatMessage(messages.showProductDetails)}
    >
      {children}
    </TextTooltip>
  )
}

const CartProduct = ({
  product,
  product: { unitsOfMeasure, floatingPrice },
  productId,
  disablePrices,
  unitOfMeasure,
  units,
  unitsMap,
  inTemplate,
  toggleDateProductIdChanging,
  selectedDeliveryDate,
  selectedRouteId,
  borderTop,
  borderBottom,
  className,
  id,
  showDateHint,
  maxValue,
  disableInput,
  index,
}) => {
  const templateModal = useModal(TemplateModal)
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const dispatch = useDispatch()
  const changingDate = !!useSelector(
    makeDateChangingProductIdSelector(productId),
  )

  const handleStarClick = params => {
    templateModal.show({
      ...params,
      listIndex: index,
      itemListId: ITEM_LIST_IDS.CART,
      itemListName: ITEM_LIST_NAMES.CART,
    })
  }

  const updateDeliveryDate = data => dispatch(updateDeliveryDateAction(data))
  const unitsGroups = values(unitsMap)
  const hasMultipleVariants = unitsGroups.length > 1
  const totalNet = sumBy('totalPriceNet')(units)
  const totalGross = sumBy('totalPriceGross')(units)
  const isCatalogReward = isCatalogRewardProduct(product)
  const promotion = get('promotion')(product)
  const isQuantitivePromo = isQuantitivePromoType(promotion)
  const moveItemTimeoutRef = useRef()
  const { productPath, outOfStock, unitOfMeasureObj, unitObj } = useMemo(
    () =>
      getProductGenericInfo({
        product,
        disablePrices,
        unitOfMeasure,
        units,
      }),
    [product, units, unitOfMeasure, disablePrices],
  )
  const [moveItemHandler, setMoveItemHandler] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const toggleDetails = () => setShowDetails(!showDetails)
  const formatRichMessage = useRichFormatMessage()

  const isSalePromo = isSalePromoType(promotion)
  const isDiscountPromo = isDiscountPromoType(promotion)
  const isLoweredPrice = isSalePromo || isDiscountPromo
  const ArrowIcon = showDetails ? ArrowUp : ArrowDownNew
  const InfoIcon = hasMultipleVariants ? ArrowIcon : InfoCircle
  const toggleDateChanging = () => toggleDateProductIdChanging(productId)
  const showInfo = showDetails && !hasMultipleVariants
  const showVariants = showDetails && hasMultipleVariants
  const confirmStockRef = useRef(null)
  const [, forceUpdate] = useState()
  const isElastic = isElasticStock({
    stock: unitOfMeasureObj.stock,
    nonStock: product.nonStock,
  })

  const dateChangeCallback = useCallback(
    date => {
      const updateHandler = () => {
        updateDeliveryDate({
          deliveryDate: date,
          prevRouteId: selectedRouteId,
          productIds: [productId],
        })
        toggleDateChanging()
        setMoveItemHandler(false)
      }
      setMoveItemHandler(() => updateHandler)

      // eslint-disable-next-line no-param-reassign
      moveItemTimeoutRef.current = setTimeout(updateHandler, moveTimeout)
    },
    [selectedRouteId, productId],
  )

  const showCalendar = isMobile && changingDate
  const deliveryCalendarRendered = (
    <DeliveryCalendar
      {...isMobile && { justifyContent: 'space-between' }}
      selectedDeliveryDate={selectedDeliveryDate}
      productIds={[productId]}
      dateChangeHandler={dateChangeCallback}
    />
  )
  const infoRendered = showInfo && (
    <Flex width="100%">
      <DetailsRow
        {...{
          isSalePromo,
          isDiscountPromo,
          unitOfMeasureObj,
          unitObj,
          productId,
          promotion,
        }}
      />
    </Flex>
  )
  const variantsRendered = showVariants && (
    <Flex width="100%">
      <Flex flexDirection="column" width="100%">
        {unitsGroups.map(([unit]) => (
          <VariantRow
            key={unit.unitOfMeasure}
            unit={unit}
            product={product}
            productId={productId}
            unitsOfMeasure={unitsOfMeasure}
            onStarClick={handleStarClick}
            maxValue={maxValue}
            disableInput={disableInput}
          />
        ))}
      </Flex>

      {!isMobile && <StarContainer />}
    </Flex>
  )

  const trackSelectItem = useSelectItemTracking({
    product,
    itemListId: ITEM_LIST_IDS.CART,
    itemListName: ITEM_LIST_NAMES.CART,
    unitOfMeasure,
    index,
  })

  return (
    <Flex
      width="100%"
      flexDirection="column"
      bg={theme.colors.white}
      mb={isMobile && borderBottom ? theme.spacing.xs : 0}
    >
      <SwitchTransition {...{ className }}>
        <Transition key={moveItemHandler} timeout={100}>
          {state => (
            <Flex style={{ ...defaultStyle, ...transitionStyles[state] }}>
              <ProductWrapper
                {...{ id, borderTop }}
                borderBottom={
                  borderBottom &&
                  !isNonQuantitivePromoProduct(product) &&
                  !showCalendar
                }
              >
                <Flex height="100%">
                  {moveItemHandler ? (
                    <MoveItemConfirm
                      moveItemTimeout={moveItemTimeoutRef.current}
                      {...{
                        moveItemHandler,
                        setMoveItemHandler,
                        toggleDateChanging,
                      }}
                    />
                  ) : (
                    <>
                      <ImageAndCtas
                        productPath={productPath}
                        outOfStock={outOfStock}
                        changingDate={changingDate}
                        toggleDateChanging={toggleDateChanging}
                        showDateHint={showDateHint}
                        product={product}
                        onStarClick={handleStarClick}
                        unitOfMeasureObj={unitOfMeasureObj}
                        hasMultipleVariants={hasMultipleVariants}
                        onTrackProductClick={trackSelectItem}
                      />

                      <RightSideWrapper>
                        <ContentWrapper>
                          <BrandAndName
                            unitOfMeasureObj={unitOfMeasureObj}
                            changingDate={changingDate}
                            product={product}
                            productPath={productPath}
                            deliveryCalendarRendered={deliveryCalendarRendered}
                            onTrackProductClick={trackSelectItem}
                          />

                          <InnerRightWrapper>
                            <ActionButtons
                              hasMultipleVariants={hasMultipleVariants}
                              productId={productId}
                              product={product}
                              inTemplate={inTemplate}
                              unitOfMeasureObj={unitOfMeasureObj}
                              maxValue={maxValue}
                              disableInput={disableInput}
                              toggleDetails={toggleDetails}
                              dateChangeCallback={date =>
                                updateDeliveryDate({
                                  deliveryDate: date,
                                  prevRouteId: selectedRouteId,
                                  productIds: [productId],
                                })
                              }
                              addRemoveCallback={params => {
                                // as the component is memoised, using the hack to re-render
                                // when quntity has changed
                                if (isElastic) forceUpdate(params)
                              }}
                              confirmStockRef={confirmStockRef.current}
                              quantity={get('0.quantity')(units)}
                            >
                              {isElastic && (
                                <StockConfirmContainer ref={confirmStockRef} />
                              )}
                            </ActionButtons>

                            <PricesAndInfoWrapper>
                              <Prices>
                                <NetPrice
                                  data-test-id={`net_price_${productId}`}
                                  $isLoweredPrice={isLoweredPrice}
                                >
                                  <FloatingPriceWrapper
                                    containerStyle={{
                                      justifyContent: 'flex-end',
                                    }}
                                    hasFloatingPrice={floatingPrice}
                                  >
                                    {formatPrice(totalNet)}
                                  </FloatingPriceWrapper>
                                </NetPrice>

                                <TinyTextFaded>
                                  {formatRichMessage(messages.gross, {
                                    price: formatPrice(totalGross),
                                  })}
                                </TinyTextFaded>
                              </Prices>

                              <InfoIconContainer>
                                <InfoIconWrapper isMobile={isMobile}>
                                  <InfoIcon
                                    data-test-id={`info_btn_${productId}`}
                                    className="product-info"
                                    color={
                                      showDetails
                                        ? undefined
                                        : theme.colors.gray3
                                    }
                                    onClick={toggleDetails}
                                  />
                                </InfoIconWrapper>
                              </InfoIconContainer>
                            </PricesAndInfoWrapper>
                          </InnerRightWrapper>
                        </ContentWrapper>

                        {!isMobile && infoRendered}
                        {!isMobile && variantsRendered}
                      </RightSideWrapper>
                    </>
                  )}
                </Flex>

                {isMobile && infoRendered}
                {isMobile && variantsRendered}
              </ProductWrapper>
            </Flex>
          )}
        </Transition>
      </SwitchTransition>
      {showCalendar && (
        <Box px={theme.spacing.sm} pb={theme.spacing.sm}>
          <TertiaryText>
            <FormattedMessage {...messages.move} />:
          </TertiaryText>

          <Box mt={theme.spacing.sm}>{deliveryCalendarRendered}</Box>
        </Box>
      )}
      {promotion &&
        (isQuantitivePromo ||
        isCatalogReward ||
        isDiscountPromo ||
        isSalePromo ? (
          <SelfPromoBar {...{ units, promotion, product, isCatalogReward }} />
        ) : (
          <PromoBar
            {...{ promotion, unitOfMeasureObj }}
            productPoints={getTotalPoints(
              [{ product }],
              isValueBasedPromoType(promotion),
            )}
          />
        ))}
    </Flex>
  )
}

export { default as InactiveProduct } from './InactiveProduct'

export default compose(
  productContainer,
  Comp => React.memo(Comp, isDeepEqual),
)(CartProduct)
