import React from 'react'

const Sushi = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0703 10.71C13.7272 10.71 15.0703 9.94445 15.0703 9.00004C15.0703 8.05563 13.7272 7.29004 12.0703 7.29004C10.4135 7.29004 9.07031 8.05563 9.07031 9.00004C9.07031 9.94445 10.4135 10.71 12.0703 10.71Z"
      fill="#FACEED"
    />
    <path
      d="M12.0703 20.5C16.5503 20.5 20.0703 18.3 20.0703 15.5V9C20.0703 6.2 16.5503 4 12.0703 4C7.59031 4 4.07031 6.2 4.07031 9V15.5C4.07031 18.3 7.58031 20.5 12.0703 20.5ZM12.0703 6C15.6003 6 18.0703 7.58 18.0703 9C18.0423 9.30855 17.9431 9.60638 17.7803 9.87C17.1003 10.87 15.2503 11.87 12.7803 11.99H11.3903C8.88031 11.83 7.00031 10.89 6.35031 9.84C6.21075 9.60618 6.11581 9.34848 6.07031 9.08V9C6.07031 7.58 8.53031 6 12.0703 6Z"
      fill="#241C17"
    />
  </svg>
)

export default Sushi
