import React from 'react'

import { getTopHeaderOffset, scrollElementIntoView } from 'utils/dom'

import Date from './Date'
import Package from './Package'
import { DateCardContainer } from './styles'

const DateCard = ({
  isFirst,
  isLast,
  date,
  hideDeliveries,
  isIncoming,
  routes,
  stickyId,
  expandedMode,
  innerRef,
  itemsPerPage,
  isEvenlyDisrtibuted,
}) => (
  <DateCardContainer
    {...{ isIncoming, itemsPerPage, isEvenlyDisrtibuted }}
    ref={innerRef}
  >
    <Date
      value={date}
      {...{ isIncoming }}
      onClick={() => scrollElementIntoView(stickyId, -getTopHeaderOffset())}
    />

    {!hideDeliveries && (
      <Package {...{ isFirst, isLast, isIncoming, routes, expandedMode }} />
    )}
  </DateCardContainer>
)

export default DateCard
