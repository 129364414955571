import React, { useState, useMemo, useEffect } from 'react'

import { useInterval } from 'hooks'
import { formatTime, secondsDiff } from 'utils/datetime'
import { TimerClock as TimerIcon } from 'components/Icons'
import { useTimerColorsConfig } from './utils'
import DeliveryTimerTooltip from './Tooltip'

import { DeliveryTimerContainer, DeliveryTimerText } from './styles'

export const DeliveryTimer = ({
  closestDeliveryDate: { deadlineOrderDate, deliveryDate } = {},
  cartBtnStyles = {},
  hasTimer,
}) => {
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [isIntervalRunning, setIntervalRunning] = useState(false)

  useEffect(
    () => {
      setIntervalRunning(true)
      setSecondsLeft(secondsDiff(deadlineOrderDate))
    },
    [deadlineOrderDate],
  )

  useInterval(() => {
    const diff = secondsDiff(deadlineOrderDate)
    if (diff < 1) {
      setSecondsLeft(0)
      setIntervalRunning(false)
    } else {
      setSecondsLeft(diff)
    }
  }, isIntervalRunning ? 1000 : null)

  const { hours, minutes, seconds } = useMemo(() => formatTime(secondsLeft), [
    secondsLeft,
  ])
  const colorsConfig = useTimerColorsConfig(
    deadlineOrderDate,
    cartBtnStyles,
    hasTimer,
  )

  if (hasTimer) {
    return (
      <DeliveryTimerTooltip deliveryDate={deliveryDate}>
        <DeliveryTimerContainer
          data-test-id="delivery-timer-container"
          $bgColor={colorsConfig.bg}
        >
          <TimerIcon color={colorsConfig.content} />
          <DeliveryTimerText
            data-test-id="delivery-timer-text"
            $textColor={colorsConfig.content}
          >
            {secondsLeft < 60 ? (
              <>
                {minutes}
                <span>:</span>
                {seconds}
              </>
            ) : (
              <>
                {hours}
                <span>:</span>
                {minutes}
              </>
            )}
          </DeliveryTimerText>
        </DeliveryTimerContainer>
      </DeliveryTimerTooltip>
    )
  }

  return null
}

export default DeliveryTimer
