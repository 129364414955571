import { makeMessages, makeTranslation } from 'utils'

const messages = {
  termsTitle: 'terms.title',
  termsDescription: 'terms.description',
  newsletterTitle: 'newsletter.title',
  newsletterDescription: 'newsletter.description',
  marketingTitle: 'marketing.title',
  marketingDescription: 'marketing.description',
  subscribeToast: {
    id: 'newsletter.subscribeToast',
  },
  unsubscribeToast: {
    id: 'newsletter.unsubscribeToast',
  },
  cookieSettingsBtn: 'cookieSettingsBtn',
  termsLink: { id: 'signUp.termsLink' },
  privacyLink: { id: 'signUp.privacyLink' },
}

export default makeMessages(messages, makeTranslation('profile.consents'))
