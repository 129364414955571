import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { DetailTextV2 } from 'components/Text'

import theme from 'theme'

export const LabelContainer = styled(Flex)`
  display: inline-flex;
  width: 56px;
  height: 16px;
  padding: 0px 3px;
  border: 1px solid ${theme.colors.gray5};
  border-radius: 4px;
`

export const LabelText = styled(DetailTextV2)`
  color: ${theme.colors.gray7};
`

export const LabelSeparator = styled.span`
  padding-left: ${p => p.separatorSize}px;
`
