import React from 'react'
import { useModal } from '@ebay/nice-modal-react'

import theme from 'theme'
import { Bookmark } from 'components/Icons'
import TemplateModal from 'components/modal/TemplateModal'

import { DefaultIconWrapper } from './styles'

export const BookmarkIcon = ({
  Wrapper = DefaultIconWrapper,
  product,
  unitOfMeasure,
  isInUserTemplate,
  clearTemplatesOnClose,
  itemListId,
  itemListName,
  itemModelId,
  listIndex,
  disabled,
  color = theme.colors.gray5,
  fillColor = theme.colors.primary,
}) => {
  const templateModal = useModal(TemplateModal)

  return (
    <Wrapper
      disabled={disabled}
      data-test-id="bookmark-button"
      $isInUserTemplate={isInUserTemplate}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        templateModal.show({
          product,
          unitOfMeasure,
          clearTemplatesOnClose,
          itemListId,
          itemListName,
          itemModelId,
          listIndex,
        })
      }}
    >
      <Bookmark
        fill={isInUserTemplate ? fillColor : 'none'}
        color={isInUserTemplate ? fillColor : color}
      />
    </Wrapper>
  )
}

export default BookmarkIcon
