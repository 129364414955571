/* eslint-disable react/prop-types */
import React from 'react'

import Back from 'components/Back'

import { Wrapper, BackButtonContainer, PageLayout } from './styles'

const MissingItemsLayout = ({ children }) => (
  <PageLayout>
    <Wrapper>
      <BackButtonContainer>
        <Back />
      </BackButtonContainer>

      {children}
    </Wrapper>
  </PageLayout>
)

export default MissingItemsLayout
