import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { get, noop, capitalize, compose } from 'lodash/fp'

import theme from 'theme'
import { makePromotionProductSelectors } from 'containers/Promotions/Products/selectors'
import { cartPromosTotalPointsSelector } from 'containers/Cart/selectors'
import buttonsContainer from 'containers/components/Product/ButtonsContainer'

import { SecondaryTextSuccess } from 'components/Text'
import { Cart } from 'components/Icons'
import PromoProgressCircle, { SIZE } from 'components/PromoProgressCircle'
import { PRODUCT_IMAGE_SIZES } from 'components/Product/ProductImage'
import PromoGroup from 'components/Product/PromoGroup'

import {
  formatAmount,
  calculateAmountByQuantity,
  formatUnitAmount,
} from 'components/Product/utils'

import { formatPrice, useRichFormatMessage } from 'utils'
import getCart from 'containers/Cart/actions'
import ValuePromoProgressInfo from 'components/ValuePromoProgressBar'
import {
  isOutOfCatalogRewardProduct,
  isValueBasedPromoType,
} from 'containers/Promotions/utils'
import messages from '../../messages'
import {
  PromoText,
  OuterWrapper,
  Wrapper,
  PromoBox,
  BoxChild,
  Counter,
} from '../styles'
import {
  CartBtnBox,
  InCartBox,
  PrizeImage,
  Points,
  PromoInfoContainer,
} from './styles'

const ValueBasedPromoText = ({ reward }) => {
  const richFormatMessage = useRichFormatMessage()
  const missingCount =
    reward.uniqueProductsThreshold - reward.uniqueProductsCount
  const missingAmount = formatPrice(
    reward.threshold - reward.thresholdProgression,
  )
  const rewardPrice = formatPrice(reward.priceNet)

  if (reward.toCollect) {
    return richFormatMessage(messages.requirementsMet, {
      rewardPrice,
    })
  }
  if (reward.maxApplicationsReached) {
    return richFormatMessage(messages.max)
  }
  if (reward.uniqueProductsThreshold === 1) {
    return richFormatMessage(messages.valueReqOnly, {
      rewardPrice,
      missingAmount,
    })
  }
  if (reward.uniqueProductsCount >= reward.uniqueProductsThreshold) {
    return richFormatMessage(messages.uniqueMet, { rewardPrice, missingAmount })
  }
  if (reward.thresholdDelta > reward.threshold) {
    return richFormatMessage(messages.amountMet, {
      rewardPrice,
      missingCount,
    })
  }
  return richFormatMessage(messages.requirementsMissing, {
    rewardPrice,
    missingCount,
    missingAmount,
  })
}

const PromoBar = ({
  addToCart,
  productPoints,
  unitOfMeasureObj,
  intl: { formatMessage } = {},
  promotion: { title, id, groupId, showModal, reward },
  promotion,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 719px)' })
  const dispatch = useDispatch()

  const rewardProductId = reward?.productId
  const prizeProductSelectors = useMemo(
    () => makePromotionProductSelectors(rewardProductId),
    [rewardProductId],
  )

  const prizeProductObj = useSelector(prizeProductSelectors.data)
  const totalPointsObj = useSelector(cartPromosTotalPointsSelector)

  const totalPoints = get(id)(totalPointsObj)

  const pointsThreshold = reward?.threshold
  const prizeQtyInCart = get('unitsOfMeasure.0.inCartQuantity')(prizeProductObj)
  const prizeUnit = get('unitsOfMeasure.0')(prizeProductObj)
  const prizeAmountInCart =
    prizeUnit && calculateAmountByQuantity(prizeUnit, prizeQtyInCart)
  const isOutOfCatalogRewardPromo = isOutOfCatalogRewardProduct(
    promotion,
    prizeProductObj,
  )
  const showInCartBox =
    !isOutOfCatalogRewardPromo && !isMobile && !!prizeQtyInCart

  const addMoreAmount = Math.ceil(
    (totalPoints / pointsThreshold
      ? pointsThreshold - (totalPoints % pointsThreshold)
      : pointsThreshold - totalPoints) / unitOfMeasureObj.points,
  )
  const formatAddMoreMsg = formatMessage(messages.missingPoints, {
    amount: formatAmount(unitOfMeasureObj, addMoreAmount),
  })

  const handleAddToCart = useCallback(() => {
    if (thresholdMet) {
      addToCart({
        product: prizeProductObj,
        productId: prizeProductObj.id,
        unit: prizeUnit.unitOfMeasure,
        quantity: prizeQtyInCart + 1,
        justAddedToCart: !prizeQtyInCart,
        callback: () => {
          dispatch(getCart.delta())
        },
        changeAmountToStockCallback: noop,
        getMessage: stockAmount =>
          formatMessage(messages.maxAvailableAmount, {
            amount: formatUnitAmount(prizeUnit, stockAmount),
          }),
      })
    }
  })

  const promoText =
    !isOutOfCatalogRewardPromo && !thresholdMet
      ? `${title}. ${formatAddMoreMsg}`
      : title

  const isValueBasedPromo = isValueBasedPromoType(promotion)

  const thresholdMet = reward.toCollect > 0

  const isValueBasedPromoRequirementMet =
    reward.uniqueProductsThreshold > 1
      ? reward.uniqueProductsCount > 1 ||
        reward.thresholdDelta / reward.threshold >= 1
      : reward.thresholdDelta / reward.threshold >= 1

  return (
    <OuterWrapper>
      <Wrapper>
        <PromoInfoContainer>
          <PromoBox>
            {isOutOfCatalogRewardPromo || isValueBasedPromo ? (
              <>
                <BoxChild>
                  {/* eslint-disable */}
                  {isValueBasedPromo ? (
                      <ValuePromoProgressInfo rewardDetails={reward} />
                    ) : (
                      <PromoProgressCircle
                        reward={reward}
                        size={SIZE.SMALL}
                        hidePointsText
                      />
                    )}
                {/* eslint-enable */}
                </BoxChild>

                {thresholdMet || isValueBasedPromo ? (
                  <CartBtnBox
                    onClick={handleAddToCart}
                    disabled={!thresholdMet}
                    hidden={
                      (isValueBasedPromo && !isValueBasedPromoRequirementMet) ||
                      (reward.maxApplicationsReached && !reward.toCollect)
                    }
                    data-test-id="reward-to-cart-button"
                  >
                    <Cart
                      color={
                        !thresholdMet
                          ? theme.colors.greenLight7
                          : theme.colors.white
                      }
                      width={20}
                      height={20}
                    />
                  </CartBtnBox>
                ) : (
                  <Counter>
                    <Points>
                      +
                      {isValueBasedPromo ? (
                        formatPrice(productPoints)
                      ) : (
                        <FormattedMessage
                          {...messages.pointsShort}
                          values={{ amount: productPoints }}
                        />
                      )}
                    </Points>
                  </Counter>
                )}
              </>
            ) : (
              <PromoText bold>
                {capitalize(formatMessage(messages.promotion))}
              </PromoText>
            )}
          </PromoBox>

          <PrizeImage product={prizeProductObj} size={PRODUCT_IMAGE_SIZES.S} />
          <PromoGroup {...{ groupId, showModal }}>
            <PromoText data-test-id="promotion-bar-text">
              {isValueBasedPromo ? (
                <ValueBasedPromoText reward={reward} />
              ) : (
                promoText
              )}
            </PromoText>
          </PromoGroup>
        </PromoInfoContainer>

        {showInCartBox && (
          <InCartBox>
            <SecondaryTextSuccess bold>
              <FormattedMessage
                {...messages.amountInCart}
                values={{ amount: prizeAmountInCart }}
              />
            </SecondaryTextSuccess>
          </InCartBox>
        )}
      </Wrapper>
    </OuterWrapper>
  )
}

export default compose(
  buttonsContainer,
  injectIntl,
)(PromoBar)
