import React from 'react'
import { FormattedMessage } from 'react-intl'

import { P } from 'components/Text'

import Notification from '../Notification'
import messages from './messages'

const Resend = ({ email }) => (
  <Notification header={messages.resend}>
    <P bold style={{ marginTop: '8px' }}>
      <b>
        <FormattedMessage {...messages.toContinue} values={{ value: email }} />
      </b>
      &nbsp;
      <FormattedMessage {...messages.toDoThis} />
    </P>
  </Notification>
)

export default Resend
