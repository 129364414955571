import { delay } from 'redux-saga'
import { takeLatest, put, call } from 'redux-saga/effects'
import {
  NOTIFICATIONS_SIGNAL,
  openNotification,
  hideNotification,
} from '../actions/notifications'

const NOTIFICATION_TIMEOUT = 3000

export function* notificationFlow({
  data: { message, type = 'success' } = {},
  additionalData: { timeout = NOTIFICATION_TIMEOUT } = {},
}) {
  yield put(openNotification({ message, type }))
  yield call(delay, timeout)
  yield put(hideNotification())
}

export default [takeLatest(NOTIFICATIONS_SIGNAL, notificationFlow)]
