import styled from 'styled-components'

import theme from 'theme'
import { SecondaryText } from 'components/Text'
import { BADGE_DIRECTION, BADGE_STATUS } from './consts'

// prettier-ignore
export const BadgeContainer = styled.span`
  display: flex;
  min-height: 24px;
  align-items: center;
  gap: 6px;
  padding-top: ${theme.spacing.xxs};
  padding-bottom: ${theme.spacing.xxs};

  ${p => p.direction === BADGE_DIRECTION.LEFT && `
    padding-left: ${theme.spacing.base};
    padding-right: ${theme.spacing.sm};
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  `}
  ${p => p.direction === BADGE_DIRECTION.RIGHT && `
    padding-left: ${theme.spacing.sm};
    padding-right: ${theme.spacing.base};
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  `}

  ${p => p.status === BADGE_STATUS.PROMO && `
    backdrop-filter: blur(12px);
    background-color: ${theme.colors.semiTranspBg.greenLight};
  `}
  ${p => p.status === BADGE_STATUS.SUCCESS && `
    background-color: ${theme.colors.semiTranspBg.success};
  `}
  ${p => p.status === BADGE_STATUS.WARNING && `
    background-color: ${theme.colors.semiTranspBg.warning};
  `}
  ${p => p.status === BADGE_STATUS.ERROR && `
    background-color: ${theme.colors.semiTranspBg.error};
  `}
  ${p => p.status === BADGE_STATUS.INFO && `
    background-color: ${theme.colors.semiTranspBg.info};
  `}
  ${p => p.status === BADGE_STATUS.DISABLED && `
    background-color: ${theme.colors.fadedGray};
  `}

  ${SecondaryText} {
    ${p => p.status === BADGE_STATUS.PROMO && `
      font-family: ${theme.fonts.primary};
      font-size: ${theme.textSizes.xxs};
      color: ${theme.colors.secondary};
      font-weight: 500;
    `}
    ${p => p.status === BADGE_STATUS.SUCCESS && `
      color: ${theme.colors.status.success};
    `}
    ${p => p.status === BADGE_STATUS.WARNING && `
      color: ${theme.colors.status.warning};
    `}
    ${p => p.status === BADGE_STATUS.ERROR && `
      color: ${theme.colors.status.error};
    `}
    ${p => p.status === BADGE_STATUS.INFO && `
      color: ${theme.colors.status.info};
    `}
    ${p => p.status === BADGE_STATUS.DISABLED && `
      color: ${theme.colors.primary};
    `}
  }
`

export const Separator = styled.div`
  border-radius: 50%;
  height: 3px;
  background-color: ${theme.colors.secondary};
  width: 3px;
`
