import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { get } from 'lodash/fp'

import theme from 'theme'
import { useOfMaxWidth } from 'hooks'
import { formatPrice } from 'utils'
import { scrollElementIntoView, getTopHeaderOffset } from 'utils/dom'
import { makePromotionProductSelectors } from 'containers/Promotions/Products/selectors'
import {
  cartProductByPromoIdSelector,
  cartCatalogPrizeUnit,
} from 'containers/Cart/selectors'
import { formatSavingsLabel } from 'views/Cart/utils'
import { findPrizeUnitIndex } from 'containers/Cart/utils'

import { GoTo } from 'components/Icons'
import { TertiaryText, TinyTextFaded } from 'components/Text'
import PromoProgressCircle, { SIZE } from 'components/PromoProgressCircle'
import { PRODUCT_IMAGE_SIZES } from 'components/Product/ProductImage'
import PromoGroup from 'components/Product/PromoGroup'

import { APP_BREAKPOINTS } from 'consts'
import {
  isCatalogRewardProduct,
  isDiscountPromoType,
  isOutOfCatalogRewardProduct,
  isQuantitivePromoType,
  isRewardPromoType,
  isSalePromoType,
  isValueBasedPromoType,
} from 'containers/Promotions/utils'
import messages from '../../messages'
import {
  FadedText,
  Prices,
  PriceText,
  PrizeImage,
  PromoName,
  Wrapper,
  PromoInfo,
  InCartBox,
  InfoAndActionsWrapper,
  ImgPricesWrapper,
  FirstPartWrapper,
  InfoWrapper,
} from './styles'
import AmountButtonsStyled from './AmountButtonsStyled'

const PromotionItem = ({
  prizeProductData,
  id,
  groupId,
  showModal,
  title,
  description,
  productId,
  type,
  reward,
}) => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TABLET_START - 1)
  const { formatMessage } = useIntl()
  const prizeUnitIndex = findPrizeUnitIndex(prizeProductData?.units)
  const prizeProductSelectors = useMemo(
    () => makePromotionProductSelectors(productId),
    [productId],
  )
  const qualifyingProduct = useSelector(
    cartProductByPromoIdSelector(productId, id),
  )
  const prizeFromStore = useSelector(prizeProductSelectors.data)
  const prize = get('product')(prizeProductData) || prizeFromStore
  const unitsOfMeasure = get('unitsOfMeasure.0')(prize)

  const isSalePromo = isSalePromoType({ type })
  const isQuantitivePromo = isQuantitivePromoType(prize?.promotion)
  const isDiscountPromo = isDiscountPromoType({ type })
  const isOutCatalogRewardPromo = isOutOfCatalogRewardProduct({ type }, prize)
  const isRewardPromo = isRewardPromoType({ type })
  const isValueBasedPromo = isValueBasedPromoType({ type })
  const isCatalogRewardPromo = isCatalogRewardProduct(prize)
  const isStandardPromo = isOutCatalogRewardPromo || isCatalogRewardPromo

  const prizeUnit = useSelector(cartCatalogPrizeUnit(productId))
  const unit =
    (isQuantitivePromo
      ? prizeProductData.units[prizeUnitIndex]
      : (isStandardPromo && unitsOfMeasure) || prizeUnit) || {}
  const multiplier = get('unitsOfMeasure.0.multiplier')(prize)
  const prizeQtyInCart = isQuantitivePromo
    ? prizeProductData.units[prizeUnitIndex]?.quantity
    : (isStandardPromo && unit.inCartQuantity) ||
      get('unitsOfMeasure.0.inCartQuantity')(prize)
  const showSavingsText = isDiscountPromo || isSalePromo
  const savingsPromoText =
    description || formatSavingsLabel(unit.totalPriceNetDiscount)

  const displayAmountButtons =
    isOutCatalogRewardPromo || (isCatalogRewardPromo && !prizeQtyInCart)
  const displayPromoLink = prize?.inUserCatalog

  const promoLinkRendered = (
    <PromoGroup {...{ groupId, showModal }}>
      <TinyTextFaded>{formatMessage(messages.seePromotion)}</TinyTextFaded>
    </PromoGroup>
  )

  const maxRewardAmount =
    (isOutCatalogRewardPromo ? reward.availableNo : reward.stock) * multiplier
  const isCartBtnDisabled = !reward.toCollect

  const valueProgressInfo = (
    <TertiaryText bold>
      {formatPrice(reward?.thresholdDelta, { withoutCurrency: true })}
      {' / '}
      {formatPrice(reward?.threshold)}
    </TertiaryText>
  )

  const pointsProgressInfo = (
    <TertiaryText bold>
      <span style={{ marginRight: theme.spacing.xs }}>
        {formatMessage(messages.points)}
      </span>
      {reward?.thresholdDelta}
      {' / '}
      {reward?.threshold}
    </TertiaryText>
  )

  return (
    <Wrapper>
      <FirstPartWrapper>
        <ImgPricesWrapper>
          <PrizeImage product={prize} size={PRODUCT_IMAGE_SIZES.S} />
          <Prices>
            <PriceText>{formatPrice(reward.priceNet)}</PriceText>
            <FadedText>
              {formatMessage(messages.grossPrice, {
                price: formatPrice(reward.priceGross),
              })}
            </FadedText>
          </Prices>
        </ImgPricesWrapper>
        <PromoName>{showSavingsText ? savingsPromoText : title}</PromoName>
      </FirstPartWrapper>
      <InfoAndActionsWrapper>
        <InfoWrapper {...{ isQuantitivePromo }}>
          {displayPromoLink && !isRewardPromo ? (
            promoLinkRendered
          ) : (
            <>
              <PromoProgressCircle
                reward={reward}
                size={isMobile ? SIZE.MEDIUM : SIZE.SMALL}
                hidePointsText
              />

              <PromoInfo>
                {isValueBasedPromo ? valueProgressInfo : pointsProgressInfo}
                {promoLinkRendered}
              </PromoInfo>
            </>
          )}
        </InfoWrapper>

        {!displayAmountButtons ? (
          <InCartBox
            withPointer
            onClick={() =>
              scrollElementIntoView(
                qualifyingProduct && !prizeQtyInCart
                  ? qualifyingProduct.id
                  : productId,
                -getTopHeaderOffset() - (isMobile ? 60 : 6),
              )
            }
          >
            {!prizeQtyInCart && (
              <GoTo style={{ marginRight: theme.spacing.xs }} />
            )}

            {formatMessage(
              messages[prizeQtyInCart ? 'productPresent' : 'showProduct'],
            )}
          </InCartBox>
        ) : (
          <AmountButtonsStyled
            {...{ productId }}
            cartBtnDisabled={isCartBtnDisabled}
            product={prize}
            maxValue={maxRewardAmount}
            unitData={unit}
            justifyContent={['center', 'space-between']}
            fontSize={13}
            refetchOnCartClick={isRewardPromo}
            neverDisableMinus
            disableTrash
            disableInput
            noSpaceBetween
            hideStar
          />
        )}
      </InfoAndActionsWrapper>
    </Wrapper>
  )
}

export default PromotionItem
