import { takeLatest, call, put, select, all } from 'redux-saga/effects'

import { getResponseError } from 'utils'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { genericGetDataEnhanced } from 'containers/App/sagas'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { trackPaymentInit } from 'services/analytics'
import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/Filters'
import { getInvoicesActions } from 'containers/Invoices/List/actions'
import { getInvoicesSummaryActions } from 'containers/Invoices/actions'
import { notifyFailure } from 'components/Product/utils'

import { initPaymentActions, settleInvoicesActions } from './actions'
import { initPay, settleInvoices } from './api'

function* initPaymentFlow({ data, additionalData }) {
  try {
    const { storeId } = yield select(userDataSelector)

    const response = yield call(
      genericGetDataEnhanced,
      {
        actions: initPaymentActions,
        request: initPay,
        params: data,
      },
      additionalData,
    )

    if (!response) {
      yield additionalData.callback && call(additionalData.callback)
      return
    }

    const { total } = additionalData
    trackPaymentInit(storeId, total)

    // redirecting to a payment page
    window.location = response.paymentUrl
  } catch (error) {
    console.log('Error initiating a payment', error)
    yield additionalData.callback && call(additionalData.callback)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

function* settleInvoicesFlow({ data, additionalData }) {
  try {
    yield call(
      genericGetDataEnhanced,
      {
        actions: settleInvoicesActions,
        request: settleInvoices,
        params: data,
      },
      additionalData,
    )

    // refetch invoices and finances after settlement to keep data up-to-date
    yield all([
      yield put(
        getInvoicesActions.delta({
          type: INVOICE_TYPE_IDS.TO_PAY,
        }),
      ),
      yield put(getInvoicesSummaryActions.delta()),
    ])
  } catch (error) {
    yield put(notifyFailure(getResponseError(error)))
  }
}

export default [
  takeLatest(initPaymentActions.DELTA, initPaymentFlow),
  takeLatest(settleInvoicesActions.DELTA, settleInvoicesFlow),
]
