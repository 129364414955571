import styled from 'styled-components'

import theme from 'theme'

// prettier-ignore
export const Container = styled.div`
  overflow: hidden;
  position: relative;
  background-color: #fff;

  ${p => p.isIncoming && `
    opacity: 0.5;
  `}

  &:not(:first-of-type) {
    margin-top: 2px;
  }
`

// prettier-ignore
export const VerticalLine = styled.div`
  width: 2px;
  background-color: ${theme.colors.primary};
  top: 0;
  bottom: 0;
  left: 34px;
  position: absolute;

  ${p => p.shiftTop && `
    top: ${theme.spacing.sm};
  `}
  
  ${p => p.shiftBottom && `
    bottom: ${theme.spacing.sm};
  `};
`

// prettier-ignore
export const DeliveryRoute = styled.div`
  margin-left: 36px;
  padding-right: ${theme.spacing.sm};
  padding-left: 34px;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  margin-top: ${theme.spacing.xs};

  line-height: 28px;

  ${p => p.isFirst && `
    margin-top: ${theme.spacing.xxs};
  `}

  ${p => p.isLast && `
    margin-bottom: ${theme.spacing.sm};
  `}

  ${p => p.isTail && `
    &:after {
      content: ' ';
      display: block;
      width: 2px;
      height: 50%;
      background-color: #fff;
      position: absolute;
      left: -2px;
      top: 50%;
      bottom: 0;
      z-index: ${theme.zIndex.base};
    }
  `}
`

export const Indicator = styled.div`
  position: absolute;
  left: -8px;
  top: 0;
  z-index: ${theme.zIndex.base + 1};
`
