import React from 'react'
import styled from 'styled-components'

import theme from 'theme'

import { Calendar } from 'components/Icons'

const Line = styled.div`
  width: 2px;
  height: 24px;
  background-color: ${theme.colors.primary};
  position: absolute;
  top: 43px;
  left: 50%;
  transform: translateX(-50%);
`
export const CalendarIcon = styled(
  ({ isLast, number, withoutLines, ...rest }) => (
    <div {...rest}>
      <Calendar {...{ number }} />
      {!withoutLines && !isLast && <Line />}
    </div>
  ),
)`
  position: relative;
  margin-right: 18px;
`
