import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from '@rebass/grid'
import { find } from 'lodash'

import {
  formatPrice,
  formatPricePerUnit,
  makeMessages,
  makeTranslation,
  useRichFormatMessage,
} from 'utils'

import { FloatingPriceWrapper } from 'components/FloatingPrice'
import { isLoweredPrice, getUnitDef, parseAmount } from '../utils'

import { PriceSection, Units, FormattedPrice, Price, SubPrice } from './styles'

const messages = makeMessages(
  {
    gross: { id: 'prices.gross' },
    soldForShort: 'soldForShort',
    soldFor: 'soldFor',
  },
  makeTranslation('product'),
)

const ProductPrices = ({
  isVariant,
  quantity = 1,
  unitDesc,
  baseUnitDesc,
  unitOfMeasure,
  big,
  pricePerUnit,
  unitForPricePerUnit,
  unitsOfMeasure,
  suppressSecondaryPrices,
  hideQuantity,
  promotion,
  hasFloatingPrice,
}) => {
  const formatRichMessage = useRichFormatMessage()

  const reward = promotion?.reward
  const { priceNet, priceGross, multiplier } =
    find(unitsOfMeasure, { unitOfMeasure }) || {}
  const isLoweredPricePromo = isLoweredPrice(promotion)
  const priceNetPerUnit = isLoweredPricePromo
    ? reward?.priceNetPerUnit
    : pricePerUnit

  const weightedInfo = multiplier !== 1 && (
    <FormattedMessage
      {...messages[big ? 'soldFor' : 'soldForShort']}
      values={{
        amount: parseAmount(multiplier),
        unit: unitDesc || baseUnitDesc,
      }}
    />
  )

  return (
    <PriceSection {...{ isVariant }}>
      <Flex
        flexDirection="column"
        justifyContent={
          multiplier !== 1 || priceNetPerUnit ? 'flex-end' : 'center'
        }
      >
        {!hideQuantity && (
          <Units {...{ isVariant, big }}>
            {getUnitDef({
              unitQuantity: quantity,
              baseUnitDesc,
              unitDesc: unitDesc || baseUnitDesc,
            })}
          </Units>
        )}
        <FormattedPrice {...{ isVariant }}>
          {weightedInfo ||
            formatPricePerUnit(priceNetPerUnit, unitForPricePerUnit)}
        </FormattedPrice>
      </Flex>
      {!suppressSecondaryPrices && (
        <Flex flexDirection="column" alignItems="flex-end">
          <Flex flexDirection="column" justifyContent="space-between">
            <FloatingPriceWrapper
              hasTooltip
              hasFloatingPrice={hasFloatingPrice}
            >
              {isLoweredPricePromo ? (
                <Flex flexDirection="row" alignItems="flex-end">
                  <SubPrice
                    $isLoweredPricePromo={isLoweredPricePromo}
                    {...{ isVariant }}
                  >
                    {formatPrice(priceNet)}
                  </SubPrice>
                  <Price
                    $isLoweredPricePromo={isLoweredPricePromo}
                    {...{ isVariant, big }}
                  >
                    {formatPrice(reward?.priceNet)}
                  </Price>
                </Flex>
              ) : (
                <Price {...{ isVariant, big }}>{formatPrice(priceNet)}</Price>
              )}
            </FloatingPriceWrapper>
            <SubPrice {...{ isVariant }}>
              {formatRichMessage(messages.gross, {
                price: formatPrice(
                  isLoweredPricePromo ? reward?.priceGross : priceGross,
                ),
              })}
            </SubPrice>
          </Flex>
        </Flex>
      )}
    </PriceSection>
  )
}

export default ProductPrices
