import styled from 'styled-components'
import { getSpacing } from 'components/helpers/theme-selectors'
import { P } from 'components/Text'

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: ${props => getSpacing(props).sm};
  ${P} {
    margin-left: ${props => getSpacing(props).xs};
  }
`

export const ErrorBox = styled.div`
  color: red;
`
