import { flow, find, get } from 'lodash/fp'

import { formatDateForApi } from 'containers/Cart/utils'

export const getLimit = ({ deliveryDates, deliveryDate, routeId }) =>
  flow(
    find({
      routeId,
      deliveryDate: formatDateForApi(deliveryDate),
    }),
    get('logisticLimit'),
  )(deliveryDates)
