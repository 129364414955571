import React from 'react'
import { useSelector } from 'react-redux'

import { selectTradeLimit } from 'containers/Orders/selectors'
import { invoicesSummarySelectors } from 'containers/Invoices/selectors'
import { PAYMENT_COLORS } from 'containers/Orders/consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { selectCartInfoData } from 'containers/Cart/selectors'

import Blocked from './Variants/Blocked'
import Overdue from './Variants/Overdue'
import Unpaid from './Variants/Unpaid'
import Pristine from './Variants/Pristine'
import AllPaid from './Variants/AllPaid'
import HiddenDues from './Variants/HiddenDues'
import ZeroCartLimit from './Variants/ZeroCartLimit'

const Invoices = () => {
  const tradeLimit = useSelector(selectTradeLimit)
  const invoicesSummary = useSelector(invoicesSummarySelectors.data)
  const { hideDues, hideInvoices } = useSelector(userDataSelector)
  const cartInfoData = useSelector(selectCartInfoData)

  if (!invoicesSummary || !tradeLimit) return null

  const { paymentColorName } = tradeLimit
  const hasZeroLimit = tradeLimit.tradeLimit === 0
  const hasEmptyCart = !cartInfoData?.deliveries?.length

  if (hideDues || hideInvoices) {
    return <HiddenDues tradeLimit={tradeLimit} />
  }

  if (paymentColorName === PAYMENT_COLORS.RED) {
    return <Blocked tradeLimit={tradeLimit} invoicesSummary={invoicesSummary} />
  }

  if (hasZeroLimit && hasEmptyCart) {
    return (
      <ZeroCartLimit
        tradeLimit={tradeLimit}
        invoicesSummary={invoicesSummary}
      />
    )
  }

  const hasGreenOverdue =
    paymentColorName === PAYMENT_COLORS.GREEN &&
    invoicesSummary.overdue.remainingAmount
  if (paymentColorName === PAYMENT_COLORS.YELLOW || hasGreenOverdue) {
    return <Overdue tradeLimit={tradeLimit} invoicesSummary={invoicesSummary} />
  }

  if (invoicesSummary.allInvoicesCount === 0) {
    return (
      <Pristine tradeLimit={tradeLimit} invoicesSummary={invoicesSummary} />
    )
  }

  if (invoicesSummary.toPay.invoicesCount) {
    return <Unpaid tradeLimit={tradeLimit} invoicesSummary={invoicesSummary} />
  }

  return <AllPaid tradeLimit={tradeLimit} invoicesSummary={invoicesSummary} />
}

export default Invoices
