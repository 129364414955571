import React from 'react'
import { BasicButtonV2, BasicButtonV2Grey } from 'components/Button'

import {
  InnerContainer,
  IconContainer,
  Container,
  TextContainer,
  Header,
  Description,
  ActionButtons,
} from './styles'

const StatusScreen = ({
  iconImg,
  headerText,
  descText,
  primaryBtnConfig,
  secondaryBtnConfig,
}) => (
  <Container col="12">
    <InnerContainer>
      <IconContainer src={iconImg} />
      <TextContainer>
        <Header>{headerText}</Header>
        <Description data-test-id="status-screen-desc">{descText}</Description>
        <ActionButtons>
          <BasicButtonV2 onClick={primaryBtnConfig.handleClick}>
            {primaryBtnConfig.text}
          </BasicButtonV2>
          <BasicButtonV2Grey onClick={secondaryBtnConfig.handleClick}>
            {secondaryBtnConfig.text}
          </BasicButtonV2Grey>
        </ActionButtons>
      </TextContainer>
    </InnerContainer>
  </Container>
)

export default StatusScreen
