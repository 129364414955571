import React from 'react'
import { useIntl } from 'react-intl'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { TertiaryText } from 'components/Text'
import messages from '../messages'

const Row = ({ label, value }) => {
  const { formatMessage } = useIntl()

  return (
    <Flex justifyContent="space-between" mb={theme.spacing.xs}>
      <TertiaryText>
        {formatMessage(messages[label]).replace(':', '')}:
      </TertiaryText>

      <TertiaryText bold>{value}</TertiaryText>
    </Flex>
  )
}

export default Row
