import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { ProductInfoContainer } from 'views/ProductDetails/ProductInfoSection/styles'
import {
  CarouselOverflowContainer,
  DefaultCarouselWrapper,
} from 'components/Carousel/styles'
import {
  ProductControlsContainer,
  StickyContainer,
} from 'views/ProductDetails/CartControlsSection/styles'
import {
  DESKTOP_HEIGHT as DESKTOP_HEADER_HEIGHT,
  MOBILE_HEIGHT as MOBILE_HEADER_HEIGHT,
} from 'containers/App/LoggedInLayout/Header/styles'

const PADDING_TABLET = 32

export const ProductDetailsTopSection = styled.div`
  display: grid;
  gap: ${theme.spacing.md};
  width: 100%;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    grid-template-columns: 1fr 1fr;

    ${ProductInfoContainer} {
      grid-column: span 2;
    }

    ${StickyContainer} {
      top: ${MOBILE_HEADER_HEIGHT + PADDING_TABLET}px;
    }
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    grid-template-columns: 2fr 1fr;

    ${ProductInfoContainer} {
      grid-column: auto;
    }

    ${ProductControlsContainer} {
      grid-row: span 2;
    }

    ${StickyContainer} {
      top: ${DESKTOP_HEADER_HEIGHT + PADDING_TABLET}px;
    }
  }
`

export const ProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.md};

  padding: ${theme.spacing.sm};

  ${CarouselOverflowContainer} {
    margin: 0 -${theme.spacing.sm};
    padding: 0 ${theme.spacing.sm};
  }

  ${DefaultCarouselWrapper} {
    :after,
    :before {
      content: '';
      position: absolute;
      z-index: ${theme.zIndex.base};
      top: 0;
      bottom: 0;
      pointer-events: none;
      width: 24px;
    }

    :before {
      left: -${theme.spacing.sm};
      background-image: linear-gradient(
        90deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    :after {
      right: -${theme.spacing.sm};
      background-image: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: ${PADDING_TABLET}px;

    ${DefaultCarouselWrapper} {
      :before {
        left: -${PADDING_TABLET}px;
      }

      :after {
        right: -${PADDING_TABLET}px;
      }
    }

    ${CarouselOverflowContainer} {
      margin: 0 -${PADDING_TABLET}px;
      padding: 0 ${PADDING_TABLET}px;
    }
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    ${CarouselOverflowContainer} {
      margin: 0 -${theme.spacing.sm};
      padding: 0 ${theme.spacing.sm};
    }

    ${DefaultCarouselWrapper} {
      :before {
        left: -${theme.spacing.sm};
      }

      :after {
        right: -${theme.spacing.sm};
      }
    }
  }
`
