import React from 'react'

import theme from 'theme'

const DotsMenuLarge = ({ color = theme.colors.lightGrey7 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="6" r="1.5" fill={color} />
    <circle cx="12" cy="12" r="1.5" fill={color} />
    <circle cx="12" cy="18" r="1.5" fill={color} />
  </svg>
)

export default DotsMenuLarge
