import styled from 'styled-components'

import theme from 'theme'

const LinkBtn = styled.button`
  font-family: ${theme.fonts.primary};
  user-select: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.96px;
  color: #76c067;
  text-transform: uppercase;
  padding: 0;
`

export const LinkBtnV2 = styled(LinkBtn)`
  font-family: ${theme.fonts.primary};
  font-size: 11px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: normal;
  color: ${theme.colors.primary};
  text-transform: none;
`

export default LinkBtn
