import { createAction } from 'redux-actions'
import { omit, prop } from 'lodash/fp'

import { createActionsGroup } from 'utils/redux-utils'

export default createActionsGroup('AGREEMENTS')

export const toggleNewsletterSubscription = createAction(
  'NEWSLETTER.TOGGLE',
  omit('meta'),
  prop('meta'),
)
