import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  fetchAndActivate,
  getRemoteConfig,
  getAll,
} from 'firebase/remote-config'
import { firebase } from 'utils/firebase'

const ConfigContext = createContext({})
const remoteConfig = getRemoteConfig(firebase)

remoteConfig.settings.minimumFetchIntervalMillis = 25000

const RemoteConfigProvider = ({ defaults, children }) => {
  const [values, setValues] = useState(defaults)
  const [isConfigFetched, setConfigFetched] = useState(false)

  const fetchConfig = useCallback(
    () => {
      fetchAndActivate(remoteConfig)
        .then(() => getAll(remoteConfig))
        .then(remote => {
          const newConfig = {
            ...values,
          }

          Object.entries(remote).forEach(([key, config]) => {
            try {
              newConfig[key] = JSON.parse(config.asString())
            } catch (e) {
              newConfig[key] = config.asString()
            }
          })
          setValues(newConfig)
        })
        .catch(error => console.error(error))
        .finally(() => setConfigFetched(true))
    },
    [remoteConfig],
  )

  useEffect(() => {
    remoteConfig.defaultConfig = defaults
    fetchConfig()
  }, [])

  const value = useMemo(
    () => ({ config: values, isConfigFetched, fetchConfig }),
    [values, isConfigFetched, fetchConfig],
  )

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  )
}

export default RemoteConfigProvider

export const useRemoteConfig = () => useContext(ConfigContext)
