import { makeMessages, makeTranslation } from 'utils'

const messages = {
  title: 'title',
  emptyTitle: 'emptyTitle',
  noOrdersSubtitle: 'noOrders.subtitle',
  noOrdersText: 'noOrders.text',
  allOrders: { id: 'common.labels.all' },
  orderAccepted: 'accepted',
  orderPendingApproval: 'pendingApproval',
  orderCanceled: 'canceled',
  orderCompleted: 'completed',
  orderInProgress: 'inProgress',
  today: 'today',
  yesterday: 'yesterday',
  beforeYesterday: 'beforeYesterday',
  latestOrder: 'latestOrder',
  pendingStatusMsg: 'pendingStatusMsg',
  canceledStatusMsg: 'canceledStatusMsg',
  deliveryStatusMsg: 'deliveryStatusMsg',
  sourceEcommerce: 'sourceEcommerce',
  sourceService: 'sourceService',
}

export default makeMessages(messages, makeTranslation('ordersTile'))
