import styled from 'styled-components'
import { Flex } from '@rebass/grid'

export const Row = styled(Flex)`
  flex: 1;
  justify-content: space-between;

  ${p =>
    p.shrink &&
    `
    flex: 0;
  `};
`
