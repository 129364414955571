import React, { useEffect } from 'react'

import { useRichFormatMessage } from 'utils'
import ActionWrapper, { ACTION_TYPE } from 'components/ActionWrapper'
import { MailSmall, PhoneSmall } from 'components/Icons'

import messages from '../Notification/messages'

const InfoPanel = ({ header, children }) => {
  const formatMessage = useRichFormatMessage()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="md:grid md:grid-cols-4">
        <div className="flex flex-col gap-12 px-6 py-12 md:py-[88px] md:col-start-2 md:col-span-2 ">
          <h4 className="text-2xl md:text-4xl md:leading-[48px] text-blue-900 font-semibold">
            {header}
          </h4>

          {children}
        </div>

        <div className="md:col-span-4 bg-grey-300 h-px" />

        <div className="flex flex-col gap-6 px-4 py-12 md:py-[88px] md:col-span-2 md:col-start-2 ">
          <span className="text-base font-medium text-blue-900">
            {formatMessage(messages.needHelp)}
          </span>
          <div className="flex flex-col gap-4 lg:flex-row">
            <ActionWrapper
              actionType={ACTION_TYPE.PHONE}
              value={formatMessage(messages.phone)}
              className="py-2.5 px-4 flex gap-4 bg-grey-150 hover:bg-grey-400 flex-1 text-[13px] leading-5 rounded-lg text-blue-900 items-center"
            >
              <PhoneSmall className="fill-grey-500" />
              {formatMessage(messages.phone)}
            </ActionWrapper>
            <ActionWrapper
              actionType={ACTION_TYPE.EMAIL}
              value={formatMessage(messages.email)}
              className="py-2.5 px-4 flex gap-4 bg-grey-150 hover:bg-grey-400 flex-1 text-[13px] leading-5 rounded-lg text-blue-900 items-center"
            >
              <MailSmall className="stroke-grey-500" />
              {formatMessage(messages.email)}
            </ActionWrapper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoPanel
