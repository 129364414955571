export const CONTACT_FORM_TOPICS = [
  'orderComplaint',
  'deliveryNotes',
  'suggestions',
  'productsOffer',
  'other',
]

export const DESCRIPTION_MIN_LENGTH = 10
export const DESCRIPTION_MAX_LENGTH = 5000

export const FILE_SIZE_LIMIT = 10 * 1024 * 1024
