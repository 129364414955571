import React from 'react'
import { useIntl } from 'react-intl'

import {
  EyeOpenV2 as ListEmptyIcon,
  NoFeedMessages as EndOfListIcon,
} from 'components/Icons'

import { Container, IconContainer, Text } from './styles'
import messages from '../messages'

const EndOfListComponent = ({ empty }) => {
  const { formatMessage } = useIntl()

  return (
    <Container>
      <IconContainer>
        {empty ? <ListEmptyIcon /> : <EndOfListIcon />}
      </IconContainer>
      <Text>
        {formatMessage(
          empty ? messages.emptyStateMessage : messages.lastNotifications,
        )}
      </Text>
    </Container>
  )
}

export default EndOfListComponent
