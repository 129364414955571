import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { bindActionCreators, genericSelector } from 'utils/redux-utils'

import { AUTH_REDUCER_NAME, LOGIN_REDUCER_NAME } from './consts'
import {
  loginActions,
  logout,
  signUpActions,
  signUpMigrateActions,
  passwordActions,
  confirmEmailActions,
  resendConfirmEmailActions,
  validateTokenActions,
  resendSetPasswordActions,
} from './actions'
import {
  authTokenSelector,
  confirmEmailSelector,
  confirmErrorCodeSelector,
  signUpEmailSelector,
  signUpErrorCodeSelector,
  validateTokenErrorCodeSelector,
  userLoginSelector,
  resendPasswordEmailSelector,
  isSessionExpiredSelector,
  isSignUpMigrateFetching,
} from './selectors'

export * from './consts'
export * from './selectors'
export * from './actions'

const { isFetching: isLoginFetching, fetchError: loginError } = genericSelector(
  [AUTH_REDUCER_NAME, LOGIN_REDUCER_NAME],
)

const mapStateToProps = createStructuredSelector({
  token: authTokenSelector,
  isLoginFetching,
  loginError,
  signUpErrorCode: signUpErrorCodeSelector,
  confirmEmailError: confirmErrorCodeSelector,
  tokenErrorCode: validateTokenErrorCodeSelector,
  setPasswordStepEmail: userLoginSelector,
  confirmStepEmail: confirmEmailSelector,
  signUpStepEmail: signUpEmailSelector,
  resendPasswordStepEmail: resendPasswordEmailSelector,
  isSessionExpired: isSessionExpiredSelector,
  isSignUpMigrateFetching,
})

const mapDispatchToProps = bindActionCreators({
  login: loginActions.delta,
  logout,
  signUp: signUpActions.delta,
  setPassword: passwordActions.delta,
  confirmEmail: confirmEmailActions.delta,
  resendConfirmEmail: resendConfirmEmailActions.delta,
  clearEmailConfirm: resendConfirmEmailActions.clear,
  validateToken: validateTokenActions.delta,
  resendSetPassword: resendSetPasswordActions.delta,
  signUpMigrate: signUpMigrateActions.delta,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
