import React from 'react'
import {
  InfoSection,
  InfoSectionText,
  InfoSectionTitle,
  NotificationContainer,
} from './styles'

const NotificationWrapper = ({ title, subtitle, children }) => (
  <NotificationContainer>
    <InfoSection>
      <InfoSectionTitle>{title}</InfoSectionTitle>
      <InfoSectionText>{subtitle}</InfoSectionText>
    </InfoSection>
    {children}
  </NotificationContainer>
)

export default NotificationWrapper
