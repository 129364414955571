import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { useGetOrderDetailsQuery } from 'containers/Orders/Details/rtkApi'
import { trackPageView, SCREENS } from 'services/analytics'
import LoaderFullHeight from 'components/LoaderFullHeight'
import useChangedLocationModal from 'hooks/useChangedLocationModal'
import OrderItemsList from './OrderItemsList'
import Breadcrumbs from './Breadcrumbs'
import Header from './Header'
import OrderActions from './OrderActions'
import PrimaryDetails from './PrimaryDetails'

import {
  Container,
  TopSection,
  TopSectionInnerContainer,
  OrderDetailsDivider,
} from './styles'

const OrdersDetails = () => {
  const { orderId } = useParams()
  const location = useLocation()

  const userData = useSelector(userDataSelector)
  const notifProps = location.state?.notifProps

  const {
    data: orderDetails,
    isFetching: isOrderDetailsFetching,
    error,
  } = useGetOrderDetailsQuery({
    orderId,
    // needed for cases when changing location from order to order
    suppress404Redirect: !!notifProps,
  })

  useEffect(() => trackPageView(SCREENS.ORDER_SUMMARY), [])

  useChangedLocationModal({ isReady: !isOrderDetailsFetching && !error })

  if (!orderDetails) return <LoaderFullHeight />

  return (
    <>
      {isOrderDetailsFetching && <LoaderFullHeight />}
      <Container col="12">
        <TopSection data-test-id="order-details-top-section">
          <Breadcrumbs />
          <TopSectionInnerContainer>
            <Header orderDetails={orderDetails} />
            <OrderActions
              orderId={orderId}
              orderStatusId={orderDetails.orderStatusId}
              orderDetails={orderDetails}
            />
          </TopSectionInnerContainer>
        </TopSection>
        <OrderDetailsDivider />
        <PrimaryDetails userData={userData} orderDetails={orderDetails} />
        <OrderItemsList orderId={orderId} orderDetails={orderDetails} />
      </Container>
    </>
  )
}

export default OrdersDetails
