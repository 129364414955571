import React from 'react'
import { FormattedMessage } from 'react-intl'

import { makeMessages, makeTranslation, FormattedRichMessage } from 'utils'

import timeline1Img from 'assets/hintsImgs/timeline1.png'
import timeline2Img from 'assets/hintsImgs/timeline2.png'
import timeline3Img from 'assets/hintsImgs/timeline3.png'

const messages = makeMessages(
  {
    header1: 'header1',
    header2: 'header2',
    header3: 'header3',
    info1: 'info1',
    info2: 'info2',
    info3: 'info3',
    oneMore: { id: 'hintModal.button.oneMore' },
    oneLast: { id: 'hintModal.button.oneLast' },
  },
  makeTranslation('hintModal.dividedDeliveries'),
)

export const hintModalConfig = [
  {
    header: <FormattedMessage {...messages.header1} />,
    img: timeline1Img,
    info: <FormattedRichMessage {...messages.info1} />,
    nextTxt: <FormattedMessage {...messages.oneMore} />,
  },
  {
    header: <FormattedMessage {...messages.header2} />,
    img: timeline2Img,
    info: <FormattedRichMessage {...messages.info2} />,
    nextTxt: <FormattedMessage {...messages.oneLast} />,
  },
  {
    header: <FormattedMessage {...messages.header3} />,
    img: timeline3Img,
    info: <FormattedRichMessage {...messages.info3} />,
  },
]
