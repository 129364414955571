import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  btnText: {
    id: 'navbar.home',
  },
}

export default makeMessages(messages, makeTranslation('noResults'))
