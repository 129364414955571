import React from 'react'
import { FormattedMessage } from 'react-intl'

import { BUTTON_SIZE } from 'components/Button'
import ProductLicenseTooltip from './ProductLicenseTooltip'

import messages from '../messages'

import { LicenseBtn } from './styles'

const ProductLicense = ({ product: { licenseType } = {} }) => (
  <ProductLicenseTooltip>
    <LicenseBtn size={BUTTON_SIZE.SMALL}>
      <FormattedMessage {...messages.missingLicense} values={{ licenseType }} />
    </LicenseBtn>
  </ProductLicenseTooltip>
)

export default ProductLicense
