import React from 'react'
import { useIntl } from 'react-intl'

import { PageContent } from 'containers/App/LoggedInLayout/styles'

import Information from 'containers/App/LoggedInLayout/Footer/Information'
import Contacts from './Contacts'
import Apps from './Apps'

import {
  FooterWrapper,
  FooterInnerContainer,
  FooterPageContent,
} from './styles'

const Footer = () => {
  const { formatMessage } = useIntl()

  return (
    <FooterWrapper>
      <PageContent>
        <FooterPageContent>
          <FooterInnerContainer>
            <Information {...{ formatMessage }} />
            <Contacts {...{ formatMessage }} />
            <Apps {...{ formatMessage }} className="FooterApps" />
          </FooterInnerContainer>
        </FooterPageContent>
      </PageContent>
    </FooterWrapper>
  )
}

export default Footer
