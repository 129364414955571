import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { TertiaryBtnV2 } from 'components/Button/styles'

export const TertiaryDashboardBtn = styled(TertiaryBtnV2)`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.xs};
  }
`
