import React from 'react'

import { Panel, PanelCenteredContainer } from './styledComponents'

const PanelComponent = props => {
  if (props.centered) {
    return (
      <PanelCenteredContainer>
        <Panel {...props} />
      </PanelCenteredContainer>
    )
  }

  return <Panel {...props} />
}

export default PanelComponent
