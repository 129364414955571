import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import {
  sum,
  uniq,
  flow,
  map,
  find,
  flatten,
  get,
  getOr,
  filter,
  sumBy,
  sortBy,
  intersectionWith,
  pathOr,
  head,
  propOr,
  groupBy,
  compose,
  reduce,
  values,
  prop,
} from 'lodash/fp'

import { DATE_TIME_FORMATS } from 'utils/datetime'
import { isProductInactive, isElasticStockExceeding } from 'utils'
import { makeDataSelector, stateValueSelectorFactory } from 'utils/redux-utils'
import {
  deliveryDatesByProductsSelector,
  allDeliveryDatesSelector,
} from 'containers/Delivery/selectors'
import {
  getTotalPoints,
  isNonQuantitivePromoProduct,
  isQuantitivePromoType,
} from 'containers/Promotions/utils'
import { userDataSelector } from 'containers/UserInfo/selectors'

import { isAvailableAfter } from 'components/Product/utils'
import {
  transformCartItems,
  calculateDeliveryTotal,
  formatDateForApi,
} from './utils'
import { CART_INFO_REDUCER_NAME } from './consts'

export const selectCartInfoData = makeDataSelector([CART_INFO_REDUCER_NAME])

export const makeDateChangingProductIdSelector = productId =>
  createSelector(
    stateValueSelectorFactory('changingDateProductId')(CART_INFO_REDUCER_NAME),
    get(productId),
  )

export const cartTotalSelector = createSelector(selectCartInfoData, data => ({
  totalNet: getOr(0, 'totalNet')(data),
  totalGross: getOr(0, 'totalGross')(data),
}))

export const selectIsOrderable = createSelector(selectCartInfoData, data =>
  getOr(false, 'orderable')(data),
)

export const hasFloatingPriceSelector = createSelector(
  selectCartInfoData,
  data => getOr(false, 'anyFloatingPrice')(data),
)

const cartSelector = createSelector(
  selectCartInfoData,
  flow(
    get('deliveries'),
    sortBy(['date', 'routeSortingOrder']),
  ),
)

export const cartDataSelector = createSelector(
  cartSelector,
  userDataSelector,
  (deliveries, userData) =>
    flow(
      map(delivery => {
        const filteredItems = filter(
          item =>
            !isProductInactive(item) &&
            !isAvailableAfter(item.product, userData),
        )(delivery.items)
        const items = transformCartItems(filteredItems)

        return {
          ...delivery,
          items,
          productIds: map('product.id')(items),
        }
      }),
      filter(delivery => !!delivery.items.length),
    )(deliveries),
)

export const cartDeliveriesSelector = createSelector(
  cartDataSelector,
  deliveryDates =>
    deliveryDates.map(deliveryDate => ({
      ...deliveryDate,
      dateObj: dayjs(deliveryDate.date),
    })),
)

export const cartProductDeliveryDateSelector = productId =>
  createSelector(cartDeliveriesSelector, deliveries => {
    const delivery = deliveries.find(({ productIds }) =>
      productIds.some(pId => pId === productId),
    )
    return get('dateObj', delivery)
  })

const cartFirstGroupDeliverySelector = createSelector(
  cartDeliveriesSelector,
  head,
)

export const cartFirstGroupProductsIdsSelector = createSelector(
  cartFirstGroupDeliverySelector,
  get('productIds'),
)

export const allCartItemsSelector = createSelector(
  cartSelector,
  flow(
    map('items'),
    flatten,
    transformCartItems,
  ),
)

export const cartItemsNumberSelector = createSelector(
  cartDataSelector,
  sumBy('items.length'),
)

export const getPlainCartProducts = filter(({ product }) => {
  const isInUserCatalog = get('inUserCatalog')(product)

  return product.active && isInUserCatalog
})

export const cartPromosSelector = createSelector(
  allCartItemsSelector,
  flow(
    reduce((acc, productData) => {
      const { product } = productData

      if (product.active && !!product.promotion) {
        const promo = product.promotion

        return {
          ...acc,
          [promo.reward.productId]: {
            ...promo,
            ...(isQuantitivePromoType(product.promotion) && {
              prizeProductData: productData,
            }),
          },
        }
      }

      return acc
    }, {}),
    values,
  ),
)

export const cartPromosPrizesIdsSelector = createSelector(
  allCartItemsSelector,
  flow(
    filter(
      flow(
        get('product'),
        isNonQuantitivePromoProduct, // without selfPromo
      ),
    ),
    map(get('product.promotion.reward.productId')),
    uniq,
  ),
)

export const cartPromosTotalPointsSelector = createSelector(
  allCartItemsSelector,
  reduce((acc, { product }) => {
    if (product.active && !!product.promotion) {
      const promoId = product.promotion.id
      const productPoints = getTotalPoints([{ product }])

      return {
        ...acc,
        [promoId]: sum([acc[promoId], productPoints]),
      }
    }

    return acc
  }, {}),
)

export const inactiveCartProductsSelector = createSelector(
  allCartItemsSelector,
  userDataSelector,
  (allItems, userData) =>
    filter(
      item =>
        isProductInactive(item) || isAvailableAfter(item.product, userData),
    )(allItems),
)

// TODO check if it's still needed
export const cartProductItemsSelector = productId =>
  createSelector(allCartItemsSelector, filter({ productId }))

export const cartProductByIdSelector = productId =>
  createSelector(
    allCartItemsSelector,
    find(({ product }) => product.id === productId),
  )

export const cartProductUnitsSelector = productId =>
  createSelector(cartProductByIdSelector(productId), item => get('units', item))

export const cartProductByPromoIdSelector = (productId, promoId) =>
  createSelector(allCartItemsSelector, allItems => {
    const itemByPromoId = allItems.find(
      ({ product }) =>
        product.promotion &&
        product.id !== productId &&
        product.promotion.id === promoId,
    )

    return get('product', itemByPromoId)
  })

export const cartCatalogPrizeUnit = productId =>
  createSelector(cartProductByIdSelector(productId), item => {
    const units = get('units', item)
    return find(unit => unit.prize)(units)
  })

export const deliveryDatesSettingsByDeliveriesSelector = ({
  filterExceedingElasticStock,
  isNonActiveItems = false,
} = {}) =>
  createSelector(
    deliveryDatesByProductsSelector,
    isNonActiveItems ? cartSelector : cartDataSelector,
    (deliveryDatesByProducts, cartData) =>
      cartData.reduce((acc, { productIds, items }) => {
        const commonDeliveryDates = items.reduce(
          (res, { product: { id: productId }, product }) => {
            const productDeliveryDates = flow(
              pathOr([], [productId, 0, 'deliveryDates']),
              sortBy(['deliveryDate', 'routeSortingOrder']),
              deliveryDates => {
                if (!filterExceedingElasticStock) return deliveryDates

                const hasElasticStockExceeded = product.unitsOfMeasure.reduce(
                  (isExceeding, unitOfMeasureObj) =>
                    isExceeding ||
                    isElasticStockExceeding({ product, unitOfMeasureObj }),
                  false,
                )

                return hasElasticStockExceeded
                  ? deliveryDates.filter(prop('nonStock'))
                  : deliveryDates
              },
            )(deliveryDatesByProducts)

            acc.set(productId, productDeliveryDates)

            if (!res.length) return productDeliveryDates

            return intersectionWith(
              (delivery1, delivery2) =>
                delivery1.deliveryDate === delivery2.deliveryDate &&
                delivery1.routeId === delivery2.routeId,
              res,
              productDeliveryDates,
            )
          },
          [],
        )

        acc.set(productIds.join('_'), commonDeliveryDates)
        return acc
      }, new Map()),
  )

export const deliveryDatesByProductIdsSelector = (
  productIds,
  filterExceedingElasticStock,
  isNonActiveItems,
) =>
  createSelector(
    deliveryDatesSettingsByDeliveriesSelector({
      filterExceedingElasticStock,
      isNonActiveItems,
    }),
    deliveryDatesSettingsByDeliveries =>
      deliveryDatesSettingsByDeliveries.get(productIds.join('_')),
  )

export const grouppedDeliveryDatesByProductIdsSelector = productIds =>
  createSelector(
    deliveryDatesByProductIdsSelector(productIds),
    groupBy('deliveryDate'),
  )

export const productDeliveryDatesByDateSelector = (productIds, deliveryDate) =>
  createSelector(
    grouppedDeliveryDatesByProductIdsSelector(productIds),
    grouppedDeliveryDates => {
      const date = formatDateForApi(deliveryDate)
      return propOr([], date, grouppedDeliveryDates)
    },
  )

export const firstDeliverySelector = createSelector(
  cartDeliveriesSelector,
  head,
)

export const firstGroupDeliverySettingsSelector = createSelector(
  cartFirstGroupDeliverySelector,
  allDeliveryDatesSelector,
  (firstGroupDelivery, allDeliveryDates) => {
    if (!firstGroupDelivery) return {}

    return find({
      deliveryDate: dayjs(firstGroupDelivery.date).format(
        DATE_TIME_FORMATS.API_DATE,
      ),
      routeId: firstGroupDelivery.routeId,
    })(allDeliveryDates)
  },
)

export const expiredProductIdsSelector = createSelector(
  cartSelector, // chosing cartSelector as it includes out of stock products, unlike cartDeliveriesSelector
  deliveryDatesByProductsSelector,
  compose(
    productIdsSet => [...productIdsSet],
    (cartDeliveries, deliveryDatesByProducts) =>
      reduce(
        (productIdsSet, { items, date, routeId }) =>
          // check expiry date of each product
          reduce((productsSet, { product: { id } }) => {
            const productDeliveries = get(`${id}.0.deliveryDates`)(
              deliveryDatesByProducts,
            )
            if (!prop('length', productDeliveries)) return productsSet

            const {
              deadlineOrderDate: productDeadline,
              deliveryDate: productDeliveryDate,
            } = head(productDeliveries)

            const routeIsUnavailable = !find(
              delivery =>
                date === delivery.deliveryDate && routeId === delivery.routeId,
            )(productDeliveries)

            if (
              dayjs(productDeadline).isSameOrBefore(dayjs()) ||
              dayjs(productDeliveryDate).isAfter(dayjs(date)) ||
              routeIsUnavailable
            ) {
              productsSet.add(id)
            }

            return productsSet
          }, productIdsSet)(items),
        new Set(),
      )(cartDeliveries),
  ),
)

export const firstDeliveryLogisticLackAmountSelector = createSelector(
  firstGroupDeliverySettingsSelector,
  firstDeliverySelector,
  (settings, firstDelivery) => {
    const total = calculateDeliveryTotal(propOr([], 'items', firstDelivery))

    if (total === 0) {
      return 0
    }

    return settings && settings.logisticLimit - total
  },
)
