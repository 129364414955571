import { takeLatest, call, put, select } from 'redux-saga/effects'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { genericGetDataEnhanced } from 'containers/App/sagas'

import { INVOICE_TYPE_IDS } from './Filters'
import { invoicesDateRangeFiltersSelector } from './Filters/selectors'
import {
  getInvoicesActions,
  getOverpaymentInvoicesActions,
  downloadInvoiceActions,
} from './actions'
import { getInvoices, getInvoiceFile } from './api'

function* getInvoicesFlow({ data, additionalData }) {
  try {
    const dateRange = yield select(invoicesDateRangeFiltersSelector)
    yield call(
      genericGetDataEnhanced,
      {
        actions: getInvoicesActions,
        request: getInvoices,
        params: { ...data, ...dateRange },
      },
      additionalData,
    )
  } catch (error) {
    console.log('Error getting invoices', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

function* getOverpaymentInvoicesFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getOverpaymentInvoicesActions,
      request: getInvoices,
      params: {
        page: 1,
        perPage: 200, // overpayments modal doesn't support pagination, using some high page size
        type: INVOICE_TYPE_IDS.OVERPAID,
      },
    })
  } catch (error) {
    console.log('Error getting an overpayment invoices', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

function* downloadInvoiceFlow({
  data,
  additionalData: { onSuccess, onError },
}) {
  try {
    const response = yield call(
      genericGetDataEnhanced,
      {
        actions: downloadInvoiceActions,
        request: getInvoiceFile,
        params: data,
      },
      { rethrow404: true },
    )

    if (response) {
      yield call(onSuccess, response)
    }
  } catch (error) {
    console.log('Error downloading an invoice file', error)
    yield call(onError)
  }
}

export default [
  takeLatest(getInvoicesActions.DELTA, getInvoicesFlow),
  takeLatest(getOverpaymentInvoicesActions.DELTA, getOverpaymentInvoicesFlow),
  takeLatest(downloadInvoiceActions.DELTA, downloadInvoiceFlow),
]
