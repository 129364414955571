import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import theme from 'theme'
import { BaseTextV2, H4, P, SubText } from 'components/Text'

export const PriceSection = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: ${p => !p.isVariant && theme.spacing.base};
`

export const CatalogPriceSection = styled(PriceSection)`
  font-family: ${theme.fonts.primary};
  flex-direction: column;
  padding: 12px 8px;
  margin-bottom: 0;
`

export const ProductSubText = styled(SubText)`
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.xxs};
  color: ${theme.colors.lightGrey4};
  line-height: 16px;
  ${props =>
    props.marginBottom &&
    `
    margin-bottom: ${props.marginBottom}px;
  `};
`

export const WeightedSubText = styled(ProductSubText)`
  height: 16px;
`

export const OldPrice = styled(ProductSubText)`
  align-self: center;
  text-decoration-line: line-through;
  margin-left: 14px;
`

// prettier-ignore
export const Units = styled(P)`
  line-height: 19px;
  font-weight: ${theme.fontWeights.strongBold};
  ${p => p.isVariant ? `color: ${theme.colors.primary}` : ''};
  margin-bottom: ${p => p.big ? 8 : 2}px;
`

// prettier-ignore
export const FormattedPrice = styled(SubText)`
  line-height: 16px;
  font-size: ${theme.textSizes.sm};
  ${p => p.isVariant ? `color: ${theme.colors.primary}` : ''};
`

// prettier-ignore
export const Price = styled(H4)`
  margin: 0;
  text-align: right;
  line-height: ${p => p.big ? 34 : 24}px;
  font-weight: ${theme.fontWeights.strongBold};
  ${p => p.isVariant ? `color: ${theme.colors.primary}` : ''};
  ${p => p.$isLoweredPricePromo ? `color: ${theme.colors.secondary}` : ''};
  ${p => p.big ? `font-size: ${theme.headingSize.l}` : ''};
`

export const CatalogPrice = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  font-weight: ${theme.fontWeights.bold};
`

export const SubPrice = styled(SubText)`
  font-size: ${theme.textSizes.sm};
  color: ${theme.colors.gray4};
  text-align: right;
  letter-spacing: 0.02em;
  line-height: 16px;
  ${p =>
    p.$isLoweredPricePromo &&
    `
    text-decoration: line-through;
    margin-right: ${theme.spacing.xs};
  `};
`

export const CatalogPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
`
