import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

export const CartIconWrapper = styled(Flex)`
  position: relative;
`

export const BadgeContainer = styled(Flex)`
  position: absolute;
  left: 12px;
  top: -7px;
  ${p =>
    !p.isAlert &&
    `
    padding: 2px;
    border-radius: 3px;
    background: ${theme.colors.white};
    top: -5px;
  `};
`

export const BadgeInnerContainer = styled(Flex)`
  padding: 1px 2px;
  border-radius: 3px;
  min-width: 16px;
  height: 12px;
  justify-content: center;
  background: ${p => p.bgColor};
`

export const BadgeText = styled(BaseTextV2)`
  font-size: 9px;
  line-height: 10px;
  font-weight: 600;
  color: ${p => p.contentColor};
`
