import { handleActions } from 'redux-actions'

import { updateCartItem, deleteCartItem } from 'containers/Cart/actions'
import { createReducer, initialState } from 'utils/simpleDataReducer'

import { searchProductsActions } from './actions'

const suggestionsReducer = createReducer(searchProductsActions)
const suggestionsInCartReducer = handleActions(
  {
    [updateCartItem.SUCCESS]: (state, { data }) => {
      const { cartItem } = data
      if (!cartItem) return state

      const { product } = cartItem
      return state.updateIn(['data'], storeData => {
        if (!storeData) return storeData

        return storeData.updateIn(['products'], items =>
          items.map(
            item =>
              item.get('id') === product.id ? item.merge(product) : item,
          ),
        )
      })
    },
    [deleteCartItem.SUCCESS]: (state, { additionalData }) =>
      state.updateIn(['data'], data => {
        if (!data) return data

        return data.updateIn(['products'], items =>
          items.map(item => {
            const id = item.get('id')
            if (id === additionalData.productId) {
              return item.update('unitsOfMeasure', unitsOfMeasure =>
                unitsOfMeasure.map(unit => {
                  const unitOfMeasure = unit.get('unitOfMeasure')
                  return unitOfMeasure === additionalData.unitOfMeasure
                    ? unit.merge({ inCartQuantity: 0 })
                    : unit
                }),
              )
            }
            return item
          }),
        )
      }),
  },
  initialState,
)

const searchReducer = (state, action) => {
  const nextState = suggestionsReducer(state, action)
  return suggestionsInCartReducer(nextState, action)
}
export default searchReducer
