import React from 'react'

import theme from 'theme'

const UserDelete = ({ color = theme.colors.gray5 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 20V19C15 16.7909 13.2091 15 11 15H7C4.79086 15 3 16.7909 3 19V20"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="9" cy="8" r="3" stroke={color} strokeWidth="2" />
    <path
      d="M17 8L21 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 8L17 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UserDelete
