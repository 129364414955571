import { useEffect, useRef, useState } from 'react'

import { useRemoteConfig } from 'containers/RemoteConfigProvider'

import defaultBgImg from 'assets/login-background.jpg'

const DEFAULT_FALLBACK_TIMEOUT = 1000

export const useFirebaseBackgroundImage = () => {
  const {
    config: { loginScreenBg },
  } = useRemoteConfig()

  const [bgImg, setBgImg] = useState(null)

  const timeout = useRef(null)

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setBgImg(defaultBgImg)
    }, DEFAULT_FALLBACK_TIMEOUT)

    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  useEffect(
    () => {
      if (loginScreenBg !== undefined) {
        clearTimeout(timeout.current)
        if (loginScreenBg === '') {
          setBgImg(defaultBgImg)
        } else {
          setBgImg(loginScreenBg)
        }
      }
    },
    [loginScreenBg],
  )

  return bgImg
}
