import styled from 'styled-components'

import theme from 'theme'

import { ControlLabel } from 'components/Text'

export const Container = styled.div`
  display: flex;
  padding ${theme.spacing.xs} ${theme.spacing.sm};
  align-items: flex-start;
`

export const IconContainer = styled.div`
  flex-shrink: 0;
  padding: ${theme.spacing.xs} 0;
`

export const LabelContainer = styled.div`
  padding ${theme.spacing.xs} ${theme.spacing.sm};
`

export const AddressLabel = styled(ControlLabel)`
  line-height: 20px;
`
