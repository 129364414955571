import React from 'react'
import { FormattedMessage } from 'react-intl'

import theme from 'theme'
import { ArrowLeftXS, ArrowRightXS } from 'components/Icons'

import messages from './messages'
import { ProductCount, FiltersPageControl } from './styles'

const FiltersPagination = ({
  totalCount,
  totalPages,
  current,
  onPageChange,
  isNav,
}) => (
  <>
    <ProductCount {...{ isNav }}>
      {totalCount}
      &nbsp;
      <FormattedMessage
        {...messages.numberOfProducts}
        values={{ number: totalCount }}
      />
    </ProductCount>
    <FiltersPageControl
      onChange={onPageChange}
      prevIcon={
        <ArrowLeftXS
          color={theme.colors[current === 1 ? 'gray6' : 'primary']}
        />
      }
      nextIcon={
        <ArrowRightXS
          color={theme.colors[current === totalPages ? 'gray6' : 'primary']}
        />
      }
      prevBtnTestId="filters-pagination-prev-btn"
      nextBtnTestId="filters-pagination-next-btn"
      {...{ totalPages, current }}
    />
  </>
)

export default FiltersPagination
