import theme, { paymentTheme } from 'theme'
import { PAY_SCENARIO, ORDER_PAY_BUTTON_COLOR } from 'containers/Orders/consts'
import { ScenarioLocked, ScenarioAlert } from 'components/Icons'

const { green, yellow, red } = paymentTheme

export const INVOICES_PAY_BUTTON_CONFIG = {
  [ORDER_PAY_BUTTON_COLOR.GREEN]: {
    bgColor: theme.colors.greenLight3,
    bgHoverColor: theme.colors.greenLight4,
    txtColor: theme.colors.secondary,
  },
  [ORDER_PAY_BUTTON_COLOR.YELLOW]: {
    bgColor: yellow.bg,
    bgHoverColor: yellow.bgHover,
    txtColor: yellow.text,
  },
  [ORDER_PAY_BUTTON_COLOR.RED]: {
    bgColor: red.bg,
    bgHoverColor: red.bgHover,
    txtColor: red.text,
  },
}

export const SCENARIO_CONFIG = {
  [PAY_SCENARIO.GREEN]: {
    scenarioBg: theme.colors.transparent,
    scenarioSecTxt: theme.colors.battleshipGrey,
    submitBg: green.bg,
    submitBgHover: green.bgHover,
    txtColor: green.text,
  },
  [PAY_SCENARIO.YELLOW]: {
    scenarioBg: theme.colors.transparent,
    scenarioSecTxt: yellow.text,
    submitBg: yellow.bg,
    submitBgHover: yellow.bgHover,
    txtColor: yellow.text,
    IconComponent: ScenarioAlert,
  },
  [PAY_SCENARIO.RED]: {
    scenarioBg: theme.colors.transparent,
    scenarioSecTxt: theme.colors.redDarkText,
    submitBg: red.bg,
    submitBgHover: red.bgHover,
    txtColor: red.text,
    IconComponent: ScenarioLocked,
  },
}
