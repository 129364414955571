import { makeMessages, makeTranslation } from 'utils'

const messages = {
  name: 'name',
  purchaseDate: 'purchaseDate',
  createdAt: 'createdAt',
  default: 'default',
}

export default makeMessages(messages, makeTranslation('sorting'))
