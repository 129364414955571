import styled, { keyframes } from 'styled-components'

import theme from 'theme'
import { BaseTextV2Medium } from 'components/Text'

export const DeliveryTimerContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  gap: ${theme.spacing.sm};
  border-radius: 0 ${theme.borderRadius.base} ${theme.borderRadius.base} 0;
  background: ${p => p.$bgColor};
  cursor: pointer;
`

const blink = keyframes`
  50% {
    color: transparent;
  }
`

export const DeliveryTimerText = styled(BaseTextV2Medium)`
  color: ${p => p.$textColor};

  > span {
    animation: ${blink} 1s steps(1) infinite;
  }
`
