import { takeLatest, call, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'

import { STATIC_FILTERS } from 'components/Filters/consts'
import { getTemplateFilters } from '../api'
import { getTemplateFiltersActions } from '../actions'

export function* getTemplateFiltersFlow({ data } = {}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getTemplateFiltersActions,
      request: getTemplateFilters,
      params: data,
      dataSelector: filters => [...filters, ...STATIC_FILTERS],
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(getTemplateFiltersActions.DELTA, getTemplateFiltersFlow),
]
