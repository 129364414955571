import React from 'react'
import { useIntl } from 'react-intl'
import { Flex } from '@rebass/grid'

import { HeartSmall } from 'components/Icons'
import TextTooltip from 'components/Tooltip/TextTooltip'

import messages from './messages'

const SystemTemplateIcon = ({ productId, iconSize, placement }) => {
  const { formatMessage } = useIntl()

  return (
    <Flex onClick={e => e.preventDefault()}>
      <TextTooltip
        content={formatMessage(messages.systemTemplateTooltipText)}
        placement={placement}
      >
        <Flex data-test-id={`system_template_icon_${productId}`}>
          <HeartSmall iconSize={iconSize} />
        </Flex>
      </TextTooltip>
    </Flex>
  )
}

export default SystemTemplateIcon
