import React from 'react'
import theme from 'theme'

const PreservativeFree = ({ color = theme.colors.primary }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14454_362332)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.30816 8.59264C5.17505 8.50707 5 8.60264 5 8.76087L5 11.9999C5 13.6567 6.34315 14.9999 8 14.9999C9.5333 14.9999 10.7979 13.8496 10.978 12.3649C10.9869 12.2916 10.9513 12.2206 10.8892 12.1806L9.64816 11.3828C9.51505 11.2972 9.34 11.3928 9.34 11.551V11.9999C9.34 12.74 8.74006 13.3399 8 13.3399C7.25994 13.3399 6.66 12.74 6.66 11.9999V9.57091C6.66 9.50286 6.6254 9.43948 6.56816 9.40268L5.30816 8.59264Z"
        fill={color}
        className="primaryFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00009 2C4.00009 1.44772 4.44781 1 5.00009 1L6.00009 1L10.0001 1L11.0001 1C11.5524 1 12.0001 1.44772 12.0001 2C12.0001 2.48424 11.6559 2.88809 11.1988 2.98025C11.0905 3.00209 11.0001 3.08954 11.0001 3.2L11.0001 8.92778C11.0001 8.99583 11.0347 9.05921 11.0919 9.09601L15.4057 11.8691C15.7541 12.0931 15.855 12.5571 15.631 12.9056C15.407 13.254 14.943 13.3549 14.5945 13.1309L0.594523 4.13088C0.246096 3.90689 0.145218 3.44286 0.369208 3.09443C0.593197 2.746 1.05723 2.64513 1.40566 2.86912L4.69194 4.98172C4.82504 5.06729 5.00009 4.97172 5.00009 4.81349L5.00009 3.2C5.00009 3.08954 4.90965 3.00209 4.80137 2.98025C4.34428 2.88809 4.00009 2.48424 4.00009 2Z"
        fill={color}
        className="primaryFill"
      />
    </g>
    <defs>
      <clipPath id="clip0_14454_362332">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default PreservativeFree
