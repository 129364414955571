import React from 'react'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'

import theme from 'theme'

import messages from '../messages'
import { SmallText } from './styles'

const InfoRow = ({
  label,
  value,
  rightSideColor,
  ValueWrapper = 'span',
  ...rest
}) => {
  const { formatMessage } = useIntl()
  return (
    <Flex {...rest}>
      <SmallText style={{ marginRight: theme.spacing.xs }}>
        {formatMessage(messages[label]).replace(':', '')}:
      </SmallText>

      <ValueWrapper>
        <SmallText bold color={rightSideColor}>
          {value}
        </SmallText>
      </ValueWrapper>
    </Flex>
  )
}

export default InfoRow
