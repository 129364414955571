import React from 'react'
import PropTypes from 'prop-types'

import { SmallIcon, ICONS, ICONS_POSITION, ICON_COLORS } from 'components/Icon'

import { Container, InnerContainer } from './styles'

export const ACTION_TYPE = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
}

const ActionLink = ({
  value,
  href,
  actionType = ACTION_TYPE.EMAIL,
  suppressSpacingTop,
}) => (
  <Container
    href={href}
    actionType={actionType}
    value={value}
    suppressSpacingTop={suppressSpacingTop}
  >
    <InnerContainer>
      <SmallIcon
        type={actionType === ACTION_TYPE.EMAIL ? ICONS.MAIL : ICONS.PHONE}
        position={ICONS_POSITION.LEFT}
        iconSvgProps={{ color: ICON_COLORS.FADED }}
      />

      <span>{value}</span>
    </InnerContainer>
  </Container>
)

ActionLink.propTypes = {
  actionType: PropTypes.string,
  value: PropTypes.string,
  suppressSpacingTop: PropTypes.bool,
}

export default ActionLink
