import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getColors, getSpacing } from 'components/helpers/theme-selectors'

/* prettier-ignore */
const InfoPanel = styled(Flex)`
  width: 100%;
  max-width: 100%;
  padding: ${props => getSpacing(props).base};
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => getColors(props).border};
`

export default InfoPanel
