import React from 'react'
import { useIntl } from 'react-intl'

import { ContentWrapper } from 'components/Layout'
import { FullWidthSeparator } from 'containers/App/LoggedInLayout/styles'
import { PageTitleV2 } from 'components/Text'

import { Header } from '../../styles'
import messages from '../../../messages'
import FilteredTabs from '../FilteredTabs'
import Breadcrumbs from './Breadcrumbs'

const LayoutWrapper = ({ children, selectedTab, onTabSelect }) => {
  const { formatMessage } = useIntl()

  return (
    <ContentWrapper col="12">
      <Breadcrumbs formatMessage={formatMessage} />
      <Header>
        <PageTitleV2>{formatMessage(messages.title)}</PageTitleV2>
        <FilteredTabs {...{ selectedTab, onTabSelect }} />
      </Header>
      <FullWidthSeparator />

      {children}
    </ContentWrapper>
  )
}

export default LayoutWrapper
