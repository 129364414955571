import React from 'react'
import { generatePath } from 'react-router-dom'

import { ROUTES } from 'consts'
import { BreadCrumb, BreadCrumbList } from 'components/BreadCrumbs'
import messages from 'views/Invoices/messages'

const Breadcrumbs = ({ formatMessage }) => (
  <BreadCrumbList>
    <BreadCrumb to={generatePath(ROUTES.DASHBOARD)}>
      {formatMessage(messages.home)}
    </BreadCrumb>
    <BreadCrumb $hideArrow>{formatMessage(messages.title)}</BreadCrumb>
  </BreadCrumbList>
)

export default Breadcrumbs
