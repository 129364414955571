import styled, { createGlobalStyle } from 'styled-components'

import theme from 'theme'
import { ModalHeader } from 'components/modal/styles'
import { StyledScroll } from 'components/Scroll/styles'

export const PortalModalStyle = createGlobalStyle`
  .ReactModal__Overlay {
    // making sure this modal is hidden behind generic error modal
    z-index: ${theme.zIndex.modalOverlay - 1}; 
  }

  .ReactModal__Content {
    max-width: 724px !important;
    padding: ${theme.spacing.base} !important;
  }

  .InvoicesSettleModal {
    max-height: calc(100% - 68px);
  }
`

export const ModalContentContainer = styled.div`
  padding: ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.base};

  ${ModalHeader} {
    margin-bottom: ${theme.spacing.sm};

    h4 span {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const TotalSubheader = styled.div`
  padding: ${theme.spacing.sm} 0;
  border-top: 1px solid ${theme.colors.borderFaded};
  border-bottom: 1px solid ${theme.colors.borderFaded};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SettleModalScroll = styled(StyledScroll)`
  max-height: 40vh;
  @media screen and (min-height: 668px) {
    max-height: 50vh;
  }
  overflow: auto;
`

export const Invoices = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
  padding: ${theme.spacing.base} 0;
`

export const InvoiceContainer = styled.div`
  padding: 0 ${theme.spacing.sm};
`
