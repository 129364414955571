import styled from 'styled-components'
import { Box } from '@rebass/grid'

import { getSpacing } from 'components/helpers/theme-selectors'

export const InnerContainer = styled(Box)`
  > button {
    margin-top: ${props => getSpacing(props).base};
    width: 100%;
  }
`

export const ConfirmPasswordContainer = styled.div`
  margin-top: ${props => getSpacing(props).base};
`
