import styled, { css } from 'styled-components'
import { getColors, getSpacing } from 'components/helpers/theme-selectors'
import { P } from 'components/Text'
import theme from 'theme'
import { ALERT_SIZE, ALERT_TYPE } from './consts'

const alertSizesStyles = {
  [ALERT_SIZE.NORMAL]: css`
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
  `,
  [ALERT_SIZE.SMALL]: css`
    padding: ${props => getSpacing(props).sm} ${props => getSpacing(props).xs};
    height: 40px;
  `,
}

const getAlertSize = ({ size }) =>
  alertSizesStyles[size] || alertSizesStyles[ALERT_SIZE.NORMAL]

const alertTypeStyles = {
  [ALERT_TYPE.ERROR]: css`
    background: ${props => getColors(props).error};
  `,
  [ALERT_TYPE.SUCCESS]: css`
    background: ${props => getColors(props).secondary};
  `,
  [ALERT_TYPE.INFO]: css`
    background: ${props => getColors(props).info};
  `,
}

const getAlertType = ({ type }) =>
  alertTypeStyles[type] || alertTypeStyles[ALERT_TYPE.SUCCESS]

const AlertBox = styled.div`
  ${getAlertSize};
  ${getAlertType};
  border-radius: ${theme.borderRadius.base};
  color: ${props => getColors(props).white};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  ${P} {
    padding: 2px 8px;
    color: ${props => getColors(props).white};
  }
`

export default AlertBox
