import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { ORDER_ITEM_REMOVING_REASONS } from 'views/Orders/consts'

import messages from '../../../messages'

const Labels = ({ orderItem }) => {
  const { formatMessage } = useIntl()
  const { actions, unit } = orderItem

  const labelsList = useMemo(
    () => {
      let labels = []

      if (unit?.reward) {
        labels = [
          {
            msg: formatMessage(messages.rewardProductBadge),
            bgColor: 'bg-green-100',
            textColor: 'text-green-600',
          },
        ]
      }

      if (
        actions?.removing?.reason ===
        ORDER_ITEM_REMOVING_REASONS.ALREADY_REMOVED
      ) {
        labels = [
          ...labels,
          {
            msg: formatMessage(messages.alreadyRemovedLabel),
            bgColor: 'bg-red-100',
            textColor: 'text-red-700',
          },
        ]
      }

      return labels
    },
    [actions, unit],
  )

  if (!labelsList.length) return null

  return (
    <div className="flex flex-col gap-1 lg:flex-row lg:gap-2">
      {labelsList.map(({ msg, bgColor, textColor }) => (
        <div
          className={`flex rounded-[4px] px-1 self-start ${bgColor}`}
          key={msg}
        >
          <span className={`text-[10px] leading-4 font-medium ${textColor}`}>
            {msg}
          </span>
        </div>
      ))}
    </div>
  )
}

export default Labels
