import { formatFiltersParams } from './utils'

export const getProducts = ({
  current,
  pageSize,
  category,
  group,
  subgroup,
  ...rest
}) => {
  const queryParams = formatFiltersParams({
    per_page: pageSize,
    page: current,
    category_id: category,
    group_id: group,
    subgroup_id: subgroup,
    ...rest,
  })

  return {
    url: `me/products?${queryParams}`,
  }
}
