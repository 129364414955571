import styled from 'styled-components'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { TinyTextV2Medium } from 'components/Text'

export const InfographicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${theme.borderRadius.md};
  height: 120px;
  background-color: ${theme.colors.greenLight5};
  padding: ${theme.spacing.ss} ${theme.spacing.base};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.ss} 40px;
  }
`

export const StoreIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 96px;
  gap: ${theme.spacing.xxs};
`

export const StoreIconImg = styled.img`
  width: 58px;
  height: 58px;
`

export const CustomerNoContainer = styled.div`
  display: flex;
  padding: 0 ${theme.spacing.xxs};
  background-color: ${theme.colors.greenLight5};
  border-radius: 4px;
  height: 16px;
`

export const CustomerNoText = styled(TinyTextV2Medium)`
  font-size: 10px;
  color: ${theme.colors.secondary};
`
