import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { Link } from 'components/Text'
import ArrowRight from './ArrowRight.svg'

const Arrow = css`
  &:after {
    content: url("${ArrowRight}");
    display: block;
    height: 16px;
    width: 16px;
    margin-left: 8px;
  }
  &:hover :after {
    cursor: default;
  }
`

export const BreadCrumb = styled(Link)`
  ${p => !p.$hideArrow && Arrow};
  margin-right: 8px;
  margin-top: 4px;
  display: inline-flex;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.xxs};
  line-height: 16px;
  font-weight: ${theme.fontWeights.regular};
`

export const BreadCrumbList = styled(Flex)`
  color: ${theme.colors.primary};
  max-width: 100%;
  flex-wrap: wrap;
`
