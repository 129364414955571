import styled from 'styled-components'

import { AlternateBtn } from 'components/Button'
import theme from 'theme'

export const LicenseBtn = styled(AlternateBtn)`
  &:hover {
    cursor: default;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray2};
    color: ${theme.colors.primary};
  }
`
