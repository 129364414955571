import React from 'react'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  INTERACTION_KINDS,
} from 'components/Popup'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import { TextTooltipContent, TextTooltipContainer } from './styles'

const TextTooltip = ({
  children,
  content,
  interactionKind,
  textContainerStyle,
  targetProps,
  matchTriggerWidth,
  placement = 'top',
  ...props
}) => {
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  if (!content) {
    return children
  }

  const tooltipInteractionKind =
    interactionKind || isDesktop
      ? INTERACTION_KINDS.HOVER
      : INTERACTION_KINDS.CLICK

  return (
    <Popover
      placement={placement}
      interactionKind={tooltipInteractionKind}
      {...props}
    >
      <PopoverContent matchTriggerWidth={matchTriggerWidth}>
        <TextTooltipContainer
          $disableMaxWidth={matchTriggerWidth}
          style={textContainerStyle}
        >
          <TextTooltipContent>{content}</TextTooltipContent>
        </TextTooltipContainer>
      </PopoverContent>
      <PopoverTrigger {...targetProps}>{children}</PopoverTrigger>
    </Popover>
  )
}

export default TextTooltip
