import styled from 'styled-components'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

export const Container = styled.div`
  border-radius: 8px;
  ${p =>
    p.headerBgHoverColor &&
    `
    .invoices-menu-item:hover {
      background-color: ${p.headerBgHoverColor};
    }
  `};
`

export const IconPlaceholder = styled.div`
  width: 24px;
  margin-right: ${theme.spacing.sm};
  flex-shrink: 0;
`

export const Content = styled(BaseTextV2).attrs({ as: 'div' })`
  padding: ${theme.spacing.ss} ${theme.spacing.sm};
  font-size: 12px;
  line-height: 20px;
  display: flex;
  color: ${p => p.$txtColor};
`
