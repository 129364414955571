import { css } from 'styled-components'
import { getColors, getFontWeights } from 'components/helpers/theme-selectors'

const getFontWeight = (props, defaultValue = 'regular') => {
  if (props.strongBold) {
    return getFontWeights(props).strongBold
  }
  if (props.bold) {
    return getFontWeights(props).bold
  }
  if (props.regular) {
    return getFontWeights(props).regular
  }
  return getFontWeights(props)[defaultValue]
}

const genericTextStyles = css`
  color: ${props => props.color || getColors(props).black};
  ${props => props.breakLines && `white-space: pre-wrap; text-align: center;`};
  ${props => props.interactive && `cursor: pointer; user-select: none;`};
`

export const textStyles = css`
  ${genericTextStyles};
  font-weight: ${getFontWeight};
`

export const headingStyles = css`
  ${genericTextStyles};
  font-weight: ${props => getFontWeight(props, 'bold')};
`

export const disableUserSelect = css`
  user-select: none;
`
