import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { H4v2, BaseTextV2 } from 'components/Text'

export const SubSection = styled(Flex)`
  flex-direction: column;
  padding: 0 16px;
`

export const SectionHeader = styled(H4v2)`
  padding: 8px 16px;
  margin-bottom: 16px;
`

export const TextContainer = styled(Flex)`
  padding: 6px 0;
`

export const DataText = styled(BaseTextV2).attrs({
  isSmall: { fontSize: true, lineHeight: true },
})`
  flex-shrink: 1;
`

export const LabelText = styled(DataText)`
  flex-shrink: 0;
  width: 72px;
  margin-right: 16px;
  color: ${theme.colors.lightGrey4};
`
