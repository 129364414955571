import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import BasicBtn from 'components/Button/ButtonV2'
import { BUTTON_TYPES, BUTTON_SIZE } from './consts'

export const buttonStyles = {
  [BUTTON_TYPES.PRIMARY]: css`
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    border: none;
    &:hover {
      background: ${theme.colors.primaryDark};
    }
    &:disabled {
      cursor: default;
      background: ${theme.colors.gray2};
      color: ${theme.colors.gray3};
    }
  `,
  [BUTTON_TYPES.SECONDARY]: css`
    background: ${theme.colors.secondary};
    color: ${theme.colors.white};
    border: none;
    &:hover {
      background: ${theme.colors.secondary};
    }
    &:disabled {
      cursor: default;
      background: ${theme.colors.gray2};
    }
  `,
  [BUTTON_TYPES.ALTERNATE]: css`
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray2};
    color: ${theme.colors.primary};
    &:hover {
      color: ${theme.colors.secondary};
      border: 1px solid ${theme.colors.secondary};
      background: ${theme.colors.white};
    }

    &:disabled {
      cursor: default;
      color: ${theme.colors.gray2};
      border: 1px solid ${theme.colors.gray2};
    }
  `,
  [BUTTON_TYPES.ERROR]: css`
    background: ${theme.colors.error};
    color: ${theme.colors.white};
    border: none;
    &:hover {
      background: ${theme.colors.errorDark};
    }
  `,
  [BUTTON_TYPES.ERROR_ALTERNATE]: css`
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray2};
    color: ${theme.colors.error};
    &:hover {
      color: ${theme.colors.errorDark};
      border: 1px solid ${theme.colors.errorDark};
      background: ${theme.colors.white};
    }
  `,
  [BUTTON_TYPES.WARNING_LIGHT]: css`
    background: ${theme.colors.orange};
    color: ${theme.colors.white};
    border: none;
    &:hover {
      background: ${theme.colors.orange};
    }
  `,
  [BUTTON_TYPES.LINK]: css`
    color: ${theme.colors.primary};
    border: none;
    &:hover {
      background: ${theme.colors.primaryDark};
    }
  `,
  [BUTTON_TYPES.BASIC]: css``,
}

export const buttonSize = {
  [BUTTON_SIZE.BIG]: css`
    min-width: 182px;
    height: 48px;
    padding: 0 ${theme.spacing.sm};
  `,
  [BUTTON_SIZE.MEDIUM]: css`
    height: 44px;
    padding: 0 20px;
  `,
  [BUTTON_SIZE.SMALL]: css`
    min-width: 136px;
    height: 40px;
    line-height: 38px;
    padding: 0 ${theme.spacing.sm};
  `,
  [BUTTON_SIZE.ICON]: css`
    width: 40px;
    height: 40px;
  `,
  [BUTTON_SIZE.BLOCK]: css`
    width: 100%;
    height: 48px;
  `,
  [BUTTON_SIZE.SEARCH]: css`
    width: 48px;
    height: 32px;
  `,
  [BUTTON_SIZE.BASIC]: css``,
}

export const getButtonSize = size =>
  buttonSize[size] || buttonSize[BUTTON_SIZE.BIG]

export const getButtonStyle = type =>
  buttonStyles[type] || buttonStyles[BUTTON_TYPES.PRIMARY]

// prettier-ignore
export const BasicButton = styled.button`
  ${({ type }) => getButtonStyle(type)};
  ${({ size }) => getButtonSize(size)};
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.textSizes.l};
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  &:focus {
    outline: none;
  }
  ${props => props.nowrap && `white-space: nowrap;`};
  ${props => props.active && `
    color: ${theme.colors.secondary};

    svg {
      fill: ${theme.colors.secondary};
    }
  `};
  ${props => props.$fullWidth && `
    width: 100%;
  `}
`

export const BasicButtonStyle = css`
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.medium};
  font-size: 13px;
  line-height: 24px;
  padding: 8px 16px;
  border-radius: 8px;
  min-width: auto;
  height: auto;
`

export const BasicButtonV2 = styled(BasicButton)`
  ${BasicButtonStyle};
  color: ${theme.colors.white};
  background: ${theme.colors.secondary};
  :hover {
    background: ${theme.colors.greenLight};
  }
  :disabled {
    background: ${theme.colors.gray2};

    &:hover {
      background: ${theme.colors.gray2};
    }
  }
`

export const BasicButtonV2Grey = styled(BasicButtonV2)`
  background: ${theme.colors.lightGrey};
  color: ${theme.colors.primary};
  :hover {
    background: ${theme.colors.lightGrey7};
  }
`

export const BasicButtonV2GreySmall = styled(BasicButtonV2Grey)`
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.textSizes.xs};
`

export const ToggleButtonV2 = styled(BasicButtonV2Grey)`
  font-weight: ${theme.fontWeights.regular};
  gap: ${theme.spacing.xs};
  ${props =>
    props.$isActive &&
    `
    color: ${theme.colors.white};
    background: ${theme.colors.secondary};
    :hover {
      background: ${theme.colors.greenLight};
    }
  `};
`

export const GreyWrapper = styled(Flex)`
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 8px;
  background: ${theme.colors.lightGrey};
`

export const InteractiveWrapper = styled(Flex)`
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    background: ${theme.colors.lightGrey};
  }
`

export const TertiaryBtnV2 = styled(BasicBtn).attrs(props => ({
  $bgColor: theme.colors.transparent,
  $hoverColor: theme.colors.lightGrey,
  ...props,
}))``

export const PrimaryBtnV2 = styled(BasicBtn).attrs(props => ({
  $bgColor: theme.colors.secondary,
  $hoverColor: theme.colors.greenLight,
  ...props,
}))``

export const SecondaryBtnV2 = styled(BasicBtn).attrs(props => ({
  $bgColor: theme.colors.lightGrey,
  $hoverColor: theme.colors.lightGrey7,
  ...props,
}))``
