import React from 'react'
import { FormattedMessage } from 'react-intl'

import theme from 'theme'
import { Caption } from 'components/Text'
import { CLICK_OUTSIDE_IGNORED_CLASSNAME } from 'hooks/useOnClickOutside'

import {
  SuggestContainer,
  ProductsContainer,
  ProductsHeader,
  ProductsTitle,
  NoResultsContainer,
  ShowAllTxt,
} from './styles'
import messages from './messages'

const SearchSuggest = ({ children, onShowAll }) => {
  const hasSearchSuggestions = Array.isArray(children)
    ? !!children?.length
    : !!children

  if (hasSearchSuggestions) {
    return (
      <SuggestContainer
        data-test-id="search-suggest-container"
        className={CLICK_OUTSIDE_IGNORED_CLASSNAME}
      >
        <ProductsContainer>
          <ProductsHeader>
            <ProductsTitle>
              <FormattedMessage {...messages.products} />
            </ProductsTitle>

            <ShowAllTxt
              onClick={onShowAll}
              data-test-id="search-suggest-show-all"
            >
              <FormattedMessage {...messages.showAll} />
            </ShowAllTxt>
          </ProductsHeader>

          <div>{children}</div>
        </ProductsContainer>
      </SuggestContainer>
    )
  }

  return (
    <NoResultsContainer
      data-test-id="search_suggestions_no_results"
      className={CLICK_OUTSIDE_IGNORED_CLASSNAME}
    >
      <Caption strongBold color={theme.colors.gray4}>
        <FormattedMessage {...messages.noResults} />
      </Caption>
    </NoResultsContainer>
  )
}

export default SearchSuggest
