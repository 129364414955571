import React, { useState } from 'react'
import {
  DescriptionDropdownContent,
  DescriptionDropdownHeader,
  DropdownHeading,
} from 'views/ProductDetails/DescriptionDropdown/styles'
import { ArrowDownNew, ArrowUp } from 'components/Icons'

const DescriptionDropdown = ({
  children,
  title,
  testId,
  isOpenByDefault = false,
}) => {
  const [isOpen, setOpen] = useState(isOpenByDefault)

  return (
    <>
      <DescriptionDropdownHeader
        onClick={() => setOpen(!isOpen)}
        data-test-id={testId}
      >
        <DropdownHeading>{title}</DropdownHeading>
        {isOpen ? <ArrowUp /> : <ArrowDownNew />}
      </DescriptionDropdownHeader>
      {isOpen && (
        <DescriptionDropdownContent>{children}</DescriptionDropdownContent>
      )}
    </>
  )
}

export default DescriptionDropdown
