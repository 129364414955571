const DELIVERIES = {
  DELIVERY_DATE_CLICK: 'clicked_delivery_date',
}

export const trackDeliveryDateClick = ({ origin, customerNo, day }) => {
  const { routes } = day

  const deliveryRouteScheduled = !!routes.length
  const routeWithOrder = routes.find(r => r.orders.length)

  window.dataLayer.push({
    event: DELIVERIES.DELIVERY_DATE_CLICK,
    customer_no: customerNo,
    delivery_route_scheduled: deliveryRouteScheduled,
    order_placed: !!routeWithOrder,
    logistic_minimum_met: routeWithOrder?.logisticLackAmount === 0,
    origin,
  })
}
