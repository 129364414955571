import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { bindActionCreators } from 'utils/redux-utils'
import { createStructuredSelector } from 'reselect'
import { hideNotification } from 'containers/App/actions/notifications'

import {
  notificationMessageSelector,
  notificationDisplaySelector,
  notificationTypeSelector,
} from '../selectors'

import { HeaderNotification } from './styles'

class Notifications extends React.PureComponent {
  componentDidMount() {
    this.props.clear()
  }

  render() {
    const { isOpen, message, type, isMobile } = this.props

    return (
      <HeaderNotification visible={isOpen} type={type} {...{ isMobile }}>
        {message instanceof Object && message.id ? (
          <FormattedMessage {...message} />
        ) : (
          message
        )}
      </HeaderNotification>
    )
  }
}

Notifications.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  clear: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  isOpen: notificationDisplaySelector,
  message: notificationMessageSelector,
  type: notificationTypeSelector,
})

const mapDispatchToProps = bindActionCreators({
  clear: hideNotification,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications)
