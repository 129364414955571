import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import { ICONS_POSITION, ICON_SIZE } from './consts'
import IconWrapper from './IconWrapper'

const withIcon = ({
  iconSize = ICON_SIZE.NORMAL,
  iconType,
  iconPosition: position = ICONS_POSITION.LEFT,
  children,
  ...props
}) => Component => {
  switch (position) {
    case ICONS_POSITION.RIGHT:
      return (
        <Component {...props}>
          <span>{children}</span>
          <IconWrapper position={position} iconSize={iconSize}>
            <Icon type={iconType} size={iconSize} />
          </IconWrapper>
        </Component>
      )
    case ICONS_POSITION.CENTER:
      return (
        <Component {...props}>
          <IconWrapper position={position} iconSize={iconSize}>
            <Icon type={iconType} size={iconSize} />
          </IconWrapper>
        </Component>
      )
    default:
      return (
        <Component {...props}>
          <IconWrapper position={position} iconSize={iconSize}>
            <Icon type={iconType} size={iconSize} />
          </IconWrapper>
          <span>{children}</span>
        </Component>
      )
  }
}

withIcon.propTypes = {
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
  iconType: PropTypes.string.isRequired,
  position: PropTypes.oneOf(Object.values(ICONS_POSITION)).isRequired,
}

export default withIcon
