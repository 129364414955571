import theme from 'theme'
import styled from 'styled-components'
import { H4v2 } from 'components/Text'

export const FilteredPageTileWrapper = styled.div`
  display: flex;
  flex: 1 0;
  border-radius: 16px;
  position: relative;
  min-height: 147.5px;
  overflow: hidden;
  padding: ${theme.spacing.sm};
  cursor: pointer;
  transition: all ease-out 300ms;
  background: ${theme.colors.lightGrey};
  &:hover {
    background: ${theme.colors.lightGrey7};
  }
  img {
    transform: scale(1);
    transition: all ease-out 300ms;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`

export const FilteredPageTileTitle = styled(H4v2)``

export const FilteredPageTileImage = styled.img`
  position: absolute;
  height: 100%;
  align-self: center;
  right: 0;
`
