import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'
import { InteractiveWrapper } from 'components/Button/styles'

export const InvoiceMenuWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: center;
  width: 24px;
  height: 24px;

  ${p =>
    p.$isAlignedTop &&
    `
    margin-top: 10px;
    align-self: flex-start;
  `};

  ${p =>
    p.$isActive &&
    `
    cursor: pointer;
    circle {
      fill: ${theme.colors.gray5};
    }

    :hover {
      circle {
        fill: ${theme.colors.primary};
      }
    }
  `};
`

export const InvoiceMenuContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.sm};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 34px;
  background-color: ${theme.colors.white};
  border-radius: 20px;
`

export const InvoiceMenuMobileContainer = styled(InvoiceMenuContainer)`
  position: fixed;
  background: ${theme.colors.white};
  z-index: ${theme.zIndex.modalOverlay + 1};
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
  border-radius: 20px;
`

export const InvoiceMenuItemContainer = styled(InteractiveWrapper)`
  gap: ${theme.spacing.sm};
  width: 100%;

  :hover {
    background: ${theme.colors.lightGrey6};
  }
`

export const InvoiceMenuItemText = styled(BaseTextV2)``

// remove all stylings from button and treat like interactive wrapper
export const InvoiceMenuBtn = styled.button`
  all: unset;
`
