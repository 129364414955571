import React from 'react'
import { FormattedMessage } from 'react-intl'

import { formatPrice } from 'utils'
import { useOfMaxWidth } from 'hooks'

import { APP_BREAKPOINTS } from 'consts'

import { parseAmount } from '../../utils'
import { DetailsWrapper, MobileLeftBottomWrapper } from '../styledComponents'
import InfoColumn from '../InfoColumn'
import messages from '../messages'

const DetailsRow = ({
  promotion,
  isDiscountPromo,
  isSalePromo,
  unitOfMeasureObj,
  unitObj,
  productId,
}) => {
  const { unitOfMeasureDescription, priceNet, multiplier } = unitOfMeasureObj
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const inRow = isMobile

  return (
    <>
      {isMobile && <MobileLeftBottomWrapper mt={16} />}

      <DetailsWrapper inColumn={isMobile}>
        <InfoColumn
          title={<FormattedMessage {...messages.netPrice} />}
          value={formatPrice(isSalePromo ? priceNet : unitObj.priceNet)}
          {...{ inRow }}
        />

        <InfoColumn
          title={
            <FormattedMessage
              {...messages.pricePerUnit}
              values={{
                unit: unitOfMeasureDescription,
              }}
            />
          }
          value={formatPrice(
            isDiscountPromo ? promotion.reward.priceNet : priceNet,
          )}
          {...{ inRow }}
        />

        <InfoColumn
          title={<FormattedMessage {...messages.packaging} />}
          value={`${parseAmount(multiplier)} ${unitOfMeasureDescription}`}
          {...{ inRow }}
        />

        <InfoColumn
          title={<FormattedMessage {...messages.articleNo} />}
          value={productId}
          {...{ inRow }}
        />
      </DetailsWrapper>
    </>
  )
}

export default DetailsRow
