import React from 'react'

import theme from 'theme'

const DeliveryTruckAlert = ({
  color = theme.colors.orangeDark2,
  borderColor = theme.colors.orange3,
}) => (
  <svg
    width="32"
    height="28"
    viewBox="0 0 32 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 21H29C30.1046 21 31 20.1046 31 19V16H24V19"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M29 11L31 16H24V11H29Z"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M24 19V10C24 8.89543 23.1046 8 22 8H11C9.89543 8 9 8.89543 9 10V19C9 20.1046 9.89543 21 11 21H12"
      stroke={color}
      strokeWidth="2"
    />
    <path d="M17 21H23" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <rect x="1" y="1" width="15" height="15" rx="4" fill={color} />
    <rect
      x="1"
      y="1"
      width="15"
      height="15"
      rx="4"
      stroke={borderColor}
      strokeWidth="2"
    />
    <path
      d="M7.52586 5.27501C7.51172 4.85123 7.85142 4.5 8.27544 4.5H8.72459C9.14859 4.5 9.48829 4.85121 9.47417 5.27498L9.34946 9.01666C9.34047 9.28616 9.11939 9.5 8.84973 9.5H8.15041C7.88076 9.5 7.65968 9.28617 7.65069 9.01667L7.52586 5.27501Z"
      fill="white"
    />
    <path
      d="M9.5 11.5C9.5 12.0523 9.05228 12.5 8.5 12.5C7.94772 12.5 7.5 12.0523 7.5 11.5C7.5 10.9477 7.94772 10.5 8.5 10.5C9.05228 10.5 9.5 10.9477 9.5 11.5Z"
      fill="white"
    />
    <circle cx="14.5" cy="21.5" r="2.5" stroke={color} strokeWidth="2" />
    <circle cx="25.5" cy="21.5" r="2.5" stroke={color} strokeWidth="2" />
  </svg>
)

export default DeliveryTruckAlert
