import { createActionsGroup } from 'utils/redux-utils'

export const resetPasswordActions = createActionsGroup('RESET_PASSWORD')
export const confirmResetPasswordActions = createActionsGroup(
  'CONFIRM_RESET_PASSWORD',
)

export const validateResetPasswordTokenActions = createActionsGroup(
  'VALIDATE_RESET_PASSWORD_TOKEN',
)
