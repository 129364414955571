import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { H4 } from 'components/Text'
import { AddProductsButton } from 'components/Button'
import ButtonsContainer from 'components/Product/ButtonsContainer'
import { AmountContainer } from 'components/Product/Amount/styles'
import { BasicButton } from 'components/Button/styles'

import { APP_BREAKPOINTS } from 'consts'

const { spacing, colors } = theme

export default styled(ButtonsContainer)`
  ${AmountContainer}, ${BasicButton}:enabled, ${H4} {
    color: ${colors.secondary};
    background: ${colors.secondaryBgLight};
  }

  ${AmountContainer} {
    width: 110px;
    border: none;
    border-left: 1px solid ${colors.white};
    border-right: 1px solid ${colors.white};

    :disabled {
      -webkit-text-fill-color: ${colors.secondary};
      opacity: 1;
    }
  }

  ${BasicButton} {
    border: none;
    width: ${spacing.md};

    :disabled {
      color: ${colors.borderGreen};
      background-color: ${colors.secondaryBgLight};
    }
  }

  ${AddProductsButton} {
    padding: 0 ${spacing.base};
    width: 174px;

    :enabled {
      color: ${colors.white};
      background-color: ${colors.secondary};
    }

    :disabled {
      color: ${colors.borderGreen};
      background-color: ${colors.bgLightGreen};
    }

  }

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-left: ${theme.spacing.base};
  }

  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    margin-top: ${spacing.sm};

    > ${Flex} {
      width: 100%;
    }

    ${AddProductsButton} {
      width: 100%;
    }

    form {
      width: 100%;

      ${AmountContainer} {
        width: 100%;
      }
    }
  }
`
