import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
import {
  createReducer,
  reducer as commonFetchReducer,
} from 'utils/simpleDataReducer'
import { handleActions } from 'redux-actions'

import { trackFilters } from 'services/analytics'
import { updateCartItem, deleteCartItem } from 'containers/Cart/actions'
import { resetProductUnitsOfMeasureInCart } from 'containers/Products/reducer'
import {
  TEMPLATES_REDUCER_NAME,
  CURRENT_TEMPLATE_REDUCER_NAME,
  TEMPLATE_PRODUCT_DETAILS_REDUCER_NAME,
  TEMPLATE_NOT_ADDED_ITEMS_REDUCER_NAME,
  TEMPLATE_AVAILABLE_FILTERS_REDUCER_NAME,
  TEMPLATE_SELECTED_FILTERS_REDUCER_NAME,
} from './consts'

import {
  addTemplateToCartActions,
  templatesActions,
  templateItemsActions,
  getTemplateActions,
  getTemplateProductDetails,
  templateNotAddedItemsActions,
  getTemplateFiltersActions,
  applyTemplateFilters,
  clearTemplateFilters,
  templateStatusChange,
} from './actions'

import { getTemplateFromState } from './utils'

const initialState = fromJS({
  isOpen: false,
  productId: null,
  unitOfMeasure: null,
})

const templateNotAddedItemsReducer = (
  // eslint-disable-next-line default-param-last
  state = fromJS({ items: null, templateName: null }),
  action,
) => {
  switch (action.type) {
    case templateNotAddedItemsActions.SUCCESS: {
      return state
        .set('items', fromJS(action.data.items))
        .set('templateName', action.data.templateName)
        .set('templateId', action.data.templateId)
        .set('type', action.data.type)
    }
    case templateNotAddedItemsActions.CLEAR: {
      return state.set('items', null).set('templateName', null)
    }
    default:
      return state
  }
}

export const templatesReducer = (state, action) => {
  if (action.type === templatesActions.CREATE) {
    return state
      .set('isFetching', false)
      .set('finished', true)
      .update('data', data =>
        fromJS([action.data, ...(data ? data.toJS() : [])]),
      )
  }

  if (action.type === getTemplateActions.SUCCESS) {
    const {
      data: { itemsCount, id },
    } = action
    const data = state.get('data')

    if (data) {
      const [templateToUpdate, templateIndex] = getTemplateFromState(data, id)

      if (templateToUpdate.get('itemsCount') !== itemsCount) {
        return state.updateIn(['data', templateIndex], template =>
          template.set('itemsCount', itemsCount),
        )
      }
    }
  }

  return commonFetchReducer(state, action, templatesActions)
}

const currentTemplateReducer = createReducer(templateItemsActions)
export const currentTemplateHandlers = handleActions(
  {
    [updateCartItem.SUCCESS]: (state, { data: { cartItem } }) => {
      let updState = state
      const items = state.getIn(['data', 'items']) || []
      items.forEach((item, itemIndex) => {
        const product = item.get('product')
        if (cartItem && cartItem.product.id === product.get('id')) {
          updState = state
            .mergeIn(
              ['data', 'items', itemIndex, 'product'],
              product.merge(cartItem.product),
            )
            .setIn(['data', 'items', itemIndex, 'units'], cartItem.units)
        }
      })

      return updState
    },
    [deleteCartItem.SUCCESS]: (
      state,
      { data: { cartItem }, additionalData },
    ) => {
      let updState = state

      if (!cartItem) {
        const items = state.getIn(['data', 'items']) || []
        items.forEach((item, itemIndex) => {
          const product = item.get('product')
          if (additionalData.productId === product.get('id')) {
            updState = state
              .mergeIn(
                ['data', 'items', itemIndex, 'product', 'unitsOfMeasure'],
                resetProductUnitsOfMeasureInCart(
                  product.get('unitsOfMeasure'),
                  additionalData.unitOfMeasure,
                ),
              )
              .setIn(['data', 'items', itemIndex, 'units'], [])
          }
        })
      }

      return updState
    },
    [templateStatusChange]: (state, { payload: updProduct }) => {
      let updState = state

      const items = state.getIn(['data', 'items']) || []
      const itemIndex = items.findIndex(
        i => i.get('productId') === updProduct.id,
      )

      if (itemIndex !== -1) {
        updState = state.mergeIn(
          ['data', 'items', itemIndex, 'product'],
          updProduct,
        )
      }

      return updState
    },
    [addTemplateToCartActions.SUCCESS]: (state, { data: { cartItems } }) => {
      let updState = state

      const items = state.getIn(['data', 'items']) || []

      items.forEach((item, itemIndex) => {
        const updItem = cartItems.find(
          ({ product }) => product.id === item.get('productId'),
        )

        if (updItem) {
          updState = updState.mergeIn(['data', 'items', itemIndex], updItem)
        }
      })

      return updState
    },
  },
  initialState,
)

export const availableFiltersReducer = createReducer(getTemplateFiltersActions)
export const selectedFiltersReducer = handleActions(
  {
    [applyTemplateFilters]: (
      state,
      {
        payload: { id, name, filters, isMultipleFilters = false, eventValues },
      },
    ) => {
      trackFilters({
        templateId: id,
        filterName: name,
        filterValues: filters,
        eventValues,
      })

      // prettier-ignore
      return isMultipleFilters
        ? {
          ...state,
          ...filters,
        } : {
          ...state,
          [name]: { ...filters },
        }
    },
    [clearTemplateFilters]: () => ({}),
  },
  {},
)

export default combineReducers({
  [TEMPLATES_REDUCER_NAME]: templatesReducer,
  [TEMPLATE_PRODUCT_DETAILS_REDUCER_NAME]: createReducer(
    getTemplateProductDetails,
  ),
  [TEMPLATE_NOT_ADDED_ITEMS_REDUCER_NAME]: templateNotAddedItemsReducer,
  [CURRENT_TEMPLATE_REDUCER_NAME]: (state, action) => {
    const newState = currentTemplateReducer(state, action)
    return currentTemplateHandlers(newState, action)
  },
  [TEMPLATE_AVAILABLE_FILTERS_REDUCER_NAME]: availableFiltersReducer,
  [TEMPLATE_SELECTED_FILTERS_REDUCER_NAME]: selectedFiltersReducer,
})
