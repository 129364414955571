import styled from 'styled-components'
import theme from 'theme'
import { H2v2 } from 'components/Text'
import { breakPointTablet } from 'components/Layout'
import { CarouselOverflowContainer } from 'components/Carousel/styles'
import { APP_BREAKPOINTS } from 'consts'
import { BasicButtonV2 } from 'components/Button'

export const RecommendedBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: ${theme.spacing.md} 0;
  ${CarouselOverflowContainer} {
    @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
      margin: 0 -${theme.spacing.md};
      padding: 0 ${theme.spacing.md};
    }
    @media screen and (max-width: ${breakPointTablet}) {
      margin: 0 -${theme.spacing.sm};
      padding: 0 ${theme.spacing.sm};
    }
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled(H2v2)`
  font-weight: ${theme.fontWeights.bold};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: ${theme.headingSize.l};
    line-height: 40px;
  }
`

export const SeeAllButton = styled(BasicButtonV2)`
  background: ${theme.colors.transparent};
  color: ${theme.colors.secondary};
  padding: ${theme.spacing.xs} ${theme.spacing.ss};
  line-height: 20px;
  &:hover {
    background-color: ${theme.colors.lightGrey};
  }
`
