import styled from "styled-components"

import theme from "theme"

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.base};
  *:not(:last-child) {
    margin-right: ${theme.spacing.xs};
  }
`
