import * as Sentry from '@sentry/react'

export const trackLocationChange = userData => {
  const {
    storeName,
    stockName,
    customerDiscountGroup,
    accountId,
    customerEmail,
    login,
    customerNo,
    storeId,
  } = userData

  window.dataLayer.push({
    event: 'signIn',
    store_name: storeName,
    store_id: String(storeId),
    customer_no: customerNo,
    stock_name: stockName,
    customer_discount_group: customerDiscountGroup,
  })

  Sentry.setUser({
    id: accountId,
    email: customerEmail,
    username: login,
    customer_no: customerNo,
    store_id: String(storeId),
  })
}
