import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ROUTES } from 'consts'

import { Link, P } from 'components/Text'

import Notification from '../Notification'
import messages from './messages'
import { StyledButton } from './styledComponents'

const Resend = ({ email }) => (
  <Notification hideFooter header={messages.resend} width="490px">
    <P bold style={{ marginTop: '8px' }}>
      <FormattedMessage
        {...messages.resendDescription}
        values={{ value: <b>{email}</b> }}
      />
    </P>

    <Link to={ROUTES.LOGIN}>
      <StyledButton>
        <FormattedMessage {...messages.toLogin} />
      </StyledButton>
    </Link>
  </Notification>
)

export default Resend
