import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { Document as DocumentIcon } from 'components/Icons'
import ContactFormModalWrapper from 'components/ContactForm/ContactFormModal/Wrapper'
import {
  EVENT_ORIGIN,
  PROFILE_EVENTS,
  trackSimpleEvent,
} from 'services/analytics'

import messages from '../messages'
import { OtherRowContainer } from '../styles'
import OtherRow from '../OtherRow'

const ContactForm = () => {
  const { formatMessage } = useIntl()
  const [isContactFormOpen, setContactFormOpen] = useState(false)

  const handleContactFormOpen = () => {
    setContactFormOpen(true)
    trackSimpleEvent(PROFILE_EVENTS.CONTACT_FORM_CLICKED, {
      origin: EVENT_ORIGIN.USER_SETTINGS,
      button: formatMessage(messages.contactForm),
    })
  }

  return (
    <ContactFormModalWrapper
      isContactFormOpen={isContactFormOpen}
      onContactFormClose={() => setContactFormOpen(false)}
    >
      <OtherRowContainer
        onClick={handleContactFormOpen}
        data-test-id="contact-form-row"
      >
        <OtherRow
          text={formatMessage(messages.contactForm)}
          icon={<DocumentIcon />}
        />
      </OtherRowContainer>
    </ContactFormModalWrapper>
  )
}

export default ContactForm
