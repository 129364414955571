import React from 'react'
import { FormattedMessage } from 'react-intl'

import theme from 'theme'
import { DotsMenu3, Trash, Edit } from 'components/Icons'
import { ControlLabel } from 'components/Text'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'

import messages from '../messgaes'
import {
  ContentContainer,
  DotsMenuBtn,
  MenuItemContainer,
  MenuItem,
  MenuItemIcon,
  Separator,
  DotsIconContainer,
} from './styles'

const TemplateMenu = ({ isOpen, onToggleOpen, onClose, onEdit, onRemove }) => (
  <Popover
    open={isOpen}
    onClose={onClose}
    onClickOutside={onClose}
    placement="top"
  >
    <PopoverTrigger asChild>
      <DotsMenuBtn
        onClick={ev => {
          ev.stopPropagation()
          ev.preventDefault()
          onToggleOpen()
        }}
        data-test-id="template-menu"
      >
        <DotsIconContainer>
          <DotsMenu3 />
        </DotsIconContainer>
      </DotsMenuBtn>
    </PopoverTrigger>
    <PopoverContent>
      <ContentContainer>
        <MenuItemContainer>
          <MenuItem
            onClick={ev => {
              ev.stopPropagation()
              ev.preventDefault()
              onEdit()
            }}
            data-test-id="template-menu-edit"
          >
            <MenuItemIcon>
              <Edit />
            </MenuItemIcon>

            <ControlLabel>
              <FormattedMessage {...messages.edit} />
            </ControlLabel>
          </MenuItem>
        </MenuItemContainer>

        <Separator />

        <MenuItemContainer>
          <MenuItem
            onClick={ev => {
              ev.stopPropagation()
              ev.preventDefault()
              onRemove()
            }}
            data-test-id="template-menu-remove"
          >
            <MenuItemIcon>
              <Trash color={theme.colors.redDarkText} />
            </MenuItemIcon>

            <ControlLabel color={theme.colors.redDarkText}>
              <FormattedMessage {...messages.delete} />
            </ControlLabel>
          </MenuItem>
        </MenuItemContainer>
      </ContentContainer>
    </PopoverContent>
  </Popover>
)

export default TemplateMenu
