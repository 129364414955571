import React from 'react'

import { Check } from 'components/Icons'
import { CheckboxWrapperV2 } from 'components/Input/styles'
import Checkbox from 'components/Input/Checkbox'
import {
  CheckBoxWrapper,
  CheckBoxLabel,
  CheckBoxLabelContainer,
} from './styles'

const CheckBox = ({ label, onChange, children, checked, ...rest }) => (
  <CheckBoxWrapper checked={checked} data-test-id={`filter-item#${rest.id}`}>
    <Checkbox
      icon={<Check />}
      onChange={ev => onChange(ev.target.checked)}
      Wrapper={CheckboxWrapperV2}
      checked={checked}
      {...rest}
    />
    <CheckBoxLabelContainer>
      <CheckBoxLabel>{label}</CheckBoxLabel>
      {children}
    </CheckBoxLabelContainer>
  </CheckBoxWrapper>
)

export default CheckBox
