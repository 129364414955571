import React from 'react'
import { useIntl } from 'react-intl'

import { CartCrossedV2 as NoOrdersIcon } from 'components/Icons'

import messages from '../messages'
import { Container, Text } from './styles'

const NoOrders = () => {
  const { formatMessage } = useIntl()

  return (
    <Container>
      <NoOrdersIcon />
      <Text>{formatMessage(messages.noOrdersText)}</Text>
    </Container>
  )
}

export default NoOrders
