import React from 'react'
import { useIntl } from 'react-intl'

import messages from '../../../messages'
import {
  ColumnContainer,
  ColumnHeader,
  ColumnRow,
  ColumnRowLabel,
  ColumnRowText,
} from './styles'

const Column = ({ config }) => {
  const { formatMessage } = useIntl()

  return (
    <ColumnContainer data-test-id={config.containerTestId}>
      <ColumnHeader>{config.header}</ColumnHeader>
      <ColumnContainer>
        {config.rows.map(row => (
          <ColumnRow key={row.label}>
            <ColumnRowLabel>{`${row.label}:`}</ColumnRowLabel>
            <ColumnRowText>
              {row.value || formatMessage(messages.none)}
            </ColumnRowText>
          </ColumnRow>
        ))}
      </ColumnContainer>
    </ColumnContainer>
  )
}

export default Column
