import React from 'react'

const Croissant = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_33991_107537)">
      <path
        d="M3.96317 15.7698C3.87184 15.6054 3.64856 15.5741 3.51558 15.7071L2.50411 16.7185C1.19976 18.0229 2.06498 20.2556 3.90765 20.3404L6.06363 20.4396C6.28502 20.4498 6.43172 20.2131 6.32409 20.0194L3.96317 15.7698Z"
        fill="#E39600"
      />
      <path
        d="M20.0196 6.32484C20.2134 6.43247 20.4501 6.28578 20.4399 6.06438L20.3406 3.90746C20.2558 2.06479 18.0231 1.19957 16.7187 2.50392L15.7067 3.51598C15.5737 3.64896 15.605 3.87224 15.7694 3.96357L20.0196 6.32484Z"
        fill="#E39600"
      />
      <path
        d="M6.72013 10.0689C6.36399 9.50925 6.44433 8.77735 6.91339 8.30829L8.31012 6.91156C8.77918 6.4425 9.51108 6.36216 10.0707 6.7183L18.6328 12.1669C19.3967 12.653 19.514 13.7215 18.8738 14.3617L14.3635 18.8719C13.7233 19.5122 12.6548 19.3949 12.1687 18.631L6.72013 10.0689Z"
        fill="#E39600"
      />
      <path
        d="M6.05024 11.6569C5.95302 11.5041 5.73933 11.4806 5.61128 11.6087L4.86073 12.3592C4.41391 12.8061 4.31743 13.495 4.6243 14.0473L8.19847 20.4808C8.65512 21.3028 9.77154 21.4592 10.4364 20.7943L11.1577 20.073C11.2681 19.9626 11.267 19.7845 11.1685 19.6635C11.0986 19.5777 11.0329 19.4867 10.9717 19.3906L6.05024 11.6569Z"
        fill="#F7C26E"
      />
      <path
        d="M19.6625 11.1694C19.7836 11.268 19.9617 11.269 20.0721 11.1586L20.7929 10.4378C21.4578 9.77291 21.3014 8.65648 20.4795 8.19984L14.046 4.62567C13.4936 4.31879 12.8047 4.41527 12.3579 4.8621L11.6078 5.61222C11.4797 5.74026 11.5032 5.95396 11.6559 6.05118L19.3897 10.9726C19.4858 11.0338 19.5767 11.0996 19.6625 11.1694Z"
        fill="#F7C26E"
      />
    </g>
    <defs>
      <clipPath id="clip0_33991_107537">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Croissant
