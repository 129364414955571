import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { H4 } from 'components/Text'

export const Header = styled(Flex)``

export const NoReplacementsContainer = styled(Flex)`
  border: 1px solid rgba(221, 226, 229, 0.5);
  padding: 48px 24px;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  text-align: center;
`

export const NoReplacementsTitle = styled(H4)`
  font-weight: 600;
`
