import * as React from 'react'

import theme from 'theme'

const Truck2 = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M20 17h1a2 2 0 002-2v-3h-7v3M21 7l2 5h-7V7h5z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M16 15V6a2 2 0 00-2-2h-4M4 17H3a2 2 0 01-2-2v-1M9 17h6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M2 5.05c0-.337.081-.669.236-.966.155-.298.38-.551.653-.738a1.956 1.956 0 011.877-.19c.303.13.571.333.78.594.209.26.351.57.416.9a2.1 2.1 0 01-.048.995 2.06 2.06 0 01-.5.855L2 10h4"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={6.5} cy={17.5} r={2.5} stroke={color} strokeWidth={2} />
    <circle cx={17.5} cy={17.5} r={2.5} stroke={color} strokeWidth={2} />
  </svg>
)

export default Truck2
