import queryString from 'query-string'

export const RTK_PRODUCT_TAG = 'product'

export const formatFiltersParams = ({
  search,
  brands,
  storageTypes,
  productFeatures,
  offerFeatures,
  onStock,
  promotion,
  sortBy,
  cuisineFeatures,
  ...rest
}) =>
  queryString.stringify(
    {
      search,
      brands,
      storage_types: storageTypes,
      product_features: productFeatures,
      on_stock: onStock,
      sort_by: sortBy,
      offer_features: offerFeatures,
      promotion,
      cuisine_features: cuisineFeatures,
      ...rest,
    },
    { arrayFormat: 'bracket' },
  )

export const provideProductsQueryTags = result => {
  if (!result?.products) return []
  const tags = result.products.map(product => ({
    type: RTK_PRODUCT_TAG,
    id: product.id,
  }))
  return tags
}
