import styled from 'styled-components'
import { withIcon, ICONS_POSITION, ICONS, ICON_SIZE } from 'components/Icon'
import { AlternateBtn, BUTTON_SIZE } from 'components/Button'
import { getSpacing } from '../helpers/theme-selectors'

export const BackButton = props =>
  withIcon({
    ...props,
    iconSize: ICON_SIZE.SMALL,
    iconType: ICONS.CHEVRON_LEFT,
    iconPosition: ICONS_POSITION.LEFT,
    size: BUTTON_SIZE.SMALL,
  })(styled(AlternateBtn)`
    min-width: auto;
    border-radius: 76px;
    padding: 0 ${p => getSpacing(p).sm};
    span {
      line-height: 19px;
      margin-top: -2px;
    }
  `)
