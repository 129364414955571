import React from 'react'

import theme from 'theme'

const FloatingPriceOutlineBig = ({ color = theme.colors.infoBlue2 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-test-id="floating-price-outline-icon"
  >
    <rect
      x="5"
      y="4"
      width="14"
      height="15.9996"
      rx="1.99993"
      stroke={color}
      strokeWidth="2"
    />
    <rect x="11" y="6" width="2" height="7.49981" fill={color} />
    <rect
      x="15.75"
      y="9"
      width="2"
      height="7.49981"
      transform="rotate(90 15.75 9)"
      fill={color}
    />
    <rect
      x="15.75"
      y="15"
      width="2"
      height="7.49981"
      transform="rotate(90 15.75 15)"
      fill={color}
    />
  </svg>
)

export default FloatingPriceOutlineBig
