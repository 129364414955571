import React from 'react'

import theme from 'theme'
import { CheckV2, CartV2 } from 'components/Icons'

import { AddToCartButton, BtnPlaceholder, IconContainer } from './styles'

const AddToCartBtn = ({
  unitOfMeasure,
  onAddToCart,
  onDeleteFromCart,
  disabled,
  isInactive,
  style,
}) => {
  const isInCart = !!unitOfMeasure.inCartQuantity

  if (isInactive) {
    return <BtnPlaceholder />
  }

  return (
    <AddToCartButton
      disabled={disabled}
      $isInCart={isInCart}
      onClick={isInCart ? onDeleteFromCart : onAddToCart}
      data-test-id="add_to_cart_button"
      style={style}
    >
      <IconContainer data-test-id="add_btn_icon_container">
        {isInCart ? (
          <CheckV2 color={theme.colors.secondary} data-test-id="in_cart_icon" />
        ) : (
          <CartV2 data-test-id="add_to_cart_icon" />
        )}
      </IconContainer>
    </AddToCartButton>
  )
}

export default AddToCartBtn
