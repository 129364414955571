import React from 'react'

import FilteredPageTile from 'components/FilteredPageTile'
import { PreselectedFilterPagesContainer } from 'views/Dashboard/FilteredPagesTile/styles'
import { DASHBOARD_FILTERED_PAGE_VARIANTS } from 'views/Dashboard/FilteredPagesTile/consts'
import { FILTERED_PAGE_MAP } from 'components/FilteredPageTile/consts'

const FilteredPagesTile = ({ tileCount, offerDetails }) => {
  if (!offerDetails || !tileCount) return null

  return (
    <PreselectedFilterPagesContainer
      $tileCount={tileCount}
      data-test-id="filtered-pages-tile-container"
    >
      {offerDetails.hasNovelties && (
        <FilteredPageTile
          variantConfig={
            DASHBOARD_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.NOVELTIES]
          }
        />
      )}
      {offerDetails.hasActiveClearances && (
        <FilteredPageTile
          variantConfig={
            DASHBOARD_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.CLEARANCE]
          }
        />
      )}
      {offerDetails.hasBestsellers && (
        <FilteredPageTile
          variantConfig={
            DASHBOARD_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.BESTSELLERS]
          }
        />
      )}
      {offerDetails.hasActivePromotions && (
        <FilteredPageTile
          variantConfig={
            DASHBOARD_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.PROMOTIONS]
          }
        />
      )}
    </PreselectedFilterPagesContainer>
  )
}

export default FilteredPagesTile
