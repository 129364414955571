import * as React from 'react'

import theme from 'theme'

const GoTo = ({
  color = theme.colors.secondary,
  width = 24,
  height = 24,
  style,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'rotate(180deg)', ...style }}
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M12 16V4M8 20h8M16 12l-4 4-4-4"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GoTo
