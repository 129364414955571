import styled from 'styled-components'
import { Columns } from 'layouts'
import { getSpacing } from 'components/helpers/theme-selectors'

export * from './Tabs'
export * from './Row'
export * from './FixedBottom'

export const breakPointTablet = '600px' // TODO: use the value from theme

export const ContentWrapper = styled(Columns)`
  padding: ${props => getSpacing(props).md};

  @media screen and (max-width: ${breakPointTablet}) {
    padding: ${props => getSpacing(props).sm};
  }
`
