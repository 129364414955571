import React from 'react'

import theme from 'theme'

const TruckSpeed = ({ color = theme.colors.primary, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    data-test-id="truck-icon"
    {...rest}
  >
    <path
      d="M20 17H21C22.1046 17 23 16.1046 23 15V12H16V15"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M21 7L23 12H16V7H21Z"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M12 4H14.0004C15.1049 4 16.0004 4.89543 16.0004 6V15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M9 17H15" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M5 8H10" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M3 4H8" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M1 12H6" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <circle cx="6.5" cy="17.5" r="2.5" stroke={color} strokeWidth="2" />
    <circle cx="17.5" cy="17.5" r="2.5" stroke={color} strokeWidth="2" />
  </svg>
)

export default TruckSpeed
