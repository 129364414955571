import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { ORDER_STATUS } from 'views/Orders/consts'

import messages from 'views/Orders/messages'
import { OrderStatusTagContainer, OrderStatusTagText } from './styles'

const ORDER_STATUS_CONFIG = {
  [ORDER_STATUS.TO_BE_ACCEPTED]: {
    bgColor: theme.colors.orange3,
    textColor: theme.colors.orangeDark2,
  },
  [ORDER_STATUS.ACCEPTED]: {
    bgColor: theme.colors.greenLight5,
    textColor: theme.colors.secondary,
  },
  [ORDER_STATUS.IN_PROGRESS]: {
    bgColor: theme.colors.greenLight5,
    textColor: theme.colors.secondary,
  },
  [ORDER_STATUS.COMPLETED]: {
    bgColor: theme.colors.semiTranspBg.grayLight,
    textColor: theme.colors.lightGrey4,
  },
  [ORDER_STATUS.CANCELED]: {
    bgColor: theme.colors.redLight2,
    textColor: theme.colors.redDarkText,
  },
}

const OrderStatusTag = ({ orderStatusId }) => {
  const { formatMessage } = useIntl()

  const config = ORDER_STATUS_CONFIG[orderStatusId]

  if (!config) return null

  return (
    <OrderStatusTagContainer
      style={{ backgroundColor: config.bgColor }}
      data-test-id="order-status-tag"
    >
      <OrderStatusTagText style={{ color: config.textColor }}>
        {formatMessage(messages[`status${orderStatusId}`])}
      </OrderStatusTagText>
    </OrderStatusTagContainer>
  )
}

export default OrderStatusTag
