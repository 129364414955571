import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { path } from 'lodash/fp'

import { PROFILE_ROUTES } from 'containers/Profile/consts'

import { PageContainer, BackBtn } from './components/styledComponents'

const ProfileWrapper = () => {
  const location = useLocation()
  const isBackAllowed = ![
    PROFILE_ROUTES.PROFILE,
    PROFILE_ROUTES.NEWSLETTER_UNSUBSCRIBE_SUCCESS,
  ].some(p => p === location.pathname)
  const isBackActive = isBackAllowed && !path('state.suppressBack', location)

  return (
    <PageContainer
      padded={
        PROFILE_ROUTES.NEWSLETTER_UNSUBSCRIBE_SUCCESS !== location.pathname
      }
    >
      {isBackActive && <BackBtn data-test-id="backButton" />}
      <Outlet />
    </PageContainer>
  )
}

export default ProfileWrapper
