import React from 'react'
import PropTypes from 'prop-types'
import _camelCase from 'lodash/camelCase'
import sprite from 'feather-icons'
import { getIconSize, ICON_SIZE } from './consts'

const Icon = ({ type, size = ICON_SIZE.NORMAL, iconSvgProps, ...props }) => {
  const { attrs, contents } = sprite.icons[type]
  const svgProps = Object.entries(attrs).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key === 'class' ? 'className' : _camelCase(key)]: value,
    }),
    {},
  )

  return React.createElement('svg', {
    ...svgProps,
    ...iconSvgProps,
    fill: 'auto',
    ...props,
    width: getIconSize(size),
    height: getIconSize(size),
    dangerouslySetInnerHTML: { __html: contents },
  })
}

Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string.isRequired,
  iconSvgProps: PropTypes.shape({}),
}

export default Icon
