import React from 'react'

const IncomingIndicator = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7"
      cy="7"
      r="4"
      fill="#F2F4F6"
      stroke="#131B4D"
      strokeWidth="2"
    />
  </svg>
)

export default IncomingIndicator
