import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { formatHumanDate } from 'components/Product/Availability/utils'
import { cartProductDeliveryDateSelector } from 'containers/Cart/selectors'

import Confirmation from './Confirmation'
import { DelayButton, DiscardButton } from './Confirmation/styles'
import messages from './messages'

const StockDecreased = ({
  onConfirm,
  onDiscard,
  product,
  closestDeliveryDate,
  Wrapper,
}) => {
  const { formatMessage } = useIntl()
  const cartProductDeliveryDate = useSelector(
    cartProductDeliveryDateSelector(product.id),
  )
  const closestDeliveryDateFormatted = useMemo(
    () => formatHumanDate({ date: closestDeliveryDate, formatMessage }),
    [closestDeliveryDate],
  )

  const currentDeliveryDateFormatted = useMemo(
    () => formatHumanDate({ date: cartProductDeliveryDate, formatMessage }),
    [cartProductDeliveryDate],
  )

  return (
    <Confirmation
      message={
        <FormattedMessage
          {...messages.deliverOn}
          values={{
            date: closestDeliveryDateFormatted,
          }}
        />
      }
      {...{ Wrapper }}
    >
      <DiscardButton onClick={() => onConfirm()}>
        <FormattedMessage {...messages.deliverOnConfirm} />
      </DiscardButton>
      <DelayButton onClick={() => onDiscard()}>
        <FormattedMessage
          {...messages.deliverOnDiscard}
          values={{ date: currentDeliveryDateFormatted }}
        />
      </DelayButton>
    </Confirmation>
  )
}

export default StockDecreased
