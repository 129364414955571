import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

import useIsElementInView from 'hooks/useIsElementInView'
import {
  useLazyGetRecommendationsQuery,
  useGetRecommendationsState,
} from 'containers/Products/rtkApi'
import ProductsCarousel from 'components/ProductsCarousel'

import messages from './messages'
import { RecommendedBoxContainer } from './styles'

const RecommendedBox = ({
  categoryId,
  groupId,
  subgroupId,
  pageName,
  Container = RecommendedBoxContainer,
  itemListId,
  itemListName,
}) => {
  const { formatMessage } = useIntl()
  const [
    carouselRef,
    hasSeenCarousel,
    setHasSeenCarousel,
  ] = useIsElementInView()

  const [fetchRecommendations] = useLazyGetRecommendationsQuery()
  const {
    data: {
      products: recommendations = [],
      meta: { experiment: { id: itemModelId } = {}, attributionToken } = {},
    } = {},
    isFetching: isRecommendationsFetching,
    isUninitialized: isRecommendationsUninitialized,
  } = useGetRecommendationsState({ categoryId, groupId, subgroupId, pageName })

  useEffect(
    () => {
      if (hasSeenCarousel) {
        fetchRecommendations({ categoryId, groupId, subgroupId, pageName })
      }

      setHasSeenCarousel(false)
    },
    [categoryId, groupId, subgroupId, pageName, hasSeenCarousel],
  )

  return (
    <ProductsCarousel
      ref={carouselRef}
      isLoading={isRecommendationsFetching || isRecommendationsUninitialized}
      products={recommendations}
      itemModelId={itemModelId}
      attributionToken={attributionToken}
      itemListName={itemListName}
      itemListId={itemListId}
      title={formatMessage(messages.title)}
      Container={Container}
      testId="recommended-section"
    />
  )
}

export default RecommendedBox
