// TODO: refactor for more functional way

export const getColors = ({ theme }) => theme.colors

export const getFonts = ({ theme }) => theme.fonts

export const getTextSizes = ({ theme }) => theme.textSizes

export const getHeadingSize = ({ theme }) => theme.headingSize

export const getLineHeights = ({ theme }) => theme.lineHeights

export const getFontWeights = ({ theme }) => theme.fontWeights

export const getSpacing = ({ theme }) => theme.spacing

export const getSize = ({ theme }) => theme.size

export const getBreakpoint = ({ theme }) => theme.breakpoint
