import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import theme from 'theme'

export const ProgressBarContainer = styled(Flex)`
  height: ${p => p.height}px;
  border-radius: ${p => p.height / 2}px;
  overflow: hidden;
  width: 100%;
  gap: 1px;
`

export const ProgressBarSplit = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.greenLight6};
`

export const Progress = styled.div`
  height: 100%;
  border-radius: ${p =>
    p.progress !== 100 && `0 ${p.height / 2}px ${p.height / 2}px 0`};
  width: ${p => p.progress}%;
  background-color: ${theme.colors.secondary};
`
