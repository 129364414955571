/* eslint-disable import/no-import-module-exports */

/**
 * Create the store with dynamic reducers
 */
import createSagaMiddleware from 'redux-saga'
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit'

import { rtkApi } from 'services/api/rtkApi'
import createReducer from './reducers'

const sagaMiddleware = createSagaMiddleware()

export default function configureStore() {
  const store = rtkConfigureStore({
    reducer: createReducer(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        // Flags set to true as most state is still based on immutable Maps
        // These checks greatly slows down dev env and produces tons of console warnings
        // TODO: remove after moving all states to RTK
        serializableCheck: false,
        immutableCheck: false,
      }).concat([sagaMiddleware, rtkApi.middleware]),
  })

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return store
}
