import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { LabelV2 } from 'components/Fields/styles'
import { BasicButtonV2 } from 'components/Button'
import { TinyTextV2 } from 'components/Text'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`

export const FieldContainer = styled(Flex)`
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const EmailContainer = styled(Flex)`
  gap: ${theme.spacing.xs};
  align-items: center;
`

export const SubmitButton = styled(BasicButtonV2)`
  margin-top: ${theme.spacing.xxs};
`

export const Counter = styled(TinyTextV2)`
  display: flex;
  align-items: center;
  color: ${theme.colors.gray7};
  margin-left: auto;
`

export const EmailLabel = styled(LabelV2).attrs({ as: 'span' })``
