import React from 'react'

const Fries = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="group-[.active-btn]:fill-white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.8063 12.5808C7.59988 12.2405 7.24617 12 6.84683 12H6.30241C5.66839 12 5.19287 12.5773 5.31722 13.1961L6.92474 21.1961C7.01866 21.6635 7.43101 22 7.90993 22H16.3096C16.7886 22 17.2009 21.6635 17.2948 21.1961L18.9024 13.1961C19.0267 12.5773 18.5512 12 17.9172 12H17.3727C16.9734 12 16.6197 12.2405 16.4133 12.5808C15.5337 14.0306 13.9355 15 12.1098 15C10.2841 15 8.68585 14.0306 7.8063 12.5808Z"
      fill="#229944"
    />
    <path
      d="M8.3357 2.63656C8.28923 2.22351 8.6466 1.898 9.00204 2.02964L10.7878 2.74832C10.9958 2.82533 11.136 3.04153 11.136 3.28506V13.2667C11.136 13.6191 10.7782 13.8536 10.5087 13.6267C10.1788 13.3491 9.9253 12.9809 9.69043 12.5488C9.60638 12.3941 9.50929 12.2419 9.39991 12.0956L8.3357 2.63656Z"
      fill="#99D0AC"
    />
    <path
      d="M6.47988 10.6917C6.83422 10.6917 7.16409 10.3867 7.09701 10.0388L6.47979 6.83778C6.42363 6.58516 6.21962 6.40767 5.98542 6.40767H4.50953C4.1723 6.40767 3.9282 6.76529 4.01918 7.12608L4.7326 10.323C4.8002 10.6259 5.12584 10.7911 5.43127 10.736C5.59251 10.7069 5.75839 10.6917 5.928 10.6917H6.47988Z"
      fill="#99D0AC"
    />
    <path
      d="M18.8525 10.6241C19.1533 10.6846 19.4781 10.53 19.5549 10.2329L19.9802 8.58734C20.0258 8.41098 19.9916 8.22101 19.8884 8.07773L18.6696 6.38507C18.4043 6.01663 17.8736 6.14962 17.7763 6.60891L17.0867 9.86471C17.0078 10.237 17.3687 10.5694 17.7493 10.5694H18.3012C18.49 10.5694 18.6742 10.5882 18.8525 10.6241Z"
      fill="#99D0AC"
    />
    <path
      d="M15.1357 12.2106L15.7904 4.93679C15.8284 4.51422 15.4495 4.19746 15.0946 4.35519L13.4026 4.99817C13.2092 5.08409 13.0825 5.29213 13.0825 5.52348V13.2667C13.0825 13.619 13.4371 13.8573 13.7405 13.6783C14.22 13.3953 14.6781 13.0082 14.9278 12.5488C14.9901 12.4342 15.0595 12.321 15.1357 12.2106Z"
      fill="#99D0AC"
    />
  </svg>
)

export default Fries
