import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { trackInvoiceTypeChange } from 'services/analytics'
import {
  invoicesTypeFiltersSelector,
  INVOICE_TYPE,
} from 'containers/Invoices/List/Filters'
import { ToggleButtonV2 } from 'components/Button/styles'

import messages from '../../../messages'

import { FiltersInnerContainer, InvoiceCount } from './styles'

const FilteredTabs = ({ onTabSelect, selectedTab }) => {
  const { formatMessage } = useIntl()
  const { storeId } = useSelector(userDataSelector)
  const invoicesTypeFilters = useSelector(invoicesTypeFiltersSelector)

  return (
    <FiltersInnerContainer>
      {[...INVOICE_TYPE.values()].map(({ id, title }) => {
        const filterObj = invoicesTypeFilters[id]

        if (!filterObj) return null

        const isActive = selectedTab === filterObj.name

        return (
          <ToggleButtonV2
            key={id}
            $isActive={isActive}
            onClick={() => {
              onTabSelect(id)
              trackInvoiceTypeChange({
                type: filterObj.name,
                storeId,
              })
            }}
          >
            {formatMessage(messages[title])}
            <InvoiceCount $isActive={isActive}>{filterObj.count}</InvoiceCount>
          </ToggleButtonV2>
        )
      })}
    </FiltersInnerContainer>
  )
}

export default FilteredTabs
