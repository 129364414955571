import { css, createGlobalStyle } from 'styled-components'
import { HeaderContainer } from 'containers/App/LoggedInLayout/Header/styles'

const calculateRightMargin = () => {
  const documentWidth = document.documentElement.clientWidth
  const windowWidth = window.innerWidth
  const scrollBarWidth = windowWidth - documentWidth
  return css`
    padding-right: ${scrollBarWidth}px;
  `
}

export const ModalGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    ${() => calculateRightMargin()}
    ${HeaderContainer} {
      ${() => calculateRightMargin()}
    }
}
`
