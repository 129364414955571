import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  getFormattedProductParams,
  normalizePrice,
  trackEcommerceEvent,
  formatItemListParams,
  trackSimpleEvent,
  getAvailabilityStatusParam,
} from 'services/analytics/utils'
import { findUnitOfMeasureByVariant } from 'components/Product/utils'
import { CURRENCY } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'

import { CATALOG_EVENTS } from './consts'

const useProductEventParams = ({ product, unitOfMeasure, itemModelId }) => {
  const { customerNo } = useSelector(userDataSelector)

  const unitData = useMemo(
    () => findUnitOfMeasureByVariant(product.unitsOfMeasure, unitOfMeasure),
    [product, unitOfMeasure],
  )

  const price = useMemo(() => normalizePrice(unitData?.priceGross), [unitData])
  const productParams = useMemo(
    () => getFormattedProductParams(product, unitData, itemModelId),
    [product, unitData],
  )

  return { customerNo, price, productParams }
}

export const useSelectItemTracking = ({
  product,
  itemListId,
  itemListName,
  itemModelId,
  additionalEventParams,
  unitOfMeasure,
  index = 0,
}) => {
  const { customerNo, price, productParams } = useProductEventParams({
    product,
    unitOfMeasure,
    itemModelId,
  })

  const trackSelectItem = () => {
    trackEcommerceEvent(CATALOG_EVENTS.SELECT_ITEM, {
      value: price,
      currency: CURRENCY,
      customer_no: customerNo,
      items: [
        {
          ...productParams,
          ...formatItemListParams(itemListId, itemListName),
          price,
          index: index + 1,
        },
      ],
      ...additionalEventParams,
    })
  }

  return trackSelectItem
}

export const useViewItemTracking = ({
  product,
  itemListId,
  itemListName,
  itemModelId,
  index = 0,
  additionalEventParams,
  unitOfMeasure,
}) => {
  const { customerNo, price, productParams } = useProductEventParams({
    product,
    unitOfMeasure,
    itemModelId,
  })

  if (!price || !productParams) return () => null

  const trackViewItem = () => {
    trackEcommerceEvent(CATALOG_EVENTS.VIEW_ITEM, {
      value: price,
      currency: CURRENCY,
      customer_no: customerNo,
      items: [
        {
          ...productParams,
          ...formatItemListParams(itemListId, itemListName),
          price,
          index: index + 1,
        },
      ],
      ...additionalEventParams,
    })
  }

  return trackViewItem
}

export const useProductAvailabilityTracking = ({ product, unitData }) => {
  const userData = useSelector(userDataSelector)
  const { customerNo, storeId } = userData

  const availabilityStatusParam = useMemo(
    () => getAvailabilityStatusParam({ product, unitData, userData }),
    [product, unitData, userData],
  )

  const trackProductWithAvailability = event => {
    trackSimpleEvent(event, {
      customer_no: customerNo,
      store_id: String(storeId),
      item_id: product.id,
      item_name: product.name,
      item_status: availabilityStatusParam,
    })
  }

  return trackProductWithAvailability
}
