import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { makeMessages, makeTranslation } from 'utils'
import { getSpacing } from 'components/helpers/theme-selectors'
import { BackButton } from './styles'

const messages = makeMessages(
  {
    back: { id: 'back' },
    return: { id: 'return' },
  },
  makeTranslation(''),
)

const Back = ({ message = 'back', ...props }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <BackButton onClick={() => navigate(-1)} {...props}>
      {formatMessage(messages[message])}
    </BackButton>
  )
}

export default Back

// prettier-ignore
export const FixedBack = styled(Back)`
  position: absolute;
  left: ${p => getSpacing(p).md};

  @media (max-width: ${p => p.maxWidthMargin || '900px'}) {
    position: unset;
    margin-bottom: ${p => getSpacing(p).sm};
    align-self: stretch;
  }
`
