import { formatFiltersParams } from 'containers/Products/utils'

const scope = 'categories_tree'
const makeRequestBuilder = section => ({ body }) => {
  const queryParams = formatFiltersParams(body)
  if (section) {
    return { url: `${scope}/${section}?${queryParams}` }
  }

  return { url: `${scope}?${queryParams}` }
}

export const getCategory = makeRequestBuilder('categories')
export const getCategoriesList = () => ({ url: scope })
export const getCategoriesListWithParams = makeRequestBuilder()

export const getCategoryGroups = makeRequestBuilder('groups')
export const getCategorySubgroups = makeRequestBuilder('subgroups')
