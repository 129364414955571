import styled from 'styled-components'

import { LargeTextV2Medium, TinyTextV2Secondary } from 'components/Text'

import theme from 'theme'

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
`

export const DateText = styled(LargeTextV2Medium)`
  line-height: 16px;
`

export const DayText = styled(TinyTextV2Secondary)``

// prettier-ignore
export const DeliveryDayContainer = styled.div`
  position: relative;
  display: flex;
  padding: ${theme.spacing.ss} 0;
  width: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: ${theme.borderRadius.base};
  cursor: pointer;
  gap: ${theme.spacing.xxs};
  :hover {
    background: ${theme.colors.lightGrey7};
  }
  ${p => p.$isToday && `
    background: ${theme.colors.greenLight5};
    ${DateText}, ${DayText} {
      color: ${theme.colors.greenDark};
    }
  `}
  ${p => p.$isSelected && `
    background: ${theme.colors.secondary};
    cursor: auto;
    ${DateText}, ${DayText} {
      color: ${theme.colors.white};
    }
    .delivery-truck-icon, .route-icon {
      path, circle {
        stroke: ${theme.colors.white};
      }
    }
    :hover {
      background: ${theme.colors.secondary};
    }
  `};
`

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
`
