import { makeMessages, makeTranslation } from 'utils'

const messages = {
  removeTooltip: 'removeTooltip',
  cannotRemoveTooltip: 'cannotRemoveTooltip',
  confirmationTitle: 'confirmationTitle',
  confirmationDesc: 'confirmationDesc',
  reject: 'reject',
  confirm: 'confirm',
  success: 'success',
  error: { id: 'error.generic' },
}

export default makeMessages(
  messages,
  makeTranslation('orderDetails.removeItem'),
)
