import React from 'react'
import styled, { css } from 'styled-components'
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'

import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'
import { disableUserSelect } from './styles'

export const Link = styled(({ active, ...rest }) => <RouterLink {...rest} />)`
  text-decoration: none;
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.primary};
  font-size: ${theme.textSizes.l};
  line-height: ${theme.lineHeights.md};
  cursor: pointer;
  width: ${props => (props.$fullWidth ? '100%' : 'fit-content')};
  &:hover {
    color: ${theme.colors.primaryDark};
    text-decoration: none;
  }
`

export const BrandedLink = styled(Link)`
  cursor: pointer;
  color: ${theme.colors.secondary};
  font-size: ${theme.headingSize.sm};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.strongBold};
`

// prettier-ignore
export const NavLink = styled(({ bigFont, isFirst, isTemplate, ...props }) => (
  <RouterNavLink {...props} />
))`
  ${disableUserSelect};
  text-decoration: none;
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.textSizes.m};
  cursor: pointer;
  position: relative;

  &:hover {
    color: ${theme.colors.secondary};
    text-decoration: none;
  }

  &.active {
    color: ${theme.colors.secondary};
    cursor: pointer;
  }

  &:not(:first-of-type) {
    padding-left: 24px;
  }

  ${({ isTemplate }) => isTemplate && css`
    width: 260px;
    min-height: 40px;
    background-color: ${theme.colors.white};
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    word-break: break-word;
    align-items: center;

    &.active, :hover {
      background-color: ${theme.colors.lightGrey};
    }
    &.active {
      span {
        color: ${theme.colors.primary};
      }
    }
  `};

  ${({ isTemplate }) => !isTemplate && css`
    &.active:after {
      content: '';
      width: 100%;
      border-bottom: 2px solid ${theme.colors.secondary};
      position: absolute;
      top: 25px;
    }
    @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
      padding-left: 24px;
      &:hover {
        border-left: 3px solid ${theme.colors.secondary};
      }
    }
  `};

  ${({ isFirst, isTemplate }) => !isFirst && !isTemplate && css`
    &.active:after {
      transform: translateX(-15%);
    }
  `}
`

export const HeaderLink = styled(Link)`
  color: ${theme.colors.primary};
  font-size: ${theme.headingSize.m};

  :hover {
    color: ${theme.colors.secondary};
  }
`

export const DashboardLink = styled(RouterLink)`
  font-size: ${theme.textSizes.sm};
  font-weight: ${theme.fontWeights.medium};
  line-height: 20px;
  color: ${theme.colors.secondary};
  text-decoration: none;
`
