import React, { useMemo } from 'react'
import usePrevious from 'use-previous'
import { useIntl } from 'react-intl'
import { Field } from 'redux-form/immutable'
import { noop, castArray, isEqual } from 'lodash'

import {
  makeRequiredValidator,
  makeMinLengthValidator,
  makeMaxLengthValidator,
  makeExactLengthValidator,
} from 'utils/redux-form-utils'

import InputComponent from './InputComponent'

const GenericField = ({
  component = InputComponent,
  validate = [],
  required,
  minLength,
  maxLength,
  exactLength,
  ...rest
}) => {
  const intl = useIntl()

  const prevValidate = usePrevious(validate)
  const isNewValidate = !isEqual(prevValidate, validate)
  const validateArray = useMemo(
    () => [
      required ? makeRequiredValidator({ intl }) : noop,
      minLength ? makeMinLengthValidator({ intl, minLength }) : noop,
      maxLength ? makeMaxLengthValidator({ intl, maxLength }) : noop,
      exactLength ? makeExactLengthValidator({ intl, exactLength }) : noop,
      ...castArray(validate),
    ],
    [isNewValidate, required, minLength, maxLength, exactLength],
  )

  return <Field component={component} validate={validateArray} {...rest} />
}

export default GenericField
