import { compose } from 'lodash/fp'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { bindActionCreators } from 'utils/redux-utils'

import {
  categoryActions,
  categoriesListActions,
  categoryGroupActions,
  categorySubgroupActions,
} from './actions'
import {
  categorySelector,
  categoriesListSelector,
  categorySubgroupSelector,
  categoryGroupSelector,
  allCategoriesListSelector,
} from './selectors'

export * from './consts'

export const mapStateToProps = createStructuredSelector({
  category: categorySelector,
  categoriesList: categoriesListSelector,
  allCategoriesList: allCategoriesListSelector,
  categoryGroup: categoryGroupSelector,
  categorySubgroup: categorySubgroupSelector,
})

export const mapDispatchToProps = bindActionCreators({
  getCategory: categoryActions.delta,
  getCategoriesList: categoriesListActions.delta,
  getCategoryGroup: categoryGroupActions.delta,
  getCategorySubgroup: categorySubgroupActions.delta,
  clearCategoriesList: categoriesListActions.clear,
  clearCategoriesGroup: categoryGroupActions.clear,
  clearCategoriesSubgroup: categorySubgroupActions.clear,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
