import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import {
  productTemplatesAction,
  templatesActions,
} from 'containers/Templates/actions'
import { selectTemplatesData } from 'containers/Templates/selectors'
import ProductImage from 'components/Product/ProductImage'
import ProductTitle from 'components/Product/ProductTitle'
import { MODAL_BODY_CLASSNAME } from '../ModalWrapper'
import TemplateModalList from './TemplateModalList'

import messages from './messages'
import { ModalHeader } from '../styles'
import { ImgContainer, TemplateModalContainer } from './styles'
import Modal from '..'

export default NiceModal.create(
  ({
    product = {},
    unitOfMeasure,
    clearTemplatesOnClose = true,
    itemListId,
    itemListName,
    itemModelId,
    listIndex,
  }) => {
    const modal = useModal()
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const { id, brand, name } = product

    const templates = useSelector(selectTemplatesData)

    const getProductTemplates = (productId, unit) =>
      dispatch(productTemplatesAction({ productId, unitOfMeasure: unit }))
    useEffect(
      () => {
        if (id && unitOfMeasure) {
          getProductTemplates(id, unitOfMeasure)
        }
      },
      [id, unitOfMeasure],
    )

    useEffect(
      () => () => {
        if (clearTemplatesOnClose) {
          dispatch(templatesActions.clear())
        }
      },
      [],
    )

    return (
      <Modal
        handleClose={modal.remove}
        bodyClassName={MODAL_BODY_CLASSNAME.TEMPLATE}
        hideHeader
      >
        <ModalHeader
          heading={formatMessage(messages.modalHeader)}
          onClick={modal.remove}
          dataTestId="close-template-modal-button"
        />
        <TemplateModalContainer data-test-id="template-modal">
          <ImgContainer>
            <ProductImage product={product} outOfStock={false} />
            <ProductTitle title={name} brand={brand} />
          </ImgContainer>
          <TemplateModalList
            product={product}
            templates={templates}
            unitOfMeasure={unitOfMeasure}
            itemListId={itemListId}
            itemListName={itemListName}
            itemModelId={itemModelId}
            listIndex={listIndex}
          />
        </TemplateModalContainer>
      </Modal>
    )
  },
)
