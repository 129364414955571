import _omit from 'lodash/omit'
import store from 'store'
import makeApiWithReauth from 'services/api/api'
import { rtkApi } from 'services/api/rtkApi'

import { snakeCaseDeep } from 'utils'
import pkg from '../../package.json'

const parseOptions = (options = {}) => {
  if (options) {
    return options.body instanceof FormData
      ? { ...options, body: options.body }
      : { ...options, body: JSON.stringify(options.body) }
  }
  return options
}

const addHeaders = (options = {}) => {
  const headers = _omit(options.headers, ['omitContentType'])
  const omitContentType = options.headers?.omitContentType

  return {
    ...options,
    headers: {
      ...headers,
      'App-Version': pkg.version,
      Platform: 'web',

      // omitting Content-Type when sending files as multipart/form-data
      // Content-Types is set by browser in such case
      ...(!omitContentType && {
        'Content-Type':
          (headers && headers['Content-Type']) || 'application/json',
      }),
    },
  }
}

/**
 * Requests a URL, returning data/throwing error
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to axios fetch
 *
 * @return {object}           The response data
 */

export const OFFLINE_ERROR = 'offline'

export default async function request({ url, options = {} }) {
  const data = snakeCaseDeep(options.body)
  const optionsWithHeaders = addHeaders(options)
  const parsedOptions = parseOptions(optionsWithHeaders)

  if (!navigator.onLine) throw OFFLINE_ERROR

  const api = makeApiWithReauth(store, rtkApi)

  try {
    const res = await api({ url, data, ...parsedOptions })

    return [res.data, res.headers]
  } catch (error) {
    const errorResponse = {
      ...error.response,
      body: error.response.data,
    }

    throw errorResponse
  }
}
