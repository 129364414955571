import styled from 'styled-components'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

export const FileSelectorContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${theme.spacing.xs};
`

export const AddFileLabel = styled(BaseTextV2).attrs({ as: 'label' })`
  flex-shrink: 0;
  cursor: pointer;
  width: fit-content;
  color: ${theme.colors.secondary};
  font-weight: ${theme.fontWeights.medium};
`

export const AddFileInput = styled.input`
  display: none;
`
