import React from 'react'

import theme from 'theme'
import { ArrowLongRight as ArrowIcon } from 'components/Icons'
import StoreIconLeft from './assets/store-icon-left.svg'
import StoreIconRight from './assets/store-icon-right.svg'

import {
  InfographicContainer,
  StoreIconContainer,
  StoreIconImg,
  CustomerNoContainer,
  CustomerNoText,
} from './styles'

const Infographic = ({ fromCustomerNo, toCustomerNo }) => (
  <InfographicContainer>
    <StoreIconContainer>
      <StoreIconImg src={StoreIconLeft} />
      <CustomerNoContainer>
        <CustomerNoText>{fromCustomerNo}</CustomerNoText>
      </CustomerNoContainer>
    </StoreIconContainer>
    <ArrowIcon color={theme.colors.secondary} />
    <StoreIconContainer>
      <StoreIconImg src={StoreIconRight} />
      <CustomerNoContainer>
        <CustomerNoText>{toCustomerNo}</CustomerNoText>
      </CustomerNoContainer>
    </StoreIconContainer>
  </InfographicContainer>
)

export default Infographic
