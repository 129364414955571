import { takeLatest, put, call, select, take } from 'redux-saga/effects'
import history from 'utils/history'
import _get from 'lodash/get'
import { omit } from 'lodash/fp'
import * as Sentry from '@sentry/react'

import { ALWAYS_ENTERABLE_ROUTES, ROUTES } from 'consts'
import { getPhoneNumber } from 'utils'
import locationsApi from 'containers/Locations/rtkApi'
import settingsApi from 'containers/Settings/rtkApi'
import { formValuesSelector } from 'utils/redux-form-utils'
import { genericGetDataEnhanced } from 'containers/App/sagas'
import {
  SIGN_IN_MODAL,
  MODAL_SOMETHING_WENT_WRONG,
} from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'
import { userDataSelector } from 'containers/UserInfo/selectors'
import userInfoActions from 'containers/UserInfo/actions'
import { trackLocationChange } from 'containers/Locations/utils'
import { trackSignUpDataSent } from 'services/analytics'

import { cleanupUserProperties } from 'services/analytics/user'
import { cleanupTidioChat } from 'utils/tidioChat'
import { hasPathInRoutes } from 'utils/routes'
import {
  login,
  signUp,
  setPassword,
  confirmEmail,
  resendConfirmEmail,
  validateToken,
  resendSetPassword,
  checkLogin,
  signUpMigrate,
  resendPasswordReset,
} from './api'
import {
  PASSWORD_FORM_NAME,
  ACCOUNT_ERRORS,
  VALIDATE_ERRORS,
  CONFIRM_ERRORS,
  LOGOUT_REASONS,
  SIGNUP_LOGIN_ERRORS,
} from './consts'
import {
  loginActions,
  LOGOUT,
  logoutBaseAction,
  signUpActions,
  passwordActions,
  confirmEmailActions,
  resendConfirmEmailActions,
  validateTokenActions,
  resendSetPasswordActions,
  resendPasswordResetActions,
  logoutReason,
  checkLoginActions,
  signUpMigrateActions,
} from './actions'
import { storeAuthInfo, clearAuthInfo } from './tokenStorage'

export function* loginFlow({ data, additionalData: { redirectTo } = {} }) {
  try {
    const response = yield call(genericGetDataEnhanced, {
      actions: loginActions,
      request: login,
      params: {
        body: data,
      },
    })
    yield call(storeAuthInfo, response)

    const getSettingsAction = yield put(
      settingsApi.endpoints.getSettings.initiate(),
    )
    const { skipLocationSelection } = yield call(getSettingsAction.unwrap)

    if (redirectTo || skipLocationSelection) {
      history.push(redirectTo || ROUTES.DASHBOARD)
    } else {
      const getLocationsAction = yield put(
        locationsApi.endpoints.getLocations.initiate(),
      )
      const locations = yield call(getLocationsAction.unwrap)

      if (locations.length > 1) {
        history.push(ROUTES.SET_LOCATION)
      } else {
        history.push(ROUTES.DASHBOARD)
      }
    }

    yield take(userInfoActions.SUCCESS)

    const userData = yield select(userDataSelector)
    trackLocationChange(userData)

    // redirect not needed as it's handled by AuthGuard
  } catch (error) {
    // currently nothing to do
    console.log('Error logging in the user', error)
  }
}

export function* logoutFlow({ data, additionalData }) {
  try {
    yield call(clearAuthInfo)
    yield put(logoutBaseAction())

    if (_get(additionalData, 'reason') === LOGOUT_REASONS.SESSION_EXPIRED) {
      yield put(logoutReason(LOGOUT_REASONS.SESSION_EXPIRED))
    }

    const callback = _get(data, 'callback')

    const isNoRedirectRoute = hasPathInRoutes({
      routes: ALWAYS_ENTERABLE_ROUTES,
      pathname: history.location.pathname,
    })

    if (callback) {
      callback()
    } else {
      !isNoRedirectRoute && history.push(ROUTES.LOGIN)
    }

    cleanupTidioChat()
    cleanupUserProperties()
    Sentry.setUser(null)
  } catch (error) {
    // currently nothing to do
    console.log('Error logging out the user', error)
  }
}

export function* signUpFlow({ data }) {
  const { phoneNo, deliveryCountryCode: codeObj, ...formValues } = data
  const phoneNumber = getPhoneNumber({ codeObj, number: phoneNo })

  try {
    yield call(genericGetDataEnhanced, {
      actions: signUpActions,
      request: signUp,
      params: {
        body: {
          ...formValues,
          phoneNo: phoneNumber,
          deliveryCountryCode: codeObj?.value,
        },
      },
    })

    yield call(trackSignUpDataSent)
  } catch (error) {
    const errorCode = _get(error, 'body.errors[0].code')
    const accountErrors = Object.values(ACCOUNT_ERRORS)

    if (accountErrors.includes(errorCode)) {
      yield put(openModal(SIGN_IN_MODAL, { hideHeader: true, errorCode }))
      // eslint-disable-next-line no-underscore-dangle
    } else if (!error.formErrors || error.formErrors._error) {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }
}

export function* signUpMigrateFlow({
  data: { values, token },
  additionalData: { resolve, reject },
}) {
  const formValues = omit(['recaptcha'], values.toJS())

  try {
    yield call(
      genericGetDataEnhanced,
      {
        actions: signUpMigrateActions,
        request: signUpMigrate,
        params: {
          body: { ...formValues, token },
        },
      },
      { resolve, rethrow404: true },
    )

    resolve()
  } catch (error) {
    reject(error)
  }
}

export function* setPasswordFlow() {
  const formValues = yield select(formValuesSelector(PASSWORD_FORM_NAME))
  try {
    yield call(genericGetDataEnhanced, {
      actions: passwordActions,
      request: setPassword,
      params: {
        body: formValues,
      },
    })
  } catch (error) {
    const errorCode = _get(error, 'body.errors[0].code')
    // global errors
    if (!Object.values(VALIDATE_ERRORS).includes(errorCode)) {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }
}

export function* confirmEmailFlow(action) {
  try {
    const {
      data: { ...body },
    } = action

    yield call(genericGetDataEnhanced, {
      actions: confirmEmailActions,
      request: confirmEmail,
      params: {
        body,
      },
    })
  } catch (error) {
    const errorCode = _get(error, 'body.errors[0].code')
    // global errors
    if (!Object.values(CONFIRM_ERRORS).includes(errorCode)) {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }
}

export function* resendConfirmEmailFlow(action) {
  try {
    const {
      data: { ...body },
    } = action

    yield call(genericGetDataEnhanced, {
      actions: resendConfirmEmailActions,
      request: resendConfirmEmail,
      params: {
        body,
      },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* validateTokenFlow(action) {
  try {
    const {
      data: { ...body },
    } = action

    yield call(genericGetDataEnhanced, {
      actions: validateTokenActions,
      request: validateToken,
      params: {
        body,
      },
    })
  } catch (error) {
    const errorCode = _get(error, 'body.errors[0].code')
    // global errors
    if (!Object.values(VALIDATE_ERRORS).includes(errorCode)) {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }
}

export function* resendSetPasswordFlow(action) {
  try {
    const {
      data: { ...body },
    } = action

    yield call(genericGetDataEnhanced, {
      actions: resendSetPasswordActions,
      request: resendSetPassword,
      params: {
        body,
      },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* resendPasswordResetFlow(action) {
  try {
    const {
      data: { ...body },
      additionalData: { successCallback },
    } = action

    yield call(genericGetDataEnhanced, {
      actions: resendPasswordResetActions,
      request: resendPasswordReset,
      params: {
        body,
      },
    })
    yield call(successCallback)
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

const isLoginInvalidError = err =>
  _get(err, 'body.errors.0.code') === SIGNUP_LOGIN_ERRORS.ALREADY_TAKEN

export function* checkLoginFlow(action) {
  const { data, additionalData } = action

  try {
    yield call(genericGetDataEnhanced, {
      actions: checkLoginActions,
      request: checkLogin,
      params: {
        body: data,
      },
    })
  } catch (error) {
    if (isLoginInvalidError(error)) {
      return yield call(additionalData.onError)
    }
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
  return yield call(additionalData.onSuccess)
}

export default [
  takeLatest(loginActions.DELTA, loginFlow),
  takeLatest(LOGOUT, logoutFlow),
  takeLatest(signUpActions.DELTA, signUpFlow),
  takeLatest(signUpMigrateActions.DELTA, signUpMigrateFlow),
  takeLatest(passwordActions.DELTA, setPasswordFlow),
  takeLatest(confirmEmailActions.DELTA, confirmEmailFlow),
  takeLatest(resendConfirmEmailActions.DELTA, resendConfirmEmailFlow),
  takeLatest(validateTokenActions.DELTA, validateTokenFlow),
  takeLatest(resendSetPasswordActions.DELTA, resendSetPasswordFlow),
  takeLatest(resendPasswordResetActions.DELTA, resendPasswordResetFlow),
  takeLatest(checkLoginActions.DELTA, checkLoginFlow),
]
