import React from 'react'
import { FormattedMessage } from 'react-intl'

import { P } from 'components/Text'
import { isInvalid } from 'utils/redux-form-utils'
import Checkbox from 'components/Input/Checkbox'

import { CheckBoxWrapper, ErrorBox } from './styledComponents'

export const CheckBoxComponent = ({
  title,
  meta,
  style,
  input: { onChange, onBlur: _, ...inputProps },
  required,
  ...rest
}) => (
  <CheckBoxWrapper>
    <Checkbox
      {...inputProps}
      {...rest}
      onChange={() => onChange(!inputProps.value)}
      invalid={isInvalid(meta)}
    />
    {title ? (
      <P>
        {title}
        {isInvalid(meta) && (
          <ErrorBox>
            <FormattedMessage {...meta.error} />
          </ErrorBox>
        )}
      </P>
    ) : (
      isInvalid(meta) && (
        <ErrorBox>
          <FormattedMessage {...meta.error} />
        </ErrorBox>
      )
    )}
  </CheckBoxWrapper>
)
export default CheckBoxComponent
