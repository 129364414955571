import { takeLatest, call, put, select } from 'redux-saga/effects'
import { propOr, identity } from 'lodash/fp'

import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { openModal } from 'containers/App/actions/modal'
import { genericGetDataEnhanced } from 'containers/App/sagas'
import { getProducts } from 'containers/Products/api'
import { userDataSelector } from 'containers/UserInfo/selectors'
import {
  trackViewItemList,
  trackSearchProductsEvent,
  ITEM_LIST_IDS,
  ITEM_LIST_NAMES,
  CATALOG_EVENTS,
} from 'services/analytics'

import { searchProductsActions } from './actions'
import { searchResultsSelector } from './selectors'

export function* searchProductsFlow({
  data: { pageSize = 6, search },
  additionalData,
}) {
  try {
    const userData = yield select(userDataSelector)
    const response = yield call(genericGetDataEnhanced, {
      actions: searchProductsActions,
      request: getProducts,
      params: {
        pageSize,
        search,
      },
    })

    if (!response) {
      return
    }

    const onSuccess = propOr(identity, 'onSuccess', additionalData)
    const searchResults = yield select(searchResultsSelector)
    yield call(onSuccess, searchResults)

    if (response.meta.pagination.totalCount === 0) {
      trackSearchProductsEvent({
        event: CATALOG_EVENTS.EMPTY_SEARCH_RESULTS,
        query: search,
        userData,
      })
    }

    trackSearchProductsEvent({
      event: CATALOG_EVENTS.SEARCH,
      query: search,
      userData,
    })
    trackViewItemList({
      products: searchResults,
      itemListId: ITEM_LIST_IDS.SEARCH,
      itemListName: ITEM_LIST_NAMES.SEARCH,
    })
  } catch (error) {
    console.log('Error searching products', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [takeLatest(searchProductsActions.DELTA, searchProductsFlow)]
