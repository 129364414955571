import React, { useLayoutEffect } from 'react'

import InfoPanel from 'views/InfoPanel'
import { useRichFormatMessage } from 'utils'
import messages from '../Notification/messages'

const Succeed = ({ email }) => {
  const formatMessage = useRichFormatMessage()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InfoPanel header={formatMessage(messages.header)}>
      <span className="text-base md:text-lg leading-7 text-blue-900">
        {formatMessage(messages.toContinue, { email })}
      </span>
    </InfoPanel>
  )
}

export default Succeed
