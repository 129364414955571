import styled from 'styled-components'

import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'
import {
  BaseTextV2,
  BaseTextV2Secondary,
  BaseTextV2Bold,
} from 'components/Text'

export const UnitSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const ProductUnitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};

  > button {
    border-radius: 0;

    &:first-of-type {
      border-top-left-radius: ${theme.borderRadius.base};
      border-top-right-radius: ${theme.borderRadius.base};
    }

    &:last-of-type {
      border-bottom-right-radius: ${theme.borderRadius.base};
      border-bottom-left-radius: ${theme.borderRadius.base};
    }
  }
`

export const ProductUnitContainer = styled.button`
  display: flex;
  gap: ${theme.spacing.sm};
  background-color: ${theme.colors.lightGrey};
  align-items: center;
  padding: 11px ${theme.spacing.sm};
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${theme.colors.lightGrey7};
  }
`

export const CheckmarkContainer = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NetPriceContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
`

export const NetPrice = styled(BaseTextV2)`
  font-size: ${theme.textSizes.m};
  line-height: 24px;
  font-weight: ${theme.fontWeights.bold};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.l};
  }
`

export const SecondaryText = styled(BaseTextV2Secondary).attrs({
  isSmall: { lineHeight: true, fontSize: true },
})`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.sm};
    line-height: 24px;
  }
`

export const OldPrice = styled(SecondaryText)`
  text-decoration: line-through;
`

export const UnitText = styled(BaseTextV2Bold).attrs({
  isSmall: { fontSize: true },
})`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.sm};
  }
`

export const PackagingInfoContainer = styled.div`
  padding-right: ${theme.spacing.sm};
  align-self: flex-end;
`
