import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { invoicesToPayActions } from 'containers/Invoices/List/actions'
import { clearInvoicesDateRange } from 'containers/Invoices/List/Filters/actions'

const InvoicesWrapper = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearInvoicesDateRange())
    dispatch(invoicesToPayActions.clearSelected())
  }, [])

  return <Outlet />
}

export default InvoicesWrapper
