import styled from 'styled-components'
import { AlternateBtn } from 'components/Button'

// prettier-ignore
export const StarBtnDrawer = styled(AlternateBtn)`
  ${({ noBorder }) => noBorder && `
    border: none;
    :hover {
      border: none;
    }
  `};

  ${p => p.isTransparent && `
    background-color: transparent;

    :hover {
      background-color: transparent;
    }
  `}
`
