import { makeMessages, makeTranslation } from 'utils'

const messages = {
  processingHeader: 'processing.header',
  processingDesc: 'processing.desc',
  confirmedHeader: 'confirmed.header',
  confirmedDesc: 'confirmed.desc',
  rejectedHeader: 'rejected.header',
  rejectedDesc: 'rejected.desc',
  backToDashboard: { id: 'invoices.pay.backToDashboard' },
  backToPayments: { id: 'invoices.pay.backToPayments' },
}

export default makeMessages(messages, makeTranslation('invoices.payProcessing'))
