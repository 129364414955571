import React from 'react'

const Sushi = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.04688 7.14005C9.15144 7.14005 10.0469 6.62965 10.0469 6.00005C10.0469 5.37044 9.15144 4.86005 8.04688 4.86005C6.94231 4.86005 6.04688 5.37044 6.04688 6.00005C6.04688 6.62965 6.94231 7.14005 8.04688 7.14005Z"
      fill="#A6B3C0"
    />
    <path
      d="M8.04683 13.6667C11.0335 13.6667 13.3802 12.2 13.3802 10.3334V6.00002C13.3802 4.13335 11.0335 2.66669 8.04683 2.66669C5.06017 2.66669 2.7135 4.13335 2.7135 6.00002V10.3334C2.7135 12.2 5.0535 13.6667 8.04683 13.6667ZM8.04683 4.00002C10.4002 4.00002 12.0468 5.05335 12.0468 6.00002C12.0282 6.20572 11.962 6.40427 11.8535 6.58002C11.4002 7.24669 10.1668 7.91335 8.52017 7.99335H7.5935C5.92017 7.88669 4.66683 7.26002 4.2335 6.56002C4.14046 6.40414 4.07716 6.23234 4.04683 6.05335V6.00002C4.04683 5.05335 5.68683 4.00002 8.04683 4.00002Z"
      fill="#A6B3C0"
    />
  </svg>
)

export default Sushi
