import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

import { FloatingPriceInfoBox } from 'components/FloatingPrice'

export const RowContainer = styled(Flex)`
  @media screen and (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    padding: 0 ${theme.spacing.sm};
  }

  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-top: 48px;
  }
`

export const InfoMsg = styled.div`
  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: 0 ${theme.spacing.base};
    padding-bottom: ${theme.spacing.vertical};
  }

  padding-bottom: ${theme.spacing.md};
`

export const InfoBox = styled.div`
  @media screen and (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: 0 ${theme.spacing.base};
  }
`

export const CartFloatingPriceInfo = styled(FloatingPriceInfoBox)`
  flex-direction: row-reverse;
  padding: 20px 18px 20px ${theme.spacing.base};
  margin: ${theme.spacing.sm} 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: ${theme.borderRadius.base};
`

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${theme.colors.gray2};
  margin-top: ${theme.spacing.ss};
  margin-bottom: 20px;
`
