import React from 'react'

const Pizza = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_33991_107512)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.527 13.2085C21.4468 10.758 19.908 8.46084 17.9106 6.44721L17.779 6.31561C15.7649 4.31777 13.4672 2.77876 11.0161 1.69855C10.2584 1.36463 9.39594 1.77102 9.11297 2.54918L5.91386 11.3467C5.85399 11.5114 5.94831 11.6907 6.10999 11.7582C6.40904 11.8832 6.68921 12.0673 6.93258 12.3107C7.93264 13.3107 7.93265 14.9322 6.93258 15.9322C6.23668 16.6281 5.23989 16.8398 4.36097 16.5672C4.19374 16.5153 4.00642 16.5922 3.94659 16.7567L3.03272 19.2699C2.59743 20.4669 3.75798 21.6275 4.95502 21.1922L12.6024 18.4113C12.8422 18.3241 12.8773 17.983 12.6889 17.811C12.6553 17.7803 12.6221 17.7487 12.5896 17.7161C11.4327 16.5592 11.4327 14.6835 12.5896 13.5266C13.7465 12.3698 15.6222 12.3698 16.779 13.5266C17.4586 14.2062 17.739 15.1337 17.6202 16.0179C17.5862 16.2707 17.8322 16.5096 18.0719 16.4224L21.6764 15.1117C22.4546 14.8287 22.861 13.9662 22.527 13.2085ZM10.5855 9.27288C11.4642 10.1516 12.8888 10.1516 13.7675 9.27288C14.6462 8.3942 14.6462 6.96958 13.7675 6.0909C12.8888 5.21222 11.4642 5.21222 10.5855 6.0909C9.70683 6.96958 9.70683 8.3942 10.5855 9.27288Z"
        fill="#F7C26E"
      />
      <circle
        cx="12.1797"
        cy="7.68149"
        r="2.25"
        transform="rotate(45 12.1797 7.68149)"
        fill="#E70000"
      />
      <path
        d="M12.5928 13.5264C11.4359 14.6833 11.4359 16.5589 12.5928 17.7158C12.7041 17.8272 12.8222 17.9278 12.9456 18.0177C13.0451 18.0903 13.1747 18.1031 13.2906 18.0616L17.3164 16.6193C17.4323 16.5778 17.5242 16.4856 17.5551 16.3664C17.8095 15.3838 17.5519 14.296 16.7822 13.5264C15.6253 12.3695 13.7497 12.3695 12.5928 13.5264Z"
        fill="#E70000"
      />
      <path
        d="M6.93577 15.9318C7.93583 14.9318 7.93583 13.3103 6.93577 12.3103C6.70917 12.0837 6.45067 11.9084 6.17472 11.7845C5.98571 11.6996 5.77419 11.8073 5.69984 12.0007L4.13146 16.0803C4.05706 16.2739 4.1422 16.4956 4.33959 16.559C5.22446 16.8433 6.23337 16.6342 6.93577 15.9318Z"
        fill="#E70000"
      />
    </g>
    <defs>
      <clipPath id="clip0_33991_107512">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Pizza
