import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { APP_BREAKPOINTS } from 'consts'
import { StyledScroll } from 'components/Scroll/styles'
import theme from 'theme'

import {
  TertiaryTextV2Medium,
  BaseTextV2Medium,
  TinyTextV2Secondary,
  BaseTextV2Secondary,
} from 'components/Text'

export const ListContainer = styled(StyledScroll)`
  max-height: 50vh;
  margin: -20px 0;

  .simplebar-content {
    padding: 6px 0px !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    max-height: 65vh;
    padding: ${theme.spacing.base} 0;

    .simplebar-content {
      padding: 14px 0px !important;
    }
  }
`

export const ListTitle = styled(TertiaryTextV2Medium)`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.l};
    line-height: 24px;
  }
`

export const AffectedOrdersTitleContainer = styled(Flex)`
  padding-top: ${theme.spacing.base};
  gap: 10px;
`

export const OrderContainer = styled(Flex)`
  flex-direction: column;
  background: ${theme.colors.lightGrey};
  border-radius: ${theme.borderRadius.base};
  padding: ${theme.spacing.sm};
  gap: ${theme.spacing.ss};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    flex-direction: row;
    justify-content: space-between;
    gap: ${theme.spacing.sm};
  }
`

export const OrderTotalContainer = styled(Flex)`
  justify-content: space-between;
  padding-top: ${theme.spacing.ss};
  border-top: 1px solid ${theme.colors.borderFaded};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    flex-direction: column;
    gap: ${theme.spacing.xxs};
    text-align: right;
    padding-top: 0;
    border: none;
  }
`

export const OrderLinkContainer = styled(Flex)`
  align-items: center;
  gap: ${theme.spacing.xxs};
  cursor: pointer;

  :hover {
    * {
      color: ${theme.colors.primary};
      stroke: ${theme.colors.primary};
    }
  }
`

export const OrderTextContainer = styled(Flex)`
  flex-direction: column;
  gap: ${theme.spacing.xxs};
`

export const OrderText = styled(BaseTextV2Medium).attrs({
  isSmall: { lineHeight: true },
})`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    line-height: 24px;
  }
`

export const OrderTextSecondary = styled(BaseTextV2Secondary).attrs({
  isSmall: { lineHeight: true },
})``

export const InfoTextContainer = styled(Flex)`
  gap: 10px;
`

export const InfoText = styled(TinyTextV2Secondary)`
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.sm};
    line-height: 20px;
  }
`
