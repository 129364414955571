import { makeMessages, makeTranslation } from 'utils'

export default makeMessages(
  {
    title: 'title',
    okBtn: 'okBtn',
    shiftDateBtn: 'shiftDateBtn',
    deliverOn: 'deliverOn',
    deliverOnConfirm: 'deliverOnConfirm',
    deliverOnDiscard: 'deliverOnDiscard',
  },
  makeTranslation('stockModal'),
)
