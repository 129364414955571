import React from 'react'
import { capitalize } from 'lodash/fp'

import theme from 'theme'

import { Calendar } from 'components/Icons'

import {
  DateContainer,
  Month,
  WeekDay,
  DateDesc,
  CalendarIconContainer,
} from './styles'

const Date = ({ value, isIncoming, onClick }) => (
  <DateContainer {...{ isIncoming, onClick }}>
    <CalendarIconContainer {...{ isIncoming }}>
      <Calendar
        number={value.format('D')}
        color={isIncoming ? theme.colors.gray3 : undefined}
      />
    </CalendarIconContainer>

    <DateDesc>
      <Month {...{ isIncoming }}>
        {capitalize(value.format('D MMMM').split(' ')[1])}
      </Month>
      <WeekDay bold {...{ isIncoming }}>
        {value.format('dddd')}
      </WeekDay>
    </DateDesc>
  </DateContainer>
)

export default Date
