import styled from 'styled-components'

import { APP_BREAKPOINTS } from 'consts'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 32px;

  :first-child {
    margin-top: 16px;
  }

  @media (min-width: ${APP_BREAKPOINTS.TMP_MAIN}px) {
    flex-flow: row;
    margin-top: 80px;
  }
`
