import * as React from 'react'

import theme from 'theme'

const Truck = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M20 17h1a2 2 0 002-2v-3h-7v3M21 7l2 5h-7V7h5z"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M16 15V6a2 2 0 00-2-2H3a2 2 0 00-2 2v9a2 2 0 002 2h1"
      stroke={color}
      strokeWidth={2}
    />
    <path d="M9 17h6" stroke={color} strokeWidth={2} strokeLinecap="round" />
    <circle cx={6.5} cy={17.5} r={2.5} stroke={color} strokeWidth={2} />
    <circle cx={17.5} cy={17.5} r={2.5} stroke={color} strokeWidth={2} />
  </svg>
)

export default Truck
