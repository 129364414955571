import styled from 'styled-components'

import theme from 'theme'

import { Check } from 'components/Icons'

import { HiddenInput, visibleStyles, hiddenStyles } from '../styles'

export const HiddenCheckbox = styled(HiddenInput).attrs({ type: 'checkbox' })``

export const StyledCheckIcon = styled(Check).attrs({
  color: theme.colors.secondary,
})``

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid ${theme.colors.gray3};
  border-radius: 4px;

  ${StyledCheckIcon} {
    margin-left: -1px;
    margin-top: -1px;
    ${p => (p.checked ? visibleStyles : hiddenStyles)};
  }
`

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`
