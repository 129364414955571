import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import {
  STOCK_TYPES,
  UNAVAILABILITY_TYPES,
} from 'components/Product/Availability/consts'

import { Box as BoxIcon } from 'components/Icons'
import { FloatingPriceOutlineBig as FloatingPriceIcon } from 'components/Icons/FloatingPrice'
import { useAvailabilityConfig } from './utils'

import messages from '../../messages'
import { BoxContainer, InfoRow, InfoText, PrimaryInfoRow } from './styles'

const AvailabilityBox = ({
  product,
  unitData,
  closestDeliveryDate,
  isSoonAvailable,
  productDeliveryOptions,
}) => {
  const { formatMessage } = useIntl()

  const { message, IconComponent, type } = useAvailabilityConfig({
    product,
    closestDeliveryDate,
    unitData,
    isSoonAvailable,
    productDeliveryOptions,
  })

  const isProductAvailable = unitData.stock > 0 && product.active
  const hasLowStockLabel =
    product.labelType === STOCK_TYPES.LOW && isProductAvailable

  const isHighlighted = type === UNAVAILABILITY_TYPES.NON_STOCK

  return (
    <BoxContainer>
      <PrimaryInfoRow
        $isHighlighted={isHighlighted}
        data-test-id="primary-info-row"
      >
        <div>
          <IconComponent color={theme.colors.infoBlue2} />
        </div>
        <InfoText
          $isHighlighted={isHighlighted}
          data-test-id="primary-info-text"
        >
          {message}
        </InfoText>
      </PrimaryInfoRow>
      {hasLowStockLabel && (
        <InfoRow $isHighlighted={isHighlighted}>
          <div>
            <BoxIcon color={theme.colors.infoBlue2} />
          </div>
          <InfoText>{product.labelName}</InfoText>
        </InfoRow>
      )}
      {product.floatingPrice && (
        <InfoRow $isHighlighted={isHighlighted}>
          <div>
            <FloatingPriceIcon />
          </div>
          <InfoText>{formatMessage(messages.floatingPriceInfo)}</InfoText>
        </InfoRow>
      )}
    </BoxContainer>
  )
}

export default AvailabilityBox
