import dayjs from 'dayjs'
import { find } from 'lodash/fp'

import { DATE_TIME_FORMATS } from 'utils/datetime'

const getNewRouteData = ({
  deliveryDate: deliveryDateTmp,
  prevRouteId,
  cartDeliveries,
  availableDeliveryDates,
}) => {
  const deliveryDate = dayjs(deliveryDateTmp).format(DATE_TIME_FORMATS.API_DATE)
  const cartDeliveryAtNewDate = find({ date: deliveryDate })(cartDeliveries)

  const matchForCartDelivery =
    cartDeliveryAtNewDate &&
    find({
      deliveryDate: cartDeliveryAtNewDate.date,
      routeId: cartDeliveryAtNewDate.routeId,
    })(availableDeliveryDates)

  if (cartDeliveryAtNewDate && matchForCartDelivery) {
    return cartDeliveryAtNewDate
  }

  const foundByDateAndRoute = find({
    deliveryDate,
    ...(prevRouteId && { routeId: prevRouteId }),
  })(availableDeliveryDates)

  const foundByDate = find({
    deliveryDate,
  })(availableDeliveryDates)

  return foundByDateAndRoute || foundByDate || {}
}

export default getNewRouteData
