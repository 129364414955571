import { makeMessages, makeTranslation } from 'utils'

const messages = {
  feedbackTitle: 'feedback.title',
  feedbackMessage: 'feedback.message',
  feedbackOptions: 'feedback.options',
  feedbackOption1: 'feedback.option1',
  feedbackOption2: 'feedback.option2',
  feedbackOption3: 'feedback.option3',
  feedbackOption4: 'feedback.option4',
  feedbackOption5: 'feedback.option5',
  feedbackCtaBtn: 'feedback.ctaBtn',
  feedbackSuccessTitle: 'feedback.successTitle',
  feedbackSuccessMessage: 'feedback.successMessage',
  subscribeToast: 'subscribeToast',
  unsubscribeToast: 'unsubscribeToast',
  subscribeConfirmToast: 'subscribeConfirmToast',
  subscribedTitle: 'subscribed.title',
  subscribedMessage: 'subscribed.message',
}

export default makeMessages(messages, makeTranslation('newsletter'))
