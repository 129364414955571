import { reduce, flow, find, get } from 'lodash/fp'

export const getApiProductsToUpdate = reduce((acc, inactiveProduct) => {
  const {
    product: { active, id, unitsOfMeasure },
    units,
  } = inactiveProduct
  return [
    ...acc,
    {
      product: { id },
      units: units
        .filter(
          ({ unitOfMeasure }) =>
            !active ||
            !flow(
              find({ unitOfMeasure }),
              get('stock'),
            )(unitsOfMeasure),
        )
        .map(({ unitOfMeasure }) => ({
          unit_of_measure: unitOfMeasure,
          quantity: 0,
        })),
    },
  ]
}, [])
