import styled from 'styled-components'

import theme from 'theme'

import { breakPointTablet } from 'components/Layout'
import { ProductBoxContainer } from 'components/Product/ProductBox/styles'
import { CatalogProductButtonsContainer } from 'components/Product/ProductButtons/styles'
import { Link } from 'components/Text'

export const LinkWrapper = styled(Link)`
  width: auto;
`

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 320px;
  @media screen and (max-width: ${breakPointTablet}) {
    height: 240px;
  }
  position: relative;
  overflow: hidden;
`

export const Img = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  transition: scale 0.5s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.3, 1);
  z-index: 0;
`

export const PromoTileContainer = styled.div`
  display: flex;
  flex-direction: ${p => (p.$isProductTile ? 'row' : 'column')};
  overflow: auto;
  height: 100%;
  border-radius: 16px;
  justify-content: space-between;
  transition: background-color 0.5s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.3, 1);
  background-color: ${p => p.$bgColor};
  &:hover {
    ${p => !p.$isProductTile && `background-color: ${p.$hoverBgColor};`};
    ${Img} {
      scale: 1.1;
    }
  }
  @media screen and (max-width: 416px) {
    width: calc(100vw - 32px);
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${p => (p.$isProductTile ? '50%' : '100%')};
  padding: ${p =>
    p.$isProductTile
      ? '48px 0px 48px 40px'
      : `40px 40px ${theme.spacing.xs} 40px`};
  span {
    color: ${p => p.$txtColor};
  }
  @media screen and (max-width: ${breakPointTablet}) {
    padding: ${p =>
      p.$isProductTile ? '40px 16px 40px 24px' : '32px 24px 8px 24px'};
  }
`

export const BrowseButton = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  margin: ${theme.spacing.base};
  border-radius: 8px;
  height: fit-content;
  align-self: flex-end;
  background-color: ${p => p.$bgColor};
  z-index: 1;
`

const Text = styled.span`
  font-family: ${theme.fonts.primary};
  width: 100%;
  text-align: left;
  user-select: none;
`

export const PromoTagText = styled(Text)`
  margin-bottom: ${theme.spacing.base};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
`

export const PromoTileTitle = styled(Text)`
  margin-bottom: ${theme.spacing.sm};
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.02em;
  word-break: break-word;
  @media screen and (max-width: ${breakPointTablet}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const PromoTileDescription = styled(Text)`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  word-break: break-word;
`

export const BrowseText = styled(Text)`
  width: fit-content;
  font-weight: 400;
  color: ${theme.colors.white};
  white-space: nowrap;
  font-size: 12px;
  line-height: 24px;
  margin-right: ${theme.spacing.sm};
`

export const ProductContainer = styled.div`
  display: flex;
  width: 50%;
  padding: ${theme.spacing.sm};
  > a {
    width: 100%;
  }
  ${ProductBoxContainer} {
    min-width: 148px;
    max-width: initial;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    :hover {
      background: white;
      ${CatalogProductButtonsContainer} {
        background: ${theme.colors.lightGrey};
        box-shadow: none;
        input,
        button {
          background: ${theme.colors.lightGrey};
        }
      }
    }
  }
  ${CatalogProductButtonsContainer} {
    width: 100%;
    justify-content: space-between;
    input,
    button {
      :hover {
        background: white !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      }
    }
  }
  @media screen and (max-width: ${breakPointTablet}) {
    padding: ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs} 0px;
  }
`
