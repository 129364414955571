import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'
import {
  getColors,
  getSpacing,
  getFonts,
} from 'components/helpers/theme-selectors'
import { P } from 'components/Text'

// prettier-ignore
export const DropdownTitle = styled(P)`
  font-family: ${props => getFonts(props).secondary};
  font-size: 11px;
  color: ${props => getColors(props).primary};
  user-select: none;
  margin-right: ${props => getSpacing(props).xs};
  ${props =>
    props.isOpen && `color: ${getColors(props).white}`};
`

// prettier-ignore
export const DropdownSelectedCount = styled.span`
  font-family: Inter;
  font-weight: 400;
  font-size: 11px;
  user-select: none;
  border-radius: 5px;
  padding: 4px 10px;
  margin-right: 11px;
  background-color: ${props => getColors(props).secondary};
  color: ${props => getColors(props).white};
  ${props => props.isOpen && `
    color: ${getColors(props).white};
    background-color: ${getColors(props).greenDark};
  `}
`

export const ClearSelectedBtn = styled(DropdownSelectedCount)`
  display: none;
  font-weight: 900;
`

// prettier-ignore
export const DropdownWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
`

// prettier-ignore
export const DropdownControl = styled(Flex)`
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  align-items: center;
  padding: 0 ${props => getSpacing(props).xs};
  border-radius: 8px;
  :hover {
    background-color: ${props => getColors(props).lightGrey};
    @media (hover) and (pointer: fine) {
      > span:first-of-type {
        display: none;
      }
      > span:not(:first-of-type) {
        display: inline;
        padding: 0;
        background-color: ${props => getColors(props).greenLight};
      }
    }
  }
  ${props => props.isOpen && `
    background-color: ${getColors(props).secondary};
    :hover {
      background-color: ${getColors(props).greenLight};
      > span {
        background-color: ${getColors(props).secondary};
      }
    }
  `};
  ${props => props.disabled && `
    cursor: auto;
    opacity: 0.3;
    :hover {
      background-color: initial;
    }
  `}
`

export const DropdownIconWrapper = styled(Box)`
  margin: 0 -2px;
`
