import { RETAIL_EVENTS } from './consts'
import {
  retailTrackUserEvent,
  mapProductToRetailDetails,
  formatCategories,
} from './utils'

export const retailTrackAddToCart = ({ product, quantity, attributionToken }) =>
  retailTrackUserEvent(RETAIL_EVENTS.ADD_TO_CART, {
    productDetails: [mapProductToRetailDetails({ product, quantity })],
    attributionToken,
  })

export const retailTrackRemoveFromCart = ({ product, quantity }) =>
  retailTrackUserEvent(RETAIL_EVENTS.REMOVE_FROM_CART, {
    productDetails: [mapProductToRetailDetails({ product, quantity })],
  })

export const retailTrackPurchaseComplete = ({
  unifiedOrderNumber,
  orders,
  value,
  tax,
  currency,
}) =>
  retailTrackUserEvent(RETAIL_EVENTS.PURCHASE, {
    purchaseTransaction: {
      id: unifiedOrderNumber,
      revenue: value,
      tax,
      currencyCode: currency,
    },
    productDetails: orders.reduce((acc, order) => {
      const orderItems = order.orderItems.map(({ product, quantity }) =>
        mapProductToRetailDetails({ product, quantity }),
      )

      return [...acc, ...orderItems]
    }, []),
  })

export const retailTrackDetailView = ({ product, attributionToken }) =>
  retailTrackUserEvent(RETAIL_EVENTS.PDP_VIEW, {
    productDetails: [mapProductToRetailDetails({ product })],
    attributionToken,
  })

export const retailTrackCategoryView = ({
  categoryName,
  groupName,
  subgroupName,
}) =>
  retailTrackUserEvent(RETAIL_EVENTS.CATEGORY_PAGE_VIEW, {
    pageCategories: [
      formatCategories({ categoryName, groupName, subgroupName }),
    ],
  })

export const retailTrackHomeView = () =>
  retailTrackUserEvent(RETAIL_EVENTS.HOME_PAGE_VIEW)

export const retailTrackCartView = () =>
  retailTrackUserEvent(RETAIL_EVENTS.CART_PAGE_VIEW)
