import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

// prettier-ignore
export const NotificationWrapper = styled(Flex)`
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${theme.zIndex.base};
  box-sizing: border-box;
  height: ${({ visible }) => (visible ? 'auto' : '0')};
  max-height: ${({ visible }) => (visible ? '180px' : '0')};
  width: 100%;
  transition: max-height ease-out 1500ms;
  overflow: hidden;
  & > * {
    margin: 0;
  }

  ${({ orderMessage }) => orderMessage && `
    right: 0;
    margin: 0;
    font-size: 500;
  `};
`
