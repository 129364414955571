import { handleActions } from 'redux-actions'
import { isEqual, prop } from 'lodash/fp'

import { getInvoicesActions } from '../actions'

import { selectInvoicesDateRange, clearInvoicesDateRange } from './actions'
import { INVOICE_TYPE } from './consts'

const INIT_STATE = {
  byType: {},
  dateRange: {
    startDate: null,
    endDate: null,
  },
}

export default handleActions(
  {
    [getInvoicesActions.SUCCESS]: (state, { data: { meta } }) => {
      const byType = meta.counters.reduce(
        (acc, counter) => ({
          ...acc,
          [counter.name]: {
            ...counter,
            isSelected: counter.name === INVOICE_TYPE.TO_PAY,
          },
        }),
        {},
      )

      const nextState = { ...state, byType }
      if (state) {
        return isEqual(state.byType, byType) ? state : nextState
      }

      return nextState
    },
    [selectInvoicesDateRange]: (state, { payload }) => {
      const startDate = prop('startDate', payload)
        ? payload.startDate.format()
        : null
      const endDate = prop('endDate', payload) ? payload.endDate.format() : null
      return { ...state, dateRange: { startDate, endDate } }
    },
    [clearInvoicesDateRange]: state => ({
      ...state,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    }),
  },
  INIT_STATE,
)
