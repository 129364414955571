import styled from 'styled-components'

import theme from 'theme'

import { TinyText } from 'components/Text'
import { X } from 'components/Icons'

// prettier-ignore
export const SmallText = styled(TinyText).attrs({ as: 'span' })`
  color: ${theme.colors.secondary};

  ${p => p.withMargins && `
    margin-top: ${theme.spacing.xxs};
    margin-left: ${theme.spacing.xxs};
  `};
`

export const XIcon = styled(X).attrs({
  width: 14,
  height: 14,
  color: theme.colors.secondary,
})`
  margin-top: 1px;
  margin-left: -4px;
`
