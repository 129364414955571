import { combineReducers } from 'redux-immutable'

import { CHANGE_PASSWORD_REDUCER_NAME } from './ChangePassword/consts'
import changePasswordReducer from './ChangePassword/reducer'
import { AGREEMENTS_REDUCER_NAME } from './Agreements/consts'
import agreementsReducer from './Agreements/reducer'

export default combineReducers({
  [CHANGE_PASSWORD_REDUCER_NAME]: changePasswordReducer,
  [AGREEMENTS_REDUCER_NAME]: agreementsReducer,
})
