import { compose } from 'lodash/fp'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import injectSaga from 'utils/injectSaga'
import { bindActionCreators } from 'utils/redux-utils'

import { CAMPAIGNS_REDUCER_NAME } from './consts'
import {
  campaignsActions,
  campaignDetailsActions,
  campaignProductsActions,
} from './actions'
import {
  campaignsSelector,
  campaignDetailsSelector,
  campaignProductsSelector,
} from './selectors'
import saga from './sagas'

export * from './consts'

export const mapStateToProps = createStructuredSelector({
  campaigns: campaignsSelector,
  campaignDetails: campaignDetailsSelector,
  campaignProducts: campaignProductsSelector,
})

export const mapDispatchToProps = bindActionCreators({
  getCampaigns: campaignsActions.delta,
  getCampaignDetails: campaignDetailsActions.delta,
  clearCampaignDetails: campaignDetailsActions.clear,
  getCampaignProducts: campaignProductsActions.delta,
  clearCampaignProducts: campaignProductsActions.clear,
})

export default compose(
  injectSaga({ key: CAMPAIGNS_REDUCER_NAME, saga }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
