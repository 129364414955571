import { css } from 'styled-components'

import interRegularTTF from 'assets/fonts/Inter-Regular.ttf'
import interMediumTTF from 'assets/fonts/Inter-Medium.ttf'
import interBoldTTF from 'assets/fonts/Inter-Bold.ttf'
import interSemiBoldTTF from 'assets/fonts/Inter-SemiBold.ttf'

// prettier-ignore
const fonts = css`
  @font-face {
    font-family: "Inter";
    src: url(${interBoldTTF}) format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter";
    src: url(${interMediumTTF}) format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter";
    src: url(${interSemiBoldTTF}) format("truetype");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Inter";
    src: url(${interRegularTTF}) format("truetype");
    font-weight: 400;
    font-style: normal;
  }
`

export default fonts
