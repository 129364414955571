import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

export const RowContainer = styled(Flex)`
  flex-direction: column;
  ${p => p.isFixed && `box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.15);`};
`

export const InnerRow = styled(Flex)`
  flex-direction: column;
  background-color: ${theme.colors.white};
  flex: 1;
  padding: 0 ${theme.spacing.base};
`

// prettier-ignore
export const HeaderRow = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  transition: padding 100ms;

  ${p => p.isLastItem ? `
    padding-bottom: ${theme.spacing.sm};
  ` : `
    padding: ${theme.spacing.xs} 0;
  `};
`

export const MobileSeparator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.sm};
  background-color: ${theme.colors.gray2};
`

export const SeparatorContainer = styled.div`
  width: 100%;
`
