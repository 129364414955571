import { useState } from 'react'
import { useInView } from 'react-intersection-observer'

const useIsElementInView = () => {
  const [hasSeenElement, setHasSeenElement] = useState(false)

  const handleElementInView = inView => {
    if (inView && !hasSeenElement) {
      return setHasSeenElement(inView)
    }

    return null
  }
  const [elementRef] = useInView({
    delay: 50,
    onChange: inView => handleElementInView(inView),
  })

  return [elementRef, hasSeenElement, setHasSeenElement]
}

export default useIsElementInView
