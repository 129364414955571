import React from 'react'
import { Box, Flex } from '@rebass/grid'

import { Truck as TruckIcon } from 'components/Icons'
import { FixedBottom } from 'components/Layout'

import { HeadingColumn, TailColumn, Column } from '../../components'

import { Address, PayBtn, PayScenario, DeliveryDates } from '../components'

import { RowContainer, InnerRow } from './styles'

const Desktop = ({ stickyPaymentId, isFixed }) => (
  <FixedBottom {...{ isFixed }}>
    <RowContainer {...{ isFixed }} id={stickyPaymentId} height={80}>
      <InnerRow>
        <HeadingColumn px={24}>
          <Flex alignItems="center">
            <Box mr={24}>
              <TruckIcon />
            </Box>
            <Box width={1}>
              <DeliveryDates />
            </Box>
          </Flex>
        </HeadingColumn>

        <Column borderedLeft width={1} px={24}>
          <Address />
        </Column>

        <PayScenario
          WrapComponent={({ children }) => (
            <Column borderedLeft width={1} px={24}>
              {children}
            </Column>
          )}
        />
      </InnerRow>

      <TailColumn>
        <PayBtn />
      </TailColumn>
    </RowContainer>
  </FixedBottom>
)

export default Desktop
