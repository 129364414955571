import { makeMessages, makeTranslation } from 'utils'

const messages = {
  multiplePackagings: 'multiplePackagings',
  move: 'move',
  moved: 'moved',
  revert: 'revert',
  see: 'see',
  promotion: 'promotion',
  uniqueMet: { id: 'promotions.value.uniqueMet' },
  amountMet: { id: 'promotions.value.amountMet' },
  max: { id: 'promotions.value.maxObtained' },
  requirementsMet: { id: 'promotions.value.requirementsMet' },
  requirementsMissing: { id: 'promotions.value.requirementsMissing' },
  valueReqOnly: { id: 'promotions.value.valueReqOnly' },
  temporarilyUnavailable: 'temporarilyUnavailable',
  removedFromOffer: 'removedFromOffer',
  amountInCart: 'amountInCart',
  moveSingleProduct: 'moveSingleProduct',
  pointsShort: { id: 'pointsShort' },
  packaging: { id: 'cart.productDetails.packaging' },
  netPrice: { id: 'cart.productDetails.netPrice' },
  pricePerUnit: { id: 'cart.productDetails.pricePerUnit' },
  articleNo: { id: 'cart.productDetails.articleNo' },
  gross: { id: 'prices.gross' },
  maxAvailableAmount: { id: 'nonStock.maxAvailableAmount' },
  showProductDetails: { id: 'product.showDetails' },
  availableFrom: { id: 'stock.availableFrom' },
  elasticStockAvailability: 'elasticStock.availability',
  missingPoints: { id: 'cart.missingPoints' },
}

export default makeMessages(messages, makeTranslation('cart'))
