/* eslint-disable no-restricted-globals */
import { put, select, throttle } from 'redux-saga/effects'

import { hasMajorVersionBumped } from 'utils'

import { appConfigActions } from '../actions'
import { apiVersionSelector } from '../selectors'

function* checkApiVersionChangeFlow({ payload: { apiVersion } }) {
  if (!apiVersion) {
    // console.log('No API version has been found')
    return
  }

  const prevApiVersion = yield select(apiVersionSelector)
  // console.log(
  //   `Detecting breaking changes in the new API version ${prevApiVersion} -> ${apiVersion}`,
  // )

  yield put(appConfigActions.updateApiVersion(apiVersion))
  if (prevApiVersion && hasMajorVersionBumped(prevApiVersion, apiVersion)) {
    // console.log('There are breaking changes in the API, refreshing the page')
    location.reload()
  } else {
    // console.log('No breaking changes in the API')
  }
}

export default [
  throttle(5000, appConfigActions.checkApiVersion, checkApiVersionChangeFlow),
]
