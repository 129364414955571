import styled from 'styled-components'

import theme from 'theme'

export const QuantityControlsWrapper = styled.div`
  flex: 1;
  display: flex;
  border-radius: ${theme.borderRadius.base};
  background-color: ${theme.colors.lightGrey};
  padding: ${theme.spacing.xxs};
  gap: ${theme.spacing.xs};
`
export const QuantityBtnWrapper = styled.button`
  display: flex;
  padding: ${theme.spacing.xxs};
  &:hover {
    background-color: ${theme.colors.lightGrey7};
  }
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

export const UnitQuantityInputWrapper = styled.input`
  flex: 1;
  padding: 6px ${theme.spacing.xxs};
  text-align: center;
  border-radius: 6px;
  font-size: ${theme.textSizes.sm};
  font-weight: ${theme.fontWeights.medium};
  background-color: ${theme.colors.lightGrey};
  color: ${theme.colors.secondary};

  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.secondary};
  }
`
