import React from 'react'
import { FormattedMessage } from 'react-intl'

import theme from 'theme'
import { SecondaryText } from 'components/Text'

import messages from '../../messages'

import { PriceBlock, PriceBlockTiny, Amount } from './styles'

const getColor = ({ isError, isAddedAmount }) => {
  if (isError) return theme.colors.redDark
  if (isAddedAmount) return theme.colors.gray4
  return theme.colors.secondary
}

const PricingBlock = ({ isAddedAmount, isError, amount, tiny, label }) => {
  const Container = tiny ? PriceBlockTiny : PriceBlock
  const color = getColor({ isError, isAddedAmount })
  const affectedAmountLabel = isAddedAmount ? (
    <FormattedMessage {...messages.addedAmountText} />
  ) : (
    <FormattedMessage {...messages.lackAmountText} />
  )

  return (
    <Container>
      <Amount {...{ color }}>{amount}</Amount>

      <SecondaryText {...{ color }}>
        {label || affectedAmountLabel}
      </SecondaryText>
    </Container>
  )
}

export default PricingBlock
