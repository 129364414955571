import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from '@rebass/grid'
import { find } from 'lodash'

import {
  formatPrice,
  formatPricePerUnit,
  makeMessages,
  makeTranslation,
  useRichFormatMessage,
} from 'utils'

import { FloatingPriceWrapper } from 'components/FloatingPrice'
import { getUnitDef, isLoweredPrice, parseAmount } from '../utils'

import {
  CatalogPriceContainer,
  CatalogPriceSection,
  ProductSubText,
  CatalogPrice,
  OldPrice,
  WeightedSubText,
} from './styles'

const messages = makeMessages(
  {
    gross: { id: 'prices.gross' },
    soldForShort: 'soldForShort',
    soldFor: 'soldFor',
  },
  makeTranslation('product'),
)

const CatalogProductPrices = ({
  isVariant,
  quantity = 1,
  unitOfMeasureDescription: unitDesc,
  baseUnitDesc,
  baseUnitOfMeasure,
  unitOfMeasure = baseUnitOfMeasure,
  big,
  pricePerUnit,
  unitForPricePerUnit,
  unitsOfMeasure,
  suppressSecondaryPrices,
  hideQuantity,
  hideWeightedInfo,
  unitInCart,
  totalPriceGross,
  totalPriceNet,
  prizeUnit,
  promotion,
  hideOldPrice,
  hasFloatingPrice,
}) => {
  const formatRichMessage = useRichFormatMessage()

  const reward = promotion?.reward
  const isLoweredPricePromo = isLoweredPrice(promotion)
  const priceNetPerUnit = isLoweredPricePromo
    ? reward?.priceNetPerUnit
    : pricePerUnit
  const rewardTotalPriceNet = prizeUnit?.totalPriceNet || 0
  const rewardTotalPriceGross = prizeUnit?.totalPriceGross || 0
  const { priceNet, priceGross, multiplier } =
    find(unitsOfMeasure, { unitOfMeasure }) || {}

  const productPriceNet = isLoweredPricePromo ? reward?.priceNet : priceNet
  const productPriceGross = isLoweredPricePromo
    ? reward?.priceGross
    : priceGross

  const unitTotalPriceNet = unitInCart?.totalPriceNet || 0
  const totalNetPrice = unitInCart?.prize
    ? rewardTotalPriceNet
    : unitTotalPriceNet + rewardTotalPriceNet ||
      totalPriceNet ||
      productPriceNet

  const unitTotalPriceGross = unitInCart?.totalPriceGross || 0
  const totalGrossPrice = unitInCart?.prize
    ? rewardTotalPriceGross
    : unitTotalPriceGross + rewardTotalPriceGross ||
      totalPriceGross ||
      productPriceGross

  const weightedInfo = multiplier !== 1 && (
    <FormattedMessage
      {...messages[big ? 'soldFor' : 'soldForShort']}
      values={{
        amount: parseAmount(multiplier),
        unit: unitDesc || baseUnitDesc,
      }}
    />
  )

  return (
    <CatalogPriceSection {...{ isVariant }}>
      <Flex
        flexDirection="column"
        justifyContent={
          multiplier !== 1 || priceNetPerUnit ? 'start' : 'center'
        }
      >
        {!hideQuantity && (
          <ProductSubText marginBottom={2}>
            {getUnitDef({
              unitQuantity: quantity,
              baseUnitDesc,
              unitDesc: unitDesc || baseUnitDesc,
            })}
          </ProductSubText>
        )}
        {!suppressSecondaryPrices && (
          <CatalogPriceContainer>
            <FloatingPriceWrapper
              hasTooltip
              hasFloatingPrice={hasFloatingPrice}
            >
              {isLoweredPricePromo && !hideOldPrice ? (
                <Flex flexDirection="row" alignItems="flex-end">
                  <CatalogPrice>{formatPrice(productPriceNet)}</CatalogPrice>
                  <OldPrice>{formatPrice(priceNet)}</OldPrice>
                </Flex>
              ) : (
                <CatalogPrice>{formatPrice(totalNetPrice)}</CatalogPrice>
              )}
            </FloatingPriceWrapper>
            <ProductSubText marginBottom={12}>
              {formatRichMessage(messages.gross, {
                price: formatPrice(totalGrossPrice),
              })}
            </ProductSubText>
          </CatalogPriceContainer>
        )}
      </Flex>
      {!hideWeightedInfo && (
        <WeightedSubText>
          {weightedInfo ||
            formatPricePerUnit(priceNetPerUnit, unitForPricePerUnit)}
        </WeightedSubText>
      )}
    </CatalogPriceSection>
  )
}

export default CatalogProductPrices
