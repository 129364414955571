import styled from 'styled-components'

import theme from 'theme'
import { TinyText, BaseText } from 'components/Text'
import { getColors } from 'components/helpers/theme-selectors'

import { APP_BREAKPOINTS } from 'consts'

// prettier-ignore
export const DateContainer = styled.div`
  display: flex;
  height: 76px;
  padding: 16px;
  background-color: ${p => getColors(p).white};
  position: relative;

  &:hover {
    cursor: pointer;
  }

  ${p => p.isIncoming && `
    background-color: ${getColors(p).gray1};
  `};
`

export const DateDesc = styled.div`
  margin-left: 16px;
  margin-top: -1px;
`

export const Month = styled(TinyText)`
  line-height: 16px;
  color: ${p => (p.isIncoming ? theme.colors.gray3 : theme.colors.primary)};
  text-transform: capitalize;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const WeekDay = styled(BaseText).attrs({ as: 'div' })`
  color: ${p => (p.isIncoming ? theme.colors.gray3 : theme.colors.primary)};
  font-size: 18px;
  line-height: 20px;
  margin-top: 4px;
  text-transform: capitalize;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: 20px;
  }
`

export const CalendarIconContainer = styled.div`
  background-color: ${p => (p.isIncoming ? 'transparent' : '#fff')};
  z-index: ${theme.zIndex.base};
`
