import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getSpacing } from 'components/helpers/theme-selectors'
import { PageContent } from 'containers/App/LoggedInLayout/styles'

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: ${p => getSpacing(p).md};

  max-width: 1024px;
  width: 100%;

  @media screen and (max-width: 505px) {
    padding: ${p => getSpacing(p).base};
  }
`

export const BackButtonContainer = styled.div`
  margin-right: auto;
  margin-bottom: ${p => getSpacing(p).md};
`

export const PageLayout = styled(PageContent)`
  @media (min-width: 1440px) {
    position: relative;

    ${BackButtonContainer} {
      position: absolute;
      left: ${p => getSpacing(p).md};
      top: ${p => getSpacing(p).md};
    }
  }
`
