import { call, takeLatest, put, select } from 'redux-saga/effects'
import { omit } from 'lodash'
import history from 'utils/history'

import { ROUTES } from 'consts'
import { genericGetDataEnhanced } from 'containers/App/sagas'
import { notifyAction } from 'containers/App/actions'
import cartActions from 'containers/Cart/actions'
import { ALERT_TYPE } from 'components/Alert'
import { getDeliveryDatesByProductsFlow } from 'containers/Delivery/sagas'
import { trackNotAllAdded } from 'services/analytics'
import { userDataSelector } from 'containers/UserInfo/selectors'

import { addAllToCart } from '../api'
import {
  addTemplateToCartActions,
  templateNotAddedItemsActions,
} from '../actions'

export function* addTemplateToCartFlow({
  data: { id, templateName, type } = {},
  additionalData: { successMessage, errorMessage, successCallback },
}) {
  try {
    yield put(cartActions.request())

    const params = { id }
    const response = yield call(genericGetDataEnhanced, {
      actions: addTemplateToCartActions,
      request: addAllToCart,
      params,
    })

    if (!response) return

    const { itemsNotEnoughStock } = response
    // need to refetch the cart so on the screen with missing items
    // delivery dates for elastic products could be read from cart
    yield put(cartActions.delta())

    if (itemsNotEnoughStock.length) {
      yield put(
        templateNotAddedItemsActions.success({
          items: itemsNotEnoughStock,
          templateName,
          templateId: id,
          type,
        }),
      )

      const user = yield select(userDataSelector)
      yield call(trackNotAllAdded, { user })

      history.push(ROUTES.NOT_ALL_ADDED)
    } else {
      if (successCallback) yield call(successCallback)

      yield call(getDeliveryDatesByProductsFlow, {
        callback: function* addAllCallback() {
          yield put(notifyAction({ message: successMessage }))
        },
      })
    }
  } catch (error) {
    const omittedError = omit(error, ['headers'])
    yield put(cartActions.failure(omittedError))
    yield put(notifyAction({ message: errorMessage, type: ALERT_TYPE.ERROR }))
  }
}

export default [
  takeLatest(addTemplateToCartActions.DELTA, addTemplateToCartFlow),
]
