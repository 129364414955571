import {
  flow,
  groupBy,
  mapValues,
  mergeWith,
  sum,
  values,
  isNumber,
} from 'lodash/fp'

import {
  PAYMENT_COLOR_WEIGHT,
  PAY_SCENARIO_WEIGHT,
  RESTRICTION_DRIVEN_BY,
} from './consts'

export const getUniqueUnits = flow(
  groupBy('unitOfMeasure'),
  mapValues(([a, b = {}]) =>
    mergeWith((src, obj) => (isNumber(src) ? sum([obj, src]) : src), a, b),
  ),
  values,
)

export const hasRestrictionDrivenBy = tradeLimit => {
  const { scenario, paymentColorName } = tradeLimit

  const paymentColorWeigth = PAYMENT_COLOR_WEIGHT[paymentColorName]
  const scenarioWeight = PAY_SCENARIO_WEIGHT[scenario]

  if (scenarioWeight > paymentColorWeigth) {
    return RESTRICTION_DRIVEN_BY.CART_LIMIT
  }

  return RESTRICTION_DRIVEN_BY.INVOICES_STATUS
}
