import { call, select } from 'redux-saga/effects'
import { head, isEmpty, find } from 'lodash/fp'
import dayjs from 'dayjs'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import {
  deliveryDatesByProductIdsSelector,
  cartProductDeliveryDateSelector,
  cartDeliveriesSelector,
} from './selectors'
import cartActions from './actions'
import { updateDelivery } from './api'

export const ELASTIC_AFFECT_TYPE = {
  EXCEEDED: 'exceeded',
  DECREASED: 'decreased',
}

function* getProductInCartDeliveryDate(
  productId,
  onCartRefresh,
  justAddedToCart,
) {
  const deliveryDate = yield select(cartProductDeliveryDateSelector(productId))
  if (deliveryDate) return deliveryDate

  // need to refetch the cart as product has just been added
  // ↑ Yes, but only after the product is actually in cart, otherwise the response is missing the just added product
  if (!justAddedToCart) {
    yield call(onCartRefresh)
  }
  return yield select(cartProductDeliveryDateSelector(productId))
}

export function* getElasticQuantity({
  unitOfMeasureObj,
  requestedQuantity,
  checkElasticStock,
  product,
  onCartRefresh,
  justAddedToCart,
}) {
  if (!checkElasticStock) return requestedQuantity

  if (
    unitOfMeasureObj.inCartQuantity === 0 &&
    requestedQuantity > unitOfMeasureObj.stock
  ) {
    // initial addition from historical order
    return unitOfMeasureObj.stock
  }

  const deliveryDate = yield call(
    getProductInCartDeliveryDate,
    product.id,
    onCartRefresh,
    justAddedToCart,
  )

  if (!deliveryDate) return requestedQuantity
  if (deliveryDate.isSameOrAfter(product.closestDeliveryTime, 'd')) {
    return requestedQuantity
  }

  if (
    requestedQuantity > unitOfMeasureObj.stock &&
    unitOfMeasureObj.inCartQuantity <= unitOfMeasureObj.stock
  ) {
    return unitOfMeasureObj.stock
  }

  return requestedQuantity
}

function* getElasticStockOnQuantityIncrease({
  product,
  unitOfMeasureObj,
  requestedQuantity,
  onCartRefresh,
}) {
  const deliveryDate = yield call(
    getProductInCartDeliveryDate,
    product.id,
    onCartRefresh,
  )

  if (!deliveryDate) return null // product has just been added to a cart by clicking "Add to cart"
  if (deliveryDate.isSameOrAfter(product.closestDeliveryTime, 'd')) return null

  if (requestedQuantity > unitOfMeasureObj.inCartQuantity) {
    return {
      stockType: ELASTIC_AFFECT_TYPE.EXCEEDED,
      unitOfMeasureObj,
      requestedQuantity,
      deliveryDate,
    }
  }

  return null
}

function* getElasticStockOnQuantityDecrease({
  product,
  unitOfMeasureObj,
  requestedQuantity,
  shiftOnDecreaseToClosestDate,
}) {
  const closestDeliveryOption = yield call(getProductClosestDeliveryDate, {
    product,
  })

  const cartProductDeliveryDate = yield select(
    cartProductDeliveryDateSelector(product.id),
  )
  if (
    cartProductDeliveryDate.isSameOrBefore(
      closestDeliveryOption.deliveryDate,
      'd',
    )
  ) {
    return null
  }

  if (shiftOnDecreaseToClosestDate) {
    const {
      routeId,
      routeName,
      routesSortingOrder,
      deliveryDate,
    } = closestDeliveryOption
    yield call(genericGetDataEnhanced, {
      actions: cartActions,
      request: updateDelivery,
      params: {
        routeId,
        routeName,
        routesSortingOrder,
        date: deliveryDate,
        productIds: [product.id],
      },
      method: 'cancel', // do not change the store
    })

    // exiting as there is no need for user's confirmation
    return null
  }

  return {
    stockType: ELASTIC_AFFECT_TYPE.DECREASED,
    unitOfMeasureObj,
    requestedQuantity,
    deliveryDate: closestDeliveryOption.deliveryDate,
  }
}

function* getProductClosestDeliveryDate({ product }) {
  const deliveryDatesSettingsList = yield select(
    deliveryDatesByProductIdsSelector([product.id]),
  )
  if (isEmpty(deliveryDatesSettingsList)) return null

  const deliveryOption = head(deliveryDatesSettingsList)
  const cartDeliveries = yield select(cartDeliveriesSelector)
  const inCartSameDelivery = cartDeliveries.find(({ date }) =>
    dayjs(date).isSame(deliveryOption.deliveryDate, 'd'),
  )

  if (inCartSameDelivery) {
    // if there are some products on the same date in the cart
    // route ID should be preserved
    const finalDeliveryOption = deliveryDatesSettingsList.find(
      ({ deliveryDate, routeId }) =>
        dayjs(inCartSameDelivery.date).isSame(deliveryDate, 'd') &&
        inCartSameDelivery.routeId === routeId,
    )

    if (finalDeliveryOption) return finalDeliveryOption
  }

  return deliveryOption
}

const hasElasticQuantityDecreased = (unitOfMeasureObj, updatedUnitOfMeasure) =>
  unitOfMeasureObj.inCartQuantity > updatedUnitOfMeasure.inCartQuantity && // is quantity decreasing?
  unitOfMeasureObj.inCartQuantity > updatedUnitOfMeasure.stock && // did previous quantity exceed the stock?
  updatedUnitOfMeasure.stock >= updatedUnitOfMeasure.inCartQuantity // is current quantity in stock limit?

export function* detectAffectedElasticStock({
  checkElasticStock,
  product,
  unitOfMeasureObj, // TODO should be named prevUnitOfMeasureObj
  requestedQuantity,
  shiftOnDecreaseToClosestDate,
  onCartRefresh,
}) {
  if (!checkElasticStock) return null

  const updatedUnitOfMeasure = find({
    unitOfMeasure: unitOfMeasureObj.unitOfMeasure,
  })(product.unitsOfMeasure)
  if (hasElasticQuantityDecreased(unitOfMeasureObj, updatedUnitOfMeasure)) {
    return yield call(getElasticStockOnQuantityDecrease, {
      product,
      unitOfMeasureObj: updatedUnitOfMeasure,
      requestedQuantity,
      shiftOnDecreaseToClosestDate,
    })
  }

  return yield call(getElasticStockOnQuantityIncrease, {
    product,
    unitOfMeasureObj: updatedUnitOfMeasure,
    requestedQuantity,
    onCartRefresh,
  })
}
