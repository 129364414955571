import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  getPayStatusActions,
  payStatusSelector,
  pollPayStatus,
} from 'containers/Invoices/PayProcessing'

import PayStatus from './PayStatus'

const PayProcessing = () => {
  const { paymentId } = useParams()
  const dispatch = useDispatch()

  const payStatus = useSelector(payStatusSelector)

  useEffect(() => {
    dispatch(pollPayStatus({ paymentId }))

    return () => {
      dispatch(getPayStatusActions.cancel())
    }
  }, [])

  return <PayStatus variant={payStatus} />
}
export default PayProcessing
