import React from 'react'
import theme from 'theme'

const Kosher = ({ color = theme.colors.primary }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.71063 1.81584C7.8399 1.59423 8.1601 1.59423 8.28937 1.81584L13.5483 10.8312C13.6786 11.0545 13.5175 11.335 13.259 11.335H2.74104C2.48249 11.335 2.3214 11.0545 2.45167 10.8312L7.71063 1.81584Z"
      stroke={color}
      className="primaryStroke"
      strokeWidth="1.33"
    />
    <path
      d="M8.28937 14.1842C8.1601 14.4058 7.8399 14.4058 7.71063 14.1842L2.45167 5.16879C2.3214 4.94547 2.48249 4.665 2.74104 4.665L13.259 4.665C13.5175 4.665 13.6786 4.94547 13.5483 5.1688L8.28937 14.1842Z"
      stroke={color}
      className="primaryStroke"
      strokeWidth="1.33"
    />
  </svg>
)

export default Kosher
