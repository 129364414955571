import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from '@rebass/grid'
import { find } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'

import { formatAmount } from 'components/Product/utils'
import messages from 'components/Product/messages'

import { SmallText } from '../SelfPromoBar/styles'
import { AmountsContainer } from './styles'

const Amounts = ({ units, unitsOfMeasure }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 719px)' })

  return (
    !isMobile && (
      <AmountsContainer>
        {units
          .sort((a, b) => a.prize - b.prize)
          .map(({ unitOfMeasure, quantity, prize }) => (
            <Flex
              justifyContent="space-between"
              key={`${unitOfMeasure}-${prize}`}
            >
              <SmallText>
                <FormattedMessage
                  {...messages[prize ? 'pricePromo' : 'pricePlain']}
                />
              </SmallText>
              <SmallText bold>
                {formatAmount(
                  find({ unitOfMeasure }, unitsOfMeasure),
                  quantity,
                )}
              </SmallText>
            </Flex>
          ))}
      </AmountsContainer>
    )
  )
}

export default Amounts
