import React from 'react'

import { PORTAL_MODAL_VARIANTS } from '../PortalModal/consts'
import {
  ConfirmationModal,
  ConfirmationModalHeader,
  ModalBtnsContainer,
  ModalFooterContainer,
  DescriptionText,
  LeftBtn,
  RightBtn,
  BUTTONS_VARIANT,
} from './styles'

const Confirmation = ({
  children,
  isOpen,
  isWide,
  onClose,
  headerClassName,
  onLeftBtnClick = onClose,
  onConfirm,
  isConfirmDisabled,
  headingText,
  footerText,
  leftBtnText,
  leftBtnTestId = 'confirmation-cancel',
  rightBtnText,
  rightBtnTestId,
  btnsVariant = BUTTONS_VARIANT.DELETE,
}) => (
  <ConfirmationModal
    isOpen={isOpen}
    onClose={onClose}
    $isWide={isWide}
    variant={PORTAL_MODAL_VARIANTS.MIDDLE}
    testId="confirmation-modal"
  >
    <ConfirmationModalHeader
      className={headerClassName}
      heading={headingText}
      onClick={onClose}
    />

    {children}

    <ModalFooterContainer>
      {footerText && <DescriptionText>{footerText}</DescriptionText>}
      <ModalBtnsContainer>
        {leftBtnText && (
          <LeftBtn onClick={onLeftBtnClick} data-test-id={leftBtnTestId}>
            {leftBtnText}
          </LeftBtn>
        )}
        <RightBtn
          onClick={onConfirm}
          $variant={btnsVariant}
          $singleButton={!leftBtnText}
          data-test-id={rightBtnTestId}
          disabled={isConfirmDisabled}
        >
          {rightBtnText}
        </RightBtn>
      </ModalBtnsContainer>
    </ModalFooterContainer>
  </ConfirmationModal>
)

export default Confirmation
