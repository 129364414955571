import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { CardC } from 'components/Icons'
import { EVENT_ORIGIN, trackPayInvoices } from 'services/analytics'
import { userDataSelector } from 'containers/UserInfo/selectors'

import messages from '../../messages'
import Footer from '../Footer'

const InvoicesActions = ({ variant, message, onPayInvoices }) => {
  const { formatMessage } = useIntl()
  const { customerNo, storeId } = useSelector(userDataSelector)

  return (
    <Footer
      variant={variant}
      message={message}
      IconComponent={CardC}
      btnLabel={formatMessage(messages.payInvoicesBtn)}
      onBtnClick={() => {
        onPayInvoices()
        trackPayInvoices({
          storeId,
          customerNo,
          origin: EVENT_ORIGIN.DASHBOARD,
        })
      }}
      btnTestID="pay-invoices-btn"
    />
  )
}

export default InvoicesActions
