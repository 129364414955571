import { combineReducers } from 'redux-immutable'

import { createReducer } from 'utils/simpleDataReducer'
import { productsReducer } from 'containers/Products/reducer'

import {
  CAMPAIGNS_ITEMS_REDUCER_NAME,
  CAMPAIGN_DETAILS_REDUCER_NAME,
  CAMPAIGN_PRODUCTS_REDUCER_NAME,
} from './consts'
import {
  campaignsActions,
  campaignDetailsActions,
  campaignProductsActions,
} from './actions'

const campaignProductsReducer = createReducer(campaignProductsActions)

export default combineReducers({
  [CAMPAIGNS_ITEMS_REDUCER_NAME]: createReducer(campaignsActions),
  [CAMPAIGN_DETAILS_REDUCER_NAME]: createReducer(campaignDetailsActions),
  [CAMPAIGN_PRODUCTS_REDUCER_NAME]: (state, action) => {
    const newState = campaignProductsReducer(state, action)
    return productsReducer(newState, action)
  },
})
