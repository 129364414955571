import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { APP_BREAKPOINTS } from 'consts'
import Checkbox from 'components/Input/Checkbox'
import { CheckboxWrapperV2 } from 'components/Input/styles'
import TwButton from 'components/Button/TwButton'
import { Check } from 'components/Icons'
import DropdownBody from '..'

const Multiselect = ({
  portalNode,
  maxWidth,
  offset,
  onOverlayClick,
  onConfirm,
  onOptionSelect,
  options,
  selectedOptions,
  selectedOptionsLimit,
  hasConfirmBtn,
  btnText,
}) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${APP_BREAKPOINTS.DESKTOP_START}px)`,
  })
  const isAboveLimit =
    selectedOptionsLimit && selectedOptions.length === selectedOptionsLimit

  return (
    <DropdownBody
      portalNode={portalNode}
      maxWidth={maxWidth}
      onOverlayClick={onOverlayClick}
      offset={offset}
      testId="multiselect"
      isMobile={!isDesktop}
    >
      <div className="flex flex-col rounded-2xl">
        <div className="flex flex-col p-4">
          {options.map(option => {
            const disabled = isAboveLimit && !selectedOptions.includes(option)

            return (
              <button
                aria-label={option}
                key={option}
                className="group flex px-4 py-2 gap-4 items-center rounded-lg hover:bg-grey-150 disabled:bg-white"
                onClick={() => onOptionSelect(option)}
                disabled={disabled}
                type="button"
              >
                <Checkbox
                  name={option}
                  icon={<Check />}
                  Wrapper={CheckboxWrapperV2}
                  checked={selectedOptions.includes(option)}
                  className="group-hover:bg-grey-400 group-disabled:bg-grey-400"
                  onClick={ev => ev.stopPropagation()}
                  disabled={disabled}
                  readOnly
                />
                <span className="text-[13px] leading-6 text-blue-900">
                  {option}
                </span>
              </button>
            )
          })}
        </div>
        {hasConfirmBtn && (
          <div className="border-t border-t-grey-300 border-solid p-4">
            <TwButton className="w-full h-10" onClick={onConfirm}>
              <span className="text-[12px] leading-6">{btnText}</span>
            </TwButton>
          </div>
        )}
      </div>
    </DropdownBody>
  )
}

export default Multiselect
