import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  useLazyGetProductRecommendationsQuery,
  useGetProductRecommendationsState,
  useGetProductReplacementsQuery,
} from 'containers/Products/rtkApi'
import useIsElementInView from 'hooks/useIsElementInView'

import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'

import messages from '../messages'

const usePDPCarouselData = ({ isUnitDisabled, productId }) => {
  const { formatMessage } = useIntl()
  const [
    carouselRef,
    hasSeenCarousel,
    setHasSeenCarousel,
  ] = useIsElementInView()

  const [fetchProductRecommendations] = useLazyGetProductRecommendationsQuery()
  const {
    data: {
      products: recommendations = [],
      meta: { experiment: { id: itemModelId } = {}, attributionToken } = {},
    } = {},
    isFetching: isRecommendationsFetching,
    isUninitialized: isRecommendationsUninitialized,
  } = useGetProductRecommendationsState({ id: productId })

  const {
    data: { products: replacements = [] } = {},
    isFetching: isReplacementsFetching,
  } = useGetProductReplacementsQuery(
    { id: productId },
    { skip: !productId || !isUnitDisabled },
  )

  useEffect(
    () => {
      if (productId && hasSeenCarousel && !isUnitDisabled) {
        fetchProductRecommendations({ id: productId })
      }

      setHasSeenCarousel(false)
    },
    [productId, hasSeenCarousel, isUnitDisabled],
  )

  const {
    carouselTitle,
    carouselProducts,
    isCarouselLoading,
    itemListName,
    itemListId,
  } = useMemo(() => ({
    carouselProducts: isUnitDisabled ? replacements : recommendations,
    isCarouselLoading: isUnitDisabled
      ? isReplacementsFetching
      : isRecommendationsFetching || isRecommendationsUninitialized,
    itemListName: isUnitDisabled
      ? ITEM_LIST_NAMES.SUBSTITUTES
      : ITEM_LIST_NAMES.RECOMMENDATION_FBT,
    itemListId: isUnitDisabled
      ? ITEM_LIST_IDS.SUBSTITUTES
      : ITEM_LIST_IDS.RECOMMENDATION_FBT,
    carouselTitle: formatMessage(
      isUnitDisabled
        ? messages.replacements
        : messages.frequentlyBoughtTogether,
    ),
  }))

  return {
    carouselRef,
    carouselTitle,
    carouselProducts,
    isCarouselLoading,
    itemListName,
    itemListId,
    itemModelId,
    attributionToken,
  }
}

export default usePDPCarouselData
