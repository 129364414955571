import React from 'react'
import { Flex } from '@rebass/grid'
import { SwitchTransition, Transition } from 'react-transition-group'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import theme from 'theme'
import { isElasticStockExceeding } from 'utils'
import { useOfMaxWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import { formatAmount } from 'components/Product/utils'
import { deliveryDatesByProductIdsSelector } from 'containers/Cart/selectors'
import { areStockNonStockDeliveryDatesEqual } from 'components/Product/Availability/utils'
import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'

import { BrandTxt } from '../styledComponents'
import { duration, defaultStyle, transitionStyles } from '../fadeInConsts'
import messages from '../messages'

import { StockNotif, ProductName } from './styles'

const BrandAndName = ({
  product: { id, name, brand, active, closestDeliveryTime },
  product,
  changingDate,
  productPath,
  deliveryCalendarRendered,
  unitOfMeasureObj,
  onTrackProductClick,
}) => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const productDeliveryOptions = useSelector(
    deliveryDatesByProductIdsSelector([id]),
  )

  const hasElasticNotif =
    isElasticStockExceeding({ product, unitOfMeasureObj }) &&
    !areStockNonStockDeliveryDatesEqual(
      productDeliveryOptions,
      closestDeliveryTime,
    )

  return (
    <SwitchTransition>
      <Transition key={!isMobile && changingDate} timeout={duration}>
        {transition => (
          <Flex style={{ ...defaultStyle, ...transitionStyles[transition] }}>
            {!isMobile && changingDate ? (
              deliveryCalendarRendered
            ) : (
              <Flex height="100%" alignItems="center">
                <Flex
                  flexDirection="column"
                  px={isMobile ? theme.spacing.xs : 0}
                >
                  {brand && (
                    <BrandTxt
                      data-test-id={`product_brand_${id}`}
                      {...isMobile && { style: { marginBottom: '4px' } }}
                    >
                      {brand}
                    </BrandTxt>
                  )}

                  <ProductName
                    {...{ active, productPath, name }}
                    productId={id}
                    onNameClick={onTrackProductClick}
                    eventParams={{
                      itemListId: ITEM_LIST_IDS.CART,
                      itemListName: ITEM_LIST_NAMES.CART,
                    }}
                  />

                  {hasElasticNotif && (
                    <StockNotif bold color={theme.colors.info}>
                      <FormattedMessage
                        {...messages.elasticStockAvailability}
                        values={{
                          amount: formatAmount(
                            unitOfMeasureObj,
                            unitOfMeasureObj.stock,
                          ),
                        }}
                      />
                    </StockNotif>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </Transition>
    </SwitchTransition>
  )
}

export default BrandAndName
