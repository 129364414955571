import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { identity } from 'lodash/fp'

import {
  formatUnitAmount,
  makeChangeNotification,
} from 'components/Product/utils'
import cartActions from 'containers/Cart/actions'

import messages from 'components/ProductCartControls/messages'

export const useCartQuantityHandling = ({
  product,
  unitData,
  attributionToken,
  beforeAll = identity,
  afterAll = identity,
  resetQuantityCallback = identity,
  onAffectedElasticStock = identity,
  onChangeAmountToStock = identity,
  onLimitedStock = identity,
}) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const dispatch = useDispatch()
  // TODO: check if possible to replace (generic msg even?)
  const addRemoveCallback = ({ justAddedToCart, removedFromCart }) =>
    dispatch(makeChangeNotification({ justAddedToCart, removedFromCart, intl }))

  // TODO: check if possible to simplify
  return useCallback(
    debounce(
      (quantity, { suppressElasticStockCheck, onAddSuccessCallback } = {}) => {
        beforeAll()
        if (quantity === 0) {
          dispatch(
            cartActions.deleteDelta({
              product,
              // TODO: change to use product instead of this
              productId: product.id,
              unitOfMeasure: unitData.unitOfMeasure,
              successCallback: () => {
                addRemoveCallback({
                  removedFromCart: true,
                })
                afterAll()
              },
              errorCallback: () => {
                resetQuantityCallback()
                afterAll()
              },
            }),
          )
        } else {
          // TODO: move handling of this into saga
          const justAddedToCart = unitData.inCartQuantity === 0

          dispatch(
            cartActions.createDelta({
              product,
              quantity,
              justAddedToCart,
              unit: unitData.unitOfMeasure,
              attributionToken,
              getMessage: stockAmount =>
                formatMessage(messages.maxAvailableAmount, {
                  amount: formatUnitAmount(unitData, stockAmount),
                }),
              callback: ({ affectedElasticStock }) => {
                if (affectedElasticStock) {
                  onAffectedElasticStock(affectedElasticStock)
                } else {
                  onAddSuccessCallback && onAddSuccessCallback()
                }

                addRemoveCallback({
                  justAddedToCart,
                })
                afterAll()
              },
              resetAmountCallback: () => {
                resetQuantityCallback()
                afterAll()
              },
              openStockLimitedTooltip: stock => {
                onLimitedStock(stock)
                afterAll()
              },
              changeAmountToStockCallback: stock => {
                onChangeAmountToStock(stock)
                afterAll()
              },

              // no floating price confirm on decrease
              shiftOnDecreaseToClosestDate: true,
              suppressElasticStockCheck,
            }),
          )
        }
      },
      350,
    ),
    [unitData],
  )
}
