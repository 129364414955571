import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { getScrollbarWidth } from 'utils'

import { BoldInfo, PSecondary } from 'components/Text'
import { FloatingPriceInfoBox } from 'components/FloatingPrice'
import { APP_BREAKPOINTS } from 'consts'

export const OuterWrapper = styled(Flex)`
  width: 100vw;
  margin: 0 calc(50% - 50vw + ${getScrollbarWidth});
  padding: 0 calc(50vw - 50% - ${getScrollbarWidth});
  background-color: ${theme.colors.bgGray};
`

export const Wrapper = styled(Flex)`
  flex-direction: column;
  width: 1024px;
  max-width: 100vw;
  margin: 0 auto;
  padding-top: 48px;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    padding: ${theme.spacing.xs};
    margin-bottom: ${theme.spacing.md};
  }
`

export const Section = styled(Flex)`
  flex-direction: column;
  padding: 48px 64px;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.sm};
    margin-top: ${theme.spacing.xs};
  }
`

// prettier-ignore
export const HalfBox = styled(Flex)`
  flex-direction: column;
  width: 50%;

  ${p => p.withBorder && `
    padding-right: ${theme.spacing.md};
    border-right: 1px solid ${theme.colors.fadedGray}
  `}
  ${p => p.withPaddingLeft && `
    padding-left: ${theme.spacing.md};
  `};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: 100%;
    padding: 0;
  }
`

export const Separator = styled.div`
  height: 1px;
  background-color: ${theme.colors.fadedGray};
  margin-top: 32px;
  margin-bottom: 48px;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    margin: ${theme.spacing.base} 0;
  }
`

export const SecondaryHeader = styled(PSecondary)`
  margin-top: ${theme.spacing.base};
  width: 60%;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    margin-top: ${theme.spacing.sm};
    width: 100%;
  }
`

export const BoldHeader = styled(BoldInfo)`
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    font-size: 18px;
  }
`

export const SummaryFloatingPriceInfo = styled(FloatingPriceInfoBox)`
  margin-top: ${theme.spacing.md};
  ${p =>
    p.$isDesktop &&
    `
    width: 50%;
    place-self: flex-end;
    padding-left: ${theme.spacing.md};
  `};
`
