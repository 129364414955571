import React, { useMemo } from 'react'

import {
  getBadgeProductLabels,
  isProductWithBadgeFeatures,
  excludeBidmarketFeature,
} from 'components/Product/Badge/utils'
import { BadgeWrapper } from 'components/Product/PromotionBadge/styles'
import { BADGE_STATUS } from '../Badge/consts'
import { Badge } from '../Badge'

const PromotionBadge = ({
  product,
  direction,
  showPromoName = false,
  productFeaturesLimit,
}) => {
  const { promotion, features } = product
  const productHasOfferFeatures = isProductWithBadgeFeatures(product)

  const elements = useMemo(
    () => {
      let result = []
      const discount = promotion?.reward?.discountPercentage
      if (discount) {
        result = [`-${discount}%`]
      }
      if (productHasOfferFeatures) {
        const filteredFeatures = excludeBidmarketFeature(features)
        const productLabels = getBadgeProductLabels(filteredFeatures).slice(
          0,
          productFeaturesLimit,
        )
        result = [...result, ...productLabels]
      }
      if (promotion && showPromoName) {
        result = [...result, promotion.title]
      }
      return result
    },
    [promotion, features],
  )

  if (elements.length) {
    return (
      <BadgeWrapper>
        <Badge
          status={BADGE_STATUS.PROMO}
          {...{ direction, elements }}
          data-test-id="promo-badge"
        />
      </BadgeWrapper>
    )
  }

  return null
}

export default PromotionBadge
export { BADGE_DIRECTION } from '../Badge'
