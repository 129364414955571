import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { omit } from 'lodash/fp'

import { SELECT_TYPE, MOBILE_FILTERS_QUERY } from 'components/Filters/consts'
import TextTooltip from 'components/Tooltip/TextTooltip'

import { StyledButtonFilter } from '../styles'

const ButtonFilter = ({
  title,
  type,
  onChange,
  onApply,
  defaultFilter,
  activeFilter,
  selectedFilters,
  tooltipText,
  isAlwaysActive,
  testId,
}) => {
  const isActive = activeFilter && selectedFilters[activeFilter.value]
  const isMobile = useMediaQuery({ query: MOBILE_FILTERS_QUERY })
  const filter = isActive ? defaultFilter : activeFilter

  const handleFilterChange = item => {
    onChange({ [item.value]: true }, () => onApply())
  }

  // prettier-ignore
  const handleGroupFilterChange = checked => {
    checked
      ? onChange({ ...selectedFilters, [activeFilter.value]: true }, () => onApply())
      : onChange(omit([activeFilter.value], selectedFilters), () => onApply())
  }

  const handleClick = () => {
    type === SELECT_TYPE.BUTTON
      ? handleFilterChange(filter)
      : handleGroupFilterChange(!isActive)
  }

  const btnFilter = (
    <StyledButtonFilter
      data-test-id={testId}
      onClick={handleClick}
      isActive={isActive}
      isDisabled={isAlwaysActive ? false : !activeFilter}
    >
      {title}
    </StyledButtonFilter>
  )

  return tooltipText && !isMobile ? (
    <TextTooltip content={tooltipText}>{btnFilter}</TextTooltip>
  ) : (
    btnFilter
  )
}

export default ButtonFilter
