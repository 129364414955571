import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { Flex } from '@rebass/grid'
import { sumBy, groupBy } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'

import theme from 'theme'
import { formatPrice } from 'utils'
import { ROUTES, APP_BREAKPOINTS } from 'consts'

import { Link, TinyText } from 'components/Text'
import { parseAmount, getProductGenericInfo } from 'components/Product/utils'

import ProductPreview from '../../ProductsPreview/ProductPreview'
import { VerticalLine } from '../../styles'

import { PriceWrapper, ProductContainer, ImgWrapper } from './styles'

const OrderProduct = ({ product: { product, units }, isSummary }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px)`,
  })
  const productPath = generatePath(ROUTES.PRODUCT_DETAILS, { id: product.id })

  const unitsProcessed = useMemo(
    () => {
      const processed = {}
      const unitsByUnitOfMeasure = groupBy('unitOfMeasure', units)

      return units.reduce((acc, unit) => {
        if (processed[unit.unitOfMeasure]) return acc
        processed[unit.unitOfMeasure] = true

        const currUnits = unitsByUnitOfMeasure[unit.unitOfMeasure]
        const totalNet = sumBy('totalPriceNet', currUnits)
        const quantity = sumBy('quantity', currUnits)
        const { priceShown } = getProductGenericInfo({
          product,
          unitOfMeasure: unit.unitOfMeasure,
          units: currUnits,
        })

        return [
          ...acc,
          {
            totalNet,
            quantity,
            priceShown,
            unitOfMeasureDescription: unit.unitOfMeasureDescription,
          },
        ]
      }, [])
    },
    [units, product],
  )

  return unitsProcessed.map((unit, index) => (
    <ProductContainer key={unit.unitOfMeasureDescription} isFirst={index === 0}>
      {index === 0 ? (
        <Flex alignItems="center" pr={theme.spacing.sm} flex={1}>
          <ImgWrapper {...{ isSummary }}>
            <ProductPreview {...{ product }} />
          </ImgWrapper>

          <Flex {...isMobile && { flexDirection: 'column' }}>
            <TinyText bold>{product.brand}</TinyText>
            {!isMobile && <>&nbsp;</>}
            {product?.inUserCatalog ? (
              <Link to={productPath} target="_blank">
                <TinyText>{product.name}</TinyText>
              </Link>
            ) : (
              <TinyText>{product.name}</TinyText>
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex flex={1} />
      )}

      {!isMobile && (
        <Flex>
          <VerticalLine />
        </Flex>
      )}

      <PriceWrapper>
        <TinyText>
          {parseAmount(unit.quantity)} {unit.unitOfMeasureDescription}
        </TinyText>

        <TinyText bold>
          {unit.priceShown && formatPrice(unit.totalNet)}
        </TinyText>
      </PriceWrapper>
    </ProductContainer>
  ))
}

export default OrderProduct
