import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { AlternateBtn } from 'components/Button'
import { BasicButton } from 'components/Button/styles'
import { SecondaryText, SecondaryTextPrimary } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'

import { AmountContainer } from 'components/Product/Amount/styles'
import ButtonsContainer from '../../ButtonsContainer'

export const ButtonsContainerStyled = styled(ButtonsContainer)`
  width: 144px;
  color: ${theme.colors.primary};

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: auto;
  }

  ${BasicButton} {
    border-top: none;
    border-bottom: none;
    width: 30px;
    background-color: transparent;

    svg {
      width: 18px;
    }

    &:nth-child(2) {
      border: none;
      margin-right: ${theme.spacing.sm};
    }

    &:hover {
      border-top: none;
      border-bottom: none;
      border-left: 1px solid ${theme.colors.gray2};
      border-right: 1px solid ${theme.colors.gray2};

      &:nth-child(2) {
        border: none;
        margin-right: ${theme.spacing.sm};
      }

      @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
        border: none;
      }
    }

    @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
      border: none;
      height: 24px;
    }
  }

  ${AmountContainer} {
    background-color: transparent;
    border: none;
    font-size: 13px !important;
    font-weight: 600;
    width: 100%;

    @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
      border-left: 1px solid ${theme.colors.gray2};
      border-right: 1px solid ${theme.colors.gray2};
      border-radius: 0;
      width: 64px;
      height: 24px;
    }
  }
`

export const TrashBtn = styled(AlternateBtn)`
  width: auto;
  border: none;
  margin-right: ${theme.spacing.sm};
  background-color: transparent;

  &:hover {
    border: none;
    background-color: transparent;
  }

  svg {
    width: 18px;
  }

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    height: ${theme.spacing.base};
    margin-left: ${theme.spacing.xs};
    padding-left: 0;
  }
`

export const MultiplePackagingsTxt = styled(SecondaryTextPrimary).attrs({
  strongBold: true,
})`
  margin: 0 auto;
`

export const AmountFromOrdered = styled(SecondaryText).attrs({
  bold: true,
})`
  margin: 0 auto;
  color: ${theme.colors.battleshipGrey};
`

export const TextWrapper = styled(Flex)`
  height: 100%;
  align-items: center;
  position: relative;

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    height: auto;
    width: 100%;
    justify-content: space-between;
  }
`

export const MultiplePackagingsWrapper = styled(Flex)`
  height: 100%;
  width: 144px;
  align-items: center;
  cursor: pointer;
`
