import React from 'react'

import theme from 'theme'

const Card = ({ color = theme.colors.gray5 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="5"
      width="20"
      height="14"
      rx="3"
      stroke={color}
      strokeWidth="2"
    />
    <path d="M15 15H19" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <rect x="2" y="9" width="20" height="3" fill={color} />
  </svg>
)

export default Card
