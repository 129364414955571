import React from 'react'
import { generatePath } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { ROUTES } from 'consts'
import { BreadCrumbList, BreadCrumb } from 'components/BreadCrumbs'
import messages from './messages'

const MainPage = ({ breadcrumbs = [] }) => {
  const { formatMessage } = useIntl()

  return (
    <BreadCrumbList>
      <BreadCrumb to={generatePath(ROUTES.DASHBOARD)}>
        {formatMessage(messages.home)}
      </BreadCrumb>
      {breadcrumbs.map(({ path, title }) => (
        <BreadCrumb key={title} to={path}>
          {title}
        </BreadCrumb>
      ))}
    </BreadCrumbList>
  )
}

export default MainPage
