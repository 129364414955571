import { makeMessages, makeTranslation } from 'utils'

const messages = {
  title: 'title',
  secondaryTitle: 'secondary_title',
  ctaBtn: 'cta_btn',
  backBtn: 'back_btn',
  email: {
    id: 'validators.email',
  },
  password: {
    id: 'login.passwordLabel',
  },
  login: 'login',
  sentTitle: 'sent_title',
  sentSubtitle: 'sent_subtitle',
  sentCtaBtn: 'sent_cta_btn',
  confirmTitle: 'confirm_title',
  confirmSubtitle: 'confirm_subtitle',
  confirmCtaBtn: 'confirm_cta_btn',
  confirmDoneTitle: 'confirm_done_title',
  confirmDoneSubtitle: 'confirm_done_subtitle',
  confirmDoneCtaBtn: 'confirm_done_cta_btn',
  linkExpiredTitle: {
    id: 'setPassword.expired',
  },
  linkExpiredMessage: {
    id: 'setPassword.expiredDescription',
  },
  linkInvalidTitle: {
    id: 'setPassword.invalid',
  },
  linkInvalidMessage: {
    id: 'setPassword.invalidDescription',
  },
  linkAlreadyUsedTitle: {
    id: 'setPassword.alreadyUsed',
  },
  linkAlreadyUsedMessage: {
    id: 'setPassword.alreadyUsedDescription',
  },
  newPasswordHint: { id: 'profile_change_password.new_password_hint' },
  toLogin: { id: 'setPassword.toLogin' },
  '10212133118': { id: 'Error.10212133118' },
  resendResetLink: {
    id: 'setPassword.sendNewLink',
  },
  supportMail: {
    id: 'support.mail',
  },
  supportPhone: {
    id: 'support.phone',
  },
  supportTitle: {
    id: 'support.needHelp',
  },
  resend: 'resend',
  resendDescription: 'resendDescription',
}

export default makeMessages(messages, makeTranslation('reset_password'))
