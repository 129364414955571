import React from 'react'

import theme from 'theme'

const TruckClosed = ({ color = theme.colors.gray5 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    data-test-id="closed-truck-icon"
  >
    <path
      d="M18 14V5C18 4.44772 17.5523 4 17 4H7C6.44772 4 6 4.44771 6 5V14C6 14.5523 6.44772 15 7 15H17C17.5523 15 18 14.5523 18 14Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16.5857 21H17.7C17.8657 21 18 20.8657 18 20.7V18.3C18 18.1343 17.8657 18 17.7 18H6.3C6.13431 18 6 18.1343 6 18.3V20.7C6 20.8657 6.13431 21 6.3 21H7.41429C7.57997 21 7.71429 20.8657 7.71429 20.7V18.3C7.71429 18.1343 7.8486 18 8.01429 18H15.9857C16.1514 18 16.2857 18.1343 16.2857 18.3V20.7C16.2857 20.8657 16.42 21 16.5857 21Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect x="11" y="4" width="2" height="11" rx="0.6" fill={color} />
  </svg>
)

export default TruckClosed
