import React from 'react'
import { useIntl } from 'react-intl'

import useDeadlineOrderDates from 'hooks/useDeadlineOrderDates'

import messages from '../messages'
import { AccountDetailsSecondaryText } from '../styles'

const DeadlineOrderDate = ({
  closestDeliveryDate: { deadlineOrderDate, deliveryDate } = {},
}) => {
  const { formatMessage } = useIntl()

  const [deadlineDateFormatted, deliveryDateFormatted] = useDeadlineOrderDates({
    deadlineOrderDate,
    deliveryDate,
  })

  return (
    <AccountDetailsSecondaryText data-test-id="deadline-order-time">
      {formatMessage(messages.deadlineOrderDate, {
        deadlineDate: deadlineDateFormatted,
        deliveryDate: deliveryDateFormatted,
      })}
    </AccountDetailsSecondaryText>
  )
}

export default DeadlineOrderDate
