import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'

export const CookieSettingsButton = styled.button`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: ${theme.spacing.sm};
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${theme.colors.white};
  border: 0;
  color: ${theme.colors.primary};
  padding: 0 ${theme.spacing.sm};
  margin-top: ${theme.spacing.sm};

  &:hover {
    background-color: ${theme.colors.lightGrey};
  }
`

export const CookieBtnTitle = styled(BaseTextV2)`
  font-weight: 500;
  font-size: 14px;
`
