import { createSelector } from 'reselect'
import { prop } from 'lodash/fp'
import dayjs from 'dayjs'

import { makeImmutableStateSelector } from 'utils/redux-utils'
import {
  INVOICES_REDUCER_NAME,
  INVOICES_FILTERS_REDUCER_NAME,
} from '../../consts'

export const selectInvoicsFilters = state =>
  makeImmutableStateSelector(state, [
    INVOICES_REDUCER_NAME,
    INVOICES_FILTERS_REDUCER_NAME,
  ])

export const invoicesTypeFiltersSelector = createSelector(
  selectInvoicsFilters,
  prop('byType'),
)

export const invoicesDateRangeFiltersSelector = createSelector(
  selectInvoicsFilters,
  ({ dateRange }) => {
    const startDate = prop('startDate', dateRange)
    const endDate = prop('endDate', dateRange)

    return {
      startDate: startDate ? dayjs(startDate) : null,
      endDate: endDate ? dayjs(endDate) : null,
    }
  },
)
