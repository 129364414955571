import React from 'react'
import { Flex } from '@rebass/grid'

import ProductImage, {
  PRODUCT_IMAGE_SIZES,
} from 'components/Product/ProductImage'

const ProductPreview = ({ product }) => (
  <Flex
    key={product.id}
    width={24}
    height={24}
    style={{ mixBlendMode: 'multiply' }}
  >
    <ProductImage {...{ product }} size={PRODUCT_IMAGE_SIZES.S} />
  </Flex>
)

export default ProductPreview
