import { makeMessages, makeTranslation } from 'utils'

const messages = {
  legalText: 'legalText',
  legalTextTermsLink: 'legalTextTermsLink',
  returnContact: 'returnContact',
  description: 'description',
  submit: 'submit',
  emailPlaceholder: 'emailPlaceholder',
  additionalEmailPlaceholder: 'additionalEmailPlaceholder',
  phonePlaceholder: 'phonePlaceholder',
  topicPlaceholder: 'topicPlaceholder',
  descPlaceholder: 'descPlaceholder',
  addFiles: 'addFiles',
  orderComplaint: 'topics.orderComplaint',
  deliveryNotes: 'topics.deliveryNotes',
  suggestions: 'topics.suggestions',
  productsOffer: 'topics.productsOffer',
  other: 'topics.other',
  filesValidation: 'filesValidation',
  emptyField: { id: 'validators.blank' },
  minLength: { id: 'validators.minLength' },
  maxLength: { id: 'validators.maxLength' },
  invalidEmail: { id: 'signUp.error.invalidEmail' },
  invalidPhoneNo: { id: 'signUp.phoneInvalid' },
  contactFormTitle: 'title',
  contactFormDesc: 'desc',
  sendClaimSuccess: 'sendClaimSuccess',
  sendClaimError: 'sendClaimError',
  fileUploadError: 'fileUploadError',
  phoneLabel: 'phoneLabel',
  emailLabel: 'emailLabel',
  additionalEmailLabel: 'additionalEmailLabel',
}

export default makeMessages(messages, makeTranslation('contactForm'))
