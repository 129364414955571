import React from 'react'
import PropTypes from 'prop-types'
import { CheckboxWrapper, CheckboxBasic, CheckboxLabel } from './styles'

const Checkbox = ({
  name,
  Wrapper = CheckboxWrapper,
  className,
  disabled,
  ...props
}) => (
  <Wrapper htmlFor={name} className={className} disabled={disabled}>
    <CheckboxBasic
      name={name}
      id={name}
      aria-label={name}
      {...props}
      type="checkbox"
      role="checkbox"
    />
    <CheckboxLabel className={className} />
  </Wrapper>
)

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
}

export default Checkbox
