import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import messages from './messages'
import {
  Line,
  BigHeader,
  Description,
  PhoneLink,
  EmailLink,
  StyledWrapper,
  StyledContainer,
} from './styledComponents'

const PHONE = '+48 91 460 20 20'

const Notification = ({
  header,
  children,
  hideFooter,
  height,
  width,
  ...rest
}) => {
  const { formatMessage } = useIntl()
  return (
    <StyledWrapper>
      <StyledContainer
        containerHeight={height}
        containerWidth={width}
        {...rest}
      >
        <BigHeader strongBold>
          <FormattedMessage {...header}>{txt => txt}</FormattedMessage>
        </BigHeader>
        {children}
        {!hideFooter && (
          <React.Fragment>
            <Line />
            <Description>
              <FormattedMessage {...messages.needHelp} />
            </Description>
            <PhoneLink>{PHONE}</PhoneLink>
            <EmailLink href={`mailto:${formatMessage(messages.email)}`}>
              {formatMessage(messages.email)}
            </EmailLink>
          </React.Fragment>
        )}
      </StyledContainer>
    </StyledWrapper>
  )
}

export default Notification
