import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { ROUTES } from 'consts'
import { formatPrice } from 'utils'
import { trackSimpleEvent, NAV_BAR_EVENTS } from 'services/analytics'

import { CartV2 } from 'components/Icons'
import { cartTotalSelector } from 'containers/Cart/selectors'
import { closestTimerDeliveryDateSelector } from 'containers/Delivery/selectors'
import DeliveryTimer from './DeliveryTimer'

import { Cart, CartBtn, CartBtnContainer } from './styles'

const CartButton = ({ cartBtnStyles }) => {
  const cartTotal = useSelector(cartTotalSelector)
  const closestDeliveryDate = useSelector(closestTimerDeliveryDateSelector)
  const deadlineOrderDate = closestDeliveryDate?.deadlineOrderDate

  const { isAlert } = cartBtnStyles
  const hasTimer = dayjs().isBetween(
    dayjs(deadlineOrderDate).subtract(2, 'hour'),
    deadlineOrderDate,
  )

  return (
    <CartBtnContainer>
      <CartBtn
        to={ROUTES.CART}
        onClick={() => trackSimpleEvent(NAV_BAR_EVENTS.CART_CLICKED)}
      >
        <Cart
          icon={<CartV2 />}
          data-test-id="cart_button"
          cartBtnStyles={cartBtnStyles}
          $hasTimer={hasTimer}
        >
          {isAlert ? null : formatPrice(cartTotal.totalNet)}
        </Cart>
      </CartBtn>
      <DeliveryTimer
        closestDeliveryDate={closestDeliveryDate}
        cartBtnStyles={cartBtnStyles}
        hasTimer={hasTimer}
      />
    </CartBtnContainer>
  )
}

export default CartButton
