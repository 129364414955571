import React from 'react'

import { ArrowLeftV2 } from 'components/Icons'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import CarouselTracker from 'components/Carousel/CarouselTracker'

import {
  CarouselButtonRight,
  CarouselButtonLeft,
  CarouselButtonsWrapper,
} from './styles'

const CarouselControls = ({ numberOfPages, selectedPageIndex, emblaApi }) => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)
  const autoplay = emblaApi?.plugins()?.autoplay

  const handleNextClick = () => {
    emblaApi.scrollNext()
    autoplay.stop()
  }

  const handlePrevClick = () => {
    emblaApi.scrollPrev()
    autoplay.stop()
  }

  if (isTablet && numberOfPages > 1) {
    return (
      <CarouselButtonsWrapper>
        <CarouselButtonLeft
          onClick={handlePrevClick}
          data-test-id="prev-campaign-button"
        >
          <ArrowLeftV2 />
        </CarouselButtonLeft>
        <CarouselButtonRight
          onClick={handleNextClick}
          data-test-id="next-campaign-button"
        >
          <ArrowLeftV2 />
        </CarouselButtonRight>
      </CarouselButtonsWrapper>
    )
  }

  return (
    <CarouselTracker
      slideCount={numberOfPages}
      selectedSlide={selectedPageIndex}
    />
  )
}

export default CarouselControls
