import styled from 'styled-components'

import theme from 'theme'
import { PopoverClose } from 'components/Popup'

export const Container = styled(PopoverClose)`
  display: flex;
  padding ${theme.spacing.xs} ${theme.spacing.sm};
  cursor: pointer;
  border-radius: 8px;

  ${p =>
    p.bgColor &&
    `
    background-color: ${p.bgColor};
  `}

  &:hover {
    background-color: ${theme.colors.lightGrey};
  }
`

export const DescContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

export const SupplementLabel = styled.div`
  margin-left: 10px;
`

export const IconContainer = styled.div`
  margin-right: ${theme.spacing.sm};
`
