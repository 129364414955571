import { call, takeLatest, put } from 'redux-saga/effects'
import { crudSwitch, genericGetDataEnhanced } from 'containers/App/sagas'
import { getResponseError } from 'utils'
import { trackAddedTemplate } from 'services/analytics'
import { notifyAction } from 'containers/App/actions'
import { ALERT_TYPE } from 'components/Alert'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { templatesActions, getTemplateActions } from '../actions'
import {
  createTemplate,
  deleteTemplate,
  getTemplates,
  getTemplate,
  renameTemplate,
} from '../api'

export function* getTemplatesFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: templatesActions,
      request: getTemplates,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* getTemplateFlow({ data = {} }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getTemplateActions,
      request: getTemplate,
      params: data,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* createTemplateFlow({
  data = {},
  additionalData: { onSuccess, onFailure } = {},
}) {
  try {
    const response = yield call(genericGetDataEnhanced, {
      actions: templatesActions,
      request: createTemplate,
      params: data,
      method: 'create',
    })

    if (response?.id) {
      yield call(trackAddedTemplate, response.id)
    } else {
      return
    }

    yield call(onSuccess, response)
  } catch (error) {
    yield call(onFailure, getResponseError(error))
  }
}

export function* removeTemplateFlow({
  data = {},
  additionalData: { successMessage, successCallback },
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: templatesActions,
      request: deleteTemplate,
      params: data,
      method: 'delete',
    })

    if (successCallback) yield call(successCallback)

    yield put(notifyAction({ message: successMessage }))
  } catch (error) {
    yield put(
      notifyAction({
        message: getResponseError(error),
        type: ALERT_TYPE.ERROR,
      }),
    )
  }
}

export function* renameTemplateFlow({
  data = {},
  additionalData: { onSuccess, onFailure } = {},
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: templatesActions,
      request: renameTemplate,
      params: data,
      method: 'update',
    })
    yield call(onSuccess)
  } catch (error) {
    yield call(onFailure, getResponseError(error))
  }
}

export default [
  takeLatest(templatesActions.DELTA, crudSwitch, {
    getSaga: getTemplatesFlow,
    createSaga: createTemplateFlow,
    deleteSaga: removeTemplateFlow,
    updateSaga: renameTemplateFlow,
  }),
]
