import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'

export const DropdownWrapper = styled(Flex)`
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.lightGrey2};
  }
`

export const DropdownContainer = styled(Flex)`
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 8px 16px;
  :hover {
    background: ${theme.colors.lightGrey};
  }
`

export const DropdownTitleContainer = styled(Flex)`
  align-items: center;
`

export const DropdownDescription = styled(BaseTextV2).attrs({
  isSmall: {
    fontSize: true,
    lineHeight: true,
  },
})`
  margin-top: 16px;
  padding: 0 16px 8px 16px;
  color: ${theme.colors.lightGrey4};
`

export const DropdownDescriptionLink = styled(DropdownDescription).attrs({
  as: 'a',
})`
  text-decoration: underline;
  margin-top: 0;
  padding: 0;
`

export const ArrowContainer = styled(Flex)`
  margin-right: 16px;
`

export const DropdownSwitch = styled.label`
  position: relative;
  display: flex;
  margin-left: 16px;
  width: 48px;
  height: 24px;
  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

// prettier-ignore
export const SwitchSlider = styled.span`
  position: absolute;
  border-radius: 8px;
  cursor: ${p => (p.readonly ? 'default' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 24px;
  background-color: ${p => p.readonly
    ? theme.colors.greenLight5
    : theme.colors[p.isChecked ? 'secondary' : 'lightGrey7']};
  transition: 0.4s;
  :before {
    position: absolute;
    content: '';
    height: 16px;
    width: 24px;
    border-radius: 4px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    ${p => !p.readonly && `
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    `};
    ${p => p.isChecked && `
      transform: translateX(16px);
    `};
  }
`
