import styled from 'styled-components'

import { getColors } from 'components/helpers/theme-selectors'

export const ProductImg = styled.img`
  width: 100%;
  max-height: 100%;
  background: ${props => getColors(props).white};
  object-fit: contain;

  ${({ isImgGrayed }) =>
    isImgGrayed &&
    `
    filter: grayscale(100%);
    opacity: 0.6;
  `};
`
