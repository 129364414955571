import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'

export const PromoGroupWrapper = styled.div`
  cursor: pointer;
`

export const PromoText = styled(BaseTextV2)`
  color: ${theme.colors.secondary};
  ${props =>
    props.$isShowModal &&
    `
    border-radius: 8px;
    :hover {
      background: ${theme.colors.greenLight5};
    }
  `};
`
