import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { TinyText, TinyTextFaded } from 'components/Text'

// prettier-ignore
const Wrapper = styled(Flex)`
  width: 120px;
  flex-direction: column;

  ${p => p.inRow && `
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  `};
`

const InfoColumn = ({ title, value, inRow }) => (
  <Wrapper {...{ inRow }}>
    <TinyTextFaded>{title}:</TinyTextFaded>

    <TinyText>{value}</TinyText>
  </Wrapper>
)

export default InfoColumn
