import React, { useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import { useNavigate, generatePath } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { NAV_BAR_EVENTS } from 'services/analytics'
import { ArrowRightV2, X as XIcon } from 'components/Icons'
import { ROUTES } from 'consts'
import { cn } from 'utils'

import messages from '../messages'
import { CATEGORIES_MAP, DefaultCategoriesIcon } from '../Icons'
import { trackCategoriesDropdownEvent } from '../utils'
import { SubcategoriesList, SCROLL_POSITIONS } from './styles'

const SubcategoriesSection = ({ category, clearSelection }) => {
  const { formatMessage } = useIntl()
  const [scrollPosition, setScrollPosition] = useState(SCROLL_POSITIONS.TOP)
  const scrollRef = useRef()

  const isListBorderHidden =
    scrollPosition === SCROLL_POSITIONS.TOP ||
    scrollPosition === SCROLL_POSITIONS.NONE

  useEffect(() => {
    const scroll = scrollRef.current

    const handleScroll = throttle(e => {
      const { scrollTop, clientHeight, scrollHeight, scrollTopMax } = e.target
      const scrollMax = scrollTopMax || scrollHeight - clientHeight
      if (!scrollMax) {
        setScrollPosition(SCROLL_POSITIONS.NONE)
      } else if (scrollTop >= scrollMax - 20) {
        setScrollPosition(SCROLL_POSITIONS.BOTTOM)
      } else if (scrollTop === 0) {
        setScrollPosition(SCROLL_POSITIONS.TOP)
      } else if (scrollPosition !== SCROLL_POSITIONS.MID) {
        setScrollPosition(SCROLL_POSITIONS.MID)
      }
    }, 100)
    scroll.addEventListener('scroll', handleScroll)
    return () => {
      scroll.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(
    () => {
      const { clientHeight, scrollHeight, scrollTopMax } = scrollRef.current
      const scrollMax = scrollTopMax || scrollHeight - clientHeight
      if (!scrollMax) {
        setScrollPosition(SCROLL_POSITIONS.NONE)
      }
    },
    [category],
  )

  const navigate = useNavigate()
  const IconComponent = CATEGORIES_MAP[category.id] || DefaultCategoriesIcon

  const handleViewCategory = () => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.SEE_ALL_CATEGORY_CLICKED, {
      category: category.name,
    })

    navigate(
      generatePath(ROUTES.PRODUCTS, {
        category: category.id,
      }),
    )
  }

  const handleGroupClick = group => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.SUBCATEGORY_CLICKED, {
      category: category.name,
      subcategory: group.name,
    })

    navigate(
      generatePath(ROUTES.PRODUCTS, {
        category: category.id,
        group: group?.id,
      }),
    )
  }

  return (
    <div className="relative h-full">
      <div
        className={cn(
          'flex justify-between items-center bg-white pl-3 py-2 pr-2 border-solid border-b border-grey-300 absolute top-0 w-full z-10',
          isListBorderHidden && 'border-none',
        )}
      >
        <div className="flex gap-3">
          {IconComponent && <IconComponent />}
          <span className="text-[13px] leading-6">{category.name}</span>
        </div>
        <button
          type="button"
          className={cn('flex justify-center items-center h-6 w-6 group')}
          onClick={clearSelection}
          data-test-id="cancel-subcategories-selection-button"
        >
          <XIcon className="[&>path]:stroke-grey-500 [&>path]:group-hover:stroke-blue-900" />
        </button>
      </div>
      {/* TODO: replace styled-component with tailwind */}
      <SubcategoriesList
        scrollableNodeProps={{ ref: scrollRef }}
        $scrollPosition={scrollPosition}
        data-test-id="subcategories-list"
      >
        {category.groups.map(group => (
          <button
            type="button"
            className="flex justify-between py-2.5 px-3 items-center text-[13px] leading-5 text-grey-800 font-normal hover:text-blue-900"
            data-test-id={`subcategory-${group.id}`}
            key={group.id}
            onClick={() => handleGroupClick(group)}
          >
            <span>{group.name}</span>
            <span>{group.productCount}</span>
          </button>
        ))}
      </SubcategoriesList>
      <button
        type="button"
        className="flex justify-between absolute w-full bottom-0 py-2 pr-2 pl-4 ease-out duration-300 transition-colors text-white bg-green-600 hover:bg-green-500 [&_path]:stroke-white rounded-lg"
        data-test-id="view-category-button"
        onClick={() => handleViewCategory()}
      >
        <span className="text-[13px] leading-6">
          {formatMessage(messages.viewFull)}
        </span>
        <ArrowRightV2 />
      </button>
    </div>
  )
}

export default SubcategoriesSection
