import styled from 'styled-components'

import theme from 'theme'

import { H4Primary } from 'components/Text'

// prettier-ignore
export const List = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  ${p => p.isHeadArrowVisible && `
    margin-left: 26px;
  `};

  ${p => p.isTailArrowVisible && `
    margin-right: 26px;
  `};
`

export const DeliveryDatesListContainer = styled.div`
  @media (min-width: 1440px) {
    max-width: 1038px;
  }

  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  margin: ${theme.spacing.md};
`

export const ChevronIconContainer = styled.div`
  background-color: #fff;
  width: 24px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: ${theme.spacing.md};
`

// prettier-ignore
export const Chevron = styled.div`
  position: absolute;
  transition: transform .3s ease-in-out;

  ${p => p.isHead && `
    left: 0;
    transform: translateX(-26px);
  `}

  ${p => p.isTail && `
    right: 0;
    transform: translateX(26px);
  `}

  ${p => p.isVisible && `
    transform: translateX(0);
  `}
`

export const LineDashed = styled.div`
  border: 1px dashed ${theme.colors.primary};
  margin-top: ${theme.spacing.xs};
`

export const IconWrapper = styled.div`
  position: absolute;
  left: -56px;
  top: 132px;
`

export const Header = styled(H4Primary)`
  b {
    color: ${theme.colors.secondary};
  }
`
