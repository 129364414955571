import React from 'react'
import { useIntl } from 'react-intl'

import { PAY_SCENARIO } from 'containers/Orders/consts'

import { useGetSubtitleFromTradeLimit } from 'views/Dashboard/Invoices/utils'
import Tile from '../../Tile'
import messages from '../../messages'
import CartActions from '../../ActionBar/CartActions'
import { LinesGroup, Overpayments, CreditLimit } from '../../Lines'

const Pristine = ({ tradeLimit, invoicesSummary }) => {
  const { formatMessage } = useIntl()
  const { scenario } = tradeLimit
  const hasCartIssue = scenario !== PAY_SCENARIO.GREEN
  const { subtitleColor, subtitle } = useGetSubtitleFromTradeLimit({
    scenario,
    defaultMessage: formatMessage(messages.subtitlePristine),
  })

  return (
    <Tile
      title={formatMessage(
        hasCartIssue ? messages.titlePristineCredit : messages.titlePristine,
      )}
      subtitle={subtitle}
      subtitleColor={subtitleColor}
      footer={hasCartIssue && <CartActions scenario={scenario} />}
    >
      <LinesGroup isFirst isLast>
        <CreditLimit tradeLimit={tradeLimit} />
        <Overpayments invoicesSummary={invoicesSummary} />
      </LinesGroup>
    </Tile>
  )
}

export default Pristine
