import React from 'react'

import { parseProductTitle } from 'utils'

import { Brand, Title } from 'components/Product/styles'
import { TitleAndBrand } from './styles'

const ProductTitle = ({ brand, title }) => (
  <TitleAndBrand>
    <Brand>{brand}</Brand>
    <Title>{parseProductTitle(title)}</Title>
  </TitleAndBrand>
)

export default ProductTitle
