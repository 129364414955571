import { takeLatest, call, put } from 'redux-saga/effects'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { genericGetDataEnhanced } from 'containers/App/sagas'

import { getPromos } from './api'
import { getPromosActions } from './actions'

function* getPromosFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getPromosActions,
      request: getPromos,
    })
  } catch (error) {
    console.log('Error getting promotion campaigns', error)
    if (error.status === 500) {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }
}

export default [takeLatest(getPromosActions.DELTA, getPromosFlow)]
