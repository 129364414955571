import React from 'react'
import theme from 'theme'

const MailV2 = ({ color = theme.colors.primary }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12V5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 9L2.58579 11.4142C2.21071 11.7893 2 12.298 2 12.8284V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V12.8284C22 12.298 21.7893 11.7893 21.4142 11.4142L19 9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 12L12 16L21 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 7L15 7" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M9 10H12" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export default MailV2
