import { makeMessages, makeTranslation } from 'utils'

const messages = {
  required: 'blank',
  passShort: { id: 'password.tooShort' },
  maxLength: 'maxLength',
  minLength: 'minLength',
  emailFormat: 'email',
}

export default makeMessages(messages, makeTranslation('validators'))
