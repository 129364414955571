import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getColors } from 'components/helpers/theme-selectors'

export const RowContainer = styled(Flex)`
  ${p => p.isFixed && `box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.15);`};
`

export const InnerRow = styled(Flex)`
  background-color: ${p => getColors(p).white};
  flex: 1;

  > * {
    padding: 0 24px;
  }
`
