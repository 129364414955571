import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getColors, getSpacing } from 'components/helpers/theme-selectors'
import { P } from 'components/Text'
import { NormalIcon } from 'components/Icon'

// prettier-ignore
export const InfoIcon = styled(NormalIcon)`
  margin-right: ${props => getSpacing(props).sm};
  margin-left: 0;
  svg {
    stroke: ${props => getColors(props).white};

    ${props => props.isWarning && `
      stroke: ${getColors(props).black};
    `};
  }
`

// prettier-ignore
export const Message = styled(P)`
  color: ${props => getColors(props).white};

  ${props => props.isWarning && `
    color: ${getColors(props).black};
  `};
`

// prettier-ignore
export const Container = styled(Flex)`
  min-height: 48px;
  width: 100%;
  margin: 0 auto ${props => getSpacing(props).sm} auto;
  padding: 14px 18px;
  max-width: 958px;
  align-items: center;
  background-color: ${props => getColors(props).secondary};

  ${props => props.isWarning && `
    background-color: ${getColors(props).warning};
  `};
`
