import { bindActionCreators as reduxBindActionCreators } from 'redux'
import _includes from 'lodash/includes'
import _values from 'lodash/values'

export const createBaseActionCreator = type => () => ({ type })

export const createActionCreator = type => (data, additionalData) => ({
  type,
  data,
  additionalData,
})

export const createDeltaAction = base => `DELTA/${base}`

export const bindActionCreators = actionsObj => dispatch =>
  reduxBindActionCreators(actionsObj, dispatch)

/*
 * Actions group creator
 */

export const CRUD_ACTIONS_MAPPER = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

const CRUD_ACTIONS = _values(CRUD_ACTIONS_MAPPER)

export const createActionsGroup = base => {
  const DELTA = createDeltaAction(base)
  const delta = createActionCreator(DELTA)
  const baseActions = [
    ...CRUD_ACTIONS,
    'REQUEST',
    'SUCCESS',
    'FAILURE',
    'CANCEL',
    'CLEAR',
  ].reduce(
    (result, actionName) => {
      const newResult = result
      newResult[actionName] = `${base}/${actionName}`
      newResult[actionName.toLowerCase()] = createActionCreator(
        newResult[actionName],
      )
      newResult.actions.push(newResult[actionName])

      if (_includes(CRUD_ACTIONS, actionName)) {
        newResult[`${actionName.toLowerCase()}Delta`] = (
          data,
          additionalData = {},
        ) => delta(data, { ...additionalData, method: actionName })
      }

      return result
    },
    { actions: [] },
  )

  baseActions.DELTA = DELTA
  baseActions.delta = delta

  return baseActions
}
