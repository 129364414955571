import styled from 'styled-components'

import theme from 'theme'

import { GreyWrapper } from 'components/Button/styles'
import { BaseTextV2, TinyTextV2Secondary } from 'components/Text'
import { DefaultPopoverContainer } from 'components/Popup/styles'
import { TEMPLATE_ROW_MOBILE_MAX_WIDTH } from 'components/Product/consts'
import {
  QuantityBtnWrapper,
  QuantityControlsWrapper,
  UnitQuantityInputWrapper,
} from 'components/ProductCartControls/QuantityControls/styles'

export const QuantityBoxContainer = styled.div`
  display: flex;

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    flex-direction: column;
  }
`

export const UnitAmountText = styled(BaseTextV2).attrs({
  isSmall: { fontSize: true },
})`
  font-weight: 500;
  line-height: 16px;
`

export const PricePerUnitText = styled(TinyTextV2Secondary)``

export const EditBtn = styled.div`
  display: flex;
  padding: ${theme.spacing.xxs};
  border-radius: ${theme.borderRadius.base};
`

export const QuantityBox = styled(GreyWrapper)`
  padding: ${theme.spacing.xxs} ${theme.spacing.ss} ${theme.spacing.xxs} 10px;
  gap: ${theme.spacing.ss};
  width: 144px;
  ${props => props.$isTemplateAutomatic && `cursor: auto;`} :hover {
    ${EditBtn} {
      background: ${theme.colors.lightGrey};
    }
  }

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    width: auto;
    padding: ${theme.spacing.xxs} ${theme.spacing.ss};
  }
`

export const QuantityDetails = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  ${DefaultPopoverContainer};

  width: 260px;
  flex-direction: column;
  padding: ${theme.spacing.sm};
  gap: ${theme.spacing.sm};
`

export const VariantBtn = styled.div`
  display: flex;
  cursor: pointer;
  background: ${theme.colors.lightGrey};
  padding: ${theme.spacing.sm};
  justify-content: space-between;
  align-items: center;
`

export const VariantBtnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  ${VariantBtn} {
    :first-child {
      border-top-right-radius: ${theme.borderRadius.base};
      border-top-left-radius: ${theme.borderRadius.base};
    }
    :last-child {
      border-bottom-left-radius: ${theme.borderRadius.base};
      border-bottom-right-radius: ${theme.borderRadius.base};
    }
  }
`

export const VariantBtnText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})``

export const VariantCheckbox = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 14px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  background: ${theme.colors.white};
  align-items: center;
  justify-content: center;
`

export const ProductButtonsContainer = styled.div`
  display: flex;
  gap: 2px;

  ${QuantityBtnWrapper}:disabled {
    opacity: 0.4;
    cursor: default;

    :hover {
      background-color: transparent;
    }
  }

  ${QuantityControlsWrapper} {
    border-radius: 0;
    min-width: 0;

    path {
      stroke: ${theme.colors.primary};
    }

    ${UnitQuantityInputWrapper} {
      :focus {
        border: none;
      }

      min-width: 0;
      color: ${theme.colors.primary};
    }
  }
`

export const SmallBtn = styled.div`
  display: flex;
  cursor: pointer;
  background: ${theme.colors.lightGrey};
  padding: ${theme.spacing.xs};
  align-self: center;
`

export const TrashBtn = styled(SmallBtn)`
  border-radius: ${theme.borderRadius.base} 0 0 ${theme.borderRadius.base};
`

export const ConfirmBtn = styled(SmallBtn)`
  border-radius: 0 ${theme.borderRadius.base} ${theme.borderRadius.base} 0;
`
