const ELASTIC_STOCKS_EVENTS = {
  STOCK_EXCEEDED: 'esmShown', // When the modal is displayed
  IN_STOCK_APPLIED: 'esmInStockApplied', // When the user selects the in-stock/short delivery date
  NON_STOCK_APPLIED: 'esmNonStockApplied', // When the user selects the non-stoc/distant dd
}

export const trackStockExceeded = ({ customerNo, storeId }) => {
  window.dataLayer.push({
    event: ELASTIC_STOCKS_EVENTS.STOCK_EXCEEDED,
    customer_no: customerNo,
    store_id: String(storeId),
  })
}

export const trackInStockApplied = ({ productId, deliveryDate }) => {
  window.dataLayer.push({
    event: ELASTIC_STOCKS_EVENTS.IN_STOCK_APPLIED,
    product_id: productId,
    delivery_date: deliveryDate,
  })
}

export const trackNonStockApplied = (productId, deliveryDate) => {
  window.dataLayer.push({
    event: ELASTIC_STOCKS_EVENTS.NON_STOCK_APPLIED,
    product_id: productId,
    delivery_date: deliveryDate,
  })
}
