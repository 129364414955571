import React from 'react'
import { FormattedMessage } from 'react-intl'

import theme, { paymentTheme } from 'theme'
import { PAYMENT_COLORS } from 'containers/Orders/consts'
import { Card, Alert } from 'components/Icons/NavMenu'

import messages from '../messages'
import MenuItem from '../MenuItem'

import { Container, Content, IconPlaceholder } from './styles'

const { yellow, red } = paymentTheme

export const INVOICES_COLORS = {
  [PAYMENT_COLORS.YELLOW]: {
    headerBg: yellow.bg,
    headerBgHover: yellow.bgHover,
    headerTxtColor: yellow.text,
    msgTxtColor: yellow.text,
  },
  [PAYMENT_COLORS.RED]: {
    headerBg: red.bg,
    headerBgHover: red.bgHover,
    headerTxtColor: red.text,
    msgTxtColor: theme.colors.redDarkText,
  },
}

const InvoicesMenuItem = ({ onSelect, supplementLabel, paymentColorName }) => {
  const invoicesStyles = INVOICES_COLORS[paymentColorName]
  const invoicesMsg =
    paymentColorName === PAYMENT_COLORS.RED
      ? 'invoicesRedMsg'
      : 'invoicesYellowMsg'

  return (
    <Container headerBgHoverColor={invoicesStyles?.headerBgHover}>
      <MenuItem
        icon={invoicesStyles ? <Alert /> : <Card />}
        title={<FormattedMessage {...messages.invoices} />}
        testId="invoices"
        onSelect={onSelect}
        supplementLabel={supplementLabel}
        bgColor={invoicesStyles?.headerBg}
        txtColor={invoicesStyles?.headerTxtColor}
        className="invoices-menu-item"
      />

      {invoicesStyles && (
        <Content
          data-test-id="pay-bills-msg"
          $txtColor={invoicesStyles.msgTxtColor}
        >
          <IconPlaceholder />
          <FormattedMessage {...messages[invoicesMsg]} />
        </Content>
      )}
    </Container>
  )
}

export default InvoicesMenuItem
