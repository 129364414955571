import styled, { css } from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import {
  getFonts,
  getLineHeights,
  getFontWeights,
  getHeadingSize,
  getColors,
  getSpacing,
} from '../helpers/theme-selectors'
import { BaseTextV2 } from './texts'
import { headingStyles } from './styles'

const getHeadingStyles = ({
  fontSize,
  fontHeight = 'md',
  fontFamily = 'base',
}) => css`
  font-size: ${props => getHeadingSize(props)[fontSize]};
  line-height: ${props => getLineHeights(props)[fontHeight]};
  font-family: ${props => getFonts(props)[fontFamily]};
`

export const H1 = styled.h1`
  ${headingStyles};
  ${getHeadingStyles({ fontSize: 'xxl' })};
  & > * {
    ${getHeadingStyles({ fontSize: 'xxl' })};
  }
`

export const H2 = styled.h2`
  ${headingStyles};
  ${getHeadingStyles({ fontSize: 'xl' })};
  & > * {
    ${getHeadingStyles({ fontSize: 'xl' })};
  }
`

export const H3 = styled.h3`
  ${headingStyles};
  ${getHeadingStyles({ fontSize: 'l' })}; // 28px
  & > * {
    ${getHeadingStyles({ fontSize: 'l' })};
  }
`
// prettier-ignore
export const PageTitle = styled(H3)`
  font-weight: ${props => getFontWeights(props).strongBold};

  ${props => props.spacedBottom && `
    margin-bottom: ${getSpacing(props).base};
  `};
`

export const H4 = styled.h4`
  ${headingStyles};
  ${getHeadingStyles({ fontSize: 'm' })}; // 22px
  & > * {
    ${getHeadingStyles({ fontSize: 'm' })};
  }
`

export const H4Primary = styled(H4)`
  color: ${props => getColors(props).primary};
`

export const H5 = styled.h5`
  ${headingStyles};
  ${getHeadingStyles({ fontSize: 'sm' })};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  & > * {
    ${getHeadingStyles({ fontSize: 'sm' })};
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
`

export const H5Strong = styled(H5)`
  font-weight: ${props => getFontWeights(props).strongBold};
  letter-spacing: 0.96px;
  color: ${props => getColors(props).gray4};
`

export const H1v2 = styled(BaseTextV2).attrs({ as: 'h1' })`
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
`

export const H2v2 = styled(BaseTextV2).attrs({ as: 'h2' })`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`

export const H2mobile = styled(H2v2)`
  font-weight: 600;
`

export const H3v2 = styled(BaseTextV2).attrs({ as: 'h3' })`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

export const H4v2 = styled(BaseTextV2).attrs({ as: 'h4' })`
  font-weight: 500;
  font-size: 14px;
`

export const PageTitleV2 = styled(H1v2)`
  margin-top: 48px;
  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 30px;
  }
`

export const ModalHeader = styled(H2v2)`
  font-weight: 600;
  line-height: 32px;
`

export const PageHeader = styled(H1v2)`
  @media (max-width: ${APP_BREAKPOINTS.TABLET_START - 1}px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const PageTitleDivider = styled.div`
  width: 100vw;
  max-width: 1440px;
  border-top: 1px solid ${theme.colors.borderFaded};
  margin: ${theme.spacing.sm} -${theme.spacing.md} 48px -${theme.spacing.md};
`
