export const PROFILES = {
  CATERING: 'Catering',
  HOTEL: 'Hotel z restauracją',
  KEBAB: 'Kebab',
  PIZZA: 'Pizzeria',
  RESTAURANT: 'Restauracja',
  SUSHI: 'Sushi',
  ASIAN: 'Kuchnia azjatycka',
  MEDITER: 'Kuchnia śródziemnomorska',
  POLISH: 'Kuchnia polska',
  OTHER: 'Inne',
}
