import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  cursor: ${p => (p.$hasTooltip ? 'pointer' : 'inherit')};
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${p => p.$iconMargin}px;
  svg {
    border-radius: 2px;
    ${p => p.$borderColor && `border: 1px solid ${p.$borderColor}`};
  }
`
