import * as React from 'react'
import { isNil } from 'lodash/fp'
import styled from 'styled-components'

import theme from 'theme'

import { BoldInfo } from 'components/Text'

const Number = styled(BoldInfo)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 13px;
`

const Calendar = ({
  color = theme.colors.primary,
  width = 38,
  height = 44,
  number,
  ...rest
}) => (
  <div style={{ position: 'relative' }}>
    {!isNil(number) && <Number {...{ color }}>{number}</Number>}
    <svg
      viewBox="0 0 38 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...rest}
    >
      <path
        d="M7 4H6a5 5 0 00-5 5v26a5 5 0 005 5h26a5 5 0 005-5V9a5 5 0 00-5-5h-1M13 4h12M1 12h36"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M10 2v4M28 2v4"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)

export default Calendar
