import React from 'react'

import theme from 'theme'

const NoResults = ({ color = theme.colors.primary }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 36H5C2.79086 36 1 34.2091 1 32V6C1 3.79086 2.79086 2 5 2H35C37.2091 2 39 3.79086 39 6V15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M1 7H39" stroke={color} strokeWidth="2" />
    <circle cx="29" cy="29" r="14" stroke={color} strokeWidth="2" />
    <path
      d="M39 39L47 47"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 34L34 24"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M24 24L34 34"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default NoResults
