import styled from 'styled-components'
import PortalModal from 'components/modal/PortalModal'
import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

export const EmailNotificationModalWrapper = styled(PortalModal)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.vertical};
`

export const DescriptionText = styled(BaseTextV2).attrs({
  isSmall: {
    lineHeight: true,
  },
})``
