import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AlternateBtn, BUTTON_SIZE } from 'components/Button'

import messages from './messages'
import { Header, Content } from './styles'

const OfflineModal = () => (
  <>
    <Header>
      <FormattedMessage {...messages.offlineHeader} />
    </Header>
    <Content>
      <FormattedMessage {...messages.offlineInfo} />
    </Content>
    <AlternateBtn
      onClick={() => window.location.reload()}
      size={BUTTON_SIZE.BLOCK}
    >
      <FormattedMessage {...messages.reloadPage} />
    </AlternateBtn>
  </>
)

export default OfflineModal
