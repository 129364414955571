import React, { useCallback, useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import { useSelector } from 'react-redux'

import { useOfMaxWidth } from 'hooks'
import { isElementInViewport } from 'utils'
import { APP_BREAKPOINTS } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'

import { GreyWrapper } from '../styledComponents'
import FinanceRow from './FinanceRow'
import SummaryRow from './SummaryRow'
import PaymentStickyRow, { stickyPaymentId } from './PaymentStickyRow'

const getVisibilityThreshold = () => {
  const node = document.getElementById(stickyPaymentId)
  return node && node.offsetHeight
}

const SummaryFooter = ({ scrollbarWidth, aggregatedCartData }) => {
  const isLowerThanMain = useOfMaxWidth(APP_BREAKPOINTS.TMP_MAIN - 1)
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TABLET_START - 1)
  const { hideDues } = useSelector(userDataSelector)
  const dataPresent = !!aggregatedCartData.length

  const [isFooterFixed, setIsFooterFixed] = useState(true)
  const footerRef = useRef(null)
  const getIsFooterFixed = useCallback(
    () =>
      !isElementInViewport(footerRef.current, getVisibilityThreshold()) &&
      !isElementInViewport(
        footerRef.current,
        getVisibilityThreshold(),
        'above',
      ),
    [footerRef],
  )

  const handleScrollThrottled = useCallback(
    throttle(() => {
      if (footerRef.current) {
        setIsFooterFixed(getIsFooterFixed())
      }
    }, 66),
    [footerRef.current],
  )

  useEffect(
    () => {
      window.addEventListener('scroll', handleScrollThrottled, {
        passive: true,
      })

      return () => {
        window.removeEventListener('scroll', handleScrollThrottled)
      }
    },
    [handleScrollThrottled],
  )

  useEffect(
    () => {
      setIsFooterFixed(getIsFooterFixed())
    },
    [setIsFooterFixed, getIsFooterFixed, dataPresent],
  )

  return (
    <div style={{ marginTop: 80 }}>
      {!hideDues && (
        <GreyWrapper {...{ scrollbarWidth }} withBorder>
          <FinanceRow />
        </GreyWrapper>
      )}

      <GreyWrapper {...{ scrollbarWidth }} withBorder={!isMobile}>
        <SummaryRow />
      </GreyWrapper>

      <div ref={footerRef} />
      <GreyWrapper
        {...{ scrollbarWidth }}
        height={isLowerThanMain ? 'auto' : 80}
      >
        <PaymentStickyRow isFixed={isFooterFixed} />
      </GreyWrapper>

      <GreyWrapper {...{ scrollbarWidth }} height={32} />
    </div>
  )
}

export default SummaryFooter
