import { rtkApi } from 'services/api/rtkApi'
import { getGAClientId } from 'utils/cookieUtils'
import { provideProductsQueryTags, RTK_PRODUCT_TAG } from './utils'

const RECOMMENDED_PER_PAGE = 21

export const RTK_RECOMMENDED_PRODUCT_LIST_TAG = 'RecommendedProducts'

const products = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_PRODUCT_TAG, RTK_RECOMMENDED_PRODUCT_LIST_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getRecommendations: build.query({
        query: ({ categoryId, groupId, subgroupId, pageName } = {}) => ({
          url: 'me/recommendations',
          params: {
            categoryId,
            groupId,
            subgroupId,
            pageName,
            // hardcoded as there's only one model currently
            model: 'RECOMMENDED_FOR_YOU',
            visitorId: getGAClientId(),
            perPage: RECOMMENDED_PER_PAGE,
            page: 1,
          },
        }),
        providesTags: result => [
          { type: RTK_RECOMMENDED_PRODUCT_LIST_TAG },
          ...provideProductsQueryTags(result),
        ],
      }),

      getProductRecommendations: build.query({
        query: ({ id }) => ({
          url: `me/products/${id}/recommendations`,
          params: {
            visitor_id: getGAClientId(),
            per_page: RECOMMENDED_PER_PAGE,
          },
        }),
        providesTags: result => provideProductsQueryTags(result),
      }),

      getProductReplacements: build.query({
        query: ({ id }) => ({
          url: `me/products/${id}/replacements`,
        }),
        providesTags: result => provideProductsQueryTags(result),
      }),
    }),
  })

export const {
  useLazyGetRecommendationsQuery,
  useLazyGetProductRecommendationsQuery,
  useLazyGetProductReplacementsQuery,
  useGetProductReplacementsQuery,
} = products

export const useGetProductRecommendationsState =
  rtkApi.endpoints.getProductRecommendations.useQueryState

export const useGetRecommendationsState =
  rtkApi.endpoints.getRecommendations.useQueryState
