import React from 'react'

const GroupButtonsSkeleton = () =>
  Array.from(Array(3).keys()).map(key => (
    <svg
      key={key}
      width="155"
      height="40"
      viewBox="0 0 155 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="155"
        height="40"
        rx="8"
        fill="url(#paint0_linear_30537_22912)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_30537_22912"
          x1="155"
          y1="20"
          x2="-7.98278e-07"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6F8FA" />
          <stop offset="1" stopColor="#E4E9EE" />
        </linearGradient>
      </defs>
    </svg>
  ))

export default GroupButtonsSkeleton
