import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import container from 'containers/CartMissingItems'
import MissingItemsList from 'components/MissingItemsList'
import ErrorContainer from 'views/Cart/ErrorContainer'
import { CART_ITEM_ERROR } from 'containers/Cart/consts'
import { Wrapper } from 'views/TemplateNotAddedItems/styles'

import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'
import messages from './messages'
import { ProductNotification } from './styles'

const CartMissingItems = ({ orderErrorItems }) => {
  const isMissingAlcLicenseError = useMemo(
    () =>
      orderErrorItems.find(
        item => item.errorId === CART_ITEM_ERROR.MISSING_ALCOHOL_LICENSE,
      ),
    [orderErrorItems],
  )
  const headerSubTitleMessage = isMissingAlcLicenseError
    ? messages.checkProducts
    : messages.amountForProductsChanged

  return (
    <Wrapper>
      <ErrorContainer
        errorMessage={<FormattedMessage {...messages.missingItemsError} />}
      />

      <MissingItemsList
        isCartFlow
        items={orderErrorItems}
        itemListName={ITEM_LIST_NAMES.CART}
        itemListId={ITEM_LIST_IDS.CART}
        headerTitle={<FormattedMessage {...messages.orderFailed} />}
        headerSubTitle={<FormattedMessage {...headerSubTitleMessage} />}
        renderProductError={({ errorMessage }) => (
          <ProductNotification strongBold>{errorMessage}</ProductNotification>
        )}
      />
    </Wrapper>
  )
}

CartMissingItems.propTypes = {
  orderErrorItems: PropTypes.arrayOf(PropTypes.object),
}

export default container(CartMissingItems)
