import React, { useCallback, useEffect, useRef } from 'react'

import { useQuery } from 'hooks'

import Consents from './Consents'
import Contacts from './Contacts'
import Other from './Other'

import messages from './messages'
import { SectionsWrapper, Section } from '../styles'
import { SectionHeader } from './styles'

const BottomSection = ({ formatMessage, contacts = [] }) => {
  const config = [
    {
      header: 'consents',
      content: <Consents {...{ formatMessage }} />,
      testId: 'consents-section',
    },
    {
      header: 'contacts',
      content: <Contacts {...{ contacts }} />,
      testId: 'contacts-section',
    },
    {
      header: 'other',
      content: <Other />,
      testId: 'other-section',
    },
  ]

  const yourContactSectionRef = useRef(null)
  const { focus } = useQuery()
  const scrollToYourContacts = useCallback(() => {
    if (yourContactSectionRef.current) {
      const elementPosition = yourContactSectionRef.current.getBoundingClientRect()
        .top

      const header = document.querySelector('#header-with-nav')
      const headerHeight = header ? header.getBoundingClientRect().height : 73
      const offsetPosition =
        elementPosition + window.pageYOffset - headerHeight - 16

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }, [])

  const contactsRenderCallback = useCallback(
    el => {
      yourContactSectionRef.current = el
      if (el && focus === 'your-contact') {
        setTimeout(() => {
          scrollToYourContacts()
        }, 0)
      }
    },
    [focus, scrollToYourContacts],
  )

  useEffect(
    () => {
      if (focus === 'your-contact') {
        scrollToYourContacts()
      }
    },
    [focus, scrollToYourContacts],
  )

  return (
    <SectionsWrapper direction="top">
      {config.map(({ header, content, testId }) => (
        <Section
          key={header}
          data-test-id={testId}
          ref={header === 'contacts' ? contactsRenderCallback : null}
        >
          <SectionHeader>{formatMessage(messages[header])}</SectionHeader>
          {content}
        </Section>
      ))}
    </SectionsWrapper>
  )
}

export default BottomSection
