import React, { Component } from 'react'
import { isInvalid } from 'utils/redux-form-utils'
import { BORDER_TYPES } from './consts'

const WithVisitCheck = Input => {
  class BasicInput extends Component {
    state = {
      visited: false,
    }

    handleBlur = () => {
      this.setState({ visited: true })
    }

    getBorderType = meta => {
      if (meta) {
        if (isInvalid(meta)) {
          return BORDER_TYPES.ERROR
        }
      }

      return this.state.visited ? BORDER_TYPES.VISITED : BORDER_TYPES.DEFAULT
    }

    render() {
      return (
        <Input
          onBlur={this.handleBlur}
          borderType={this.getBorderType(this.props.meta)}
          {...this.props}
        />
      )
    }
  }

  return BasicInput
}

export default WithVisitCheck
