import React, { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ORDERS_ROUTES, ORDER_STATUS } from 'views/Orders/consts'
import { trackPastOrderClick, EVENT_ORIGIN } from 'services/analytics'
import { DATE_TIME_FORMATS } from 'utils/datetime'

import messages from '../messages'
import { PendingIcon, AcceptedIcon, CanceledIcon } from './Icons'
import { Container, OrderInfo, OrderRowText } from './styles'

const CONFIG_BY_ORDER_STATUS = {
  [ORDER_STATUS.TO_BE_ACCEPTED]: {
    msg: messages.pendingStatusMsg,
    icon: <PendingIcon />,
  },
  [ORDER_STATUS.CANCELED]: {
    msg: messages.canceledStatusMsg,
    icon: <CanceledIcon />,
  },
}

const OrderRow = ({
  customerNo,
  order: {
    orderId,
    orderNumber,
    orderStatusName,
    orderStatusId,
    deliveryDate,
    orderSource,
  },
  color,
}) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const orderConfig = CONFIG_BY_ORDER_STATUS[orderStatusId]

  const handleClick = useCallback(
    () => {
      navigate(generatePath(ORDERS_ROUTES.DETAILS, { orderId }))

      trackPastOrderClick({
        orderNumber,
        customerNo,
        orderStatusName,
        origin: EVENT_ORIGIN.DASHBOARD,
      })
    },
    [orderId, orderNumber, customerNo, orderStatusName],
  )

  return (
    <Container
      data-test-id={`orders-tile-row-${orderId}`}
      onClick={handleClick}
    >
      {orderConfig?.icon || <AcceptedIcon />}

      <OrderInfo>
        <OrderRowText>
          {formatMessage(
            orderSource === 'Online'
              ? messages.sourceEcommerce
              : messages.sourceService,
            { orderNumber },
          )}
        </OrderRowText>
        <OrderRowText style={{ color }}>
          {formatMessage(orderConfig?.msg || messages.deliveryStatusMsg, {
            date: dayjs(deliveryDate).format(DATE_TIME_FORMATS.FULL_DATE),
          })}
        </OrderRowText>
      </OrderInfo>
    </Container>
  )
}

export default OrderRow
