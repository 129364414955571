import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getColors, getSpacing } from 'components/helpers/theme-selectors'
import { TertiaryText } from 'components/Text'

import { Dot } from './styles'

const RouteName = styled(TertiaryText)`
  flex: 1;
  margin-left: ${p => getSpacing(p).base};
  ${p => p.greyedOut && `color: ${getColors(p).placeholder}`};
`

const SectionContainer = styled(Flex)`
  align-items: baseline;
  margin-bottom: ${p => getSpacing(p).xs};
`

const DotWithName = ({ isIncoming, routeName }) => (
  <SectionContainer>
    <Dot full={!isIncoming} />
    <RouteName greyedOut={isIncoming}>{routeName}</RouteName>
  </SectionContainer>
)

export default DotWithName
