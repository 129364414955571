import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ROUTES } from 'consts'

import { useRemoteConfig } from 'containers/RemoteConfigProvider'
import HeaderWithNavigation from './HeaderWithNavigation'
import SimpleHeader from './SimpleHeader'
import MaintenanceToast from './MaintenanceToast'
import { HeaderContainer } from './styles'

// TODO refactor components structure to follow relations
const Header = ({ isLoggedIn, pathname, isMobile }) => {
  const { config } = useRemoteConfig()
  const { maintenanceToast } = config

  const [mobileMenuOffset, setMobileMenuOffset] = useState(0)
  const isOnChoseLocation = [
    ROUTES.SET_LOCATION,
    ROUTES.CHANGE_LOCATION,
  ].includes(pathname)

  const headerRef = useRef(null)
  useEffect(() => setMobileMenuOffset(headerRef?.current?.offsetHeight), [
    isMobile,
  ])

  return (
    <HeaderContainer {...{ isMobile }} className="mainHeader" ref={headerRef}>
      <MaintenanceToast config={maintenanceToast} />
      {isLoggedIn && !isOnChoseLocation ? (
        <HeaderWithNavigation {...{ isMobile, pathname, mobileMenuOffset }} />
      ) : (
        <SimpleHeader {...{ isMobile, isLoggedIn }} />
      )}
    </HeaderContainer>
  )
}

Header.propTypes = {
  pathname: PropTypes.string,
}

export default Header
