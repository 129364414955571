import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { P, SecondaryTextFaded } from 'components/Text'
import {
  getColors,
  getSpacing,
  getFontWeights,
} from 'components/helpers/theme-selectors'

/* prettier-ignore */
export const TabContainer = styled(Flex)`
  flex-shrink: 0;
  height: 40px;
  border-radius: 76px;
  align-items: center;
  padding: 0 ${p => getSpacing(p).sm};
  border: solid 1px ${p => getColors(p).gray2};
  cursor: pointer;
  transition: all 0.3s ease;

  ${p => p.isSelected && `
    border: 0;
    background-color: ${getColors(p).primary};

    p, span {
      color: ${getColors(p).white};
    }
  `}
  
  ${p => !p.isSelected && `  
    &:hover {
      border-color: ${getColors(p).secondary};

      p, span {
        color: ${getColors(p).secondary};
      }
    }
  `}

  &:not(:last-of-type) {
    margin-right: ${p => getSpacing(p).xs};
  }
`

export const TabTitle = styled(P)`
  font-weight: ${p => getFontWeights(p).bold};
`

export const TabMsg = styled(SecondaryTextFaded)`
  margin-left: ${p => getSpacing(p).sm};
`

export const TabsContainer = styled.div`
  display: inline-flex;
  margin-right: ${p => getSpacing(p).base};
`

export const TabContent = styled(Flex)`
  align-items: baseline;
  margin-top: -2px;
`
