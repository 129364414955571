import { makeDataSelector, makeIsFetchingSelector } from 'utils/redux-utils'

import { PROMOS_REDUCER_NAME, PROMO_GROUP_REDUCER_NAME } from '../consts'

export const selectPromoGroupData = makeDataSelector([
  PROMOS_REDUCER_NAME,
  PROMO_GROUP_REDUCER_NAME,
])

export const promoGroupFetchingSelector = makeIsFetchingSelector([
  PROMOS_REDUCER_NAME,
  PROMO_GROUP_REDUCER_NAME,
])
