import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from '@rebass/grid'

import { formatPrice } from 'utils'

import { PAY_SCENARIO, PAYMENT_COLORS } from 'containers/Orders/consts'
import { firstDeliveryLogisticLackAmountSelector } from 'containers/Cart/selectors'
import { selectTradeLimit } from 'containers/Orders/selectors'
import { Truck } from 'components/Icons'
import { userDataSelector } from 'containers/UserInfo/selectors'

import messages from 'views/Cart/messages'
import { SCENARIO_CONFIG } from 'views/Cart/SummaryFooter/consts'

import LimitProgress from './LimitProgress'
import { ColoredWrapper, ScenarioDescr } from './styles'

const PayScenario = ({ RightSideIcon, isExpanded, WrapComponent }) => {
  const { hideDues } = useSelector(userDataSelector)
  const firstDeliveryLogisticLackAmount = useSelector(
    firstDeliveryLogisticLackAmountSelector,
  )
  const { scenario, title, message, paymentColorName } = useSelector(
    selectTradeLimit,
  )

  if (!scenario) return null

  const { IconComponent, scenarioBg, scenarioSecTxt } = SCENARIO_CONFIG[
    scenario
  ]
  const isRedScenario = scenario === PAY_SCENARIO.RED
  const isRedClient = paymentColorName === PAYMENT_COLORS.RED

  if (!isRedClient && !isRedScenario && firstDeliveryLogisticLackAmount > 0) {
    return (
      <WrapComponent>
        <ColoredWrapper {...{ isExpanded }}>
          <Flex alignItems="center">
            <Box mr={16}>
              <Truck />
            </Box>

            <ScenarioDescr>
              <b>
                <FormattedMessage
                  {...messages.lacksToFreeDelivery}
                  values={{
                    amount: formatPrice(firstDeliveryLogisticLackAmount),
                  }}
                />{' '}
              </b>
              <FormattedMessage {...messages.meetLogisticLimitInfo} />
            </ScenarioDescr>
          </Flex>

          {RightSideIcon && <RightSideIcon />}
        </ColoredWrapper>
      </WrapComponent>
    )
  }

  if (scenario === PAY_SCENARIO.GREEN) {
    if (hideDues) return null

    return (
      <WrapComponent>
        <LimitProgress />
      </WrapComponent>
    )
  }

  return (
    <WrapComponent>
      <ColoredWrapper {...{ isExpanded }} bg={scenarioBg}>
        <Flex alignItems="center">
          <Box mr={16}>
            <IconComponent color={scenarioSecTxt} />
          </Box>

          <ScenarioDescr color={scenarioSecTxt}>
            <b>{title} </b>
            {message}
          </ScenarioDescr>
        </Flex>

        {RightSideIcon && (
          <RightSideIcon
            color={scenarioSecTxt}
            data-test-id="right-side-icon"
          />
        )}
      </ColoredWrapper>
    </WrapComponent>
  )
}

export default PayScenario
