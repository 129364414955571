import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from '@rebass/grid'
import Modal from 'react-modal'

import theme from 'theme'
import { makeMessages, makeTranslation } from 'utils'

import {
  Overlay,
  Header,
  HeaderWrapper,
  Img,
  Info,
  Wrapper,
  XIcon,
  SubmitButton,
  NextButton,
} from './styles'

const messages = makeMessages(
  {
    ok: 'ok',
  },
  makeTranslation('hintModal.button'),
)

const overridenStyles = {
  overlay: {
    background: 'transparent',
    zIndex: theme.zIndex.modalOverlay,
  },
  content: {
    position: 'initial',
    padding: 0,
    border: 'none',
  },
}

// TODO: refactor to FC and cover with tests
// eslint-disable-next-line react/prefer-stateless-function
class HintModalMain extends React.Component {
  render() {
    const {
      header,
      info,
      img,
      onClose,
      submitTxt = <FormattedMessage {...messages.ok} />,
      submitClickHandler = onClose,
      nextTxt,
      nextClickHandler,
    } = this.props

    const showSubmitButton = !!submitClickHandler && !!submitTxt
    const showNextButton = !!nextTxt && !!nextClickHandler

    return (
      <Modal
        isOpen
        onRequestClose={onClose}
        style={overridenStyles}
        // onAfterOpen={() => {
        //   document.body.style.overflow = 'hidden'
        // }}
        // onAfterClose={() => {
        //   document.body.style.overflow = 'unset'
        // }}
      >
        <Wrapper onClick={e => e.stopPropagation()}>
          <HeaderWrapper>
            <Header>{header}</Header>

            <XIcon onClick={onClose} />
          </HeaderWrapper>

          <Img src={img} />

          <Info>{info}</Info>

          <Flex width="100%">
            {showSubmitButton && (
              <SubmitButton onClick={submitClickHandler}>
                {submitTxt}
              </SubmitButton>
            )}

            {showNextButton && (
              <NextButton onClick={nextClickHandler}>{nextTxt}</NextButton>
            )}
          </Flex>
        </Wrapper>
      </Modal>
    )
  }
}

const HintModal = props => (
  <>
    <Overlay />
    <HintModalMain {...props} />
  </>
)

export default HintModal
