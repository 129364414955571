import React from 'react'

import StarBtn from '../StarBtn'

import { ReplacementContent } from './styles'

const OutOfStockPDP = props => (
  <ReplacementContent>
    <StarBtn {...props} hasTextStarBtn />
  </ReplacementContent>
)

export default OutOfStockPDP
