import React from 'react'
import { Flex } from '@rebass/grid'

import { Link } from 'components/Text'

import ProductImage, { PRODUCT_IMAGE_SIZES } from '../ProductImage'

import { ImgContainer } from './styles'

const ProductLinkImage = ({
  outOfStock,
  product,
  productPath,
  eventParams,
  onSelect,
  isTransparent,
  ...rest
}) => {
  if (productPath) {
    return (
      <Link to={productPath} state={eventParams} onClick={onSelect} {...rest}>
        <ImgContainer style={isTransparent && { mixBlendMode: 'multiply' }}>
          <ProductImage
            size={PRODUCT_IMAGE_SIZES.S}
            {...{ outOfStock, product }}
          />
        </ImgContainer>
      </Link>
    )
  }

  return (
    <Flex onClick={onSelect} {...rest}>
      <ImgContainer style={isTransparent && { mixBlendMode: 'multiply' }}>
        <ProductImage
          size={PRODUCT_IMAGE_SIZES.S}
          {...{ outOfStock, product }}
        />
      </ImgContainer>
    </Flex>
  )
}

export default ProductLinkImage
