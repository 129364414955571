import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { BaseTextV2 } from 'components/Text'

export const MaintenanceToastContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  background: ${theme.colors.redLight2};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.xs} 0;
  }
`

export const MaintenanceToastTextContainer = styled(Flex)`
  max-width: ${theme.size.inner};
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: 2px ${theme.spacing.xs};
  }
`

export const MaintenanceToastText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true, fontSize: true },
})`
  color: ${theme.colors.redDarkText};
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.sm};
  }
`
