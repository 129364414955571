import React from 'react'

const Ketchup = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7201 15.7098L17.1286 20.8823C16.7413 22.141 15.5783 23 14.2613 23H9.7384C8.42138 23 7.25838 22.141 6.87106 20.8823L5.27951 15.7097C4.93066 14.576 5.28262 13.3431 6.17745 12.5644L7.12527 11.7396C7.99824 10.9799 8.49941 9.87937 8.49941 8.72214V7.5C8.49941 6.11929 9.6187 5 10.9994 5H12.9998C14.3805 5 15.4998 6.11929 15.4998 7.5V8.72193C15.4998 9.87924 16.0011 10.9798 16.8741 11.7395L17.8221 12.5643C18.717 13.343 19.069 14.5759 18.7201 15.7098ZM7.49038 14.0731C7.19211 14.3327 7.07479 14.7436 7.19107 15.1215L8.78262 20.2941C8.91173 20.7137 9.29939 21 9.7384 21H14.2613C14.7003 21 15.0879 20.7137 15.217 20.2941L16.8086 15.1216C16.9249 14.7436 16.8075 14.3327 16.5092 14.0731L15.5613 13.2483C14.2517 12.1088 13.4998 10.4579 13.4998 8.72193V7.5C13.4998 7.22386 13.276 7 12.9998 7H10.9994C10.7233 7 10.4994 7.22386 10.4994 7.5V8.72214C10.4994 10.458 9.74765 12.1088 8.4382 13.2483L7.49038 14.0731Z"
      fill="#99D0AC"
    />
    <rect
      className="group-[.active-btn]:fill-white"
      x="8.99976"
      y="1"
      width="6"
      height="2"
      rx="1"
      fill="#229944"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M9.18379 15.4691C9.09198 15.1569 9.326 14.8442 9.65138 14.8442C9.9857 14.8442 10.2208 14.5152 10.1125 14.1989L9.97523 13.7977C9.92866 13.6617 10.0078 13.5147 10.1471 13.4787C11.3624 13.1647 12.6376 13.1647 13.8529 13.4787C13.9922 13.5147 14.0713 13.6617 14.0248 13.7977L13.8875 14.1989C13.7792 14.5152 14.0143 14.8442 14.3486 14.8442C14.674 14.8442 14.908 15.1569 14.8162 15.4691L13.8833 18.6411C13.8208 18.8539 13.6255 19 13.4037 19H10.5963C10.3745 19 10.1792 18.8539 10.1167 18.6411L9.18379 15.4691Z"
      fill="#229944"
    />
  </svg>
)

export default Ketchup
