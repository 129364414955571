import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  dashboardHeader: { id: 'dashboard.header' },
  seeMore: 'seeMore',
  productsCount: {
    id: 'products.numberOfProducts',
  },
  tomorrow: { id: 'navbar.tomorrow' },
  day: { id: 'navbar.day' },
  days: { id: 'navbar.days' },
  nearestDeliveryLongVersion: { id: 'navbar.nearestDeliveryLongVersion' },
}

export default makeMessages(messages, makeTranslation('categories'))
