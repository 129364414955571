import React from 'react'

const Fruits = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5168 2.1914C16.2847 1.93028 15.8905 1.93726 15.6543 2.20668C15.0459 2.90054 14.0127 4.31421 14.0001 5.92477C13.9953 6.53425 14.1372 7.11035 14.3478 7.6275C14.3478 7.6275 14.8114 8.74206 15.073 8.92797C15.2586 9.18901 16.3722 9.6522 16.3722 9.6522C16.8893 9.86279 17.4655 10.0047 18.075 9.99988C19.6857 9.98727 21.0994 8.95418 21.7933 8.3458C22.0627 8.10957 22.0697 7.71539 21.8086 7.48332C21.1361 6.88564 19.7585 5.87441 18.1479 5.88702C18.1362 5.88711 18.1244 5.88726 18.1127 5.88746C18.1129 5.87562 18.1131 5.86377 18.1132 5.85192C18.1258 4.24136 17.1145 2.8639 16.5168 2.1914Z"
      fill="#99D0AC"
    />
    <path
      className="group-[.active-btn]:fill-white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5618 16.4128C13.7234 16.7795 13.6066 17.2031 13.2979 17.4586C9.04668 20.9764 3.33162 22.7414 2.29477 21.7046C1.62099 21.0309 2.13045 18.3816 3.53526 15.4423C3.75616 14.9801 4.30549 14.7742 4.75334 15.0228C5.66064 15.5267 6.44711 16.2222 7.05828 17.0552C7.29206 17.3739 7.7399 17.4427 8.05856 17.2089C8.37722 16.9751 8.44603 16.5273 8.21225 16.2087C7.53123 15.2804 6.66757 14.4938 5.67411 13.9017C5.15175 13.5905 4.92798 12.914 5.26757 12.4096C5.98194 11.3485 6.80008 10.3242 7.70847 9.41586C9.60081 7.5236 12.674 7.52871 14.5727 9.42728C15.9454 10.8 16.3283 12.7866 15.7199 14.497C15.4681 15.205 14.5434 15.1031 14.1215 14.4812C13.4792 13.5344 12.653 12.7228 11.6946 12.0979C11.3635 11.8821 10.9201 11.9755 10.7043 12.3065C10.4884 12.6376 10.5818 13.0809 10.9129 13.2968C12.0725 14.0528 12.9987 15.135 13.5618 16.4128Z"
      fill="#229944"
    />
  </svg>
)

export default Fruits
