import React from 'react'
import theme from 'theme'

const CircleCheckMark = ({
  color = theme.colors.white,
  bgColor = theme.colors.secondary,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect width="20" height="20" rx="10" fill={bgColor} />
    <path
      d="M6 9.69231L8.85714 14L14 6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CircleCheckMark
