// TODO cleanup selectors with usage of genericSelector
import { createSelector } from 'reselect'
import _get from 'lodash/fp/get'
import _getOr from 'lodash/fp/getOr'

import {
  makeDataSelector,
  makeErrorSelector,
  makeIsFetchingSelector,
  makeIsFinishedSelector,
  makeImmutableStateSelector,
} from 'utils/redux-utils'
import { getStoredAuthInfo } from 'containers/Auth/tokenStorage'

import {
  AUTH_REDUCER_NAME,
  SIGN_UP_REDUCER_NAME,
  SIGN_UP_MIGRATE_REDUCER_NAME,
  CONFIRM_EMAIL_REDUCER_NAME,
  RESEND_CONFIRM_REDUCER_NAME,
  RESEND_SET_PASSWORD_REDUCER_NAME,
  VALIDATE_TOKEN_REDUCER_NAME,
  LOGOUT_REASON_REDUCER_NAME,
  LOGOUT_REASONS,
  LOGIN_REDUCER_NAME,
} from './consts'

const makeErrorCodeSelector = selector =>
  createSelector(selector, _getOr(undefined, 'body.errors[0].code'))

const makeEmailSelector = selector =>
  createSelector(selector, _getOr('', 'emailAddress'))

export const loginDataSelector = () => getStoredAuthInfo()

export const signUpDataSelector = makeDataSelector([
  AUTH_REDUCER_NAME,
  SIGN_UP_REDUCER_NAME,
])

export const confirmDataSelector = makeDataSelector([
  AUTH_REDUCER_NAME,
  RESEND_CONFIRM_REDUCER_NAME,
])

export const resendPasswordDataSelector = makeDataSelector([
  AUTH_REDUCER_NAME,
  RESEND_SET_PASSWORD_REDUCER_NAME,
])

export const setPasswordDataSelector = makeDataSelector([
  AUTH_REDUCER_NAME,
  VALIDATE_TOKEN_REDUCER_NAME,
])

export const signInFetchingSelector = makeIsFetchingSelector([
  AUTH_REDUCER_NAME,
  LOGIN_REDUCER_NAME,
])

export const signInErrorSelector = makeErrorSelector([
  AUTH_REDUCER_NAME,
  LOGIN_REDUCER_NAME,
])

export const signUpErrorSelector = makeErrorSelector([
  AUTH_REDUCER_NAME,
  SIGN_UP_REDUCER_NAME,
])

export const confirmErrorSelector = makeErrorSelector([
  AUTH_REDUCER_NAME,
  CONFIRM_EMAIL_REDUCER_NAME,
])

export const isConfirmFetchedSelector = makeIsFinishedSelector([
  AUTH_REDUCER_NAME,
  CONFIRM_EMAIL_REDUCER_NAME,
])

export const validateTokenErrorSelector = makeErrorSelector([
  AUTH_REDUCER_NAME,
  VALIDATE_TOKEN_REDUCER_NAME,
])

export const signUpErrorCodeSelector = makeErrorCodeSelector(
  signUpErrorSelector,
)
export const validateTokenErrorCodeSelector = makeErrorCodeSelector(
  validateTokenErrorSelector,
)
export const confirmErrorCodeSelector = makeErrorCodeSelector(
  confirmErrorSelector,
)

export const signUpEmailSelector = makeEmailSelector(signUpDataSelector)
export const confirmEmailSelector = makeEmailSelector(confirmDataSelector)
export const userLoginSelector = createSelector(
  setPasswordDataSelector,
  _getOr('', 'login'),
)
export const resendPasswordEmailSelector = makeEmailSelector(
  resendPasswordDataSelector,
)

export const submitSuccededSelector = createSelector(
  signUpDataSelector,
  _get('submitSucced'),
)

export const authTokenSelector = createSelector(
  loginDataSelector,
  _get('authToken'),
)

export const isAuthenticatedSelector = createSelector(
  authTokenSelector,
  token => !!token,
)

export const isSessionExpiredSelector = state =>
  makeImmutableStateSelector(state, [
    AUTH_REDUCER_NAME,
    LOGOUT_REASON_REDUCER_NAME,
  ]) === LOGOUT_REASONS.SESSION_EXPIRED

export const isSignUpMigrateFetching = makeIsFetchingSelector([
  AUTH_REDUCER_NAME,
  SIGN_UP_MIGRATE_REDUCER_NAME,
])

export const signUpMigrateErrorSelector = makeErrorSelector([
  AUTH_REDUCER_NAME,
  SIGN_UP_MIGRATE_REDUCER_NAME,
])

export const signUpMigrateErrorCodeSelector = makeErrorCodeSelector(
  signUpMigrateErrorSelector,
)
