import React from 'react'

import {
  MaintenanceToastContainer,
  MaintenanceToastTextContainer,
  MaintenanceToastText,
} from './styles'

const MaintenanceToast = ({ config }) => {
  if (config?.enabled) {
    const { toastText } = config

    return (
      <MaintenanceToastContainer>
        <MaintenanceToastTextContainer>
          <MaintenanceToastText>{toastText}</MaintenanceToastText>
        </MaintenanceToastTextContainer>
      </MaintenanceToastContainer>
    )
  }

  return null
}

export default MaintenanceToast
