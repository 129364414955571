import { reduce, compose, map, find, toPairs, groupBy } from 'lodash/fp'

const mapProductIdsToDeliverySettings = ({
  productIds,
  cartDeliveries,
  deliveryDatesSettingsByDeliveries,
}) =>
  reduce((acc, productId) => {
    const settings = deliveryDatesSettingsByDeliveries.get(productId)

    if (settings) {
      const closestDelivery = settings[0]
      let newDelivery = closestDelivery

      const cartDeliveryAtNewDate = find({
        date: closestDelivery.deliveryDate,
      })(cartDeliveries)
      const matchForCartDelivery =
        cartDeliveryAtNewDate &&
        find({
          deliveryDate: cartDeliveryAtNewDate.date,
          routeId: cartDeliveryAtNewDate.routeId,
        })(settings)

      if (cartDeliveryAtNewDate && matchForCartDelivery) {
        newDelivery = matchForCartDelivery
      }

      return [
        ...acc,
        {
          productId,
          ...newDelivery,
        },
      ]
    }

    return acc
  }, [])(productIds)

export const getDeliveriesToUpdate = ({
  expiredProductIds,
  cartDeliveries,
  deliveryDatesSettingsByDeliveries,
}) => {
  const productsDeliverySettings = mapProductIdsToDeliverySettings({
    productIds: expiredProductIds,
    cartDeliveries,
    deliveryDatesSettingsByDeliveries,
  })

  return compose(
    reduce(
      (acc, [, settings]) => [
        ...acc,
        {
          ...settings[0],
          productIds: map('productId')(settings),
        },
      ],
      [],
    ),
    toPairs,
    groupBy(({ deliveryDate, routeId }) => `${deliveryDate}_${routeId}`),
  )(productsDeliverySettings)
}
