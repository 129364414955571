import React from 'react'
import theme from 'theme'

const EyeOpen = ({ color = theme.colors.primary }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6163 4.7909C10.4156 3.59119 13.5843 3.59119 16.3836 4.7909C18.5338 5.71239 20.3389 7.28804 21.5425 9.29398L22.1488 10.3046C22.775 11.3482 22.775 12.652 22.1488 13.6957L21.5425 14.7063C20.3389 16.7122 18.5338 18.2879 16.3836 19.2094C13.5843 20.4091 10.4156 20.4091 7.6163 19.2094C5.46614 18.2879 3.66105 16.7123 2.45748 14.7063L1.85111 13.6957C1.22492 12.652 1.22492 11.3482 1.85111 10.3046L2.45748 9.29398C3.66105 7.28804 5.46614 5.71239 7.6163 4.7909ZM15.5958 6.62919C13.2996 5.64509 10.7004 5.64509 8.40414 6.62919C6.6404 7.38507 5.15973 8.67754 4.17247 10.323L3.56609 11.3336C3.31993 11.7439 3.31993 12.2564 3.56609 12.6667L4.17247 13.6773C5.15973 15.3228 6.6404 16.6152 8.40414 17.3711C10.7004 18.3552 13.2996 18.3552 15.5958 17.3711C17.3595 16.6152 18.8402 15.3227 19.8275 13.6773L20.4339 12.6667C20.68 12.2564 20.68 11.7439 20.4339 11.3336L19.8275 10.323C18.8402 8.67754 17.3595 7.38507 15.5958 6.62919Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16C14.2091 16 16 14.2091 16 12C16 11.5837 15.3996 11.4879 15.0448 11.7057C14.7408 11.8924 14.3829 12 14 12C12.8954 12 12 11.1046 12 10C12 9.61705 12.1076 9.25924 12.2943 8.95517C12.5121 8.6004 12.4163 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
      fill={color}
    />
  </svg>
)

export default EyeOpen
