import React from 'react'

import theme from 'theme'

const SquareAlert = ({ color = theme.colors.orangeDark2 }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="-0.00195312" width="12" height="12" rx="3" fill={color} />
    <path
      d="M5.0239 2.77501C5.00976 2.35123 5.34947 2 5.77349 2H6.22263C6.64664 2 6.98634 2.35121 6.97222 2.77498L6.8475 6.51666C6.83852 6.78617 6.61744 7 6.34778 7H5.64846C5.37881 7 5.15773 6.78617 5.14874 6.51667L5.0239 2.77501Z"
      fill="white"
    />
    <circle cx="5.99805" cy="9" r="1" fill="white" />
  </svg>
)

export default SquareAlert
