import React from 'react'
import { CartV2, HeartBig } from 'components/Icons'
import AddTemplateToCartButton from './AddTemplateToCartButton'
import RemoveTemplate from './RemoveTemplate'

import {
  TemplateName,
  TemplateNameWrapper,
  HeaderContainer,
  TemplateHeaderActions,
} from './styles'

const Header = ({ editable, name, id, totalCount, type, isAllUnavailable }) => {
  const isHeartIcon = !editable && name
  const isActive = !!totalCount && !isAllUnavailable

  return (
    <HeaderContainer>
      <TemplateNameWrapper>
        {isHeartIcon && <HeartBig />}
        <TemplateName {...{ editable }}>{name}</TemplateName>
      </TemplateNameWrapper>
      <TemplateHeaderActions>
        {editable && <RemoveTemplate id={id} name={name} />}
        <AddTemplateToCartButton
          isActive={isActive}
          {...{ name, id, type }}
          icon={<CartV2 />}
        />
      </TemplateHeaderActions>
    </HeaderContainer>
  )
}

export default Header
