import React from 'react'
import { FormattedMessage } from 'react-intl'

import { formatPrice } from 'utils'
import messages from 'views/Cart/messages'

import { DeliveryCaption } from '../styles'

const Delivery = ({ missingFreeDeliveryAmount, isBold }) => (
  <DeliveryCaption bold={isBold}>
    {missingFreeDeliveryAmount > 0 ? (
      <FormattedMessage
        {...messages.lacksToFreeDelivery}
        values={{
          amount: formatPrice(missingFreeDeliveryAmount),
        }}
      />
    ) : (
      <FormattedMessage {...messages.freeDelivery} />
    )}
  </DeliveryCaption>
)

export default Delivery
