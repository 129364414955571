export const PAST_ORDER_EVENTS = {
  STOCK_EXCEEDED: 'poStockExceededShown',
  NON_STOCK_DISTANT_DD: 'poNonStockApplied',
  CLICKED_CANCEL_ORDER: 'clicked_cancel_order',
  CANCELLED_ORDER: 'cancelled_order',
  CANCELLATION_ABANDONED: 'order_cancellation_abandoned',
  CLICKED_PAST_ORDER: 'clicked_past_order',
  CLICKED_SEE_ALL_ORDERS_TILE: 'clicked_see_all_orders_tile',
  TILE_EXPANDED: 'orders_tile_expanded',
  TILE_COLLAPSED: 'orders_tile_collapsed',
}

export const PAST_ORDER_CLICK_ORIGIN = {
  ORDERS_TILE: 'orders_tile',
  ORDER_LIST: 'order_list',
}

export const trackPOStockExceeded = ({ productId, customerNo, storeId }) => {
  window.dataLayer.push({
    event: PAST_ORDER_EVENTS.STOCK_EXCEEDED,
    product_id: productId,
    customer_no: customerNo,
    store_id: String(storeId),
  })
}

export const trackPONonStockDistantDD = (
  productId,
  distantDD,
  inStockQty,
  closeDD,
  orderedQty,
  user,
) => {
  window.dataLayer.push({
    event: PAST_ORDER_EVENTS.NON_STOCK_DISTANT_DD,
    product_id: productId,
    distant_dd: distantDD,
    in_stock_qty: inStockQty,
    close_dd: closeDD,
    ordered_qty: orderedQty,
    customer_no: user.customerNo,
    store_id: String(user.storeId),
  })
}

export const trackOrderEvent = ({
  event,
  orderDetails,
  productCount,
  userData,
  reason,
}) => {
  const eventParams = {
    order_number: orderDetails.orderNumber,
    total_gross: orderDetails.totalGross,
    product_count: productCount,
    customer_no: userData.customerNo,

    // optional, only for confirm cancel event
    ...(reason ? { reason: reason.join(',') } : {}),
  }

  window.dataLayer.push({
    event,
    ...eventParams,
  })
}

export const trackPastOrderClick = ({
  orderNumber,
  customerNo,
  orderStatusName,
  origin,
}) => {
  window.dataLayer.push({
    event: PAST_ORDER_EVENTS.CLICKED_PAST_ORDER,
    transaction_id: orderNumber,
    customer_no: customerNo,
    origin,
    order_status: orderStatusName,
  })
}

export const trackSeeAllOrdersClick = ({ customerNo, origin }) => {
  window.dataLayer.push({
    event: PAST_ORDER_EVENTS.CLICKED_SEE_ALL_ORDERS_TILE,
    customer_no: customerNo,
    origin,
  })
}

export const trackOrderTileExpanded = ({ storeId, customerNo, origin }) => {
  window.dataLayer.push({
    event: PAST_ORDER_EVENTS.TILE_EXPANDED,
    store_id: String(storeId),
    customer_no: customerNo,
    origin,
  })
}

export const trackOrderTileCollapsed = ({ storeId, customerNo, origin }) => {
  window.dataLayer.push({
    event: PAST_ORDER_EVENTS.TILE_COLLAPSED,
    store_id: String(storeId),
    customer_no: customerNo,
    origin,
  })
}
