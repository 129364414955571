import styled from 'styled-components'

import theme from 'theme'

import { AlternateBtn, BUTTON_SIZE } from 'components/Button'
import { InteractiveWrapper } from 'components/Button/styles'
import { withIcon, ICONS, ICONS_POSITION, ICON_SIZE } from 'components/Icon'

export const CreateTemplateWrapper = styled(InteractiveWrapper)`
  padding: 0;
  width: 100%;
  button {
    width: 100%;
  }
`

export const CreateTemplateToggle = props =>
  withIcon({
    iconSize: ICON_SIZE.SMALL,
    iconType: ICONS.PLUS,
    iconPosition: ICONS_POSITION.LEFT,
    size: BUTTON_SIZE.SMALL,
    ...props,
  })(styled(AlternateBtn)`
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    background: transparent;
    padding: 0;
    justify-content: flex-start;
    border: none;
    figure {
      margin-right: ${theme.spacing.sm};
    }
    :hover {
      color: ${theme.colors.primary};
      background: transparent;
      border: none;
    }
  `)
