import newsletterWatchers from './Newsletter/sagas'
import newsletterConfirmWatchers from './Newsletter/EmailConfirm/sagas'
import changePasswordWatchers from './ChangePassword/sagas'
import agreementsWatchers from './Agreements/sagas'
import deleteAccountWatchers from './DeleteAccount/sagas'
import resetPasswordWatchers from './ResetPassword/sagas'

export const profileWatchers = [
  ...newsletterWatchers,
  ...newsletterConfirmWatchers,
  ...changePasswordWatchers,
  ...agreementsWatchers,
  ...deleteAccountWatchers,
  ...resetPasswordWatchers,
]
