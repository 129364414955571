import { takeLatest, call, put, race, take } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { prop } from 'lodash/fp'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { genericGetDataEnhanced } from 'containers/App/sagas'

import { getPayStatusActions, pollPayStatus } from './actions'
import { getPayStatus } from './api'
import { PAY_STATUS } from './consts'

function* getPayStatusFlow({ data }) {
  try {
    return yield call(genericGetDataEnhanced, {
      actions: getPayStatusActions,
      request: getPayStatus,
      params: data,
    })
  } catch (error) {
    console.log('Error getting payment status', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    return null
  }
}

function* pollPayStatusFlow({ payload }) {
  console.log('Start polling payment request status')

  const { request } = yield race({
    request: call(getPayStatusFlow, { data: payload }),
    cancel: take(getPayStatusActions.CANCEL),
  })

  if (prop('status', request) === PAY_STATUS.PROCESSING) {
    console.log('Payment is being processed, waiting for 5 sec')
    const { timeout } = yield race({
      cancel: take(getPayStatusActions.CANCEL),
      timeout: delay(5000),
    })

    if (timeout) {
      console.log('Timeout has been exhausted, requesting the status again')
      yield put(pollPayStatus(payload))
    }
  }
}

export default [
  takeLatest(getPayStatusActions.DELTA, getPayStatusFlow),
  takeLatest(pollPayStatus, pollPayStatusFlow),
]
