import { makeMessages, makeTranslation } from 'utils'

const messages = {
  blank: 'blank',
  minLength: 'minLength',
  maxLength: 'maxLength',
  exactLength: 'exactLength',
  regex: 'regex',
}

export default makeMessages(messages, makeTranslation('validators'))
