import { makeMessages, makeTranslation } from 'utils'

const messages = {
  numberOfProducts: { id: 'products.numberOfProducts' },
  lackOfDeliveryAddress: { id: 'lackOfDeliveryAddress' },
  orderDetails: 'orderDetails',
  billingDetail: 'billingDetail',
  orderDate: 'orderDate',
  orderNumber: 'orderNumber',
  status: 'status',
  deliveryMethod: 'deliveryMethod',
  deliveryTime: 'deliveryTime',
  edit: { id: 'edit' },
  orderComments: 'orderComments',
  orderedProducts: 'orderedProducts',
  payment: 'payment',
  invoiceWillBeAvailable: 'invoiceWillBeAvailable',
  deliveryAddress: 'deliveryAddress',
  addAll: 'addAll',
  invoice: 'invoice',
  toPay: { id: 'cart.toPay' },
  amountNet: { id: 'cart.amountNet' },
  amountGross: { id: 'cart.amountGross' },
  delivery: { id: 'cart.delivery' },
  selectedRouteInfo: { id: 'cart.selectedRouteInfo' },
  billingData: 'billingData',
  purchaser: 'purchaser',
  nip: 'nip',
  errorHeading: { id: 'cart.error.heading' },
  orderPlaced: 'orderPlaced',
  orderSummary: 'orderSummary',
  confirmedOrder: 'confirmedOrder',
  orderVAT: 'orderVAT',
  gross: { id: 'prices.gross' },
  total: 'total',
  none: 'none',
  source: { id: 'orders.list.source' },
  maxAvailableAmount: { id: 'nonStock.maxAvailableAmount' },
  reportComplaint: 'reportComplaint',
  reportFeedback: 'reportFeedback',
  contactFormDescription: 'contactFormDescription',
  feedbackFormDescription: 'feedbackFormDescription',
  cancelOrder: 'cancelOrder',
  floatingPriceInfo: { id: 'floatingPrice.orderInfoBoxText' },
  contactDetails: 'contactDetails',
  orderSource: 'orderSource',
  route: 'route',
  yourComment: 'yourComment',
  free: 'free',
  paymentSummary: { id: 'cart.paymentSummary' },
  orders: { id: 'navbar.orders' },
  orderDateTooltip: 'orderDateTooltip',
  missingLicense: { id: 'product.missingLicense' },
  outOfCatalog: { id: 'product.outOfCatalogTooltip' },
  addToTemplate: { id: 'product.addToTemplateTooltip' },
  rewardProductBadge: 'rewardProductBadge',
  showReplacements: { id: 'catalog.replacements.showReplacementsBtn' },
  routeDeadlineInfo: 'routeDeadlineInfo',
  notFoundHeader: 'notFound.header',
  notFoundDesc: 'notFound.desc',
  changeLocation: { id: 'navbar.menu.changeLocation' },
  home: { id: 'navbar.home' },
  alreadyRemovedLabel: 'alreadyRemovedLabel',
}

export default makeMessages(messages, makeTranslation('orderDetails'))
