import React from 'react'

import PasswordInput from 'components/Input/PasswordInput'
import GenericField from '../GenericField'

const Password = props => (
  <GenericField
    component={PasswordInput}
    name="password"
    type="password"
    required
    {...props}
  />
)

export default Password
