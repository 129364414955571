import styled, { css } from 'styled-components'
import { Box, Flex } from '@rebass/grid'

import {
  getColors,
  getSpacing,
  getTextSizes,
  getFonts,
} from 'components/helpers/theme-selectors'
import { P, SecondaryText } from 'components/Text'
import { PrimaryBtn } from 'components/Button'
import { BasicInput } from 'components/Input/styles'
import { DropdownScroll } from 'components/Scroll/styles'
import theme from 'theme'

export const CheckBoxLabelContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const CheckBoxLabel = styled(P)`
  margin-left: ${props => getSpacing(props).sm};
  word-break: break-all;
  font-family: ${props => getFonts(props).secondary};
  font-size: ${props => getTextSizes(props).sm};
  color: ${props => getColors(props).primary};
  line-height: 24px;
`

const filterOptionStyles = css`
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  border-radius: 8px;
  align-items: center;
  .primaryFill {
    ${p => p.checked && `fill: ${theme.colors.primary}`};
  }
  .primaryStroke {
    ${p => p.checked && `stroke: ${theme.colors.primary}`};
  }
  :hover {
    .primaryFill {
      fill: ${theme.colors.primary};
    }
    .primaryStroke {
      stroke: ${theme.colors.primary};
    }
    background-color: ${props => getColors(props).lightGrey};
    .checkbox {
      border-radius: 4px;
      background-color: ${props => getColors(props).lightGrey3};
      :before {
        background-color: ${props => getColors(props).greenLight};
      }
    }
  }
`

export const CheckBoxWrapper = styled.label`
  ${filterOptionStyles} width: 100%;
  cursor: pointer;
  display: flex;
`

export const FiltersDropdownScroll = styled(DropdownScroll)`
  padding: ${theme.spacing.sm};
  @media (min-width: 640px) {
    max-height: 314px;
  }
  @media (max-width: 640px) {
    height: calc(100% - 60px);
  }
  ${props =>
    props.$isNewFilters &&
    `
    @media (max-width: 640px) {
      .simplebar-content:last-child {
        margin-bottom: 60px;
      }
    }
  `};
`

export const DropdownItemsSeparator = styled.div`
  height: 1px;
  margin: 16px 16px 16px 19px;
  background-color: ${props => getColors(props).lightGrey2};
`

export const Header = styled(Box)`
  padding: 0 ${props => getSpacing(props).base};
  border-bottom: 1px solid ${props => getColors(props).lightGrey2};
`

export const ClearAll = styled(Flex)`
  ${filterOptionStyles} justify-content: left;
  align-items: center;
  user-select: none;
  cursor: pointer;
  > svg:first-child {
    margin-left: -3px;
  }
`

export const SearchContainer = styled(Box)`
  position: relative;
  margin: ${props => getSpacing(props).xs} 0;

  input {
    height: 40px; // TODO refactor 40px
    padding-left: 48px;
    padding-top: 0;
  }
`

export const SearchIconContainer = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 8px;
`

export const SearchInput = styled(BasicInput)`
  border: none;
  font-family: ${props => getFonts(props).secondary};
  font-size: ${props => getTextSizes(props).sm};
  color: ${props => getColors(props).primary};
  ::placeholder {
    opacity: 1;
    color: ${props => getColors(props).gray5};
  }
  :focus {
    border: none;
    outline: none;
    ::placeholder {
      color: ${props => getColors(props).gray5};
    }
  }
`

export const ClearBtn = styled.a`
  cursor: pointer;
  font-family: ${props => getFonts(props).secondary};
  margin-left: 11px;
  color: ${props => getColors(props).gray5};
  font-size: ${props => getTextSizes(props).xs};
`

export const Footer = styled(Box)`
  padding: ${props => getSpacing(props).sm};
  padding-bottom: 0;
  margin-bottom: ${props => getSpacing(props).sm};
  border-top: 1px solid ${props => getColors(props).lightGrey2};
  @media (max-width: 640px) {
    position: sticky;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: ${props => getSpacing(props).xs};
    background: ${props => getColors(props).white};
  }

  button {
    width: 100%;
  }
`

export const FilterItemsCount = styled(SecondaryText)`
  display: inline-block;
  margin-left: ${props => getSpacing(props).xs};
  line-height: 24px;
`

export const ApplyFiltersBtn = styled(PrimaryBtn)`
  font-family: ${props => getFonts(props).secondary};
  font-weight: 400;
  font-size: ${props => getTextSizes(props).xs};
  color: ${props => getColors(props).white};
  background-color: ${props => getColors(props).secondary};
  border-radius: 8px;
  :hover {
    background-color: ${props => getColors(props).greenLight};
  }
  :after {
    content: '${p => !!p.$selectedTotalCount && p.$selectedTotalCount}';
    opacity: 0.6;
    margin-left: 10px;
  }
`

export const ApplyTotalCount = styled(SecondaryText)`
  font-family: ${props => getFonts(props).secondary};
  font-weight: 400;
  font-size: ${props => getTextSizes(props).xs};
`
