import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { capitalize } from 'lodash/fp'

import { formatPrice, useRichFormatMessage } from 'utils'

import TextTooltip from 'components/Tooltip/TextTooltip'
import messages from 'views/Cart/messages'

function TooltipComp({
  children,
  firstDeliveryLogisticLackAmount,
  firstDelivery,
}) {
  const formatRichMessage = useRichFormatMessage()

  const amount = useMemo(() => formatPrice(firstDeliveryLogisticLackAmount), [
    firstDeliveryLogisticLackAmount,
  ])
  const date = useMemo(
    () =>
      capitalize(
        dayjs(firstDelivery?.date)
          .format('D MMMM')
          .split(' ')
          .join('\u00A0'),
      ),
    [firstDelivery],
  )

  return (
    <TextTooltip
      content={formatRichMessage(messages.addToMeetLimit, { amount, date })}
      targetProps={{
        style: { height: '100%' },
      }}
      matchTriggerWidth
    >
      {children}
    </TextTooltip>
  )
}

export default TooltipComp
