import React from 'react'
import { FormattedMessage } from 'react-intl'

import { P } from 'components/Text'

import Panel from '../../components/Panel'
import { Header } from '../../components/styledComponents'
import messages from '../messages'

const UnsubscribeSuccess = () => (
  <Panel>
    <Header>
      <FormattedMessage {...messages.feedbackSuccessTitle} />
    </Header>

    <P>
      <FormattedMessage {...messages.feedbackSuccessMessage} />
    </P>
  </Panel>
)

export default UnsubscribeSuccess
