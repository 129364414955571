import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { TinyText } from 'components/Text'
import ProductImage from 'components/Product/ProductImage'
import { BoxChild } from '../styles'

export const InCartBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.secondaryBgLight};
  border: 1px solid ${theme.colors.borderGreen};
  border-radius: 5px;
  padding: 6px 12px;
  min-width: 100px;
`

export const CartBtnBox = styled(BoxChild)`
  background-color: ${p =>
    p.disabled ? theme.colors.secondaryBgLight : theme.colors.secondary};
  ${p => p.hidden && 'display: none'};
  ${p =>
    p.disabled
      ? `border-left: 1px solid ${theme.colors.borderGreen}`
      : 'cursor: pointer'};
`

export const PrizeImage = styled(ProductImage)`
  width: 32px;
  height: 32px;
  mix-blend-mode: multiply;
`

export const PromoInfoContainer = styled(Flex)`
  align-items: center;
  gap: 12px;
`

export const Points = styled(TinyText)`
  color: ${theme.colors.secondary};
  font-weight: ${theme.fontWeights.strongBold};
  font-size: 11px;
`
