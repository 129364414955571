export const BADGE_DIRECTION = {
  LEFT: 'l',
  RIGHT: 'r',
}

export const BADGE_STATUS = {
  PROMO: 'promo',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  DISABLED: 'disabled',
}

export const FEATURE_TYPES = {
  OFFER: 'OFFER',
  RECOMMENDATION: 'RECOMMENDATION',
}
