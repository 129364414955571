import { createSelector } from 'reselect'
import { compose, getOr, slice, find } from 'lodash/fp'

import { makeDataSelector } from 'utils/redux-utils'

import { PRODUCTS_REDUCER_NAME } from '../consts'
import { REPLACEMENTS_REDUCER_NAME } from './consts'

const selectReplacementsData = makeDataSelector([
  PRODUCTS_REDUCER_NAME,
  REPLACEMENTS_REDUCER_NAME,
])

export const allReplacementProductsSelector = createSelector(
  selectReplacementsData,
  data => data?.products || [],
)

export const replacementProductsSelector = createSelector(
  selectReplacementsData,
  compose(
    slice(0, 6),
    getOr([], 'products'),
  ),
)

export const replacementByIdSelector = id =>
  createSelector(replacementProductsSelector, find({ id }))
