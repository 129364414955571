import React from 'react'
import styled from 'styled-components'

import { getSpacing } from 'components/helpers/theme-selectors'
import { Cart } from 'components/Icons'

import SecondaryBtn from './SecondaryBtn'
import { BUTTON_SIZE } from './consts'

const AddProductsButton = ({ children, icon, ...rest }) => (
  <SecondaryBtn {...rest}>
    {icon || <Cart />}
    {children}
  </SecondaryBtn>
)

// prettier-ignore
const BtnStyled = styled(AddProductsButton).attrs({ size: BUTTON_SIZE.SMALL })`
  padding: 0 ${p => getSpacing(p).sm};

  ${p => p.children ? `
    svg {
      margin-right: ${getSpacing(p).xs};
    }
  ` : `
    padding: 0 ${getSpacing(p).xs};
  `};
`

export default BtnStyled
