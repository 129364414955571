import styled from 'styled-components'

import theme from 'theme'

import { breakPointLayoutS } from '../styledComponents'

export const Container = styled.div`
  padding-top: 96px;
  width: 100%;
`

export const Separator = styled.div`
  height: 1px;
  background-color: ${theme.colors.borderFaded};
  margin: 0 -${theme.spacing.md};

  @media (max-width: ${breakPointLayoutS}) {
    margin: 0 -${theme.spacing.base};
  }
`
