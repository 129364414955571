import React from 'react'
import { useIntl } from 'react-intl'

import { BreadCrumbList, BreadCrumb } from 'components/BreadCrumbs'
import { ROUTES } from 'consts'

import { useQueryFilters } from 'hooks'
import { generatePathWithSearch } from 'views/Products/Header/utils'
import messages from '../messages'
import { BreadCrumbsContainer } from './styles'

const ProductsBreadcrumbs = ({ children }) => {
  const { formatMessage } = useIntl()
  const { searchQuery } = useQueryFilters()

  return (
    <BreadCrumbsContainer>
      <BreadCrumbList>
        <BreadCrumb
          to={generatePathWithSearch(ROUTES.PRODUCTS, { search: searchQuery })}
        >
          {formatMessage(messages.allProducts)}
        </BreadCrumb>

        {children}
      </BreadCrumbList>
    </BreadCrumbsContainer>
  )
}

export default ProductsBreadcrumbs
