import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { noop } from 'lodash/fp'

import { logout } from 'containers/Auth/actions'

const SignUpMigrateWrapper = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      logout({
        callback: noop, // TODO show confirmation if user wants to proceed
      }),
    )
  }, [])

  return <Outlet />
}

export default SignUpMigrateWrapper
