import styled from 'styled-components'

import { Link } from 'components/Navigation'

import { Title, Description } from '../SignUp/styledComponents'

export const PrimaryTitle = styled(Title)`
  margin-bottom: 48px;
`

export const SectionDesc = styled(Description)`
  margin-top: 16px;
`

export const AgreementSection = styled.div`
  margin-top: 24px;
`

export const SuccessLink = styled(Link)`
  margin-top: 24px;
`
