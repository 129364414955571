import { combineReducers } from 'redux-immutable'
import { createReducer } from 'utils/simpleDataReducer'

import {
  ALL_CATEGORIES_LIST_REDUCER_NAME,
  CATEGORY_LIST_REDUCER_NAME,
  SELECTED_CATEGORY_GROUP_REDUCER_NAME,
  SELECTED_CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_SUBGROUP_REDUCER_NAME,
} from 'containers/Categories/consts'
import {
  categoryActions,
  categoriesListActions,
  categoryGroupActions,
  categorySubgroupActions,
  allCategoriesListActions,
} from './actions'

export default combineReducers({
  [SELECTED_CATEGORY_REDUCER_NAME]: createReducer(categoryActions),
  [CATEGORY_LIST_REDUCER_NAME]: createReducer(categoriesListActions),
  [ALL_CATEGORIES_LIST_REDUCER_NAME]: createReducer(allCategoriesListActions),
  [SELECTED_CATEGORY_GROUP_REDUCER_NAME]: createReducer(categoryGroupActions),
  [SELECTED_CATEGORY_SUBGROUP_REDUCER_NAME]: createReducer(
    categorySubgroupActions,
  ),
})
