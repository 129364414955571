import React from 'react'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'

import theme from 'theme'

import { TertiaryText } from 'components/Text'

import messages from 'views/Cart/messages'

const InfoRow = ({ label, labelOpts, value }) => {
  const { formatMessage } = useIntl()
  return (
    <Flex
      mb={theme.spacing.xs}
      alignItems="center"
      justifyContent="space-between"
    >
      <TertiaryText>
        {formatMessage(messages[label], labelOpts).replace(':', '')}:
      </TertiaryText>

      <TertiaryText bold>{value}</TertiaryText>
    </Flex>
  )
}

export default InfoRow
