import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { Link } from 'components/Text'

const rowStyling = css`
  justify-content: space-between;
  padding: 8px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  ${p =>
    !p.isDisabled &&
    `
    cursor: pointer;
    :hover {
      background: ${theme.colors.lightGrey};
    }
  `};
`

export const OtherRowsWrapper = styled.div`
  margin-top: 8px;
  border-bottom: 1px solid ${theme.colors.lightGrey2};
`

export const OtherRowContainer = styled(Flex)`
  ${rowStyling};
`

export const OtherRowLink = styled(Link)`
  display: flex;
  width: auto;
  ${rowStyling};
`

export const OtherTextContainer = styled(Flex)`
  > svg {
    margin-right: 16px;
  }
`
