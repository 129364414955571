import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2, BaseTextV2Medium } from 'components/Text'

export const LogisticInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.sm};
  gap: ${theme.spacing.xs};
  border-radius: ${theme.borderRadius.md};
  background-color: ${theme.colors.orange3};
`

export const LogisticInfoTitleContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
`

export const LogisticInfoTitle = styled(BaseTextV2Medium).attrs({
  isSmall: { lineHeight: true },
})`
  color: ${theme.colors.orangeDark2};
  padding-top: ${theme.spacing.xxs};
`

export const LogisticInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  gap: ${theme.spacing.sm};
`

export const LogisticInfoText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true, fontSize: true },
})`
  color: ${theme.colors.orangeDark2};
`

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
`

export const ProgressBarValues = styled.div`
  display: flex;
  justify-content: space-between;
`
