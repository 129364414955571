import styled from 'styled-components'

import theme from 'theme'

export const LinesGroup = styled.div`
  padding: ${theme.spacing.sm} ${theme.spacing.xs};

  ${p =>
    p.isFirst &&
    `
    padding-top: 0;
  `};
  ${p => p.isLast && `padding-bottom: 0;`};
`
