import React, { forwardRef } from 'react'

import { PageContent } from 'containers/App/LoggedInLayout/styles'

import { FixedContainer, InnerFixedWrapper } from './styles'

export const FixedBottom = forwardRef(({ children, isFixed }, ref) => (
  <FixedContainer ref={ref} {...{ isFixed }}>
    <PageContent>
      <InnerFixedWrapper {...{ isFixed }}>{children}</InnerFixedWrapper>
    </PageContent>
  </FixedContainer>
))
