import React from 'react'
import { FormattedMessage } from 'react-intl'

import Notification from '../Notification'
import messages from './messages'
import { StyledButton } from './styledComponents'

const Expired = ({ onBtnClick }) => (
  <Notification header={messages.expired} width="490px">
    <FormattedMessage {...messages.expiredDescription} />
    <StyledButton onClick={onBtnClick}>
      <FormattedMessage {...messages.sendNewLink} />
    </StyledButton>
  </Notification>
)

export default Expired
