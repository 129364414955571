import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { AddProductsButton } from 'components/Button'
import { Link, BaseTextV2 } from 'components/Text'
import { DefaultPopoverContainer } from 'components/Popup/styles'

// prettier-ignore
export const UserControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: ${theme.spacing.xxs};

  ${p => p.isMobile && `
    > * {
      padding: ${theme.spacing.xs} ${theme.spacing.sm};
      margin-right: 0;
    }
    > :not(:last-child) {
      margin-right: 0;
    }
  `};
`

export const CartBtn = styled(Link)`
  @media (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    width: 40px;
    button {
      min-width: 0;

      figure {
        margin-right: ${theme.spacing.xs};
        margin-left: ${theme.spacing.xs};
      }
    }
  }
`

// prettier-ignore
export const Cart = styled(AddProductsButton)`
  font-family: ${theme.fonts.primary};
  font-weight: 500;
  line-height: 24px;
  font-size: 13px;
  border-radius: ${p => p.$hasTimer ? '8px 0 0 8px' : '8px'};
  ${p => p.isCounter && `
    min-width: auto;
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
  `}
  :hover {
    background: ${theme.colors.greenLight};
  }

  svg {
    fill: none;
    margin-right: ${theme.spacing.sm};
  }
  ${p => p.cartBtnStyles && `
    background: ${p.cartBtnStyles.bgColor};
    color: ${p.cartBtnStyles.contentColor};
    path {
      stroke: ${p.cartBtnStyles.contentColor};
    }
    circle {
      fill: ${p.cartBtnStyles.contentColor};
    }
    :hover {
      background: ${p.cartBtnStyles.hoverBgColor};
    }
    ${p.cartBtnStyles.isAlert && `
      min-width: 0;
      > svg {
        margin-right: 0;
      }
      padding: ${theme.spacing.xs} ${theme.spacing.sm};
    `}
  `}
`

// prettier-ignore
export const UserIconContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  cursor: pointer;
  border-radius: 8px;
  &[data-state='open'],
  :hover {
    background: ${theme.colors.lightGrey};
  }
  ${p => p.userBtnStyles && `
    background: ${p.userBtnStyles.bgColor};
    * {
      color: ${p.userBtnStyles.contentColor};
    }
    path, circle {
      stroke: ${p.userBtnStyles.contentColor};
    }
    &[data-state='open'], :hover {
      background: ${p.userBtnStyles.hoverBgColor};
    }
  `}
`

export const UserIconCustomerNo = styled(BaseTextV2).attrs({
  isSmall: { fontSize: true },
})`
  margin-left: ${theme.spacing.sm};
  white-space: nowrap;
`

export const UserMenuContainer = styled.div`
  ${DefaultPopoverContainer};
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: ${theme.spacing.sm};
  border-radius: 16px;
  width: 384px;
`

export const CartBtnContainer = styled.div`
  display: flex;
  gap: 2px;
`
