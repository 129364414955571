import React, { useEffect, useRef, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { isNumber, isEqual, isNil, compose } from 'lodash/fp'
import queryString from 'query-string'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import theme from 'theme'
import { ROUTES } from 'consts'
import { useQuery } from 'hooks'
import { withSpinner } from 'hocs'
import {
  hideInvoicesSelector,
  userDataSelector,
} from 'containers/UserInfo/selectors'
import {
  withPageView,
  SCREENS,
  trackFromDateSelected,
  trackToDateSelected,
} from 'services/analytics'
import { H4 } from 'components/Text'
import InfoPanel from 'components/InfoPanel'
import {
  INVOICES_REDUCER_NAME,
  INVOICES_LIST_REDUCER_NAME,
  INVOICES_PER_PAGE_SIZE,
  INVOICE_PAYMENT_STATUS,
} from 'containers/Invoices/consts'
import {
  INVOICE_TYPE,
  INVOICE_TYPE_IDS,
} from 'containers/Invoices/List/Filters'
import { invoicesDateRangeFiltersSelector } from 'containers/Invoices/List/Filters/selectors'
import { selectInvoicesDateRange } from 'containers/Invoices/List/Filters/actions'
import {
  invoicesPaginationSelector,
  invoicesSelector,
  invoicesSelectors,
  selectSelectedInvoices,
} from 'containers/Invoices/List/selectors'

import {
  getInvoicesActions,
  invoicesToPayActions,
} from 'containers/Invoices/List/actions'

import { INVOICES_ROUTES, DEFAULT_INVOICE_TYPE } from '../consts'
import messages from '../messages'

import LayoutWrapper from './components/LayoutWrapper'
import List from './components/InvoicesList'
import NoInvoices from './components/NoInvoices'
import DateRangePickerWrapper from './components/DateRangePicker'
import PaySummary from './components/PaySummary'
import {
  FiltersContainer,
  DateRangePickerLabel,
  DateRangePickerContainer,
  ListWrapper,
} from './styles'

// TODO: remove once there's a decision on what to do with calendar
const IS_FILTER_SHOWN = false

const InvoicesList = props => {
  const hideInvoices = useSelector(hideInvoicesSelector)
  const { formatMessage } = useIntl()
  if (hideInvoices) {
    return (
      <LayoutWrapper>
        <InfoPanel mt={theme.spacing.sm}>
          <H4 breakLines>{formatMessage(messages.hideInvoicesInfo)}</H4>
        </InfoPanel>
      </LayoutWrapper>
    )
  }
  return <InvoicesListAfterGuard {...props} />
}

const InvoicesListAfterGuard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const invoices = useSelector(invoicesSelector)
  const invoicesPagination = useSelector(invoicesPaginationSelector)
  const isInvoicesFetching = useSelector(invoicesSelectors.isFetching)
  const hasInvoicesFetched = useSelector(invoicesSelectors.isFinished)

  const dateRange = useSelector(invoicesDateRangeFiltersSelector)
  const selectedInvoicesMap = useSelector(selectSelectedInvoices)
  const { storeId } = useSelector(userDataSelector)

  const { page, type: queryType, preselectStatus } = useQuery() || {}
  let preselectStatuses = isNil(preselectStatus) ? [] : preselectStatus
  preselectStatuses = Array.isArray(preselectStatuses)
    ? preselectStatuses
    : [preselectStatuses]

  const type = INVOICE_TYPE.has(queryType) ? queryType : DEFAULT_INVOICE_TYPE
  const isPaginated =
    !preselectStatuses.length || type !== INVOICE_TYPE_IDS.TO_PAY

  const lastInvoicesParams = useRef(null)

  const start = dateRange.startDate ? dateRange.startDate.format() : null
  const end = dateRange.endDate ? dateRange.endDate.format() : null
  const invoicesParams = useMemo(
    () => ({
      page: isNumber(page) ? page : 1,
      type,

      dateStart: start,
      endDate: end,

      perPage: isPaginated ? INVOICES_PER_PAGE_SIZE : 200, // 200 - is max supported by middleware
    }),
    [page, type, start, end, isPaginated],
  )

  useEffect(
    () => {
      if (isEqual(lastInvoicesParams.current, invoicesParams)) {
        // query params didn't change, exiting
        return
      }

      lastInvoicesParams.current = invoicesParams
      dispatch(
        getInvoicesActions.delta(invoicesParams, {
          resolve: data => {
            if (preselectStatuses.length) {
              const invoicesToSelect = data.invoices.filter(
                ({ statusId, payment: { status } }) =>
                  status !== INVOICE_PAYMENT_STATUS.PROCESSING &&
                  preselectStatuses.indexOf(statusId) > -1,
              )
              dispatch(
                invoicesToPayActions.selectMultiple({
                  invoices: invoicesToSelect,
                }),
              )
            }
          },
        }),
      )
    },
    [invoicesParams, dateRange, preselectStatus],
  )

  const noInvoices =
    hasInvoicesFetched && !isInvoicesFetching && !invoices.length
  const selectInvoicesType = invoicesType =>
    navigate(
      `${INVOICES_ROUTES.ROOT}?${queryString.stringify({
        type: invoicesType,
        preselectStatus,
      })}`,
    )

  const onDateChange = range => {
    if (dateRange.startDate !== range.startDate) {
      trackFromDateSelected(storeId, range.startDate.format())
    }

    if (dateRange.endDate !== range.endDate) {
      trackToDateSelected(storeId, range.endDate.format())
    }

    dispatch(selectInvoicesDateRange(range))
  }

  return (
    <LayoutWrapper
      selectedTab={invoicesParams.type}
      onTabSelect={selectInvoicesType}
    >
      {/* disabled as per 6785, maybe will be returned later */}
      {IS_FILTER_SHOWN && (
        <FiltersContainer bordered={!!invoices.length}>
          <DateRangePickerContainer data-test-id="date-range-picker">
            <DateRangePickerLabel>
              {formatMessage(messages.dateRangeLabel)}
            </DateRangePickerLabel>
            <DateRangePickerWrapper
              {...{ dateRange }}
              onChange={range => onDateChange(range)}
            />
          </DateRangePickerContainer>
        </FiltersContainer>
      )}

      <ListWrapper {...{ noInvoices }}>
        {noInvoices ? (
          <NoInvoices
            invoicesType={invoicesParams.type}
            onGoToCatalog={() => navigate(ROUTES.DASHBOARD)}
            onSelectAll={() => selectInvoicesType(INVOICE_TYPE_IDS.ALL)}
          />
        ) : (
          <List
            pagination={invoicesPagination}
            isFetching={isInvoicesFetching}
            onPageChange={nextPage =>
              navigate(
                `${INVOICES_ROUTES.ROOT}?${queryString.stringify({
                  type,
                  preselectStatus,
                  page: nextPage,
                })}`,
              )
            }
            {...{ invoices, isPaginated }}
          />
        )}

        <PaySummary {...{ selectedInvoicesMap }} />
      </ListWrapper>
    </LayoutWrapper>
  )
}

export default compose(
  withPageView(SCREENS.INVOICES),
  withSpinner([[INVOICES_REDUCER_NAME, INVOICES_LIST_REDUCER_NAME]]),
)(InvoicesList)
