import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { signUpMigrateErrorCodeSelector } from 'containers/Auth'
import { SIGNUP_MIGRATE_ERRORS } from 'containers/Auth/consts'

import Notification from '../Notification'
import signUpMessages from '../SignUp/messages'
import messages from './messages'

const Failure = () => {
  const signUpMigrateErrorCode = useSelector(signUpMigrateErrorCodeSelector)
  const isExpired = signUpMigrateErrorCode === SIGNUP_MIGRATE_ERRORS.EXPIRED

  return (
    <Notification
      header={
        isExpired ? messages.expiredTitle : signUpMessages.somethingWentWrong
      }
      width={490}
      height={270}
      spacedVertical
    >
      <FormattedMessage
        {...(isExpired ? messages.expiredMsg : messages.errorMsg)}
      />
    </Notification>
  )
}

export default Failure
