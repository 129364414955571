import { createSelector } from 'reselect'

import {
  makeDataSelector,
  makeIsFinishedSelector,
  makeIsFetchingSelector,
} from 'utils/redux-utils'

import { PRODUCT_DETAILS_REDUCER_NAME } from './consts'

export const selectData = makeDataSelector(PRODUCT_DETAILS_REDUCER_NAME)

export const productDetailsSelector = createSelector(
  selectData,
  data => data || {},
)

export const productDetailsIsFetched = makeIsFinishedSelector(
  PRODUCT_DETAILS_REDUCER_NAME,
)

export const productDetailsFetchingSelector = makeIsFetchingSelector(
  PRODUCT_DETAILS_REDUCER_NAME,
)
