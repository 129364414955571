import allPromosMobile from './assets/all_promos_mobile.png'
import allPromosWeb from './assets/all_promos_web.png'
import clearances from './assets/clearances.png'

export const PROMOTIONS_ROUTES = {
  ROOT: '/promo',
}

export const STATIC_GROUP_PROPS = {
  ALL_PROMOS: {
    title: 'Wszystkie promocje',
    shortDescription:
      'Przeglądaj według kategorii wszystkie produkty dostępne w promocjach.',
    picture: {
      urlMobile: allPromosMobile,
      urlWeb: allPromosWeb,
    },
    tag: 'Stała kategoria',
  },

  CLEARANCES: {
    title: 'Wszystkie wyprzedaże',
    shortDescription:
      'Korzystaj z okazji cenowych! Na wyprzedaży znajdziesz produkty, które wkrótce znikną z naszej oferty lub takie, które maja krótszą datę przydatności.',
    picture: {
      urlMobile: clearances,
      urlWeb: clearances,
    },
    tag: 'Stała kategoria',
  },
}
