import React from 'react'

const Breakfast = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4527 22.0001C18.6582 22.0001 19.8143 21.4996 20.6668 20.6088C21.5192 19.718 21.9981 18.5098 21.9981 17.2501L21.9981 7.75006C21.9981 6.75312 21.6979 5.78145 21.1401 4.97269C20.5823 4.16393 19.7951 3.55908 18.8901 3.24382C17.985 2.92855 17.008 2.91887 16.0973 3.21612C15.1867 3.51338 14.3887 4.10251 13.8163 4.90006L3.81629 4.90006C3.33408 4.90006 2.87162 5.10024 2.53064 5.45656C2.18966 5.81288 1.99811 6.29615 1.99811 6.80006L1.99811 18.2001C1.99811 18.704 2.18966 19.1872 2.53064 19.5436C2.87162 19.8999 3.33408 20.1001 3.81629 20.1001L13.8163 20.1001C14.2387 20.6911 14.7875 21.1707 15.4189 21.5006C16.0503 21.8305 16.7467 22.0015 17.4527 22.0001Z"
      fill="#E39600"
    />
    <path
      d="M17.4527 19.0001C18.6582 19.0001 19.8143 18.5523 20.6668 17.7553C21.5192 16.9582 21.9981 15.8772 21.9981 14.7501L21.9981 6.25006C21.9981 5.35806 21.6979 4.48867 21.1401 3.76504C20.5823 3.04141 19.7951 2.50023 18.8901 2.21816C17.985 1.93608 17.008 1.92741 16.0973 2.19338C15.1867 2.45934 14.3887 2.98646 13.8163 3.70006L3.81629 3.70006C3.33408 3.70006 2.87162 3.87917 2.53064 4.19798C2.18966 4.51679 1.99811 4.94919 1.99811 5.40006L1.99811 15.6001C1.99811 16.0509 2.18966 16.4833 2.53064 16.8021C2.87162 17.1209 3.33408 17.3001 3.81629 17.3001L13.8163 17.3001C14.2387 17.8289 14.7875 18.258 15.4189 18.5532C16.0503 18.8483 16.7467 19.0014 17.4527 19.0001Z"
      fill="#FEEFC2"
    />
    <rect x="4" y="5" width="16" height="11" rx="3" fill="#FFC7B7" />
    <rect x="6" y="6" width="6" height="6" rx="3" fill="#F8312F" />
    <rect x="12" y="9" width="6" height="6" rx="3" fill="#F8312F" />
  </svg>
)

export default Breakfast
