import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box, Flex } from '@rebass/grid'

import { formatPrice, useRichFormatMessage } from 'utils'
import { ArrowDown, Truck, XNormal } from 'components/Icons'
import { TinyText } from 'components/Text'

import messages from 'views/Cart/messages'
import { ScenarioDescr } from '../../components/PayScenario/styles'

function DefaultHeader({
  isExpanded,
  setIsExpanded,
  isLogisticLackAmount,
  firstDeliveryLogisticLackAmount,
  deliveriesCount,
}) {
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()

  const amount = useMemo(() => formatPrice(firstDeliveryLogisticLackAmount), [
    firstDeliveryLogisticLackAmount,
  ])
  const deliveriesLabel = formatMessage(messages.deliveriesCountGenitive, {
    number: deliveriesCount,
  })

  return (
    <>
      <Flex alignItems="center">
        <Box mr={24}>
          <Truck />
        </Box>

        {isLogisticLackAmount && !isExpanded ? (
          <ScenarioDescr>
            <b>{formatMessage(messages.lacksToFreeDelivery, { amount })} </b>
            {formatMessage(messages.meetLogisticLimitInfo)}
          </ScenarioDescr>
        ) : (
          <TinyText>
            {formatRichMessage(messages.receiveDeliveriesShort, {
              deliveriesCount,
              deliveriesLabel,
            })}
          </TinyText>
        )}
      </Flex>

      {isExpanded ? (
        <XNormal
          onClick={e => {
            e.stopPropagation()
            setIsExpanded(false)
          }}
        />
      ) : (
        <ArrowDown width={18} />
      )}
    </>
  )
}

export default DefaultHeader
