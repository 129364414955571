import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

export const TemplateSortingContainer = styled(Flex)`
  flex-shrink: 0;
  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding-right: 8px;
    margin-right: 56px;
    border-right: 1px solid ${theme.colors.borderFaded};
  }
`

export const SortingDropdownContainer = styled(Flex)`
  flex-direction: column;
  padding: 16px;
`
