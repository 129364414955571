import React from 'react'
import { FormattedMessage } from 'react-intl'

import { makeMessages, makeTranslation, FormattedRichMessage } from 'utils'

import calendarImg from 'assets/hintsImgs/calendar1.png'
import commentsImg from 'assets/hintsImgs/comments.png'

const dateMessages = makeMessages(
  {
    header: 'header1',
    info1: 'info1',
  },
  makeTranslation('hintModal.dates'),
)

const dateHeader = <FormattedMessage {...dateMessages.header} />

export const dateHintModalConfig = [
  {
    header: dateHeader,
    img: calendarImg,
    info: <FormattedRichMessage {...dateMessages.info1} />,
  },
]

const commentMessages = makeMessages(
  {
    header: 'header',
    info: 'info',
  },
  makeTranslation('hintModal.comments'),
)

const commentHeader = <FormattedMessage {...commentMessages.header} />

export const commentHintModalConfig = [
  {
    header: commentHeader,
    img: commentsImg,
    info: <FormattedRichMessage {...commentMessages.info} />,
  },
]
