import { formatFiltersParams } from '../utils'

export const getFilters = ({
  templateId,
  category,
  group,
  subgroup,
  search,
  selectedFiltersParams,
}) => {
  let params = {}
  if (templateId) params = { template_id: templateId }
  if (category) params = { category_id: category }
  if (group) params = { group_id: group }
  if (subgroup) params = { subgroup_id: subgroup }
  if (search) params = { ...params, search }
  if (selectedFiltersParams) params = { ...params, ...selectedFiltersParams }

  const queryParams = formatFiltersParams(params)

  return {
    url: `me/products/filters?${queryParams}`,
  }
}
