import styled from 'styled-components'

import theme from 'theme'

import { HiddenInput, visibleStyles, hiddenStyles } from '../styles'

export const HiddenRadio = styled(HiddenInput).attrs({ type: 'radio' })``

export const StyledChecked = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 10px;
  margin-top: 5px;
  margin-left: 5px;
  background-color: ${theme.colors.secondary};
`

export const StyledRadio = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid ${theme.colors.gray3};
  border-radius: 14px;

  ${StyledChecked} {
    ${p => (p.checked ? visibleStyles : hiddenStyles)};
  }
`

export const RadioContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`
