import styled, { css } from 'styled-components'

import { BaseTextV2Secondary, TinyTextV2Secondary, H4v2 } from 'components/Text'
import { getSpacing } from 'components/helpers/theme-selectors'
import theme from 'theme'
import { Flex } from '@rebass/grid'
import { INVOICES_DESKTOP_START } from 'views/Invoices/consts'
import { CheckboxLabel, CheckboxWrapper } from 'components/Input/styles'

const InvoiceListItemContainer = styled.div`
  display: flex;

  @media (min-width: ${INVOICES_DESKTOP_START}) {
    // max possible width of each container
    width: 426px;
  }
`

export const PricesContainer = styled(Flex)`
  flex-shrink: 1;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    flex-direction: row;
    min-width: 0;
    justify-content: flex-end;
    gap: 0;
  }
`

const hintStyles = css`
  display: flex;
  align-items: center;
  gap: 2px ${theme.spacing.xs};
  ${p => p.$isDisabled && `color: ${theme.colors.gray5}`};

  span.bp5-popover-target {
    width: auto;
  }
`

export const DesktopHint = styled(BaseTextV2Secondary).attrs({
  isSmall: { lineHeight: true },
})`
  ${hintStyles};
  flex-wrap: wrap;
`

export const MobileHint = styled(TinyTextV2Secondary)`
  ${hintStyles};
  flex-wrap: wrap;
`

const priceHintStyles = css`
  ${p => p.$color && `color: ${p.$color}`};
  text-align: right;
  justify-content: flex-end;
`

export const DesktopPriceHint = styled(DesktopHint)`
  ${priceHintStyles};
`

export const MobilePriceHint = styled(MobileHint)`
  ${priceHintStyles};
`

// prettier-ignore
export const Container = styled.div`
  padding: ${theme.spacing.sm} ${theme.spacing.xs} ${theme.spacing.sm} ${theme.spacing.sm};
  cursor: ${p => (p.$isSelectable ? 'pointer' : 'auto')};
  display: flex;
  gap: ${theme.spacing.sm};
  justify-content: space-between;
  align-items: center;
  ${p => p.$isSelected && `background-color: ${theme.colors.lightGrey}`};
  &:hover {
    ${p => p.$isSelectable && `
      background-color: ${theme.colors.lightGrey};
      ${CheckboxLabel} {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        background-color: ${theme.colors.white};
      }
    `};
  }

  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    padding: ${theme.spacing.sm};
    border-radius: ${theme.borderRadius.base};
    gap: ${theme.spacing.ss};
  }

  // disable hover color for checkbox
  ${CheckboxWrapper} {
    :hover {
      input:checked + ${CheckboxLabel}::before {
        background-color: ${theme.colors.secondary};
      }
    }
  }
`

export const InvoiceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.xxs};
  align-items: flex-start;
  align-self: stretch;
  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
`

export const AmountColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${theme.spacing.xxs};
  @media (min-width: ${INVOICES_DESKTOP_START}) {
    ${p => p.limitWidth && 'width: 140px'};
  }
`

export const RemainingAmountColumn = styled(AmountColumn)`
  display: none;
  padding-right: ${p => getSpacing(p).base};
  margin-right: ${theme.spacing.base};
  border-right: solid 1px #dde2e5;

  @media (min-width: ${INVOICES_DESKTOP_START}) {
    display: flex;
  }
`

export const MobileRemainingAmountRow = styled(AmountColumn)`
  @media (min-width: 640px) {
    display: none;
  }
`

export const Heading = styled(H4v2)`
  ${p => p.$isDisabled && `color: ${theme.colors.gray7};`}
  @media screen and (min-width: ${INVOICES_DESKTOP_START}) {
    font-size: ${theme.textSizes.l};
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

export const InvoicePriceContainer = styled(InvoiceListItemContainer)`
  justify-content: flex-end;
  gap: ${theme.spacing.xs};

  @media (min-width: ${INVOICES_DESKTOP_START}) {
    gap: ${theme.spacing.ss};
  }
`

export const InvoiceInfoOuterContainer = styled(InvoiceListItemContainer)`
  gap: ${theme.spacing.ss};
  align-items: center;

  ${p =>
    p.$isAlignedTop &&
    `
    margin-bottom: auto;
  `};
`

export const InvoiceInfoMiddleContainer = styled(InvoiceListItemContainer)`
  display: none;

  @media (min-width: ${INVOICES_DESKTOP_START}) {
    display: flex;
    align-self: flex-end;

    span.bp5-popover-target {
      width: auto;
    }
  }
`

export const PaymentNumberText = styled(TinyTextV2Secondary)`
  @media (min-width: ${INVOICES_DESKTOP_START}) {
    font-size: ${theme.textSizes.sm};
    line-height: 20px;
  }
`

export const InvoiceDateContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xxs};
  align-items: center;
  cursor: pointer;
`

export const InlineSeparator = styled.span`
  display: none;

  @media (min-width: ${INVOICES_DESKTOP_START}) {
    display: flex;
  }
`

export const PaymentDetailsContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.sm};

  @media (min-width: ${INVOICES_DESKTOP_START}) {
    display: none;
  }
`

export const PaymentDetailsInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.xs};
  align-items: center;
  cursor: pointer;
`
