import React, { useCallback, useEffect } from 'react'
import { compose } from 'lodash/fp'
import { reduxForm, Form, Field } from 'redux-form/immutable'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { withSpinner } from 'hocs'
import {
  AUTH_REDUCER_NAME,
  CHECK_LOGIN_REDUCER_NAME,
  SIGNUP_MIGRATE_FORM_NAME,
  SIGN_UP_MIGRATE_REDUCER_NAME,
  isSignUpMigrateFetching,
  signUpMigrateActions,
} from 'containers/Auth'
import { RECAPTCHA_KEY, ROUTES } from 'consts'
import { PrimaryBtn } from 'components/Button'
import { Password, CheckBoxComponent, Recaptcha } from 'components/Fields'
import BackButton from 'components/Back'
import { useQuery } from 'hooks'

import {
  PageLayout,
  FormWrapper,
  FormSection,
  FormSectionHeading,
  Description,
  Href,
} from '../SignUp/styledComponents'
import signUpMessages from '../SignUp/messages'
import { Input, MARKETING_CHECKBOXES } from '../SignUp/fields'
import CheckBoxText from '../SignUp/CheckBoxText'
import { asyncValidate } from '../SignUp/utils'

import messages from './messages'
import validate from './validate'
import { PrimaryTitle, SectionDesc, AgreementSection } from './styles'

const SignUpMigrate = ({ handleSubmit, change }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(isSignUpMigrateFetching)
  const navigate = useNavigate()
  const { token } = useParams()

  const { formatMessage } = useIntl()

  const { login_suggestion: loginSuggestion } = useQuery()
  useEffect(
    () => {
      change('login', loginSuggestion || '')
    },
    [loginSuggestion],
  )

  const onSubmit = useCallback(
    values => {
      dispatch(
        signUpMigrateActions.delta(
          { values, token },
          {
            resolve: () => navigate('/migrate/success', { replace: true }),
            reject: () => navigate('/migrate/failure', { replace: true }),
          },
        ),
      )
    },
    [token, navigate],
  )

  return (
    <PageLayout>
      {window.history.length > 2 && <BackButton />}

      <FormWrapper>
        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <PrimaryTitle>{formatMessage(messages.title)}</PrimaryTitle>

          <FormSection>
            <FormSectionHeading>
              {formatMessage(signUpMessages.login)}
            </FormSectionHeading>
            <SectionDesc>{formatMessage(messages.loginDesc)}</SectionDesc>

            <Input
              dataAttr="migration_login"
              label={formatMessage(messages.loginInput)}
              name="login"
              required
            />
          </FormSection>

          <FormSection>
            <FormSectionHeading>
              {formatMessage(messages.pass)}
            </FormSectionHeading>
            <SectionDesc mb>{formatMessage(messages.passDesc)}</SectionDesc>

            <Password
              stretch
              label={formatMessage(messages.pass)}
              dataAttr="migration_pass"
              hint={formatMessage(messages.passHint)}
            />
          </FormSection>

          <FormSection>
            <FormSectionHeading>
              {formatMessage(messages.agreements)}
            </FormSectionHeading>
            <AgreementSection>
              <Field
                key="agreement_1"
                name="agreement_1"
                required
                component={CheckBoxComponent}
                alignItems="flex-start"
                title={formatMessage(signUpMessages.agreement_1, {
                  termsLink: (
                    <Href href={ROUTES.TERMS}>
                      {formatMessage(signUpMessages.termsLink)}
                    </Href>
                  ),
                  privacyLink: (
                    <Href href={ROUTES.PRIVACY}>
                      {formatMessage(signUpMessages.privacyLink)}
                    </Href>
                  ),
                })}
              />
            </AgreementSection>

            <Field
              key="agreement_2"
              name="agreement_2"
              component={CheckBoxComponent}
              alignItems="flex-start"
              title={<CheckBoxText name="agreement_2" />}
            />

            <Description mb>
              {formatMessage(signUpMessages.acceptMarketing)}
            </Description>
            {MARKETING_CHECKBOXES.map(({ name, required }) => (
              <Field
                key={name}
                component={CheckBoxComponent}
                {...{ name, required }}
                alignItems="center"
                title={<CheckBoxText {...{ name, required }} />}
              />
            ))}

            <Recaptcha name="recaptcha" siteKey={RECAPTCHA_KEY} />
          </FormSection>

          <PrimaryBtn
            type="submit"
            disabled={isFetching}
            data-test-id="migration_cta_btn"
          >
            {formatMessage(messages.ctaBtn)}
          </PrimaryBtn>
        </Form>
      </FormWrapper>
    </PageLayout>
  )
}

export default compose(
  reduxForm({
    form: SIGNUP_MIGRATE_FORM_NAME,
    validate,
    asyncValidate,
    asyncBlurFields: ['login'],
    initialValues: {
      agreement_2: false,
      agreement_3: false,
      agreement_4: false,
    },
  }),
  withSpinner([
    [AUTH_REDUCER_NAME, CHECK_LOGIN_REDUCER_NAME],
    [AUTH_REDUCER_NAME, SIGN_UP_MIGRATE_REDUCER_NAME],
  ]),
)(SignUpMigrate)
