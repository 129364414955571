import { FILTERS_TYPES, FILTERS_VALUES } from 'components/Filters/consts'
import { trackSimpleEvent } from './utils'

export const FILTER_EVENTS = {
  promotion: 'selected_promos_only',
  clearance: 'selected_clearances',
  onStock: 'selected_available_only',
  brands: 'selected_brand_filtering',
  productFeatures: 'selected_product_features_filtering',
  cuisineFeatures: 'selected_cuisine_filtering',
  storageTypes: 'Selected storage filtering',
  brandsOpen: 'clicked_brand_filtering',
  productFeaturesOpen: 'clicked_product_features_filtering',
  cuisineFeaturesOpen: 'clicked_cuisine_filtering',
  category: 'selected_category_filtering',
  bestseller: 'selected_bestsellers',
  novelty: 'selected_novelties',
  bidmarket: 'selected_bidmarket',
  recommended: 'selected_recommended',
  clickOfferFeatures: 'clicked_label_filtering',
  clickedRecommended: 'clicked_recommended',
  clickedPromotions: 'clicked_promotions',
  clickedBestsellers: 'clicked_bestsellers',
  clickedNovelties: 'clicked_novelties',
  clickedClearances: 'clicked_clearances',
  clickedCuisine: 'clicked_cuisine',
}

export const trackBrandsFilterEvent = filterValues => {
  window.dataLayer.push({
    event: FILTER_EVENTS.brands,
    brands: Object.keys(filterValues),
  })
}

export const trackProductFeaturesFilterEvent = filterValues => {
  window.dataLayer.push({
    event: FILTER_EVENTS.productFeatures,
    productFeatures: Object.keys(filterValues),
  })
}

export const trackCuisineFeaturesFilterEvent = filterValues => {
  window.dataLayer.push({
    event: FILTER_EVENTS.cuisineFeatures,
    cuisine: Object.keys(filterValues).join(', '),
  })
}

export const trackStorageTypeFilterEvent = filterValues => {
  window.dataLayer.push({
    event: FILTER_EVENTS.storageTypes,
    appliedFilters: Object.keys(filterValues),
  })
}

export const trackOfferFeaturesFilterEvent = (filterValues, featuresCounts) => {
  const {
    BESTSELLER: bestsellerFilterValue,
    NOVELTY: noveltyFilterValue,
    BIDMARKET: bidmarketFilterValue,
    RECOMMENDATION: recommendedFilterValue,
  } = FILTERS_VALUES.OFFER_FEATURES

  if (filterValues[bestsellerFilterValue]) {
    window.dataLayer.push({
      event: FILTER_EVENTS.bestseller,
      totalCount: featuresCounts[bestsellerFilterValue],
    })
  }

  if (filterValues[noveltyFilterValue]) {
    window.dataLayer.push({
      event: FILTER_EVENTS.novelty,
      totalCount: featuresCounts[noveltyFilterValue],
    })
  }

  if (filterValues[bidmarketFilterValue]) {
    window.dataLayer.push({
      event: FILTER_EVENTS.bidmarket,
      totalCount: featuresCounts[bidmarketFilterValue],
    })
  }

  if (filterValues[recommendedFilterValue]) {
    window.dataLayer.push({
      event: FILTER_EVENTS.recommended,
      totalCount: featuresCounts[recommendedFilterValue],
    })
  }
}

export const trackCatalogFilters = ({
  filterName,
  filterValues,
  eventValues,
}) => {
  if (!Object.keys(filterValues).length || filterValues.all) return

  const {
    ON_STOCK,
    PROMOTION,
    BRANDS,
    STORAGE_TYPES,
    PRODUCT_FEATURES,
    CUISINE_FEATURES,
    OFFER_FEATURES,
    CLEARANCE,
  } = FILTERS_TYPES

  switch (filterName) {
    case ON_STOCK:
      trackSimpleEvent(FILTER_EVENTS.onStock)
      break
    case PROMOTION:
      trackSimpleEvent(FILTER_EVENTS.promotion)
      break
    case CLEARANCE:
      trackSimpleEvent(FILTER_EVENTS.clearance)
      break
    case BRANDS:
      trackBrandsFilterEvent(filterValues)
      break
    case PRODUCT_FEATURES:
      trackProductFeaturesFilterEvent(filterValues)
      break
    case CUISINE_FEATURES:
      trackCuisineFeaturesFilterEvent(filterValues)
      break
    case STORAGE_TYPES:
      trackStorageTypeFilterEvent(filterValues)
      break
    case OFFER_FEATURES:
      trackOfferFeaturesFilterEvent(filterValues, eventValues)
      break
    default:
  }
}

export const trackCatalogCategory = category => {
  window.dataLayer.push({
    event: FILTER_EVENTS.category,
    category,
  })
}

export const trackBrandFilteringOpen = () => {
  window.dataLayer.push({
    event: FILTER_EVENTS.brandsOpen,
  })
}

export const trackProductFeatureFilteringOpen = () => {
  window.dataLayer.push({
    event: FILTER_EVENTS.productFeaturesOpen,
  })
}

export const trackOfferFeaturesOpen = () => {
  window.dataLayer.push({
    event: FILTER_EVENTS.clickOfferFeatures,
  })
}

export const trackCuisineFeatureFilteringOpen = () => {
  window.dataLayer.push({
    event: FILTER_EVENTS.cuisineFeaturesOpen,
  })
}
