import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { TEMPLATE_TYPES } from 'views/Templates/consts'
import { notifySuccess } from 'components/Product/utils'
import {
  templatesActions,
  deleteProductFromTemplate,
  addProductToTemplate,
} from 'containers/Templates/actions'
import { selectTemplatesFinished } from 'containers/Templates'
import CreateTemplate from 'views/Templates/TemplatesList/CreateTemplate'
import ListInput from 'components/Input/ListInput'
import Loader from 'components/Loader'
import {
  findUnitData,
  trackAddedToTemplate,
  trackRemovedFromTemplate,
} from 'services/analytics'
import { userDataSelector } from 'containers/UserInfo/selectors'
import TemplateModalListItem from './TemplateModalListItem'

import messages from '../messages'
import {
  ListContainer,
  CreateTemplateContainer,
  InputContainer,
  ScrollableListContainer,
} from './styles'

const TemplateModalList = ({
  templates,
  unitOfMeasure,
  itemListId,
  itemListName,
  itemModelId,
  listIndex,
  product,
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [isInputOpen, setInputOpen] = useState(false)
  const isTemplatesFinished = useSelector(selectTemplatesFinished)
  const { customerNo } = useSelector(userDataSelector)
  const isLoader = !isTemplatesFinished && templates.length === 0

  // TODO: find a way to get path in modals?
  const { pathname } = useLocation()

  const scrollableList = useRef(null)

  const openInput = () => {
    setInputOpen(true)
    scrollableList.current.scrollTo(0, 0)
  }

  const closeInput = () => setInputOpen(false)
  const createTemplate = (data, additionalData) => {
    dispatch(templatesActions.createDelta(data, additionalData))
  }

  const notifyTemplateSuccess = msg =>
    dispatch(notifySuccess(formatMessage(msg)))

  const addToTemplateSuccess = () =>
    notifyTemplateSuccess(messages.addedNotification)
  const removeFromTemplateSuccess = () =>
    notifyTemplateSuccess(messages.removedNotification)

  const addToTemplateHandler = params => {
    dispatch(
      addProductToTemplate(
        { ...params, path: pathname, quantity: 1 },
        {
          onSuccess: () => {
            addToTemplateSuccess()

            trackAddedToTemplate({
              product,
              unitData: findUnitData(product.unitsOfMeasure, unitOfMeasure),
              itemListId,
              itemListName,
              itemModelId,
              customerNo,
              listIndex,
            })
          },
        },
      ),
    )
  }

  const removeFromTemplateHandler = params =>
    dispatch(
      deleteProductFromTemplate(
        { ...params, path: pathname },
        {
          onSuccess: () => {
            removeFromTemplateSuccess()

            trackRemovedFromTemplate(product.id, params.template.id)
          },
        },
      ),
    )

  const onCreateTemplateSuccess = createdTemplate => {
    closeInput()
    addToTemplateHandler({
      template: createdTemplate,
      productId: product.id,
      unitOfMeasure,
    })
    notifyTemplateSuccess(messages.createNotification)
  }

  return (
    <ListContainer>
      <CreateTemplateContainer>
        <CreateTemplate
          onClick={openInput}
          testId="template-modal-create-template"
        />
      </CreateTemplateContainer>
      <ScrollableListContainer scrollableNodeProps={{ ref: scrollableList }}>
        {isInputOpen && (
          <InputContainer>
            <ListInput
              onSubmit={createTemplate}
              onClose={closeInput}
              onSuccess={onCreateTemplateSuccess}
              inputTestId="template-modal-create-template-input"
              actionTestId="template-modal-create-template-action"
              closeBtnTestId="template-modal-create-template-close"
            />
          </InputContainer>
        )}
        {isLoader ? (
          <Loader />
        ) : (
          templates.map(
            template =>
              template.type === TEMPLATE_TYPES.CUSTOMER ? (
                <TemplateModalListItem
                  key={template.id}
                  productId={product.id}
                  {...{
                    template,
                    unitOfMeasure,
                  }}
                  onAddToTemplate={addToTemplateHandler}
                  onRemoveFromTmplate={removeFromTemplateHandler}
                />
              ) : null,
          )
        )}
      </ScrollableListContainer>
    </ListContainer>
  )
}

export default TemplateModalList
