import styled from 'styled-components'

import { BaseTextV2 } from 'components/Text'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
`

export const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  gap: 4px;
`

export const SectionTitle = styled(BaseTextV2)`
  margin-bottom: 24px;
  font-weight: 500;
`
