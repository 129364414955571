import { fromJS } from 'immutable/dist/immutable'

import { TOOLTIP_OPEN, TOOLTIP_CLOSE } from '../actions/tooltip'

const initialState = fromJS({
  isOpen: false,
  id: null,
})

const tooltipReducer = (state = initialState, action) => {
  const { data } = action

  switch (action.type) {
    case TOOLTIP_OPEN: {
      return state.set('isOpen', true).set('id', data.id)
    }
    case TOOLTIP_CLOSE: {
      return state.set('isOpen', false).set('id', null)
    }
    default:
      return state
  }
}

export default tooltipReducer
