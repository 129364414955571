import * as React from 'react'

import theme from 'theme'

const CalendarChange = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M3 7a3 3 0 013-3h12a3 3 0 013 3v6a3 3 0 01-3 3h-.5a2.5 2.5 0 00-2.5 2.5v0a2.5 2.5 0 01-2.5 2.5H6a3 3 0 01-3-3V7z"
      stroke={color}
      strokeWidth={2}
    />
    <path
      d="M4 9h16M8 5V3M16 5V3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M12 21c5.4 0 9-3.2 9-8"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={8} cy={13} r={1} fill={color} />
    <circle cx={8} cy={17} r={1} fill={color} />
    <circle cx={12} cy={13} r={1} fill={color} />
    <circle cx={12} cy={17} r={1} fill={color} />
    <circle cx={16} cy={13} r={1} fill={color} />
  </svg>
)

export default CalendarChange
