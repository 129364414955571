import { createActions } from 'redux-actions'
import { identity } from 'lodash/fp'

import { createActionsGroup } from 'utils/redux-utils'

export const getInvoicesActions = createActionsGroup('GET_INVOICES')

export const invoicesToPayActions = createActions({
  TOGGLE_SELECTED: identity,
  CLEAR_SELECTED: identity,
  SELECT_MULTIPLE: identity,
})

export const getOverpaymentInvoicesActions = createActionsGroup(
  'GET_OVERPAYMENT_INVOICES',
)

export const downloadInvoiceActions = createActionsGroup('DOWNLOAD_INVOICE')
