export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'
export const MODAL_CLOSE_ALL = 'MODAL_CLOSE_ALL'

export const openModal = (type, data, resolve) => ({
  type: MODAL_OPEN,
  payload: {
    type,
    data,
    resolve,
  },
})

export const closeModal = type => ({
  type: MODAL_CLOSE,
  payload: {
    type,
  },
})

export const closeAllModals = () => ({
  type: MODAL_CLOSE_ALL,
})
