import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { range, toString } from 'lodash/fp'
import { reduxForm, Field } from 'redux-form/immutable'
import { fromJS } from 'immutable'

import { makeSchemaValidator } from 'utils/validators'
import { P } from 'components/Text'
import Radio from 'components/Input/Radio'
import Textarea from 'components/Input/Textarea'
import { PrimaryBtn, BUTTON_SIZE } from 'components/Button'
import { sendNewsletterFeedbackActions } from 'containers/Profile/Newsletter/actions'
import { GenericField } from 'components/Fields'
import { formValuesSelector } from 'utils/redux-form-utils'

import Panel from '../../components/Panel'
import messages from '../messages'

import { Header } from '../../components/styledComponents'

import validationSchema from './validationSchema'
import { NEWSLETTER_FEEDBACK_FORM_NAME } from './consts'
import {
  OptionsHeader,
  Option,
  Options,
  OptionsInnderContainer,
} from './styles'

const optionsRange = range(0, 5)

const RadioField = ({ input, meta, ...rest }) => <Radio {...input} {...rest} />

const Feedback = ({ handleSubmit }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const formValues = useSelector(
    formValuesSelector(NEWSLETTER_FEEDBACK_FORM_NAME),
  )

  const formatOptionMsg = index =>
    formatMessage(messages[`feedbackOption${index + 1}`])
  const submitNewsletterFeedback = values => {
    const reasonComment = values.get('reasonComment')
    const reasonIndex = +values.get('reasonIndex')

    dispatch(
      sendNewsletterFeedbackActions.delta({
        reason:
          reasonIndex === optionsRange.length - 1
            ? reasonComment
            : formatOptionMsg(reasonIndex),
      }),
    )
  }

  return (
    <Panel>
      <Header>{formatMessage(messages.feedbackTitle)}</Header>

      <P>{formatMessage(messages.feedbackMessage)}</P>

      <form onSubmit={handleSubmit(submitNewsletterFeedback)}>
        <Options>
          <OptionsHeader>
            <P strongBold>{formatMessage(messages.feedbackOptions)}</P>
          </OptionsHeader>

          <OptionsInnderContainer>
            {optionsRange.map(optionIndex => (
              <Option key={optionIndex}>
                <Field
                  id={toString(optionIndex)}
                  name="reasonIndex"
                  component={RadioField}
                  type="radio"
                  value={toString(optionIndex)}
                  label={formatOptionMsg(optionIndex)}
                  testId={`nslUnsubscribeReason${optionIndex + 1}`}
                />
              </Option>
            ))}
          </OptionsInnderContainer>

          <GenericField
            component={Textarea}
            name="reasonComment"
            data-test-id="unsubscribeReasonTextarea"
            width="100%"
            disabled={+formValues.reasonIndex !== optionsRange.length - 1}
          />
        </Options>

        <PrimaryBtn
          type="submit"
          size={BUTTON_SIZE.BLOCK}
          data-test-id="unsubscribeSendAnswer"
        >
          {formatMessage(messages.feedbackCtaBtn)}
        </PrimaryBtn>
      </form>
    </Panel>
  )
}

const feedbackValidator = makeSchemaValidator(validationSchema)

export default reduxForm({
  initialValues: fromJS({
    reasonIndex: '0',
  }),
  form: NEWSLETTER_FEEDBACK_FORM_NAME,
  validate: values =>
    +values.get('reasonIndex') === optionsRange.length - 1
      ? feedbackValidator(values)
      : undefined,
})(Feedback)
