import * as React from 'react'

import theme from 'theme'

const Comment = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M13 19l.707-.707A1 1 0 0013 18v1zm4 4l-.707.707A1 1 0 0018 23h-1zm0-4v-1a1 1 0 00-1 1h1zM4 8a2 2 0 012-2V4a4 4 0 00-4 4h2zm0 8V8H2v8h2zm2 2a2 2 0 01-2-2H2a4 4 0 004 4v-2zm7 0H6v2h7v-2zm-.707 1.707l4 4 1.414-1.414-4-4-1.414 1.414zM18 23v-4h-2v4h2zm0-5h-1v2h1v-2zm2-2a2 2 0 01-2 2v2a4 4 0 004-4h-2zm0-8v8h2V8h-2zm-2-2a2 2 0 012 2h2a4 4 0 00-4-4v2zM6 6h12V4H6v2z"
      fill={color}
    />
    <path
      d="M7 10h8M7 14h4"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
)

export default Comment
