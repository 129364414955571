import React, { useMemo } from 'react'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { useDeleteProductHandler, useOfMaxWidth } from 'hooks'
import theme from 'theme'
import {
  ITEM_LIST_IDS,
  ITEM_LIST_NAMES,
  useSelectItemTracking,
} from 'services/analytics'
import { userDataSelector } from 'containers/UserInfo/selectors'

import { TinyText } from 'components/Text'
import { ICONS, ICONS_POSITION } from 'components/Icon'
import { BUTTON_SIZE } from 'components/Button'
import { APP_BREAKPOINTS } from 'consts'
import { DATE_TIME_FORMATS } from 'utils/datetime'

import productContainer from 'containers/components/Product/ButtonsContainer'
import { getProductGenericInfo, isAvailableAfter } from '../../utils'
import {
  ContentWrapper,
  Prices,
  ProductWrapper,
  InfoIconContainer,
  ProductNameStyled,
  BrandTxt,
} from '../styledComponents'
import ImageAndCtas from '../ImageAndCtas'
import { TrashBtn } from '../ActionButtons/styles'
import messages from '../messages'

import { BrandNameContainer, CtaWrapper, ReplacementsButton } from './styles'

const InactiveProduct = ({
  product,
  product: { active, brand, name, closestDeliveryTime },
  productId,
  unitOfMeasure,
  units,
  getReplacements,
  index,
}) => {
  const { formatMessage } = useIntl()
  const handleDeleteProduct = useDeleteProductHandler({ product })
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const { productPath } = useMemo(
    () =>
      getProductGenericInfo({
        product,
        unitOfMeasure,
        units,
      }),
    [product, units, unitOfMeasure],
  )
  const userData = useSelector(userDataSelector)
  const isSoonAvailable = isAvailableAfter(product, userData)

  const trackSelectItem = useSelectItemTracking({
    product,
    itemListId: ITEM_LIST_IDS.CART,
    itemListName: ITEM_LIST_NAMES.CART,
    unitOfMeasure,
    index,
  })

  const msgKey = active ? 'temporarilyUnavailable' : 'removedFromOffer'

  return (
    <ProductWrapper
      isInactive
      borderBottom
      {...isMobile && { style: { minHeight: 'auto' } }}
    >
      <Flex>
        <ImageAndCtas
          productPath={productPath}
          product={product}
          changingDate={false}
          isInactive
          onTrackProductClick={trackSelectItem}
        />

        <ContentWrapper
          isInactive
          {...isMobile && { style: { height: 'auto' } }}
        >
          <BrandNameContainer {...{ isSoonAvailable }}>
            {brand && <BrandTxt>{brand}</BrandTxt>}

            <ProductNameStyled
              {...{ productPath, name }}
              active={isSoonAvailable}
              onNameClick={trackSelectItem}
              eventParams={{
                itemListId: ITEM_LIST_IDS.CART,
                itemListName: ITEM_LIST_NAMES.CART,
              }}
            />
          </BrandNameContainer>

          <CtaWrapper>
            <TrashBtn
              size={BUTTON_SIZE.ICON}
              iconPosition={ICONS_POSITION.CENTER}
              iconType={ICONS.TRASH}
              data-test-id={`trash_${productId}`}
              onClick={() => handleDeleteProduct({ productId })}
            />

            <ReplacementsButton
              onClick={() =>
                getReplacements({
                  productId,
                  showInModal: true,
                  refetchCart: true,
                })
              }
            />

            {!isMobile && (
              <>
                <Prices>
                  <TinyText
                    bold
                    color={
                      isSoonAvailable
                        ? theme.colors.secondary
                        : theme.colors.placeholder
                    }
                  >
                    {isSoonAvailable
                      ? formatMessage(messages.availableFrom, {
                          date: dayjs(closestDeliveryTime).format(
                            DATE_TIME_FORMATS.SHORT_DATE,
                          ),
                        })
                      : formatMessage(messages[msgKey])}
                  </TinyText>
                </Prices>

                <InfoIconContainer />
              </>
            )}
          </CtaWrapper>
        </ContentWrapper>
      </Flex>
    </ProductWrapper>
  )
}

export default productContainer(InactiveProduct)
