import React from 'react'
import { Icon, IconWrapper } from 'components/Icon'
import PropTypes from 'prop-types'
import { compose, get, method } from 'lodash/fp'

import { isInvalid } from 'utils/redux-form-utils'
import { useRichFormatMessage } from 'utils'
import { HintBox, InputWrapper, TextInputLabel, BasicInput } from './styles'
import { BORDER_TYPES } from './consts'

export const getBorderType = meta => {
  if (isInvalid(meta)) {
    return BORDER_TYPES.ERROR
  }
  if (meta && meta.touched) {
    return BORDER_TYPES.VISITED
  }

  return BORDER_TYPES.DEFAULT
}

const extractMetaError = compose(
  err => method('toJSON')(err) || err,
  get('error'),
)

const TextInput = ({
  iconType,
  borderType,
  label,
  width,
  placeholder,
  iconColor,
  disabled,
  meta,
  dataAttr,
  onIconClick,
  hint,
  errorHint,
  ...props
}) => {
  const error = extractMetaError(meta)
  const formatRichMessage = useRichFormatMessage()

  return (
    <InputWrapper {...{ disabled, width, iconType }}>
      <BasicInput
        {...props}
        disabled={disabled}
        borderType={getBorderType(meta)}
        placeholder={placeholder}
        data-test-id={dataAttr}
      />
      {label && <TextInputLabel>{label}</TextInputLabel>}
      {iconType && (
        <IconWrapper onClick={onIconClick} clickable={!!iconType}>
          <Icon color={iconColor} type={iconType} />
        </IconWrapper>
      )}

      {isInvalid(meta) ? (
        <React.Fragment>
          <HintBox isError>
            {!error.id ? <span>{error}</span> : formatRichMessage(error)}
          </HintBox>
          {errorHint && <HintBox isError>{errorHint}</HintBox>}
        </React.Fragment>
      ) : (
        hint && <HintBox>{hint}</HintBox>
      )}
    </InputWrapper>
  )
}

TextInput.propTypes = {
  hasIcon: PropTypes.bool,
  iconType: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  placeholder: PropTypes.string,
  borderType: PropTypes.string,
  meta: PropTypes.object,
}

export default TextInput
