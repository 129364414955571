import React from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import theme from 'theme'
import { formatPrice, formatRoundPrice } from 'utils'
import { DATE_TIME_FORMATS } from 'utils/datetime'
import { ProgressBar } from 'components'
import { DeliveryTruckAlert as AlertIcon } from 'components/Icons'

import messages from './messages'
import {
  LogisticInfoContainer,
  LogisticInfoTitleContainer,
  LogisticInfoTitle,
  LogisticInfoTextContainer,
  LogisticInfoText,
  ProgressBarContainer,
  ProgressBarValues,
} from './styles'

const LogisticLimitNotMet = ({ orderDetails }) => {
  const { logisticLackAmount, logisticLimit, routeDeadline } = orderDetails
  const { formatMessage } = useIntl()

  const amount = formatPrice(logisticLackAmount)
  const deadline = dayjs(routeDeadline).format(DATE_TIME_FORMATS.FULL_DATE_TIME)

  return (
    <LogisticInfoContainer data-test-id="logistic-limit-info-box">
      <LogisticInfoTitleContainer>
        <div>
          <AlertIcon />
        </div>
        <LogisticInfoTitle>
          {formatMessage(messages.lacksToLogisticLimit, { amount })}
        </LogisticInfoTitle>
      </LogisticInfoTitleContainer>
      <LogisticInfoTextContainer>
        <LogisticInfoText>
          {formatMessage(messages.addProductsToDelivery, { deadline })}
        </LogisticInfoText>
        <ProgressBarContainer data-test-id="logistic-limit-progress-bar">
          <ProgressBar
            value={logisticLimit - logisticLackAmount}
            maxValue={logisticLimit}
            innerColor={theme.colors.orangeDark2}
            outerColor={theme.colors.semiTranspBg.grayLight}
            height={8}
          />
          <ProgressBarValues>
            <LogisticInfoText>{formatRoundPrice(0)}</LogisticInfoText>
            <LogisticInfoText>
              {formatRoundPrice(logisticLimit)}
            </LogisticInfoText>
          </ProgressBarValues>
        </ProgressBarContainer>
      </LogisticInfoTextContainer>
    </LogisticInfoContainer>
  )
}

export default LogisticLimitNotMet
