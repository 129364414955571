import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'

import Wrapper from './Wrapper'
import Content from './Content'

const LoaderFullHeight = ({ className }) => (
  <Wrapper className={className} data-test-id="loader-full-height">
    <Content>
      <Spinner name="ball-spin-fade-loader" color="gray" fadeIn="none" />
    </Content>
  </Wrapper>
)

LoaderFullHeight.propTypes = {
  className: PropTypes.string,
}

export default LoaderFullHeight
