import React from 'react'
import { useIntl } from 'react-intl'

import { formatPrice } from 'utils'

import messages from '../../messages'
import Tile from '../index'

const OverdueTile = ({
  invoicesSummary,
  footer,
  children,
  title,
  subtitle,
  subtitleColor,
}) => {
  const { formatMessage } = useIntl()

  const { invoicesCount } = invoicesSummary.overdue
  const titleLabel =
    title ||
    formatMessage(messages.titleOverdue, {
      invoicesCount,
      invoicesLabel: formatMessage(messages.overdueCount, {
        number: invoicesCount,
      }),
    })

  return (
    <Tile
      title={titleLabel}
      subtitle={
        subtitle ||
        formatMessage(messages.grossPrice, {
          price: formatPrice(invoicesSummary.overdue.remainingAmount),
        })
      }
      subtitleColor={subtitleColor}
      footer={footer}
    >
      {children}
    </Tile>
  )
}

export default OverdueTile
