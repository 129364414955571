import styled from 'styled-components'

import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'

export const OrderItemsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.lg};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
  }
`

export const OrderListHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.spacing.sm};
  border-bottom: 1px solid ${theme.colors.semiTranspBg.grayLight};
  gap: ${theme.spacing.xs};
  padding-left: ${theme.spacing.xs};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding-left: 0;
  }
`

export const OrderListItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  margin: ${theme.spacing.xs} 0;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    gap: ${theme.spacing.xs};
    margin-top: ${theme.spacing.base};
    margin-bottom: 40px;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    margin-top: ${theme.spacing.sm};
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: ${theme.spacing.sm} 0;
  border-top: 1px solid ${theme.colors.semiTranspBg.gray};
  border-bottom: 1px solid ${theme.colors.semiTranspBg.gray};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    border-bottom: none;
  }
`
