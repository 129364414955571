import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { ProgressBar } from 'components'
import { SALE_PROGRESS_VARIANTS } from './consts'

import { SaleContainer, SaleTextContainer, SaleText } from './styles'
import messages from './messages'

const CONFIG = {
  [SALE_PROGRESS_VARIANTS.DEFAULT]: {
    containerStyle: {
      gap: theme.spacing.xs,
    },
    textStyle: {
      fontSize: theme.textSizes.xxs,
      lineHeight: '16px',
      fontWeight: theme.fontWeights.medium,
    },
    progressBarStyle: {
      innerColor: theme.colors.secondary,
      outerColor: theme.colors.semiTranspBg.greenLight2,
    },
  },
  [SALE_PROGRESS_VARIANTS.BIG]: {
    containerStyle: {
      gap: theme.spacing.xxs,
    },
    textStyle: {
      fontSize: theme.textSizes.sm,
      lineHeight: '24px',
      fontWeight: theme.fontWeights.regular,
    },
    progressBarStyle: {
      innerColor: theme.colors.secondary,
      outerColor: 'rgba(173, 220, 177, 0.7)',
      height: 4,
    },
  },
}

const SaleProgressBar = ({
  className,
  isLongText,
  promotion,
  baseUnitDesc,
  variant = SALE_PROGRESS_VARIANTS.DEFAULT,
}) => {
  const { formatMessage } = useIntl()
  const reward = promotion?.reward

  const { initialStock, stock } = reward
  const { containerStyle, textStyle, progressBarStyle } = CONFIG[variant]

  return (
    <SaleContainer style={containerStyle} className={className}>
      <SaleTextContainer>
        <SaleText style={textStyle}>
          {formatMessage(
            messages[
              isLongText ? 'availableAtDiscountLong' : 'availableAtDiscount'
            ],
          )}
        </SaleText>
        <SaleText style={textStyle}>
          {stock} {baseUnitDesc}
        </SaleText>
      </SaleTextContainer>
      <ProgressBar
        value={stock}
        maxValue={initialStock}
        innerColor={progressBarStyle.innerColor}
        outerColor={progressBarStyle.outerColor}
        height={progressBarStyle.height}
      />
    </SaleContainer>
  )
}

export default SaleProgressBar
