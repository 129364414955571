import React from 'react'
import { useIntl } from 'react-intl'

import TextTooltip from 'components/Tooltip/TextTooltip'
import {
  FloatingPriceSmall,
  FloatingPriceXSmall,
} from 'components/Icons/FloatingPrice'
import { FLOATING_PRICE_VARIANT } from '../consts'

import messages from '../messages'
import { Container, IconContainer } from './styles'

const ICON_VARIANTS = {
  [FLOATING_PRICE_VARIANT.SMALL]: FloatingPriceSmall,
  [FLOATING_PRICE_VARIANT.EXTRA_SMALL]: FloatingPriceXSmall,
}

const FloatingPriceWrapper = ({
  children,
  hasTooltip,
  hasFloatingPrice,
  iconMargin = 4,
  containerStyle,
  iconColor,
  iconBgColor,
  iconBorderColor,
  iconVariant = FLOATING_PRICE_VARIANT.SMALL,
}) => {
  const { formatMessage } = useIntl()

  const Icon = ICON_VARIANTS[iconVariant]

  if (hasFloatingPrice) {
    const wrapper = (
      <Container $hasTooltip={hasTooltip} style={containerStyle}>
        <IconContainer
          $iconMargin={iconMargin}
          $borderColor={iconBorderColor}
          data-test-id="floating-price-icon"
        >
          <Icon color={iconColor} bgColor={iconBgColor} />
        </IconContainer>
        {children}
      </Container>
    )

    if (hasTooltip) {
      return (
        <TextTooltip content={formatMessage(messages.tooltipText)}>
          {wrapper}
        </TextTooltip>
      )
    }

    return wrapper
  }

  return children
}

export default FloatingPriceWrapper
