import { createSelector } from 'reselect'
import { get } from 'lodash/fp'

import {
  makeErrorSelector,
  makeDataSelector,
  makeIsFinishedSelector,
} from 'utils/redux-utils'

import {
  RESET_PASSWORD_REDUCER_NAME,
  RESET_PASSWORD_INIT_REDUCER_NAME,
  RESET_PASSWORD_CONFRIRM_REDUCER_NAME,
  RESET_PASSWORD_TOKEN_VALIDATOR_REDUCER_NAME,
} from './consts'

export const resetPasswordInitErrorSelector = makeErrorSelector([
  RESET_PASSWORD_REDUCER_NAME,
  RESET_PASSWORD_INIT_REDUCER_NAME,
])

export const resetPasswordConfirmErrorSelector = makeErrorSelector([
  RESET_PASSWORD_REDUCER_NAME,
  RESET_PASSWORD_CONFRIRM_REDUCER_NAME,
])

export const resetPasswordTokenErrorSelector = makeErrorSelector([
  RESET_PASSWORD_REDUCER_NAME,
  RESET_PASSWORD_TOKEN_VALIDATOR_REDUCER_NAME,
])

const resetPasswordTokenValidatedSelector = makeDataSelector([
  RESET_PASSWORD_REDUCER_NAME,
  RESET_PASSWORD_TOKEN_VALIDATOR_REDUCER_NAME,
])

export const resettingPasswordLoginSelector = createSelector(
  resetPasswordTokenValidatedSelector,
  get('login'),
)

export const isResetPasswordTokenValidatedSelector = makeIsFinishedSelector([
  RESET_PASSWORD_REDUCER_NAME,
  RESET_PASSWORD_TOKEN_VALIDATOR_REDUCER_NAME,
])
