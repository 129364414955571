import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AlternateBtn, BUTTON_SIZE } from 'components/Button'

import messages from './messages'
import { Header, Content } from './styles'

const SomethingWentWrong = ({ modal: { closeModal } }) => (
  <>
    <Header>
      <FormattedMessage {...messages.somethingWentWrong} />
    </Header>
    <Content>
      <FormattedMessage {...messages.somethingWentWrongInfo} />
    </Content>
    <AlternateBtn onClick={closeModal} size={BUTTON_SIZE.BLOCK}>
      <FormattedMessage {...messages.return} />
    </AlternateBtn>
  </>
)

export default SomethingWentWrong
