import React from 'react'

const Drinks = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0934 4.00443C19.6433 4.05598 20.0473 4.54353 19.9957 5.09341L18.6657 19.2801C18.5212 20.8217 17.2272 22.0001 15.6788 22.0001H8.32134C6.77297 22.0001 5.47896 20.8217 5.33443 19.2801L4.00443 5.09341C3.95288 4.54353 4.35685 4.05598 4.90673 4.00443C5.4566 3.95288 5.94415 4.35685 5.9957 4.90673L7.3257 19.0934C7.37388 19.6073 7.80521 20.0001 8.32134 20.0001H15.6788C16.1949 20.0001 16.6263 19.6073 16.6744 19.0934L18.0044 4.90673C18.056 4.35685 18.5435 3.95288 19.0934 4.00443Z"
      fill="#99D0AC"
    />
    <path
      className="group-[.active-btn]:fill-white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.134 10L14.5772 8.56037L12.1417 9.78839L12.0767 10H6.47363L7.32614 19.0934C7.37432 19.6073 7.80565 20.0001 8.32177 20.0001H15.6792C16.1954 20.0001 16.6267 19.6073 16.6749 19.0934L17.5274 10H14.134ZM13.8507 4.22498L16.2669 3.00666C16.3181 2.49866 15.9486 2.04511 15.4048 2.00308C14.9147 1.9652 14.448 2.28019 14.3052 2.74535L13.8507 4.22498ZM13.2642 6.13424L15.7024 4.90482L15.1651 6.65038L12.7282 7.87913L13.2642 6.13424Z"
      fill="#229944"
    />
  </svg>
)

export default Drinks
