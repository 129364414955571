import React from 'react'

import theme from 'theme'

const Bill = ({
  color = theme.colors.primary,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M17 14l2 2 2-2v5a2 2 0 01-2 2v0m0 0H8a3 3 0 01-3-3V3l2 2 2-2 2 2 2-2 2 2 2-2v16a2 2 0 002 2v0zM9 9h2M9 12h3M9 15h2M9 18h3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Bill
