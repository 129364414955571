import styled from 'styled-components'
import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

export const FloatingPricesInfoContainer = styled.div`
  display: flex;
  gap: 18px;
  background: ${p => p.$bgColor || theme.colors.white};
`

export const FloatingPricesTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: ${theme.spacing.xs};
`

export const FloatingPricesInfoTitle = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.infoBlue2};
`

export const FloatingPricesInfoText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true, fontSize: true },
})`
  color: ${theme.colors.lightGrey4};
`

export const FloatingPricesIconContainer = styled.div``
