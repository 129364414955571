import { takeLatest, call, all, put } from 'redux-saga/effects'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'

import * as api from './api'
import {
  campaignsActions,
  campaignDetailsActions,
  campaignProductsActions,
} from './actions'

export function* getCampaignsFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: campaignsActions,
      request: api.getCampaigns,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* getCampaignDetailsFlow({ data } = {}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: campaignDetailsActions,
      request: api.getCampaignDetails,
      params: data,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* getCampaignProductsFlow({ data } = {}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: campaignProductsActions,
      request: api.getCampaignProducts,
      params: data,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default function* watcher() {
  yield all([
    takeLatest(campaignsActions.DELTA, getCampaignsFlow),
    takeLatest(campaignDetailsActions.DELTA, getCampaignDetailsFlow),
    takeLatest(campaignProductsActions.DELTA, getCampaignProductsFlow),
  ])
}
