import React from 'react'

import { useOfMaxWidth } from 'hooks'

import { APP_BREAKPOINTS } from 'consts'

import Mobile from './Mobile'
import Desktop from './Desktop'

export const stickyPaymentId = 'stickyPayment'

const PaymentStickyRow = ({ isFixed }) => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TMP_MAIN - 1)

  const propsToPass = {
    stickyPaymentId,
    isFixed,
  }

  if (isMobile) return <Mobile {...propsToPass} />

  return <Desktop {...propsToPass} />
}

export default PaymentStickyRow
