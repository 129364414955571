import React from 'react'

const Syrop = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8H14C15.6569 8 17 9.34315 17 11V20C17 21.6569 15.6569 23 14 23H10C8.34315 23 7 21.6569 7 20V11C7 9.34314 8.34315 8 10 8ZM10 10C9.44772 10 9 10.4477 9 11V20C9 20.5523 9.44772 21 10 21H14C14.5523 21 15 20.5523 15 20V11C15 10.4477 14.5523 10 14 10H10Z"
      fill="#99D0AC"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M15 13H9V20C9 20.5523 9.44772 21 10 21H14C14.5523 21 15 20.5523 15 20V13Z"
      fill="#229944"
    />
    <path
      className="group-[.active-btn]:fill-white"
      d="M12 1C11.6069 1 11.2629 1.2643 11.1616 1.64416L10.5333 4H9C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4H13.4667L12.8384 1.64416C12.7371 1.2643 12.3931 1 12 1Z"
      fill="#229944"
    />
  </svg>
)

export default Syrop
