import React from 'react'
import {
  RadioLabelWrapper,
  RadioInput,
  RadioToggle,
  RadioLabel,
} from './styles'

const Radio = ({
  disabled,
  checked,
  label,
  id,
  children,
  bold,
  testId,
  ...props
}) => (
  <RadioLabelWrapper disabled={disabled} data-test-id={testId}>
    <RadioInput
      checked={checked}
      id={id}
      {...props}
      disabled={disabled}
      type="radio"
    />
    <RadioToggle />

    {label && (
      <RadioLabel bold={bold} disabled={disabled} htmlFor={id}>
        {label}
        {children}
      </RadioLabel>
    )}
  </RadioLabelWrapper>
)

export default Radio
