import { useCallback } from 'react'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'consts'
import { INVOICE_STATUS } from 'containers/Invoices/consts'

const useOverdueInvoiceHandler = () => {
  const navigate = useNavigate()

  return useCallback(
    () => {
      navigate(
        `${ROUTES.INVOICES.ROOT}?${queryString.stringify({
          preselectStatus: INVOICE_STATUS.NOT_PAID_EXPIRED,
        })}`,
      )
    },
    [navigate],
  )
}

export default useOverdueInvoiceHandler
