import React from 'react'
import { useIntl } from 'react-intl'

import { Filtering as FilteringIcon, XNormal as XIcon } from 'components/Icons'
import NoResults from '..'

import messages from './messages'

const FilteringNoResults = ({ onClearFilters }) => {
  const { formatMessage } = useIntl()

  return (
    <NoResults
      icon={<FilteringIcon />}
      text={formatMessage(messages.lackOfProducts)}
      btnIcon={<XIcon />}
      btnText={formatMessage(messages.clearFiltersBtn)}
      handleBtnClick={onClearFilters}
      containerTestId="filtering-no-results"
      btnTestId="filtering-no-results-btn"
    />
  )
}

export default FilteringNoResults
