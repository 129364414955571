import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

// prettier-ignore
export const Wrapper = styled.div`
  background: ${theme.colors.gray1};
  display: flex;
  width: 100%;
  padding-bottom: 24px;
`

// prettier-ignore
export const Container = styled(Flex)`
  max-width: 490px;
  background: ${theme.colors.white};
  margin: 120px auto auto;
`

export const Content = styled(Flex).attrs({
  p: ['10px', '24px'],
})`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
