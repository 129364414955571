export const USER_INFO_REDUCER_NAME = 'userInfo'

export const CONTACT_TITLE_IDS = {
  SALES: 1,
  BOK: 2,
  INVOICING: 3,
  DELIVERY_ISSUES: 4,
}

export const CONTACT_TYPES = {
  NAME: 'name',
  ADDRESS: 'address',
  PHONE: 'phoneno',
  EMAIL: 'email',
  TEXT: 'text',
}
