import { createSelector } from 'reselect'
import { get, compose, isNil, path } from 'lodash/fp'

import { makeDataSelector, makeIsFetchingSelector } from 'utils/redux-utils'

import { PRODUCTS_SEARCH_REDUCER_NAME } from './consts'

const searchProductsDataSelector = makeDataSelector(
  PRODUCTS_SEARCH_REDUCER_NAME,
)

export const searchResultsSelector = createSelector(
  searchProductsDataSelector,
  compose(
    products => (isNil(products) ? [] : products),
    get('products'),
  ),
)

export const searchResultsPaginationSelector = createSelector(
  searchProductsDataSelector,
  path(['meta', 'pagination']),
)

export const isSearchFetchingSelector = makeIsFetchingSelector(
  PRODUCTS_SEARCH_REDUCER_NAME,
)
