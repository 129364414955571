import styled from 'styled-components'

import theme from 'theme'
import { BasicButtonV2 } from 'components/Button'
import { TinyTextV2Secondary } from 'components/Text'

import { SETTLE_MODAL_MOBILE_MAX_WIDTH } from '../consts'

export const LeftCol = styled.div``

export const RightCol = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.ss};
  padding-top: ${theme.spacing.ss};
  border-top: 1px solid ${theme.colors.borderFaded};

  ${BasicButtonV2} {
    width: 114px;
  }
`

export const Container = styled.footer`
  border-top: 1px solid ${theme.colors.borderFaded};
  padding-top: ${theme.spacing.ss};

  @media (min-width: ${SETTLE_MODAL_MOBILE_MAX_WIDTH}) {
    padding-top: ${theme.spacing.base};
    display: flex;
    justify-content: space-between;

    ${LeftCol} {
      display: flex;
    }

    ${RightCol} {
      gap: ${theme.spacing.vertical};
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
  }
`

// prettier-ignore
export const PriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${SETTLE_MODAL_MOBILE_MAX_WIDTH}) {
    flex-flow: column;
    align-items: flex-start;

    &:not(:first-of-type) {
      margin-left: ${theme.spacing.vertical};
      padding-left: ${theme.spacing.vertical};
      border-left: 1px solid ${theme.colors.borderFaded};
    }
  }
`

export const TotalBlock = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media (min-width: ${SETTLE_MODAL_MOBILE_MAX_WIDTH}) {
    align-items: flex-end;
  }
`

export const PriceLabel = styled.div`
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  ${p => p.color && `color: ${p.color};`};
`

export const CaptionLabel = styled(TinyTextV2Secondary).attrs({ as: 'label' })`
  color: ${theme.colors.lightGrey4};
`

export const OverpaymentsHints = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
`

export const RamainingInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`

export const InfoBtn = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`
