import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { Document as DocumentIcon } from 'components/Icons'
import { formatPrice } from 'utils'

import messages from '../messages'
import {
  OrdersDetailsBtn,
  OrderBtnTitle,
  OrderBtnPrice,
  AllOrdersBtn,
  OrderActions,
} from './styles'

const OrderSummary = ({ order, onOrderDetailsClick, onAllOrdersClick }) => {
  const { formatMessage } = useIntl()

  return (
    <OrderActions>
      <OrdersDetailsBtn onClick={onOrderDetailsClick}>
        <DocumentIcon color={theme.colors.primary} />
        <OrderBtnTitle>
          {formatMessage(messages.latestOrder)}
          <OrderBtnPrice>{formatPrice(order.totalNet)}</OrderBtnPrice>
        </OrderBtnTitle>
      </OrdersDetailsBtn>
      <AllOrdersBtn onClick={onAllOrdersClick}>
        {formatMessage(messages.allOrders)}
      </AllOrdersBtn>
    </OrderActions>
  )
}

export default OrderSummary
