import styled from 'styled-components'

import { getSpacing, getFontWeights } from 'components/helpers/theme-selectors'
import { P, H4 } from 'components/Text'
import { AlternateBtn } from 'components/Button'

export const Title = styled(H4)`
  margin: 0;
`

export const SecondaryTitle = styled(P)`
  margin-top: ${props => getSpacing(props).base};

  b {
    font-weight: ${props => getFontWeights(props).bold};
  }
`

export const CtaBtn = styled(AlternateBtn)`
  margin-top: ${props => getSpacing(props).base};
  width: 100%;
`
