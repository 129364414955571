import { takeLatest, select, call, put } from 'redux-saga/effects'
import { isEmpty } from 'lodash/fp'

import { genericGetDataEnhanced } from 'containers/App/sagas'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { cartFirstGroupProductsIdsSelector } from 'containers/Cart/selectors'
import {
  getDeliveryDatesByProductsRequest,
  getAllDeliveryDatesRequest,
  getUserDeliveriesRequest,
} from './api'
import {
  getDeliveryDatesByProducts,
  getAllDeliveryDates,
  getUserDeliveries,
} from './actions'

export function* getDeliveryDatesByProductsFlow({
  callback,
  data: { productIds } = {},
} = {}) {
  try {
    const firstGroupProductsIds = yield select(
      cartFirstGroupProductsIdsSelector,
    )
    const requestingProductIds = productIds || firstGroupProductsIds

    if (isEmpty(requestingProductIds)) return

    yield call(genericGetDataEnhanced, {
      actions: getDeliveryDatesByProducts,
      request: getDeliveryDatesByProductsRequest,
      params: {
        productIds: requestingProductIds,
      },
    })

    if (callback) {
      yield call(callback)
    }
  } catch (error) {
    console.log('Error fetching delivery dates by products', error)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

// Retrieves the logistic limit to display in a header
// "deliveryDatesByProducts" cannot be used when the cart is empty.
// Also used to display logistics limit for incoming orders in a cart.
export function* getAllDeliveryDatesFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getAllDeliveryDates,
      request: getAllDeliveryDatesRequest,
    })
  } catch (err) {
    console.log('Error getting all delivery dates', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* getUserDeliveriesFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getUserDeliveries,
      request: getUserDeliveriesRequest,
    })
  } catch (err) {
    console.log('Error getting user deliveries', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(getDeliveryDatesByProducts.DELTA, getDeliveryDatesByProductsFlow),
  takeLatest(getAllDeliveryDates.DELTA, getAllDeliveryDatesFlow),
  takeLatest(getUserDeliveries.DELTA, getUserDeliveriesFlow),
]
