import styled from 'styled-components'
import theme from 'theme'
import { FilteredPageTileWrapper } from 'components/FilteredPageTile/styles'

export const DropdownFilteredPageImage = styled.img`
  position: absolute;
`

export const NoveltiesTileImage = styled(DropdownFilteredPageImage)`
  width: 144px;
  bottom: 0;
  right: 0;
`

export const ClearancesTileImage = styled(DropdownFilteredPageImage)`
  width: 100%;
  right: 20px;
  bottom: -7px;
`

export const BestsellersTileImage = styled(DropdownFilteredPageImage)`
  width: 100%;
  right: -16px;
  bottom: 0;
`

export const PromotionsTileImage = styled(DropdownFilteredPageImage)`
  width: 130px;
  bottom: 0;
  right: 0;
`

export const DropdownFilteredPageWrapper = styled(FilteredPageTileWrapper)`
  min-width: 147.5px;
  height: 147.5px;
  padding: ${theme.spacing.sm};
`

export const BestsellersTileWrapper = styled(DropdownFilteredPageWrapper)`
  background: ${theme.colors.lightCyan};
  &:hover {
    background: ${theme.colors.cyan};
  }
`

export const NoveltiesTileWrapper = styled(DropdownFilteredPageWrapper)`
  background: ${theme.colors.lightPurple};
  &:hover {
    background: ${theme.colors.purple};
  }
`

export const ClearancesTileWrapper = styled(DropdownFilteredPageWrapper)`
  background: ${theme.colors.orangeLight4};
  &:hover {
    background: ${theme.colors.orangeLight5};
  }
`

export const PromotionsTileWrapper = styled(DropdownFilteredPageWrapper)`
  background: ${theme.colors.greenLight5};
  &:hover {
    background: ${theme.colors.greenLightHover};
  }
`
