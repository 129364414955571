import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { withSpinner } from 'hocs'
import { CART_INFO_REDUCER_NAME } from 'containers/Cart/consts'
import {
  PROMOS_REDUCER_NAME,
  PROMO_GROUP_REDUCER_NAME,
  ALL_PROMO_GROUPS_REDUCER_NAME,
} from 'containers/Promotions/consts'
import { getAllPromoGroupsActions } from 'containers/Promotions/AllGroups/actions'
import { selectAllPromoGroupsData } from 'containers/Promotions/AllGroups/selectors'
import { PageTitleV2 } from 'components/Text'
import Breadcrumbs from 'components/Layout/Breadcrumbs/MainPage'
import theme from 'theme'
import { BrowseCatalog } from 'components/Icons'
import { ROUTES } from 'consts'
import {
  ALL_PROMO_ORIGINS,
  FILTER_EVENTS,
  trackSimpleEvent,
} from 'services/analytics'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { STATIC_GROUP_PROPS } from './consts'
import PromoTile from './PromoTile'
import messages from './messages'

import { PromotionsWrapper, Divider, TilesContainer } from './styles'

const Promotions = () => {
  const dispatch = useDispatch()
  const userData = useSelector(userDataSelector)

  useEffect(() => {
    dispatch(getAllPromoGroupsActions.delta())
  }, [])

  const promoGroups = useSelector(selectAllPromoGroupsData) || []

  return (
    <PromotionsWrapper>
      <Breadcrumbs />
      <PageTitleV2>
        <FormattedMessage {...messages.promo} />
      </PageTitleV2>
      <Divider />
      <TilesContainer>
        {userData.offerDetails?.hasActivePromotions && (
          <PromoTile
            promoGroup={STATIC_GROUP_PROPS.ALL_PROMOS}
            data-test-id="all-promos-promo-tile"
            bgColor={theme.colors.greenDark}
            hoverBgColor={theme.colors.green}
            txtColor={theme.colors.white}
            btnColor={theme.colors.greenLight}
            BtnIcon={BrowseCatalog}
            redirectRoute={ROUTES.PROMO_PRODUCTS}
            onClickEvent={() => {
              trackSimpleEvent(FILTER_EVENTS.clickedPromotions, {
                origin: ALL_PROMO_ORIGINS.TILE,
              })
            }}
          />
        )}

        {userData.offerDetails?.hasActiveClearances && (
          <PromoTile
            promoGroup={STATIC_GROUP_PROPS.CLEARANCES}
            data-test-id="all-clearances-promo-tile"
            BtnIcon={BrowseCatalog}
            redirectRoute={ROUTES.CLEARANCE_PRODUCTS}
            onClickEvent={() => {
              trackSimpleEvent(FILTER_EVENTS.clickedClearances, {
                origin: ALL_PROMO_ORIGINS.TILE,
              })
            }}
          />
        )}

        {promoGroups.map(promoGroup => (
          <PromoTile key={promoGroup?.id} promoGroup={promoGroup} />
        ))}
      </TilesContainer>
    </PromotionsWrapper>
  )
}

export default withSpinner([
  CART_INFO_REDUCER_NAME,
  [PROMOS_REDUCER_NAME, PROMO_GROUP_REDUCER_NAME],
  [PROMOS_REDUCER_NAME, ALL_PROMO_GROUPS_REDUCER_NAME],
])(Promotions)
