import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  useRemainingDaysFormatted,
  getInvoiceExpiryDays,
} from 'views/Invoices/utils'
import messages from 'views/Cart/messages'

export const usePaymentDate = ({
  overdueAmount,
  overdueState,
  invoicesPaymentDate,
}) => {
  const { formatMessage } = useIntl()
  const daysCount = useRemainingDaysFormatted(invoicesPaymentDate)

  const datesFormat = useMemo(
    () => {
      const diffDays = getInvoiceExpiryDays(invoicesPaymentDate)

      const overdueMsg = diffDays !== 0 ? formatMessage(messages.overdue) : ''
      if (!overdueState && overdueAmount > 0) {
        return `${daysCount}${overdueMsg}`
      }

      return daysCount
    },
    [daysCount, overdueAmount, overdueState, invoicesPaymentDate],
  )

  return datesFormat
}
