import styled from 'styled-components'
import { Box } from '@rebass/grid'

import theme from 'theme'
import { Caption, TinyTextHigh } from 'components/Text'
import { getColors } from 'components/helpers/theme-selectors'
import { APP_BREAKPOINTS } from 'consts'
import { SEARCH_DESKTOP_BREAKPOINT } from '../const'

export const SuggestContainer = styled.div`
  display: flex;
  min-height: 125px;
  background: ${props => getColors(props).white};
  overflow-y: auto;
  border-radius: 0 0 16px 16px;
  box-shadow: ${theme.shadows.primary};
  width: 100vw;
  max-width: 720px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-height: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    height: 100vh;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    border-radius: 16px;
    z-index: ${theme.zIndex.header};
  }
`

export const ProductsContainer = styled(Box)`
  background: ${props => getColors(props).white};
  padding: 8px;
  width: 100%;

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    padding: 12px;
  }
`

export const ProductsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: baseline;
  padding: 12px 0;
  border-bottom: 1px solid rgba(87, 106, 118, 0.1);
  margin: 0 8px 8px;

  @media (min-width: ${SEARCH_DESKTOP_BREAKPOINT}px) {
    margin: 0 12px 12px;
  }
`

export const ProductsTitle = styled(Caption).attrs({
  isSuccess: true,
  uppercase: true,
  strongBold: true,
})`
  letter-spacing: 0.04em;
`

export const ShowAllTxt = styled(TinyTextHigh)`
  cursor: pointer;
  text-align: right;

  &:hover {
    color: ${theme.colors.secondary};
  }
`

export const NoResultsContainer = styled(SuggestContainer)`
  text-transform: uppercase;
  min-height: 0;
  padding: ${theme.spacing.base};
`
