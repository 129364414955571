import React from 'react'
import { FormattedMessage } from 'react-intl'

import Notification from '../Notification'
import messages from './messages'

const Expired = () => (
  <Notification height="270px" header={messages.invalid} width="490px">
    <FormattedMessage {...messages.invalidDescription} />
  </Notification>
)

export default Expired
