/* eslint-disable indent */
import styled from 'styled-components'
import theme from 'theme'
import { StyledScroll } from 'components/Scroll/styles'

export const SCROLL_POSITIONS = {
  TOP: 'top',
  MID: 'mid',
  BOTTOM: 'bottom',
  NONE: 'none',
}

export const SubcategoriesList = styled(StyledScroll)`
  .simplebar-content {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xxs};
  }
  // trackbar spacing
  margin-right: -${theme.spacing.sm};
  padding-right: ${theme.spacing.sm};
  // scroll effects
  &:after {
    content: '';
    position: absolute;
    z-index: ${theme.zIndex.base};
    pointer-events: none;
    width: calc(100% - ${theme.spacing.sm});
    height: 40px;
    left: 0;
    bottom: 40px;
    opacity: ${p =>
      p.$scrollPosition === SCROLL_POSITIONS.BOTTOM ||
      p.$scrollPosition === SCROLL_POSITIONS.NONE
        ? '0'
        : '1'};
    transition: opacity 100ms ease-in;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 86.67%
    );
  }
  padding-top: 41px;
  padding-bottom: 39px;
  height: 100%;
`
