import queryString from 'query-string'

import { getFilters } from 'containers/Products/Filters/api'
import { formatFiltersParams } from 'containers/Products/utils'

const PAGE_SIZE = 24

const templates = `/me/templates`
const templateItems = id => `${templates}/${id}/items`
const templateAddToCart = templateId =>
  `/me/templates/${templateId}/add_to_cart`

export const getTemplates = () => ({
  url: templates,
})

export const getTemplate = ({ id }) => ({
  url: `${templates}/${id}`,
})

export const createTemplate = ({ name }) => ({
  url: templates,
  options: {
    method: 'POST',
    body: { name },
  },
})

export const deleteTemplate = ({ id }) => ({
  url: `${templates}/${id}`,
  options: {
    method: 'DELETE',
  },
})

export const renameTemplate = ({ id, name }) => ({
  url: `${templates}/${id}`,
  options: {
    method: 'PATCH',
    body: {
      name,
    },
  },
})

export const getTemplateItems = ({
  id,
  current = 1,
  pageSize = PAGE_SIZE,
  ...rest
}) => {
  const queryParams = formatFiltersParams({
    per_page: pageSize,
    page: current,
    ...rest,
  })

  return {
    url: `${templateItems(id)}?${queryParams}`,
  }
}

export const addAllToCart = ({ id }) => ({
  url: templateAddToCart(id),
  options: { method: 'PUT' },
})

export const changeTemplateProductQuantity = ({
  templateId,
  productId,
  body,
}) => ({
  url: `${templateItems(templateId)}/${productId}`,
  options: { method: 'PUT', body },
})

export const addProductToTemplate = ({ id, productId, body }) => ({
  url: `${templateItems(id)}/${productId}`,
  options: { method: 'PUT', body },
})

export const deleteTemplateProduct = ({ id, productId }) => ({
  url: `${templateItems(id)}/${productId}`,
  options: { method: 'DELETE' },
})

export const getProductTemplates = ({ productId, unitOfMeasure }) => {
  const queryParams = queryString.stringify({
    unit_of_measure: unitOfMeasure,
    product_id: productId,
  })

  return {
    url: `${templates}?${queryParams}`,
  }
}

export const getTemplateFilters = ({ id, selectedFiltersParams }) =>
  getFilters({ templateId: id, selectedFiltersParams })
