import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router-dom'

import { ROUTES } from 'consts'
import { secondsDiff } from 'utils/datetime'
import cartActionsGroup from 'containers/Cart/actions'
import {
  cartItemsNumberSelector,
  selectIsOrderable,
} from 'containers/Cart/selectors'
import { getInvoicesSummaryActions } from 'containers/Invoices/actions'
import { tradeLimitActions } from 'containers/Orders/actions'
import { selectTradeLimit } from 'containers/Orders/selectors'
import { getUserDeliveries } from 'containers/Delivery/actions'
import { closestTimerDeliveryDateSelector } from 'containers/Delivery/selectors'
import { HEADER_ID } from 'containers/App/LoggedInLayout/Header/consts'
import { setPaymentStylesConfig } from '../UserControls/utils'

import { Header, HeaderWrapper, MobileSearch } from './styles'
import Search from './Search'
import Logo from './Logo'
import NavBar from './NavBar'
import UserControls from '../UserControls'
import MobileUserControls from './MobileUserControls'

const HeaderWithNavigation = ({ isMobile, pathname, mobileMenuOffset }) => {
  const didMountRef = useRef(false)
  const dispatch = useDispatch()
  const [mobileSearchEnabled, setMobileSearchEnabled] = useState(false)

  const cartCount = useSelector(cartItemsNumberSelector)
  const { scenario, paymentColorName } = useSelector(selectTradeLimit)

  const isBelowLimit = !useSelector(selectIsOrderable)
  const { userBtnStyles, cartBtnStyles } = useMemo(
    () => setPaymentStylesConfig(scenario, paymentColorName, isBelowLimit),
    [scenario, paymentColorName, isBelowLimit],
  )

  // handle refetch when current closest delivery deadline is up
  const { deadlineOrderDate } = useSelector(closestTimerDeliveryDateSelector)
  useEffect(
    () => {
      let timeout

      if (deadlineOrderDate) {
        const secondsToPlaceOrder = secondsDiff(deadlineOrderDate)

        timeout = setTimeout(() => {
          dispatch(cartActionsGroup.delta())
          dispatch(getUserDeliveries.delta())
        }, secondsToPlaceOrder * 1000)
      }

      return () => {
        clearTimeout(timeout)
      }
    },
    [deadlineOrderDate],
  )

  useEffect(
    () => {
      if (mobileSearchEnabled && pathname !== ROUTES.SEARCH_RESULTS) {
        setMobileSearchEnabled(false)
      }

      if (
        didMountRef.current &&
        matchPath({ path: ROUTES.CART, end: true }, pathname)
      ) {
        dispatch(cartActionsGroup.delta())
        dispatch(tradeLimitActions.delta())
        dispatch(getInvoicesSummaryActions.delta())
      } else if (!didMountRef.current) {
        didMountRef.current = true
        dispatch(cartActionsGroup.delta())
        dispatch(tradeLimitActions.delta())
        dispatch(getInvoicesSummaryActions.delta())
      }
    },
    [pathname],
  )

  return (
    <HeaderWrapper {...{ isMobile }} id={HEADER_ID}>
      <Header>
        {mobileSearchEnabled ? (
          <MobileSearch {...{ mobileSearchEnabled, setMobileSearchEnabled }} />
        ) : (
          <Logo />
        )}

        {isMobile ? (
          <MobileUserControls
            {...{
              mobileSearchEnabled,
              setMobileSearchEnabled,
              userBtnStyles,
              cartBtnStyles,
              cartCount,
              mobileMenuOffset,
            }}
          />
        ) : (
          <>
            <Search />
            <NavBar />
            <UserControls {...{ userBtnStyles, cartBtnStyles }} />
          </>
        )}
      </Header>
    </HeaderWrapper>
  )
}

export default HeaderWithNavigation
