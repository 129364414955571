import React from 'react'
import { Flex, Box } from '@rebass/grid'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash/fp'

import { formatPrice } from 'utils'
import { useOfMaxWidth } from 'hooks'

import {
  cartTotalSelector,
  cartDeliveriesSelector,
  firstDeliveryLogisticLackAmountSelector,
  hasFloatingPriceSelector,
} from 'containers/Cart/selectors'
import { userDataSelector } from 'containers/UserInfo/selectors'

import { H4Primary, TertiaryText, TinyTextFaded } from 'components/Text'
import { InfoCircle } from 'components/Icons'

import { APP_BREAKPOINTS } from 'consts'
import messages from 'views/Cart/messages'
import { PAY_SCENARIO } from 'containers/Orders/consts'
import { selectTradeLimit } from 'containers/Orders/selectors'

import { Column, HeadingColumn, TailColumn, InfoRow } from '../components'

import {
  RowContainer,
  InfoMsg,
  InfoBox,
  CartFloatingPriceInfo,
  Divider,
} from './styles'

const SummaryRow = () => {
  const { formatMessage } = useIntl()
  const isLowerThanMain = useOfMaxWidth(APP_BREAKPOINTS.TMP_MAIN - 1)
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TABLET_START - 1)
  const { totalNet, totalGross } = useSelector(cartTotalSelector)
  const deliveryDates = useSelector(cartDeliveriesSelector)
  const userData = useSelector(userDataSelector)
  const hasFloatingPrice = useSelector(hasFloatingPriceSelector)
  const { hideDues } = userData
  const firstDeliveryLogisticLackAmount = useSelector(
    firstDeliveryLogisticLackAmountSelector,
  )
  const { scenario } = useSelector(selectTradeLimit)
  const isGreenScenario = scenario === PAY_SCENARIO.GREEN

  return (
    <RowContainer flexDirection={isMobile ? 'column' : 'row'}>
      <Flex width={1} flexDirection="column" justifyContent="space-between">
        <HeadingColumn pb={isMobile ? 36 : 0}>
          <H4Primary>{formatMessage(messages.paymentSummary)}</H4Primary>
        </HeadingColumn>

        {!isLowerThanMain && (
          <Flex height={40}>
            <HeadingColumn borderedLeft pl={24} pb={24}>
              <TertiaryText bold>
                {formatMessage(messages.deliveriesOn, {
                  deliveriesCount: deliveryDates.length,
                  deliveriesLabel: formatMessage(messages.deliveriesCount, {
                    number: deliveryDates.length,
                  }),
                })}
              </TertiaryText>
            </HeadingColumn>

            <Column borderedLeft px={24} width={1} pb={24}>
              <TertiaryText bold>
                {formatMessage(messages.deliveryPlace)}
              </TertiaryText>
            </Column>

            {!(
              hideDues &&
              firstDeliveryLogisticLackAmount <= 0 &&
              isGreenScenario
            ) && (
              <Column borderedLeft px={24} width={1} pb={24}>
                <TertiaryText bold>
                  {formatMessage(messages.finances)}:
                </TertiaryText>
              </Column>
            )}
          </Flex>
        )}
      </Flex>

      <TailColumn>
        <InfoBox>
          <InfoRow label="amountNet" value={formatPrice(totalNet)} />

          <InfoRow
            label="orderVAT"
            value={formatPrice(totalGross - totalNet)}
          />

          <InfoRow label="delivery" value={formatMessage(messages.free)} />
        </InfoBox>

        <Divider />

        <InfoBox>
          <InfoRow
            label="paymentMethod"
            value={capitalize(userData.paymentMethodCode)}
          />

          <InfoRow
            label="paymentDate"
            value={capitalize(userData.paymentTermCode)}
          />
        </InfoBox>

        {hasFloatingPrice ? <CartFloatingPriceInfo /> : <Divider />}

        <InfoMsg>
          <Flex>
            <TinyTextFaded>
              {formatMessage(messages.amountCanChange)}
            </TinyTextFaded>
            <Box ml={16}>
              <InfoCircle />
            </Box>
          </Flex>
        </InfoMsg>
      </TailColumn>
    </RowContainer>
  )
}

export default SummaryRow
