import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { BaseText } from 'components/Text'

import { APP_BREAKPOINTS } from 'consts'

// prettier-ignore
export const PayBtnWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 ${theme.spacing.base};
  width: 100%;
  height: 100%;

  ${p => p.disableCursor && `
    pointer-events: none;
  `};

  ${p => p.bgHover && `
    :hover {
      background: ${p.bgHover};
    }
  `}

  @media screen and (max-width: ${APP_BREAKPOINTS.TMP_MAIN - 1}px) {
    height: auto;
    padding-top: ${theme.spacing.xs};
    padding-bottom: calc(${theme.spacing.xs} + ${p => p.isFixed ? 'env(safe-area-inset-bottom)' : '0px'});
  }
`

export const BtnTitle = styled(BaseText)`
  font-size: 16px;
  line-height: 20px;
  ${p => p.withMargin && `margin-right: ${theme.spacing.base}`};
`

export const PriceWrapper = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
  padding-right: ${theme.spacing.sm};
`
