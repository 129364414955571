import styled from 'styled-components'

import theme from 'theme'
import { Link } from 'components/Text'

export const ContactHeader = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${theme.spacing.ss} ${theme.spacing.sm};
  gap: ${theme.spacing.sm};
  background: ${theme.colors.lightGrey7};
  border-radius: ${theme.borderRadius.base};
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: ${theme.colors.gray6};
  }
`

export const ContactsList = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${theme.spacing.xxs};
`
