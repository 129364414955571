import styled from 'styled-components'

// prettier-ignore
export const DateCardContainer = styled.div`
  width: ${p => 100 / p.itemsPerPage}%;
  display: flex;
  flex-shrink: 0;
  flex-flow: column;

  ${p => p.isEvenlyDisrtibuted && `
    width: auto;
    flex: 1;
  `}

  &:not(:last-of-type) {
    margin-right: 2px;
    width: calc(${p => 100 / p.itemsPerPage}% - 2px);

    ${p => p.isEvenlyDisrtibuted && `
      width: auto;
      flex: 1;
    `}
  }
`
