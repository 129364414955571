import React from 'react'

import { cn } from 'utils'

export const BUTTON_VARIANTS = {
  primary: 'bg-green-600 hover:bg-green-500 text-white',
  secondary: 'bg-grey-200 hover:bg-grey-400 text-blue-900',
  tertiary: 'hover:bg-grey-200 text-blue-900',
  alert: 'bg-red-600 hover:bg-red-500 text-white text-white',
  warning: 'bg-orange-100 hover:bg-orange-300 text-orange-900',
}

const TwButton = ({
  children,
  className,
  variant = BUTTON_VARIANTS.primary,
  ...props
}) => (
  <button
    type="button"
    className={cn(
      'group py-2 px-4 rounded-lg duration-300 ease-out disabled:bg-grey-400',
      variant,
      className,
    )}
    {...props}
  >
    <div className="group-disabled:text-grey-600 group-disabled:bg-grey-400">
      {children}
    </div>
  </button>
)

export default TwButton
