import styled from 'styled-components'

import theme from 'theme'

export const ProductCount = styled.span`
  margin-left: ${theme.spacing.xs};
  color: ${theme.colors.gray5};
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.xxs};
`
