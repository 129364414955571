import styled from 'styled-components'

import theme from 'theme'

const Content = styled.div`
  background-color: #f7f5f7;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 100px;
  margin: auto;
  box-shadow: 0 0 10px rgba(165, 165, 165, 0.4);
  z-index: ${theme.zIndex.loaderOverlay + 1};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`

export default Content
