import { reduce, map, find, get, flow, toNumber, isBoolean } from 'lodash/fp'

export const makeValidate = items => values =>
  reduce(
    (acc, item) => ({
      ...acc,
      ...(!values[item.productId] &&
        values[item.productId] !== 0 && { [item.productId]: true }),
    }),
    {},
  )(items)

export const makeFormToApiValues = items =>
  map.convert({ cap: false })((val, key) => ({
    product: { id: key },
    units: [
      {
        quantity: isBoolean(val) ? +!val : toNumber(val),
        unitOfMeasure: flow(
          find({ productId: key }),
          get('unitOfMeasure'),
        )(items),
      },
    ],
  }))
