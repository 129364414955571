export const AUTH_REDUCER_NAME = 'auth'

export const LOGIN_REDUCER_NAME = 'login'
export const LOGIN_FORM_NAME = 'login'

export const SIGN_UP_REDUCER_NAME = 'signUp'
export const SIGN_UP_FORM_NAME = 'signUp'
export const SIGN_UP_MIGRATE_REDUCER_NAME = 'signUpMigrate'
export const SIGNUP_MIGRATE_FORM_NAME = 'signupMigrate'

export const PASSWORD_REDUCER_NAME = 'password'
export const PASSWORD_FORM_NAME = 'password'

export const CONFIRM_EMAIL_REDUCER_NAME = 'confirmEmail'

export const RESEND_CONFIRM_REDUCER_NAME = 'resendConfirm'

export const VALIDATE_TOKEN_REDUCER_NAME = 'validateToken'

export const RESEND_SET_PASSWORD_REDUCER_NAME = 'resendSetPassword'

export const LOGOUT_REASON_REDUCER_NAME = 'logoutReason'
export const CHECK_LOGIN_REDUCER_NAME = 'checkLogin'

export const ACCOUNT_ERRORS = {
  EXISTS: 10111120101,
  REGISTRATION_IN_PROGRESS: 10111120102,
  NOT_VERIFIED: 10111120104,
}

export const VALIDATE_ERRORS = {
  INVALID: 10212134114,
  EXPIRED: 10212134115,
  ALREADY_USED: 10212134117,
}

export const CONFIRM_ERRORS = {
  INVALID: 10111121103,
  EXPIRED: 10111121106,
  ALREADY_IN_PROGRESS: 10111121108,
  VERIFIED: 10111121109,
}

export const LOGOUT_REASONS = {
  SESSION_EXPIRED: 'expired',
}

export const SIGNUP_LOGIN_ERRORS = {
  ALREADY_TAKEN: 10111164110,
}

export const SIGNUP_MIGRATE_ERRORS = {
  EXPIRED: 10111178173,
}

export const LOGIN_ERRORS = {
  INVALID_CREDS: 10101021020,
}
