import { fromJS } from 'immutable/dist/immutable'

import {
  NOTIFICATIONS_OPEN,
  NOTIFICATIONS_HIDE,
} from '../actions/notifications'

const initialState = fromJS({
  isOpen: false,
  message: null,
  type: '',
})

const notificationsReducer = (state = initialState, action) => {
  const { data } = action

  switch (action.type) {
    case NOTIFICATIONS_OPEN: {
      return state
        .set('isOpen', true)
        .set('message', data.message)
        .set('type', data.type)
    }
    case NOTIFICATIONS_HIDE: {
      return state
        .set('isOpen', false)
        .set('message', null)
        .set('type', '')
    }
    default:
      return state
  }
}

export default notificationsReducer
