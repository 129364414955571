import React from 'react'

const Card = ({ color = '#131B4D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <rect
      width="22"
      height="16"
      x="1"
      y="4"
      stroke={color}
      strokeWidth="2"
      rx="3"
    />
    <path stroke={color} strokeLinecap="round" strokeWidth="2" d="M15 15h4" />
    <path fill={color} d="M1 8H23V11H1z" />
  </svg>
)

export default Card
