import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { getSpacing } from 'components/helpers/theme-selectors'

import { HintBox } from '../styles'

export const Counter = styled(Flex)`
  justify-content: space-between;
  padding-top: ${props => getSpacing(props).xs};
  padding-right: ${props => getSpacing(props).sm};
`

export const HintError = styled(HintBox)`
  margin-top: 0;
`
