import styled from 'styled-components'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { BaseTextV2 } from 'components/Text'

export const BannerContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  background-color: ${theme.colors.infoBlue4};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  margin: ${theme.spacing.xs} 0;
  border-radius: ${theme.borderRadius.base};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    margin-top: ${theme.spacing.sm};
    margin-bottom: 0;
  }
`

export const BannerTextContainer = styled.div`
  padding: 2px ${theme.spacing.xs};
`

export const BannerText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  color: ${theme.colors.infoBlue2};
`
