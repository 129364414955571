import React from 'react'

const NotificationBell = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 3.13401 5.13401 0 9 0C12.866 0 16 3.13401 16 7V8.81966C16 9.44064 16.1446 10.0531 16.4223 10.6085L17.8944 13.5528C18.0494 13.8628 18.0329 14.2309 17.8507 14.5257C17.6684 14.8205 17.3466 15 17 15H1C0.653423 15 0.331557 14.8205 0.14935 14.5257C-0.0328564 14.2309 -0.0494204 13.8628 0.105574 13.5528L1.57771 10.6085C1.85542 10.0531 2 9.44064 2 8.81966V7ZM9 2C6.23858 2 4 4.23858 4 7V8.81966C4 9.75113 3.78313 10.6698 3.36656 11.5029L2.61803 13H15.382L14.6334 11.5029C14.2169 10.6698 14 9.75113 14 8.81966V7C14 4.23858 11.7614 2 9 2Z"
      fill="#131B4D"
    />
    <path
      d="M7 16H9H11C11 17.1046 10.1046 18 9 18C7.89543 18 7 17.1046 7 16Z"
      fill="#131B4D"
    />
  </svg>
)

export default NotificationBell
