import styled from 'styled-components'
import { FlexColumns } from 'layouts'

import { getSpacing } from 'components/helpers/theme-selectors'

export const PkgColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-right: ${props => getSpacing(props).base};
`

export const DetailsContainer = styled(FlexColumns)``
