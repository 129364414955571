import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { identity } from 'lodash/fp'

import ListInput from 'components/Input/ListInput'

import { notifySuccess } from 'components/Product/utils'
import { TEMPLATE_TYPES } from '../consts'
import TemplatesTopSegment from './TemplatesTopSegment'
import TemplateListItem from './TemplateListItem'

import messages from '../messages'
import {
  TemplatesListContainer,
  CreateTemplateContainer,
  ScrollableListContainer,
  StickyContainer,
} from './styles'

const TemplatesListContent = ({
  templates = [],
  bidfoodTemplate = null,
  isMobile = false,
  isInputOpen = false,
  openInput = identity,
  closeInput = identity,
  createTemplate = identity,
  closeDropdown = identity,
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [openedMenuTemplateId, setOpenedMenuTemplateId] = useState(null)
  const [editingTemplateId, setEditingTemplateId] = useState(null)

  const handleOnEdit = id => {
    setOpenedMenuTemplateId(null)
    setEditingTemplateId(id)
  }

  const handleInputBlur = e => {
    if (!e.target.value) {
      closeInput()
    }
  }

  const onCreateTemplateSuccess = () => {
    closeInput()
    dispatch(notifySuccess(formatMessage(messages.createNotification)))
  }

  const listContents = (
    <>
      {isInputOpen && (
        <CreateTemplateContainer data-test-id="create-template-input">
          <ListInput
            onSubmit={createTemplate}
            onClose={closeInput}
            onBlur={handleInputBlur}
            onSuccess={onCreateTemplateSuccess}
          />
        </CreateTemplateContainer>
      )}

      {templates.map(
        ({ id, name, type, itemsCount = 0 }) =>
          type === TEMPLATE_TYPES.CUSTOMER ? (
            <TemplateListItem
              hasMenu
              key={id}
              id={id}
              name={name}
              itemsCount={itemsCount}
              onClose={closeDropdown}
              isEditing={id === editingTemplateId}
              onEdit={handleOnEdit}
              onEditComplete={() => setEditingTemplateId(null)}
              isMenuOpen={id === openedMenuTemplateId}
              onOpenMenu={setOpenedMenuTemplateId}
              onCloseMenu={() => setOpenedMenuTemplateId(null)}
            />
          ) : null,
      )}
    </>
  )

  return !isMobile ? (
    <div style={{ flexGrow: 1 }}>
      <StickyContainer>
        <TemplatesTopSegment
          {...{ bidfoodTemplate, isMobile, closeDropdown, openInput }}
        />
        <ScrollableListContainer data-test-id="templates_list">
          {listContents}
        </ScrollableListContainer>
      </StickyContainer>
    </div>
  ) : (
    <TemplatesListContainer>{listContents}</TemplatesListContainer>
  )
}

export default TemplatesListContent
