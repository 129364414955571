import React, { useRef, useEffect, useState, useMemo } from 'react'
import usePrevious from 'use-previous'
import { getTopHeaderOffset } from 'utils/dom'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Sticky from 'react-stickynode'
import { Flex } from '@rebass/grid'
import { capitalize } from 'lodash/fp'
import dayjs from 'dayjs'

import { useOfMinWidth } from 'hooks'
import { DESKTOP_HEIGHT } from 'containers/App/LoggedInLayout/Header/styles'
import { selectCartInfoData } from 'containers/Cart/selectors'
import { makeDeliveryStickyId, formatDateForApi } from 'containers/Cart/utils'
import { updateDeliveryDate as updateDeliveryDateAction } from 'containers/Cart/actions'
import {
  useGetStorageValue,
  CONFIG_SCHEMA_KEYS,
  CONFIG_API_PATHS_MAP,
} from 'containers/Storage'
import { useUpdateStorageMutation } from 'services/api/rtkApi'
import { HintModal, HintOverlay } from 'components'
import {
  ArrowUp,
  ArrowDownNew,
  Calendar,
  CalendarChange,
} from 'components/Icons'
import { BoldInfo, TertiaryText, TinyText } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'
import messages from 'views/Cart//messages'

import Comment from './Comment'
import DeliveryCalendar from './DeliveryCalendar'
import RouteSection from './RouteSection'
import { dateHintModalConfig, commentHintModalConfig } from './hintModalConfig'
import {
  DeliveryContainer,
  StyledText,
  DeliveryDateContainer,
  MobileDeliveryContainer,
  MobileDeliveryHeader,
  MobileMoveAllWrapper,
} from './styledComponents'

const DeliveryTimeSlots = ({
  selectedDelivery: {
    deliveryDate,
    routeId,
    routeName,
    isFirstCartDelivery,
  } = {},
  selectedDelivery,
  onlyIncoming,
  itemsNumber,
  activeProductIds,
  dateChanging,
  toggleDateChanging,
  routeChanging,
  toggleRouteChanging,
}) => {
  const isMainRes = useOfMinWidth(APP_BREAKPOINTS.TMP_MAIN)
  const dispatch = useDispatch()
  const [dateHintIndex, setDateHintIndex] = useState(-1)
  const [commentHintIndex, setCommentHintIndex] = useState(-1)
  const [mobileExpanded, setMobileExpanded] = useState(false)
  const dateObj = dayjs(deliveryDate)
  const dateHintEnabled =
    useGetStorageValue(CONFIG_SCHEMA_KEYS.CART_GUIDE.CHANGE_DATE_ALL) &&
    isFirstCartDelivery

  const [updateStorage] = useUpdateStorageMutation()

  const cartData = useSelector(selectCartInfoData)
  // get all productIds by route and date, even for inactive products
  const deliveryByRoute = useMemo(
    () =>
      cartData?.deliveries.find(
        d => d.date === formatDateForApi(deliveryDate) && d.routeId === routeId,
      ),
    [cartData, deliveryDate, routeId],
  )
  const allProductIds = deliveryByRoute?.productIds

  const firstUpdate = useRef(true)
  const prevProductsLength = usePrevious(activeProductIds.length)
  const idName = makeDeliveryStickyId(deliveryDate, routeId)
  useEffect(
    () => {
      if (firstUpdate.current) {
        firstUpdate.current = false
        return
      }

      if (activeProductIds.length > prevProductsLength) {
        const node = document.getElementById(idName)
        const y = node.getBoundingClientRect().top + window.scrollY

        window.scroll({
          top: y - getTopHeaderOffset() - 12,
          behavior: 'smooth',
        })
      }
    },
    [activeProductIds],
  )

  const updateDeliveryDate = args => dispatch(updateDeliveryDateAction(args))

  const changeDateIconRendered = (
    <HintOverlay
      enabled={dateHintEnabled}
      withTooltip
      tooltipText={<FormattedMessage {...messages.moveAllProducts} />}
      clickHandler={e => {
        e.stopPropagation()
        setDateHintIndex(0)
        toggleDateChanging()
      }}
    >
      <CalendarChange
        style={{ cursor: 'pointer' }}
        onClick={e => {
          e.stopPropagation()
          if (dateHintEnabled) setDateHintIndex(0)
          toggleDateChanging()
        }}
      />
    </HintOverlay>
  )
  const deliveryCalendarRendered = dateChanging && (
    <DeliveryCalendar
      {...!isMainRes && { justifyContent: 'space-between', width: '100%' }}
      selectedDeliveryDate={dateObj}
      dateChangeHandler={date => {
        updateDeliveryDate({
          deliveryDate: date,
          prevRouteId: routeId,
          productIds: allProductIds,
        })
        toggleDateChanging()
      }}
      isGroupChange
      productIds={allProductIds}
    />
  )
  const routeSectionRendered = !onlyIncoming && (
    <RouteSection
      {...{
        routeChanging,
        toggleRouteChanging,
        onlyIncoming,
        updateDeliveryDate,
        selectedDelivery,
      }}
      allProductIds={allProductIds}
      activeProductIds={activeProductIds}
    />
  )
  const commentSectionRendered = !onlyIncoming && (
    <Comment
      {...{
        selectedDelivery,
        setCommentHintIndex,
      }}
    />
  )

  return (
    <>
      {isMainRes ? (
        <DeliveryContainer id={idName}>
          <Sticky
            enabled={itemsNumber > 2}
            top={DESKTOP_HEIGHT}
            bottomBoundary={`#${idName}`}
          >
            <DeliveryDateContainer {...{ onlyIncoming }}>
              <Flex>
                <Calendar number={dateObj.format('D')} />

                <Flex flexDirection="column" justifyContent="center" ml="18px">
                  <StyledText>
                    {capitalize(dateObj.format('D MMMM').split(' ')[1])}
                  </StyledText>

                  <BoldInfo>{capitalize(dateObj.format('dddd'))}</BoldInfo>
                </Flex>
              </Flex>

              {!onlyIncoming && changeDateIconRendered}
            </DeliveryDateContainer>

            {deliveryCalendarRendered}

            {!onlyIncoming && (
              <Flex height={routeChanging ? 'auto' : 73}>
                {routeSectionRendered}
              </Flex>
            )}

            {commentSectionRendered}
          </Sticky>
        </DeliveryContainer>
      ) : (
        <MobileDeliveryContainer
          sticky={itemsNumber > 2}
          id={idName}
          data-test-id={idName}
        >
          <MobileDeliveryHeader
            data-test-id={`${idName}-header`}
            height={64}
            clickable={!onlyIncoming}
            onClick={() => {
              if (!onlyIncoming) {
                setMobileExpanded(!mobileExpanded)
              }
            }}
          >
            <Flex>
              <Calendar number={dateObj.format('D')} />

              <Flex flexDirection="column" justifyContent="center" ml={16}>
                <TertiaryText>
                  {capitalize(dateObj.format('D MMMM').split(' ')[1])},{' '}
                  <b>{capitalize(dateObj.format('dddd'))}</b>
                </TertiaryText>

                <TinyText>
                  {routeName ? (
                    <FormattedMessage
                      {...messages.selectedRouteInfo}
                      values={{ value: routeName }}
                    />
                  ) : (
                    !onlyIncoming && (
                      <FormattedMessage {...messages.differentRoutes} />
                    )
                  )}
                </TinyText>
              </Flex>
            </Flex>

            {!onlyIncoming && (mobileExpanded ? <ArrowUp /> : <ArrowDownNew />)}
          </MobileDeliveryHeader>

          {mobileExpanded && (
            <>
              <MobileMoveAllWrapper
                height={57}
                onClick={() => toggleDateChanging()}
              >
                <Flex alignItems="center">
                  <Flex ml="8px" mr={24}>
                    {changeDateIconRendered}
                  </Flex>

                  <TertiaryText>
                    <FormattedMessage {...messages.moveAll} />
                  </TertiaryText>
                </Flex>

                {dateChanging ? <ArrowUp /> : <ArrowDownNew />}
              </MobileMoveAllWrapper>

              {dateChanging && (
                <Flex>
                  <Flex pl="8px" width="100%">
                    {deliveryCalendarRendered}
                  </Flex>
                </Flex>
              )}

              {!onlyIncoming && (
                <Flex minHeight={57}>{routeSectionRendered}</Flex>
              )}

              <Flex>{commentSectionRendered}</Flex>
            </>
          )}
        </MobileDeliveryContainer>
      )}

      {dateHintIndex > -1 && (
        <HintModal
          {...dateHintModalConfig[dateHintIndex]}
          onClose={() => {
            setDateHintIndex(-1)
            updateStorage({
              path: CONFIG_API_PATHS_MAP.CART_GUIDE.CHANGE_DATE_ALL,
              value: false,
            })
          }}
        />
      )}

      {commentHintIndex > -1 && (
        <HintModal
          {...commentHintModalConfig[commentHintIndex]}
          onClose={() => {
            setCommentHintIndex(-1)
            updateStorage({
              path: CONFIG_API_PATHS_MAP.CART_GUIDE.COMMENTS,
              value: false,
            })
          }}
        />
      )}
    </>
  )
}

export default DeliveryTimeSlots
