import React from 'react'

import theme from 'theme'

const NoFeedMessages = ({ color = theme.colors.secondary }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 7C4.5 5.34315 5.84315 4 7.5 4H17.5C19.1569 4 20.5 5.34315 20.5 7V10.3057C20.5 11.2834 19.7932 12.1178 18.8288 12.2785L15.2046 12.8826C14.7831 12.9528 14.4528 13.2831 14.3826 13.7046L13.9178 16.4932C13.6767 17.9398 12.4251 19 10.9586 19H6.66228C4.6146 19 3.19349 16.9991 3.79425 15.0415C4.5 12.7418 4.5 12.12 4.5 10V7Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M4.5 9H20.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M8.5 5V3" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M16.5 5V3" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path
      d="M4.5 18V18C4.5 19.1046 5.39543 20 6.5 20H17.5C19.1569 20 20.5 18.6569 20.5 17V9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 19C16.5 19 20.5 13 20.5 11"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default NoFeedMessages
