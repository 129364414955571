import { DASHBOARD_LAYOUT, DASHBOARD_TILE_OPEN } from 'views/Dashboard/consts'

export const getBrowserDashboardLayout = () => {
  const value = localStorage.getItem(DASHBOARD_LAYOUT)
  if (value) {
    return JSON.parse(value)
  }
  return false
}

export const setBrowserDashboardLayout = state => {
  localStorage.setItem(DASHBOARD_LAYOUT, state)
}

export const getBrowserDashboardTileOpen = () => {
  const value = localStorage.getItem(DASHBOARD_TILE_OPEN)
  if (value) {
    return JSON.parse(value)
  }
  return false
}

export const setBrowserDashboardTileOpen = state => {
  localStorage.setItem(DASHBOARD_TILE_OPEN, state)
}
