import React from 'react'
import { ROUTES } from 'consts'
import { useNavigate } from 'react-router-dom'

import Panel from '../Panel'
import { Title, SecondaryTitle, CtaBtn } from './styledComponents'

const ConfirmPanel = ({ title, secondaryTitle, ctaBtnTitle }) => {
  const navigate = useNavigate()

  return (
    <Panel>
      <Title strongBold data-test-id="confirm_panel_title">
        {title}
      </Title>

      <SecondaryTitle>{secondaryTitle}</SecondaryTitle>

      <CtaBtn onClick={() => navigate(ROUTES.LOGIN)}>{ctaBtnTitle}</CtaBtn>
    </Panel>
  )
}

export default ConfirmPanel
