import React from 'react'

import User from './User'
import CartButton from './CartButton'
import NotificationCenter from '../NotificationCenter'
import { UserControlsWrapper } from './styles'

const UserControls = ({ userBtnStyles, cartBtnStyles }) => (
  <UserControlsWrapper>
    <NotificationCenter />
    <User {...{ userBtnStyles }} />
    <CartButton {...{ cartBtnStyles }} />
  </UserControlsWrapper>
)

export default UserControls
