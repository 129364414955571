import styled from 'styled-components'

import { Flex } from '@rebass/grid'
import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'

export const Container = styled(Flex)`
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: ${theme.spacing.base};
  max-width: 311px;
  flex-direction: column;
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    max-width: 640px;
    flex-direction: row;
  }
`

export const LeadRow = styled(Flex)`
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const MessageContainer = styled(Flex)`
  flex-direction: column;
  gap: ${theme.spacing.base};
  text-align: center;
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    text-align: left;
  }
`

export const Link = styled.a`
  color: ${theme.colors.secondary};
  text-decoration: none;
  border-bottom: 1px solid;
`

export const IconContainer = styled(Flex)`
  flex-shrink: 0;
`
