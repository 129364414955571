import React from 'react'

import theme from 'theme'

const NoTemplates = ({ color = theme.colors.primary }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12C12 10.3431 13.3431 9 15 9H33C34.6569 9 36 10.3431 36 12V39.7974C36 41.0692 34.5167 41.7639 33.5397 40.9498L25.2804 34.067C24.5387 33.4489 23.4613 33.4489 22.7196 34.067L14.4603 40.9498C13.4833 41.7639 12 41.0692 12 39.7974V12Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 27L29 17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M19 17L29 27"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default NoTemplates
