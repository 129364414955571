import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { ICONS, ICONS_POSITION } from 'components/Icon'
import { BUTTON_SIZE } from 'components/Button'

import messages from '../messages'

import { StarBtnDrawer } from './styledComponents'

const StarBtn = ({
  onStarClick,
  active,
  productId,
  children,
  hasTextStarBtn,
  className,
  ...rest
}) => (
  <StarBtnDrawer
    active={active}
    onClick={onStarClick}
    size={BUTTON_SIZE[hasTextStarBtn ? 'SMALL' : 'ICON']}
    iconType={ICONS.STAR}
    iconPosition={ICONS_POSITION[hasTextStarBtn ? 'LEFT' : 'CENTER']}
    data-test-id={`${productId}_star${active ? '_filled' : ''}`}
    {...{ className }}
    {...rest}
  >
    {children || (
      <FormattedMessage
        {...(active ? messages.removeFromTemplate : messages.addToTemplate)}
      />
    )}
  </StarBtnDrawer>
)

export default StarBtn

StarBtn.propTypes = {
  active: PropTypes.bool,
  isProductDetails: PropTypes.bool,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
}
