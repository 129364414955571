import React from 'react'

import {
  SectionTitleContainer,
  SectionTitle,
  SectionItemsContainer,
} from './styles'

const NotificationFeedSection = ({ children, title }) => (
  <>
    {title && (
      <SectionTitleContainer>
        <SectionTitle>{title}</SectionTitle>
      </SectionTitleContainer>
    )}
    <SectionItemsContainer>{children}</SectionItemsContainer>
  </>
)

export default NotificationFeedSection
