import styled from 'styled-components'

import { H5Strong } from 'components/Text'
import { getSpacing } from 'components/helpers/theme-selectors'

export const Container = styled.div`
  margin-top: ${props => getSpacing(props).base};
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${props => getSpacing(props).base} auto
    ${props => getSpacing(props).sm};
`

export const MonthHeader = styled(H5Strong)`
  margin-bottom: ${props => getSpacing(props).sm};
`

export const ItemsGroupList = styled.section`
  &:not(:last-of-type) {
    margin-bottom: ${props => getSpacing(props).md};
  }
`
