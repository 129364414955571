import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

import { TinyTextV2Medium } from 'components/Text'

export const OrderStatusTagContainer = styled.div`
  border-radius: 6px;
  padding: 0 ${theme.spacing.xxs};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: 0 ${theme.spacing.xs};
  }
`

export const OrderStatusTagText = styled(TinyTextV2Medium)`
  line-height: 20px;

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    font-size: ${theme.textSizes.sm};
    line-height: 24px;
  }
`
