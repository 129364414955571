import { combineReducers } from 'redux-immutable'

import { createReducer } from 'utils/simpleDataReducer'

import {
  DELIVERY_DATES_BY_PRODUCTS_REDUCER_NAME,
  ALL_DELIVERY_DATES_REDUCER_NAME,
  USER_DELIVERIES_REDUCER_NAME,
} from './consts'
import {
  getDeliveryDatesByProducts,
  getAllDeliveryDates,
  getUserDeliveries,
} from './actions'

export default combineReducers({
  [DELIVERY_DATES_BY_PRODUCTS_REDUCER_NAME]: createReducer(
    getDeliveryDatesByProducts,
  ),
  [ALL_DELIVERY_DATES_REDUCER_NAME]: createReducer(getAllDeliveryDates),
  [USER_DELIVERIES_REDUCER_NAME]: createReducer(getUserDeliveries),
})
