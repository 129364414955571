import React from 'react'

const Croissant = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_34121_130852)">
      <path
        d="M2.64342 10.5133C2.58253 10.4037 2.43367 10.3829 2.34502 10.4715L1.67071 11.1458C0.801143 12.0154 1.37796 13.5039 2.60641 13.5604L4.04372 13.6265C4.19132 13.6333 4.28911 13.4755 4.21736 13.3464L2.64342 10.5133Z"
        fill="#A6B3C0"
      />
      <path
        d="M13.3477 4.21668C13.4769 4.28843 13.6347 4.19064 13.6279 4.04304L13.5617 2.6051C13.5052 1.37665 12.0167 0.799836 11.1471 1.6694L10.4724 2.34411C10.3838 2.43276 10.4046 2.58162 10.5142 2.6425L13.3477 4.21668Z"
        fill="#A6B3C0"
      />
      <path
        d="M4.48134 6.71272C4.24392 6.33962 4.29748 5.85169 4.61019 5.53898L5.54134 4.60783C5.85405 4.29512 6.34198 4.24156 6.71508 4.47899L12.4232 8.1114C12.9324 8.43547 13.0106 9.14777 12.5838 9.5746L9.57696 12.5814C9.15013 13.0082 8.43783 12.93 8.11376 12.4208L4.48134 6.71272Z"
        fill="#A6B3C0"
      />
      <path
        d="M4.0348 7.77135C3.96998 7.6695 3.82752 7.65386 3.74216 7.73923L3.24179 8.2396C2.94391 8.53748 2.87959 8.99674 3.08417 9.36499L5.46695 13.654C5.77138 14.202 6.51566 14.3062 6.95892 13.863L7.43977 13.3821C7.51335 13.3085 7.51266 13.1898 7.44695 13.1091C7.40038 13.0519 7.35654 12.9912 7.31577 12.9272L4.0348 7.77135Z"
        fill="#A6B3C0"
      />
      <path
        d="M13.1097 7.44638C13.1904 7.51208 13.3091 7.51278 13.3827 7.43919L13.8632 6.95863C14.3065 6.51538 14.2023 5.77109 13.6543 5.46666L9.36528 3.08388C8.99703 2.8793 8.53777 2.94362 8.23988 3.2415L7.73981 3.74158C7.65444 3.82694 7.67008 3.9694 7.77193 4.03422L12.9278 7.3152C12.9918 7.35596 13.0525 7.3998 13.1097 7.44638Z"
        fill="#A6B3C0"
      />
    </g>
    <defs>
      <clipPath id="clip0_34121_130852">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Croissant
