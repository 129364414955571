import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { includes } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'
import { isNil, omit, propEq } from 'lodash/fp'

import theme from 'theme'
import { ICONS_POSITION } from 'components/Icon'
import { CartV2, MinusV2, PlusV2 } from 'components/Icons'
import { BUTTON_SIZE } from 'components/Button'
import { increaseAmount, decreaseAmount } from 'components/Product/utils'
import { isTooltipOpenSelector } from 'containers/App/selectors'
import CatalogProductLicense from 'components/Product/ProductLicense/CatalogProductLicense'

import messages from 'components/Product/messages'
import {
  CatalogButtonsWrapper,
  CatalogAddProductsButton,
  CatalogAlternateBtn,
  CatalogProductButtonsContainer,
  CatalogAmount,
  AmountForm,
} from './styles'

const CatalogProductButtons = ({
  Wrapper = CatalogButtonsWrapper,
  unitData,
  amount = unitData.multiplier,
  disableTrash,
  trashInsteadOfMinus = !disableTrash && Number(amount) === unitData.multiplier,
  onCartClick,
  onTrashClick,
  onInputChange,
  onInputBlur,
  onInputFocus,
  isInCart,
  cartBtnDisabled,
  hideCart,
  active,
  fontSize = 13,
  productId,
  onPlusClick,
  onMinusClick,
  isVariants,
  maxValue,
  disableInput,
  neverDisableMinus,
  product,
  buttonIconsProps,
  showCartTitle,
  ...rest
}) => {
  const location = useLocation()
  const { formatMessage } = useIntl()

  const iconProps = {
    size: BUTTON_SIZE.ICON,
    iconPosition: ICONS_POSITION.CENTER,
  }

  const isTooltipOpen = useSelector(isTooltipOpenSelector(productId))

  const handlePlusClick = useCallback(
    () => {
      const increasedAmount = increaseAmount(unitData, amount)
      onPlusClick(increasedAmount)
    },
    [unitData, amount],
  )

  const handleMinusClick = useCallback(
    () => {
      const decreasedAmount = decreaseAmount(unitData, amount)
      if (decreasedAmount > 0) {
        onMinusClick(decreasedAmount)
      }
    },
    [unitData, amount],
  )

  return (
    <Wrapper
      noSpaceBetween={includes(location.pathname, 'templates')}
      {...omit(['onNotifyStockUnavailable'], rest)}
      unitData={unitData}
      col="12"
    >
      <Flex width="100%">
        {propEq('licenseMissing', true)(product) && !isInCart ? (
          <CatalogProductLicense {...{ product }} />
        ) : (
          <>
            {!hideCart && !isInCart ? (
              <CatalogAddProductsButton
                ml={theme.spacing.xs}
                icon={<CartV2 />}
                onClick={onCartClick}
                data-test-id={`cart_${productId}`}
                disabled={cartBtnDisabled}
              >
                {showCartTitle && formatMessage(messages.toCart)}
              </CatalogAddProductsButton>
            ) : (
              <CatalogProductButtonsContainer
                {...{ isVariants, isTooltipOpen }}
              >
                <CatalogAlternateBtn
                  {...iconProps}
                  disabled={
                    isTooltipOpen ||
                    (!neverDisableMinus &&
                      !trashInsteadOfMinus &&
                      Number(amount) === unitData.multiplier)
                  }
                  data-test-id={`minus_${productId}`}
                  onClick={
                    trashInsteadOfMinus ||
                    Number(amount) === unitData.multiplier
                      ? onTrashClick
                      : handleMinusClick
                  }
                >
                  <MinusV2 {...buttonIconsProps} />
                </CatalogAlternateBtn>
                <AmountForm
                  onSubmit={e => {
                    e.preventDefault()
                    onCartClick()
                  }}
                >
                  <CatalogAmount
                    disabled={isTooltipOpen || disableInput}
                    data-test-id={`input_${productId}`}
                    style={{ fontSize }}
                    value={amount}
                    onFocus={onInputFocus}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    displayUnit={unitData.unitOfMeasureDescription}
                  />
                </AmountForm>
                <CatalogAlternateBtn
                  onClick={handlePlusClick}
                  data-test-id={`plus_${productId}`}
                  {...iconProps}
                  disabled={
                    isTooltipOpen || (!isNil(maxValue) && amount >= maxValue)
                  }
                >
                  <PlusV2 {...buttonIconsProps} />
                </CatalogAlternateBtn>
              </CatalogProductButtonsContainer>
            )}
          </>
        )}
      </Flex>
    </Wrapper>
  )
}

export default CatalogProductButtons
