import styled from 'styled-components'

import theme from 'theme'
import { BaseTextV2 } from 'components/Text'

export const Container = styled.div`
  padding: ${theme.spacing.base} 0;
  display: flex;
  flex-direction: column;
  max-width: 240px;
  width: 100%;
  gap: ${theme.spacing.sm};
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const Text = styled(BaseTextV2)`
  font-size: 12px;
  line-height: 20px;
  text-align: center;
`

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.greenLight5};
  border-radius: 50%;
`
