import React from 'react'

import { SecondaryText } from 'components/Text'

import { BADGE_DIRECTION, BADGE_STATUS } from './consts'
import { BadgeContainer, Separator } from './styles'

const Badge = ({
  direction = BADGE_DIRECTION.LEFT,
  status = BADGE_STATUS.SUCCESS,
  elements = [],
}) => (
  <BadgeContainer {...{ direction, status }}>
    {elements.map((elem, index) => (
      <React.Fragment key={`badge_${elem}`}>
        <SecondaryText bold>{elem}</SecondaryText>
        {index !== elements.length - 1 && <Separator />}
      </React.Fragment>
    ))}
  </BadgeContainer>
)

export default Badge
