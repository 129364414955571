import React from 'react'

import { FLOATING_PRICE_VARIANT } from 'components/FloatingPrice/consts'
import { FloatingPriceWrapper } from 'components/FloatingPrice'
import { SquareAlert as AlertIcon } from 'components/Icons'

import { IconContainer } from './styles'

const TotalSumWrapper = ({
  children,
  hasFloatingPrice,
  hasLogisticLimitInfo,
}) => {
  if (hasLogisticLimitInfo) {
    return (
      <IconContainer data-test-id="logistic-limit-info-icon">
        <AlertIcon />
        {children}
      </IconContainer>
    )
  }

  if (hasFloatingPrice) {
    return (
      <FloatingPriceWrapper
        hasFloatingPrice
        iconVariant={FLOATING_PRICE_VARIANT.EXTRA_SMALL}
        containerStyle={{
          alignItems: 'baseline',
        }}
      >
        {children}
      </FloatingPriceWrapper>
    )
  }

  return children
}

export default TotalSumWrapper
