export const CART_INFO_REDUCER_NAME = 'cartInfo'
export const ADDITIONAL_INFO_REDUCER_NAME = 'additionalInfo'

export const CART_ERRORS = {
  LOW_STOCK: 102060500321,
  OUT_OF_STOCK: 102060500322,
  NOT_ENOUGH_STOCK: 10205101093,
  NOT_ALL_ADDED: 10205102095,
}

export const CART_UPDATE_ERROR_CODES = {
  UPDATE_ITEM_IN_CART: 10205101223,
  DELETE_FROM_CART: 10205108223,
  DELETE_UNIT_FROM_CART: 10205109223,
  EMPTY_CART: 10204106223,
}

export const REFETCH = {
  CART: 'cart',
  PDP: 'productDetailPage',
}

export const CART_ITEM_ERROR = {
  UNSUFFICIENT_AMOUNT: 210,
  MISSING_ALCOHOL_LICENSE: 215,
}

export const PROMO_TYPES = {
  DISCOUNT: 'Discount',
  QUANTITIVE: 'Quantitive',
  POINTS_BASED: 'PointsBased',
  SALE: 'Sale',
  VALUE_BASED: 'ValueBased',
}
