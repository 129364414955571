import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { TinyTextV2Secondary } from 'components/Text'
import theme from 'theme'

export const TextTooltipContainer = styled(Flex)`
  flex-direction: column;
  max-width: ${p => (p.$disableMaxWidth ? 'auto' : '220px')};
  padding: 8px 12px;
  box-shadow: rgba(12, 52, 89, 0.12) 0px 4px 36px;
  background-color: ${theme.colors.white};
  border-radius: 8px;
`

export const TextTooltipContent = styled(TinyTextV2Secondary)``
