import { handleActions } from 'redux-actions'

import { updateCartItem, deleteCartItem } from 'containers/Cart/actions'

import { getPromoProduct } from './actions'

const REQUEST_INIT_STATE = {
  isFetching: false,
  isFinished: false,
  error: null,
  data: null,
}

export default handleActions(
  {
    [getPromoProduct.REQUEST]: (state, { data: { id } }) => ({
      ...state,
      [id]: { ...(state[id] || REQUEST_INIT_STATE), isFetching: true },
    }),
    [getPromoProduct.SUCCESS]: (state, { data: { response, id } }) => ({
      ...state,
      [id]: { ...REQUEST_INIT_STATE, isFinished: true, data: response },
    }),
    [getPromoProduct.FAILURE]: (state, { data: { error, id } }) => ({
      ...state,
      [id]: { ...REQUEST_INIT_STATE, isFinished: true, error },
    }),

    [getPromoProduct.CLEAR]: () => ({ ...REQUEST_INIT_STATE }),

    [updateCartItem.SUCCESS]: (state, { data: { cartItem } }) => {
      if (cartItem) {
        const productId = cartItem.product.id
        const inStateProduct = state[productId]
        if (inStateProduct) {
          return {
            ...state,
            [productId]: {
              ...inStateProduct,
              data: cartItem.product,
            },
          }
        }
      }

      return state
    },
    [deleteCartItem.SUCCESS]: (
      state,
      { data: { cartItem }, additionalData: { productId } },
    ) => {
      const inStateProduct = state[productId]
      if (!cartItem && inStateProduct) {
        return {
          ...state,
          [productId]: {
            ...inStateProduct,
            data: {
              ...inStateProduct.data,
              unitsOfMeasure: inStateProduct.data.unitsOfMeasure.map(unit => ({
                ...unit,
                inCartQuantity: 0,
              })),
            },
          },
        }
      }

      return state
    },
  },
  {},
)
