import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { BasicButtonV2GreySmall } from 'components/Button/styles'
import { BaseTextV2 } from 'components/Text'

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.md};
`

export const InnerContainer = styled(Container)`
  gap: ${theme.spacing.sm};
`

export const Text = styled(BaseTextV2).attrs({ isSmall: { lineHeight: true } })`
  white-space: pre;
  text-align: center;
`

export const Btn = styled(BasicButtonV2GreySmall)`
  display: flex;
  gap: ${theme.spacing.sm};
`
