import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2 } from 'components/Text'

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};

  ${p =>
    p.$isHighlighted &&
    `
    margin-left: ${theme.spacing.sm};
  `};
`

export const PrimaryInfoRow = styled(InfoRow)`
  ${p =>
    p.$isHighlighted &&
    `
    background-color: ${theme.colors.infoBlue4};
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    border-radius: ${theme.borderRadius.base};
    margin-left: 0;
  `};
`

export const InfoText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  ${p =>
    p.$isHighlighted &&
    `
    color: ${theme.colors.infoBlue2};
  `};
`
