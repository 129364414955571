import { createClient } from 'contentful'
import { useCallback, useState } from 'react'

import {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST,
} from 'consts'

const client = createClient({
  space: CONTENTFUL_SPACE_ID,
  accessToken: CONTENTFUL_ACCESS_TOKEN,
  environment: CONTENTFUL_ENVIRONMENT,
  host: CONTENTFUL_HOST,
})

export const useContentful = () => {
  const [items, setItems] = useState([])
  const [isLoading, setLoading] = useState(false)

  const fetchEntries = useCallback(async slug => {
    setLoading(true)
    try {
      const response = await client.getEntries({
        'fields.slug': slug,
        content_type: 'documentsPage',
      })

      const entries = response.includes.Entry
      const assets = response.includes.Asset

      const result = response.items[0].fields.documents.map(document => {
        const entry = entries.find(item => item.sys.id === document.sys.id)
        return {
          id: entry.sys.id,
          title: entry.fields.displayName,
          currentFileUrl: assets.find(
            asset => entry.fields.currentFile.sys.id === asset.sys.id,
          ).fields.file.url,
          previousVersionFileUrl: assets.find(
            asset => entry.fields.previousVersionFile?.sys.id === asset.sys.id,
          )?.fields.file.url,
        }
      })

      setItems(result)
    } catch (e) {
      console.log('Error accessing contentful entries:', e)
    } finally {
      setLoading(false)
    }
  }, [])

  return { items, fetchEntries, isLoading }
}
