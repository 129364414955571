import styled from 'styled-components'

import theme from 'theme'

import { BaseTextV2Secondary } from 'components/Text'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.sm} 0;
  height: calc(100% - 33px); // 100% - separator + margin total height
  gap: ${theme.spacing.xs};
  flex-direction: column;
`

export const Text = styled(BaseTextV2Secondary)``
