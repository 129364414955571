import React from 'react'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import theme from 'theme'

import { MaxIconContainer } from 'components/Product/PromoGroup/PrizeRow/PrizeProgress/styles'
import { CheckV3 } from 'components/Icons'
import {
  CircleProgressContainer,
  PointsText,
  AmountText,
  Wrapper,
} from './styles'
import { SIZE } from './consts'

const { colors } = theme
const progressStyles = buildStyles({
  pathColor: colors.secondary,
  trailColor: 'transparent',
  strokeLinecap: 'butt',
  pathTransition: 'none',
})

const PromoProgressCircle = ({
  reward,
  withMarginLeft,
  hidePointsText,
  size = SIZE.BIG,
  ...rest
}) => {
  const {
    thresholdProgression,
    threshold,
    maxApplicationsReached,
    thresholdDelta,
    toCollect,
  } = reward
  const sideProgress = thresholdProgression && toCollect
  const progress = (thresholdProgression / threshold) * 100
  const exactThresholdAchieved = toCollect && !thresholdProgression

  const showPointsText = !maxApplicationsReached && !hidePointsText
  if (maxApplicationsReached && !toCollect) {
    return (
      <MaxIconContainer>
        <CheckV3 color={theme.colors.white} />
      </MaxIconContainer>
    )
  }

  return (
    <Wrapper {...{ size }} {...rest}>
      {showPointsText && (
        <PointsText>
          {thresholdDelta}/{threshold} PKT.
        </PointsText>
      )}
      <CircleProgressContainer {...{ withMarginLeft }}>
        <CircularProgressbarWithChildren
          value={100}
          strokeWidth={8}
          styles={progressStyles}
        >
          <CircularProgressbarWithChildren
            value={
              maxApplicationsReached || exactThresholdAchieved ? 100 : progress
            }
            strokeWidth={sideProgress ? 14 : 50}
            styles={progressStyles}
          >
            {toCollect && (
              <AmountText
                data-test-id="reward-amount"
                color={sideProgress ? colors.secondary : colors.white}
              >
                {reward.toCollect}
              </AmountText>
            )}
          </CircularProgressbarWithChildren>
        </CircularProgressbarWithChildren>
      </CircleProgressContainer>
    </Wrapper>
  )
}

export default PromoProgressCircle
export * from './consts'
