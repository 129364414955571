/* eslint-disable react/prop-types */
import React from 'react'

import { trimTitle } from 'utils'

import { ProductName, NameContainer } from './styles'

const ProductNameComponent = ({
  active,
  productPath,
  onNameClick,
  name,
  productId,
  children,
  isSmall,
  isClickable = true,
  className,
  eventParams,
}) => (
  <NameContainer {...{ isClickable }}>
    <ProductName
      data-test-id={`product_name_${productId}`}
      {...{ active, isSmall, className }}
      to={productPath}
      state={eventParams}
      onClick={onNameClick}
    >
      {trimTitle(name)}
    </ProductName>

    {children ? (
      <>
        &nbsp;
        {children}
      </>
    ) : null}
  </NameContainer>
)

export default ProductNameComponent
