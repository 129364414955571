import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

import { BaseTextV2Medium } from 'components/Text'

export const AccountDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  gap: ${theme.spacing.sm};
  align-items: center;
  justify-content: space-between;
`

export const AccountDetailsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    text-align: right;
    gap: 2px;
  }
`

export const AccountDetailsText = styled(BaseTextV2Medium).attrs({
  isSmall: { lineHeight: true },
})``

export const AccountDetailsSecondaryText = styled(AccountDetailsText)`
  color: ${theme.colors.gray7};
`
