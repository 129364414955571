import React from 'react'

import { Container, InnerContainer, Text, Btn } from './styles'

const NoResults = ({
  icon,
  text,
  btnIcon,
  btnText,
  handleBtnClick,
  containerTestId,
  btnTestId,
}) => (
  <Container data-test-id={containerTestId}>
    <InnerContainer>
      {icon}
      <Text>{text}</Text>
    </InnerContainer>
    <Btn data-test-id={btnTestId} onClick={handleBtnClick}>
      {btnIcon}
      {btnText}
    </Btn>
  </Container>
)

export default NoResults
