import React from 'react'
import PropTypes from 'prop-types'
import _toNumber from 'lodash/toNumber'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import theme from 'theme'

import { Wrapper } from './styledComponents'

const defaultStyles = {
  trailColor: 'transparent',
  strokeLinecap: 'butt',
  pathTransition: 'none',
}

const linesPadding = 0.02
const getCircleValue = val => 100 * (val - linesPadding)

const ProgressIndicator = ({
  tradeLimit,
  invoicesTotal,
  cartTotal,
  maxValue,
}) => {
  const getValue = val => _toNumber(val) / _toNumber(maxValue)

  const invoicesValue = getValue(invoicesTotal)
  const tradeLimitValue = getValue(tradeLimit)
  const cartValue = getValue(cartTotal)
  const rotation = invoicesValue + linesPadding / 2

  return (
    <Wrapper>
      <CircularProgressbarWithChildren
        value={getCircleValue(invoicesValue)}
        strokeWidth={6}
        styles={buildStyles({
          ...defaultStyles,
          pathColor: theme.colors.gray2,
          rotation: linesPadding / 2,
        })}
      >
        <CircularProgressbarWithChildren
          value={getCircleValue(tradeLimitValue)}
          strokeWidth={6}
          styles={buildStyles({
            ...defaultStyles,
            pathColor: theme.colors.primary,
            rotation,
          })}
        >
          <div style={{ width: '75%' }}>
            <CircularProgressbarWithChildren
              value={getCircleValue(cartValue)}
              strokeWidth={8}
              styles={buildStyles({
                ...defaultStyles,
                pathColor: theme.colors.secondary,
                rotation,
              })}
            />
          </div>
        </CircularProgressbarWithChildren>
      </CircularProgressbarWithChildren>
    </Wrapper>
  )
}

export default ProgressIndicator

ProgressIndicator.propTypes = {
  tradeLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cartTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
