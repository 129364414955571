import { createActions } from 'redux-actions'
import { identity } from 'lodash/fp'

import {
  createActionsGroup,
  createDeltaAction,
  createActionCreator,
} from 'utils/redux-utils'

export const CLEAR_CART_DELTA = createDeltaAction('CLEAR_CART_DELTA')
export const clearCartAction = createActionCreator(CLEAR_CART_DELTA)

export const SET_CHANGING_DATE_PRODUCT_ID = 'SET_CHANGING_DATE_PRODUCT_ID'
export const setChangingDateProductId = createActionCreator(
  SET_CHANGING_DATE_PRODUCT_ID,
)

export const DELIVERY_ACTIONS = { UPDATE: 'DELIVERY_DATE/UPDATE' }

export default createActionsGroup('CART')
export const updateCartItem = createActionsGroup('UPDATE_CART_ITEM')
export const deleteCartItem = createActionsGroup('DELETE_CART_ITEM')

export const updateDeliveryDate = createActionCreator(DELIVERY_ACTIONS.UPDATE)

export const updateDeliveryComment = createActionsGroup(
  'UPDATE_DELIVERY_COMMENT',
)

export const confirmDeliveryChange = createActions({
  ACCEPT: identity,
  DECLINE: identity,
})

export const TRACK_STOCK_EXCEEDED = createDeltaAction('TRACK_STOCK_EXCEEDED')
export const trackStockExceededAction = createActionCreator(
  TRACK_STOCK_EXCEEDED,
)
