import React from 'react'
import PropTypes from 'prop-types'

import { DEFAULT_PAGE_SIZE } from 'consts'
import Pagination from 'components/Pagination'
import { PaginationContainer, Container } from './styles'

const ItemsTable = ({
  pagination,
  onPageChange,
  children,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const currentPage = pagination.currentPage || 1
  const totalCount = pagination.totalCount || 0
  const totalPages = pagination.totalPages || 1

  return (
    <Container>
      {children}

      {totalCount > pageSize && (
        <PaginationContainer>
          <Pagination
            onChange={onPageChange}
            totalPages={totalPages}
            current={currentPage}
          />
        </PaginationContainer>
      )}
    </Container>
  )
}

ItemsTable.propTypes = {
  children: PropTypes.node.isRequired,
  pagination: PropTypes.shape({}),
  onPageChange: PropTypes.func,
}

export * from './utils'
export default ItemsTable
