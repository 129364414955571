import { groupBy, keys } from 'lodash/fp'
import dayjs from 'dayjs'

import { DATE_TIME_FORMATS } from 'utils/datetime'

export const isCurrentMonth = group =>
  dayjs().format(DATE_TIME_FORMATS.MONTH_GROUP) === group

export const makeItemsByDateGrouper = iteratee =>
  groupBy(item => {
    const date = iteratee(item)
    return dayjs(date).format(DATE_TIME_FORMATS.MONTH_GROUP)
  })

export const sortGroupedItems = groupedItems =>
  keys(groupedItems).sort((group1, group2) => {
    const [month1, year1] = group1.split(' ')
    const [month2, year2] = group2.split(' ')
    const yearDiff = year2 - year1
    if (yearDiff !== 0) return yearDiff
    return (
      dayjs()
        .month(month2)
        .format('M') -
      dayjs()
        .month(month1)
        .format('M')
    )
  })
